import { useDispatch, useSelector } from "react-redux";
import { Appstore } from "../../redux";
import { useEffect, useState } from "react";
import { SetContentTags } from "../../redux/catalogs/states";
import { getContentTags } from "../../Services/ContentTag";

const UseContentTag = () => {
  const [isFetching, setIsFetching] = useState(false)

  const dispatch = useDispatch();
  const contentTagsData = useSelector((state: Appstore) => state.contentTags); 

  const resetContentTags = () => {
    setIsFetching(true)
  }

  useEffect(() => {
    
      if (contentTagsData.length) {
        setIsFetching(false)
        return
      }
      if (!isFetching) return

      getContentTags()
      .then((response) => dispatch(SetContentTags(response)))
      .catch((error) => console.error(error))
      .finally(() => setIsFetching(false))
  }, [isFetching]);

  useEffect(() => {
    setIsFetching(true)
  }, []);


  return {contentTagsData, isFetching,resetContentTags};
};

export {UseContentTag}
