import React, { useEffect, useState } from "react";
import CatalogSubTitle from "../Catalog Text/CatalogSubTitle";
import DropDownList from "../../Atomos/DropDown/Dropdown";
import NewButton from "../../Atomos/Buttons/newButton";
import PlusIcon from "../../Atomos/Icons/PlusIcon";
import Modal from "../../Atomos/Modals/Modal";
import Input from "../../Atomos/Inputs/Input";
import CancelClear from "../../Atomos/Icons/CancelClear";
import RedPencil from "../../Atomos/Icons/RedPencil";
import DeleteIcon from "../../Atomos/Icons/DeleteIcon";
import { useLocation } from "react-router-dom";
import { IPerson } from "../../../interfaces";
import { IEducation } from "../../../interfaces/models/IEducation";
import { useStaffMode } from "../../../hooks/StaffModeProvider";
import { getCurrentTimestamp } from "../../../utils/functions";
import { Appstore } from "../../../redux";
import { useSelector } from "react-redux";
import { Option } from "../../Atomos/DropDown/DDMultiple";
import { v4 as UUIDV4 } from "uuid";
import { getSchools } from "../../../Services/School";
import { useCreateAndLog } from "../../../hooks/LogActivity/LogActivityCreates";
import { getDegrees } from "../../../Services/Degree";
import useViewport from "../../../hooks/useViewPort";

type ProfileEducationProps = {
  person: IPerson;
  educationId?: string
  educations?: IEducation[]
  setEducations: React.Dispatch<React.SetStateAction<IEducation[]>>
}

type Error = {
  error: boolean;
  message: string;
}
type ActiveErrors = {
  school: Error;
  degree: Error;
  startYear: Error;
  endYear: Error;
  note1: Error;
  note2: Error;
  note3: Error;
  note4: Error;
};

const initialActiveErrors: ActiveErrors = {
  school: { error:false, message:'' },
  degree: { error:false, message:'' },
  startYear: { error:false, message:'' },
  endYear: { error:false, message:'' },
  note1: { error:false, message:'' },
  note2: { error:false, message:'' },
  note3: { error:false, message:'' },
  note4: { error:false, message:'' },
};

const initialModalErrors = {
  schoolRequired: false,
  degreeRequired: false,
}

const ProfileEducationEdit: React.FC<ProfileEducationProps> = ({ 
  person,
  educations, 
  setEducations
}) => {

  const [activeModalErrors, setActiveModalErrors] = useState(initialModalErrors)
  const [addedSchool, setAddedSchool] = useState<string>('')
  const [addedDegree, setAddedDegree] = useState<any>('')
  const [selectedSchool, setSelectedSchool] = useState<Option>();
  const [selectedDegree, setSelectedDegree] = useState<Option>();
  const [schools, setSchools] = useState<any[]>([]);
  const [activeErrors, setActiveErrors] = useState<ActiveErrors>(initialActiveErrors);
  const [degree, setDegree] = useState<any[]>([])
  const [newSchoolModal, setNewSchoolModal] = useState(false)
  const [newDegreeModal, setNewDegreeModal] = useState(false)
  const [duplicateError, setDuplicateError] = useState(false)
  const [startYear, setStartYear] = useState<number | undefined>();
  const [endYear, setEndYear] = useState<number | undefined>();
  const [noteOne, setNoteOne] = useState('');
  const [noteTwo, setNoteTwo] = useState('');
  const [noteThree, setNoteThree] = useState('');
  const [noteFour, setNoteFour] = useState('');
  const [educationId, setEducationId] = useState<string>('');

  const manageFirm = useSelector((state: Appstore) => state.manageFirm);
  const { viewportWidth } = useViewport()
  const { pathname } = useLocation()
  const create = useCreateAndLog();

  const handleEditEducation = (education: IEducation) => {
    setEducationId(education.id ?? "");
    setSelectedSchool({id: education.schoolId, name: education.schoolName});
    setSelectedDegree({id: education.degreeId, name: education.degreeName});
    setStartYear(education.startYear);
    setEndYear(education.endYear);
    const notesArray = education.notes?.split('\n') || [];
    setNoteOne(notesArray[0] || '');
    setNoteTwo(notesArray[1] || '');
    setNoteThree(notesArray[2] || '');
    setNoteFour(notesArray[3] || '');
  };

  const clearForm = () => {
    setSelectedSchool({id: '', name: ''});
    setSelectedDegree({id: '', name: ''});
    setStartYear(undefined);
    setEndYear(undefined);
    setNoteOne('');
    setNoteTwo('');
    setNoteThree('');
    setNoteFour('');
  };

  const {isStaffMode} = useStaffMode();

  const toggleNewSchoolModal = () => {
    setNewSchoolModal(!newSchoolModal)
  }

  const toggleNewDegreeModal = () => {
    setNewDegreeModal(!newDegreeModal)
  }

  const CancelIcon = () => (
    <div className="w-[1.5rem] h-[1.5rem] flex items-center justify-center">
      <CancelClear />
    </div>
  )

  const AddIcon = () => (
    <div className="w-[1.5rem] h-[1.5rem] flex items-center justify-center">
      <PlusIcon />
    </div>
  )

  const getSchoolData = async () => {
    try {
      const response = await getSchools()
      setSchools(response);
    } catch (error) {
      console.error('Error fetching schools', error);
    }
  }

  const getDegreesData = async () => {
    try {
      const response = await getDegrees()
      setDegree(response);
    } catch (error) {
      console.error('Error fetching degree', error);
    }
  };

  useEffect(() => {
    getSchoolData()
    getDegreesData()
  }, [])

  const addNewSchool = async () => {
    setDuplicateError(false)
    if (addedSchool.trim() === '') {
      const errors = {
        ...initialModalErrors,
        schoolRequired: true,
      }
      setActiveModalErrors(errors)
      return
    }
    try {
      await create.school({ 
        school: {name: addedSchool}, 
        pathname, 
        firmId: manageFirm.selectedFirm?.id ?? '' 
      });

    } catch (error: any) {
      if (error.message.includes('Name')) {
        setActiveModalErrors({
          ...initialModalErrors,
          schoolRequired: true,
        })
        setDuplicateError(true) 
      } else {
        console.error(error)
      }
    }
  }

  const addDegree = async (name: string) => {
    try {
      let newDegreeData = {
        name: name
      }
      const response = await create.degree({ degree: newDegreeData, pathname, firmId: manageFirm.selectedFirm?.id ?? '' });
      setSelectedDegree(response)
    } catch (error: any) {
      if (error.message.includes('Name')) {
        setDuplicateError(true)
        setActiveModalErrors({
          ...initialModalErrors,
          degreeRequired: true,
        })
      } else {
        console.error(error)
      }
    }
  }

  const addNewDegree = async () => {
    setDuplicateError(false)
    if (addedDegree.trim() === '') {
      const errors = {
        ...initialModalErrors,
        degreeRequired: true,
      }
      setActiveModalErrors(errors)
      return
    }
    await addDegree(addedDegree)
  }


  const handleNewSchoolName = (e: any) => {
    setAddedSchool(e.target.value);
    if (activeModalErrors.schoolRequired && e.target.value.trim() !== '') {
      setActiveModalErrors(prev => ({ ...prev, schoolRequired: false }));
    }
  };

  const handleNewDegreeName = (e: any) => {
    setAddedDegree(e.target.value);
    if (activeModalErrors.degreeRequired && e.target.value.trim() !== '') {
      setActiveModalErrors(prev => ({ ...prev, degreeRequired: false }));
    }
  };

  const characterCount = (note: string) => `${note.length}/50`;
 
  const validateYear = (year: number | undefined, fieldName: string): { error: boolean, message: string } => {
    if (year === undefined || isNaN(year)) {
      return { error: true, message: `${fieldName} must be a valid number` };
    }
    if (year < 1900 || year > new Date().getFullYear() + 100) {
      return { error: true, message: `${fieldName} must be between 1900 and ${new Date().getFullYear() + 100}` };
    }
    return { error: false, message: '' };
  };
  
  const validateStartEndYear = (startYear: number | undefined, endYear: number | undefined): { error: boolean, message: string } => {
    if (startYear !== undefined && endYear !== undefined && startYear > endYear) {
      return { error: true, message: 'Start year must be less than or equal to end year' };
    }
    return { error: false, message: '' };
  };
  
  const validation = () => {
    const newActiveErrors: ActiveErrors = {
      school: {
        error: !selectedSchool,
        message: 'School is required',
      },
      degree: {
        error: !selectedDegree,
        message: 'Degree is required',
      },
      startYear: validateYear(startYear, 'Start year'),
      endYear: validateYear(endYear, 'End year'),
      note1: {
        error: noteOne.length > 50,
        message: 'Maximum characters exceeded (50)',
      },
      note2: {
        error: noteTwo.length > 50,
        message: 'Maximum characters exceeded (50)',
      },
      note3: {
        error: noteThree.length > 50,
        message: 'Maximum characters exceeded (50)',
      },
      note4: {
        error: noteFour.length > 50,
        message: 'Maximum characters exceeded (50)',
      },
    };
  
    const startEndYearError = validateStartEndYear(startYear, endYear);
    if (startEndYearError.error) {
      newActiveErrors.startYear = startEndYearError;
      newActiveErrors.endYear = startEndYearError;
    }
  
    setActiveErrors(newActiveErrors);
    return isSomeError(newActiveErrors);
  };

  const isSomeError = (errors: ActiveErrors) => {
    return Object.values(errors).some((errorObj) => errorObj.error);
  };

  const handleFormSubmit = async () => {
    if (validation()) return;
  
    const formData: IEducation = {
      id: educationId ?? UUIDV4(),
      startYear: startYear ?? 0,
      endYear: endYear ?? 0,
      notes: [noteOne, noteTwo, noteThree, noteFour].join('\n'),
      schoolId: selectedSchool?.id ?? '',
      schoolName: selectedSchool?.name ?? '',
      degreeId: selectedDegree?.id ?? '',
      degreeName: selectedDegree?.name ?? '',
      personId: person?.id ?? '',
      personName: person?.name ?? '',
      firmId: manageFirm.selectedFirm?.id ?? '',
      firmName: manageFirm.selectedFirm?.firmName ?? '',
      createdDate: getCurrentTimestamp(),
    };
  
    setEducations((prev) => {
      const existingIndex = prev.findIndex((edu) => edu.id === formData.id);
      if (existingIndex !== -1) {
        const updatedEducations = [...prev];
        updatedEducations[existingIndex] = formData;
        return updatedEducations;
      } else {
        return [...prev, formData];
      }
    });
  
    clearForm();
  };

  const handleDeleteEducation = async (education: IEducation) => {
    setEducations((educations || []).filter(e => e.id !== education.id))
  }
  return (
    <>
      {newSchoolModal &&
        <Modal className="w-full p-5" titleColor="#0070CD" isModalOpen={newSchoolModal} title={'New School'} toggleModal={toggleNewSchoolModal} children={
          <>
            <div className="flex flex-col gap-6">
              <div>
                <Input isWfull title={'Name'} error={activeModalErrors.schoolRequired} errorMsj={`${duplicateError ? 'This value is already registered in the DB. Please input a different/new one.' : 'Name is required'}`} required={true} value={addedSchool} onChange={(e) => handleNewSchoolName(e)} />
              </div>
              <div className='flex md:grid md:grid-cols-2 lg:flex lg:grid-cols-none lg:flex-row justify-center flex-col-reverse gap-[1rem] md:gap-[1rem] lg:justify-end items-center'>
                <NewButton color="neutral" style="outlined" onClick={() => setNewSchoolModal(false)} content='textIcon' text="CANCEL" icon={<CancelIcon />} />
                <NewButton color="accent" style="filled" onClick={() => addNewSchool()} content='textIcon' text="ADD" icon={<AddIcon />} />
              </div>
            </div>
          </>
        } />
      }
      {newDegreeModal &&
        <Modal className="w-full p-5" titleColor="#0070CD" isModalOpen={newDegreeModal} title={'New Degree'} toggleModal={toggleNewDegreeModal} children={
          <>
            <div className="flex flex-col gap-6">
              <div>
                <Input required title={'Name'} error={activeModalErrors.degreeRequired} value={addedDegree} errorMsj={`${duplicateError ? 'This value is already registered in the DB. Please input a different/new one.' : 'Name is required'}`} onChange={(e: any) => handleNewDegreeName(e)} />
              </div>
              <div className='flex md:grid md:grid-cols-2 lg:flex lg:grid-cols-none lg:flex-row justify-center flex-col-reverse gap-[.625rem] md:gap-[1rem] lg:justify-end'>
                <NewButton color="neutral" style="outlined" onClick={() => setNewDegreeModal(false)} content='textIcon' text="CANCEL" icon={<CancelIcon />} />
                <NewButton color="accent" style="filled" onClick={() => addNewDegree()} content='textIcon' text="ADD" icon={<AddIcon />} />
              </div>
            </div>
          </>
        } />
      }
      <section className=' formsectionNTitle w-full'>
        <CatalogSubTitle title={'Education'} className={`${isStaffMode ? 'text-Default' : 'text-accentDefault'}`} />
        <div className="flex flex-col gap-5 md:flex-row  items-start lg:gap-[1.25rem] self-stretch">
          <div className="flex flex-col md:flex-1 items-end gap-[1.25rem] self-stretch">
            <div className="flex justify-end items-end gap-5 self-stretch">
              <DropDownList
                title="School"
                required
                getid
                data={schools}
                selectedObjst={selectedSchool?.name}
                seter={setSelectedSchool}
                error={activeErrors.school.error}
                errormsj={activeErrors.school.message}
              />
              <div className={`flex h-auto ${activeErrors.school ? 'items-center' : 'items-end'}`}>
                <NewButton onClick={toggleNewSchoolModal} text={viewportWidth < 768 ? '' : viewportWidth < 1024 ? 'New' : 'New School'} content="textIcon" icon={<PlusIcon />}  color={`${isStaffMode ? 'accent' : 'primary'}`}/>
              </div>
            </div>
            <div className="flex justify-end items-end gap-5 self-stretch">
              <DropDownList
                title="Degree"
                required
                getid
                data={degree}
                selectedObjst={selectedDegree?.name}
                seter={setSelectedDegree}
                error={activeErrors.degree.error}
                errormsj={activeErrors.degree.message}
              />
              <div className={`flex h-auto ${activeErrors.degree ? 'items-center' : 'items-end'}`}>
                <NewButton onClick={toggleNewDegreeModal} text={viewportWidth < 768 ? '' : viewportWidth < 1024 ? 'New' : 'New Degree'} content="textIcon" icon={<PlusIcon />}  color={`${isStaffMode ? 'accent' : 'primary'}`}/>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-end items-start gap-4 self-stretch">
              <Input
                isWfull
                title={'Start Year'}
                required
                error={activeErrors.startYear.error}
                errorMsj={activeErrors.startYear.message}
                name="startYear"
                value={startYear?.toString()}
                onChange={e => setStartYear(Number(e.target.value))}
              />
              <Input
                isWfull
                title={'End Year (or expected)'}
                required
                error={activeErrors.endYear.error}
                errorMsj={activeErrors.endYear.message}
                name="endYear"
                value={endYear?.toString()}
                onChange={e => setEndYear(Number(e.target.value))}
              />
            </div>
            <div className="flex flex-col items-end gap-4 self-stretch">
              <div className="w-full">
                <Input
                  isWfull
                  title={'Notables'}
                  error={activeErrors.note1.error}
                  errorMsj={activeErrors.note1.message}
                  value={noteOne}
                  onChange={e => setNoteOne(e.target.value)}
                />
                <div className="flex justify-end">
                  <span className="flex text-base leading-[1.125rem] text-[#474F56] pt-[.625rem] px-2">{characterCount(noteOne)}</span>
                </div>
              </div>

              <div className="w-full">
                <Input
                  isWfull
                  title={''}
                  error={activeErrors.note2.error}
                  errorMsj={activeErrors.note2.message}
                  value={noteTwo}
                  onChange={e => setNoteTwo(e.target.value)}
                />
                <div className="flex justify-end">
                  <span className="flex text-base leading-[1.125rem] text-[#474F56] pt-[.625rem] px-2">{characterCount(noteTwo)}</span>
                </div>
              </div>

              <div className="w-full">
                <Input
                  isWfull
                  title={''}
                  error={activeErrors.note3.error}
                  errorMsj={activeErrors.note3.message}
                  value={noteThree}
                  onChange={e => setNoteThree(e.target.value)}
                />
                <div className="flex justify-end">
                  <span className="flex text-base leading-[1.125rem] text-[#474F56] pt-[.625rem] px-2">{characterCount(noteThree)}</span>
                </div>
              </div>

              <div className="w-full">
                <Input
                  isWfull
                  title={''}
                  error={activeErrors.note4.error}
                  errorMsj={activeErrors.note4.message}
                  value={noteFour}
                  onChange={e => setNoteFour(e.target.value)}
                />
                <div className="flex justify-end">
                  <span className="flex text-base leading-[1.125rem] text-[#474F56] pt-[.625rem] px-2">{characterCount(noteFour)}</span>
                </div>
              </div>
            </div>
            <div className="lg:max-w-[16rem] w-full md:max-w-[13.06rem]">
              <NewButton onClick={handleFormSubmit} size="medium" text="ADD EDUCATION" content="textIcon" icon={<PlusIcon />} color={`${isStaffMode ? 'accent' : 'primary'}`} className=""/>
            </div>
          </div>
          <hr className="flex h-0 md:h-auto md:w-0 flex-col justify-center items-center self-stretch border border-[#F6F6F6]" />
          <div className="flex flex-col items-end gap-[1.25rem] flex-1">
            {educations && educations?.map((education: IEducation) => (
              <div className="flex flex-col gap-4 self-stretch">
                <div className="flex flex-col items-start gap-4 self-stretch">
                  <div className="flex items-start gap-4 self-stretch">
                    <div className="flex flex-col items-start gap-3">
                    <div className={`flex min-h-[2.25rem] p-[.46rem] justify-center items-center gap-2 rounded-full border border-solid ${isStaffMode ? 'border-Default bg-Default' : 'border-[#BC3323] bg-[#BC3323]'}  self-stretch cursor-pointer`} onClick={() => handleEditEducation(education)}>
                        <div className="flex w-5 h-5 justify-center items-center fill-white p-[0.15625rem] relative z-10">
                          <RedPencil />
                        </div>
                      </div>
                      <div className="flex min-h-[2.25rem] justify-center items-center gap-2 rounded-full border border-solid border-[#FFF3EB] bg-[#FFF3EB] self-stretch cursor-pointer" onClick={() => handleDeleteEducation(education)}>
                        <div className="flex w-5 h-5 justify-center items-center fill-[#BD4B00] relative z-10">
                          <DeleteIcon />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-start gap-3 flex-1">
                      <span className="text-[1.125rem] font-semibold leading-[1.25rem] text-[#474F56]">{education?.schoolName}</span>
                      <span className="text-[#474F56] text-lg leading-8">{education?.startYear} - {education?.endYear}</span>
                      <span className="text-[#474F56] text-lg leading-5 italic">{education?.degreeName}</span>
                    </div>
                  </div>
                </div>
                <ul className="ml-10 list-disc list-inside">
                  {education?.notes?.split('\n').map((note, index) => (
                    note.length > 0 && <li key={index}>{note}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default ProfileEducationEdit;