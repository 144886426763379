import { createSlice } from "@reduxjs/toolkit";
import { ManageFirmEmpty } from "../emptyStates";

export const manageFirmSlice = createSlice({
  name: "manageFirm",
  initialState: ManageFirmEmpty,
  reducers: {
    ModifyManageFirm: (state, action) => ({ ...state, ...action.payload }),
    ResetManageFirm: () => ManageFirmEmpty,
    SetFirm: (state, action) => {
      state.selectedFirm = action.payload;
      localStorage.setItem("selectedFirm", JSON.stringify(action.payload));
    },
    SetTab: (state, action) => {
      state.selectedTab = action.payload;
      localStorage.setItem("selectedTab", action.payload);
    },
    SetShowMenu: (state, action) => {
      state.showMenu = action.payload;
    },
  },
});

export const { ModifyManageFirm, ResetManageFirm, SetFirm, SetTab } =
  manageFirmSlice.actions;
