import { FC } from 'react';
import css from './BackgroundProfile.module.css';

interface BackgroundProps {
  image: string;
  viewMode?: boolean;
}

const BackgroundComponent: FC<BackgroundProps> = ({ image, viewMode }) => {
  const bgImg = {
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
  };

  return (
    <div className="absolute inset-0 pointer-events-none -z-1 overflow-hidden">
      <div className={`${css.bgHolder} w-full h-full`}>
        <div
          className={`w-full h-full bg-fixed bg-cover relative ${css.bgImage}`}
          style={bgImg}
        >
          <div className={`${css.grayLayer} absolute inset-0 bg-[#2c2c2c] opacity-50`}></div>
        </div>
      </div>
    </div>
  );
};

export default BackgroundComponent;
