

import { axios } from "..";
import IOffice from "../interfaces/models/IOffice";

export const getOffice = async () => {
    try {
        const offices = await axios.Get('./office');
        const data = offices.data;
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    } 
  };

export const getOfficeByFirm = async (id: string) => {
    try {
      const params = new URLSearchParams();
      params.append('filter', JSON.stringify({firm: {value: id, comparisonType: 'EQUAL'}}))

      const offices = await axios.Get(`./office?${params.toString()}`);
      const data = offices.data;
      return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    } 
  };

  export const updateOffice = async (office: IOffice) => {
    try {
      const response = await axios.Put(`/office`, office);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error
    }
  };
  
  export const createContent = async (office: IOffice) => {
    try {
      const response = await axios.Post(`/office`, `${office}`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error
    }
  };

  export const getOfficeByJurisdiction = async (jurisdictionId: string) => {
    try {
      const params = new URLSearchParams();
      params.append('fields', JSON.stringify(['id']))
      params.append('filter', JSON.stringify({jurisdiction: {value: jurisdictionId, comparisonType: 'EQUAL'}}))

      const offices = await axios.Get(`./office?${params.toString()}`);
      return offices.data;
    } catch (error) {
        throw error;
    } 
  };

  export const getOfficeByJurisdictionGroup = async (jurisdictionId: string) => {
    try {
      const params = new URLSearchParams();
      params.append('fields', JSON.stringify(['id']))
      params.append('filter', JSON.stringify({jurisdictionGroup: {value: jurisdictionId, comparisonType: 'EQUAL'}}))

      const offices = await axios.Get(`./office?${params.toString()}`);
      return offices.data;
    } catch (error) {
        throw error;
    } 
  };