import { axios } from "..";

export const getDataAdmin = async (): Promise<any[]> => {
  try {
    const dataAdmin = await axios.Get("./dataAdmin");
    const data = dataAdmin.data;
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const updateDataAdmin = async (dataAdmin: any): Promise<any> => {
  try {
    const res = await axios.Put("DataAdmin", dataAdmin);
    return res.data
  } catch (error) {
    console.error(error)
    throw error
  }
};

export const createDataAdmin = async (dataAdmin: any): Promise<any> => {
  try {
    const res = await axios.Post("DataAdmin", dataAdmin);
    return res.data
  } catch (error) {
    console.error(error);
    throw error
  }
};

export const deleteDataAdmin = async (id: string): Promise<boolean> => {
  try {
    const response = await axios.Delete(`DataAdmin`, `${id}`);
    return response.status === 200;
  } catch (error) {
    console.error("Error deleting firm:", error);
    return false;
  }
};

export const getDataAdminByFirm = async (firmId: string): Promise<any[]> => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({firm: {value: firmId, comparisonType: 'EQUAL'}}))
    const dataAdmin = await axios.Get(`./dataAdmin?${params.toString()}`);
    return dataAdmin.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};
