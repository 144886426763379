// src/redux/catalogs/states/citiesSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICity } from "../../../interfaces";

const initialState: ICity[] = [];

export const citiesSlice = createSlice({
  name: "cities",
  initialState,
  reducers: {
    SetCities: (_, action: PayloadAction<ICity[]>) => action.payload,
    AddCity: (state, action: PayloadAction<ICity>) => {
      state.push(action.payload);
    },
    UpdateCity: (state, action: PayloadAction<ICity>) => {
      const index = state.findIndex((city) => city.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },
    DeleteCity: (state, action: PayloadAction<string>) => {
      return state?.filter((city) => city.id !== action.payload);
    },
  },
});

export const { SetCities, AddCity, UpdateCity, DeleteCity } =
  citiesSlice.actions;
export default citiesSlice.reducer;
