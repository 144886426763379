import React, { useEffect, useState } from 'react';
import SubStateFilter from '../../components/Catalogs/Filters/SubStateFiler';
import { Option } from '../../components/Atomos/DropDown/DDMultiple';
import useDataLoader2 from '../../hooks/useDataLoader2';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import { formatTimeSpanToString } from '../../utils/functions';
import { useStatesList } from '../../hooks/Catalogs/useStates';
import { UseCountryList } from '../../hooks/Catalogs';
import { ISubstate } from '../../interfaces';
import { defaultPageSize } from '../../utils/const';

const State: React.FC = ({ ...props }) => {
  const Headers: string[] = ['name', 'abbreviation', 'state', 'country', 'createdDate'];
  
  const [tableInstance, setTableInstance] = useState<any>();
  const [selectedCountry, setSelectedCountry] = useState<Option[]>([]);
  const [selectedState, setSelectedState] = useState<Option[]>([]);
  const [subStateData, setSubStateData] = useState<any[]>([]);
  const [stateByCountryData, setStateByCountryData] = useState<any[]>([]);
  const [nameFilter, setNameFilter] = useState('');
  const [abbreviationFilter, setAbbreviationFilter] = useState('');
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'name',
    'abbreviation',
    'state',
    'stateName',
    'countryName',
    'createdDate'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/subState',
    defaultPageSize,
    fields
  });
  const { statesData } = useStatesList()
  const { countryData} = UseCountryList()
  const { generateColumns } = useColumnConfig(Headers);

  const columns = generateColumns([
    { id: 'count', header: '#', cell: (props: any) => <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>, enableSorting: false, size: 50 },
    { id: 'name', header: 'Name', cell: (props: any) => props.getValue() },
    { id: 'abbreviation', header: 'Abbreviation', cell: (props: any) => props.getValue() },
    { id: 'state', header: 'State', cell: (props: any) => props.getValue()},
    { id: 'country', header: 'Country', cell: (props: any) => props.getValue() },
    {
      id: 'createdDate', header: 'Created Date', cell: (props: any) => {
        const value = props.getValue();
        return (
          <p>
            {value && formatTimeSpanToString(value) }
          </p>
        );
      }
    },
    {
      id: 'edit', header: 'Edit', cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={data[props.row.id]}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ), enableSorting: false
    },
  ]);

  const clearSelections = () => {
    setNameFilter('')
    setAbbreviationFilter('')
    setSelectedCountry([])
    setSelectedState([])
    fetchData(true)
  };

  const formatData = (data: ISubstate[]) => {
    const formatedData = data.map((d: ISubstate) => {
      const state = statesData.find(s => s.id === d.state)
      return {
        ...d,
        state: d.stateName,
        country: d.countryName
      }
    })
    return formatedData
  }

  useEffect(() => {
    if (statesData.length){
      setStateByCountryData(statesData.filter(s => 
        selectedCountry.map(c => c.id).includes(s.country)))
    } else {
      setStateByCountryData([])
    }
  },[statesData, selectedCountry]);

  useEffect(() => {
    if (data) setSubStateData(formatData(data))
  },[data]);

  useEffect(() => {
    if (!statesData.length || !countryData.length) return
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[statesData, countryData]);

  useEffect(() => {
    let filterAux = {
      name: {
        value: nameFilter,
        comparisonType: 'CONTAINS'
      },
      abbreviation: {
        value: abbreviationFilter,
        comparisonType: 'CONTAINS'
      },
      country: {
        value: selectedCountry?.map(c => c.id),
        comparisonType: 'EQUAL'
      },
      state: {
        value: selectedState?.map(f => f.id),
        comparisonType: 'EQUAL'
      },
    }
    setFilter(filterAux)
  },[
    nameFilter, 
    abbreviationFilter,
    selectedCountry, 
    selectedState, 
  ]);
  
  return (
    <>
      <div className=' mainSection'>
        <CatalogTableC
          noRedBox
          title={`SubState Catalog`}
          catalogName={'SubState'}
          route={'SubState'}
          isfiltered={false}
          data={data}
          headers={Headers}
          totalCount={totalCount}
        >
          <SubStateFilter
            nameFilter={nameFilter}
            abbreviationFilter={abbreviationFilter}
            onNameFilterChange={setNameFilter}
            onAbbreviationFilterChange={setAbbreviationFilter}
            countryData={countryData}
            stateData={stateByCountryData}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            selectedState={selectedState}
            setSelectedState={setSelectedState}
            headers={Headers}
            resetFilters={clearSelections}
            search={() => fetchData()}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={subStateData}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </div>
    </>
  );
};

export default State;
