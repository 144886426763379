import { useState, useEffect } from 'react';
import Axios from '../utils/axios';
import { ISorting } from '../interfaces/Components/ITable';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../redux/loaderActions';
import { useNavigate } from 'react-router-dom';

interface DataLoaderProps {
  endpoint: string;
  queryParams?: string[];
  countOnly?: boolean;
  filters?: any;
}

interface DataLoaderResult<T> {
  data: T[];
  totalCount: number;
  isLoading: boolean;
  sort: { field: string; order: "asc" | "desc" | "default" } | null;
}

const useDataLoader = <T extends unknown>({
  endpoint,
  queryParams = [],
  countOnly = false,
  filters,
}: DataLoaderProps): DataLoaderResult<T> => {
  const [data, setData] = useState<T[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sort, setSort] = useState<ISorting>({ field: "_ts", order: "asc" });
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const axiosInstance = new Axios();

  const checkFilters = (filters: any) => {
    let queryString = "";

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (Array.isArray(value)) {
        if (value.length > 0) {
          queryString +=
            `&${key}=` +
            JSON.stringify(value.map((item: any) => item.id || item));
        }
      } else if (typeof value === "string") {
        if (value !== "") {
          queryString += `&${key}=${value}`;
        }
      } 
    });

    return queryString;
  };

  useEffect(() => {
    const sortParam = queryParams.find((param) => param.startsWith("sort="));
    if (sortParam) {
      const [, sortValue] = sortParam.split("=");
      const [field, order] = sortValue.split(":");
      const safeOrder = order === "desc" ? "desc" : "asc";
      setSort({ field, order: safeOrder });
    } else {
      setSort({ field: '_ts', order: 'asc' });
    }

    const fetchData = async () => {
      try {
        const filtersData = filters ? checkFilters(filters) : "";

        const formattedParams = queryParams.map((param) => param.split("="));
        const queryString = new URLSearchParams(
          formattedParams as any
        ).toString();
        const url = `${endpoint+"?"}${filtersData ? `&${filtersData}` : ""}${queryString ? `&${queryString}` : ""}${countOnly ? `${queryString ? "&" : "?"}countOnly=true` : ""}`;

        const response = await axiosInstance.Get(url);
        if (response.data != null) {
          setTotalCount(response.data.length);
          setData(response.data);
        } else {
          console.log('NOOOOOOOO')
        }
      } catch (error: any) {
        if (error?.response?.data === "Forbidden: You don't have access to this resource.") {
          navigate('/')
        }
        console.error('Error al cargar los datos:', error);
      }
    };

    fetchData();
  }, [endpoint, queryParams, countOnly, filters]);

  return { data, totalCount, isLoading, sort };
};

export default useDataLoader;
