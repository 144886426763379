import { axios } from "..";

export const getUsers = async () => {
  try {
    const users = await axios.Get("./user");
    const data = users.data;
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const createUser = async (user: any) => {
  try {
    const newUser = await axios.Post("./user", user);
    const data = newUser.data;
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
}
