
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../Context';
import Modal from '../../Atomos/Modals/Modal';
import Input from '../../Atomos/Inputs/Input';
import NewButton from '../../Atomos/Buttons/newButton';
import Plus from '../../Atomos/Icons/EditMode/Plus';
import CloseModal from '../../Atomos/Icons/EditMode/CloseModal';
import Axios from '../../../utils/axios';

type titleprops = {
  open: boolean;
  toggleModal: () => void;
  onAddLanguage?: (language: any) => void;
}

const axios = new Axios();

const AddLanguageModal: React.FC<titleprops> = ({ open, toggleModal, onAddLanguage }) => {
  const { mode } = useAppContext();
  const [language, setLanguage] = useState('');
  const [errors, setErrors] = useState({ name: { error: false, message: '' } });

  const saveLanguage = async () => {
    if (language === '') {
      setErrors({ name: { error: true, message: 'This field is required' } });
      return;
    } else {
      const newLanguage = { name: language };
      const response = await axios.Post('language', newLanguage);
      if (response.status === 200 && !response.data.error) {
        // Verifica si onAddLanguage está definido antes de invocarlo
        if (onAddLanguage) {
          onAddLanguage(newLanguage);
        }
        ClearDataNClose();
      } else {
        setErrors({ name: { error: true, message: response.data.error || 'Error saving language' } });
      }
    }
  };

  const ClearDataNClose = () => {
    setLanguage('');
    errors.name.error = false;
    errors.name.message = '';
    setErrors({ name: { error: false, message: '' } });
    toggleModal();
  }

  const handleLanguageError = (languagestr: string) => {
    if (languagestr !== '') {
      errors.name.error = false;
      errors.name.message = '';
      setErrors({ name: { error: false, message: '' } });
    }
    setLanguage(languagestr);
  }

  return (
    <Modal isModalOpen={open} toggleModal={ClearDataNClose} title={'New Language'}  >
      <div className='flex flex-col gap-10 formModal'>
        <div>
          <Input title="Name" name="name" onChange={(e) => handleLanguageError(e.target.value)}
            errorMsj={errors.name.message} value={language} error={errors.name.error} required />
        </div>
        <div className="flex   gap-5">
          <NewButton onClick={ClearDataNClose} content='textIcon' size='large' style='outlined' color='neutral' icon={<CloseModal />} text="Cancel" />
          <NewButton onClick={saveLanguage} content='textIcon' size='large' color={`${mode === 'member' ? 'primary' : 'accent'}`} icon={<Plus />} text="Add" />
        </div>
      </div>
    </Modal>
  )

};

export default AddLanguageModal;