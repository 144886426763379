function Content (props: any) {
  return (
    <div className={props.className}>
      <svg 
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
      >
        <path d="M3.29346 42.7065V5.26514H44.7348V42.7065H3.29346ZM7.25436 38.7456H40.7457V9.25428H7.25436V38.7456ZM12.5913 33.5586H35.437V30.5586H12.5913V33.5586ZM12.5913 25.3999H20.3413V14.413H12.5913V25.3999ZM25.4 25.3999H35.437V22.3999H25.4V25.3999ZM25.4 17.413H35.437V14.413H25.4V17.413Z"/>
      </svg>
    </div>
  );
}

export default Content;
