import { axios } from "..";
import { IAddress } from "../interfaces/models/IAddress";

export const getAllAddresses = async () => {
  try {
    const response = await axios.Get('/Address')
    if(response && response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const createAddress = async (address: IAddress) => {
  try {
    const response = await axios.Post(`/Address`, address);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error
  }
};

export const updateAddress = async (address: IAddress) => {
  try {
    const response = await axios.Put(`/Address`, address);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error
  }
};

export const getAddresById = async (id: string) => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({id: {value: id, comparisonType: 'EQUAL'}}))
    const response = await axios.Get(`/Address?${params.toString()}`);
    if(response && response.data)
    return response.data
  } catch (error) {
    console.error(error)
  }
}