import { axios } from "..";
import { IEducation } from "../interfaces/models/IEducation";

export const getEducationByPersonId = async (personId: string): Promise<IEducation[]> => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({personId: {value: personId, comparisonType: 'EQUAL'}}))
    const response = await axios.Get(`./education?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error
  }
};

export const postEducation = async (education: IEducation): Promise<any[]> => {
  try {
    const response = await axios.Post('./education', education);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error
  }
};

export const updateEducation = async (education: IEducation): Promise<any[]> => {
  try {
    const response = await axios.Put('./education', education);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error
  }
};

export const deleteEducation = async (id: string): Promise<any[]> => {
  try {
    const response = await axios.Delete('./education', id);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error
  }
};
