import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/AddCatalogs.css";
import DropDownList from "../../../components/Atomos/DropDown/Dropdown";
import Input from "../../../components/Atomos/Inputs/Input";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import { PhoneNumber } from "../../../components/Atomos/PhoneNumber/PhoneNumber";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import { getAllCities } from "../../../Services/City";
import { getStates } from "../../../Services/State";
import TextArea from "../../../components/Atomos/Inputs/TextArea";
import { useLogging } from "../../../Context/LoggingContext";
import { getCurrentTimestamp, getNameById, updateData } from "../../../utils/functions";
import SwitchToogle from "../../../components/Atomos/Switch-Toogle/Switch";
import IOffice, { IPhoneNumber } from "../../../interfaces/models/IOffice";
import { UseRegionList } from "../../../hooks/Catalogs/useRegionListOptions";
import { UseCountryList } from "../../../hooks/Catalogs";
import { UseJurisdictionList } from "../../../hooks/Catalogs/useJurisdiction";

interface iCatalogProps {
  mode: "add" | "edit";
}

type error = { error: boolean; msj: string };
type errors = {
  name: error;
  jurisdiction: error;
  jurisdictionGroup: error;
  address: error;
  country: error;
  state: error;
  city: error;
  officeType: error;
  phoneNumber: error;
  firm: error;
  yearFounded: error;
};
const currentYear = new Date().getFullYear();

const AddOffice: React.FC<iCatalogProps> = (props) => {
  const axios = new Axios();
  const navigate = useNavigate();
  const { logActivity } = useLogging();
  const location = useLocation();
  const { id } = useParams();
  const { state } = useLocation();
  const [office, setOffice] = useState<IOffice>(
    state
      ? { ...state }
      : {
        name: "",
        officeType: "",
        countOfPeople: "0",
        jurisdiction: "",
        jurisdictionGroup: "",
        firm: "",
        address: "",
        city: "",
        phoneNumber: "",
        faxNumber: "",
        yearFounded: "",
      }
  );

  const [errors, setErrors] = useState<errors>({
    name: { error: false, msj: "" },
    jurisdiction: { error: false, msj: "" },
    jurisdictionGroup: { error: false, msj: "" },
    address: { error: false, msj: "" },
    country: { error: false, msj: "" },
    state: { error: false, msj: "" },
    city: { error: false, msj: "" },
    officeType: { error: false, msj: "" },
    phoneNumber: { error: false, msj: "" },
    firm: { error: false, msj: "" },
    yearFounded: { error: false, msj: "" },
  });

  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState("");
  const [firmMainOfficeCount, setfirmMainOfficeCount] = useState(0);
  const [jurisdictionsInJGCount, setJurisdictionsInJGCount] = useState<any[]>(
    []
  );
  const { mode } = props;

  const [cityData, setCityData] = useState<any[]>([]);
  const [stateData, setStateData] = useState<any[]>([]);
  const [addressData, setAddressData] = useState<any[]>([]);
  const [firmData, setFirmData] = useState<any[]>([]);
  const [officeData, setOfficeData] = useState<any[]>([]);
  const [jurisdictionGroupData, setJurisdictionGroupData] = useState<any[]>([]);
  const [loadingStates, setLoadingStates] = useState(false);
  const [selectedOfficeType, setSelectedOfficeType] = useState(
    state?.officeType ?? ""
  );
  const [selectedCountry, setSelectedCountry] = useState({ id: "", name:"" });
  const [selectedCity, setSelectedCity] = useState({ id: "", name: "", countryName: "" });
  const [selectedState, setSelectedState] = useState({ id: "", name: "" });
  const [selectedAddress, setSelectedAddress] = useState({ id: "", name: "" });
  const [selectedFirm, setSelectedFirm] = useState({ id: "", name: "" });
  const [primaryType, setPrimaryType] = useState(false);
  const [selectedJurisdiction, setSelectedJurisdiction] = useState({
    id: "",
    name: "",
  });
  const [selectedJurisdictionGroup, setSelectedJurisdictionGroup] = useState({
    id: "",
    name: "",
  });
  const [faxNumber, setFaxNumber] = useState<IPhoneNumber>(
    state?.faxNumber ? state.faxNumber : { code: "", phone: "", basecode: "" });
  const [phoneNumber, setPhoneNumber] = useState<IPhoneNumber>(
    state?.phoneNumber ? state.phoneNumber : { code: "", phone: "", basecode: "" });
  const [people, setPeople] = useState<string>("");
  const [overview, setOverview] = useState<string>(state?.overview || "");
  const [regionalType, setRegionalType] = useState(false);
  const [multipleType, setMultipleType] = useState(false);
  const [mainFirmOverview, setMainFirmOverview] = useState(false);
  const [regionName, setRegionName] = useState<string>("");
  const { regionData } = UseRegionList();
  const { countryData } = UseCountryList();
  const { jurisdictionData } = UseJurisdictionList();

  const getProfessionalsCount = async () => {
    try {
      const response = await axios.Get("/person");
      if (response && response.data) {
        const filtered = response.data.filter(
          (person: any) => person.mainOffice === office.id
        ).length;
        setPeople(filtered?.toString());
      }
    } catch (error) { }
  };

  useEffect(() => {
    getProfessionalsCount();
  }, [office]);

  useEffect(() => {
    if (!countryData.length || !state?.country) return 
    const _selectedCountry = countryData.find((country: any) => country.id === state.country);
    setSelectedCountry(_selectedCountry);
    setRegionName(getNameById(_selectedCountry.regions.map((r:any) => r.id), regionData).join(', '));
  }, [countryData, state])

  useEffect(() => {
    if (selectedState.id) {
      getAllCities().then((data) => {
        if (data != null) {
          const newCities = data.filter(
            (item: any) => item.state === selectedState.id
          );
          setCityData(newCities);
        }
      });
    }
  }, [selectedState.id, loadingStates]);



  function transformAddressData(newAddress: any) {
    return newAddress.map((address: any) => {
      return {
        ...address,
        name: address ? address.address1 : null,
      };
    });
  }

  useEffect(() => {
    if (!selectedState.id && stateData.length > 0) {
      const filteredState = stateData.find((stateOffice: any) => stateOffice.name === state?.stateName);
      if (filteredState) {
        setSelectedState({ id: filteredState.id, name: filteredState.name });
      } else {
        console.warn("No matching state found");
      }
    }
  }, [selectedState, stateData]);


  useEffect(() => {
    const fetchAddresses = async () => {
      let queryString = "/address";
      queryString +=
        selectedCity.id === ""
          ? ""
          : "?&cities=" + JSON.stringify([selectedCity.id]);
      const res = await axios.Get(queryString);
      const newData = transformAddressData(res.data);
      const newAddresses = newData.filter((item: any) => item.city === selectedCity.id);
      setAddressData(newAddresses);

      const filteredAddress = newData.find((address: any) => address.id === state?.address);
      if (filteredAddress) {
        setSelectedAddress({ id: filteredAddress.id, name: filteredAddress.name });
      }
    };

    if (selectedCity.id !== "") {
      fetchAddresses();
    }

  }, [selectedCity]);


  useEffect(() => {
    if (
      errors.firm.error ||
      errors.name.error ||
      errors.country.error ||
      errors.state.error ||
      errors.city.error ||
      errors.address.error ||
      errors.jurisdiction.error ||
      errors.jurisdictionGroup.error ||
      errors.officeType.error ||
      errors.phoneNumber.error
    )
      validateForm();
  }, [
    office,
    selectedOfficeType,
    selectedCity,
    selectedCountry,
    selectedState,
    selectedFirm,
    selectedAddress,
    selectedJurisdiction,
    selectedJurisdictionGroup,
    phoneNumber,
  ]);

  useEffect(() => {
    if (
      !errors.firm.error &&
      !errors.name.error &&
      !errors.country.error &&
      !errors.state.error &&
      !errors.city.error &&
      !errors.address.error &&
      !errors.jurisdiction.error &&
      !errors.jurisdictionGroup.error &&
      !errors.officeType.error &&
      !errors.phoneNumber.error
    )
      setSaveOverrideOpen(false);
  }, [errorFields]);

  useEffect(() => {
    if (!selectedFirm?.id) return
    const firm = firmData.find((firm) => firm.id === selectedFirm.id);
    setPrimaryType(firm.membershipType === "Primary");
    const jurisdictionGroup = jurisdictionGroupData.find(
      (jur) => jur.id === firm.jurisdictionGroup
    );
    setSelectedJurisdictionGroup({
      id: jurisdictionGroup?.id,
      name: jurisdictionGroup?.name,
    });
  }, [selectedFirm]);

  useEffect(() => {
    if (officeData.length === 0) {
      const fetchOfficeData = async () => {
        try {
          const response = await axios.Get("./office");
          const data = response.data;
          if (data != null) {
            setOfficeData(data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchOfficeData();
    }

    const fetchFirms = async () => {
      const res = await axios.Get("/firm?withWSG=true");
      setFirmData(res.data);
      const filteredFirm = res.data.find((firm: any) => firm.id === state?.firm)
      setSelectedFirm({ id: filteredFirm?.id || '', name: filteredFirm?.name || '' });
    };

    const fetchCities = async () => {
      const res = await axios.Get("/city");
      if (res.data) {
        setCityData(res.data);
        const filteredCountry = res.data.find((country: any) => country.id === state?.city)
        setSelectedCity({
          id: filteredCountry?.id ?? "",
          name: filteredCountry?.name ?? "",
          countryName: filteredCountry?.countryName ?? "",
        });
      }
    };



    setErrors({
      name: { error: false, msj: "" },
      jurisdiction: { error: false, msj: "" },
      jurisdictionGroup: { error: false, msj: "" },
      address: { error: false, msj: "" },
      country: { error: false, msj: "" },
      state: { error: false, msj: "" },
      city: { error: false, msj: "" },
      officeType: { error: false, msj: "" },
      phoneNumber: { error: false, msj: "" },
      firm: { error: false, msj: "" },
      yearFounded: { error: false, msj: "" },
    });

    fetchFirms();
    fetchCities();
  }, []);

  const fetchStates = async () => {
    try {
      const states = await getStates();
      if (states) {
        const newStates = states.filter((item: any) => item.country === selectedCountry.id);
        setStateData(newStates);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };



  useEffect(() => {
    if (selectedCountry.id) {
      fetchStates();
    }
  }, [selectedCountry]);

  const validateForm = () => {
    let valid = true;
    let newerrors: errors = errors;
    const firm = firmData.find((firm: any) => firm.id === selectedFirm.id);
    const jurisdiction = jurisdictionData.find(
      (jurisdiction: any) => jurisdiction.id === selectedJurisdiction.id
    );
    const firmMembershipType = firm ? firm.membershipType : "";
    const editFirm = props.mode === "edit" ? state.firmId : "";

    if (selectedOfficeType === "Main") {
      const firm = firmData.find((firm: any) => firm.id === selectedFirm.id);
      const firmMembershipType = firm ? firm.membershipType : "";

      if (firm) {
        if (firmMembershipType === "Primary") {
          if (editFirm !== selectedFirm.id && firmMainOfficeCount > 0) {
            newerrors.officeType = {
              error: true,
              msj: "Main Offices for this firm has reached its limit. Please change the Office Type.",
            };
          } else if (selectedCountry.id !== (jurisdictionData.find((jurisdiction: any) => jurisdiction.id === firm?.jurisdiction)?.country)) {
            newerrors.officeType = {
              error: true,
              msj: "Main Offices must be in a Membership Location. Please update Office Type or change the Address selected.",
            };
          } else {
            newerrors.officeType = { error: false, msj: "" };
          }
        } else if (
          firmMembershipType === "Regional" ||
          firmMembershipType === "Multiple"
        ) {
          if (
            editFirm !== selectedFirm.id &&
            firmMainOfficeCount >= jurisdictionsInJGCount.length
          ) {
            newerrors.officeType = {
              error: true,
              msj: "Main Offices for this firm has reached its limit. Please change the Office Type.",
            };
          } else if (selectedCountry.id !== firm.jurisdiction.countryId) {
            newerrors.officeType = {
              error: true,
              msj: "Main Offices must be in a Membership Location. Please update Office Type or change the Address selected.",
            };
          } else {
            newerrors.officeType = { error: false, msj: "" };
          }
        }
      } else {
        newerrors.officeType = { error: true, msj: "Firm is required to be selected." };
      }
    } else if (selectedOfficeType === "" || selectedOfficeType === null) {
      newerrors.officeType = { error: true, msj: "Office Type is required" };
    } else {
      newerrors.officeType = { error: false, msj: "" };
    }

    newerrors.firm =
      selectedFirm.name === ""
        ? { error: true, msj: "Firm is required" }
        : { error: false, msj: "" };
    newerrors.country =
      selectedCountry.name === ""
        ? { error: true, msj: "Country is required" }
        : { error: false, msj: "" };
    newerrors.state =
      selectedState.name === ""
        ? { error: true, msj: "State is required" }
        : { error: false, msj: "" };
    newerrors.city =
      selectedCity.name === ""
        ? { error: true, msj: "City is required" }
        : { error: false, msj: "" };
    newerrors.address =
      selectedAddress.name === ""
        ? { error: true, msj: "Address is required" }
        : { error: false, msj: "" };
    newerrors.name =
      office.name === ""
        ? { error: true, msj: "Name is required" }
        : { error: false, msj: "" };
    newerrors.jurisdiction =
      selectedJurisdiction.name === ""
        ? { error: true, msj: "Jurisdiction is required" }
        : { error: false, msj: "" };
    newerrors.jurisdictionGroup =
      (firmMembershipType === "Regional" ||
        firmMembershipType === "Multiple") &&
        selectedJurisdictionGroup.name === ""
        ? { error: true, msj: "Jurisdiction Group is required" }
        : { error: false, msj: "" };
    newerrors.phoneNumber =
      phoneNumber.phone?.trim() === "" ||
        phoneNumber.code === "" ||
        phoneNumber.code === undefined
        ? { error: true, msj: "Phone Number is required" }
        : { error: false, msj: "" };
    newerrors.yearFounded = 
      (!office.yearFounded || (office.yearFounded ?? 0) > 1800 && (office.yearFounded ?? 0) < currentYear)
      ? { error: false, msj: "" }
      : { error: true, msj: `Year must be between 1800 and ${currentYear}` };

        setErrors(newerrors);

        // Verifica si todos los campos son válidos
        valid = Object.values(newerrors).every((error) => !error.error);
        setErrorFields(Object.keys(newerrors).filter((key) => newerrors[key as keyof typeof newerrors].error));
      
        // Si no es válido, abre el modal de sobreescritura de guardado
        if (!valid) setSaveOverrideOpen(true);
      
        return valid;
  };

  const handleButtonClick = (action: string) => {
    setButtonClicked(action);
  };

  useEffect(() => {
    const filteredJurisdiction = jurisdictionData.find((jurisdiction: any) => jurisdiction.id === state?.jurisdiction)
    setSelectedJurisdiction({
      id: filteredJurisdiction?.id ?? "",
      name: filteredJurisdiction?.name ?? "",
    });
  }, [selectedFirm, firmData, jurisdictionData]);

  useEffect(() => {
    if (!firmData.length || !jurisdictionData.length || !selectedFirm.id) return;
    const firm = firmData.find((firm) => firm.id === selectedFirm.id);
    if (firm) {
      if (firm.membershipType !== "Primary") {
        const numberOfJurisdictionsInJG = jurisdictionData.filter(
          (jurisdiction: any) =>
            jurisdiction.partOfMulti === true &&
            jurisdiction.jurisdictionGroupId === firm.jurisdictionGroupId
        );
        setJurisdictionsInJGCount(numberOfJurisdictionsInJG);
      }

      const officesInFirm = officeData.filter(
        (office: any) =>
          office.officeType !== "Additional" && office.firmId === firm.id
      );
      setfirmMainOfficeCount(officesInFirm.length);
    }
  }, [selectedFirm, firmData, jurisdictionData]);

  const handleDelete = async (id: string) => {
    const response = await axios.Delete(`/Office`, id);

    if (response.status === 200) {
      logActivity(
        "DELETE_OFFICE",
        location.pathname,
        JSON.stringify(state ? state : {})
      );
      navigate("/Staff/Catalogs/Office");
    } else {
      console.error("Error al eliminar el elemento:", response.data);
    }
  };

  const handleChangePhone = (obj: any) => {
    setPhoneNumber({
      code: obj.code,
      phone: obj.phone,
      basecode: obj.basecode,
    });
  };

  const handleChangeFax = (obj: any) => {
    setFaxNumber({ code: obj.code, phone: obj.phone, basecode: obj.basecode });
  };

  const handleChangeMainFirmOverview = () => {
    if (!mainFirmOverview){
      officeData?.find((office) => {
        if (office.firm === selectedFirm?.id && office.officeType === "Main") {
          setOverview(office.overview);
        }
      });
    }else{
      setOverview("");
    }

    setMainFirmOverview(!mainFirmOverview);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (buttonClicked === "delete") {
      axios.Delete("office", office.id ?? "").then(() => {
        navigate("/Staff/Catalogs/Office");
      });
    } else if (buttonClicked === "saveOverride") {
      if (props.mode === "add") {
        handleSave(e);
      } else {
        handleUpdate(e);
      }
    } else if (validateForm()) {
      if (buttonClicked === "saveButton") {
        handleSave(e);
      } else if (buttonClicked === "updateButton") {
        handleUpdate(e);
      }
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setOffice({ ...office, [e.target.name]: e.target.value });
  };

  const buildOfficeObjet = () => {
    return {
      ...state,
      name: office.name ?? '',
      officeType: selectedOfficeType,
      countOfPeople: Number(office.countOfPeople) ?? null,
      jurisdiction: selectedJurisdiction.id,
      jurisdictionName: selectedJurisdiction.name,
      jurisdictionGroup: selectedJurisdictionGroup.id,
      jurisdictionGroupName: selectedJurisdictionGroup.name,
      firm: selectedFirm.id,
      firmName: selectedFirm.name,
      address: selectedAddress.id,
      addressName: selectedAddress.name,
      city: selectedCity.id,
      cityName: selectedCity.name,
      country: selectedCountry.id,
      countryName: selectedCountry.name,
      state: selectedState.id,
      stateName: selectedState.name,
      phoneNumber,
      faxNumber,
      yearFounded: Number(office.yearFounded),
      overview,
      regionName,
      createdDate: state?.createdDate,
    }
  }

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const officeData: IOffice = buildOfficeObjet();
    officeData.createdDate = getCurrentTimestamp();
    const res = await axios.Post("Office", officeData);
    if (res.status === 200) {
      if (res.data.error) {
        if (res.data.error[0].includes("Address")) {
          setErrors({
            ...errors,
            address: {
              error: true,
              msj: "Address is already assigned to an Office. Please select a different one.",
            },
          });
        }
        if (res.data.error[0].includes("Name")) {
          setErrors({
            ...errors,
            name: {
              error: true,
              msj: "Name is already assigned to an Office. Please select a different one.",
            },
          });
        }
      } else {
        setTimeout(() => {
          logActivity(
            "CREATE_OFFICE",
            location.pathname,
            JSON.stringify(res.data)
          );
          navigate("/Staff/Catalogs/Office");
        }, 500);
      }
    } else {
      console.error(res.data);
    }
  };

  const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newOffice: IOffice = buildOfficeObjet();
    await axios
      .Put("Office", newOffice)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.error) {
            if (
              res.data.error[0].includes("Address") ||
              res.data.error[0].includes("Duplicate")
            ) {
              setErrors({
                ...errors,
                address: {
                  error: true,
                  msj: "Address is already assigned to an Office. Please select a different one.",
                },
              });
            }
          } else {
            setTimeout(() => {
              logActivity(
                "UPDATE_OFFICE",
                location.pathname,
                JSON.stringify({
                  prevState: JSON.stringify(state),
                  newState: JSON.stringify(res.data),
                })
              );
              if (state.name !== office.name) {
                updateData('representativeClient', 'officeName', state.name, office.name);
              }
              navigate("/Staff/Catalogs/Office");
            }, 500);
          }
        } else {
          console.error(res.data);
        }
      });
  };

  const handleSetSelectedCountry = async (e: any) => {
    setSelectedCountry(e);
    if (e.id !== selectedCountry.id) {
      setSelectedState({ id: "", name: "" });
      setSelectedCity({ id: "", name: "", countryName: "" });
      setSelectedAddress({ id: "", name: "" });
    }
  };

  const handleSetSelectedState = (e: any) => {
    setSelectedState(e);
    // Reiniciar la ciudad solo si se cambia el estado
    if (e.id !== selectedState.id) {
      setSelectedCity({ id: "", name: "", countryName: "" });
      setSelectedAddress({ id: "", name: "" });
    }
  };

  const handleSetSelectedFirm = (e: any) => {
    setSelectedFirm(e);
    setSelectedJurisdiction({ id: "", name: "" });
    setSelectedJurisdictionGroup({ id: "", name: "" });
    setOffice({ ...office, firm: e.id });

    if (e.id !== "") {
      const firm = firmData.find((firm) => firm.id === e.id);
      if (firm) {
        const jurisdiction = jurisdictionData.find(
          (jur) => jur.id === firm.jurisdiction
        );
        const jurisdictionGroup = jurisdictionGroupData.find(
          (jur) => jur.id === firm.jurisdictionGroup
        );

        setPrimaryType(firm.membershipType === "Primary");
        setRegionalType(firm.membershipType === "Regional");
        setMultipleType(firm.membershipType === "Multiple Primary" || firm.membershipType === "Multiple");

        if (jurisdiction) {
          setSelectedJurisdiction({
            id: jurisdiction.id,
            name: jurisdiction.name,
          });
        } else {
          setSelectedJurisdiction({ id: "", name: "" });
        }

        if (jurisdictionGroup) {
          setSelectedJurisdictionGroup({
            id: jurisdictionGroup.id,
            name: jurisdictionGroup.name,
          });
        } else {
          setSelectedJurisdictionGroup({ id: "", name: "" });
        }
      }
    }
  };

  const handleSetSelectedCity = (e: any) => {
    setSelectedCity(e);
    setSelectedAddress({ id: "", name: "" });
    setOffice({ ...office, city: e.id, name: e.name });
  };

  const handleSetSelectedJurisdictionGroup = (e: any) => {
    setSelectedJurisdictionGroup(e);
    setOffice({ ...office, jurisdictionGroup: e.id });
  };

  const handleSetSelectedJurisdiction = (e: any) => {
    setSelectedJurisdiction(e);
    setOffice({ ...office, jurisdiction: e.id });
  };

  const handleSetSelectedAddress = (e: any) => {
    setSelectedAddress(e);
    setOffice({ ...office, address: e.id });
  };

  const handleSetSelectedOfficeType = (e: any) => {
    setSelectedOfficeType(e);
    setOffice({ ...office, officeType: e });
  };

  const handleOverviewChange = (e: any) => {
    setOverview(e.target.value);
  };

  return (
    <>
      <main
        className={`mainSection ${saveOverrideOpen ? "pt-7 md:pt-9 " : ""}`}
      >
        <form onSubmit={handleSubmit}>
          <SaveOverride
            fields={errorFields}
            handleButtonClick={handleButtonClick}
            open={saveOverrideOpen}
            close={() => {
              setSaveOverrideOpen(!saveOverrideOpen);
            }}
          />
          <CatalogTitle
            title={`${props.mode === 'add' ? 'New Office' : `Edit ${state?.name || ''}`}`}
            className="text-Default"
          >
            <GoBack />
          </CatalogTitle>
          <article className="articleSection">
            <section className="formsectionNTitle">
              <CatalogSubTitle
                title=" Catalog List Relationships"
                className="text-accent-Default-dark"
              />
              <div className="formsection grid grid-cols-12 gap-5 w-full">
                <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
                  <DropDownList
                    title="Firm"
                    required={true}
                    data={firmData}
                    selectedObjst={selectedFirm.name}
                    seter={handleSetSelectedFirm}
                    error={errors.firm.error}
                    errormsj={errors.firm.msj}
                    getid
                  />
                </div>
                <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
                  <DropDownList
                    title="Jurisdiction"
                    required={true}
                    data={
                      !primaryType && selectedFirm.id !== ""
                        ? jurisdictionsInJGCount
                        : jurisdictionData
                    }
                    disabled={primaryType}
                    selectedObjst={selectedJurisdiction.name}
                    seter={handleSetSelectedJurisdiction}
                    error={errors.jurisdiction.error}
                    errormsj={errors.jurisdiction.msj}
                    getid
                  />
                </div>
                <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
                  <DropDownList
                    title={primaryType ? "Jurisdiction Group" :  regionalType ? "Regional Membership Group" : multipleType ? "Multiple Primary Membership Group" : "Jurisdiction Group"}
                    required={true}
                    data={jurisdictionGroupData}
                    disabled={true}
                    selectedObjst={selectedJurisdictionGroup.name}
                    seter={handleSetSelectedJurisdictionGroup}
                    error={errors.jurisdictionGroup.error}
                    errormsj={errors.jurisdictionGroup.msj}
                    getid
                  />
                </div>
                <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
                  <DropDownList
                    title="Country"
                    required={true}
                    data={countryData}
                    selectedObjst={selectedCountry.name}
                    seter={handleSetSelectedCountry}
                    error={errors.country.error}
                    errormsj={errors.country.msj}
                    getid
                  />
                </div>
                <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
                  <DropDownList
                    title="State"
                    required={true}
                    disabled={selectedCountry.name === ""}
                    data={stateData}
                    selectedObjst={selectedState.name}
                    seter={handleSetSelectedState}
                    error={errors.state.error}
                    errormsj={errors.state.msj}
                    getid
                  />
                </div>
                <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
                  <DropDownList
                    title="City"
                    required={true}
                    disabled={selectedState.name === ""}
                    data={cityData}
                    selectedObjst={selectedCity.name}
                    seter={handleSetSelectedCity}
                    error={errors.city.error}
                    errormsj={errors.city.msj}
                    getid
                  />
                </div>
                <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
                  <DropDownList
                    title="Address"
                    required={selectedCity.name !== ""}
                    disabled={selectedCity.name === ""}
                    data={addressData}
                    selectedObjst={selectedAddress.name}
                    seter={handleSetSelectedAddress}
                    error={errors.address.error}
                    errormsj={errors.address.msj}
                    getid
                  />
                </div>
              </div>
            </section>
            <section className="formsectionNTitle">
              <CatalogSubTitle
                title="Office Information"
                className="text-accent-Default-dark"
              />
              <div className="formsection grid grid-cols-12 gap-5 w-full">
                <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
                  <Input
                    title="Name"
                    name="name"
                    error={errors.name.error}
                    errorMsj={errors.name.msj}
                    value={office.name}
                    onChange={handleInputChange}
                    required={true}
                  />
                </div>
                <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
                  <DropDownList
                    title="Office Type"
                    required={true}
                    selectedObjst={selectedOfficeType}
                    seter={handleSetSelectedOfficeType}
                    data={[
                      { id: "Main", name: "Main" },
                      { id: "Additional", name: "Additional" },
                    ]}
                    error={errors.officeType.error}
                    errormsj={errors.officeType.msj}
                  />
                </div>
                <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
                  <Input
                    title="Professionals in Office"
                    name="countOfPeople"
                    error={false}
                    value={people ? people : office?.countOfPeople?.toString()}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
                  <PhoneNumber
                    title="Phone Number"
                    Obj={phoneNumber}
                    seter={handleChangePhone}
                    required={true}
                    error={errors.phoneNumber.error}
                    errormsj={errors.phoneNumber.msj}
                  />
                </div>
                <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
                  <PhoneNumber
                    title="Fax Number"
                    error={false}
                    Obj={faxNumber}
                    seter={handleChangeFax}
                  />
                </div>
                <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
                  <Input
                    title="Year Founded"
                    name="yearFounded"
                    error={errors.yearFounded.error}
                    errorMsj={errors.yearFounded.msj}
                    value={office.yearFounded?.toString()}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="w-full">
                <TextArea
                  title="Overview"
                  error={false}
                  onChange={handleOverviewChange}
                  value={overview}
                  characterCount
                  maxChars={3500}
                  disabled={mainFirmOverview}
                />
                <SwitchToogle
                  seter={handleChangeMainFirmOverview}
                  checked={mainFirmOverview}
                  title="Use Main Firms Overview"
                />
              </div>
            </section>
            <ButtonsCrud
              Catalog="Office"
              mode={props.mode}
              id={id ? id : ""}
              disabled={
                errors.firm.error ||
                errors.name.error ||
                errors.country.error ||
                errors.state.error ||
                errors.city.error ||
                errors.address.error ||
                errors.jurisdiction.error ||
                errors.jurisdictionGroup.error ||
                errors.officeType.error ||
                errors.phoneNumber.error
              }
              actionButton={handleButtonClick}
              onDelete={handleDelete}
              state={state}
              newState={buildOfficeObjet()}
              customFieldNames={{
                ['phoneNumber.phone']:'Phone Number',
                ['faxNumber.phone']:'Fax Number',
              }}
            />
          </article>
        </form>
      </main>
    </>
  );
};

export default AddOffice;
