const Lists = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="2 2 20 20"
      className={props.className}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M7.673 17.2693H9.44225V15.5H7.673V17.2693ZM7.673 13.3845H9.44225V11.6155H7.673V13.3845ZM7.673 9.5H9.44225V7.73075H7.673V9.5ZM11.5963 17.1345H17.2885V15.6348H11.5963V17.1345ZM11.5963 13.25H17.2885V11.75H11.5963V13.25ZM11.5963 9.36525H17.2885V7.8655H11.5963V9.36525ZM4 21V4H21V21H4ZM5.5 19.5H19.5V5.5H5.5V19.5Z"/>
    </svg>
  );
}

export default Lists;
