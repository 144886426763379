import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../../utils/axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../../assets/css/AddCatalogs.css";
import Input from "../../../../components/Atomos/Inputs/Input";
import ButtonsCrud from "../../../../components/Catalogs/ButtonsCrud";
import GoBack from "../../../../components/Moleculas/GoBack";
import CatalogSubTitle from "../../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import CatalogTitle from "../../../../components/Moleculas/Catalog Text/CatalogTitle";
import CalendarInput from "../../../../components/Atomos/Inputs/CalendarInput";
import TextArea from "../../../../components/Atomos/Inputs/TextArea";
import { deleteFirmNote } from "../../../../Services/FirmNote";
import { useLogging } from "../../../../Context/LoggingContext";
import BackgroundComponent from "../../../../components/Layout/BackgroundProfile/BackgroundProfile";
import ManageFirmBackground from "../../../../assets/images&icons/bg-manageFirm.png";
import CurrentPage from "../../../../components/Layout/CurrentPageTab/CurrentPage";
import { parse } from "path";
import { set } from "date-fns";
import { useUpdateAndLog } from "../../../../hooks/LogActivity/LogActivityUpdates";
import { useCreateAndLog } from "../../../../hooks/LogActivity/LogActivityCreates";
import IFirmNote from "../../../../interfaces/models/IFirmNote";
import { useSelector } from "react-redux";
import { Appstore } from "../../../../redux";
const WSGDots = require("../../../../assets/images&icons/WSGDots.svg");

interface iCatalogProps {
  mode: "add" | "edit";
}

type error = { error: boolean; msj: string };
type errors = {
  name: error;
  note: error;
  noteDate: error;
};

const ManageAddNote: React.FC<iCatalogProps> = (props) => {
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);
  const axios = new Axios();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { state } = useLocation();
  const { mode } = props;
  const [firmNote, setFirmNote] = useState<IFirmNote>(
    mode === "edit"
      ? {
          firm: manageFirm.selectedFirm?.id ?? "",
          firmName: manageFirm.selectedFirm?.name?? "",
          noteDate: state?.note?.noteDate ?? "",
          note: state?.note?.note ?? "",
          name: state?.note?.name ?? "",
          id: state?.note?.id ?? "",
          dateCreated: state?.note?.dateCreated ?? "",
        }
      : { 
        firm: state?.id ?? "", 
        firmName: state?.name ?? "", 
        noteDate: new Date(), 
        note: "", 
        name: "", 
        id:"", 
        dateCreated: new Date() }
  );

  const [errors, setErrors] = useState<errors>({
    name: { error: false, msj: "" },
    note: { error: false, msj: "" },
    noteDate: { error: false, msj: "" },
  });
  const [buttonClicked, setButtonClicked] = useState("");
  const {pathname} = useLocation()
  const update = useUpdateAndLog();
  const create = useCreateAndLog();

  useEffect(() => {
    if (errors.name.error || errors.note.error || errors.noteDate.error)
      validateForm();
  }, [firmNote]);


  useEffect(() => {
    setErrors({
      name: { error: false, msj: "" },
      note: { error: false, msj: "" },
      noteDate: { error: false, msj: "" },
    });
  }, []);

  const validateForm = () => {
    let valid = true;

    // Create a new object to avoid mutating the state directly
    let newErrors: errors = {
      name: {
        error: firmNote.name === "",
        msj: firmNote.name === "" ? "Name is required" : "",
      },
      noteDate: {
        error: !firmNote.noteDate,
        msj: "Note Date is required",
      },
      note: { error: false, msj: "" },
    };

    // Set the new errors state
    setErrors(newErrors);

    // Validate if all fields are correct
    valid = Object.values(newErrors).every((error) => !error.error);

    return valid;
  };

  const handleButtonClick = (action: string) => {
    setButtonClicked(action);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (buttonClicked === "delete") {
      axios.Delete("firmNote", firmNote.id ?? "").then(() => {
        navigate("/Staff/Catalogs/FirmNote");
      });
    } else if (validateForm()) {
      if (buttonClicked === "saveButton") {
        handleSave(e);
      } else if (buttonClicked === "updateButton") {
        handleUpdate(e);
      }
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFirmNote({ ...firmNote, [e.target.name]: e.target.value });
  };

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFirmNote: IFirmNote = {
      id: firmNote.id ?? "",
      name: firmNote.name ?? "",
      firm: firmNote.firm ?? "",
      firmName: firmNote.firmName ?? "",
      note: firmNote.note ?? "",
      noteDate: new Date(firmNote.noteDate),
      dateCreated: new Date(),
    };

    const response = await create.firmNote({firmNote: newFirmNote, pathname});

    if (response !== undefined) {
      navigate(`/Staff/ManageFirm/GeneralTab/${firmNote.firm}/Notes`, {
        state: { id: firmNote?.firm, selectedTab: "GeneralTab" },
      });
    }
  };

  const buildNoteObject = () => {
    return {
      id: firmNote.id??"",
      name: firmNote.name??"",
      firm: firmNote.firm??"",
      firmName: firmNote.firmName??"",
      note: firmNote.note,
      noteDate: firmNote.noteDate,
      dateCreated: firmNote.dateCreated,
    }
  }
  const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFirmNote = buildNoteObject();
    const response = await update.firmNote({firmNote: newFirmNote, pathname, prevState: state.note});
    if (response !== undefined) {
      navigate(`/Staff/ManageFirm/GeneralTab/${firmNote.firm}/Notes`, {
        state: {
          id: firmNote?.firm,
          selectedTab: "GeneralTab",
        },
      });
    }
  };

  const handleSetSelectedNoteDate = (e: any) => {
    setFirmNote((prevState) => ({
      ...prevState,
      noteDate: new Date(e),
    }));
  };

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFirmNote((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <BackgroundComponent image={ManageFirmBackground} />

      <div className=" mainSection">
        <div className="flex flex-col pt-7 gap-5 md:gap-6 lg:gap-7 md:pt-8 lg:pt-10 pb-5">
          <div className="flex gap-4 justify-between">
            <CatalogTitle
              title={`Firm Notes`}
              className="text-white !p-0 lg:text-[52px] md:text-[44px] text-[42px]"
            />
            <div
              className="md:min-w-[20rem] min-h[4.5rem] overflow-hidden flex-1 hidden relative md:flex"
              style={{
                backgroundImage: `url(${WSGDots.default})`,
              }}
            ></div>
            <div className="text-white relative">
              <GoBack className="!text-white !fill-white" />
            </div>
          </div>
          <hr className="flex h-0 justify-end items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage
              prevPage="Manage Firm > General"
              currentPage={`Firm Notes`}
            />
          </div>
        </div>
        <div className="relative flex flex-col gap-10 justify-end ">
          <form onSubmit={handleSubmit}>
            <article className="articleSection ">
              <section className="formsectionNTitle">
                <CatalogSubTitle
                  title="Firm Note Information"
                  className="text-Default"
                />
                <div className="formsection grid grid-cols-7 gap-5 w-full">
                  <div className="grid col-span-7 md:col-span-3 lg:col-span-2">
                    <Input
                      title="Name"
                      name="name"
                      error={errors.name.error}
                      errorMsj={errors.name.msj}
                      value={firmNote.name}
                      onChange={handleInputChange}
                      required={true}
                    />
                  </div>
                  <div className="grid col-span-7 md:col-span-4 lg:col-span-3">
                    <CalendarInput
                      title="Note Date"
                      type="single"
                      error={errors.noteDate.error}
                      errorMsj={errors.noteDate.msj}
                      value={firmNote.noteDate.toString() ?? ""}
                      onChange={handleSetSelectedNoteDate}
                      required
                    />
                  </div>
                  <div className="grid col-span-7">
                    <TextArea
                      title="Note"
                      name="note"
                      maxChars={3500}
                      value={firmNote.note}
                      error={errors.note.error}
                      errorMsj="Character limit has been reached."
                      onChange={handleTextAreaChange}
                      characterCount={true}
                    />
                  </div>
                </div>
              </section>

              <ButtonsCrud
                Catalog="firmNote"
                mode={props.mode}
                id={id ? id : ""}
                disabled={
                  errors.name.error ||
                  errors.note.error ||
                  errors.noteDate.error
                }
                actionButton={handleButtonClick}
                onDelete={async () => {
                  if (await deleteFirmNote(firmNote.id as string)) {
                    navigate(
                      `/Staff/ManageFirm/GeneralTab/${firmNote.firm}/Notes`,
                      {
                        state: {
                          id: firmNote?.firm,
                          selectedTab: "GeneralTab",
                        },
                      }
                    );
                  }
                }}
                state={{...state.note,noteDate: new Date(state.note.noteDate)}}
                newState={buildNoteObject()}
              />
            </article>
          </form>{" "}
        </div>
      </div>
    </>
  );
};

export default ManageAddNote;
