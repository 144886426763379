import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPracticeIndustry } from "../../../interfaces/models/IPracticeIndustry";

const initialState: IPracticeIndustry[] = [];

export const practiceIndustriesSlice = createSlice({
  name: "practiceIndustries",
  initialState,
  reducers: {
    SetPracticeIndustries: (_, action: PayloadAction<IPracticeIndustry[]>) => action.payload,
    AddPracticeIndustry: (state, action: PayloadAction<IPracticeIndustry>) => {
      state.push(action.payload);
    },
    UpdatePracticeIndustry:(state, action: PayloadAction<IPracticeIndustry>) => {
      const index = state.findIndex((pi) => pi.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },
    DeletePracticeIndustry: (state, action: PayloadAction<string>) => {
      return state?.filter(
        (practiceIndustry) => practiceIndustry.id !== action.payload
      );
    },
  },
});

export const {
  SetPracticeIndustries,
  AddPracticeIndustry,
  UpdatePracticeIndustry,
  DeletePracticeIndustry,
} = practiceIndustriesSlice.actions;
