const Blogs = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="2 2 20 20"
      className={props.className}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M7.75 17.25H14.25V15.75H7.75V17.25ZM7.75 13.25H17.25V11.75H7.75V13.25ZM7.75 9.25H17.25V7.75H7.75V9.25ZM4 21V4H21V21H4ZM5.5 19.5H19.5V5.5H5.5V19.5Z" />
    </svg>
  );
}

export default Blogs;
