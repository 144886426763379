import React, { useState } from "react";
import Input from "../../Atomos/Inputs/Input";
import CatalogFilterButtons from "../CatalogFilterButtons";
import DDMultiple from "../../Atomos/DropDown/DDMultiple";
import { Table } from "@tanstack/react-table";
import { IProfessionalProfileFilters } from "../../../interfaces";
import IOffice from "../../../interfaces/models/IOffice";

interface IContentProps {
  tableInstance: Table<unknown>;
  filters: IProfessionalProfileFilters;
  headers: string[]
  setFilters: React.Dispatch<React.SetStateAction<IProfessionalProfileFilters>>;
  officeData: IOffice[];
  clearSelections: () => void;
  Search: () => void;
}
const ProfessionalProfileFilter: React.FC<IContentProps> = ({
  filters,
  Search,
  setFilters,
  headers,
  tableInstance,
  clearSelections,
  officeData,
}) => {


  const [booleanData] = useState<any[]>([
    { id: "yes", name: "Yes" },
    { id: "no", name: "No" },
  ]);

  const handleFilters = (field: string, value:any) => {
    setFilters({...filters, [field]: value})
  }

  return (
    <div className="flex formsection flex-col filtersbox w-full">
      <div className="grid grid-cols-4 formsection">
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <Input
            title="By Name:"
            name="inputName"
            value={filters.name}
            onChange={(e: any) => handleFilters('name', e.target.value)}
            error={false}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple
            title="By Office:"
            objects={officeData}
            selectedObjst={filters.offices}
            seter={(e) => handleFilters('offices', e)}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <Input
            title="By Title:"
            name="titleName"
            value={filters.title}
            onChange={(e: any) => handleFilters('title', e.target.value)}
            error={false}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple
            title="Searchable:"
            objects={booleanData}
            selectedObjst={filters.isSearchable}
            seter={(e) => handleFilters('isSearchable', e)}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple
            title="Multiple Offices:"
            objects={booleanData}
            selectedObjst={filters.multipleOffice}
            seter={(e) => handleFilters('multipleOffice', e)}
          />
        </div>
      </div>

      <div className="flex items-end justify-end">
        <CatalogFilterButtons
          onClickSearch={Search}
          handleFormReset={clearSelections}
          headers={headers}
          tableInstance={tableInstance}
        />
      </div>
    </div>
  );
};

export default ProfessionalProfileFilter;
