import React from "react";

function Groups() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor">
      <path d="M-.227 24.226v-2.001c0-.927.484-1.678 1.45-2.254.966-.576 2.229-.864 3.79-.864.2 0 .386.002.557.007.171.005.348.02.53.044a6.368 6.368 0 00-.46 1.294c-.104.44-.156.9-.156 1.378v2.396h-5.71zm8 0v-2.393c0-.749.198-1.433.593-2.053.395-.62.961-1.16 1.697-1.616.736-.457 1.61-.803 2.622-1.038 1.011-.235 2.114-.352 3.31-.352 1.217 0 2.329.117 3.334.352s1.879.58 2.621 1.038c.736.457 1.3.995 1.69 1.615.39.62.586 1.305.586 2.054v2.393H7.773zm18.735 0v-2.4a6.19 6.19 0 00-.575-2.665c.181-.027.36-.042.533-.047.174-.005.352-.007.534-.007 1.577 0 2.843.285 3.796.854.953.57 1.43 1.327 1.43 2.272v1.993h-5.718zm-16.094-2.377h11.171v-.2c-.1-.76-.662-1.369-1.683-1.828-1.022-.46-2.323-.69-3.903-.69s-2.88.233-3.902.7c-1.021.465-1.582 1.082-1.683 1.851v.167zm-5.425-3.685a2.352 2.352 0 01-1.734-.729 2.399 2.399 0 01-.72-1.75c0-.686.24-1.269.724-1.75a2.38 2.38 0 011.74-.72c.683 0 1.267.24 1.754.721a2.38 2.38 0 01.73 1.76c0 .67-.241 1.25-.725 1.737a2.399 2.399 0 01-1.769.73zm22 0a2.352 2.352 0 01-1.734-.729 2.399 2.399 0 01-.72-1.75c0-.686.24-1.269.724-1.75a2.38 2.38 0 011.74-.72c.683 0 1.267.24 1.754.721a2.38 2.38 0 01.73 1.76c0 .67-.241 1.25-.725 1.737a2.399 2.399 0 01-1.769.73zm-10.98-2.372c-1.177 0-2.177-.411-3-1.235-.824-.823-1.236-1.823-1.236-3 0-1.19.412-2.19 1.236-3 .823-.811 1.823-1.217 3-1.217 1.19 0 2.19.406 3 1.216.811.81 1.217 1.811 1.217 3.001 0 1.177-.406 2.177-1.216 3-.81.824-1.811 1.236-3.001 1.236zm.012-2.376c.513 0 .95-.181 1.308-.543.36-.362.539-.805.539-1.33 0-.524-.182-.963-.546-1.316a1.816 1.816 0 00-1.31-.529c-.526 0-.968.178-1.325.533a1.786 1.786 0 00-.537 1.32c0 .516.177.955.531 1.32.355.363.801.545 1.34.545z"></path>
    </svg>
  );
}

export default Groups;
