import { createSlice } from "@reduxjs/toolkit";

export const menuSlice = createSlice({
  name: "menu",
  initialState: {
    sideMenuIsOpen: false,
    extraMenuIsOpen: false,
  },
  reducers: {
    setSideMenuIsOpen: (state, action) => {
      state.sideMenuIsOpen = action.payload;
    },
    toggleSideMenuIsOpen: (state) => {
      state.sideMenuIsOpen = !state.sideMenuIsOpen;
    },
    setExtraMenuIsOpen: (state, action) => {
      state.extraMenuIsOpen = action.payload;
    },
    toggleExtraMenuIsOpen: (state) => {
      state.extraMenuIsOpen = !state.extraMenuIsOpen;
    },
  },
});

export const {
  setSideMenuIsOpen,
  toggleSideMenuIsOpen,
  setExtraMenuIsOpen,
  toggleExtraMenuIsOpen,
} = menuSlice.actions;

export default menuSlice.reducer;