export enum DataAdminType {
  primary = 0,
  dedicated = 1,
}

interface IDataAdmin {
  id: string;
  firm: string;
  firmName: string;
  type: DataAdminType;
  person?: string;
  personName?: string;
  membershipLocation?: string;
  membershipLocationName?: string;
  subscription: boolean;
  createdAt: number;
}

export default IDataAdmin;