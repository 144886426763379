import { useEffect, useState } from "react";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import StateFilter from "../../components/Catalogs/Filters/StateFilter";
import { IStateFilterProps } from "../../interfaces/Catalogs/Filters/ICatalogsFilters";
import {
  createColumnHelper,
  Table,
} from "@tanstack/react-table";
import { IState } from "../../interfaces";
import ShapeEdit from "../../components/Atomos/Icons/ShapeEdit";
import { Link } from "react-router-dom";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import { useDispatch } from "react-redux";
import { formatTimeSpanToString } from "../../utils/functions";
import { stateSlice } from "../../redux";
import useDataLoader2 from '../../hooks/useDataLoader2';
import { UseCountryList } from "../../hooks/Catalogs";
import { UseRegionList } from "../../hooks/Catalogs/useRegionListOptions";
import { ColumnConfig } from "../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport";
import { useColumnConfig } from "../../hooks/Table/UseColumnSettings";
import { defaultPageSize } from '../../utils/const';
type tablebase = {
  count: number;
  edit: boolean;
};

const columnHelper = createColumnHelper<tablebase & IState>();

const State = () => {
  const Headers = ["name", "abbreviation", "country", "regions", "hasSubstate", "createdDate"];
  const dispatch = useDispatch()
  const [stateFilters, setStateFilters] = useState<IStateFilterProps>({
    name: "",
    abbreviation: "",
    countries: [],
    regions: [],
  });
  const [tableInstance, setTableInstance] = useState<Table<unknown>>({} as Table<unknown>);
  const {countryData} = UseCountryList();
  const {regionData} = UseRegionList();
  const [stateData, setStateData] = useState<any>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'legacyId', 
    'name',
    'abbreviation',
    'country',
    'regions',
    'countryName',
    'regionNames',
    'hasSubstate',
    'createdDate'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/State',
    defaultPageSize,
    fields
  });
  const { generateColumns } = useColumnConfig(Headers);

  const stateColumnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false },
    { id: 'name', header: "Name" },
    { id: 'abbreviation', header: "Abbreviation" },
    { id: 'country', header: "Country" },
    { id: 'regions', header: "Regions" },
    { id: 'hasSubstate', header: "Has Substate" },
    { id: 'createdDate', header: "Created Date" },
    {
      id: 'edit', header: 'Edit', cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={data[props.row.id]}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ), enableSorting: false
    },
  ];

  const columns = generateColumns(stateColumnConfigs.map(column => ({
    ...column,
    cell: column.cell || ((props: any) => {
      switch (column.id) {
        case 'count':
          return <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
        default:
          return <p>{props.getValue()}</p>;
      }
    })
  })));

  useEffect(() => {
    dispatch(stateSlice.actions.InitializeState());
  }, []);
  
  const clearFilters = () => {
    setStateFilters({ name: "", abbreviation: "", countries: [], regions: [] });
    fetchData(true)
  };

  const formatData = (data: any[]) => {
    const formatedData = data.map((d: any) => {
      return {
        ...d,
        country: d.countryName,
        hasSubstate: d.hasSubstate ? "YES" : "NO",
        regions: d.regionNames,
        createdDate: d.createdDate ? formatTimeSpanToString(d.createdDate) : ''
      }
    })
    return formatedData
  }

  useEffect(() => {
    if (data) setStateData(formatData(data))
  },[data]);

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  useEffect(() => {
    let filterAux = {
      name: {
        value: stateFilters.name,
        comparisonType: 'CONTAINS'
      },
      abbreviation: {
        value: stateFilters.abbreviation,
        comparisonType: 'CONTAINS'
      },
      country: {
        value: stateFilters.countries.map(f => f.id),
        comparisonType: 'EQUAL'
      },
      regions: {value: stateFilters.regions.map(f => f.id)},
    }
    setFilter(filterAux)
  }, [stateFilters])
  
  return (
    <>
      <main className="mainSection">
        <CatalogTableC
          title="State Catalog"
          catalogName="STATES"
          catalogShortName="STATE"
          route="State"
          isfiltered={true}
          data={stateData}
          headers={Headers}
          totalCount={totalCount}
        >
          <StateFilter
            tableInstance={tableInstance}
            Search={() => fetchData()}
            filters={stateFilters}
            setFilters={setStateFilters}
            headers={Headers}
            clearSelections={clearFilters}
          />
          <TableWithPagination
            data={stateData}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </main>
    </>
  );
};

export default State;
