export type IAddress = {
  id: string;
  address1: string;
  address2: string;
  zipCode: string;
  noZipCode: boolean;
  addressType: string;
  city: string;
  state: string;
  country: string;
  regions: any[];
  cityName: string;
  venueLink?: any;
  stateName: string;
  countryName: string;
  regionNames: string;
  createdDate: string;
  notes?: string;
  altitude?: number;
  latitude?: number;
};

export const initialValue = {
  id: "",
  address1: "",
  address2: "",
  zipCode: "",
  noZipCode: false,
  addressType: "",
  city: "",
  state: "",
  country: "",
  regions: [],
  cityName: "",
  stateName: "",
  countryName: "",
  regionNames: "",
  createdDate: "",
  notes: "",
  altitude: undefined,
  latitude: undefined,
}