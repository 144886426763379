function Percentage(props: any) {
  return (
    <div className={props.className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M7.1635 10.3268C6.28983 10.3268 5.54417 10.0179 4.9265 9.40025C4.30883 8.78275 4 8.03708 4 7.16325C4 6.28958 4.30883 5.54392 4.9265 4.92625C5.54417 4.30875 6.28983 4 7.1635 4C8.03717 4 8.78283 4.30875 9.4005 4.92625C10.0182 5.54392 10.327 6.28958 10.327 7.16325C10.327 8.03708 10.0182 8.78275 9.4005 9.40025C8.78283 10.0179 8.03717 10.3268 7.1635 10.3268ZM7.163 8.82675C7.62483 8.82675 8.01758 8.66508 8.34125 8.34175C8.66508 8.01842 8.827 7.62583 8.827 7.164C8.827 6.702 8.66533 6.30917 8.342 5.9855C8.01867 5.66183 7.626 5.5 7.164 5.5C6.70217 5.5 6.30933 5.66167 5.9855 5.985C5.66183 6.30833 5.5 6.70092 5.5 7.16275C5.5 7.62475 5.66167 8.01758 5.985 8.34125C6.30833 8.66492 6.701 8.82675 7.163 8.82675ZM15.8462 19.0095C14.9724 19.0095 14.2268 18.7007 13.6093 18.083C12.9916 17.4653 12.6827 16.7197 12.6827 15.846C12.6827 14.9723 12.9916 14.2267 13.6093 13.609C14.2268 12.9913 14.9724 12.6825 15.8462 12.6825C16.7199 12.6825 17.4656 12.9913 18.0833 13.609C18.7008 14.2267 19.0095 14.9723 19.0095 15.846C19.0095 16.7197 18.7008 17.4653 18.0833 18.083C17.4656 18.7007 16.7199 19.0095 15.8462 19.0095ZM15.8455 17.5095C16.3075 17.5095 16.7003 17.3478 17.024 17.0245C17.3477 16.7012 17.5095 16.3085 17.5095 15.8465C17.5095 15.3847 17.3478 14.9919 17.0245 14.6683C16.7012 14.3444 16.3086 14.1825 15.8468 14.1825C15.3848 14.1825 14.9919 14.3442 14.6683 14.6675C14.3446 14.9908 14.1827 15.3835 14.1827 15.8455C14.1827 16.3073 14.3444 16.7002 14.6678 17.024C14.9911 17.3477 15.3837 17.5095 15.8455 17.5095ZM5.1405 18.9133L4.09625 17.869L17.8693 4.096L18.9135 5.14025L5.1405 18.9133Z" />
      </svg>
    </div>
  );
}

export default Percentage;
