import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICountry } from "../../../interfaces";

const initialState: ICountry[] = [];

export const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    SetCountries: (_, action: PayloadAction<ICountry[]>) => action.payload,
    AddCountry: (state, action: PayloadAction<ICountry>) => {
      state.push(action.payload);
    },
    UpdateCountry: (state, action: PayloadAction<ICountry>) => {
      const index = state.findIndex((country) => country.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },    
    DeleteCountry: (state, action: PayloadAction<string>) => {
      return state?.filter((country) => country.id !== action.payload);
    },
  },
});

export const { SetCountries, AddCountry, UpdateCountry, DeleteCountry } = countriesSlice.actions;
