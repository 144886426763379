import TextArea from "../../../components/Atomos/Inputs/TextArea";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import React from "react";

type MoreInformationProps = {
  moreInformation: string;
  setMoreInformation: React.Dispatch<React.SetStateAction<string>>;
};

const MoreInformation: React.FC<MoreInformationProps> = ({
  moreInformation,
  setMoreInformation,
}) => {
  return (
    <>
      <div className="flex w-full flex-col gap-5 lg:flex-row lg:justify-between">
        <CatalogSubTitle className="text-Default" title={`More Information`} />
      </div>

      <TextArea
        title=""
        error={false}
        onChange={(e) => setMoreInformation(e.target.value)}
        value={moreInformation}
        characterCount={true}
        maxChars={2000}
      />
    </>
  );
};

export default MoreInformation;
