import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Input from "../../../components/Atomos/Inputs/Input";
import DropDownList from "../../../components/Atomos/DropDown/Dropdown";
import SwitchToogle from "../../../components/Atomos/Switch-Toogle/Switch";
import React from "react";
import { PhoneNumber } from "../../../components/Atomos/PhoneNumber/PhoneNumber";

type OfficeGeneralInformatioProps = {
  handleChangePhone: (phone: {
    code: string;
    phone: string;
    basecode: string;
  }) => void;
  handleChangeFax: (phone: {
    code: string;
    phone: string;
    basecode: string;
  }) => void;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  handleSetSelectedCountry: (e: listobj) => void;
  handleSetSelectedState: (e: listobj) => void;
  handleSetSelectedCity: (e: listobj) => void;
  handleSetSelectedAddressType: (e: string) => void;
  handleCheckBoxChange: (name: string, checked: boolean) => void;
  countryData: listobj[];
  stateData: listobj[];
  cityData: listobj[];
  selectedCountry: any;
  selectedState: any;
  selectedCity: any;
  selectedAddress: any;
  selectedAddressType: any;
  directPhoneCode: any;
  faxNumber: any;
  firmName: string;
  addressErrors: any;
  officePhoneErrors: any;
};

type listobj = { id: string; name: string };

const OfficeGeneralInformation: React.FC<OfficeGeneralInformatioProps> = ({
  handleChangeFax,
  handleChangePhone,
  handleInputChange,
  handleSetSelectedCountry,
  handleSetSelectedState,
  handleSetSelectedCity,
  handleSetSelectedAddressType,
  handleCheckBoxChange,
  countryData,
  stateData,
  cityData,
  selectedCountry,
  selectedState,
  selectedCity,
  selectedAddress,
  selectedAddressType,
  directPhoneCode,
  faxNumber,
  addressErrors,
  officePhoneErrors,
}) => {
  return (
    <>
      <CatalogSubTitle
        className="text-[#0070CD]"
        title="Office General Information"
      />
      <div className="formsection grid grid-cols-12 gap-5 w-full">
        <div className="flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
          <DropDownList
            title="Country"
            getid
            required={true}
            data={countryData}
            selectedObjst={selectedCountry.name}
            seter={handleSetSelectedCountry}
            error={addressErrors.country.error}
            errormsj={addressErrors.country.msj}
          />
        </div>
        <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
          <div className="flex flex-col w-full">
            <DropDownList
              title="State"
              data={stateData}
              getid
              selectedObjst={selectedState.name}
              tooltip={"Please Select a Country"}
              seter={handleSetSelectedState}
              error={false}
            />
          </div>
        </div>
        <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
          <div className="flex flex-col w-full">
            <DropDownList
              title="City"
              data={cityData}
              getid
              selectedObjst={selectedCity.name}
              tooltip={"Please Select a State"}
              seter={handleSetSelectedCity}
              error={false}
            />
          </div>
        </div>
      </div>
      <div className="formsection grid grid-cols-12 gap-5 w-full">
        <div className="gap-5 flex-wrap col-span-12 md:col-span-6">
          <div className=" w-full ">
            <Input
              title="Address 1"
              name="address1"
              errorMsj={addressErrors.address1.msj}
              error={addressErrors.address1.error}
              value={selectedAddress?.address1}
              onChange={handleInputChange}
              required={true}
            />
          </div>
        </div>
        <div className="gap-5 flex-wrap col-span-12 md:col-span-6">
          <div className=" w-full">
            <Input
              title="Address 2"
              name="address2"
              error={false}
              value={selectedAddress?.address2 || ""}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
          <div className=" w-full">
            <Input
              title="Zip Code"
              name="zipCode"
              errorMsj={addressErrors.zipCode.msj}
              error={addressErrors.zipCode.error}
              disabled={selectedAddress?.noZipCode === true}
              value={
                selectedAddress?.noZipCode === true
                  ? null
                  : selectedAddress?.zipCode
              }
              onChange={handleInputChange}
              required={false}
            />
          </div>
          <div className="flex items-center w-full">
            <SwitchToogle
              small
              seter={(checked: boolean) =>
                handleCheckBoxChange("noZipCode", checked)
              }
              checked={selectedAddress?.noZipCode || false}
              title="No Zip Code"
            />
          </div>
        </div>
        <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
          <div className="flex flex-col gap-1 w-full">
            <DropDownList
              title="Address Type"
              required={true}
              selectedObjst={
                selectedAddress?.addressType
                  ? selectedAddress?.addressType
                  : selectedAddressType
              }
              seter={handleSetSelectedAddressType}
              data={[
                { id: "Office", name: "Office" },
                { id: "Venue", name: "Venue" },
                { id: "Legal", name: "Legal" },
              ]}
              error={addressErrors.addressType.error}
              errormsj={addressErrors.addressType.msj}
            />
          </div>
        </div>
        <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
          <div className=" w-full">
            <Input
              title="Longitude"
              name="longitude"
              error={false}
              value={
                selectedAddress?.longitude
                  ? selectedAddress?.longitude?.toString().trim()
                  : ""
              }
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
          <div className=" w-full">
            <Input
              title="Latitude"
              name="latitude"
              error={false}
              value={
                selectedAddress?.latitude
                  ? selectedAddress?.latitude?.toString().trim()
                  : ""
              }
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-6">
          <div className=" w-full">
            <PhoneNumber
              title="Phone Number"
              Obj={directPhoneCode}
              seter={handleChangePhone}
              required={true}
              error={officePhoneErrors.phone.error}
              errormsj={officePhoneErrors.phone.msj}
            />
          </div>
        </div>
        <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-6">
          <div className=" w-full">
            <PhoneNumber
              title="Fax Number"
              error={false}
              Obj={faxNumber}
              seter={handleChangeFax}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OfficeGeneralInformation;
