import "../../../../assets/css/AddCatalogs.css";
import React, { useEffect, useState } from "react";
import CatalogSubTitle from "../../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import NewButton from "../../../../components/Atomos/Buttons/newButton";
import { useSelector } from "react-redux";
import { Appstore } from "../../../../redux";
import { formatDateToString } from "../../../../utils/functions";

interface IMainBoxProps {
  membershipDetails: any;
  setUpdateMembership: React.Dispatch<React.SetStateAction<boolean>>;
}

const MainBoxDefault: React.FC<IMainBoxProps> = ({
  setUpdateMembership,
  membershipDetails,
}) => {
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);
  const [dueDate, setDueDate] = useState<Date>();
  const firm = manageFirm.selectedFirm;
  
  useEffect(() => {
    if (!firm?.dateJoined || !firm?.membershipDues) return;
    
    const currentYear = new Date().getFullYear();
    const dueYear = firm.membershipDues === "Paid" ? currentYear : currentYear - 1;
    
    const _dueDate = new Date(firm.dateJoined);
    _dueDate.setFullYear(dueYear);
    
    setDueDate(_dueDate);
  }, [firm?.dateJoined, firm?.membershipDues]);

  const handleUpdateClick = () => {
    setUpdateMembership(true);
  };
  return (
    <>
      <section className="flex w-full flex-col">
        <div className="grid flex-row w-full gap-5 items-stretch">
          <div>
            <div className="flex flex-col gap-3 mb-6">
              <CatalogSubTitle
                title={membershipDetails?.name}
                className="text-Default lg:text-[42px] md:text-[34px] text-[26px] not-italic font-medium leading-normal md:leading-[48px] lg:leading-[44px]"
              />
              <div>
                <span className="text-[#333] lg:text-[28px] md:text-[26px] text-lg not-italic font-bold  leading-normal md:leading-9 lg:leading-8">
                  {"Membership Status: "}
                </span>
                <span
                  className={`lg:text-[28px] md:text-[26px] text-lg not-italic font-bold leading-normal md:leading-9 lg:leading-8 ${membershipDetails.membershipStatus === "Member" ? "text-feedback-success-Default" : membershipDetails.membershipStatus === "Withdrawn" ? "text-feedback-error" : membershipDetails.membershipStatus === "Temporary Withdrawn" ? "text-[#C6A80D]" : "text-black"}  `}
                >
                  {membershipDetails?.membershipStatus}
                </span>
              </div>
            </div>

            <div className="flex flex-1 justify-center align-middle mb-5 self-stretch lg:absolute lg:right-10 lg:top-10 ">
              {membershipDetails.logo ? (
                <img
                  src={membershipDetails.logo}
                  alt="Wsg_Profile_Picture"
                  className="object-cover max-h-[174px] max-w-[330px]"
                />
              ) : (
                <div></div>
              )}
            </div>

            <div className="grid w-full grid-cols-4 gap-5 mb-5">
              <div className="grid flex-col col-span-2 lg:col-span-1 gap-2">
                <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg leading-normal md:leading-5 lg:leading-normal not-italic font-bold ">
                  {"Membership Type: "}
                </span>
                <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg  not-italic font-normal leading-6 lg:leading-7">
                  {membershipDetails?.membershipType}
                </span>
              </div>

              <div className="grid flex-col col-span-2 lg:col-span-1 gap-2">
                <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg not-italic font-bold leading-normal md:leading-5 lg:leading-normal">
                  {"Service Type: "}
                </span>
                <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg  not-italic font-normal leading-6 lg:leading-7">
                  {membershipDetails?.serviceType}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="grid w-full col-start-5 grid-cols-4 gap-5 mb-10">
          <div className="grid flex-col col-span-4 md:col-span-2 lg:col-span-1 gap-2">
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg not-italic font-bold leading-normal md:leading-5 lg:leading-normal">
              Date Joined:
            </span>
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg  not-italic font-normal leading-6 lg:leading-7">
              {manageFirm.selectedFirm?.dateJoined ? formatDateToString(new Date(manageFirm.selectedFirm.dateJoined)) : ""}
            </span>
          </div>
          <div className="grid flex-col col-span-4 md:col-span-2 lg:col-span-1 gap-2">
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg not-italic font-bold leading-normal md:leading-5 lg:leading-normal">
              Membership Organization:
            </span>
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg  not-italic font-normal leading-6 lg:leading-7">
              {"70% Optimized"}
            </span>
          </div>
          <div className="grid flex-col col-span-4 md:col-span-2 lg:col-span-1 gap-2">
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg not-italic font-bold leading-normal md:leading-5 lg:leading-normal">
              Due Date:
            </span>
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg  not-italic font-normal leading-6 lg:leading-7">
            {formatDateToString(dueDate)}
            </span>
          </div>
          <div className="grid flex-col col-span-4 md:col-span-2 lg:col-span-1 gap-2">
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg not-italic font-bold leading-normal md:leading-5 lg:leading-normal">
              Dues Payment Status:
            </span>
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lgs  not-italic font-normal leading-6 lg:leading-7">
              {manageFirm.selectedFirm?.membershipDues}
            </span>
          </div>
        </div>

        <div className="flex w-full flex-col gap-5 md:flex-row md:justify-between">
          <div className=" w-full md:w-min  ">
            <NewButton
              text={`UPDATE MEMBERSHIP`}
              color="accent"
              type="button"
              style="filled"
              size="large"
              content="text"
              onClick={() => handleUpdateClick()}
            />
          </div>
          <div className="w-full md:w-min ">
            <NewButton
              text={`MEMBER REPORT`}
              color="accent"
              type="button"
              style="outlined"
              size="large"
              content="text"
              onClick={() => {}}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default MainBoxDefault;
