import React from "react";
import SwitchToogle from "../../../components/Atomos/Switch-Toogle/Switch";
import CatalogSubTitle from "../Catalog Text/CatalogSubTitle";
import Input from "../../Atomos/Inputs/Input";
import DropDownList from "../../Atomos/DropDown/Dropdown";

type IUser = {
  hasUser: boolean;
  userName: string;
  accessType: string;
};

type IError = {
  error: boolean;
  errorMsj: string;
};

type NewUserToogleProps = {
  personState: any;
  user: IUser;
  setUser: React.Dispatch<React.SetStateAction<IUser>>;
  errorFields: string[];
  seterrorFields: React.Dispatch<React.SetStateAction<string[]>>;
  userError: IError;
  setUserError: React.Dispatch<React.SetStateAction<IError>>;
};

const NewUserToggle: React.FC<NewUserToogleProps> = (
  props: NewUserToogleProps
) => {
  return (
    <>
      <div className="flex gap-2.5">
        <SwitchToogle
          centered={true}
          seter={() => {
            props.setUser((currentUser) => ({
              ...currentUser,
              hasUser: !props.user.hasUser,
            }));
          }}
          checked={props.user.hasUser}
        />
        <span className="inputLabel"> New User</span>
      </div>

      {props.user.hasUser && (
        <div className="w-full formsectionNTitle">
          <CatalogSubTitle
            title={"User Information"}
            className={` text-accent-Default-dark`}
          />

          <div className=" flex formsection w-full">
            <Input
              disabled
              title="Email"
              name="Email"
              errorMsj={""}
              error={false}
              tooltip="Email domain must match the official Firm's Domain."
              value={props.personState.firmEmail}
              onChange={() => {}}
              required={false}
            />

            <DropDownList
              title="Access Type"
              required
              data={[
                { id: "member", name: "Member" },
                { id: "dataAdmin", name: "Data Admin + Member" },
              ]}
              selectedObjst={props.user.accessType}
              seter={(e) => {
                props.setUser((currentuser) => ({
                  ...currentuser,
                  accessType: e,
                }));
                props.seterrorFields(
                  props.errorFields.filter((error) => error != "Access Type")
                );
              }}
              error={props.userError.error}
              errormsj={props.userError.errorMsj}
            />

            <Input
              title="User Name"
              name="UserName"
              errorMsj={""}
              error={false}
              value={props.user.userName}
              onChange={(e) => {
                props.setUser((currentuser) => ({
                  ...currentuser,
                  userName: e.target.value,
                }));
              }}
              required={false}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default NewUserToggle;
