const WSGLearningInstitute = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="2 2 20 20"
      className={props.className}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M12.5 22.25C11.3385 21.2255 10.0308 20.4309 8.577 19.8663C7.12317 19.3016 5.5975 18.9936 4 18.9423V8.76925C5.6065 8.81408 7.14142 9.14867 8.60475 9.773C10.0683 10.3973 11.3667 11.2333 12.5 12.2808C13.6333 11.2333 14.9318 10.3973 16.3953 9.773C17.8586 9.14867 19.3935 8.81408 21 8.76925V18.9423C19.3923 18.9936 17.8641 19.3016 16.4152 19.8663C14.9666 20.4309 13.6615 21.2255 12.5 22.25ZM12.5 20.323C13.55 19.5462 14.6667 18.923 15.85 18.4535C17.0333 17.984 18.25 17.6738 19.5 17.523V10.4307C18.2385 10.6474 17.017 11.0769 15.8355 11.7192C14.6542 12.3614 13.5423 13.2037 12.5 14.246C11.4577 13.2037 10.3458 12.3614 9.1645 11.7192C7.983 11.0769 6.7615 10.6474 5.5 10.4307V17.523C6.75 17.6738 7.96667 17.984 9.15 18.4535C10.3333 18.923 11.45 19.5462 12.5 20.323ZM12.5 9.23075C11.5058 9.23075 10.6547 8.87675 9.94675 8.16875C9.23875 7.46075 8.88475 6.60958 8.88475 5.61525C8.88475 4.62108 9.23875 3.77 9.94675 3.062C10.6547 2.354 11.5058 2 12.5 2C13.4942 2 14.3453 2.354 15.0533 3.062C15.7613 3.77 16.1152 4.62108 16.1152 5.61525C16.1152 6.60958 15.7613 7.46075 15.0533 8.16875C14.3453 8.87675 13.4942 9.23075 12.5 9.23075ZM12.5003 7.73075C13.0821 7.73075 13.5801 7.52358 13.9943 7.10925C14.4084 6.69492 14.6155 6.19683 14.6155 5.615C14.6155 5.03317 14.4083 4.53517 13.994 4.121C13.5797 3.707 13.0816 3.5 12.4998 3.5C11.9179 3.5 11.4199 3.70717 11.0058 4.1215C10.5916 4.53583 10.3845 5.03392 10.3845 5.61575C10.3845 6.19758 10.5917 6.6955 11.006 7.1095C11.4203 7.52367 11.9184 7.73075 12.5003 7.73075Z"/>
    </svg>
  );
}

export default WSGLearningInstitute;
