import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Axios from "../../../../utils/axios";
import { UseFirmList } from "../../../../hooks/Catalogs/useFirm";
import { getNameById } from "../../../../utils/functions";
import OfficeContactBody from "../../../../components/Catalogs/OfficeContact/OfficeContactBody";
import NewButton from "../../../../components/Atomos/Buttons/newButton";
import Plus2 from "../../../../components/Atomos/Icons/Plus";
import Close from "../../../../components/Atomos/Icons/Close";
import FirmContactButton from "../../../../components/Atomos/Buttons/firmContactButton";
import SaveChanges from "../../../../components/Atomos/Icons/EditMode/SaveChanges";
import { Appstore } from "../../../../redux";
import { useSelector } from "react-redux";
import { useUpdateAndLog } from "../../../../hooks/LogActivity/LogActivityUpdates";
import IOfficeContact from "../../../../interfaces/models/IOfficeContact";

type props = {
  officeId: string;
  officeName?: string;
  officeObjs: any;
  setErrorFields: any;
  setErrorJurisdiction: any;
  buttonClicked: string;
  memberMainContact: any;
};

const initialActiveErrors = {
  primaryContact: false,
};

const ActiveErrorsMessage = {
  primaryContact: "Contact is required.",
};

const EditOfficeManage: React.FC<props> = (props: props) => {
  const axios = new Axios();
  const { state, pathname } = useLocation();
  const update = useUpdateAndLog();
  const navigate = useNavigate();

  const saveButtonRef = useRef<HTMLDivElement>(null);
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);
  const [checked, setChecked] = useState(false);
  const [contacts, setContacts] = useState<any[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<any[]>([]);
  const [offices, setOffices] = useState<any[]>([]);
  const [officeData, setOfficeData] = useState<any[]>([]);
  const [officeObjects, setOfficeObjects] = useState<any[][]>([]);
  const [selectedAdditionalContacts, setSelectedAdditionalContacts] = useState<
    { id: ""; name: "" }[]
  >([{ id: "", name: "" }]);
  const [activeErrors, setActiveErrors] = useState(initialActiveErrors);
  const [activeErrorsMessage, setActiveErrorsMessage] =
    useState(ActiveErrorsMessage);

  useEffect(() => {
    let newErrorFields: string[] = [];
    if (activeErrors.primaryContact) {
      newErrorFields.push("Contact");
    }
    props.setErrorFields(newErrorFields);
  }, [activeErrors]);

  useEffect(() => {
    if (props.buttonClicked === "saveOverride") {
      saveButtonRef.current?.click();
    }
  }, [props.buttonClicked]);

  const checkOtherOfficeContacts = () => {
    const officesWithErrors = offices?.map((office) => {
      const allContactsEmpty = office.contacts.every((contactArray: any[]) => {
        if (contactArray.length === 0) {
          return true;
        }

        return contactArray.every((contact: any) => {
          return contact.name === "" || contact.name === undefined;
        });
      });

      if (allContactsEmpty || office.contacts.length === 0) {
        return office;
      } else {
        return null;
      }
    });

    const filteredJurisdictions = officesWithErrors.filter(
      (office) => office !== null && office.office !== props.officeId
    );

    if (filteredJurisdictions.length > 0) {
      const jurIds = filteredJurisdictions.map((jur) => jur.office);

      const jurNames = jurIds.map((jur) => {
        const office = officeData.find((j) => j.id === jur);
        return office ? office.name : "";
      });

      const errorMessage = `Check Other Office Main Contacts:`;
      const errorMessages = [errorMessage, ...jurNames];

      props.setErrorJurisdiction(errorMessages);
    }

    return filteredJurisdictions;
  };

  useEffect(() => {
    props.setErrorJurisdiction([]);
    if (checked) {
      checkOtherOfficeContacts();
    }
  }, [props.officeId]);

  useEffect(() => {
    if (contacts.length === 0) {
      const fetchContacts = async () => {
        try {
          const response = await axios.Get("./person");
          const data = response.data.filter(
            (contact: any) => contact.firm === manageFirm?.selectedFirm?.id
          );

          const formatedData = data.map((d: any) => {
            return {
              ...d,
              name: (d.name + " " + d.lastName).trim(),
            };
          });
          if (data != null) {
            setContacts(formatedData);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchContacts();
    }

    if (officeData.length === 0) {
      const fetchOffices = async () => {
        try {
          const response = await axios.Get(`./office`);
          const data = response.data;
          if (data != null) {
            setOfficeData(data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchOffices();
    }
  }, []);

  const handleDropDownChangeAdditional = (index: number, contact: any) => {
    setSelectedAdditionalContacts((prevContacts) => {
      const updatedContacts = [...prevContacts];
      updatedContacts[index] = { id: contact.id, name: contact.name };
      return updatedContacts;
    });

    setOffices((prevOffices) => {
      return prevOffices.map((office) => {
        if (office.office === props.officeId) {
          const additionalContactsIndex = office.contacts.findIndex(
            (contactItem: any) => Array.isArray(contactItem)
          );

          const updatedContacts = selectedAdditionalContacts;
          updatedContacts[index] = { id: contact.id, name: contact.name };

          if (additionalContactsIndex !== -1) {
            office.contacts[additionalContactsIndex] = updatedContacts;
          }
        }
        return office;
      });
    });
  };

  const handleAddNewContact = () => {
    const officeIndex = offices?.findIndex(
      (office) => office.office === props.officeId
    );

    if (officeIndex !== -1) {
      const updatedOffices = [...offices];
      const contacts = updatedOffices[officeIndex].contacts[0];

      if (contacts) {
        updatedOffices[officeIndex].contacts[0].push({ id: "", name: "" });
        setOffices(updatedOffices);
      }
    }
  };

  const handleApplyContactAdditional = (officeIds: any, contactObject: any) => {
    officeIds.forEach((officeId: any) => {
      const officeIndex = offices?.findIndex(
        (office) => office.office === officeId.id
      );

      if (officeIndex !== -1) {
        const updatedOffices = [...offices];
        updatedOffices[officeIndex].contacts = updatedOffices[
          officeIndex
        ].contacts.map((contactItem: any) => {
          if (Array.isArray(contactItem)) {
            contactItem.push({
              id: contactObject.id,
              name: contactObject.name,
            });
          }
          return contactItem;
        });

        setOffices(updatedOffices);
      }
    });
  };

  const handleApplyAllContactAdditional = (
    contactObject: any,
    officesAvailable: any
  ) => {
    const updatedOffices = offices?.map((office) => {
      const officesAvailableIds = officesAvailable.map(
        (office: any) => office.id
      );
      if (
        office.office !== props.officeId &&
        officesAvailableIds.includes(office.office)
      ) {
        const updatedContacts = office.contacts.map((contactItem: any) => {
          if (Array.isArray(contactItem)) {
            contactItem.push({
              id: contactObject.id,
              name: contactObject.name,
            });
          }
          return contactItem;
        });

        return {
          ...office,
          contacts: updatedContacts,
        };
      }

      return office;
    });

    setOffices(updatedOffices);
  };

  const removeContact = (index: number) => {
    const officeIndex = offices?.findIndex(
      (office) => office.office === props.officeId
    );

    if (officeIndex !== -1) {
      const updatedOffices = [...offices];
      const contacts = updatedOffices[officeIndex].contacts[0];

      if (contacts && contacts.length > index) {
        contacts.splice(index, 1);

        updatedOffices[officeIndex].contacts[0] = contacts;
        setOffices(updatedOffices);
      }
    }
  };

  useEffect(() => {
    const updatedJurisdictions = props.memberMainContact?.offices?.map(
      (office: any) => {
        const additionalContacts = office.contacts
          .filter((contact: any) => contact.type === "additionalContact")
          .map((contact: any) => ({
            id: contact.contact,
            name:
              contacts.find((c: any) => c.id === contact.contact)?.name ?? "",
          }));

        const newContacts = {
          additionalContacts: additionalContacts.map((contact: any) => ({
            id: contact.id,
            name: contact.name,
          })),
        };

        return {
          office: office.office,
          contacts: Object.values(newContacts),
        };
      }
    );

    setOffices(updatedJurisdictions);
  }, [props?.memberMainContact?.offices, contacts]);

  useEffect(() => {
    const active: any = offices?.find((of) => of.office === props.officeId);

    const addCon = active?.contacts[0];
    const additionalContacts =
      addCon?.map((contact: any) => ({
        id: contact.id,
        name: contacts.find((c: any) => c.id === contact.id)?.name ?? "",
      })) ?? [];
    if (additionalContacts.length === 0) {
      setSelectedAdditionalContacts([{ id: "", name: "" }]);
    } else {
      setSelectedAdditionalContacts(additionalContacts);
    }

    let filteredContacts = [];

    if (contacts) {
      filteredContacts = contacts.filter((person) => {
        if (person.mainOffice === props.officeId) {
          return true;
        }
        if (
          person.additionalOffices &&
          person.additionalOffices.includes(props.officeId)
        ) {
          return true;
        }
        return false;
      });
    }

    setFilteredContacts(filteredContacts);
  }, [offices, props.officeId, contacts]);

  useEffect(() => {
    if (selectedAdditionalContacts && selectedAdditionalContacts.length > 0) {
      const contactIds = selectedAdditionalContacts.map(
        (contact) => contact.id
      );

      const filteredPersons = contacts.filter((person) =>
        contactIds.includes(person.id)
      );

      const officeInfo = filteredPersons.map((person) => {
        const additionalOfficesArray = Array.isArray(person.additionalOffices)
          ? person.additionalOffices
          : [];

        const allOfficeIds = [
          person.mainOffice,
          ...additionalOfficesArray.flat(),
        ];

        const allOfficesWithData = allOfficeIds.map((officeId) => {
          return officeData.find((office) => office?.id === officeId);
        });

        return {
          id: person.id,
          offices: allOfficesWithData,
        };
      });

      const officeObjectsFilter: React.SetStateAction<any[][]> = [];
      contactIds.forEach((contactId) => {
        const matchingPerson = officeInfo.find(
          (person) => person.id === contactId
        );
        if (matchingPerson) {
          const matchingOffices = matchingPerson?.offices;
          if (Array.isArray(matchingOffices)) {
            officeObjectsFilter.push(matchingOffices);
          }
        }
      });

      setOfficeObjects(officeObjectsFilter);
    }
  }, [selectedAdditionalContacts, contacts, officeData]);

  useEffect(() => {
    if (props.buttonClicked === "saveOverride") {
      saveButtonRef.current?.click();
    }
  }, [props.buttonClicked]);

  useEffect(() => {
    if (checked) {
      validateForm();
    }
  }, [selectedAdditionalContacts, offices]);

  const validateForm = () => {
    const allContactsFilled = selectedAdditionalContacts.every(
      (contact: any) => {
        if (selectedAdditionalContacts.length === 0) {
          return false;
        }
        return contact.name === "" || contact.name === undefined;
      }
    );

    setActiveErrors((prevState) => ({
      ...prevState,
      primaryContact: allContactsFilled,
    }));
  };

  const handleUpdate = async () => {
    try {
      setChecked(true);
      const officeWithErrors = checkOtherOfficeContacts();

      if (props.buttonClicked !== "saveOverride") {
        const allContactsFilled = selectedAdditionalContacts.every(
          (contact: any) => {
            if (selectedAdditionalContacts.length === 0) {
              return false;
            }
            return contact.name === "" || contact.name === undefined;
          }
        );

        const newActiveErrors = {
          primaryContact: allContactsFilled,
        };

        setActiveErrors(newActiveErrors);

        if (newActiveErrors.primaryContact) return;

        if (officeWithErrors.length > 0) {
          return;
        }
      }

      let counterContacts = 0;
      const finalOffices: any = offices?.map((offices) => {
        const additionalContacts =
          offices.contacts[0].map((contact: any) => ({
            type: "additionalContact",
            contact: contact.id,
          })) ?? [];

        const nonEmptyAdditionalContacts = additionalContacts.filter(
          (contact: any) => contact.contact !== ""
        );
        const totalContacts = nonEmptyAdditionalContacts.length;
        counterContacts += totalContacts;

        return {
          ...offices,
          contacts: [...nonEmptyAdditionalContacts],
        };
      });

      const updatedData: IOfficeContact = {
        id: state?.memberMainContact?.id,
        firm: state?.memberMainContact?.firm,
        firmName: state?.memberMainContact?.firmName,
        offices: finalOffices,
        totalContacts: counterContacts.toString(),
        createdAt: state?.memberMainContact?.createdAt,
      };
      
      await update.officeContact({
        officeContact: updatedData, 
        pathname, 
        prevState: state?.memberMainContact
      });
      
      navigate(`/Staff/ManageFirm/GeneralTab/${manageFirm?.selectedFirm?.id}`);
    } catch (error: any) {
      console.error("Error al actualizar el elemento:", error);
    }
  };

  return (
    <div className=" flex flex-col gap-6 lg:gap-10 pt-5">
      <div className=" flex flex-col gap-5">
        {selectedAdditionalContacts.map((dropdown, index) => (
          <div key={index} className="grid col-span-3 gap-1 w-full ">
            <OfficeContactBody
              title={"Contact " + (index + 1)}
              index={index}
              getid={true}
              object={dropdown}
              selectedObjst={selectedAdditionalContacts[index].name}
              seter={(id: string) => handleDropDownChangeAdditional(index, id)}
              error={index === 0 ? activeErrors.primaryContact : false}
              required={index === 0 ? true : false}
              errorMsj={activeErrorsMessage.primaryContact}
              contacts={filteredContacts}
              onApplyContact={handleApplyContactAdditional}
              onApplyAllContact={handleApplyAllContactAdditional}
              offices={
                officeObjects
                  ? officeObjects[index]
                    ? officeObjects[index]
                    : []
                  : []
              }
              officeId={props.officeId}
              remove={removeContact}
            />
          </div>
        ))}
      </div>

      <div className="w-min ">
        <NewButton
          text={`NEW CONTACT`}
          color="accent"
          type="button"
          style="filled"
          size="large"
          content="textIcon"
          onClick={handleAddNewContact}
          icon={
            <Plus2 className="w-5 h-5 shrink-0 relative overflow-visible" />
          }
        />
      </div>

      <div className=" flex justify-end self-stretch">
        <div className="flex w-min gap-4">
          <NewButton
            text={`CANCEL`}
            color="neutral"
            content="textIcon"
            type="button"
            style="outlined"
            size="medium"
            className=""
            icon={<Close />}
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className=" min-w-[8.75rem]">
            <FirmContactButton
              text={`SAVE`}
              color="accent"
              style="filled"
              size="medium"
              content="textIcon"
              className=" "
              icon={<SaveChanges />}
              onClick={handleUpdate}
              ref={saveButtonRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOfficeManage;
