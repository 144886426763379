import { useEffect, useState } from "react";
import Axios from "../../utils/axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IPerson,
  PersonAreasOfPracticeProps,
  personErrorsType,
} from "../../interfaces/models/IPerson";
import React from "react";
import { v4 as UUIDV4 } from "uuid";
import { uploadImage } from "../../utils/UploadImage";
import { MemberType } from "../../Enums/PersonEnums";
import { Option } from "../../components/Atomos/DropDown/DDMultiple";
import { getAOPRankings } from "../../Services/AOPRanking";
import { IAOPRanking } from "../../interfaces/models/IAOPRanking";
import { useLogging } from "../../Context/LoggingContext";
import { useDispatch, useSelector } from "react-redux";
import { Appstore, contentContext, contentsSlice } from "../../redux";
import { getCurrentTimestamp, getNameById, updateData } from "../../utils/functions";
import { useUpdateAndLog } from "../LogActivity/LogActivityUpdates";
import { createUser } from "../../Services/User";
import { getOfficeByFirm } from "../../Services/Office";

const usePersonAdd = (state: any) => {
  const navigate = useNavigate();
  
  const [personErrors, setPersonErrors] = useState<personErrorsType>({
    firm: { error: false, message: "" },
    name: { error: false, message: "" },
    lastName: { error: false, message: "" },
    title: { error: false, message: "" },
    listTitle: { error: false, message: "" },
    listingCategory: { error: false, message: "" },
    assistantName: { error: false, message: "" },
    assistantEmail: { error: false, message: "" },
    aop: { error: false, message: "" },
    mainOffice: { error: false, message: "" },
    firmEmail: { error: false, message: "" },
    industryOrganizationAffiliation: { error: false, message: "" },
    significantAccomplishments: { error: false, message: "" },
    additionalActivities: { error: false, message: "" },
    seminarPresentation: { error: false, message: "" },
    additionalInformation: { error: false, message: "" },
    externalType: { error: false, message: "" },
    bio: { error: false, message: "" },
  });

  const { logActivity } = useLogging();
  const {pathname} = useLocation();
  const update = useUpdateAndLog();

  const [regularPhoto, setRegularPhoto] = useState<String | File | null>(null);
  const [personState, setPersonState] = React.useState<IPerson>(
    state
      ? (state as IPerson)
      : ({
        id: UUIDV4(),
        photo: "",
        memberType: MemberType.nonMember,
        isAttorney: false,
        isSearchable: false,
        unlistedTitle: false,
        hasAssistant: false,
        firm: "",
        title: "",
        verificationCode: {
          verificationCode: 0,
          expires: 0,
          status: false
        }
      } as IPerson)
  );

  const [offices, setOffices] = useState([]);
  const [officesAdditional, setOfficesAdditional] = useState([]);
  const [buttonClicked, setButtonClicked] = useState("");

  const dispatch = useDispatch();
  const [originalFirmEmail, setOriginalFirmEmail] = useState<string | null>(null);

  const currentContent: contentContext = useSelector(
    (store: Appstore) => store.content
  );
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (personState.firmEmail) {
      setOriginalFirmEmail(personState.firmEmail); 
    }
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await getOfficeByFirm(personState.firm) 
        if (!responseData || responseData.length === 0) {
          setOffices([]);
          setOfficesAdditional([]);
          setPersonState((prevState) => ({
            ...prevState,
            countryCodePhoneNumber: "",
            phoneNumber: "",
          }));
          return;
        }

        const additionalOffices = responseData.filter((office: any) => office.officeType && office.officeType === 'Additional');

        const mappedMainOfficeAdditional = additionalOffices.map((mainOffice: any) => ({
          id: mainOffice.id,
          name: `${mainOffice.cityName}, ${mainOffice.countryName}`,
          code: mainOffice.code ?? "",
          phonenumber: mainOffice.phone ?? "",
          basecode: mainOffice.basecode ?? "",
          address: mainOffice.address,
          type: mainOffice.officeType,
        }));

        const currentMainOffice = responseData.find(
          (mainOffice: any) => mainOffice.id === personState.mainOffice
        );

        setOffices(responseData);
        setOfficesAdditional(mappedMainOfficeAdditional);
        setPersonState((prevState) => ({
          ...prevState,
          countryCodePhoneNumber: currentMainOffice?.code ?? "",
          phoneNumber: currentMainOffice?.phonenumber ?? "",
          mappedMainOffice: "",
          mappedMainOfficeAdditional: "",
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [personState.firm]);


  const handleMultipleSelectChange: (name: string, value: Option[]) => void = (
    name,
    value
  ) => {
    setPersonState((prevState) => ({
      ...prevState,
      [name]: value.map((option) => option.id),
    }));
  };

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setPersonState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckBoxChange = (name: string, checked: boolean) => {
    setPersonState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleRadioButtonChange = async (name: string, index: number) => {
    await setPersonState((prevState) => ({
      ...prevState,
      [name]: index,
    }));
  };

  const handleTextAreaElement = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setPersonState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneNumberChange = (
    codeName: string,
    phoneName: string,
    baseCode: string,
    code: string,
    phone: string,
    base: string
  ) => {
    setPersonState((prevState) => ({
      ...prevState,
      [codeName]: code ?? "",
      [phoneName]: phone,
      [baseCode]: base ?? "",
    }));
  };

  const handleAddSocialNetworks = (
    newsocialNetworks: { name: string; url: string }[]
  ) => {
    setPersonState((prevState) => ({
      ...prevState,
      socialNetworks: newsocialNetworks,
    }));
  };

  const handleAddAdditionalOffices = (additionalOfficesList: string[]) => {
    setPersonState((prevState) => ({
      ...prevState,
      additionalOffices: additionalOfficesList,
    }));
  };

  const handleAddlanguages = (languages: string[]) => {
    setPersonState((prevState) => ({
      ...prevState,
      languages: languages,
    }));
  };

  const handleAddAdmision = (barAdmissions: string[]) => {
    setPersonState((prevState) => ({
      ...prevState,
      barAdmissions: barAdmissions,
    }));
  };
  const handleAddIndustryOrganizationAffiliation = (
    industryOrganizationAffiliation: string[]
  ) => {
    setPersonState((prevState) => ({
      ...prevState,
      industryOrganizationAffiliation: industryOrganizationAffiliation,
    }));
  };

  const handlerAreasOfPractice = (aops: PersonAreasOfPracticeProps[]) => {
    if (aops && aops.length > 0) {
      let clonedAOPs = [...aops];
      const orderedAOPs = clonedAOPs
        .filter((aop) => aop.order > 0)
        .sort((a, b) => a.order - b.order);

      orderedAOPs.forEach((aop, index) => {
        aop.order = index + 1;
      });
      setPersonState((prevState) => ({
        ...prevState,
        areasOfPractice: clonedAOPs,
      }));
    }
  };

  const getFirmById = async (id: string) => {
    if (personState.memberType === MemberType.staff) {
      const response = await axios.Get("/wsg");
      if (response.status === 200) {
        return response.data;
      }
    } else {
      const response = await axios.Get("/firm?id=" + id);
      if (response.status === 200) {
        return response.data;
      }
    }
  };

  const validation = async () => {
    const newErrors = { ...personErrors };
    if (personState.memberType === MemberType.member && !personState.firm) {
      newErrors.firm.error = true;
      newErrors.firm.message = "Firm is required";
    } else {
      newErrors.firm.error = false;
      newErrors.firm.message = "";
    }

    if (
      !personState.externalType &&
      personState.memberType === MemberType.nonMember
    ) {
      newErrors.externalType.error = true;
      newErrors.externalType.message = "External Type is required";
    } else {
      newErrors.externalType.error = false;
      newErrors.externalType.message = "";
    }

    if (!personState.name || personState.name.trim() === "") {
      newErrors.name.error = true;
      newErrors.name.message = "Name is required";
    } else {
      newErrors.name.error = false;
      newErrors.name.message = "";
    }

    if (!personState.lastName || personState.lastName.trim() === "") {
      newErrors.lastName.error = true;
      newErrors.lastName.message = "Last Name is required";
    } else {
      newErrors.lastName.error = false;
      newErrors.lastName.message = "";
    }

    if (personState.unlistedTitle) {
      if (!personState.listTitle || personState.listTitle.trim() === "") {
        newErrors.listTitle.error = true;
        newErrors.listTitle.message = "List Title is required";
      } else {
        newErrors.listTitle.error = false;
        newErrors.listTitle.message = "";
      }
      if (!personState.listingCategory) {
        newErrors.listingCategory.error = true;
        newErrors.listingCategory.message = "Listing Category is required";
      } else {
        newErrors.listingCategory.error = false;
        newErrors.listingCategory.message = "";
      }
    } else {
      if (!personState.title) {
        newErrors.title.error = true;
        newErrors.title.message = "Title is required";
      } else {
        newErrors.title.error = false;
        newErrors.title.message = "";
      }
      newErrors.listTitle.error = false;
      newErrors.listTitle.message = "";
      newErrors.listingCategory.error = false;
      newErrors.listingCategory.message = "";
    }

    if (personState.hasAssistant) {
      if (
        !personState.assistantName ||
        personState.assistantName.trim() === ""
      ) {
        newErrors.assistantName.error = true;
        newErrors.assistantName.message = "Assistant Name is required";
      } else {
        newErrors.assistantName.error = false;
        newErrors.assistantName.message = "";
      }
      if (
        !personState.assistantEmail ||
        personState.assistantEmail.trim() === ""
      ) {
        newErrors.assistantEmail.error = true;
        newErrors.assistantEmail.message = "Assistant Email is required";
      } else {
        newErrors.assistantEmail.error = false;
        newErrors.assistantEmail.message = "";
      }
    } else {
      newErrors.assistantName.error = false;
      newErrors.assistantName.message = "";
      newErrors.assistantEmail.error = false;
      newErrors.assistantEmail.message = "";
    }

    if (
      !personState.firmEmail?.trim() && 
      personState.memberType !== MemberType.nonMember
    ) {
      newErrors.firmEmail.error = true;
      newErrors.firmEmail.message = "Firm Email is required";
    } else if (personState.firmEmail?.trim()) {
      if (personState.firmEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        newErrors.firmEmail.error = false;
        newErrors.firmEmail.message = "";
      } else {
        newErrors.firmEmail.error = true;
        newErrors.firmEmail.message = "Email not well formatted";
      }
    }

    if (
      personState.firm &&
      personState.memberType !== MemberType.nonMember &&
      personState.isAttorney &&
      personState.firmEmail !== originalFirmEmail
    ) {
      await getFirmById(personState.firm).then((response) => {
        if (response.length > 0) {
          if (!personState.firmEmail.endsWith(response[0].acceptedEmailDomain)) {
            newErrors.firmEmail.error = true;
            newErrors.firmEmail.message =
              "Email does not match accepted email domain";
          }
          setPersonErrors(newErrors);
        }
      });
    }


    if (personState.bio && personState.bio.length > 4000) {
      newErrors.bio.error = true;
      newErrors.bio.message =
        "Significant Accomplishments must be less than 4000 characters";
    } else {
      newErrors.bio.error = false;
      newErrors.bio.message = "";
    }

    if (
      personState.significantAccomplishments &&
      personState.significantAccomplishments.length > 4000
    ) {
      newErrors.significantAccomplishments.error = true;
      newErrors.significantAccomplishments.message =
        "Significant Accomplishments must be less than 4000 characters";
    } else {
      newErrors.significantAccomplishments.error = false;
      newErrors.significantAccomplishments.message = "";
    }
    if (
      personState.additionalActivities &&
      personState.additionalActivities.length > 1500
    ) {
      newErrors.additionalActivities.error = true;
      newErrors.additionalActivities.message =
        "Additional Activities must be less than 1500 characters";
    } else {
      newErrors.additionalActivities.error = false;
      newErrors.additionalActivities.message = "";
    }
    if (
      personState.seminarPresentation &&
      personState.seminarPresentation.length > 1500
    ) {
      newErrors.seminarPresentation.error = true;
      newErrors.seminarPresentation.message =
        "Seminar/Presentation must be less than 1500 characters";
    } else {
      newErrors.seminarPresentation.error = false;
      newErrors.seminarPresentation.message = "";
    }

    if (
      personState.isAttorney &&
      personState.memberType === MemberType.member
    ) {
      if (!personState.mainOffice) {
        newErrors.mainOffice.error = true;
        newErrors.mainOffice.message = "Main Office is required";
      } else {
        newErrors.mainOffice.error = false;
        newErrors.mainOffice.message = "";
      }

      if (!personState.areasOfPractice) {
        newErrors.aop.error = true;
        newErrors.aop.message = "At least 1 AOP selected is required";
      } else {
        newErrors.aop.error = false;
        newErrors.aop.message = "";
      }
    } else {
      newErrors.mainOffice.error = false;
      newErrors.mainOffice.message = "";
      newErrors.aop.error = false;
      newErrors.aop.message = "";
    }

    setPersonErrors(newErrors);
    return Object.values(newErrors).some(errorObj => errorObj.error)
  };

  useEffect(()=> {
    if (checked) {
      validation()
    }
  },[personState])

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    redirect: string = "",
    noRedirect: boolean = false,
    newUser: any = []
  ) => {
    e.preventDefault();
    setChecked(true)
    const hasError = await validation();
    if (hasError) {
      throw new Error("Validation error")
    }
    let response 
    try {
      let personWithPhoto: IPerson = { ...personState };
      if (personState.mainOffice) personWithPhoto.mainOfficeName = getNameById(personState.mainOffice, offices).join()
      if (personState.additionalOffices) personWithPhoto.multipleOffices = !!personState.additionalOffices?.length
      
      await uploadImage(
        regularPhoto as File,
        personState.id as string,
        "user/profile-pictures/"
      )
        .then((response) => {
          if (response) {
            setPersonState((prevPersonState) => ({
              ...prevPersonState,
              photo: response as string,
              validationCode: {
                verificationCode: 0,
                expires: 0,
                status: false
              }
            }));
            personWithPhoto = { ...personState, photo: response as string };
          } else {
            console.error("No response received from uploadImage.");
          }
        })
        .catch((error) => {
          // Handle errors
          console.error("Error during image upload:", error);
        })
        .finally(async () => {
          const hasError = await validation();
          if (hasError) throw new Error("Validation error")

          if (buttonClicked === "saveButton") {
            let userId = ''
            if (newUser) {
              userId = await createNewUser(newUser)
              personWithPhoto = { ...personWithPhoto, userId }
            } 
            response = await savePerson(personWithPhoto, redirect, noRedirect);
          } else {
            response = await handleUpdate(e, personWithPhoto, noRedirect);
          }
        });
      return response
    } catch (error) {
      // Handle unexpected errors
      console.error("Unexpected error during image upload:", error);
      throw error
    }
  };

  const createNewUser = async (user: any) => {
    const newUser = await createUser(user)
    return newUser.response.id
  }

  const getAreasOfPracticeByPerson = async (aops: any) => {
    if (aops) {
      try {
        const fetchPromises = aops.map((aop: any) =>
          axios.Get(`/areaOfPractice`, `${aop.id}`)
        );
        const responses = await Promise.all(fetchPromises);

        const allAops = responses.map((response) => response.data);
        await updatePersonAops(allAops.flat());
      } catch (error) {}
    }
  };

  const updatePersonAops = async (areasOfPractice: any[]) => {
    areasOfPractice.forEach(async (aop: any) => {
      try {
        const response = await axios.Put("/AreaOfPractice", {
          ...aop,
          isTargeted: aop.isMapped ? false : true,
        });
        if (response.status !== 200) {
          console.error("Failed to update AOP:", aop.id);
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  const axios = new Axios();

  const updatePersonAOPRankings = async (personState: IPerson) => {
    const countOccurrences = (text: string, search: string) => {
      console.log("text", text);
      const regex = new RegExp(`\\b${search}\\b`, "gi");
      return (text?.match(regex) || []).length;
    };

    const calculateRelevancy = (aopName: string): number => {
      let count = 0;
      count += countOccurrences(personState.bio, aopName);
      count += countOccurrences(
        personState.significantAccomplishments,
        aopName
      );
      count += personState.industryOrganizationAffiliation?.reduce(
        (acc: any, affiliation: any) =>
          acc + countOccurrences(affiliation, aopName),
        0
      );
      count += countOccurrences(personState.additionalActivities, aopName);
      count += countOccurrences(personState.seminarPresentation, aopName);
      return count;
    };

    const relevancyScores = personState.areasOfPractice?.map((aop: any) => ({
      ...aop,
      relevancy: calculateRelevancy(aop.name),
      matches: calculateRelevancy(aop.name),
    }));

    relevancyScores?.sort((a: any, b: any) => {
      if (b.relevancy === a.relevancy) {
        return a.name.localeCompare(b.name);
      }
      return b.relevancy - a.relevancy;
    });

    const ranks =
      relevancyScores?.slice(0, 4).map((aop: any) => ({
        id: aop.id,
        name: aop.name,
        expertise: aop.expertise,
        practiceChair: aop.practiceChair,
        isTargeted: aop.isTargeted,
        matches: aop.matches,
      })) || [];

    for (let i = ranks.length; i < 4; i++) {
      ranks.push();
    }

    try {
      const existingRankings = await getAOPRankings();
      const existingPersonRanking = existingRankings.filter(
        (ranking: IAOPRanking) => ranking.person.id === personState.id
      );
      const method = existingPersonRanking.length > 0 ? "Put" : "Post";
      const url = `/aopRanking`;

      let response: any = {};

      if (method === "Put") {
        response = await axios[method](url, {
          id: existingPersonRanking[0].id,
          person: personState,
          firm: personState.firm,
          ranks,
        });
      } else {
        response = await axios[method](url, {
          person: personState,
          firm: personState.firm,
          ranks,
        });
      }

      if (response.status === 200) {
        console.log("AOP rankings updated successfully.");
      } else {
        console.error("Failed to update AOP rankings.");
      }
    } catch (error) {
      console.error("An error occurred while updating AOP rankings:", error);
    }
  };

  const handleUpdate = async (
    event: React.FormEvent<HTMLFormElement>,
    person: IPerson,
    noRedirect: boolean
  ) => {
    event.preventDefault();
    let response;
    const {
      isOnlySocials,
      isOnlyAops,
      isOnlyProfessionalCareer,
      ...personWithoutSpecifiedProps
    } = person;
  
    try {
      const hasError = await validation();
      if (!hasError) {
        getAreasOfPracticeByPerson(person?.areasOfPractice);
        response = await update.person({
          person: personWithoutSpecifiedProps,
          pathname,
          prevState: state,
        });
        if (state.name !== person.name) {
          await updateData('education', 'personName', state.name, person.name);
        }
        if (!noRedirect) navigate(-1);
      }
      return response;
    } catch (error) {
      console.error("Error updating person:", error);
      throw error
    } finally {
      await updatePersonAOPRankings(person);
    }
  };

  const savePerson = async (person: IPerson, redirect: string, noRedirect: boolean) => {
    let response
    let hasErrors = false;
    Object.values(personErrors).forEach((error) => {
      if (error.error) {
        hasErrors = true;
      }
    });
    if (hasErrors) throw new Error("Validation error")

    try {
      response = await axios.Post("/person", {...person, createdDate: getCurrentTimestamp()});
      const stateData = {
        person: response.data.id,
        firm: personState.firm,
        email: personState.firmEmail,
        userType: personState.memberType,
        isContentRedirect: state?.isContentRedirect ? state?.isContentRedirect : false,
        content: state?.content,
        verificationCode: {
          verificationCode: 0,
          expires: 0,
          status: false
        }
      };
      
      if (response.data) {
        try {
          await updatePersonAOPRankings(person); 
        } catch (aopError) {
          console.error('Error al actualizar los rankings AOP:', aopError);
          // TODO: Escenario de fallo en la actualización para hacer rollback
        }

        logActivity('CREATE_PERSON', pathname, JSON.stringify(response.data));
        if (!noRedirect) {
          if (redirect) {
            navigate(redirect, { state: stateData });
            let updatedContent = { ...currentContent?.content };
            updatedContent.authors = currentContent?.content?.authors.concat(response.data.id);
            dispatch(
              contentsSlice.actions.ModifyContent({
                ...currentContent,
                content: updatedContent,
              })
            );
          } else {
            navigate(-1);
          }
        }
      }
      return response
    } catch (error) {
      console.error(error);
      return error
    } 
  };


  const handleDelete = async () => {
    const response = await axios.Delete("/person", personState.id as string);
    if (response.status === 200) {
      logActivity(
        "DELETE_PERSON",
        pathname,
        JSON.stringify(state ? state : {})
      );
      navigate(-1);
    }
  };

  return {
    personState,
    setPersonState,
    offices,
    officesAdditional,
    regularPhoto,
    setRegularPhoto,
    handleInputChange,
    handleCheckBoxChange,
    handleTextAreaElement,
    handlePhoneNumberChange,
    handleAddSocialNetworks,
    handleAddAdditionalOffices,
    handleAddlanguages,
    handleAddAdmision,
    handleAddIndustryOrganizationAffiliation,
    handlerAreasOfPractice,
    handleSubmit,
    handleButtonClick,
    handleDelete,
    handleRadioButtonChange,
    personErrors,
    handleMultipleSelectChange,
    setPersonErrors,
  };
};

export default usePersonAdd;
