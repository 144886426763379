import { IRegion } from "../interfaces";
import Axios from "../utils/axios";

const axios = new Axios();

export const getRegions = async (): Promise<IRegion[]> => {
  try {
    const regions = await axios.Get("./region");
    const data = regions.data;
    if (data != null) {
      return regions.data.map((region: IRegion) => ({
        id: region.id,
        name: region.name,
        abbreviation: region.abbreviation,
        UpdatedDate: region._ts,
      }));
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }

  return [];
};

export const getRegionById = async (id: string): Promise<IRegion> => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({id: {value: id, comparisonType: 'EQUAL'}}))
    const region = await axios.Get(`./region/${id}`);
    return region.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }

  return { id: "", name: "", abbreviation: "", _ts: 0 };
};

export const createRegion = async (region: IRegion): Promise<void> => {
  try {
    const response = await axios.Post(`./region`, region);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateRegion = async (region: IRegion): Promise<void> => {
  try {
    const response = await axios.Put('./region', region);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteRegion = async (id: string): Promise<boolean> => {
  try {
    await axios.Delete(`./region`, id);
    return true;
  } catch (error) {
    throw error;
  }
};

export const getRegionsCount = async (filter?:any): Promise<number> => {
  try {
    let queryString = "";
    if (filter) {
      queryString = `&filter=${JSON.stringify(filter)}`;
    }
    const response = await axios.Get(`./region?count=true${queryString}`); 
   
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }

  return 0;
};

export const getFilteredRegions = async (
  filter: any,
  pagination: any,
  sort: any
): Promise<IRegion[]> => {
  try {
    const response = await axios.Get(`./region?
        filter=${JSON.stringify(filter)}
        &sorting=${JSON.stringify(sort)}
        &pagination=${JSON.stringify({pagination, pageNumber: pagination.pageNumber-1})}
        &count=false`);

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }

  return [];
};
