import Axios from "../../../utils/axios";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import '../../../assets/css/AddCatalogs.css';
import DropDownList from "../../../components/Atomos/DropDown/Dropdown";
import useViewport from "../../../hooks/useViewPort";
import Input from "../../../components/Atomos/Inputs/Input";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import { useLogging } from "../../../Context/LoggingContext";
import { getCurrentTimestamp } from "../../../utils/functions";
import { getStatesByCountry } from "../../../Services/State";
import { UseCountryList } from "../../../hooks/Catalogs";
const axios = new Axios();

type listobj = { id: string, name: string };

interface iCatalogProps {
  mode: "add" | "edit";
}

interface Substate {
  id?: string;
  name?: string;
  abbreviation?: string;
  state?: string;
  country?: string;
  countryId?: string;
  stateId?: string;
}

type error = { error: boolean, msj: string };
type errors = {
  name: error;
  abbreviation: error;
  country: error;
  state: error;
};

const AddCountry: React.FC<iCatalogProps> = (props) => {
  const navigate = useNavigate();
  const { viewportWidth } = useViewport();
  const { state } = useLocation();
  const [substate, setSubstate] = useState<Substate>(state ? { ...state, state: state.stateId ?? '' } : { name: '', abbreviation: '', state: '' });
  const [errors, setErrors] = useState<errors>({
    name: { error: false, msj: '' },
    abbreviation: { error: false, msj: '' },
    country: { error: false, msj: '' },
    state: { error: false, msj: '' },
  });
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<listobj>({ id: '', name: '' });
  const [states, setStates] = useState<listobj[]>([]);
  const [selectedState, setSelectedState] = useState<listobj>({ id: '', name: '' });
  const [buttonClicked, setButtonClicked] = useState('');
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const { logActivity } = useLogging();
  const location = useLocation()
  const { countryData } = UseCountryList()

  useEffect(() => {
    setErrors({
      name: { error: false, msj: '' },
      abbreviation: { error: false, msj: '' },
      country: { error: false, msj: '' },
      state: { error: false, msj: '' },
    });
  }, []);

  useEffect(() => {
    if (!countryData.length) return
    const country = countryData.find((c: any) => c.name === state?.countryName);
    if (country) setSelectedCountry(country)
  }, [countryData]);

  useEffect(() => {
    const fetchStates = async () => {
      const response = await getStatesByCountry(selectedCountry.id);
      const newStates = response.filter((item: any) => item.country === selectedCountry.id);
      setStates(newStates);
    }
    fetchStates();
    if (selectedCountry.id === '') setSelectedState({ id: state?.stateId ?? '', name: state?.state ?? '' });

  }, [selectedCountry]);

  useEffect(() => {
    //validate onli if there is an error in the form
    if (errors.name.error || errors.abbreviation.error || errors.country.error || errors.state.error)
      validateForm();

    console.log(substate);
  }, [substate, selectedCountry, selectedState]);

  useEffect(() => {
    if (!errors.name.error && !errors.abbreviation.error && !errors.country.error && !errors.state.error)
      setSaveOverrideOpen(false);
  }, [errorFields]);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setSubstate({ ...substate, [e.target.name]: e.target.value });
  }

  const validateForm = () => {
    let valid = true;
    let newerrors: errors = errors;
    newerrors.name = substate?.name === '' ? { error: true, msj: 'Name is required' } : { error: false, msj: '' };
    newerrors.abbreviation = substate.abbreviation === '' ? { error: true, msj: 'Abbreviation is required' } : { error: false, msj: '' };
    newerrors.country = selectedCountry.id === '' ? { error: true, msj: 'Country is required' } : { error: false, msj: '' };
    newerrors.state = selectedState.id === '' ? { error: true, msj: 'State is required' } : { error: false, msj: '' };

    valid = Object.values(errors).every((error) => !error.error);

    setErrors(errors);
    setErrorFields(Object.keys(errors).filter((key) => errors[key as keyof typeof errors].error));

    if (!valid) setSaveOverrideOpen(true);

    return valid;
  }

  const handleButtonClick = (action: string) => {
    setButtonClicked(action);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (buttonClicked === 'delete') {
      axios.Delete('substate', substate.id ?? '').then(() => {
        logActivity('DELETE_SUBSTATE', location.pathname, JSON.stringify(state ? state : {}));
        navigate('/Staff/Catalogs/Substate');
      });
    } else
      if (buttonClicked === 'saveOverride') {
        if (props.mode === 'add') {
          handleSave(e);
        }
        else {
          handleUpdate(e);
        }
      } else if (validateForm()) {
        if (buttonClicked === 'saveButton') {
          handleSave(e);
        } else if (buttonClicked === 'updateButton') {
          handleUpdate(e);
        }
      }
  }

  const handleSetSelectedState = (e: any) => {
    setSelectedState(e);
    setSubstate({ ...substate, state: e.id });
  }

  const buildSubstateObject = () => { 
    return {
      id: substate.id,
      name: substate.name,
      country: selectedCountry.id,
      countryName: selectedCountry.name,
      abbreviation: substate.abbreviation,
      state: selectedState.id,
      stateName: selectedState.name,
    }
  }

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    const addData = {...buildSubstateObject(), createdDate: getCurrentTimestamp()};

    const res = await axios.Post('substate', addData);
    if (res.status === 200) {
      logActivity('CREATE_SUBSTATE', location.pathname, JSON.stringify(res.data))
      navigate('/Staff/Catalogs/Substate');
    }
    else {
      console.error(res.data);
    }
  }

  const handleUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const addData = buildSubstateObject();
    axios.Put('substate', addData).then((res) => {
      if (res.status === 200) {
        logActivity('UPDATE_SUBSTATE', location.pathname, JSON.stringify({ prevState: JSON.stringify(state), newState: JSON.stringify(res.data) }))
        navigate('/Staff/Catalogs/Substate');
      }
      else {
        console.error(res.data);
      }
    });
  }

  return (
    <main className={`mainSection ${saveOverrideOpen ? 'pt-7 md:pt-9 ' : ''}`}>
      <form onSubmit={handleSubmit}>
        <SaveOverride fields={errorFields} handleButtonClick={handleButtonClick} open={saveOverrideOpen} close={() => { setSaveOverrideOpen(!saveOverrideOpen) }} />
        <CatalogTitle title={`${props.mode === 'add' ? 'New Substate' : 'Edit ' + (state?.name ?? 'none')}`} className='text-Default'>
          <GoBack />
        </CatalogTitle>
        <article className="articleSection">
          <section className="formsectionNTitle">
            <CatalogSubTitle title=" SubState Information" className=" text-accent-Default-dark" />
            <div className="formsection  flex flex-col md:flex-row w-full">
              <div className=" w-full md:w-1/2">
                <Input onChange={onChangeInput} value={substate.name} name="name" title="Name" error={errors.name.error} errorMsj={errors.name.msj} required={true} />
              </div>
              <div className="w-full md:w-1/6 ">
                <Input onChange={onChangeInput} value={substate.abbreviation} name="abbreviation" title="Abbreviation" error={errors.abbreviation.error} errorMsj={errors.abbreviation.msj} required={true} />
              </div>
            </div>
          </section>
          <section className="formsectionNTitle">
            <CatalogSubTitle title="Catalog List Relationships" className=" text-accent-Default-dark" />
            <div className="formsection flex flex-col md:flex-row w-full">
              <div className="w-full md:w-1/2">
                <DropDownList getid required={true} title={"Country"} data={countryData} selectedObjst={selectedCountry.name} seter={setSelectedCountry}
                  error={errors.country.error} errormsj={errors.country.msj} />
              </div>
              <div className="w-full md:w-1/2">
                <DropDownList getid disabled={selectedCountry.id === ''} required={true} title={"State"} data={states} selectedObjst={selectedState.name} seter={handleSetSelectedState}
                  error={errors.state.error} errormsj={errors.state.msj} tooltip="Please select Country." />
              </div>
            </div>
          </section>
          <ButtonsCrud
            Catalog="substate"
            mode={props.mode}
            id={substate?.id ? substate.id : ""}
            disabled={
              errors.name.error ||
              errors.abbreviation.error ||
              errors.country.error ||
              errors.state.error
            }
            actionButton={handleButtonClick}
            onDelete={() => {
              handleButtonClick("delete");
            }}
            state={state}
            newState={buildSubstateObject()}
          />
        </article>
      </form>
    </main>
  );
}
export default AddCountry;