import React from "react";
import { IPerson } from "../../../interfaces";
import VerticalSeparator from "../../../components/Atomos/Icons/VerticalSeparator";
import EmailIcon from "../../../components/Atomos/Icons/EmailIcon";


interface ShortPersonCardProps {
  person: IPerson;
}


const ContactFrame: React.FC<ShortPersonCardProps> = ({ person }) => {
  return (
    <React.Fragment>
      <div className="flex items-center gap-6 lg:gap-[.625rem] lg:flex-1 self-stretch">
        <div className="flex lg:flex-col lg:items-center lg:gap-1">
          <div className="flex flex-col items-center gap-[0.625rem]">
            <div className="w-[5.5rem] h-[5.5rem] rounded-full ">
              <img src={person.photo} className="w-full h-full bg-cover rounded-full" />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-1 self-stretch">
          <span className="text-primary lg:text-xl lg:leading-7 font-bold self-stretch">
            {person.name} {person.lastName}
          </span>
          <span className="text-gray-font lg:text-[1.125rem] lg:leading-5 self-stretch ">
            {person.title}
          </span>
          <div className="flex lg:items-start lg:gap-3 self-stretch">
            <span className="text-gray-font lg:text-[1.125rem] lg:leading-5 self-stretch">
              {person.phoneNumber}
            </span>
            <div className="w-1 h-[1.25rem]">
              <VerticalSeparator />
            </div>
            <div className="flex p-1 justify-center items-center lg:gap-[.625rem] rounded-full bg-primary max-h-[1.25rem] ">
              <div className="w-3 h-3">
                <EmailIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ContactFrame;