function VCard(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="2 1 18 18"
      className={props.className ?? 'w-20 h-20 opacity-50'}
    >
      <path
        d="M5.545 15.176h4.743v-.246c0-.213-.059-.41-.176-.591a1.172 1.172 0 00-.493-.422 4.166 4.166 0 00-1.702-.359 4.166 4.166 0 00-1.702.359c-.211.1-.376.24-.493.422a1.064 1.064 0 00-.177.59v.247zm6.538-1.266h3.334v-.993h-3.334v.993zm-4.168-.993c.31 0 .576-.11.795-.327.219-.218.328-.482.328-.793 0-.311-.108-.576-.326-.795a1.077 1.077 0 00-.793-.329c-.312 0-.577.109-.796.327a1.077 1.077 0 00-.328.793c0 .311.109.576.327.795.217.22.482.329.793.329zm4.168-1.25h3.334v-.994h-3.334v.994zM2.5 18.333V6.667h5.833V2.5H12.5v4.167h5.833v11.666H2.5zm1.25-1.25h13.333V7.917H12.5v1.57H8.333v-1.57H3.75v9.166zm5.833-8.846h1.667V3.75H9.583v4.487z"
      ></path>
    </svg>
  );
}

export default VCard;
