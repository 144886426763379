import React, { useEffect, useState } from "react";
import useDataLoader from "../../hooks/useDataLoader2";
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from "../../assets/images&icons/profileViewMode.jpeg";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import { ColumnConfig } from "../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import { useColumnConfig } from "../../hooks/Table/UseColumnSettings";
import ActivityLogFilter from "../../components/Catalogs/Filters/ActivityLog/ActivityLogFilter";
import { Link } from "react-router-dom";
import Eye2 from "../../components/Atomos/Icons/Eye";
import { convertDateRangeToEpoch, formatTimeSpanToString } from "../../utils/functions";
import { defaultPageSize } from "../../utils/const";
import { IActivityLogsFilters } from "../../interfaces/models/IActivityLogs";
import { IFilters } from "../../interfaces/Components/ITable";

const initialFilters: IActivityLogsFilters = {
  name: "",
  firm: [],
  access: [],
  publishedDate: "",
}
const _persistentFilter: { [key: string]: IFilters } = {
  page: {
  value: "Catalogs",
  comparisonType: "CONTAINS",
}}

const ActivityLog: React.FC = ({ ...props }) => {
  const Headers: string[] = [
    "personId",
    "firm",
    "accessType",
    "action",
    "page",
    "_ts",
    "resourceUpdated",
  ];

  const [tableInstance, setTableInstance] = useState<any>();
  const { generateColumns } = useColumnConfig(Headers);
  const [logFilters, setLogFilters] = useState<IActivityLogsFilters>(initialFilters);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination,
    setPersistentFilter,
    persistentFilter
  } = useDataLoader<any>({
    endpoint: '/activityLog',
    defaultPageSize,
  });

  const columnConfigs: ColumnConfig[] = [
    { id: "count", header: "#", enableSorting: false, size: 50 },
    { id: "personName", header: "User" },
    { id: "firmName", header: "Firm" },
    { id: "accessType", header: "Access Type" },
    { id: "action", header: "Action" },
    { id: "page", header: "Page" },
    {
      id: "_ts",
      header: "Created Date",
      cell: (props: any) => {
        const value = props.getValue();
        return value
          ? new Date(Number(value) * 1000).toLocaleDateString("en-GB", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            })
          : "";
      },
    },
    {
      id: "details",
      header: "Details",
      enableSorting: false,
      cell: (props: any) => (
        <React.Fragment>
          <Link
            to={`details/${props.row.original.id}`}
            state={{
              state: JSON.parse(props.row.original.resourceUpdated),
              actionPerformed: props.row.original.action,
            }}
            className="flex gap-[.625rem] whitespace-nowrap items-center"
          >
            View Log Details
            <div className="editsvg">
              <Eye2 />
            </div>
          </Link>
        </React.Fragment>
      ),
    },
  ];

  const columns = generateColumns(
    columnConfigs.map((column) => ({
      ...column,
      cell:
        column.cell ||
        ((props: any) => {
          switch (column.id) {
            case "count":
              return (
                <p>
                  {props.row.index + 1 + pagination.pageIndex * pagination.pageSize}
                </p>
              );
            case "_ts":
              const value = props.getValue();
              return (
                <p>
                  {value ? formatTimeSpanToString(value) : ""}
                </p>
              );
            default:
              return <p>{props.getValue()}</p>;
          }
        }),
    }))
  );

  const clearSelections = () => {
    setLogFilters(initialFilters)
    fetchData(true);
  };

  useEffect(() => {
    setPersistentFilter(_persistentFilter)
  },[]);

  useEffect(() => {
    setPersistentFilter(_persistentFilter)
    if (!persistentFilter || isFetching || data.length) return
      fetchData();
      setIsFetching(true)
  },[persistentFilter]);

  useEffect(() => {
    let filterAux = {
      personName: {
        value: logFilters.name,
        comparisonType: 'CONTAINS'
      },
      firmId: {
        value: logFilters.firm.map((firm) => firm.id),
        comparisonType: 'EQUAL'
      },
      accessType:{
        value: logFilters.access.map((access) => access.name),
      },
      _ts: {
        value: convertDateRangeToEpoch(logFilters.publishedDate),
        comparisonType: 'BETWEEN_TS'
      },
    }
    setFilter(filterAux)
  }, [logFilters]);

  return (
    <>
      <BackgroundComponent image={ProfileViewModeBg} />
      <main className="mainSection">
        <div className="flex flex-col gap-5 lg:gap-6 lg:pb-[1.125rem] pb-5">
          <CatalogTitle
            title={`Catalogs Activity Log`}
            className="text-white !pb-0"
          />
          <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage
              prevPage="HOME > SEO"
              currentPage={`Activity Log`}
            />
          </div>
        </div>
        <CatalogTableC
          noNewCatalog
          noRedBox
          catalogName={"Activity Log"}
          route={"activityLog"}
          isfiltered={true}
          data={data}
          headers={Headers}
          totalCount={totalCount}
          exportData={data}
        >
          <ActivityLogFilter
            headers={Headers}
            resetFilters={clearSelections}
            search={() => fetchData()}
            tableInstance={tableInstance}
            filters={logFilters}
            setFilters={setLogFilters}
          />
          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </main>
    </>
  );
};

export default ActivityLog;
