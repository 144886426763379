import "../../../../assets/css/AddCatalogs.css";
import DropDownList from "../../../../components/Atomos/DropDown/Dropdown";
import RadioButton from "../../../../components/Atomos/Radio/RadioButton";
import SwitchToogle from "../../../../components/Atomos/Switch-Toogle/Switch";
import NewButton from "../../../../components/Atomos/Buttons/newButton";
import RemoveSign from "../../../../components/Atomos/Icons/RemoveSign";

enum DataAdminType {
  primary = 0,
  dedicated = 1,
}

interface IDataAdminContactProps {
  key: any;
  personName: any;
  membershipLocationName: any;
  type: any;
  emails: any;
  personList: any;
  jurisdictionList: any;
  primary: any;
  personError: any;
  membershipLocationError: any;
  personDuplicityError: any;
  handleSetSelectedPerson: (e: any, index: any) => void;
  handleSetSelectedMembershipLocation: (e: any, index: any) => void;
  checkEditType: (type: number, index: any) => void;
  handleCheckBoxDataSubscribeEmails: (checked: boolean, index: any) => void;
  handleDelete: (index: any) => void;
}

const DataAdminContact: React.FC<IDataAdminContactProps> = ({
  key,
  personName,
  membershipLocationName,
  type,
  emails,
  jurisdictionList,
  personList,
  primary,
  personError,
  membershipLocationError,
  personDuplicityError,
  handleSetSelectedPerson,
  handleSetSelectedMembershipLocation,
  checkEditType,
  handleCheckBoxDataSubscribeEmails,
  handleDelete,
}) => {
  return (
    <div className={`formsection grid grid-cols-11 gap-5 w-full ${(personError||personDuplicityError||membershipLocationError) ? " items-start" : "align-bottom items-end"}`}>
      <div className="grid col-span-11 md:col-span-5">
        <DropDownList
          title="Person"
          name="person"
          error={personError||personDuplicityError}
          errormsj={personError ? "Person is required" : personDuplicityError ? "This Data Admin has already been created" : ""}
          selectedObjst={personName}
          seter={(e) => handleSetSelectedPerson(e, key)}
          data={personList}
          required={true}
          getid
        />
      </div>
      <div className="grid col-span-11 md:col-span-5">
        <DropDownList
          title="Membership Location"
          name="membershipLocation"
          error={membershipLocationError}
          errormsj={"Membership Location is required"} 
          selectedObjst={membershipLocationName}
          seter={(e) => handleSetSelectedMembershipLocation(e, key)}
          data={jurisdictionList}
          required={primary ? false : true}
          disabled={primary || type === DataAdminType.primary}
          getid
        />
      </div>
      <div className={`md:grid hidden col-span-7 md:col-span-1 w-[10%] ${(personError||personDuplicityError||membershipLocationError) ? "self-center":""}`}>
        <NewButton
          className=""
          color="delete"
          style="outlined"
          size="large"
          content="iconSquare"
          icon={<RemoveSign />}
          onClick={() => handleDelete(key)}
        />
      </div>
      <div className="grid col-span-11 md:col-span-5">
        <div className="grid">
          <span className="text-gray-font text-base lg:text-lg not-italic font-normal leading-8">
            DataAdmin Type:
          </span>
        </div>
        <div className="flex flex-1 gap-5">
          <RadioButton
            index={DataAdminType.primary}
            text={"Primary"}
            selected={type === DataAdminType.primary}
            handleRadioButtonChange={(e) => checkEditType(e, key)}
            disabled={primary}
          />
          <RadioButton
            index={DataAdminType.dedicated}
            text={"Dedicated"}
            selected={type === DataAdminType.dedicated}
            handleRadioButtonChange={(e) => checkEditType(e, key)}
            disabled={primary}
          />
        </div>
      </div>
      <div className="grid col-span-11 md:col-span-5">
        <SwitchToogle
          titleFirst
          className="flex-col !items-start"
          centered
          small
          seter={(e) => handleCheckBoxDataSubscribeEmails(e, key)}
          checked={emails}
          title="Subscribe to Notifications Emails"
        />
      </div>
      <div className="grid md:hidden col-span-11 w-full">
        <NewButton
          className="!max-h-[3rem]"
          color="delete"
          text="Remove"
          style="outlined"
          size="large"
          content="textIcon"
          icon={<RemoveSign />}
          onClick={() => handleDelete(key)}
        />
      </div>
    </div>
  );
};
export default DataAdminContact;
