const Update = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="2 2 20 20"
      className={props.className}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M12.0096 20.5C10.8301 20.5 9.72501 20.2769 8.69424 19.8307C7.66348 19.3846 6.76476 18.7782 5.99809 18.0115C5.23143 17.2448 4.62502 16.3461 4.17887 15.3154C3.73272 14.2846 3.50964 13.1795 3.50964 12C3.50964 10.8205 3.73272 9.71537 4.17887 8.6846C4.62502 7.65383 5.23143 6.75512 5.99809 5.98845C6.76476 5.2218 7.66348 4.6154 8.69424 4.16925C9.72501 3.72308 10.8301 3.5 12.0096 3.5C13.2673 3.5 14.4622 3.76443 15.5942 4.29328C16.7262 4.82213 17.698 5.56539 18.5096 6.52308V4.15383H20.0095V9.2307H14.9327V7.73073H17.548C16.8583 6.88073 16.0359 6.2131 15.0807 5.72785C14.1256 5.2426 13.1019 4.99998 12.0096 4.99998C10.0596 4.99998 8.40545 5.67914 7.04712 7.03748C5.68879 8.39581 5.00962 10.05 5.00962 12C5.00962 13.95 5.68879 15.6041 7.04712 16.9625C8.40545 18.3208 10.0596 19 12.0096 19C13.7596 19 15.2856 18.4317 16.5875 17.2952C17.8894 16.1586 18.6667 14.7269 18.9192 13H20.45C20.2064 15.1487 19.2801 16.9359 17.6711 18.3615C16.0622 19.7871 14.175 20.5 12.0096 20.5ZM14.9827 16.0269L11.2596 12.3038V6.99998H12.7596V11.6961L16.0365 14.9731L14.9827 16.0269Z"/>
    </svg>
  );
}

export default Update;
