import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IFirm from "../../../interfaces/models/IFirm";

const initialState: IFirm[] = [];

export const firmsSlice = createSlice({
  name: "firms",
  initialState,
  reducers: {
    SetFirms: (_, action: PayloadAction<IFirm[]>) => action.payload,
    AddFirm: (state, action: PayloadAction<IFirm>) => {
      state.push(action.payload);
    },
    UpdateFirm: (state, action: PayloadAction<IFirm>) => {
      const index = state.findIndex((firm) => firm.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },    
    DeleteFirm: (state, action: PayloadAction<string>) => {
      return state?.filter((firm) => firm.id !== action.payload);
    },
  },
});

export const { SetFirms, AddFirm, UpdateFirm, DeleteFirm } = firmsSlice.actions;
