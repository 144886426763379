import React, { useEffect, useState } from "react";
import ProfileAndSocialLinks from "../../pages/ProfileViewMode/ProfileAndSocials";
import SocialLinks from "./SocialLinks";
import Mail from "../Atomos/Icons/Mail";
import Phone2 from "../Atomos/Icons/Phone";
import { showLoader } from "../../redux/loaderActions";
import { useDispatch } from "react-redux";
import Axios from "../../utils/axios";
import { useStaffMode } from "../../hooks/StaffModeProvider";
const axios = new Axios();
interface ProfessionalCardProps {
  entity?: any;
  isPerson?: boolean;
  isOrganization?: boolean;
  personMainOffice?: any;
  officeCountry?: any;
  viewMoreActive?: boolean;
  isEditable?: boolean;
  isPublicPreview: boolean;
  isWSGFirm: boolean;
  wsgOffice?: any;
  isDataMissing?: (missingFields: string[]) => void;
}

const ProfessionalCard: React.FC<ProfessionalCardProps> = ({
  entity,
  isPerson,
  isOrganization,
  officeCountry,
  personMainOffice,
  viewMoreActive,
  isEditable,
  isWSGFirm,
  wsgOffice,
  isPublicPreview,
  isDataMissing,
}) => {
  const { isStaffMode } = useStaffMode();
  const [userMail, setUserMail] = useState<string>("");
  const [userPhone, setUserPhone] = useState<string>("");
  const [personFirm, setPersonFirm] = useState<any>();
  const dispatch = useDispatch();

  async function getUserFirm() {
    try {
      if (entity) {
        const response = await axios.Get(`/firm`);
        const firmData = response?.data.filter(
          (firm: any) => firm.id === entity.firm
        );
        setPersonFirm(firmData[0]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (entity) {
      setUserMail(
        `mailto:${entity.firmEmail}?subject=${encodeURIComponent("Subject")}`
      );
      setUserPhone(
        `tel:${entity.directCountryCodePhoneNumber?.replace(/[()]/g, "").trim()}${entity?.directPhoneNumber}`
      );
    }
  }, []);

  useEffect(() => {
    getUserFirm();
  }, [entity]);

  const getMissingFields = () => {
    const missingFields = [];
    if (!entity?.name) missingFields.push("Name");
    if (!entity?.lastName) missingFields.push("Last Name");
    if (!personFirm && !personFirm?.name) missingFields.push("Firm");
    if (!personMainOffice?.phoneNumber) missingFields.push("Phone Number");
    if (!entity?.firmEmail) missingFields.push("Email");
    if (!entity?.mainOffice) missingFields.push("Main Office");
    if (!personMainOffice?.address) missingFields.push("Main Office Address");

    return missingFields;
  };

  const handleMissingFields = async () => {
    const missingFields = await getMissingFields();
    if (missingFields.length > 0) {
      isDataMissing && isDataMissing(missingFields);
    } else {
      isDataMissing && isDataMissing([]);
    }
  };

  return (
    <>
      <div
        className="flex flex-col p-0 justify-center items-center  gap-[.625rem] self-stretch shrink grow basis-0 lg:flex-0 h-max 
        lg:w-[30rem] lg:p-0 lg:items-start lg:gap-[.625rem] lg:flex-row"
      >
        <ProfileAndSocialLinks
          isPublicPreview={isPublicPreview}
          person={entity}
          organization={false}
          isEditable={isEditable}
        />
        <div className="flex flex-col justify-center items-end gap-6 flex-1 self-stretch">
          <div
            className={`flex flex-col gap-[.625rem]  justify-center self-stretch
          lg:flex-col-reverse lg:items-start 
          `}
          >
            <SocialLinks
              user={entity}
              organization={false}
              isEditable={isEditable}
              isPublicPreview={isPublicPreview}
              isDataMissing={handleMissingFields}
            />
            {(isPublicPreview || !isEditable) && !isOrganization && (
              <React.Fragment>
                {entity && personMainOffice && personMainOffice.phoneNumber && (
                  <a
                    href={userPhone}
                    className="flex items-center gap-2 self-stretch opacity-50 justify-center lg:justify-start"
                  >
                    <div className="w-[1.25rem] h-[1.25rem]">
                      <Phone2 />
                    </div>
                    <span className="text-gray-font text-[1.125rem] leading-6">
                      {personMainOffice.phoneNumber
                        ? personMainOffice.phoneNumber
                        : ""}
                    </span>
                  </a>
                )}
                <a
                  href={userMail}
                  className="flex items-center gap-2 self-stretch opacity-50 justify-center lg:justify-start"
                >
                  <div className="w-[1.25rem] h-[1.25rem]">
                    <Mail />
                  </div>
                  <span className="text-gray-font text-[1.125rem] leading-6">
                    Send Email
                  </span>
                </a>
              </React.Fragment>
            )}
            <div className="flex flex-col gap-1 w-full lg:items-start lg:gap-1">
              {isWSGFirm && wsgOffice ? (
                <React.Fragment>
                  <h3 className={`${isStaffMode ? 'text-Default' : 'text-primary'} text-center font-decimal text-[1.5rem] font-bold leading-[2rem] md:text-[1.625rem] lg:text-[1.75rem] lg:leading-[2rem]`}>
                    {entity.displayName
                      ? entity.displayName
                      : `${entity.name} ${entity.lastName}`}
                  </h3>
                  <h4 className="text-[#474F56] text-[1rem] leading-[1.125rem] font-semibold text-center lg:text-lg lg:leading-8">
                    {entity.title === "" || !entity.title
                      ? entity.listTitle
                      : entity.title}
                  </h4>
                  <h4 className="text-center text-[#474F56] text-[1rem] font-normal leading-[1.5rem]">
                    {wsgOffice.city}, {wsgOffice.country}
                  </h4>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h3 className={`${isStaffMode ? 'text-Default' : 'text-primary' } text-center font-decimal text-[1.5rem] font-bold leading-[2rem] md:text-[1.625rem] lg:text-[1.75rem] lg:leading-[2rem]`}>
                    {entity.displayName
                      ? entity.displayName
                      : `${entity.name} ${entity.lastName}`}
                  </h3>
                  <h4 className="text-[#474F56] text-[1rem] leading-[1.125rem] font-semibold text-center lg:text-lg lg:leading-8">
                    {entity.title === "" || !entity.title
                      ? entity.listTitle
                      : entity.title}
                  </h4>
                </React.Fragment>
              )}
              {personMainOffice && officeCountry && (
                <h4 className="text-center text-[#474F56] text-[1rem] font-normal leading-[1.5rem]">
                  {personMainOffice.name}, {officeCountry.abbreviation}
                </h4>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfessionalCard;
