import NewButton from "../../../components/Atomos/Buttons/newButton";
import BackgroundComponent from "../../../components/Layout/BackgroundProfile/BackgroundProfile";
import CurrentPage from "../../../components/Layout/CurrentPageTab/CurrentPage";
import ProfileViewModeBg from "../../../assets/images&icons/profileViewMode.jpeg";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { IAddress } from "../../../interfaces/models/IAddress";
import { UseCountryList } from "../../../hooks/Catalogs";
import { getAllCities } from "../../../Services/City";
import { getStates } from "../../../Services/State";
import Axios from "../../../utils/axios";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import { uploadImage } from "../../../utils/UploadImage";
import { Option } from "../../../components/Atomos/DropDown/DDMultiple";
import useViewport from "../../../hooks/useViewPort";
import { useSelector } from "react-redux";
import { Appstore } from "../../../redux";
import OfficeMainContacts from "./OfficeMainContacts";
import AreasOfPracticeOffice from "./AreasOfPracticeOffice";
import { useUpdateAndLog } from "../../../hooks/LogActivity/LogActivityUpdates";
import { useCreateAndLog } from "../../../hooks/LogActivity/LogActivityCreates";
import IOffice, { IPhoneNumber } from "../../../interfaces/models/IOffice";
import OfficeRepresentativeClient from "./OfficeRepresentativeClient";
import MoreInformation from "./MoreInformation";
import LatestUpdate from "./LatestUpdate";
import { useDeleteAndLog } from "../../../hooks/LogActivity/LogActivityDeletes";
import OfficeRepresentativeClientIB from "./OfficeRepresentativeClientIB";
import OfficeGeneralInformation from "./OfficeGeneralInformation";
import OfficeInformation from "./OfficeInformation";

const WSGDots = require("../../../assets/images&icons/WSGDots.svg");
const axios = new Axios();
type listobj = { id: string; name: string };
type error = { error: boolean; msj: string };
type addressErrors = {
  address1: error;
  country: error;
  state: error;
  zipCode: error;
  addressType: error;
};

type officePhoneErrors = {
  phone: error;
};

const ModifyAdditionalOffices = () => {
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);
  const navigate = useNavigate();

  const { state, pathname } = useLocation();
  const update = useUpdateAndLog();
  const create = useCreateAndLog();
  const erase = useDeleteAndLog();

  const initialState = {
    websiteUrl: state?.office?.websiteUrl || "",
    yearFounded: state?.office?.yearFounded || "",
    overview: state?.office?.overview || "",
    isMember: false,
    useFirmWebsiteUrl: true,
    useFirmOverview: true,
  };
  const [formData, setFormData] = useState(initialState);

  const [stateData, setStateData] = useState<any[]>([]);
  const [cityData, setCityData] = useState<any[]>([]);
  const [addressErrors, setAddressErrors] = useState<addressErrors>({
    address1: { error: false, msj: "" },
    country: { error: false, msj: "" },
    state: { error: false, msj: "" },
    zipCode: { error: false, msj: "" },
    addressType: { error: false, msj: "" },
  });

  const [officePhoneErrors, setOfficePhoneErrors] = useState<officePhoneErrors>(
    {
      phone: { error: false, msj: "" },
    }
  );

  const [selectedCountry, setSelectedCountry] = useState<listobj>(() => {
    return state?.office
      ? {
          id: state?.office?.country || "",
          name: state?.office?.countryName || "",
        }
      : { id: "", name: "" };
  });

  const { viewportWidth } = useViewport();
  const [selectedState, setSelectedState] = useState<listobj>({
    id: state?.office?.state || "",
    name: state?.office?.stateName || "",
  });
  const [selectedCity, setSelectedCity] = useState<listobj>({
    id: state?.office?.cityName || "",
    name: state?.office?.cityName || "",
  });
  const [selectedAddressType, setSelectedAddressType] = useState(
    state?.addressType ?? ""
  );
  const { countryData } = UseCountryList();
  const [representativeClients, setRepresentativeClients] = useState<any[]>();
  const [officeAOPS, setOfficeAOPS] = useState<any[]>();
  const [representativeClientsFirm, setRepresentativeClientsFirm] = useState<
    any[]
  >([]);
  const [representativeClientsDelete, setRepresentativeClientsDelete] =
    useState<string[]>();
  const [moreInformation, setMoreInformation] = useState<string>(
    state?.office?.moreInformation || ""
  );

  const [allAddresses, setAllAddresses] = useState<IAddress[]>(
    [] as IAddress[]
  );
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState<IPhoneNumber>({
    code: "",
    phone: "",
    basecode: "",
  });
  const [faxNumber, setFaxNumber] = useState<IPhoneNumber>({
    code: "",
    phone: "",
    basecode: "",
  });
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

  const toggleLanguageModal = () => {
    setIsLanguageModalOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (state.office) {
      setPhoneNumber({
        code: state.office.phoneNumber.code,
        phone: state.office.phoneNumber.phone,
        basecode: state.office.phoneNumber.basecode,
      });
      setFaxNumber({
        code: state.office.faxNumber.code,
        phone: state.office.faxNumber.phone,
        basecode: state.office.faxNumber.basecode,
      });
    }
  }, [state.office]);

  const [firmName, setFirmName] = useState(
    manageFirm.selectedFirm?.firmName || ""
  );
  const [languages, setLanguages] = useState<Option[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<Option[]>(() => {
    return (
      state.office?.languages?.map((language: string) => ({
        id: language,
        name: language,
      })) || []
    );
  });

  const getLanguages = async () => {
    const languages = await axios.Get("language");
    setLanguages(languages.data);
  };

  useEffect(() => {
    const selLanguages = state.office?.languages?.map((language: any) =>
      languages?.find((lang: any) => lang.id === language)
    );
    setSelectedLanguages(
      selLanguages?.filter((lang: any): lang is Option => !!lang) || []
    );
  }, [state.office?.languages, languages]);

  const fetchAddresses = async () => {
    try {
      const res = await axios.Get("/Address");
      if (res && res.data.length > 0) {
        setAllAddresses(res.data);
      }
    } catch (error) {
      console.error("Address Fetching failed, try again another time.");
    }
  };

  const fetchRepresentativeClients = async () => {
    try {
      const res = await axios.Get("/representativeClient");
      if (res && res.data.length > 0) {
        setRepresentativeClients(res.data);
      }
    } catch (error) {
      console.error(
        "Representative Clients fetching failed, try again another time."
      );
    }
  };

  useEffect(() => {
    fetchAddresses();
    getLanguages();
    fetchRepresentativeClients();
  }, []);

  useEffect(() => {
    setAddressErrors({
      address1: { error: false, msj: "" },
      country: { error: false, msj: "" },
      state: { error: false, msj: "" },
      zipCode: { error: false, msj: "" },
      addressType: { error: false, msj: "" },
    });
  }, []);

  useEffect(() => {
    if (representativeClients) {
      const firmRepClients = representativeClients.filter(
        (client: any) => manageFirm.selectedFirm?.id === client.firm
      );
      const officeRepClients = firmRepClients.filter((client: any) => {
        return client.office.includes(state.office.id);
      });
      setRepresentativeClientsFirm(officeRepClients);
    }
  }, [representativeClients]);

  useEffect(() => {
    if (!selectedCountry.id) return;
    getStates().then((data) => {
      if (data != null) {
        const newStates = data.filter(
          (item: any) => item.country === selectedCountry.id
        );
        const filteredAddress = allAddresses.find(
          (add: any) => add.id === state?.office.address
        );
        setSelectedAddress(filteredAddress);
        setStateData(newStates);
      }
    });
    if (selectedCountry.id === "")
      setSelectedState({
        id: manageFirm.selectedFirm?.state ?? "",
        name: manageFirm.selectedFirm?.state ?? "",
      });
  }, [selectedCountry, allAddresses]);

  useEffect(() => {
    if (!selectedState.id) return;
    getAllCities().then((data) => {
      if (data != null) {
        const newCities = data.filter(
          (item: any) => item.state === selectedState.id
        );
        setCityData(newCities);
      }
    });
    if (selectedState.id === "")
      setSelectedCity({
        id: state.office?.city ?? "",
        name: state.office?.cityName ?? "",
      });
  }, [selectedState]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const maxLength = 10;

    if (name === "longitude" || name === "latitude") {
      if (value.length > maxLength) {
        return;
      }
      const trimmedValue = value.trim();

      if (trimmedValue !== "") {
        if (
          trimmedValue.length > maxLength ||
          !/^[-+]?[0-9]*\.?[0-9]+$/.test(trimmedValue)
        ) {
          setAddressErrors((prevState) => ({
            ...prevState,
            [name]: {
              error: true,
              msj: "Invalid format or length exceeds 10 characters",
            },
          }));
        } else {
          setAddressErrors((prevState) => ({
            ...prevState,
            [name]: { error: false, msj: "" },
          }));
        }
      } else {
        setAddressErrors((prevState) => ({
          ...prevState,
          [name]: { error: false, msj: "" },
        }));
      }
      setSelectedAddress((prevAddress: any) => ({
        ...prevAddress,
        [name]: trimmedValue,
      }));
    } else if (name === "firmName") {
      setFirmName(value);
    } else {
      setSelectedAddress((prevAddress: any) => ({
        ...prevAddress,
        [name]: value,
      }));
    }
  };

  const handleFirmDataInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name === "yearFounded") {
      const yearPattern = /^\d{0,4}$/;
      if (value === "" || (yearPattern.test(value) && value.length <= 4)) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSetSelectedCountry = (e: any) => {
    setSelectedCountry(e);
    setSelectedState({ id: "", name: "" });
    setSelectedCity({ id: "", name: "" });

    setAddressErrors((prevState) => ({
      ...prevState,
      country: { error: false, msj: "" },
    }));
  };

  const handleSetSelectedState = (e: any) => {
    setSelectedState(e);
    setSelectedCity({ id: "", name: "" });
    setAddressErrors((prevState) => ({
      ...prevState,
      city: { error: false, msj: "" },
    }));
  };

  const handleSetSelectedCity = (e: any) => {
    setSelectedCity(e);
    setSelectedAddress({ ...selectedAddress, city: e.id });
    setAddressErrors((prevState) => ({
      ...prevState,
      state: { error: false, msj: "" },
    }));
  };

  const handleSetSelectedAddressType = (e: any) => {
    setSelectedAddressType(e);
    setSelectedAddress({ ...selectedAddress, addressType: e });
    setAddressErrors((prevState) => ({
      ...prevState,
      addressType: { error: false, msj: "" },
    }));
  };

  const buildOfficeData = () => {
    return {
      ...state.office,
        id: state.office.id,
        address: selectedAddress?.id,
        addressName: selectedAddress?.address1,
        phoneNumber: {
          phone: phoneNumber.phone,
          code: phoneNumber.code,
          basecode: phoneNumber.basecode,
        },
        faxNumber: {
          phone: faxNumber.phone,
          code: faxNumber.code,
          basecode: faxNumber.basecode,
        },
        yearFounded: formData.yearFounded,
        city: selectedCity.id,
        state: selectedState.id,
        country: selectedCountry.id,
        cityName: selectedCity.name,
        stateName: selectedState.name,
        countryName: selectedCountry.name,
        moreInformation: moreInformation,
        websiteUrl: formData.useFirmWebsiteUrl
          ? manageFirm?.selectedFirm?.websiteUrl
          : formData.websiteUrl,
        languages: selectedLanguages.map((lang: any) => lang.id),
        overview: formData.useFirmOverview
          ? manageFirm.selectedFirm?.overview
          : formData.overview,
    }
  }

  const handleUpdateInfo = async () => {
    if (validateFields()) {
      return;
    }

    try {
      let updatedRepresentativeClientsFirm: any[] = [];
      if (
        representativeClientsFirm?.length > 0 &&
        manageFirm.selectedFirm?.serviceType === "Investment Banking"
      ) {
        updatedRepresentativeClientsFirm = await Promise.all(
          representativeClientsFirm.map(async (item) => {
            if (
              item.logo &&
              item.logo instanceof File &&
              (item.new || item.updated)
            ) {
              const uploadedPhotoUrl = await uploadImage(
                item.logo,
                manageFirm.selectedFirm?.id ?? "",
                "firm/representativeClient/"
              );

              if (uploadedPhotoUrl) {
                return { ...item, logo: uploadedPhotoUrl };
              } else {
                console.error("Error uploading notable item photo.");
                return { ...item, logo: "" };
              }
            }
            return item;
          })
        );
      } else {
        updatedRepresentativeClientsFirm = representativeClientsFirm;
      }

      try {
        const updateAndCreateRepresentativeClients =
          updatedRepresentativeClientsFirm?.map(
            async (representativeClient: any) => {
              if (!representativeClient.new) {
                const prevState = representativeClients?.find(
                  (d: any) => d.id === representativeClient.id
                );
                if (!manageFirm.selectedFirm) return;
                await update.representativeClient({
                  representativeClient,
                  pathname,
                  prevState,
                  firmId: manageFirm.selectedFirm.id,
                });
              } else if (representativeClient.new) {
                delete representativeClient.new;
                if (!manageFirm.selectedFirm) return;
                await create.representativeClient({
                  representativeClient,
                  pathname,
                  firmId: manageFirm.selectedFirm.id,
                });
              }
            }
          );
        const deleteRepresentativeClients = representativeClientsDelete?.map(
          async (id) => {
            if (!manageFirm.selectedFirm) return;
            const representativeClientToDelete = representativeClients?.find(
              (rC: any) => rC.id === id
            );
            await erase.representativeClient({
              representativeClient: representativeClientToDelete?.id,
              pathname,
              firmId: manageFirm.selectedFirm?.id,
            });
          }
        );
        await Promise.all([
          ...(updateAndCreateRepresentativeClients || []),
          ...(deleteRepresentativeClients || []),
        ]);
      } catch (error) {
        console.error(error);
      }

      const officeData: IOffice = buildOfficeData();
      await update.office({
        office: officeData,
        pathname,
        prevState: state.office,
      });

      if (selectedAddress) {
        const address: IAddress = createAddressPayload(
          selectedAddress,
          selectedCity,
          selectedState,
          selectedCountry,
          {
            id: selectedAddress.id,
          }
        );

        await update.address({
          address: address,
          pathname,
          prevState: selectedAddress,
          firmId: manageFirm.selectedFirm?.id ?? "",
        });
      }

      const newOffices = state.offices?.map((office: any) => {
        if (office.id === state.office.id) {
          return { ...office, ...officeData };
        }
        return office;
      });

      navigate(
        `/Staff/ManageFirm/Profile/modify/${manageFirm?.selectedFirm?.id}`,
        { state: { ...state, offices: newOffices } }
      );
    } catch (error) {
      console.error("Error al actualizar la información:", error);
    }
  };

  const validateFields = () => {
    let hasErrors = false;

    const newOfficeErrors: addressErrors = {
      address1: { error: false, msj: "" },
      country: { error: false, msj: "" },
      state: { error: false, msj: "" },
      zipCode: { error: false, msj: "" },
      addressType: { error: false, msj: "" },
    };

    const newPhoneErrors: officePhoneErrors = {
      phone: { error: false, msj: "" },
    };

    const addError = (errorsObj: any, fieldName: string, message: string) => {
      errorsObj[fieldName] = { error: true, msj: message };
      hasErrors = true;
    };

    if (!selectedAddress?.address1) {
      addError(newOfficeErrors, "address1", "Address 1 is required");
    }
    if (!selectedCountry.id) {
      addError(newOfficeErrors, "country", "Country is required");
    }

    if (!selectedCity.id) {
      addError(newOfficeErrors, "city", "City is required");
    }
    if (!selectedAddress?.addressType) {
      addError(newOfficeErrors, "addressType", "Address Type is required");
    }

    setOfficePhoneErrors(newPhoneErrors);
    setAddressErrors(newOfficeErrors);

    return hasErrors;
  };

  const createAddressPayload = (
    address: any,
    cityObj: any,
    stateObj: any,
    countryObj: any,
    additionalFields = {}
  ): IAddress => {
    return {
      ...address,
      ...additionalFields,
      address1: address.address1,
      address2: address.address2,
      zipCode: address.zipCode,
      venueLink: address.venueLink,
      addressType: address.addressType,
      noZipCode: address.noZipCode,
      notes: address.notes,
      city: cityObj.id,
      cityName: cityObj.name,
      country: countryObj.id,
      countryName: countryObj.name,
      state: stateObj.id,
      stateName: stateObj.name,
      longitude: address.longitude,
      latitude: address.latitude,
    };
  };

  const handleCheckBoxChange = (name: string, checked: boolean) => {
    setSelectedAddress((prevState: any) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleCheckBoxFormDataChange = (name: string, checked: boolean) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  const handleChangePhone = (obj: any) => {
    setPhoneNumber({
      code: obj.code,
      phone: obj.phone,
      basecode: obj.basecode,
    });

    setOfficePhoneErrors((prevState) => ({
      ...prevState,
      phone: { error: false, msj: "" },
    }));
  };

  const handleChangeFax = (obj: any) => {
    setFaxNumber({ code: obj.code, phone: obj.phone, basecode: obj.basecode });
  };

  const handleFirmTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addNewLanguage = (newLanguage: Option) => {
    setLanguages((prevLanguages) => [...prevLanguages, newLanguage]);
  };

  useEffect(() => {
    if (state?.aops && state?.office?.id) {
      const filteredOfficesAOPS = state.aops.filter((aop: any) =>
        aop.offices.some(
          (singleOffice: any) => singleOffice.id === state.office.id
        )
      );
      setOfficeAOPS(filteredOfficesAOPS);
    } else {
      setOfficeAOPS([]);
    }
  }, [state?.aops, state?.office?.id]);

  return (
    <>
      <BackgroundComponent image={ProfileViewModeBg} />
      <main className="mainSection">
        <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 !items-end self-stretch !p-0 flex-col my-10">
          <div className="flex p-0 flex-col justify-center items-end gap-5 self-stretch md:flex-row lg:justify-between lg:gap-6">
            <h2 className="text-left w-full md:w-max text-white font-decimal text-[2.25rem] font-medium leading-[2.75rem] md:text-[2.75rem] md:leading-[3.25rem] lg:text-[3.25rem] lg:leading-[4.5rem]">
              Modify Additional Office
            </h2>
            <div
              className="self-stretch flex-1 hidden md:flex"
              style={{
                backgroundImage: `url(${WSGDots.default})`,
              }}
            ></div>
            <div className="flex items-center my-auto">
              <NewButton text="Preview" content="text" color="accent" />
            </div>
          </div>
          <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage
              prevPage="Manage Firm > Profile > Offices"
              currentPage={`Modify Additional Office`}
            />
          </div>
        </div>
        <article className="articleSection relative">
          <section className="formsectionNTitle">
            <OfficeGeneralInformation
              handleChangePhone={handleChangePhone}
              handleChangeFax={handleChangeFax}
              handleInputChange={handleInputChange}
              handleSetSelectedCountry={handleSetSelectedCountry}
              handleSetSelectedState={handleSetSelectedState}
              handleSetSelectedCity={handleSetSelectedCity}
              handleSetSelectedAddressType={handleSetSelectedAddressType}
              handleCheckBoxChange={handleCheckBoxChange}
              countryData={countryData}
              stateData={stateData}
              cityData={cityData}
              selectedCountry={selectedCountry}
              selectedState={selectedState}
              selectedCity={selectedCity}
              selectedAddress={selectedAddress}
              selectedAddressType={selectedAddressType}
              directPhoneCode={phoneNumber}
              faxNumber={faxNumber}
              firmName={firmName}
              addressErrors={addressErrors}
              officePhoneErrors={officePhoneErrors}
            />

            <OfficeInformation
              formData={formData}
              firmName={firmName}
              handleFirmDataInputChange={handleFirmDataInputChange}
              selectedLanguages={selectedLanguages}
              languages={languages}
              addNewLanguage={addNewLanguage}
              setSelectedLanguages={setSelectedLanguages}
              handleFirmTextAreaChange={handleFirmTextAreaChange}
              isLanguageModalOpen={isLanguageModalOpen}
              toggleLanguageModal={toggleLanguageModal}
              viewportWidth={viewportWidth}
              handleCheckBoxChange={handleCheckBoxFormDataChange}
              firmWebsiteUrl={manageFirm?.selectedFirm?.websiteUrl}
              firmOverview={manageFirm?.selectedFirm?.overview}
            />

            <OfficeMainContacts
              officeName={state?.office.name}
              mainContacts={state?.mainContacts}
              firmId={manageFirm?.selectedFirm?.id}
            />

            <AreasOfPracticeOffice
              officeName={state.office.name}
              aops={officeAOPS}
              firmId={manageFirm?.selectedFirm?.id}
            />

            {manageFirm?.selectedFirm?.serviceType !== "Investment Banking" && (
              <OfficeRepresentativeClient
                officeName={state.office.name}
                representativeClients={representativeClientsFirm}
                setRepresentativeClients={setRepresentativeClientsFirm}
                firmId={manageFirm.selectedFirm?.id}
                firmName={manageFirm.selectedFirm?.firmName}
                officeId={state.office.id}
                representativeClientsDelete={representativeClientsDelete}
                setRepresentativeClientsDelete={setRepresentativeClientsDelete}
              />
            )}
            {manageFirm?.selectedFirm?.serviceType === "Investment Banking" && (
              <OfficeRepresentativeClientIB
                officeName={state.office.name}
                representativeClients={representativeClientsFirm}
                setRepresentativeClients={setRepresentativeClientsFirm}
                firmId={manageFirm.selectedFirm?.id}
                firmName={manageFirm.selectedFirm?.firmName}
                officeId={state.office.id}
                representativeClientsDelete={representativeClientsDelete}
                setRepresentativeClientsDelete={setRepresentativeClientsDelete}
              />
            )}

            <MoreInformation
              moreInformation={moreInformation}
              setMoreInformation={setMoreInformation}
            />

            <LatestUpdate lastModified={manageFirm.selectedFirm?.dateCreated} />

            <ButtonsCrud
              Catalog="Address"
              mode={"edit"}
              id={manageFirm.selectedFirm?.id ?? ""}
              disabled={false}
              actionButton={handleUpdateInfo}
              onDelete={() => ""}
              hiddenDelete
              state={state}
              newState={buildOfficeData()}
            />
          </section>
        </article>
      </main>
    </>
  );
};

export default ModifyAdditionalOffices;
