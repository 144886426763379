import Axios from "./axios";

export const formatDateToString = (date: Date | undefined): string => {
  if (!date) return "";
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const formatTimeSpanToString = (value: string): string => {
  return new Date(Number(value) * 1000).toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
};

export const getNameById = (field: any, data: any) => {
  if (!field) return [];
  return data
    .filter((d: any) => field.includes(d.id))
    .map((r: any) => r.name);
};

export const isUUID = (value: string): boolean => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(value);
}

const axios = new Axios();
export const updateData = async (
  endpoint: string, 
  fieldName: string, 
  beforeValue: any,
  fieldValue: any
) => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({[fieldName]: {value: beforeValue, comparisonType: 'EQUAL'}}))
    axios.Get(`/${endpoint}?${params.toString()}`).then((response) => {
      response.data.map((data: any) => {
        const updatedData = {
          ...data,
          [fieldName]: fieldValue
        }
        console.log(updatedData)
        axios.Put(endpoint, updatedData)
      })
    })
  } catch (error) {
    console.error(`Error updating ${endpoint}:`, error);
  }
}

export const getCurrentTimestamp = () => {
  return Math.floor(new Date().getTime() / 1000)
}

export const convertDateRangeToEpoch = (dateRange: string) => {
  if (!dateRange) return "";
  const [startDateString, endDateString] = dateRange.split(" - ");
  const startDate = Math.floor(new Date(startDateString).getTime() / 1000);
  const endDate = Math.floor(new Date(endDateString).getTime() / 1000);
  return `${startDate} - ${endDate}`;
}

export const generateRandomPassword = () => {
  const length = 8;
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const specialChars = "!@#$%^&*()_+[]{}|;:,.<>?";

  let password = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(Math.floor(Math.random() * 26));
  password += "0123456789".charAt(Math.floor(Math.random() * 10));
  password += specialChars.charAt(Math.floor(Math.random() * specialChars.length));

  for (let i = 3; i < length; i++) {
    password += charset.charAt(Math.floor(Math.random() * charset.length));
  }

  return password.split('').sort(() => 0.5 - Math.random()).join('');
};