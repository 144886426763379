import React from "react";

function RemoveSign() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
      <path d="M6.66925 10.6251H13.3359C13.513 10.6251 13.6614 10.5652 13.7812 10.4454C13.901 10.3255 13.9609 10.177 13.9609 9.99987C13.9609 9.82271 13.901 9.6743 13.7812 9.55464C13.6614 9.43499 13.513 9.37516 13.3359 9.37516H6.66925C6.49217 9.37516 6.34374 9.43508 6.22396 9.55491C6.10417 9.67476 6.04427 9.82326 6.04427 10.0004C6.04427 10.1776 6.10417 10.326 6.22396 10.4456C6.34374 10.5653 6.49217 10.6251 6.66925 10.6251ZM10.004 17.9168C8.90903 17.9168 7.87984 17.709 6.9164 17.2935C5.95294 16.8779 5.11487 16.3139 4.40219 15.6016C3.68949 14.8892 3.12528 14.0515 2.70954 13.0885C2.29381 12.1255 2.08594 11.0965 2.08594 10.0015C2.08594 8.90659 2.29372 7.8774 2.70927 6.91395C3.12483 5.9505 3.68878 5.11243 4.40115 4.39975C5.11352 3.68705 5.95122 3.12284 6.91425 2.7071C7.87726 2.29137 8.90624 2.0835 10.0012 2.0835C11.0961 2.0835 12.1253 2.29127 13.0888 2.70683C14.0522 3.12239 14.8903 3.68634 15.603 4.3987C16.3157 5.11108 16.8799 5.94878 17.2956 6.91181C17.7114 7.87482 17.9192 8.9038 17.9192 9.99875C17.9192 11.0937 17.7115 12.1229 17.2959 13.0863C16.8803 14.0498 16.3164 14.8879 15.604 15.6005C14.8916 16.3132 14.0539 16.8774 13.0909 17.2932C12.1279 17.7089 11.0989 17.9168 10.004 17.9168ZM10.0026 16.6668C11.8637 16.6668 13.4401 16.021 14.7318 14.7293C16.0234 13.4376 16.6693 11.8613 16.6693 10.0001C16.6693 8.13903 16.0234 6.56264 14.7318 5.27098C13.4401 3.97931 11.8637 3.33348 10.0026 3.33348C8.14147 3.33348 6.56508 3.97931 5.27342 5.27098C3.98175 6.56264 3.33592 8.13903 3.33592 10.0001C3.33592 11.8613 3.98175 13.4376 5.27342 14.7293C6.56508 16.021 8.14147 16.6668 10.0026 16.6668Z" />
    </svg>
  );
}

export default RemoveSign;
