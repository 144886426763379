import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import React, { useEffect, useState } from "react";
import Input from "../../../components/Atomos/Inputs/Input";

type LatestUpdateProps = {
  lastModified?: any;
  source?: string;
};

const LatestUpdate: React.FC<LatestUpdateProps> = ({
  lastModified,
  source,
}) => {
  return (
    <>
      <div className="flex w-full flex-col gap-5 lg:flex-row lg:justify-between">
        <CatalogSubTitle className="text-Default" title={`Latest Update`} />
      </div>

      <div className="grid w-full gap-5 grid-cols-1 md:grid-cols-2">
        <Input
          title="Last Modification Date"
          error={false}
          value={lastModified?.toString()}
          disabled
        />
        <Input
          title="Source"
          error={false}
          value={"Admin Site"}
          disabled
        />
      </div>
    </>
  );
};

export default LatestUpdate;
