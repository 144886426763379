import NewButton from "../../../components/Atomos/Buttons/newButton";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import React, { useEffect, useState } from "react";
import Input from "../../../components/Atomos/Inputs/Input";
import DeleteIcon from "../../../components/Atomos/Icons/DeleteIcon";
import { set } from "date-fns";
import Plus from "../../../components/Atomos/Icons/Plus";

type OfficeRepresentativeClientProps = {
  officeName: string;
  representativeClients?: any[];
  setRepresentativeClients?: React.Dispatch<
    React.SetStateAction<any[]>
  >;
  firmId?: string;
  firmName?: string;
  officeId?: string;
  representativeClientsDelete?: string[];
  setRepresentativeClientsDelete?: React.Dispatch<
    React.SetStateAction<string[]|undefined>
  >;
};

const OfficeRepresentativeClient: React.FC<OfficeRepresentativeClientProps> = ({
  officeName,
  representativeClients = [],
  setRepresentativeClients,
  firmId,
  firmName,
  officeId,
  representativeClientsDelete = [],
  setRepresentativeClientsDelete,
}) => {
  const [representativeClient, setRepresentativeClient] = useState<any>();

  const handleDelete = (index: number) => {
    const newRepresentativeClients = representativeClients.filter(
      (_, i) => i !== index
    );
    if (!representativeClients[index].new) {
      setRepresentativeClientsDelete?.([...representativeClientsDelete, representativeClients[index].id])
    }
    setRepresentativeClients?.(newRepresentativeClients);
  };

  const handleAddNewRepresentativeClient = () => {
    const newRepresentativeClients = {
      name: representativeClient,
      firm: firmId,
      firmName: firmName,
      office: [officeId],
      officeNames: [officeName],
      new: true,
    };
    setRepresentativeClients?.([
      ...representativeClients,
      newRepresentativeClients,
    ]);
  };

  return (
    <>
      <div className="flex w-full flex-col gap-5 lg:flex-row lg:justify-between">
        <CatalogSubTitle
          className="text-Default"
          title={`${officeName} Representative Client`}
        />
      </div>

      <span className="text-gray-font text-base">
        {`${officeName} has Legal / Accounting Service Type.`}
      </span>

      <div className="flex gap-5 w-full">
        <Input
          title="Representative Client Name"
          error={false}
          value={representativeClient}
          onChange={(e) => setRepresentativeClient(e.target.value)}
        />
        <NewButton
          text={"ADD"}
          content="textIcon"
          color={"accent"}
          className="!self-end"
          icon={<Plus />}
          onClick={() => {
            if (representativeClient) {
              handleAddNewRepresentativeClient();
              setRepresentativeClient("");
            }
          }}
        />
      </div>

      <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-5">
        {representativeClients?.map(
          (representativeClient: any, index: number) => (
            <div className="flex gap-2 px-4 py-2 font-normal lg:text-lg lg:leading-5 text-base leading-6 text-gray-300 border border-gray-300 bg-gray-25">
              <div onClick={() => handleDelete(index)}>
                <DeleteIcon className={"h-5 w-5"} />
              </div>
              <div>{representativeClient.name}</div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default OfficeRepresentativeClient;
