

import { axios } from "..";
import IOfficeContact from "../interfaces/models/IOfficeContact";

export const getMemberMainContact = async () => {
    try {
        const memberMainContact = await axios.Get('./officeContact');
        const data = memberMainContact.data;
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    } 
  };

  export const getOfficeContactByFirmId = async (id: string) => {
    try {
      const params = new URLSearchParams();
      params.append('filter', JSON.stringify({firm: {value: id, comparisonType: 'EQUAL'}}))
      const response = await axios.Get(`./officeContact?${params.toString()}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const updateOfficeContact = async (officeContact: IOfficeContact) => {
    try {
      const response = await axios.Put(`/officeContact`, officeContact);
      return response.data;
    } catch (error) {
      throw error
    }
  };

  export const saveOfficeContact = async (state: IOfficeContact) => {
    try {
      const response = await axios.Post('./officeContact', state);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const deleteOfficeContact = async (id: string) => {
    try {
      await axios.Delete(`/officeContact`, id);
      return true;
    } catch (error) {
      throw error
    }
  };