import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import '../../../assets/css/AddCatalogs.css';
import GoBack from "../../../components/Moleculas/GoBack";
import Input from "../../../components/Atomos/Inputs/Input";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import DropDown from '../../../components/Atomos/DropDown/Dropdown';
import InputImage from "../../../components/Atomos/InputImage/InputImage";
import DDMultiple from "../../../components/Atomos/DropDown/DDMultiple";
import { uploadImage } from "../../../utils/UploadImage";
import { newRepresentativeClient, deleteRepresentativeClient, updateRepresentativeClient } from "../../../Services/RepresentativeClient";
import { createRepresentativeClient, modifyRepresentativeClient, resetRepresentativeClient } from "../../../redux/states/representativeClient.state";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import { useLogging } from "../../../Context/LoggingContext";
import { getCurrentTimestamp } from "../../../utils/functions";
import { getFirms } from "../../../Services/Firm";
import { getOfficeByFirm } from "../../../Services/Office";

interface DataItem {
  id?: string;
  name: string;
  logo?: string;
  firm: string;
  office: string;
  createdDate?: number;
}

interface iCatalogProps {
  mode: "add" | "edit";
}

interface IErrorState {
  firmRequired: boolean;
  nameRequired: boolean;
  nameDuplicated: boolean;
  nameErrorMessage?: string;
  nameDuplicatedMessage?: string;
  logoRequired: boolean;
  logoRequiredMessage: string;
}

const initialErrorState: IErrorState = {
  nameRequired: false,
  nameDuplicated: false,
  nameErrorMessage: "",
  nameDuplicatedMessage: '',
  logoRequired: false,
  logoRequiredMessage: '',
  firmRequired: false,
};

const AddRepresentativeClient: React.FC<iCatalogProps> = (props) => {
  const { state } = useLocation();
  const axios = new Axios();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { mode } = props;
  const [buttonClicked, setButtonClicked] = useState('saveButton');
  const [errorState, setErrorState] = useState<IErrorState>(initialErrorState);
  const [currentName, setCurrentName] = useState(state?.name || '');
  const [logoRequired, setLogoRequired] = useState<boolean>(false);
  const [logoDisabled, setLogoDisabled] = useState<boolean>(false);
  const [logo, setLogo] = useState<string | File | null>(state?.logo || null);
  const [firmData, setFirmData] = useState<any[]>([]);
  const [officeData, setOfficeData] = useState<any[]>([]);
  const [selectedFirm, setSelectedFirm] = useState<any>();
  const [selectedOffice, setSelectedOffice] = useState<any>([]);
  const [showSaveOverride, setShowSaveOverride] = useState(false);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [firmError, setFirmError] = useState(false)
  const { logActivity } = useLogging();
  const location = useLocation()


  const handleServiceTypeRules = (serviceType: string) => {
    switch (serviceType) {
      case "Investment Banking":
        setLogoRequired(true);
        setLogoDisabled(false);
        break;
      case "Legal":
      case "Accounting":
        setLogoRequired(false);
        setLogoDisabled(true);
        break;
      default:
        setLogoRequired(false);
        setLogoDisabled(true);
    }
  };
  
  useEffect(() => {
    if (mode === 'edit' && state) {
      const firm = firmData?.find((f: any) => f.id === state?.firm);
      setCurrentName(state.name);
      setSelectedFirm({id:firm?.id, name:firm?.name, serviceType:firm?.serviceType});
      setButtonClicked('updateButton');
    }
  }, [mode, state, firmData]);
  
  const handleDelete = async (id: string) => {
    try {
      if (state && firmData.length) {
        resetRepresentativeClient()
        deleteRepresentativeClient(state.id)
        logActivity('DELETE_REPRESENTATIVE_CLIENT', location.pathname, JSON.stringify(state ? state : {}));
        setTimeout(() => {
          navigate(-1)
        }, 500)
      }
    } catch (error) {
      console.error(error)
    }
  };

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
    handleSubmit(buttonName);
  };

  const handleSubmit = async (buttonName: string) => {

    if (buttonName === 'saveOverride') {
      await handleSaveOrUpdate('saveOverride');
      return;
    }
    const isLogoRequired = logoRequired && !(logo || state?.logo);

    const errors: IErrorState = {
      ...initialErrorState,
      nameRequired: !currentName.trim(),
      logoRequired: isLogoRequired,
      firmRequired: selectedFirm === '' || !selectedFirm
    };
    setErrorState(errors);

    const errorFields = Object.entries(errors)
      .filter(([key, value]) => value)
      .map(([key]) => {
        switch (key) {
          case "nameRequired":
            return "Name";
          case "logoRequired":
            return "Logo";
          case "firmRequired":
            return "Firm";
          default:
            return "Unknown Field";
        }
      });

    const errorStateWithMessages: IErrorState = {
      ...errorState,
      nameErrorMessage: !currentName.trim() ? 'Name is required' : '',
      logoRequiredMessage: isLogoRequired ? 'Logo is Required' : '',
      nameRequired: !currentName.trim(),
      logoRequired: isLogoRequired,
    }

    setErrorState(errorStateWithMessages);

    if (selectedFirm === '' || !selectedFirm) {
      setFirmError(true)
    }

    const isValid = Object.values(errors).every(error => !error);
    if (isValid) {
      if (logo instanceof File) {
        const userId = 'user-id';
        const path = 'test/representative';
        const uploadedImageUrl = await uploadImage(logo, userId, path);
        if (uploadedImageUrl) {
          handleSaveOrUpdate(uploadedImageUrl);
        } else {
          console.error("Failed to upload image");
        }
      } else {
        handleSaveOrUpdate(state?.logo);
      }
    } else {
      setShowSaveOverride(true);
      setErrorFields(errorFields);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      return
    }
  };

  const buildRepresentativeClientObject = (uploadedImageUrl?: string) => {
    return {
      id: state?.id,
      name: currentName,
      firm: selectedFirm?.id,
      office: selectedOffice?.map((o: any) => o.id),
      officeNames: selectedOffice?.map((o: any) => o.name),
      logo: uploadedImageUrl === 'saveOverride' ? '' : uploadedImageUrl,
    };
  }

  const handleSaveOrUpdate = async (uploadedImageUrl?: string) => {
    let url = ''

    try {
      const data = buildRepresentativeClientObject(uploadedImageUrl);
      if (mode === 'edit') {
        await handleUpdate(data);
      } else {
        await handleSave(data);
      }
    } catch (error: any) {
      console.error('Error processing request:', error);
    }
  };

  const handleSave = async (data: DataItem) => {
    const dataForLogging = {
      ...data,
      firm: selectedFirm?.name || '',
      office: selectedOffice?.name || ''
    };
    const newData = {
      ...data,
      firmName: selectedFirm.name,
      office: selectedOffice.map((o: any) => o.id),
      officeNames: selectedOffice.map((o: any) => o.name).join(', '),
      createdDate: getCurrentTimestamp()
    }
    try {
      logActivity('CREATE_REPRESENTATIVE_CLIENT', location.pathname, JSON.stringify(dataForLogging))
      await newRepresentativeClient(newData)
      createRepresentativeClient(newData)
      navigate(-1)
    } catch (error) {
      console.error(error)
    }
  };

  const handleUpdate = async (data: DataItem) => {
    const newData = {
      ...data,
      firmName: selectedFirm.name,
      officeNames: selectedOffice.map((o: any) => o.name).join(', ')
    }
    try {
      await updateRepresentativeClient(newData);
      modifyRepresentativeClient(newData);
      logActivity('UPDATE_REPRESENTATIVE_CLIENT', location.pathname, JSON.stringify({ prevState: state, newState: newData }))
      setTimeout(() => {
        navigate(-1);
      }, 1000)
    } catch (error: any) {
      console.error('Error actualizando elemento:', error);
    }
  };

  const fetchFirmData = async () => {
    try {
      const response = await axios.Get('/firm?withWSG=true');
      if (response.data) {
        setFirmData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFirmData();
  }, []);

  const handleSelectFirm = (firm: any) => {
    setSelectedFirm(firm);
    setFirmError(false)
    setErrorState({ ...initialErrorState })
  };

  const handleLoadOfficeByFirm = async (firmId: string) => {
    try {
      const response = await getOfficeByFirm(firmId)
      setOfficeData(response);
      const selectedOffice = response.find((o: any) => state?.office?.includes(o.id)) ?? [];
      setSelectedOffice([selectedOffice]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedFirm?.id) {
      handleLoadOfficeByFirm(selectedFirm.id);
      handleServiceTypeRules(selectedFirm.serviceType);
    }
  }, [selectedFirm]);

  const handleNameChange = (e: any) => {
    setCurrentName(e.target.value);
    setErrorState(initialErrorState);
  };

  const handleSetImage = (image: string | File | Blob | null) => {
    if (image instanceof File) {
      setLogo(image);
    } else if (image instanceof Blob) {
      const file = new File([image], "image.png", { type: image.type });
      setLogo(file);
    } else {
      setLogo(null);
    }
  };

  const handleCloseSaveOverride = () => {
    setShowSaveOverride(false);
  };

  return (
    <>
      <main className='mainSection'>
        {showSaveOverride && (
          <SaveOverride
            fields={errorFields}
            open={showSaveOverride}
            close={handleCloseSaveOverride}
            handleButtonClick={handleButtonClick}
          />
        )}
        <CatalogTitle
          title={`${props.mode === 'add' ? 'New Representative Client' : `Edit ${state?.name}`}`}
          className="text-Default"
        >
          <GoBack />
        </CatalogTitle>
        <article className='articleSection'>
          <section className="formsectionNTitle">
            <CatalogSubTitle
              className="text-accent-Default-dark"
              title="Catalog List Relationship"
            />
            <div className="flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 gap-5 self-stretch">
              <div className="w-full">
                <DropDown
                  title="Firm"
                  error={firmError}
                  data={firmData || []}
                  placeholder='Select One'
                  selectedObjst={selectedFirm?.name}
                  seter={handleSelectFirm}
                  getid
                  disableInput
                  required
                  errormsj="Firm is Required"
                />
              </div>

              <div className="w-full">
                <DDMultiple
                  title="Office"
                  error={false}
                  objects={officeData || []}
                  selectedObjst={selectedOffice}
                  seter={setSelectedOffice}
                  placeholder={officeData ? 'Select Multiple' : 'No Office Data'}
                  tooltip="Please select an Office"
                  dynamic
                />
              </div>
              <div className="w-full">
                <DropDown
                  title="Service Type"
                  error={false}
                  data={firmData || []}
                  selectedObjst={selectedFirm?.serviceType}
                  seter={setSelectedFirm}
                  getid
                  disabled
                  tooltip="Please select a service type"
                  dynamic
                />
              </div>
            </div>

            <>
              <CatalogSubTitle className="text-accent-Default-dark" title="Representative Client Information" />
              <div className="self-stretch flex flex-col gap-5 md:flex-row-reverse">
                <Input
                  title="Name"
                  error={errorState.nameRequired || errorState.nameDuplicated}
                  onChange={handleNameChange}
                  value={currentName}
                  errorMsj={errorState.nameErrorMessage || errorState.nameDuplicatedMessage || ''}
                  isWfull
                />
                <InputImage
                  title="Logo"
                  setImage={handleSetImage}
                  required={logoRequired}
                  disabled={logoDisabled}
                  error={logoRequired && !(logo || state?.logo)}
                  photo={logo as string}
                  errorMsg={errorState.logoRequiredMessage}
                />
              </div>

            </>
          </section>
          <ButtonsCrud
            Catalog="Representative Client"
            mode={props.mode}
            id={id || ''}
            disabled={errorState.nameRequired || errorState.nameDuplicated || errorState.firmRequired}
            actionButton={handleButtonClick}
            onDelete={handleDelete}
            state={state}
            newState={buildRepresentativeClientObject()}
          />
        </article>
      </main>
    </>
  );
}

export default AddRepresentativeClient;
