import {
  contentStatusEnum,
  contentTypeEnum,
  isApprovedEnum,
  submissionTypeEnum,
} from "../../Enums/ContentEnums";
import { Option } from "../../components/Atomos/DropDown/DDMultiple";
import IGeneralFields from "./IGeneralFileds";

export type ContentType = keyof typeof contentTypeEnum;
type ContentStatus = keyof typeof contentStatusEnum;
type IsApproved = keyof typeof isApprovedEnum;
type SubmissionType = keyof typeof submissionTypeEnum;

export interface IContent extends IGeneralFields {
  title: string;
  contentType: ContentType;
  writtenDate: undefined | Date;
  reviewedDate: ContentStatus | Date;
  reviewedBy?: string;
  publishedDate: ContentStatus | Date;
  unpublishedDate?: ContentStatus | Date;
  unpublishedBy?: string;
  notifySubscribers?: Boolean;
  totalComments?: number;
  isApproved: IsApproved;
  shortId?: string;
  authors: string[];
  submissionType: SubmissionType;
  footnotes: string;
  link: string;
  memberFirms: Option[];
  nonMemberFirms: Option[];
  jurisdictions: string[];
  tags: Option[];
  articleContent: string;
  generalTopics: string[];
  globalCrisisHub: string[];
  practiceIndustries: string[];
  favorite?: boolean;
  views?: number;
  createdBy?: string;
  firmNames?: string;
  practiceIndustryNames?: string;
  jurisdictionNames?: string;
  regionNames?: string | string[];
  createdDate?: number;
}

export interface IContentFilters {
    title: string;
    firms: Option[],
    isApproved: Option[],
    practiceIndustries: Option[],
    regions: Option[],
    jurisdictions: Option[],
    writtenDate: string,
    publishedDate: string
}

export const contentInitialState:IContentFilters = {
  title:'',
  firms: [],
  isApproved: [],
  practiceIndustries: [],
  regions: [],
  jurisdictions: [],
  writtenDate: '',
  publishedDate: '',
}