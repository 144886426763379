import NewButton from "../../../components/Atomos/Buttons/newButton";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import React from "react";
import Plus from "../../../components/Atomos/Icons/EditMode/Plus";
import ContactFrame from "../GeneralTab/ContactFrame";
import { useNavigate } from "react-router-dom";

type OfficeMainContactsInformationProps = {
  officeName: string;
  mainContacts: any;
  firmId?: string;
};

const OfficeMainContacts: React.FC<OfficeMainContactsInformationProps> = ({
  officeName,
  mainContacts,
  firmId,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex w-full flex-col gap-5 lg:flex-row lg:justify-between">
        <CatalogSubTitle
          className="text-Default"
          title={`${officeName} Main Contacts`}
        />
        <NewButton
          text={"EDIT"}
          icon={<Plus />}
          content="textIcon"
          color={"accent"}
          onClick={() => {navigate(`/Staff/ManageFirm/GeneralTab/${firmId}/MemberMainContact`)}}
        />
      </div>

      <span className="text-gray-font text-base">
        To edit this Office's Main Contacts, you will be redirected to the
        Member Main Contacts Manage page from the General tab. After making your
        changes and saving, you will be automatically returned to this page.
        Clicking 'Cancel' will discard any changes and bring you back to this
        page.
      </span>

      <div className="grid w-full grid-cols-6 gap-5">
        {mainContacts?.map((contact: any, index: number) => (
          <ContactFrame
            key={index}
            name={`${contact.name} ${contact.lastName}`}
            phone={contact.phoneNumber}
            email={contact.firmEmail}
          />
        ))}
      </div>

      
    </>
  );
};

export default OfficeMainContacts;
