import React, { useEffect, useState } from "react";
import Input from "../../Atomos/Inputs/Input";
import DropDown from "../../Atomos/DropDown/Dropdown";
import {
  Honorary,
  ListingCategory,
  MemberType,
  Suffix,
  Title,
} from "../../../Enums/PersonEnums"; 
import InputImage from "../../Atomos/InputImage/InputImage";
import { PhoneNumber } from "../../Atomos/PhoneNumber/PhoneNumber";
import SwitchToogle from "../../Atomos/Switch-Toogle/Switch";
import { useAppContext } from "../../../Context";
import { PersonDataProps } from "../../../interfaces/models/IPerson";
import NewButton from "../../Atomos/Buttons/newButton";
import UserIcon from "../../Atomos/Icons/EditMode/UserIcon";
import CatalogSubTitle from "../Catalog Text/CatalogSubTitle";
import useViewport from "../../../hooks/useViewPort"; 
import Cancel from "../../Atomos/Icons/Close";
import SaveChanges from "../../Atomos/Icons/EditMode/SaveChanges";
import { useLocation, useNavigate } from "react-router-dom";

type personDataEdit = {
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  handleCheckBoxChange: (name: string, checked: boolean) => void;
  handlePhoneNumberChange: (
    codeName: string,
    phoneName: string,
    baseCode: string,
    code: string,
    phone: string,
    base: string
  ) => void;
  offices: any[];
  Errors: any;
  memberType: MemberType;
  setPhoto: (photo: String | File | null) => void;
  isEditMode?: boolean;
  actionButton?: (buttonClicked: string) => void;
  mode?: string;
  generatedId: Number;
  showSendCredentials?: boolean
} & PersonDataProps;

const PersonDataEdit: React.FC<personDataEdit> = (props: personDataEdit) => {
  const [listTitleDisable, setListTitleDisable] = useState<boolean>(
    !props.unlistedTitle
  );
  const [listingCategoryDisbaled, setListingCategoryDisabled] =
    useState<boolean>(!props.unlistedTitle);
  const [officePhoneCode, setOfficePhoneCode] = useState<{
    code: string;
    phone: string;
    basecode: string;
  }>({ code: "", phone: "", basecode: ""});
  const [directPhoneCode, setDirectPhoneCode] = useState<{
    code: string;
    phone: string;
    basecode: string;
  }>({
    code: props.directCountryCodePhoneNumber,
    phone: props.directPhoneNumber,
    basecode: props.directPhoneBaseCode,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCheckBoxIsSerchable = (checked: boolean) => {
    props.handleCheckBoxChange("isSearchable", checked);
  };

  const handleCheckBoxIsAttorney = (checked: boolean) => {
    props.handleCheckBoxChange("isAttorney", checked);
  };

  const handleCheckBoxHasUnlistedTitle = (checked: boolean) => {
    setListTitleDisable(!checked);
    setListingCategoryDisabled(!checked);
    setSelectedListingCategory("");
    props.handleInputChange({
      target: { name: "listTitle", value: "" },
    } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
    props.handleInputChange({
      target: { name: "listingCategory", value: "" },
    } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
    props.handleCheckBoxChange("unlistedTitle", checked);
  };

  const handleCheckBoxHasAssistant = (checked: boolean) => {
    props.handleInputChange({
      target: { name: "assistantName", value: "" },
    } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
    props.handleInputChange({
      target: { name: "assistantEmail", value: "" },
    } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);

    props.handleCheckBoxChange("hasAssistant", checked);
  };

  const [selectedTitle, setSelectedTitle] = React.useState(props.title);
  const handleDropDownChangeTitle = (name: string) => {
    setSelectedTitle(name);
    props.handleInputChange({
      target: { name: "title", value: name },
    } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
  };

  const [selectedSuffix, setSelectedSuffix] = React.useState(props.suffix);
  const handleDropDownChangeSuffix = (name: string) => {
    setSelectedSuffix(name);
    props.handleInputChange({
      target: { name: "suffix", value: name },
    } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
  };

  const [selectedHonorary, setSelectedHonorary] = React.useState(
    props.honorary
  );
  const handleDropDownChangeHonorary = (name: string) => {
    setSelectedHonorary(name);
    props.handleInputChange({
      target: { name: "honorary", value: name },
    } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
  };

  const [selectedListingCategory, setSelectedListingCategory] = React.useState(
    props.listingCategory
  );
  const handleDropDownChangeListingCategory = (name: string) => {
    setSelectedListingCategory(name);
    props.handleInputChange({
      target: { name: "listingCategory", value: name },
    } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
  };

  const [selectedMainOffice, setSelectedMainOffice] = React.useState("");
  useEffect(() => {
    const currentMainOffice = props.offices.find(
      (office) => office.id === props.mainOffice
    );
    setSelectedMainOffice(currentMainOffice?.name ?? "");
    setOfficePhoneCode(currentMainOffice?.phoneNumber);
  }, [props.mainOffice, props.offices]);

  const handleDropDownChangeMainOffice = (obj: any) => {
    setOfficePhoneCode({
      basecode: obj.basecode ?? "",
      code: obj.code ?? "",
      phone: obj.phonenumber ?? "",
    });
    setSelectedMainOffice(obj.name);
    props.handleInputChange({
      target: { name: "mainOffice", value: obj.id },
    } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);

  };

  const handlerDirectPhoneChange = (obj: any) => {
    props.handlePhoneNumberChange(
      "directCountryCodePhoneNumber",
      "directPhoneNumber",
      "directPhoneBaseCode",
      obj.code,
      obj.phone,
      obj.basecode
    );
  };

  const handlerOfficePhoneChange = (obj: any) => {
    props.handlePhoneNumberChange(
      "countryCodePhoneNumber",
      "phoneNumber",
      "basecode",
      obj.code,
      obj.phone,
      obj.basecode
    );
  };

  const { mode } = useAppContext();
  const { viewportWidth } = useViewport();
  const navigate = useNavigate();

  const [urlType, setUrlType] = useState("");

  useEffect(() => {
    if (location.pathname.includes("/staff/")) {
      setUrlType("staff");
    } else {
      setUrlType("member");
    }
  }, [location]);

  const handleNavigateProfile = () => {
    if (urlType === "staff") {
      navigate(
        `/staff/profile/attorney/${props.name.trim()}-${props.lastName.trim()}/${props.generatedId}`
      );
    } else {
      navigate(
        `/profile/attorney/${props.name.trim()}-${props.lastName.trim()}/${props.generatedId}`
      );
    }
  };

  const mainOffices = props.offices;
  return (
    <article className="flex flex-col w-full gap-5">
      {props.isEditMode && (
        <>
          <div className="flex flex-col items-start gap-6 self-stretch">
            <div className="flex flex-col md:flex-row md:justify-end md:items-end md:gap-5 justify-end items-end gap-5  lg:items-center lg:gap-6 self-stretch">
              <h4 className={`w-full h-full my-auto md:min-w-fit lg:min-w-fit ${mode === 'member' ? 'text-Default' : 'text-[#BC3323]'}  text-[1.75rem] leading-[2rem] font-decimal md:text-[1.75rem] md:leading-[2rem] lg:text-[2.375rem] font-medium lg:leading-[2.75rem] flex-1`}>
                Profile Update
              </h4>
              <div className="flex items-start gap-4 w-full md:w-max self-stretch">
                <NewButton
                  content="textIcon"
                  color="neutral"
                  style="outlined"
                  text="CANCEL"
                  icon={<Cancel />}
                  className="lg:!min-w-[8.875rem]"
                  onClick={handleNavigateProfile}
                />
                <button
                  onClick={() =>
                    props.actionButton && props.actionButton("updateButton")
                  }
                  className=" flex-1 h-full  md:w-auto "
                  type="submit"
                >
                  <NewButton
                    content="textIcon"
                    color={urlType === "staff" ? "accent" : "primary"}
                    style="filled"
                    text="Save"
                    icon={<SaveChanges />}
                    className="lg:!min-w-[10rem]"
                  />
                </button>
              </div>
            </div>
            <div className="flex p-[.625rem] items-start gap-[.625rem] self-stretch border-2 border-solid border-[#EBEBEB]">
              <span className="text-base leadin-[1.5rem] text-[#FF6109] text-center md:leading-6 lg:text-[1.125rem] lg:leading-8 flex-1">
                To delete profile, contact your firm's data admin -{" "}
                <span className="font-bold leading-[2rem] text-base lg:text-[1.125rem]">
                  Arvind Gupta
                </span>
              </span>
            </div>
            <span className="text-[#FF6109] text-base leading-[1.5rem] lg:text-[1.125rem] lg:leading-8 self-stretch text-center">
              All fields marked with "*" are required.
            </span>
            <hr className="flex h-0 justify-center self-stretch" />
          </div>
        </>
      )}
      <header className=" flex items-center justify-between gap-4 col-span-12 md:col-span-8 flex-col md:flex-row h-auto">
        <div className="flex flex-col md:flex-row items-start gap-6  lg:gap-5 lg:items-center w-full ">
          <CatalogSubTitle title={"Profile"} className={`${mode === 'member' ? 'text-Default' : 'text-accentDefault'}`} />
          <div className="flex gap-5 items-center justify-end w-full lg:gap-4 self-stretch h-full">
            <div className="flex-2 ">
              <SwitchToogle
                centered
                small
                className="lg:flex lg:flex-row-reverse flex-col-reverse align-middle items-center justify-center"
                seter={handleCheckBoxIsAttorney}
                checked={props.isAttorney}
                title="Practicing Attorney"
              />
            </div>
            {props.memberType !== MemberType.nonMember && (
              <div className="flex-2">
                <SwitchToogle
                  centered
                  small
                  className="lg:flex lg:flex-row-reverse flex-col-reverse align-middle items-center justify-center"
                  seter={handleCheckBoxIsSerchable}
                  checked={props.isSearchable}
                  title="Searchable within Firm Listing"
                />
              </div>
            )}
            {props.showSendCredentials && 
              <NewButton text='SEND CREDENTIALS' className='md:text-base md:leading-[1.125rem] md:!h-7 md:!px-3 md:!py-1' size='small' color='accent' onClick={() => { }} />
            }
          </div>
        </div>
      </header>

      <section className="grid grid-cols-12 gap-5">
        <div className=" col-span-12 md:col-span-3 row-span-1 lg:row-span-3 border-2 border-solid border-gray-25 ">
          <InputImage
            icon={<UserIcon />}
            setImage={props.setPhoto}
            photo={props.photo}
            maxsize={25}
            width={300}
            height={300}
          />
        </div>

        {isMobile ? (
          <>
            <div className="col-span-6">
              <DropDown
                title="Honorary"
                required={false}
                data={Object.values(Honorary).map((value) => ({
                  name: value,
                }))}
                selectedObjst={selectedHonorary}
                seter={handleDropDownChangeHonorary}
                error={false}
                errormsj={""}
              />
            </div>
            <div className="col-span-6">
              <DropDown
                title=" Suffix"
                required={false}
                data={Object.values(Suffix).map((value) => ({
                  name: value,
                }))}
                selectedObjst={selectedSuffix}
                seter={handleDropDownChangeSuffix}
                error={false}
                errormsj={""}
              />
            </div>
            <div className="col-span-8">
              <Input
                title="First Name"
                name="name"
                errorMsj={props.Errors.name.message}
                error={props.Errors.name.error}
                value={props.name}
                onChange={props.handleInputChange}
                required={true}
              />
            </div>
            <div className="col-span-4">
              <Input
                title="Middle Initial"
                name="middleInitial"
                errorMsj={""}
                error={false}
                value={props.middleInitial}
                onChange={props.handleInputChange}
                required={false}
              />
            </div>
            <div className="col-span-12">
              <Input
                title="Last Name"
                name="lastName"
                errorMsj={props.Errors.lastName.message}
                error={props.Errors.lastName.error}
                value={props.lastName}
                onChange={props.handleInputChange}
                required={true}
              />
            </div>
            <div className="col-span-12">
              <Input
                title="Last Name"
                name="lastName"
                errorMsj={props.Errors.lastName.message}
                error={props.Errors.lastName.error}
                value={props.lastName}
                onChange={props.handleInputChange}
                required={true}
              />
            </div>
            <div className="col-span-12">
              <Input
                title="Display Name"
                name="displayName"
                error={false}
                value={props.displayName}
                onChange={props.handleInputChange}
              />
            </div>

            <div className="col-span-12">
              <DropDown
                title="Title"
                required={!props.unlistedTitle}
                data={Object.values(Title).map((value) => ({ name: value }))}
                selectedObjst={selectedTitle}
                seter={handleDropDownChangeTitle}
                error={!props.unlistedTitle ? props.Errors.title.error : false}
                errormsj={
                  !props.unlistedTitle ? props.Errors.title.message : ""
                }
              />
            </div>

            <div className="col-span-12">
              <div
                className=" row-span-1 col-span-12 md:col-span-3 md:col-start-1 lg:col-start-2 
                      flex  flex-col-reverse  items-center 
                      lg:flex-row gap-[0.625rem]"
              >
                <SwitchToogle
                  small
                  seter={handleCheckBoxHasUnlistedTitle}
                  checked={props.unlistedTitle}
                  title=""
                />
                <span className="inputLabel  justify-center text-gray-font">
                  Unlisted Title
                </span>
              </div>
            </div>

            <div className="col-span-12">
              <Input
                disabled={listTitleDisable}
                title="List Title"
                name="listTitle"
                errorMsj={props.Errors.listTitle.message}
                error={props.Errors.listTitle.error}
                value={props.listTitle}
                onChange={props.handleInputChange}
                required={true}
              />
            </div>

            <div className="col-span-12">
              <DropDown
                disabled={listingCategoryDisbaled}
                title="Listing Category"
                required={true}
                data={Object.values(ListingCategory).map((value) => ({
                  name: value,
                }))}
                selectedObjst={selectedListingCategory}
                seter={handleDropDownChangeListingCategory}
                errormsj={props.Errors.listingCategory.message}
                error={props.Errors.listingCategory.error}
              />
            </div>

            <div className="col-span-12">
              <Input
                title={`${
                  props.memberType === MemberType.nonMember
                    ? "Email"
                    : props.memberType === MemberType.member
                    ? "Firm Email"
                    : props.memberType === MemberType.staff
                    ? "WSG Email"
                    : ""
                }`}
                name="firmEmail"
                errorMsj={props.Errors.firmEmail.message}
                error={
                  props.memberType !== MemberType.nonMember &&
                  props.Errors.firmEmail.error
                }
                value={props.firmEmail}
                onChange={props.handleInputChange}
                required={
                  props.memberType !== MemberType.nonMember 
                }
              />
            </div>

            <div className="col-span-12">
              <div
                className="row-span-1 col-span-12 md:col-span-3 md:col-start-1 lg:col-start-2 
                      flex  flex-col-reverse  items-center 
                      lg:flex-row gap-[0.625rem]"
              >
                <SwitchToogle
                  seter={handleCheckBoxHasAssistant}
                  checked={props.hasAssistant}
                  title=""
                  small
                />
                <span className="inputLabel justify-center text-gray-font">
                  CC assistant on WSG Communications
                </span>
              </div>
            </div>

            <div className="col-span-12">
              <Input
                disabled={!props.hasAssistant}
                title="Assistant Name"
                name="assistantName"
                errorMsj={props.Errors.assistantName.message}
                error={props.Errors.assistantName.error}
                value={props.assistantName}
                onChange={props.handleInputChange}
                required={true}
              />
            </div>
            <div className="col-span-12">
              <Input
                disabled={!props.hasAssistant}
                title="Assistant Email"
                name="assistantEmail"
                errorMsj={props.Errors.assistantEmail.message}
                error={props.Errors.assistantEmail.error}
                value={props.assistantEmail}
                onChange={props.handleInputChange}
                required={true}
              />
            </div>
          </>
        ) : (
          <>
            <div className=" col-span-12 md:col-span-9">
              <div className="col-span-12 flex gap-5 justify-start items-start flex-wrap">
                <div className=" md:w-[22%] flex-2 lg:max-w-[12%] lg:flex-auto">
                  <DropDown
                    title="Honorary"
                    required={false}
                    data={Object.values(Honorary).map((value) => ({
                      name: value,
                    }))}
                    selectedObjst={selectedHonorary}
                    seter={handleDropDownChangeHonorary}
                    error={false}
                    errormsj={""}
                  />
                </div>
                <div className=" flex-1 lg:w-1/4 g:flex-auto">
                  <Input
                    title="First Name"
                    name="name"
                    errorMsj={props.Errors.name.message}
                    error={props.Errors.name.error}
                    value={props.name}
                    onChange={props.handleInputChange}
                    required={true}
                  />
                </div>
                <div className="md:w-[22%] flex-2 min-w-[103px] lg:max-w-[12%] g:flex-auto">
                  <Input
                    title="Middle Initial"
                    name="middleInitial"
                    errorMsj={""}
                    error={false}
                    value={props.middleInitial}
                    onChange={props.handleInputChange}
                    required={false}
                  />
                </div>
                <div className=" flex-1 lg:w-[30%] g:flex-auto">
                  <Input
                    title="Last Name"
                    name="lastName"
                    errorMsj={props.Errors.lastName.message}
                    error={props.Errors.lastName.error}
                    value={props.lastName}
                    onChange={props.handleInputChange}
                    required={true}
                  />
                </div>
                <div className="md:w-[22%] lg:w-[15%] flex-2 g:flex-auto">
                  <DropDown
                    title=" Suffix"
                    required={false}
                    data={Object.values(Suffix).map((value) => ({
                      name: value,
                    }))}
                    selectedObjst={selectedSuffix}
                    seter={handleDropDownChangeSuffix}
                    error={false}
                    errormsj={""}
                  />
                </div>
                <div className=" flex-1 lg:w-full g:flex-auto">
                  <Input
                    title="Display Name"
                    name="displayName"
                    error={false}
                    value={props.displayName}
                    onChange={props.handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-span-12 md:col-span-6 lg:col-span-9 grid grid-cols-3 md:grid-row-3 lg:grid-row-2 gap-5 justify-start items-start ">
              <div
                className=" row-span-1 col-span-12 md:col-span-3 md:col-start-1 lg:col-start-2 
                    flex  flex-col-reverse  items-center 
                    lg:flex-row gap-[0.625rem]"
              >
                <SwitchToogle
                  small
                  seter={handleCheckBoxHasUnlistedTitle}
                  checked={props.unlistedTitle}
                  title=""
                />
                <span className="inputLabel  justify-center text-gray-font">
                  Unlisted Title
                </span>
              </div>

              <div className="md:row-start-1 lg:row-start-2 col-span-12  md:col-span-3 lg:col-span-1 ">
                <DropDown
                  title="Title"
                  required={!props.unlistedTitle}
                  data={Object.values(Title).map((value) => ({ name: value }))}
                  selectedObjst={selectedTitle}
                  seter={handleDropDownChangeTitle}
                  error={
                    !props.unlistedTitle ? props.Errors.title.error : false
                  }
                  errormsj={
                    !props.unlistedTitle ? props.Errors.title.message : ""
                  }
                />
              </div>
              <div className="col-span-12 md:col-span-3 lg:col-span-1">
                <Input
                  disabled={listTitleDisable}
                  title="List Title"
                  name="listTitle"
                  errorMsj={props.Errors.listTitle.message}
                  error={props.Errors.listTitle.error}
                  value={props.listTitle}
                  onChange={props.handleInputChange}
                  required={true}
                />
              </div>
              <div className="col-span-12 md:col-span-3 lg:col-span-1">
                <DropDown
                  disabled={listingCategoryDisbaled}
                  title="Listing Category"
                  required={true}
                  data={Object.values(ListingCategory).map((value) => ({
                    name: value,
                  }))}
                  selectedObjst={selectedListingCategory}
                  seter={handleDropDownChangeListingCategory}
                  errormsj={props.Errors.listingCategory.message}
                  error={props.Errors.listingCategory.error}
                />
              </div>
            </div>

            <div className="col-span-12 md:col-span-6 lg:col-span-9 grid grid-cols-3 md:grid-row-3 lg:grid-row-2 gap-5 justify-start items-start ">
              <div
                className="row-span-1 col-span-12 md:col-span-3 md:col-start-1 lg:col-start-2 
                    flex  flex-col-reverse  items-center 
                    lg:flex-row gap-[0.625rem]"
              >
                <SwitchToogle
                  seter={handleCheckBoxHasAssistant}
                  checked={props.hasAssistant}
                  title=""
                  small
                />
                <span className="inputLabel justify-center text-gray-font">
                  CC assistant on WSG Communications
                </span>
              </div>
              <div className="md:row-start-1 lg:row-start-2 col-span-12  md:col-span-3 lg:col-span-1  ">
                <Input
                  title={`${
                    props.memberType === MemberType.nonMember
                      ? "Email"
                      : props.memberType === MemberType.member
                      ? "Firm Email"
                      : props.memberType === MemberType.staff
                      ? "WSG Email"
                      : "Email"
                  }`}
                  name="firmEmail"
                  errorMsj={props.Errors.firmEmail.message}
                  error={
                    props.memberType !== MemberType.nonMember && 
                    props.Errors.firmEmail.error
                  }
                  value={props.firmEmail}
                  onChange={props.handleInputChange}
                  required={
                    props.memberType !== MemberType.nonMember
                  }
                />
              </div>
              <div className="col-span-12 md:col-span-3 lg:col-span-1">
                <Input
                  disabled={!props.hasAssistant}
                  title="Assistant Name"
                  name="assistantName"
                  errorMsj={props.Errors.assistantName.message}
                  error={props.Errors.assistantName.error}
                  value={props.assistantName}
                  onChange={props.handleInputChange}
                  required={true}
                />
              </div>
              <div className="col-span-12 md:col-span-3 lg:col-span-1">
                <Input
                  disabled={!props.hasAssistant}
                  title="Assistant Email"
                  name="assistantEmail"
                  errorMsj={props.Errors.assistantEmail.message}
                  error={props.Errors.assistantEmail.error}
                  value={props.assistantEmail}
                  onChange={props.handleInputChange}
                  required={true}
                />
              </div>
            </div>
          </>
        )}

        <div className=" gap-5 col-span-12 grid grid-cols-12">
          {props.memberType !== MemberType.nonMember && (
            <div className="col-span-12  md:col-span-6 lg:col-span-4">
              <DropDown
                getid
                title="Main Office"
                required={
                  props.memberType === MemberType.member && props.isAttorney
                }
                data={mainOffices}
                selectedObjst={selectedMainOffice}
                seter={handleDropDownChangeMainOffice}
                error={props.Errors.mainOffice.error}
                errormsj={props.Errors.mainOffice.message}
              />
            </div>
          )}
          {/* aqui van los Phone Numbers a componetizar */}
          <div className="col-span-12 md:col-span-6 lg:col-span-4 ">
            <PhoneNumber
              disabled
              title={"Office Phone Number"}
              seter={handlerOfficePhoneChange}
              error={false}
              errormsj={""}
              Obj={{ ...officePhoneCode}}
            />
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-span-4 ">
            <PhoneNumber
              title={"Direct Phone Number"}
              Obj={directPhoneCode}
              seter={handlerDirectPhoneChange}
              error={false}
              errormsj={""}
            />
          </div>
          {props.memberType === MemberType.member && (
            <div className="col-span-12 md:col-span-6 lg:col-span-4 ">
              <Input
                title={"Sync Module Profile URL"}
                name={"profileURL"}
                value={props.profileURL}
                error={false}
                onChange={props.handleInputChange}
              />
            </div>
          )}
        </div>
      </section>
    </article>
  );
};

export default PersonDataEdit;
