import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useEffect, useState, useLayoutEffect } from "react";
import { useNavigate } from 'react-router-dom';
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";

import '../../../assets/css/AddCatalogs.css';
import DropDownList from "../../../components/Atomos/DropDown/Dropdown";
import PlusIcon from "../../../components/Atomos/Icons/PlusIcon";
import Input from "../../../components/Atomos/Inputs/Input";
import Modal from "../../../components/Atomos/Modals/Modal";
import CancelClear from "../../../components/Atomos/Icons/CancelClear";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import { IPerson } from "../../../interfaces/models/IPerson";
import NewButton from "../../../components/Atomos/Buttons/newButton";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import { UseFirmList, firmType } from "../../../hooks/Catalogs/useFirm";
import { useLogging } from "../../../Context/LoggingContext";
import { IEducation } from "../../../interfaces/models/IEducation";
import { getCurrentTimestamp } from "../../../utils/functions";

interface iCatalogProps {
  mode: "add" | "edit";
}

const initialActiveErrors = {
  firmsRequired: false,
  personRequired: false,
  SchoolRequired: false,
  degreeRequired: false,
  startYearRequired: false,
  endYearRequired: false,
  notesRequired: false,
}

const initialModalErrors = {
  schoolRequired: false,
  degreeRequired: false,
}

interface School {
  id: string;
  name: string;
}

interface Degree {
  id: string;
  name: string;
}

function transformFirmData(firmData: any) {
  const newData = () => {
    return {
      id: firmData.id,
      name: firmData.name,
      title: firmData.name,
      disable: false,
    }
  }
  return newData()
}

function transformPersonData(personData: any) {
  const newData = () => {
    return {
      id: personData.id,
      name: personData.name + '' + personData.lastName,
      title: personData.name,
      disable: false,
    }
  }
  return newData()
}

const AddEducation: React.FC<iCatalogProps> = (props) => {
  const { state } = useLocation();
  const axios = new Axios();
  const navigate = useNavigate();
  const location = useLocation()
  const { id } = useParams();
  const { mode } = props;
  const [buttonClicked, setButtonClicked] = useState('');
  const [activeErrors, setActiveErrors] = useState(initialActiveErrors);
  const [activeModalErrors, setActiveModalErrors] = useState(initialModalErrors)

  const [noteOne, setNoteOne] = useState('');
  const [noteTwo, setNoteTwo] = useState('');
  const [noteThree, setNoteThree] = useState('');
  const [noteFour, setNoteFour] = useState('');

  const [noteOneError, setNoteOneError] = useState(false);
  const [noteTwoError, setNoteTwoError] = useState(false);
  const [noteThreeError, setNoteThreeError] = useState(false);
  const [noteFourError, setNoteFourError] = useState(false);
  const [addedSchool, setAddedSchool] = useState<any>('');
  const [addedDegree, setAddedDegree] = useState<any>('');
  const { logActivity } = useLogging();

  const handleNoteChange = (note: string, setNote: React.Dispatch<React.SetStateAction<string>>, setNoteError: React.Dispatch<React.SetStateAction<boolean>>) => (event: any) => {
    const value = event.target.value;
    if (value.length <= 50) {
      setNote(value);
      setNoteError(false);
    } else {
      setNoteError(true);
    }
  };

  const handleNoteOneChange = handleNoteChange(noteOne, setNoteOne, setNoteOneError);
  const handleNoteTwoChange = handleNoteChange(noteTwo, setNoteTwo, setNoteTwoError);
  const handleNoteThreeChange = handleNoteChange(noteThree, setNoteThree, setNoteThreeError);
  const handleNoteFourChange = handleNoteChange(noteFour, setNoteFour, setNoteFourError);
  const [newSchoolModal, setNewSchoolModal] = useState(false);
  const [newDegreeModal, setNewDegreeModal] = useState(false);

  const [startYear, setStartYear] = useState('');
  const [endYear, setEndYear] = useState('');
  const [startYearError, setStartYearError] = useState(false);
  const [endYearError, setEndYearError] = useState(false);

  const [isTabletOrMobile, setIsTabletOrMobile] = useState(window.innerWidth < 768);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [newSchoolButtonText, setNewSchoolButtonText] = useState('');
  const [newDegreeButtonText, setNewDegreeButtonText] = useState('');

  const [showSaveOverride, setShowSaveOverride] = useState(false);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const { firmData: firms } = UseFirmList(firmType.member);

  const attemptSaveOrUpdate = async (e: any) => {
    e.preventDefault();
    const errors = {
      firmsRequired: selectedFirm?.length === 0 || !selectedFirmObj,
      personRequired: selectedPerson?.length === 0,
      SchoolRequired: !selectedSchool,
      degreeRequired: !selectedDegree,
      startYearRequired: startYear?.length < 4,
      endYearRequired: endYear?.length < 4,
      notesRequired: false,
    };

    setActiveErrors(errors);

    const errorFields = Object.entries(errors)
      .filter(([key, value]) => value)
      .map(([key]) => {
        switch (key) {
          case "firmsRequired":
            return "Firm";
          case "personRequired":
            return "Person";
          case "SchoolRequired":
            return "School";
          case "degreeRequired":
            return "Degree";
          case "startYearRequired":
            return "Start Year";
          case "endYearRequired":
            return "End Year";
          default:
            return "Unknown Field";
        }
      });

    const isValid = Object.values(errors).every((error) => !error);

    if (!isValid) {
      setShowSaveOverride(true);
      setErrorFields(errorFields);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      return;
    } else {
      if (mode === 'add') {
        setButtonClicked('saveButton');
      }
      else if (mode === 'edit') {
        setButtonClicked('updateButton');
      }
      setButtonClicked('saveOverride');
      handleSubmit();
    }
  };

  useLayoutEffect(() => {
    const updateSize = () => {
      const width = window.innerWidth;
      setIsTabletOrMobile(width < 768);
      setIsDesktop(width > 1024);

      if (width < 768) {
        setNewSchoolButtonText('');
        setNewDegreeButtonText('');
      } else if (width < 1024) {
        setNewSchoolButtonText('NEW');
        setNewDegreeButtonText('NEW');
      } else {
        setNewSchoolButtonText('NEW SCHOOL');
        setNewDegreeButtonText('NEW DEGREE');
      }
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const handleStartYearChange = (event: any) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setStartYear(value);
      setStartYearError(false);
    } else {
      setStartYearError(true);
    }
    setActiveErrors(prevState => ({ ...prevState, startYearRequired: false }));
    setShowSaveOverride(false);
  };

  const handleEndYearChange = (event: any) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setEndYear(value);
      setEndYearError(false);
    } else {
      setEndYearError(true);
    }
    setActiveErrors(prevState => ({ ...prevState, endYearRequired: false }));
    setShowSaveOverride(false);
  };

  const [selectedFirm, setSelectedFirm] = useState<any>('');
  const [selectedFirmObj, setSelectedFirmObj] = useState<any>();

  const handleSelectFirm = (selectedItem: any) => {
    setSelectedFirm(selectedItem.name);
    setSelectedFirmObj(selectedItem);
    const filteredPersons = allPersons.filter(person => person.firm === selectedItem.id);
    setPersons(filteredPersons);
    setActiveErrors(prevState => ({ ...prevState, firmsRequired: !selectedItem }));
  };

  const [allPersons, setAllPersons] = useState<IPerson[]>([]);
  const [persons, setPersons] = useState<IPerson[]>([]);
  const [selectedPerson, setSelectedPerson] = useState<string>('');
  const [selectedPersonObj, setSelectedPersonObj] = useState<any>();

  const handleSelectPerson = (selectedPerson: any) => {
    const completeName = `${selectedPerson.name}${selectedPerson.lastName ? ' ' + selectedPerson.lastName : ''}`;
    setSelectedPerson(completeName);
    setSelectedPersonObj(selectedPerson);
    setActiveErrors(prevState => ({ ...prevState, personRequired: false }));
  };

  const [schools, setSchools] = useState<any[]>([]); //TODO modify to use Redux and hook
  const [selectedSchool, setSelectedSchool] = useState<any>();
  const [selectedDegree, setSelectedDegree] = useState<any>({id: state?.degreeId, name: state?.degreeName})
  const [schoolSelected, setSchoolSelected] = useState<any>();

  function transformSchoolsData(schoolData: any[]) {
    return schoolData.map((school: any) => ({
      id: school.id,
      name: school.name,
      title: school.name,
      disable: false
    }));
  }

  const handleSchoolSelect = (selectedItem: any) => {
    setSelectedSchool(selectedItem.name);
    setSchoolSelected(selectedItem);
    setActiveErrors(prevState => ({ ...prevState, SchoolRequired: !selectedItem }));
  };

  const handleDegreeSelect = (selectedItem: any) => {
    setSelectedDegree(selectedItem.name);
    setDegreeSelected(selectedItem);
    setActiveErrors(prevState => ({ ...prevState, degreeRequired: !selectedItem }));
  };

  const [degree, setDegree] = useState<any[]>([]); //TODO modify to use Redux and hook
  const [degreeSelected, setDegreeSelected] = useState<any>({id: state?.degreeId, name: state?.degreeName});

  function transformDegreesData(degreesData: any[]) {
    return degreesData.map((degree: any) => ({
      id: degree.id,
      name: degree.name,
      title: degree.name,
      disable: false
    }));
  }

  useEffect(() => {
    const loadPersons = async () => {
      try {
        const response = await axios.Get('/person');
        if (response.data) {
          setAllPersons(response.data);
        }
      } catch (error) {
        console.error('Error fetching all persons', error);
      }
    };

    loadPersons();
  }, []);

  const getSchools = async () => {
    try {
      const response = await axios.Get('/school');
      if (response && response.data) {
        setSchools(transformSchoolsData(response.data));
      }
    } catch (error) {
      console.error('Error fetching schools', error);
    }
  };

  const getDegrees = async () => {
    try {
      const response = await axios.Get('/degree');
      if (response.data)
        setDegree(transformDegreesData(response.data));
    } catch (error) {
      console.error('Error fetching degree', error);
    }
  };

  useEffect(() => {
    if (mode === 'add') {
      getDegrees();
      getSchools();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === 'edit') {
      const setupEditData = async () => {
        if (!firms.length || !schools.length || !degree.length) {
          await Promise.all([getSchools(), getDegrees()]);
        }

        if (state.firm) {
          const firmDataToSelect = transformFirmData(state.firm);
          handleSelectFirm(firmDataToSelect);
        }

        if (state.person) {
          const personDataToSelect = transformPersonData(state.person);
          handleSelectPerson(personDataToSelect);
        }

        if (state?.school) {
          const schoolDataToSelect = schools.find(school => school.id === state.school.id);
          if (schoolDataToSelect) handleSchoolSelect(schoolDataToSelect);
        }

        if (state?.degree) {
          const degreeDataToSelect = degree.find(deg => deg.id === state.degree.id);
          if (degreeDataToSelect) handleDegreeSelect(degreeDataToSelect);
        }

        if (state.startYear) setStartYear(state.startYear);
        if (state.endYear) setEndYear(state.endYear);

        if (state.notes) {
          const notesArray = state.notes.split('\n');
          setNoteOne(notesArray[0] || '');
          setNoteTwo(notesArray[1] || '');
          setNoteThree(notesArray[2] || '');
          setNoteFour(notesArray[3] || '');
        }
      };

      setupEditData();
    }
  }, []);

  const handleDelete = async (id: string) => {
    const response = await axios.Delete(`/Education`, id);
    if (response.status === 200 && id && id !== '') {
      logActivity('DELETE_EDUCATION', location.pathname, JSON.stringify(state ? state : {}));
      navigate('/Staff/Catalogs/Education');
    } else {
      console.error('Error al eliminar el elemento:', response.data);
    }
  };

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
    if (buttonName === 'saveOverride') {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (buttonClicked === 'updateButton') {
      handleUpdate();
    } else if (buttonClicked === 'saveButton') {
      handleSave();
    } else if (buttonClicked === 'saveOverride') {
      handleOverride();
    }
  };

  const handleOverride = async () => {
    try {
      const addData: IEducation = {
        startYear: Number(startYear),
        endYear: Number(endYear),
        schoolId: schoolSelected?.id,
        degreeId: degreeSelected?.id,
        firmId: selectedFirmObj?.id,
        firmName: selectedFirmObj?.name,
        notes: [noteOne, noteTwo, noteThree, noteFour].join('\n'),
        personId: selectedPersonObj?.id,
        personName: selectedPersonObj?.name,
        schoolName: schoolSelected?.name,
        degreeName: selectedDegree?.name,
        createdDate: getCurrentTimestamp()
      };
      const response = await axios.Post('/Education', addData);
      if (response && response.status === 200) {
        setTimeout(() => {
          navigate('/Staff/Catalogs/Education');
        }, 200);
      }
    } catch (error: any) {
      console.error('Error creating element:', error);
    }
  };

  const handleSave = async () => {
    try {
      const addData = {
        ...setEducation(), 
        createdDate: getCurrentTimestamp()
      };

      const response = await axios.Post('/Education', addData);
      if (response && response.status === 200) {
        logActivity('CREATE_EDUCATION', location.pathname, JSON.stringify(response.data));
        setTimeout(() => {
          navigate('/Staff/Catalogs/Education');
        }, 200);
      }
    } catch (error: any) {
      console.error('Error creating element:', error);
    }
  };

  const handleUpdate = async () => {
    if (state.id) {
      const updatedData = setEducation()
      try {
        const response = await axios.Put(`Education`, updatedData);
        if (response.status === 200 || response.status === 201) {
          logActivity('UPDATE_EDUCATION', location.pathname, JSON.stringify({ prevState: JSON.stringify(state), newState: JSON.stringify(updatedData) }));
          navigate("/Staff/Catalogs/Education");
        }
      } catch (error: any) {
        console.error("Error al actualizar el elemento:", error);
      }
    }
  };

  const setEducation = () => {
    const updatedData: IEducation = {
      id: state?.id ?? '',
      startYear: Number(startYear),
      endYear: Number(endYear),
      schoolId: selectedSchool?.id,
      schoolName: schoolSelected?.name,
      degreeId: selectedDegree?.id,
      degreeName: selectedDegree?.name,
      firmId: selectedFirmObj?.id,
      firmName: selectedFirmObj?.name,
      notes: [noteOne, noteTwo, noteThree, noteFour].join('\n'),
      personId: selectedPersonObj?.id,
      personName: selectedPersonObj?.name,
      createdDate: state?.createdDate ?? '',
    };
    return updatedData
  }
  const characterCount = (note: string) => `${note.length}/50`;

  const toggleNewSchoolModal = () => {
    setNewSchoolModal(!newSchoolModal);
  };

  const handleNewSchoolName = (e: any) => {
    e.preventDefault();
    setAddedSchool(e.target.value);
    const errors = {
      ...initialModalErrors,
      schoolRequired: false,
    };
    setActiveModalErrors(errors);
  };

  const addNewSchool = async () => {
    setDuplicateError(false);
    if (addedSchool.trim() === '') {
      const errors = {
        ...initialModalErrors,
        schoolRequired: true,
      };
      setActiveModalErrors(errors);
      return;
    }
    await addSchool(addedSchool);
  };

  const [duplicateError, setDuplicateError] = useState(false);

  const addSchool = async (name: string) => {
    try {
      let newSchoolData = {
        name: name
      };
      const response = await axios.Post('/school', newSchoolData);
      const responseError = response.data.error;
      if (responseError && responseError === 'Name already exists') {
        const errors = {
          ...initialModalErrors,
          schoolRequired: true,
        };
        setDuplicateError(true);
        setActiveModalErrors(errors);
        return;
      } else {
        setTimeout(() => {
          toggleNewSchoolModal();
          getSchools();
          setAddedSchool('');
          setDuplicateError(false);
        }, 200);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleNewDegreeModal = () => {
    setNewDegreeModal(!newDegreeModal);
  };

  const handleNewDegreeName = (e: any) => {
    e.preventDefault();
    setAddedDegree(e.target.value);
    const errors = {
      ...initialModalErrors,
      schoolRequired: false,
    };
    setActiveModalErrors(errors);
  };

  const addNewDegree = async () => {
    setDuplicateError(false);
    if (addedDegree.trim() === '') {
      const errors = {
        ...initialModalErrors,
        degreeRequired: true,
      };
      setActiveModalErrors(errors);
      return;
    }
    await addDegree(addedDegree);
  };

  const addDegree = async (name: string) => {
    try {
      let newDegreeData = {
        name: name
      };
      const response = await axios.Post('/degree', newDegreeData);
      const responseError = response.data.error;
      if (responseError && responseError === 'Name already exists') {
        const errors = {
          ...initialModalErrors,
          degreeRequired: true,
        };
        setDuplicateError(true);
        setActiveModalErrors(errors);
        return;
      } else {
        setTimeout(() => {
          toggleNewDegreeModal();
          getDegrees();
          setAddedDegree('');
          setDuplicateError(false);
        }, 200);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dataList = [
    { title: "Firms", required: true, getid: true, data: firms, selectedObjst: selectedFirm, seter: handleSelectFirm, error: activeErrors.firmsRequired, errormsj: 'Please select one Firm' },
    { title: "Person", required: true, getid: true, data: persons, selectedObjst: selectedPerson, seter: handleSelectPerson, error: activeErrors.personRequired, errormsj: 'Please select one Person' },
  ];

  const newDataList = [
    { title: "School", required: true, getid: true, data: schools, selectedObjst: selectedSchool, seter: handleSchoolSelect, error: activeErrors.SchoolRequired, errormsj: 'Please select one School' },
    { title: "Degree", required: true, getid: true, data: degree, selectedObjst: selectedDegree, seter: handleDegreeSelect, error: activeErrors.degreeRequired, errormsj: 'Please select one Degree' },
  ];

  const handleCloseSaveOverride = () => {
    setShowSaveOverride(false);
  };

  const CancelIcon = () => (
    <div className="w-[1.5rem] h-[1.5rem] flex items-center justify-center">
      <CancelClear />
    </div>
  );

  const AddIcon = () => (
    <div className="w-[1.5rem] h-[1.5rem] flex items-center justify-center">
      <PlusIcon />
    </div>
  );

  return (
    <>
      {newSchoolModal &&
        <Modal titleColor="#0070CD" isModalOpen={newSchoolModal} title={'New School'} toggleModal={toggleNewSchoolModal} children={
          <>
            <div className="flex flex-col gap-6">
              <div>
                <Input title={'Name'} error={activeModalErrors.schoolRequired} errorMsj={`${duplicateError ? 'This value is already registered in the DB. Please input a different/new one.' : 'Name is required'}`} required={true} value={addedSchool} onChange={handleNewSchoolName} />
              </div>
              <div className='flex md:grid md:grid-cols-2 lg:flex lg:grid-cols-none lg:flex-row justify-center flex-col-reverse gap-[1rem] md:gap-[1rem] lg:justify-end items-center'>
                <NewButton color="neutral" style="outlined" onClick={() => setNewSchoolModal(false)} content='textIcon' text="CANCEL" icon={<CancelIcon />} />
                <NewButton color="accent" style="filled" onClick={() => addNewSchool()} content='textIcon' text="ADD" icon={<AddIcon />} />
              </div>
            </div>
          </>
        } />
      }
      {newDegreeModal &&
        <Modal titleColor="#0070CD" isModalOpen={newDegreeModal} title={'New Degree'} toggleModal={toggleNewDegreeModal} children={
          <>
            <div className="flex flex-col gap-6">
              <div>
                <Input required title={'Name'} error={activeModalErrors.degreeRequired} value={addedDegree} errorMsj={`${duplicateError ? 'This value is already registered in the DB. Please input a different/new one.' : 'Name is required'}`} onChange={handleNewDegreeName} />
              </div>
              <div className='flex md:grid md:grid-cols-2 lg:flex lg:grid-cols-none lg:flex-row justify-center flex-col-reverse gap-[.625rem] md:gap-[1rem] lg:justify-end'>
                <NewButton color="neutral" style="outlined" onClick={() => setNewDegreeModal(false)} content='textIcon' text="CANCEL" icon={<CancelIcon />} />
                <NewButton color="accent" style="filled" onClick={() => addNewDegree()} content='textIcon' text="ADD" icon={<AddIcon />} />
              </div>
            </div>
          </>
        } />
      }
      <div className={`mainSection ${showSaveOverride && 'pt-12'}`}>
        {showSaveOverride && (
          <SaveOverride
            fields={errorFields}
            open={showSaveOverride}
            close={handleCloseSaveOverride}
            handleButtonClick={(buttonClicked) => {
              handleButtonClick(buttonClicked);
            }}
          />
        )}
        <CatalogTitle title={`${props.mode === 'add' ? 'New Education' : `Edit ${state?.name}`}`} className="text-Default">
          <GoBack />
        </CatalogTitle>

        <div className='bg-white lg:p-10 md:p7 py-5 px-4'>
          <form onSubmit={attemptSaveOrUpdate}>
            <div className="w-full flex flex-col items-start self-stretch gap-[1.5rem]">
              <div className="">
                <h5 className="lg:mb-[.625rem] w-1/2 md:w-full grid-cols-2 font-decimal text-[1.5rem] md:text-[1.75rem] md:leading-8 lg:text-[2rem] leading-[2.5rem] font-medium text-[#004578]">Catalog List Relationships</h5>
              </div>
              <div className="w-full flex flex-col md:grid md:grid-cols-2 gap-x-5 gap-y-5 lg:gap-y-10">
                {dataList.map((item, index) => (
                  <div key={index} className={`${index === dataList.length - 1 ? 'lg:mb-0' : 'lg:mb-10'}`}>
                    <DropDownList
                      title={item.title}
                      required={item.required}
                      getid={item.getid}
                      data={item.data}
                      selectedObjst={item.selectedObjst && item.selectedObjst}
                      seter={item.seter}
                      error={item.error}
                      errormsj={item.errormsj}
                      disableInput={true} // Aquí deshabilitamos la entrada de texto

                    />
                  </div>
                ))}
              </div>
              <div className="w-full flex flex-col gap-[1.25rem]">
                <h4 className="lg:mb-[.625rem] font-decimal text-[1.5rem] md:text-[1.75rem] lg:text-[2rem] text-accent-Default-dark font-medium leading-[2.25rem] w-full">Education Information</h4>

                <div className="w-full flex">
                  <div className="w-full flex flex-col gap-[1.25rem]">
                    {newDataList.map((item, index) => (
                      <div key={index} className={`${index === newDataList.length - 1 ? 'mb-0' : 'mb-0'} ${activeErrors.SchoolRequired ? 'mb-2' : ''}`}>
                        <div className="flex gap-x-[1.25rem] justify-between items-end">
                          <DropDownList
                            title={item.title}
                            required={item.required}
                            getid={item.getid}
                            data={item.data}
                            selectedObjst={item.selectedObjst}
                            seter={item.seter}
                            error={item.error}
                            errormsj={item.errormsj}
                            disableInput={true} // Aquí deshabilitamos la entrada de texto
                          />
                          {item.title === "School" ? (
                            <div className={`flex h-auto ${activeErrors.SchoolRequired ? 'items-center' : 'items-end'}`}>
                              <NewButton className="" size="small" color="accent" onClick={toggleNewSchoolModal} text={newSchoolButtonText} content="textIcon" icon={<PlusIcon />} />
                            </div>
                          ) : null}

                          {item.title === "Degree" ? (
                            <div className={`flex h-auto ${activeErrors.degreeRequired ? 'items-center' : 'items-end'}`}>
                              <NewButton className="" size="small" color="accent" onClick={toggleNewDegreeModal} text={newDegreeButtonText} content="textIcon" icon={<PlusIcon />} />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={`flex flex-col md:flex-row gap-5`}>
                  <Input
                    isWfull={true}
                    title={'Start Year'}
                    required={true}
                    error={startYearError || activeErrors.startYearRequired}
                    errorMsj={(startYearError || activeErrors.startYearRequired) ? "Please enter up to 4 digits." : ""}
                    name="startYear"
                    value={startYear}
                    onChange={handleStartYearChange}
                  />
                  <Input
                    isWfull={true}
                    title={'End Year (or expected)'}
                    required={true}
                    error={endYearError || activeErrors.endYearRequired}
                    errorMsj={(endYearError || activeErrors.endYearRequired) ? "Please enter up to 4 digits." : ""}
                    name="endYear"
                    value={endYear}
                    onChange={handleEndYearChange}
                  />
                </div>
                <div className="">
                  <span className="text-[#474F56] text-[1.125rem] leading-5 mb-[.625rem]">Notable</span>
                  <div className="flex flex-col gap-y-5 md:gap-y-4"> {/* Ajuste aquí para aplicar el espacio entre grupos */}
                    <div className="">
                      <Input
                        isWfull={true}
                        title={''}
                        required={true}
                        error={noteOneError}
                        errorMsj={noteOneError ? "Maximum characters exceeded (50)." : ""}
                        value={noteOne}
                        onChange={handleNoteOneChange}
                      />
                      <div className="flex justify-end">
                        <span className="flex text-base leading-[1.125rem] text-[#474F56] pt-[.625rem] px-2">{characterCount(noteOne)}</span>
                      </div>
                    </div>
                    <div className="">
                      <Input
                        isWfull={true}
                        title={''}
                        required={true}
                        error={noteTwoError}
                        errorMsj={noteTwoError ? "Maximum characters exceeded (50)." : ""}
                        value={noteTwo}
                        onChange={handleNoteTwoChange}
                      />
                      <div className="flex justify-end">
                        <span className="flex text-base leading-[1.125rem] text-[#474F56] pt-[.625rem] px-2">{characterCount(noteTwo)}</span>
                      </div>
                    </div>
                    <div className="">
                      <Input
                        isWfull={true}
                        title={''}
                        required={true}
                        error={noteThreeError}
                        errorMsj={noteThreeError ? "Maximum characters exceeded (50)." : ""}
                        value={noteThree}
                        onChange={handleNoteThreeChange}
                      />
                      <div className="flex justify-end">
                        <span className="flex text-base leading-[1.125rem] text-[#474F56] pt-[.625rem] px-2">{characterCount(noteThree)}</span>
                      </div>
                    </div>
                    <div className="">
                      <Input
                        isWfull={true}
                        title={''}
                        required={true}
                        error={noteFourError}
                        errorMsj={noteFourError ? "Maximum characters exceeded (50)." : ""}
                        value={noteFour}
                        onChange={handleNoteFourChange}
                      />
                      <div className="flex justify-end">
                        <span className="flex text-base leading-[1.125rem] text-[#474F56] pt-[.625rem] px-2">{characterCount(noteFour)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ButtonsCrud
                Catalog="Education"
                mode={props.mode}
                id={id ? id : ""}
                /*disabled={disabled}*/ disabled={false}
                actionButton={handleButtonClick}
                onDelete={handleDelete}
                state={state}
                newState={setEducation()}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddEducation;
