import IFirm from "../interfaces/models/IFirm";
import Axios from "../utils/axios";

const axios = new Axios();

export const getFirms = async (): Promise<IFirm[]> => {
  try {
    const response = await axios.Get('/firm');
    return response.data;
  } catch (error) {
    console.error('Error fetching firms:', error);
    return [];
  }
}

export const getOfficesByFirmId = async (id:string): Promise<IFirm[]> => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({firm: {value: id, comparisonType: 'EQUAL'}}))
    const response = await axios.Get(`/office?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching firms:', error);
    return [];
  }
}
export const getAllFirms = async (): Promise<IFirm[]> => {
  try {
    const response = await axios.Get(`/firm?withWSG=true`);
    return response.data;
  } catch (error) {
    console.error('Error fetching firms:', error);
    return [];
  }
}

export const getFirmById = async (id: string): Promise<IFirm> => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({id: {value: id, comparisonType: 'EQUAL'}}))
    const response = await axios.Get(`/firm?${params.toString()}`);
    return response.data[0];
  } catch (error) {
    console.error('Error fetching firm:', error);
    throw error;
  }
}

export const createFirm = async (firm: IFirm): Promise<IFirm> => {
  try {
    const response = await axios.Post('/firm', firm);
    return response.data;
  } catch (error) {
    console.error('Error creating firm:', error);
    throw error;
  }
}

export const updateFirm = async (firm: IFirm): Promise<IFirm> => {
  try {
    const response = await axios.Put(`/firm`, firm);
    return response.data;
  } catch (error) {
    console.error('Error updating firm:', error);
    throw error;
  }
}

export const deleteFirm = async (id: string): Promise<boolean> => {
  try {
    const response = await axios.Delete(`/firm`, `${id}`);
    return response.status === 200;
  } catch (error) {
    console.error('Error deleting firm:', error);
    return false;
  }
}
