 
import { error } from "console";
import { axios } from "..";
import { Option } from "../components/Atomos/DropDown/DDMultiple";
import { IState } from "../interfaces";

export const getState = async (id: string) => {
  const response = await axios.Get("state/id=" + id);
  const state: IState = response.data as IState;
  return state;
};

export const getStates = async () => {
  const states = await axios.Get("state");
  return states.data.map((state: IState) => ({
    id: state.id,
    name: state.name,
    abbreviation: state.abbreviation,
    country: state.country,
    UpdatedDate: state._ts,
  }));
};
export const saveState = async (state: IState) => {
  try {
    const response = await axios.Post('./State', state);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//actualizar un estado
export const updateState = async (state: IState) => {
  try {
    const response = await axios.Put('./State', state);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//eliminar un estado
export const deleteState = async (id: string) => {
  try {
    await axios.Delete(`/State`, id);
    return true;
  } catch (error) {
    throw error
  }
};


 
//obtener los estados para mostrar en un dropdown o lista
export const getStatesListOptions = async (filter:any) => {
  console.log(filter); 
  const isObject = (val: any) => val && typeof val === 'object' && !Array.isArray(val);

  const filterData = filter ? isObject(filter) ? filter : JSON.parse(filter) : {};

  const StateOptionsFields = ["id", "name"];
  const response = await axios.Get(`/State?fields=${JSON.stringify(StateOptionsFields)}&filter=${JSON.stringify(filterData)}&count=false`);
  const states = response.data;
  return states?.map((state: Option) => ({
    id: state.id,
    name: state.name,
  }));
}

//obtener los estados por filtros
export const GetStatesByFilters = async (
  filters: any, 
  pagination: {pageNumber: number, pageSize: number}, 
  sorting: any, 
) => {
  try {

    const queryString = `/State?filter=${JSON.stringify(filters)}&pagination=${JSON.stringify({...pagination,pageNumber:pagination.pageNumber-1})}&sorting=${JSON.stringify(sorting)}&count=false`;
    
    const response = await axios.Get(queryString);
     return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return []
  }
};

//obtener el conteo total de estados
export const GetTotalStates = async () => {
  try {
    const response = await axios.Get('/State?count=true');
    const data = response.data;
    if (data != null) { 
     return data;
    }
  } catch (error) {
    console.error('Error fetching total count:', error);
  }
};

export const getStatesByCountry = async (countryId: string) => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({firm: {value: countryId, comparisonType: 'EQUAL'}}))
    const response = await axios.Get(`./State?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching total count:', error);
  }
};
