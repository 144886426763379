import NewButton from "../../../components/Atomos/Buttons/newButton";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Input from "../../../components/Atomos/Inputs/Input";
import TextArea from "../../../components/Atomos/Inputs/TextArea";
import React from "react";
import DDMultiple, {
  Option,
} from "../../../components/Atomos/DropDown/DDMultiple";
import Plus from "../../../components/Atomos/Icons/EditMode/Plus";
import AddLanguageModal from "../../../components/Moleculas/PersonCatalog/AddLanguageModal";

type FirmGeneralInformationProps = {
  firmName: string;
  formData: any;
  handleFirmDataInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  filteredProfessionals: number;
  filteredOffices: number;
  languages: Option[];
  addNewLanguage: (language: Option) => void;
  selectedLanguages: Option[];
  setSelectedLanguages: React.Dispatch<React.SetStateAction<Option[]>>;
  handleFirmTextAreaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isLanguageModalOpen: boolean;
  toggleLanguageModal: () => void;
  viewportWidth: number;
  firmErrors: any;
};

const FirmInformation: React.FC<FirmGeneralInformationProps> = ({
  formData,
  firmName,
  handleFirmDataInputChange,
  filteredProfessionals,
  filteredOffices,
  selectedLanguages,
  languages,
  addNewLanguage,
  setSelectedLanguages,
  handleFirmTextAreaChange,
  isLanguageModalOpen,
  toggleLanguageModal,
  viewportWidth,
  firmErrors,
}) => {
  return (
    <>
      <CatalogSubTitle
        className="text-[#0070CD]"
        title={`${firmName} Information`}
      />

      <div className="flex flex-col items-start gap-5 self-stretch">
        <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-5 self-stretch">
          <Input
            title="Website Url"
            name="websiteUrl"
            errorMsj={firmErrors.websiteUrl.msj}
            error={firmErrors.websiteUrl.error}
            value={formData.websiteUrl}
            onChange={handleFirmDataInputChange}
            required
          />
          <Input
            title="Professionals Worldwide"
            name="professionalsWorldWide"
            tooltip="This field uses data from the WSG DB"
            errorMsj={firmErrors.professionalsWorldWide?.msj}
            error={firmErrors.professionalsWorldWide?.error}
            value={filteredProfessionals.toString()}
            onChange={handleFirmDataInputChange}
            required={false}
            disabled
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-5 self-stretch">
          <Input
            title="Year Founded"
            name="yearFounded"
            errorMsj={firmErrors.yearFounded?.msj}
            error={firmErrors.yearFounded?.error}
            value={formData.yearFounded}
            onChange={handleFirmDataInputChange}
            required={formData.isMember}
          />
          <Input
            title="Litigation Percentage"
            name="litigationPercentage"
            tooltip="Enter value in % unit. Ex: 15 for 15%"
            errorMsj={firmErrors.litigationPercentage?.msj}
            error={firmErrors.litigationPercentage?.error}
            value={formData.litigationPercentage}
            onChange={handleFirmDataInputChange}
            required={formData.isMember}
            percentage
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-5 self-stretch">
          <Input
            title="Office Year Founded"
            name="officeYearFounded"
            errorMsj={firmErrors.officeYearFounded?.msj}
            error={firmErrors.officeYearFounded?.error}
            value={formData.officeYearFounded}
            onChange={handleFirmDataInputChange}
            required={formData.isMember}
          />
          <Input
            title="Offices Worldwide"
            name="officesWorldwide"
            tooltip="This field uses data from the WSG DB"
            errorMsj={firmErrors.officesWorldwide?.msj}
            error={firmErrors.officesWorldwide?.error}
            value={filteredOffices.toString()}
            onChange={handleFirmDataInputChange}
            required={formData.isMember}
            disabled
          />
        </div>
        <AddLanguageModal
          open={isLanguageModalOpen}
          toggleModal={toggleLanguageModal}
          onAddLanguage={addNewLanguage}
        />
        <div className="flex flex-col gap-5 w-full self-stretch">
          <span className="text-gray-font font-bold text-left text-[1.375rem] leading-6 ont-bold relative self-stretch">
            Languages
          </span>
          <div className="flex gap-5 items-end self-stretch w-full">
            <div className="w-full self-stretch">
              <DDMultiple
                title=""
                objects={languages}
                selectedObjst={selectedLanguages}
                seter={setSelectedLanguages}
              />
            </div>
            <div className="self-auto">
              <NewButton
                text={`${viewportWidth > 1023 ? "ADD" : viewportWidth > 768 ? "ADD" : ""}`}
                icon={<Plus />}
                content="textIcon"
                color={"accent"}
                onClick={toggleLanguageModal}
              />
            </div>
          </div>
        </div>
        <div className="flex items-start gap-5 self-stretch">
          <div className="flex p-0 flex-col items-start gap-[.625rem] flex-1">
            <span className="text-primary text-lg leading-5 w-max flex ">
              Firm Overview *
            </span>
            <div className="flex p-0 flex-col item-start gap-0 self-stretch">
              <span className="text-black text-base">
                Each word contained in your law firm listing is monitored by WSG
                powerful search engine. It allows prospective clients to find
                your information throughout the website's listings. It is
                therefore very important that your texts and descriptions
                represent your law firm in the most accurate and comprehensive
                way.
              </span>
              <TextArea
                title=""
                name="overview"
                characterCount
                maxChars={3500}
                value={formData.overview}
                error={firmErrors.overview.error}
                onChange={handleFirmTextAreaChange}
                errorMsj={firmErrors.overview.msj}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirmInformation;
