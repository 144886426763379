import BackgroundComponent from "../../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from "../../../assets/images&icons/profileViewMode.jpeg";
import CurrentPage from "../../../components/Layout/CurrentPageTab/CurrentPage";
import TabManager from "../../../components/Layout/TabManager/TabManager";
import { useEffect, useState } from "react";
import { useColumnConfig } from "../../../hooks/Table/UseColumnSettings";
import { useSelector } from "react-redux";
import { Appstore } from "../../../redux";
import {
  convertDateRangeToEpoch,
  formatTimeSpanToString,
} from "../../../utils/functions";
import { ILogFilters } from "../../../interfaces/models/IActivityLogs";
import { ColumnDef } from "@tanstack/react-table";
import CatalogTableC from "../../../components/Catalogs/CatalogTableC";
import LogFilter from "../../../components/Catalogs/Filters/LogFilter";
import TableWithPagination from "../../../components/Organismos/Table/TableTransitionToTanSack2";
import useDataLoader from "../../../hooks/useDataLoader2";
import GoBack from "../../../components/Moleculas/GoBack";
const WSGDots = require("../../../assets/images&icons/WSGDots.svg");

const ContentHome = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [tableInstance, setTableInstance] = useState<any>();
  const [headers, setHeader] = useState<string[]>([
    "fullName",
    "email",
    "date",
  ]);
  const [columns, setColumns] = useState<ColumnDef<any>[]>([]);
  const { generateColumns } = useColumnConfig(headers);
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);
  const [logData, setLogData] = useState([]);

  const {
    data,
    totalCount,
    fetchData,
    setFilter,
    sort,
    setSort,
    pagination,
    setPagination,
    setPersistentFilter,
    persistentFilter,
  } = useDataLoader<any>({
    endpoint: "/activityLog",
    defaultPageSize: 50,
  });

  const tabs = [
    { title: "Login History", action: () => setSelectedTab(0) },
    { title: "Profile Updates", action: () => setSelectedTab(1) },
  ];

  const [logFilters, setLogFilters] = useState<ILogFilters>({
    name: "",
    type: [],
    date: "",
  });

  const clearSelections = () => {
    setLogFilters({ name: "", type: [], date: "" });
    fetchData(true);
  };

  useEffect(() => {
    let _headers: string[] = [];
    let _columns: ColumnDef<any>[] = [];
    if (selectedTab === 0) {
      _headers = ["fullName", "email", "loginDate"];
      _columns = generateColumns([
        {
          id: "count",
          header: "#",
          cell: (props: any) => (
            <p>
              {props.row.index + 1 + pagination.pageIndex * pagination.pageSize}
            </p>
          ),
        },
        {
          id: "fullName",
          header: "Full Name",
          cell: (props: any) => props.getValue(),
        },
        {
          id: "email",
          header: "Email",
          cell: (props: any) => props.getValue(),
        },
        {
          id: "loginDate",
          header: "Login Date",
          cell: (props: any) => props.getValue(),
        },
      ]);
    } else {
      _headers = ["action", "source", "updatedBy", "date"];
      _columns = generateColumns([
        {
          id: "action",
          header: "Action",
          cell: (props: any) => props.getValue(),
        },
        {
          id: "source",
          header: "Source",
          cell: (props: any) => props.getValue(),
        },
        {
          id: "updatedBy",
          header: "Updated By",
          cell: (props: any) => props.getValue(),
        },
        {
          id: "date",
          header: "Date",
          cell: (props: any) => props.getValue(),
        },
      ]);
    }

    setHeader(_headers);
    setColumns(_columns);
  }, [selectedTab]);

  useEffect(() => {
    setPersistentFilter({
      firmId: {
        value: manageFirm.selectedFirm?.id,
        comparisonType: "EQUAL",
      },
      action: {
        value: "LOGIN",
        comparisonType: selectedTab === 0 ? "EQUAL" : "NOT_EQUAL",
      },
      page: {
        value: selectedTab === 0 ? "login" : "ManageFirm",
        comparisonType: "CONTAINS",
      },
    });
    setLogFilters({ name: "", type: [], date: "" });
    setPagination({ ...pagination, pageIndex: 0 });
  }, [manageFirm.selectedFirm, selectedTab]);

  const formatData = (data: any) => {
    const formattedData = selectedTab === 0 
    ? data.map((d: any) => {
      return {
        ...d,
        fullName: d.personName,
        loginDate: formatTimeSpanToString(d._ts)
      };
    })
    : data.map((d: any) => {
      return {
        ...d,
        updatedBy: d.personName,
        source: d.page,
        date: formatTimeSpanToString(d._ts)
      };
    });
    return formattedData;
  };

  
  useEffect(() => {
    if (data) setLogData(formatData(data))
  },[data]);

  useEffect(() => {
    if (!persistentFilter) return;
    fetchData(true);
  }, [persistentFilter]);

  useEffect(() => {
    let filterAux = {
      personName: {
        value: logFilters.name,
        comparisonType: "CONTAINS",
      },
      _ts: {
        value: convertDateRangeToEpoch(logFilters.date),
        comparisonType: "BETWEEN_TS",
      },
      memberType: {
        value: logFilters.type.map((f) => Number(f.id)),
        comparisonType: "EQUAL",
      },
    };
    setFilter(filterAux);
  }, [logFilters]);

  return (
    <>
      <BackgroundComponent image={ProfileViewModeBg} />
      <div className="bg-gray-100 min-h-screen text-left px-4 py-7 items-start flex flex-col gap-[1.5rem] md:py-8 md:px-6 lg:py-10 lg:px-10 lg:gap-[2.5rem]">
        <div className="flex flex-col items-start gap-5 self-stretch">
          <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 self-stretch !p-0 flex-col ">
            <div className="flex p-0 flex-col justify-center gap-5 self-stretch md:flex-row lg:justify-between lg:gap-6">
              <h2 className="text-left w-full lg:w-max text-white font-decimal text-[2.25rem] font-medium leading-[2.75rem] md:text-[2.75rem] md:leading-[3.25rem] lg:text-[3.25rem] lg:leading-[4.5rem]">
                Login History
              </h2>
              <div
                className="self-stretch flex-1 hidden lg:flex"
                style={{
                  backgroundImage: `url(${WSGDots.default})`,
                }}
              ></div>
              <div className="flex h-[4rem] w-fit items-center">
                <GoBack className="!text-white" />
              </div>
            </div>
            <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
            <div className="flex w-full">
              <CurrentPage
                prevPage="Proffessionals"
                currentPage="Login History"
              />
            </div>

            <div className="">
              <TabManager tabs={tabs} selectedTabIndex={selectedTab} />
              <CatalogTableC
                catalogName="PROFILE UPDATE"
                route={selectedTab === 0 ? "Login History" : 'Profile Updates' }
                catalogShortName="Profile Update"
                isfiltered={true}
                data={logData}
                headers={headers}
                totalCount={totalCount}
                exportData={logData}
                noNewCatalog
              >
                <LogFilter
                  filters={logFilters}
                  setFilters={setLogFilters}
                  headers={headers}
                  clearSelections={clearSelections}
                  Search={() => fetchData()}
                  tableInstance={tableInstance}
                  disabledType={selectedTab !== 0}
                />
                <TableWithPagination
                  data={logData}
                  columnsDef={columns}
                  setTableInstance={setTableInstance}
                  sorting={sort}
                  setSorting={setSort}
                  totalCount={totalCount}
                  pagination={pagination}
                  setPagination={setPagination}
                />
              </CatalogTableC>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentHome;
