import { useLogging } from "../../Context/LoggingContext";
import { ICity, IContent, IContentGeneralTopic, ICountry, IDegree, IJurisdiction, IJurisdictionGroup, IRegion, IState } from "../../interfaces";
import { IAddress } from "../../interfaces/models/IAddress";
import IContentTag from "../../interfaces/models/IContentTag";
import IDataAdmin from "../../interfaces/models/IDataAdmin";
import IFirm from "../../interfaces/models/IFirm";
import IFirmContact from "../../interfaces/models/IFirmContact";
import IFirmNote from "../../interfaces/models/IFirmNote";
import { IRepresentativeClient } from "../../interfaces/models/IRepresentativeClient";
import IGlobalCrisis from "../../interfaces/models/IGlobalCrisis";
import IOfficeContact from "../../interfaces/models/IOfficeContact";
import { IPracticeIndustry } from "../../interfaces/models/IPracticeIndustry";
import { createAddress } from "../../Services/Address";
import { addCity } from "../../Services/City";
import { createContent } from "../../Services/Content";
import { addContentGeneralTopics } from "../../Services/ContentGeneralTopic";
import { addContentTag } from "../../Services/ContentTag";
import { createCountry } from "../../Services/Country";
import { createDataAdmin } from "../../Services/DataAdmin";
import { createFirm } from "../../Services/Firm";
import { createFirmContact } from "../../Services/FirmContact";
import { createFirmNote } from "../../Services/FirmNote";
import { newRepresentativeClient } from "../../Services/RepresentativeClient";
import { addGlobalCrisis } from "../../Services/GlobalCrisis";
import { createJurisdiction } from "../../Services/Jurisdiction";
import { createJurisdictionGroup } from "../../Services/JurisdictionGroup";
import { saveOfficeContact } from "../../Services/MemberMainContact";
import { addPracticeIndustry } from "../../Services/PracticeIndustry";
import { createRegion } from "../../Services/Region";
import { saveState } from "../../Services/State";
import { ISchool } from "../../interfaces/models/ISchool";
import { IEducation } from "../../interfaces/models/IEducation";
import { addSchool } from "../../Services/School";
import { addDegree } from "../../Services/Degree";
import { postEducation } from "../../Services/Education";

interface IUseGeneral {
  pathname: string;
}

interface Ifirm extends IUseGeneral {
  firm: Omit<IFirm, 'id'>;
}
interface IdataAdmin extends IUseGeneral {
  dataAdmin: Omit<IDataAdmin, 'id'>;
}
interface IfirmContact extends IUseGeneral {
  firmContact: Omit<IFirmContact, 'id'>;
}
interface IfirmNote extends IUseGeneral {
  firmNote: Omit<IFirmNote, 'id'>;
}
interface Iaddress extends IUseGeneral {
  address: Omit<IAddress, 'id'>;
  firmId: string;
}
interface Icontent extends IUseGeneral {
  content: Omit<IContent, 'id'>;
  firmId: string;
}
interface Icity extends IUseGeneral {
  city: Omit<ICity, 'id'>;
}

interface IgeneralTopic extends IUseGeneral { generalTopic: IContentGeneralTopic; }
interface IcontentTag extends IUseGeneral { contentTag: IContentTag; }
interface Icountry extends IUseGeneral { country: ICountry; }
interface IglobalCrisis extends IUseGeneral { globalCrisis: IGlobalCrisis; }
interface Ijurisdiction extends IUseGeneral { jurisdiction: IJurisdiction; }
interface IjurisdictionGroup extends IUseGeneral { jurisdictionGroup: IJurisdictionGroup; }
interface IpracticeIndustry extends IUseGeneral { practiceIndustry: IPracticeIndustry; }
interface Iregion extends IUseGeneral { region: IRegion; }
interface Istate extends IUseGeneral { state: IState; }
interface IofficeContact extends IUseGeneral { officeContact: IOfficeContact; }
interface IrepresentativeClient extends IUseGeneral { representativeClient: IRepresentativeClient; firmId: string;}
interface Ischool extends IUseGeneral { school: ISchool; firmId: string;}
interface Idegree extends IUseGeneral { degree: IDegree; firmId: string;}
interface Ieducation extends IUseGeneral { education: IEducation;}

interface ICreateAndLog {
  createFunction: Function;
  entity: any;
  entityName: string;
  pathname: string;
  firmId?: string;
}

const action = "CREATE_";

export const useCreateAndLog = () => {
  const { logActivity } = useLogging();

  const createAndLog: (params: ICreateAndLog) => Promise<any> = async ({
    createFunction,
    entity,
    entityName,
    pathname,
    firmId,
  }) => {
    try {
      const response = await createFunction(entity);
      logActivity(
        `${action}${entityName}`,
        pathname,
        JSON.stringify(response ?? {}),
        firmId ?? response.id
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const firm = async ({ firm, pathname }: Ifirm) => {
    return await createAndLog({
      createFunction: createFirm,
      entity: firm,
      entityName: "FIRM",
      pathname,
    });
  };

  const dataAdmin = async ({ dataAdmin, pathname }: IdataAdmin) => {
    return await createAndLog({
      createFunction: createDataAdmin,
      entity: dataAdmin,
      entityName: "DATA_ADMIN",
      pathname,
      firmId: dataAdmin.firm,
    });
  };

  const firmContact = async ({ firmContact, pathname }: IfirmContact) => {
    return await createAndLog({
      createFunction: createFirmContact,
      entity: firmContact,
      entityName: "FIRM_CONTACT",
      pathname,
      firmId: firmContact.firm,
    });
  };

  const firmNote = async ({ firmNote, pathname }: IfirmNote) => {
    return await createAndLog({
      createFunction: createFirmNote,
      entity: firmNote,
      entityName: "FIRM_NOTE",
      pathname,
      firmId: firmNote.firm,
    });
  };

  const address = async ({ address, pathname, firmId }: Iaddress) => {
    return await createAndLog({
      createFunction: createAddress,
      entity: address,
      entityName: "ADDRESS",
      pathname,
      firmId,
    });
  };

  const content = async ({ content, pathname, firmId }: Icontent) => {
    return await createAndLog({
      createFunction: createContent,
      entity: content,
      entityName: "CONTENT",
      pathname,
      firmId,
    });
  };

  const city = async ({ city, pathname }: Icity) => {
    return await createAndLog({
      createFunction: addCity,
      entity: city,
      entityName: "CITY",
      pathname,
    });
  };

  const contentGeneralTopic = async ({ generalTopic, pathname }: IgeneralTopic) => {
    return await createAndLog({
      createFunction: addContentGeneralTopics,
      entity: generalTopic,
      entityName: "GENERAL_TOPIC",
      pathname,
    });
  };

  const contentTag = async ({ contentTag, pathname }: IcontentTag) => {
    return await createAndLog({
      createFunction: addContentTag,
      entity: contentTag,
      entityName: "CONTENT_TAG",
      pathname,
    });
  };

  const country = async ({ country, pathname }: Icountry) => {
    return await createAndLog({
      createFunction: createCountry,
      entity: country,
      entityName: "COUNTRY",
      pathname,
    });
  };

  const globalCrisis = async ({ globalCrisis, pathname }: IglobalCrisis) => {
    return await createAndLog({
      createFunction: addGlobalCrisis,
      entity: globalCrisis,
      entityName: "GLOBAL_CRISIS",
      pathname,
    });
  };

  const jurisdiction = async ({ jurisdiction, pathname }: Ijurisdiction) => {
    return await createAndLog({
      createFunction: createJurisdiction,
      entity: jurisdiction,
      entityName: "JURISDICTION",
      pathname,
    });
  };

  const jurisdictionGroup = async ({ jurisdictionGroup, pathname }: IjurisdictionGroup) => {
    return await createAndLog({
      createFunction: createJurisdictionGroup,
      entity: jurisdictionGroup,
      entityName: "JURISDICTION_GROUP",
      pathname,
    });
  };

  const practiceIndustry = async ({ practiceIndustry, pathname }: IpracticeIndustry) => {
    return await createAndLog({
      createFunction: addPracticeIndustry,
      entity: practiceIndustry,
      entityName: "PRACTICE_INDUSTRY",
      pathname,
    });
  };

  const region = async ({ region, pathname }: Iregion) => {
    return await createAndLog({
      createFunction: createRegion,
      entity: region,
      entityName: "REGION",
      pathname,
    });
  };

  const state = async ({ state, pathname }: Istate) => {
    return await createAndLog({
      createFunction: saveState,
      entity: state,
      entityName: "STATE",
      pathname,
    });
  };

  const officeContact = async ({ officeContact, pathname }: IofficeContact) => {
    return await createAndLog({
      createFunction: saveOfficeContact,
      entity: officeContact,
      entityName: "OFFICE_CONTACT",
      pathname,
    });
  };

  const representativeClient = async ({ representativeClient, pathname, firmId}: IrepresentativeClient) => {
    return await createAndLog({
      createFunction: newRepresentativeClient,
      entity: representativeClient,
      entityName: "REPRESENTATIVE_CLIENT", 
      pathname, 
      firmId
    });
  };

  const school = async ({ school, pathname, firmId}: Ischool) => {
    return await createAndLog({
      createFunction: addSchool,
      entity: school,
      entityName: "SCHOOL", 
      pathname, 
      firmId
    });
  };
  const degree = async ({ degree, pathname, firmId}: Idegree) => {
    return await createAndLog({
      createFunction: addDegree,
      entity: degree,
      entityName: "DEGREE", 
      pathname, 
      firmId
    });
  };
  const education = async ({ education, pathname}: Ieducation) => {
    return await createAndLog({
      createFunction: postEducation,
      entity: education,
      entityName: "REPRESENTATIVE_CLIENT", 
      pathname
    });
  };

  return {
    firm,
    dataAdmin,
    firmContact,
    firmNote,
    address,
    content,
    city,
    contentGeneralTopic,
    contentTag,
    country,
    globalCrisis,
    jurisdiction,
    jurisdictionGroup,
    practiceIndustry,
    region,
    state,
    officeContact,
    representativeClient,
    school,
    degree,
    education
  };
};
