import "../../assets/css/AddCatalogs.css";
import React, { useEffect, useState } from "react";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import ManageFirmBackground from "../../assets/images&icons/bg-manageFirm.png";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import GoBack from "../../components/Moleculas/GoBack";
import MainBox from "./GeneralTab/MainBox/MainBox";
import MembershipCategory from "./GeneralTab/MembershipCategory";
import DataAdminGT from "./GeneralTab/DataAdmin";
import { getDataAdminByFirm } from "../../Services/DataAdmin";
import { countAttorneyByFirm, fetchPersonByFirm, countAttorneyByMainOffice } from "../../Services/Person";
import FirmContactGT from "./GeneralTab/FirmContact";
import { getFirmContact } from "../../Services/FirmContact";
import MemberMainContactGT from "./GeneralTab/MemberMainContactGT";
import { getMemberMainContact } from "../../Services/MemberMainContact";
import { getOffice, getOfficeByJurisdiction, getOfficeByJurisdictionGroup } from "../../Services/Office";
import NotesGT from "./GeneralTab/Notes";
import { getAllFirmNote } from "../../Services/FirmNote";
import { useCityList } from "../../hooks/Catalogs/useCityList";
import { useStatesList } from "../../hooks/Catalogs/useStates";
import { getUsers } from "../../Services/User";
import { useDispatch, useSelector } from "react-redux";
import { Appstore } from "../../redux";
import { UseJurisdictionList } from "../../hooks/Catalogs/useJurisdiction";
import { hideLoader, showLoader } from "../../redux/loaderActions";
import { formatDateToString } from "../../utils/functions";
const WSGDots = require("../../assets/images&icons/WSGDots.svg");

const ManageFirmGeneralTab: React.FC = () => {
  const [dataAdminList, setDataAdminList] = useState<any[]>([]);
  const [personList, setPersonList] = useState<any[]>([]);
  const [wsgWorldwide, setWsgWorldwide] = useState("0");
  const [wsgInJur, setWsgInJur] = useState("0");
  const { jurisdictionData: jurisdictionFirmContact } = UseJurisdictionList()
  const [offices, setOffices] = useState<any[]>([]);
  const [firmNotes, setFirmNotes] = useState<any[]>([]);
  const [firmContact, setFirmContact] = useState<any>([]);
  const [memberMainContact, setMemberMainContact] = useState<any>([]);
  const [users, setUsers] = useState<any[]>([]);
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, [manageFirm.selectedFirm]);

  const fetchData = async () => {
    try {
      await Promise.all([
        fetchDataAdmin(),
        fetchUsers(),
        fetchPerson(),
        fetchFirmContact(),
        fetchOffices(),
        fetchMemberMainContact(),
        fetchFirmNote(),
      ])
    } catch (error) {
      console.error(error);
    }
  }
  
  const fetchDataAdmin = async () => {
    if (!manageFirm.selectedFirm) return;
    const data = await getDataAdminByFirm(manageFirm.selectedFirm.id);
    setDataAdminList(data);
  };

  const fetchUsers = async () => {
    const data = await getUsers();
    setUsers(data);
  };

  const fetchPerson = async () => {
    if (!manageFirm.selectedFirm) return;
    const data = await fetchPersonByFirm(manageFirm.selectedFirm.id);
    setPersonList(data);
  };

  const fetchFirmContact = async () => {
    const data = await getFirmContact();
    const firmContactList = data.filter(
      (firmContact: any) => firmContact.firm === manageFirm.selectedFirm?.id
    );
    setFirmContact(firmContactList);
  };

  const fetchOffices = async () => {
    const data = await getOffice();
    setOffices(data);
  };
  
  const fetchMemberMainContact = async () => {
    const data = await getMemberMainContact();
    const memberMainContactList = data.filter(
      (memberMainContact: any) => memberMainContact.firm === manageFirm.selectedFirm?.id
    );
    setMemberMainContact(memberMainContactList);
  };    

  const fetchFirmNote = async () => {
    const data = await getAllFirmNote();
    const firmNoteList = data.filter(
      (firmNote: any) => firmNote.firm === manageFirm.selectedFirm?.id
    );
    setFirmNotes(firmNoteList);
  };

  const getWSGDatabaseProfessionals = async () => {
    if (!manageFirm.selectedFirm) return;

    try {
      let peopleColunt = 0;
      if (manageFirm.selectedFirm?.membershipType === "Primary") {
        if (!manageFirm.selectedFirm?.jurisdiction) return;
  
        const officesInJurisdiction = await getOfficeByJurisdiction(
          manageFirm.selectedFirm.jurisdiction
        );
        peopleColunt = await countAttorneyByMainOffice(
          officesInJurisdiction.map((office: any) => office.id),
          manageFirm.selectedFirm.id
        );
      } else {
        if (!manageFirm.selectedFirm?.jurisdictionGroup) return;
  
        const officesInJurisdiction = await getOfficeByJurisdictionGroup(
          manageFirm.selectedFirm.jurisdictionGroup
        );
        peopleColunt = await countAttorneyByMainOffice(
          officesInJurisdiction.map((office: any) => office.id),
          manageFirm.selectedFirm.id
        );
      }
      setWsgInJur(peopleColunt?.toString());
    } catch (error) {
      console.error(error);
    }
  };

  const getWSGDatabaseWorldide = async () => {
    if (!manageFirm.selectedFirm) return;
    const peopleColunt = await countAttorneyByFirm(manageFirm.selectedFirm.id);
    setWsgWorldwide(peopleColunt)
  }

  useEffect(() => {
    getWSGDatabaseWorldide()
    getWSGDatabaseProfessionals()
  }, []);

  return (
    <>
      <BackgroundComponent image={ManageFirmBackground} />

      <div className=" mainSection">
        <div className="flex flex-col pt-7 gap-5 md:gap-6 lg:gap-7 md:pt-8 lg:pt-10 pb-5">
          <div className="flex lg:min-h-[70px] gap-4 justify-between">
            <CatalogTitle
              title={`General`}
              className="text-white !p-0 lg:text-[52px] md:text-[44px] text-[42px]"
            />
            <div
              className="md:min-w-[20rem] min-h[4.5rem] overflow-hidden flex-1 hidden relative md:flex"
              style={{
                backgroundImage: `url(${WSGDots.default})`,
              }}
            ></div>
            <div className="text-white relative">
              <GoBack className="!text-white !fill-white" />
            </div>
          </div>
          <hr className="flex h-0 justify-end items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage prevPage="Manage Firm" currentPage={`General`} />
          </div>
        </div>

        <div className="flex flex-col gap-10 justify-end">
          <MainBox />

          <MembershipCategory
            professionalsInPrimary={wsgInJur ?? "0"}
            professionalsWorldwide={wsgWorldwide ?? "0"}
            jurisdictionData={jurisdictionFirmContact}
          />

          <DataAdminGT
            dataAdminList={dataAdminList}
            personList={personList}
            jurisdictionList={jurisdictionFirmContact}
            users={users}
          />

          <FirmContactGT
            firmContactList={firmContact}
            personList={personList}
            jurisdictionList={jurisdictionFirmContact}
          />

          <MemberMainContactGT
            memberMainContactList={memberMainContact}
            personList={personList}
            officeList={offices}
          />

          <NotesGT
            noteList={firmNotes}
            id={manageFirm.selectedFirm?.id ?? ''}
          />
        </div>
      </div>
    </>
  );
};

export default ManageFirmGeneralTab;
