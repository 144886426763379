import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from '../store/AuthContext';

interface StaffModeContextProps {
  isStaffMode: boolean;
  toggleStaffMode: () => void;
  lastLocation: string | null;
  navigatedNoPermission: boolean;
  toggleModal: () => void;
  setStaffMode: () => void;
  isWsgStaff: boolean;
  pageNotFound: () => void;
  navigatedNotFound: boolean;
  toggleNotFound: () => void;
  showModal: () => void;
}

const StaffModeContext = createContext<StaffModeContextProps | undefined>(undefined);

interface StaffModeProviderProps {
  children: ReactNode;
}

export const StaffModeProvider: React.FC<StaffModeProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const lowerLocation = location.pathname.toLowerCase();
  const [isStaffMode, setIsStaffMode] = useState(true);
  const [lastLocation, setLastLocation] = useState<string | null>(null);
  const { tokenDecoded } = useContext(AuthContext);
  const [navigatedNoPermission, setNavigatedNoPermission] = useState(false);
  const [navigatedNotFound, setNavigatedNotFound] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [isWsgStaff, setIsWsgStaff] = useState(false);

  useEffect(() => {
    setLastLocation(lowerLocation);
  }, [lowerLocation]);

  const toggleStaffMode = () => {
    setToggled(true);
    setIsStaffMode((prev) => !prev);
  };

  const togglePermission = () => setNavigatedNoPermission(true);

  const toggleNotFound = () => setNavigatedNotFound(true);

  const toggleModal = () => {
    setNavigatedNoPermission(false);
    setNavigatedNotFound(false);
  };

  const showModal = () => pageNotFound();

  const setStaffMode = () => {
    setNavigatedNoPermission(false);
    setToggled(true);
    setIsStaffMode(true);
    if (!lowerLocation.startsWith('/staff')) {
      navigate('/Staff/ManageFirm');
    }
  };

  const checkPermissions = () => {
    const permissions = tokenDecoded?.extension_WSG_Permissions;
    return permissions === "WSG Staff" || permissions === "WSG Staff Super";
  };

  const pageNotFound = () => {
    if (tokenDecoded) {
      if (checkPermissions()) {
        toggleNotFound();
        setIsWsgStaff(true);
        setIsStaffMode(false);
      } else {
        setIsWsgStaff(false);
      }
      navigate('/mywsg');
    }
  };

  useEffect(() => {
    if (!toggled) {
      const isStaffPath = lowerLocation.startsWith('/staff');
      if (isStaffPath) {
        if (checkPermissions()) {
          setIsStaffMode(true);
        } else {
          togglePermission();
          setIsWsgStaff(false);
					navigate('/mywsg');
        }
      }
    }
  }, [lowerLocation, lastLocation, isStaffMode, toggled, navigate]);

  return (
    <StaffModeContext.Provider value={{ isStaffMode, toggleStaffMode, lastLocation, navigatedNoPermission, toggleModal, setStaffMode, isWsgStaff, pageNotFound, navigatedNotFound, toggleNotFound, showModal }}>
      {children}
    </StaffModeContext.Provider>
  );
};

export const useStaffMode = () => {
  const context = useContext(StaffModeContext);
  if (!context) {
    throw new Error('useStaffMode debe ser usado dentro de un StaffModeProvider');
  }
  return context;
};
