import { Option } from "../../components/Atomos/DropDown/DDMultiple";
import { IFilters } from "../Components/ITable";

export interface IProfessionalProfileFilters {
  name: string;
  offices: Option[],
  title: string;
  isSearchable: Option[],
  multipleOffice: Option[],
  firm?: {[key: string]: IFilters}
}

export const professionalInitialState:IProfessionalProfileFilters = {
  name: '',
  offices: [],
  title: '',
  isSearchable: [],
  multipleOffice: [],
  firm: {}
}