import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Axios from "../../../../utils/axios";
import DropDownList from "../../../../components/Atomos/DropDown/Dropdown";
import NewButton from "../../../../components/Atomos/Buttons/newButton";
import Plus2 from "../../../../components/Atomos/Icons/Plus";
import Close from "../../../../components/Atomos/Icons/Close";
import FirmContactButton from "../../../../components/Atomos/Buttons/firmContactButton";
import SaveChanges from "../../../../components/Atomos/Icons/EditMode/SaveChanges";
import IFirmContact from "../../../../interfaces/models/IFirmContact";
import { useUpdateAndLog } from "../../../../hooks/LogActivity/LogActivityUpdates";

type props = {
  buttonClicked: string;
  update: any;
  setUpdate: any;
  firmContact: any;
  setErrorFields: any;
  state: any;
};

const initialActiveErrors = {
  primaryContact: false,
  secondaryContact: false,
  marketingContact: false,
  accountingContact: false,
};

const ActiveErrorsMessage = {
  primaryContact: "Primary Contact is required.",
  secondaryContact: "Secondary Contact is required.",
  marketingContact: "Marketing Contact is required.",
  accountingContact: "Accounting Contact is required.",
};

const EditFirm: React.FC<props> = ({
  buttonClicked,
  firmContact,
  setErrorFields,
  state,
}) => {
  const axios = new Axios();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const saveButtonRef = useRef<HTMLDivElement>(null);
  const [selectedContacts, setSelectedContacts] = useState({
    primaryContact: { id: "", name: "" },
    secondaryContact: { id: "", name: "" },
    marketingContact: { id: "", name: "" },
    accountingContact: { id: "", name: "" },
    itContact: { id: "", name: "" },
    socialMediaContact: { id: "", name: "" },
    hrContact: { id: "", name: "" },
  });
  const [selectedAdditionalContacts, setSelectedAdditionalContacts] = useState<
    { id: ""; name: "" }[]
  >([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [activeErrors, setActiveErrors] = useState(initialActiveErrors);
  const [activeErrorsMessage, setActiveErrorsMessage] =
    useState(ActiveErrorsMessage);
  const {pathname} = useLocation()
  const update = useUpdateAndLog();

  const handleDropDownChange = (key: string, contact: any) => {
    setSelectedContacts((prevState) => ({
      ...prevState,
      [key]: { id: contact.id, name: contact?.name ?? "" },
    }));
  };

  useEffect(() => {
    let newErrorFields: string[] = [];
    if (activeErrors.primaryContact) {
      newErrorFields.push("Primary Contact");
    }
    if (activeErrors.secondaryContact) {
      newErrorFields.push("Secondary Contact");
    }
    if (activeErrors.marketingContact) {
      newErrorFields.push("Marketing Contact");
    }
    if (activeErrors.accountingContact) {
      newErrorFields.push("Accounting Contact");
    }

    setErrorFields(newErrorFields);
  }, [activeErrors]);

  const handleDropDownChangeAdditional = (index: number, contact: any) => {
    setSelectedAdditionalContacts((prevContacts) => {
      const updatedContacts = [...prevContacts];
      updatedContacts[index] = { id: contact.id, name: contact.name };
      return updatedContacts;
    });
  };

  const handleAddNewContact = () => {
    setSelectedAdditionalContacts((prevContacts) => [
      ...prevContacts,
      { id: "", name: "" },
    ]);
  };

  useEffect(() => {
    if (contacts.length === 0) {
      const fetchContacts = async () => {
        try {
          const response = await axios.Get("./person");
          const data = response.data.filter(
            (contact: any) => contact.firm === firmContact?.firm
          );

          const formatedData = data.map((d: any) => {
            return {
              ...d,
              name: (d.name + " " + d.lastName).trim(),
            };
          });
          if (data != null) {
            setContacts(formatedData);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchContacts();
    }
  }, []);

  useEffect(() => {
    const primaryContact = firmContact.contacts.find(
      (contact: any) => contact.type === "primaryContact"
    );
    const secondaryContact = firmContact.contacts.find(
      (contact: any) => contact.type === "secondaryContact"
    );
    const marketingContact = firmContact.contacts.find(
      (contact: any) => contact.type === "marketingContact"
    );
    const accountingContact = firmContact.contacts.find(
      (contact: any) => contact.type === "accountingContact"
    );
    const itContact = firmContact.contacts.find(
      (contact: any) => contact.type === "itContact"
    );
    const socialMediaContact = firmContact.contacts.find(
      (contact: any) => contact.type === "socialMediaContact"
    );
    const hrContact = firmContact.contacts.find(
      (contact: any) => contact.type === "hrContact"
    );

    setSelectedContacts({
      ...selectedContacts,
      primaryContact:
        contacts.find((contact) => contact.id === primaryContact?.contact) ??
        "",
      secondaryContact:
        contacts.find((contact) => contact.id === secondaryContact?.contact) ??
        "",
      marketingContact:
        contacts.find((contact) => contact.id === marketingContact?.contact) ??
        "",
      accountingContact:
        contacts.find((contact) => contact.id === accountingContact?.contact) ??
        "",
      itContact:
        contacts.find((contact) => contact.id === itContact?.contact) ?? "",
      socialMediaContact:
        contacts.find(
          (contact) => contact.id === socialMediaContact?.contact
        ) ?? "",
      hrContact:
        contacts.find((contact) => contact.id === hrContact?.contact) ?? "",
    });

    const additionalContacts = firmContact.contacts
      .filter(
        (contact: any) =>
          contact.type === "additionalContact" && contact.name !== ""
      )
      .map((contact: any) => ({
        id: contact.contact,
        name: contacts.find((c: any) => c.id === contact.contact)?.name ?? "",
      }));

    setSelectedAdditionalContacts(additionalContacts);
  }, [firmContact.contacts, contacts]);

  const dropdowns = [
    {
      key: "primaryContact",
      title: "Primary Contact",
      selectedObjst: selectedContacts.primaryContact,
      required: true,
      error: activeErrors.primaryContact,
      errorMsj: activeErrorsMessage.primaryContact,
    },
    {
      key: "secondaryContact",
      title: "Secondary Contact",
      selectedObjst: selectedContacts.secondaryContact,
      required: true,
      error: activeErrors.secondaryContact,
      errorMsj: activeErrorsMessage.secondaryContact,
    },
    {
      key: "marketingContact",
      title: "Marketing Contact",
      selectedObjst: selectedContacts.marketingContact,
      required: true,
      error: activeErrors.marketingContact,
      errorMsj: activeErrorsMessage.marketingContact,
    },
    {
      key: "accountingContact",
      title: "Accounting Contact",
      selectedObjst: selectedContacts.accountingContact,
      required: true,
      error: activeErrors.accountingContact,
      errorMsj: activeErrorsMessage.accountingContact,
    },
    {
      key: "itContact",
      title: "IT Contact",
      selectedObjst: selectedContacts.itContact,
    },
    {
      key: "socialMediaContact",
      title: "Social Media Contact",
      selectedObjst: selectedContacts.socialMediaContact,
    },
    {
      key: "hrContact",
      title: "HR Contact",
      selectedObjst: selectedContacts.hrContact,
    },
  ];

  const handleUpdate = async () => {
    try {
      setChecked(true);

      if (buttonClicked !== "saveOverride") {
        const primaryInput =
          selectedContacts.primaryContact.name === undefined ||
          selectedContacts.primaryContact.name === "";
        const secondaryInput =
          selectedContacts.secondaryContact.name === undefined ||
          selectedContacts.secondaryContact.name === "";
        const accountingInput =
          selectedContacts.accountingContact.name === undefined ||
          selectedContacts.accountingContact.name === "";
        const marketingInput =
          selectedContacts.marketingContact.name === undefined ||
          selectedContacts.marketingContact.name === "";

        const newActiveErrors = {
          primaryContact: primaryInput,
          secondaryContact: secondaryInput,
          marketingContact: marketingInput,
          accountingContact: accountingInput,
        };

        setActiveErrors(newActiveErrors);

        if (
          newActiveErrors.primaryContact ||
          newActiveErrors.secondaryContact ||
          newActiveErrors.marketingContact ||
          newActiveErrors.accountingContact
        )
          return;
      }

      const nonEmptyAdditionalContacts = selectedAdditionalContacts.filter(
        (contact) => contact.name !== ""
      );

      const additionalContacts = nonEmptyAdditionalContacts.map((contact) => ({
        type: `additionalContact`,
        contact: contact.id,
      }));

      const updatedData: IFirmContact = {
        id: firmContact.id,
        contacts: [
          {
            type: "primaryContact",
            contact: selectedContacts?.primaryContact.id ?? "",
          },
          {
            type: "secondaryContact",
            contact: selectedContacts?.secondaryContact.id ?? "",
          },
          {
            type: "marketingContact",
            contact: selectedContacts?.marketingContact.id ?? "",
          },
          {
            type: "accountingContact",
            contact: selectedContacts?.accountingContact.id ?? "",
          },
          { type: "itContact", contact: selectedContacts?.itContact.id ?? "" },
          {
            type: "socialMediaContact",
            contact: selectedContacts?.socialMediaContact.id,
          },
          { type: "hrContact", contact: selectedContacts?.hrContact.id ?? "" },
          ...additionalContacts,
        ],
        firm: firmContact?.firm,
        firmName: firmContact?.firmName,
        level: true,
        office: firmContact?.office,
        jurisdictions: firmContact?.jurisdictions,
      };

      const response = await update.firmContact({firmContact: updatedData, pathname, prevState: state.firmContact});
      if (response.status === 200) {
        setTimeout(() => {
          navigate(`/Staff/ManageFirm/GeneralTab/${state.id}`, {
            state: { ...state, selectedTab: "GeneralTab" },
          });
        }, 500);
      } else {
        console.error("Error al crear el elemento:", response.data);
      }
    } catch (error: any) {
      console.error("Error al crear el elemento:", error);
    }
  };

  useEffect(() => {
    if (checked) {
      validateForm();
    }
  }, [selectedContacts]);

  const validateForm = () => {
    const primaryInput =
      selectedContacts.primaryContact.name === undefined &&
      selectedContacts.primaryContact.name === "";
    const secondaryInput =
      selectedContacts.secondaryContact.name === undefined &&
      selectedContacts.secondaryContact.name === "";
    const accountingInput =
      selectedContacts.accountingContact.name === undefined &&
      selectedContacts.accountingContact.name === "";
    const marketingInput =
      selectedContacts.marketingContact.name === undefined &&
      selectedContacts.marketingContact.name === "";

    setActiveErrors((prevState) => ({
      ...prevState,
      primaryContact: primaryInput,
      secondaryContact: secondaryInput,
      marketingContact: marketingInput,
      accountingContact: accountingInput,
    }));
  };

  return (
    <>
      <div className="flex flex-col gap-10 w-full">
        <div className="grid md:grid-cols-6 lg:grid-cols-9 gap-5">
          {dropdowns.map((dropdown, index) => (
            <div key={index} className="grid col-span-3 gap-1 w-full">
              <DropDownList
                title={dropdown.title}
                selectedObjst={dropdown.selectedObjst?.name ?? ""}
                getid={true}
                seter={(id: string) => handleDropDownChange(dropdown.key, id)}
                data={contacts}
                error={dropdown.error ?? false}
                errormsj={dropdown.errorMsj ?? ""}
                required={dropdown.required ?? dropdown.required}
              />
            </div>
          ))}
          {selectedAdditionalContacts.map((dropdown, index) => (
            <div key={index} className="grid col-span-3 gap-1 w-full ">
              <DropDownList
                title={"Additional Contact " + (index + 1)}
                getid={true}
                selectedObjst={selectedAdditionalContacts[index].name}
                seter={(id: string) =>
                  handleDropDownChangeAdditional(index, id)
                }
                data={contacts}
                error={false}
              />
            </div>
          ))}
        </div>
        <div className="w-full md:w-min">
          <NewButton
            text={`NEW CONTACT`}
            color="accent"
            type="button"
            style="filled"
            size="medium"
            content="textIcon"
            onClick={handleAddNewContact}
            icon={<Plus2 />}
          />
        </div>
        <div className="flex justify-end self-stretch w-full">
            <div className="flex w-full md:w-min gap-4">
              <NewButton
                text={`CANCEL`}
                color="neutral"
                content="textIcon"
                type="button"
                style="outlined"
                size="medium"
                className=""
                icon={<Close />}
                onClick={() => {
                  navigate(-1);
                }}
              />
              <div className="w-full min-w-[8.75rem]">
                <FirmContactButton
                  text={`save`}
                  color="accent"
                  style="filled"
                  size="medium"
                  content="textIcon"
                  icon={<SaveChanges />}
                  onClick={handleUpdate}
                  ref={saveButtonRef}
                />
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFirm;
