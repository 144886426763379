import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from "../../assets/images&icons/profileViewMode.jpeg";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import NewButton from "../../components/Atomos/Buttons/newButton";
import CatalogSubTitle from "../../components/Moleculas/Catalog Text/CatalogSubTitle";
import ArrowDropDown from "../../components/Atomos/Icons/ArrowDropDownButton";
import { getNameById } from "../../utils/functions";
import { UseCountryList } from "../../hooks/Catalogs";
import { getAllAddresses } from "../../Services/Address";
import { IAddress } from "../../interfaces/models/IAddress";
import { useCityList } from "../../hooks/Catalogs/useCityList";
import { Appstore } from "../../redux";
import { useSelector } from "react-redux";
import PencilEdit from "../../components/Atomos/Icons/PencilEdit";
const WSGDots = require("../../assets/images&icons/WSGDots.svg");

const ModifyFirmProfile: React.FC = () => {
  const { state } = useLocation();
  const [mainOffices, setMainOffices] = useState([]);
  const [additionalOffices, setAdditionalOffices] = useState([]);
  const { countryData } = UseCountryList();
  const { cityData } = useCityList();
  const [addressData, setAddressData] = useState<IAddress[]>([]);
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);

  useEffect(() => {
    getAllAddresses().then((response) => {
      setAddressData(response.map((r: any) => ({ ...r, name: r.address1 })));
    });
  }, []);

  useEffect(() => {
    if (!countryData.length || !cityData.length || !addressData.length) return;

    if (state && state.offices) {
      setMainOffices(
        state.offices.filter((office: any) => office.officeType === "Main")
      );
      setAdditionalOffices(
        state.offices.filter(
          (office: any) => office.officeType === "Additional"
        )
      );
    }
  }, [countryData, cityData, addressData]);

  return (
    <>
      <BackgroundComponent image={ProfileViewModeBg} />
      <main className="mainSection">
        <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 !items-end self-stretch !p-0 flex-col my-10">
          <div className="flex p-0 flex-col justify-center items-end gap-5 self-stretch md:flex-row lg:justify-between lg:gap-6">
            <h2 className="text-left w-full md:w-max text-white font-decimal text-[2.25rem] font-medium leading-[2.75rem] md:text-[2.75rem] md:leading-[3.25rem] lg:text-[3.25rem] lg:leading-[4.5rem]">
              {`${manageFirm.selectedFirm?.name} `} Offices
            </h2>
            <div
              className="self-stretch flex-1 hidden md:flex"
              style={{
                backgroundImage: `url(${WSGDots.default})`,
              }}
            ></div>
            <div className="flex items-center my-auto">
              <NewButton text="Preview" content="text" color="accent" />
            </div>
          </div>
          <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage
              prevPage="Manage Firm > Profile"
              currentPage={`Offices`}
            />
          </div>
        </div>
        <article className="articleSection relative">
          <section className="formsectionNTitle">
            <div className="flex flex-row w-full">
              <CatalogSubTitle
                className="text-[#0070CD]"
                title="Offices Info"
              />
            </div>
            {mainOffices?.map((office: any) => (
              <div
                key={office.id}
                className="flex flex-col items-start gap-6 w-full"
              >
                <div className="flex items-center gap-4 w-full">
                  <h3 className="text-[#0070CD] font-decimal text-[1.125rem] font-medium leading-5">
                    Main Office
                  </h3>
                  <hr className="flex justify-center items-center flex-1" />
                </div>

                <div className="flex flex-col md:flex-row items-start gap-5 w-full">
                  <div className="flex flex-col gap-4 flex-grow">
                    <div className="flex flex-col gap-2">
                      <span className="text-[#474F56] text-[1.375rem] font-bold leading-6">
                        {office.name}
                      </span>
                      <span className="text-gray-font text-[1.125rem] leading-5">
                        {office.jurisdictionName}
                      </span>
                    </div>
                    <div className="flex flex-col gap-3">
                      <div className="flex items-center gap-4">
                        <span className="text-gray-font text-[1.125rem] leading-5">
                          {office?.phoneNumber?.code}{" "}
                          {office?.phoneNumber?.phone}
                        </span>
                        <hr className="h-5 border-l border-gray-300" />
                        <span className="text-gray-font whitespace-nowrap text-[1.125rem] leading-5">
                          {office?.faxNumber?.code} {office?.faxNumber?.phone}
                        </span>
                      </div>
                      <div className="flex flex-wrap items-center gap-2">
                        <span className="text-gray-font text-lg leading-5">
                          {office.addressName}
                        </span>
                        <hr className="h-5 border-l border-gray-300" />
                        <span className="text-gray-font text-lg leading-5">
                          {office.cityName}
                        </span>
                        <hr className="h-5 border-l border-gray-300" />
                        <span className="text-gray-font text-lg leading-5">
                          {office.stateName}
                        </span>
                        <hr className="h-5 border-l border-gray-300" />
                        <span className="text-gray-font text-lg leading-5">
                          {office.countryName}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col justify-end flex-shrink-0 self-stretch items-end">
                    <div className="w-full">
                      <Link
                        to={`/Staff/ManageFirm/Profile/modify/main/${office.id}`}
                        state={{ ...state, office: office }}
                      >
                        <NewButton
                          color="accent"
                          size="small"
                          content="textIcon"
                          text="EDIT"
                          className="mt-auto"
                          icon={<PencilEdit />}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="flex items-center gap-4 self-stretch w-full">
              <h3 className="text-[#0070CD] font-decimal text-[1.125rem] font-medium leading-5">
                Additional Offices
              </h3>
              <hr className="flex justify-center items-center flex-1" />
            </div>
            {additionalOffices?.map((office: any) => (
              <div
                key={office.id}
                className="flex flex-col items-start gap-6 w-full"
              >
                <div className="flex flex-col md:flex-row items-start gap-5 w-full">
                  <div className="flex flex-col gap-4 flex-grow">
                    <div className="flex flex-col gap-2">
                      <span className="text-[#474F56] text-[1.375rem] font-bold leading-6">
                        {office.name}
                      </span>
                      <span className="text-gray-font text-[1.125rem] leading-5">
                        {office.jurisdictionName}
                      </span>
                    </div>
                    <div className="flex flex-col gap-3">
                      <div className="flex items-center gap-4">
                        <span className="text-gray-font text-[1.125rem] leading-5">
                          {office?.phoneNumber?.code}{" "}
                          {office?.phoneNumber?.phone}
                        </span>
                        <hr className="h-5 border-l border-gray-300" />
                        <span className="text-gray-font whitespace-nowrap text-[1.125rem] leading-5">
                          {office?.faxNumber?.code} {office?.faxNumber?.phone}
                        </span>
                      </div>
                      <div className="flex flex-wrap items-center gap-2">
                        <span className="text-gray-font text-lg leading-5">
                          {office.addressName}
                        </span>
                        <hr className="h-5 border-l border-gray-300" />
                        <span className="text-gray-font text-lg leading-5">
                          {office.cityName}
                        </span>
                        <hr className="h-5 border-l border-gray-300" />
                        <span className="text-gray-font text-lg leading-5">
                          {office.stateName}
                        </span>
                        <hr className="h-5 border-l border-gray-300" />
                        <span className="text-gray-font text-lg leading-5">
                          {office.countryName}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col justify-end flex-shrink-0 self-stretch items-end">
                    <div className="w-full">
                      <Link
                        to={`/Staff/ManageFirm/Profile/modify/additional/${office.id}`}
                        state={{ ...state, office, mainOffice: mainOffices }}
                      >
                        <NewButton
                          color="accent"
                          size="small"
                          content="textIcon"
                          text="EDIT"
                          className="mt-auto"
                          icon={<PencilEdit />}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </section>
        </article>
      </main>
    </>
  );
};

export default ModifyFirmProfile;
