import { configureStore } from "@reduxjs/toolkit";
import { IAOPRanking } from "../interfaces/models/IAOPRanking";
import FirmProfileReducer from "./states/firmProfile.state";
import loaderReducer from "./loaderReducer";

import {
  ICountry,
  ILanguage,
  IPerson,
  IRegion,
  IState,
  ISubstate,
  ICity,
  IJurisdiction,
  IJurisdictionGroup,
} from "../interfaces";
import {
  stateSlice,
  countrySlice,
  languageSlice,
  personSlice,
  regionSlice,
  substateSlice,
  jurisdictionSlice,
  jurisdictionGroupSlice,
  aopRankingSlice,
  contentsSlice,
  citySlice,
  manageFirmSlice,
  menuSlice,
} from "./states";
import {
  contentGeneralTopicsSlice,
  contentTagsSlice,
  countriesSlice,
  firmsSlice,
  globalCrisisSlice,
  jurisdictionGroupsSlice,
  jurisdictionsSlice,
  practiceIndustriesSlice,
  regionsSlice,
  statesSlice,
  citiesSlice,
} from "./catalogs/states";
import { IRepresentativeClient } from "../interfaces/models/IRepresentativeClient";
import { representativeClientSlice } from "./states/representativeClient.state";
import { contentContext } from "./emptyStates";
import { IManageFirm } from "../interfaces/models/IManageFirm";

export interface Appstore {
  state: IState;
  city: any;
  substate: ISubstate;
  region: IRegion;
  country: ICountry;
  language: ILanguage;
  person: IPerson;
  jurisdiction: IJurisdiction;
  jurisdictionGroup: IJurisdictionGroup;
  representativeClient: IRepresentativeClient;
  aopRanking: IAOPRanking;
  contentGeneralTopics: any[];
  contentTags: any[];
  countries: any[];
  content: contentContext;
  firms: any[];
  globalCrisis: any[];
  jurisdictionGroups: any[];
  jurisdictions: any[];
  practiceIndustries: any[];
  regions: any[];
  cities: ICity[];
  states: any[];
  firmProfile: any;
  loader: any;
  manageFirm: IManageFirm;
  menus: any;
}

export default configureStore<Appstore>({
  reducer: {
    firmProfile: FirmProfileReducer,
    state: stateSlice.reducer,
    substate: substateSlice.reducer,
    region: regionSlice.reducer,
    country: countrySlice.reducer,
    language: languageSlice.reducer,
    person: personSlice.reducer,
    jurisdiction: jurisdictionSlice.reducer,
    jurisdictionGroup: jurisdictionGroupSlice.reducer,
    aopRanking: aopRankingSlice.reducer,
    representativeClient: representativeClientSlice.reducer,
    contentGeneralTopics: contentGeneralTopicsSlice.reducer,
    contentTags: contentTagsSlice.reducer,
    countries: countriesSlice.reducer,
    firms: firmsSlice.reducer,
    globalCrisis: globalCrisisSlice.reducer,
    jurisdictionGroups: jurisdictionGroupsSlice.reducer,
    jurisdictions: jurisdictionsSlice.reducer,
    practiceIndustries: practiceIndustriesSlice.reducer,
    regions: regionsSlice.reducer,
    content: contentsSlice.reducer,
    states: statesSlice.reducer,
    cities: citiesSlice.reducer,
    city: citySlice.reducer,
    loader: loaderReducer,
    manageFirm: manageFirmSlice.reducer,
    menus: menuSlice.reducer,
  },
});

export type RootState = ReturnType<typeof configureStore>;
