import React, { useEffect, useState } from "react";
import Input from "../../Atomos/Inputs/Input";
import CatalogFilterButtons from "../CatalogFilterButtons";
import DDMultiple, { Option } from "../../Atomos/DropDown/DDMultiple";
import CalendarInput from "../../Atomos/Inputs/CalendarInput";
import { Table } from "@tanstack/react-table";
import { ILogFilters } from "../../../interfaces/models/IActivityLogs";

interface IContentProps {
  tableInstance: Table<unknown>;
  filters: ILogFilters;
  headers: string[];
  setFilters: React.Dispatch<React.SetStateAction<ILogFilters>>;
  clearSelections: () => void;
  Search: () => void;
  disabledType?: boolean;
}
const LogFilter: React.FC<IContentProps> = ({
  filters,
  Search,
  setFilters,
  headers,
  tableInstance,
  clearSelections,
  disabledType,
}) => {
  const [typeData] = useState<any[]>([
    { id: 0, name: "Member" },
    { id: 2, name: "Data Admin" },
  ]);

  const handleFilters = (field: string, value: any) => {
    setFilters({ ...filters, [field]: value });
  };

  useEffect(() => {
    if (disabledType) handleFilters("type", []);
  }, [disabledType]);

  return (
    <div className="flex formsection flex-col  w-full">
      <div className="grid grid-cols-4 formsection">
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <Input
            title="By Name:"
            name="inputName"
            value={filters.name}
            onChange={(e: any) => handleFilters("name", e.target.value)}
            error={false}
          />
        </div>
        {!disabledType && (
          <div className="col-span-4 md:col-span-2 lg:col-span-1">
            <DDMultiple
              title="By Type:"
              objects={typeData}
              selectedObjst={filters.type}
              seter={(e) => handleFilters("type", e)}
            />
          </div>
        )}
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <CalendarInput
            title={"By Date:"}
            error={false}
            type={"range"}
            value={filters.date}
            onChange={(e: any) => handleFilters("date", e)}
          />
        </div>
      </div>

      <div className="flex items-end justify-end">
        <CatalogFilterButtons
          onClickSearch={Search}
          handleFormReset={clearSelections}
          headers={headers}
          tableInstance={tableInstance}
        />
      </div>
    </div>
  );
};

export default LogFilter;
