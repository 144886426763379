import { axios } from "..";
import { ISchool } from "../interfaces/models/ISchool";

export const getSchools = async (): Promise<ISchool[]> => {
  try {
    const response = await axios.Get('./school');
    return response.data;
  } catch (error) {
    console.error(error);
    throw error
  }
};

export const addSchool = async (school: ISchool): Promise<any> => {
  try {
    const response = await axios.Post('./school', school);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error
  }
};
