import NewButton from "../../../components/Atomos/Buttons/newButton";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import React from "react";
import Plus from "../../../components/Atomos/Icons/EditMode/Plus";
import InputImageMinimal from "../../../components/Atomos/InputImage/InputImageMinimal";
import Input from "../../../components/Atomos/Inputs/Input";
import DeleteIcon from "../../../components/Atomos/Icons/DeleteIcon";

type NotableItem = {
  link: string;
  photo: string | File | null;
};

type NotablesProps = {
  officeName: string;
  notables: NotableItem[];
  setNotables: React.Dispatch<React.SetStateAction<NotableItem[]>>;
  notablesError?: any;
};

const Notables: React.FC<NotablesProps> = ({
  officeName,
  notables = [],
  setNotables,
  notablesError,
}) => {
  const handleImageChange = (index: number, image: string | File | null) => {
    setNotables((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = { ...updatedItems[index], photo: image };
      return updatedItems;
    });
  };

  const handleLinkChange = (index: number, newLink: string) => {
    setNotables((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = { ...updatedItems[index], link: newLink };
      return updatedItems;
    });
  };

  const addNewNotable = () => {
    if (notables.length >= 4) return;
    setNotables((prevItems) => [...prevItems, { link: "", photo: null }]);
  };

  const deleteNotable = (index: number) => {
    setNotables((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  return (
    <>
      <div className="flex w-full flex-col gap-5 lg:flex-row lg:justify-between">
        <CatalogSubTitle
          className="text-Default"
          title={`${officeName} Notables`}
        />
        <NewButton
          text={"ADD"}
          icon={<Plus />}
          content="textIcon"
          color={"accent"}
          onClick={addNewNotable}
        />
      </div>

      <span className="text-gray-font text-base">
        Click 'Add' to create a new Notable and upload/crop an image, or click
        the thumbnail to edit. Enter the Notable URL afterward. All fields are
        required. Users will be redirected to the URL from the public website.
      </span>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full formsection flex-wrap md:flex-nowrap">
        {notables.map((notable, index) => (
          <div className="flex flex-col formsection w-full" key={index}>
            <InputImageMinimal
              setImage={(image) => handleImageChange(index, image)}
              photo={notable.photo as string}
              title="Notable Image"
            />
            <Input
              title="Notable URL"
              errorMsj={notablesError?.[index]?.errorMsj || ""}
              error={notablesError?.[index]?.errorMsj !== ""}
              value={notable.link}
              onChange={(e) => handleLinkChange(index, e.target.value)}
              required={!!notable.photo}
            />
            <NewButton
              text={"DELETE"}
              icon={<DeleteIcon />}
              content="textIcon"
              color={"delete"}
              style="outlined"
              onClick={() => deleteNotable(index)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default Notables;
