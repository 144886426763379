import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Input from "../../../components/Atomos/Inputs/Input";
import DropDownList from "../../../components/Atomos/DropDown/Dropdown";
import TextArea from "../../../components/Atomos/Inputs/TextArea";
import SwitchToogle from "../../../components/Atomos/Switch-Toogle/Switch";
import React from "react";
import InputImage from "../../../components/Atomos/InputImage/InputImage";
import { PhoneNumber } from "../../../components/Atomos/PhoneNumber/PhoneNumber";

type FirmGeneralInformationProps = {
  handleSetLogo: (logo: string | File | null) => void;
  handleSetSmallLogo: (logo: string | File | null) => void;
  handleChangePhone: (phone: {
    code: string;
    phone: string;
    basecode: string;
  }) => void;
  handleChangeFax: (phone: {
    code: string;
    phone: string;
    basecode: string;
  }) => void;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  handleInvoiceInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  handleTextAreaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleInvoiceTextAreaChange: (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleInvoiceCheckBoxChange: (name: string, checked: boolean) => void;
  handleSetInvoiceCity: (e: listobj) => void;
  handleSetInvoiceCountry: (e: listobj) => void;
  handleSetInvoiceState: (e: listobj) => void;
  handleSetSelectedCountry: (e: listobj) => void;
  handleSetSelectedState: (e: listobj) => void;
  handleSetSelectedCity: (e: listobj) => void;
  handleSetSelectedAddressType: (e: string) => void;
  handleSetSelectedInvoiceAddressType: (e: string) => void;
  handleSwitchToggle: (checked: boolean) => void;
  handleCheckBoxChange: (name: string, checked: boolean) => void;
  countryData: listobj[];
  stateData: listobj[];
  cityData: listobj[];
  selectedCountry: any;
  selectedState: any;
  selectedCity: any;
  selectedAddress: any;
  selectedAddressType: any;
  invoiceAddress: any;
  directPhoneCode: any;
  faxNumber: any;
  logo: string | File | null;
  smallLogo: string | File | null;
  firmName: string;
  addressErrors: any;
  officePhoneErrors: any;
  invoiceCity: any;
  invoiceState: any;
  invoiceCountry: any;
  invoiceErrors: any;
  firmErrors: any;
  useSameAddress: boolean;
  selectedInvoiceAddressType: any;
};

type listobj = { id: string; name: string };

const FirmGeneralInformation: React.FC<FirmGeneralInformationProps> = ({
  handleSetLogo,
  handleSetSmallLogo,
  handleChangeFax,
  handleChangePhone,
  handleInputChange,
  handleInvoiceInputChange,
  handleInvoiceCheckBoxChange,
  handleInvoiceTextAreaChange,
  handleTextAreaChange,
  handleSetInvoiceCity,
  handleSetInvoiceCountry,
  handleSetInvoiceState,
  handleSetSelectedCountry,
  handleSetSelectedState,
  handleSetSelectedCity,
  handleSetSelectedAddressType,
  handleSetSelectedInvoiceAddressType,
  handleSwitchToggle,
  handleCheckBoxChange,
  countryData,
  stateData,
  cityData,
  selectedCountry,
  selectedState,
  selectedCity,
  selectedAddress,
  selectedAddressType,
  invoiceAddress,
  directPhoneCode,
  faxNumber,
  logo,
  smallLogo,
  firmName,
  addressErrors,
  invoiceCity,
  invoiceState,
  invoiceCountry,
  invoiceErrors,
  firmErrors,
  officePhoneErrors,
  useSameAddress,
  selectedInvoiceAddressType,
}) => {
  return (
    <>
      <CatalogSubTitle
        className="text-[#0070CD]"
        title="Firm General Information"
      />
      <div className="flex items-start gap-5 self-stretch">
        <Input
          title="Law Firm Name"
          name="firmName"
          errorMsj={firmErrors.firmName.msj}
          error={firmErrors.firmName.error}
          value={firmName}
          onChange={handleInputChange}
          required={true}
        />

        <InputImage
          required={true}
          setImage={handleSetLogo}
          photo={logo as string}
          maxsize={25}
          width={300}
          height={300}
          title="Logo"
          error={firmErrors.logo.error}
          errorMsg={firmErrors.logo.msj}
        />

        <InputImage
          required={false}
          setImage={handleSetSmallLogo}
          photo={smallLogo as string}
          maxsize={25}
          width={300}
          height={300}
          title="Small Logo"
          error={false}
        />
      </div>
      <div className="formsection grid grid-cols-12 gap-5 w-full">
        <div className="flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
          <DropDownList
            title="Country"
            getid
            required={true}
            data={countryData}
            selectedObjst={selectedCountry.name}
            seter={handleSetSelectedCountry}
            error={addressErrors.country.error}
            errormsj={addressErrors.country.msj}
          />
        </div>
        <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
          <div className="flex flex-col w-full">
            <DropDownList
              title="State"
              data={stateData}
              getid
              selectedObjst={selectedState.name}
              tooltip={"Please Select a Country"}
              seter={handleSetSelectedState}
              error={false}
            />
          </div>
        </div>
        <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
          <div className="flex flex-col w-full">
            <DropDownList
              title="City"
              data={cityData}
              getid
              selectedObjst={selectedCity.name}
              tooltip={"Please Select a State"}
              seter={handleSetSelectedCity}
              error={false}
            />
          </div>
        </div>
      </div>
      <div className="formsection grid grid-cols-12 gap-5 w-full">
        <div className="gap-5 flex-wrap col-span-12 md:col-span-6">
          <div className=" w-full ">
            <Input
              title="Address 1"
              name="address1"
              errorMsj={addressErrors.address1.msj}
              error={addressErrors.address1.error}
              value={selectedAddress?.address1}
              onChange={handleInputChange}
              required={true}
            />
          </div>
        </div>
        <div className="gap-5 flex-wrap col-span-12 md:col-span-6">
          <div className=" w-full">
            <Input
              title="Address 2"
              name="address2"
              error={false}
              value={selectedAddress?.address2 || ""}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
          <div className=" w-full">
            <Input
              title="Zip Code"
              name="zipCode"
              errorMsj={addressErrors.zipCode?.msj}
              error={addressErrors.zipCode?.error}
              disabled={selectedAddress?.noZipCode === true}
              value={
                selectedAddress?.noZipCode === true
                  ? null
                  : selectedAddress?.zipCode
              }
              onChange={handleInputChange}
              required={false}
            />
          </div>
          <div className="flex items-center w-full">
            <SwitchToogle
              small
              seter={(checked: boolean) =>
                handleCheckBoxChange("noZipCode", checked)
              }
              checked={selectedAddress?.noZipCode || false}
              title="No Zip Code"
            />
          </div>
        </div>
        <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
          <div className="flex flex-col gap-1 w-full">
            <DropDownList
              title="Address Type"
              required={true}
              selectedObjst={
                selectedAddress?.addressType
                  ? selectedAddress?.addressType
                  : selectedAddressType
              }
              seter={handleSetSelectedAddressType}
              data={[
                { id: "Office", name: "Office" },
                { id: "Venue", name: "Venue" },
                { id: "Legal", name: "Legal" },
              ]}
              error={addressErrors.addressType.error}
              errormsj={addressErrors.addressType.msj}
            />
          </div>
        </div>
        <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
          <div className=" w-full">
            <Input
              title="Altitude"
              name="altitude"
              error={false}
              value={
                selectedAddress?.altitude
                  ? selectedAddress?.altitude?.toString().trim()
                  : ""
              }
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
          <div className=" w-full">
            <Input
              title="Latitude"
              name="latitude"
              error={false}
              value={
                selectedAddress?.latitude
                  ? selectedAddress?.latitude?.toString().trim()
                  : ""
              }
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-6">
          <div className=" w-full">
            <PhoneNumber
              title="Phone Number"
              Obj={directPhoneCode}
              seter={handleChangePhone}
              required={true}
              error={officePhoneErrors.phone.error}
              errormsj={officePhoneErrors.phone.msj}
            />
          </div>
        </div>
        <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-6">
          <div className=" w-full">
            <PhoneNumber
              title="Fax Number"
              error={false}
              Obj={faxNumber}
              seter={handleChangeFax}
            />
          </div>
        </div>
        <div className="gap-5 flex-wrap col-span-12 lg:gap-5">
          <TextArea
            title="Notes"
            name="notes"
            maxChars={200}
            value={selectedAddress?.notes ? selectedAddress?.notes : ""}
            error={addressErrors.notes?.error}
            errorMsj="Character limit has been reached."
            onChange={handleTextAreaChange}
            characterCount={true}
          />
        </div>
      </div>

      <CatalogSubTitle className="text-[#0070CD]" title="Invoice Information" />
      <div className="flex items-center w-full">
        <SwitchToogle
          small
          seter={handleSwitchToggle}
          checked={useSameAddress}
          title="Use above Address as Accounting Mailing Address"
        />
      </div>
      {!useSameAddress && (
        <div className="formsection grid grid-cols-12 gap-5 w-full">
          <div className="flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
            <DropDownList
              title="Country"
              getid
              required={true}
              data={countryData}
              selectedObjst={invoiceCountry.name}
              seter={handleSetInvoiceCountry}
              error={invoiceErrors.country.error}
              errormsj={invoiceErrors.country.msj}
            />
          </div>
          <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
            <div className="flex flex-col w-full">
              <DropDownList
                title="State"
                data={stateData}
                getid
                selectedObjst={invoiceState.name}
                tooltip={"Please Select a Country"}
                seter={handleSetInvoiceState}
                error={false}
              />
            </div>
          </div>
          <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
            <div className="flex flex-col w-full">
              <DropDownList
                title="City"
                data={cityData}
                getid
                selectedObjst={invoiceCity.name}
                tooltip={"Please Select a State"}
                seter={handleSetInvoiceCity}
                error={false}
              />
            </div>
          </div>
        </div>
      )}
      {!useSameAddress && (
        <div className="formsection grid grid-cols-12 gap-5 w-full">
          <div className="gap-5 flex-wrap col-span-12 md:col-span-6">
            <div className=" w-full ">
              <Input
                title="Address 1"
                name="address1"
                errorMsj={invoiceErrors.address1.msj}
                error={invoiceErrors.address1.error}
                value={invoiceAddress?.address1}
                onChange={handleInvoiceInputChange}
                required={true}
              />
            </div>
          </div>
          <div className="gap-5 flex-wrap col-span-12 md:col-span-6">
            <div className=" w-full">
              <Input
                title="Address 2"
                name="address2"
                error={false}
                value={invoiceAddress.address2}
                onChange={handleInvoiceInputChange}
              />
            </div>
          </div>
          <div className="flex gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
            {
              <div className=" w-full">
                <Input
                  title="Zip Code"
                  name="zipCode"
                  errorMsj={invoiceErrors.zipCode?.msj}
                  error={invoiceErrors.zipCode?.error}
                  value={invoiceAddress.zipCode}
                  onChange={handleInvoiceInputChange}
                  required={false}
                />
              </div>
            }
            <div className="flex items-center w-full">
              <SwitchToogle
                small
                seter={(checked: boolean) =>
                  handleInvoiceCheckBoxChange("noZipCode", checked)
                }
                checked={invoiceAddress?.noZipCode === "true"}
                title="No Zip Code"
              />
            </div>
          </div>
          <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
            <div className="flex flex-col gap-1 w-full">
              <DropDownList
                title="Address Type"
                disabled
                required={true}
                selectedObjst={selectedInvoiceAddressType}
                seter={handleSetSelectedInvoiceAddressType}
                data={[{ id: "Legal", name: "Legal" }]}
                error={false}
                errormsj={invoiceErrors.addressType.msj}
              />
            </div>
          </div>
          <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
            <div className=" w-full">
              <Input
                title="Altitude"
                name="altitude"
                error={false}
                value={invoiceAddress.altitude?.toString().trim() || ""}
                onChange={handleInvoiceInputChange}
              />
            </div>
          </div>
          <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
            <div className=" w-full">
              <Input
                title="Latitude"
                name="latitude"
                error={false}
                value={invoiceAddress.latitude?.toString().trim() || ""}
                onChange={handleInvoiceInputChange}
              />
            </div>
          </div>
          <div className="gap-5 flex-wrap col-span-12 lg:col-span-6"></div>
          <div className="gap-5 flex-wrap col-span-12 lg:gap-5">
            <TextArea
              title="Notes"
              name="notes"
              maxChars={200}
              value={invoiceAddress.notes}
              error={invoiceErrors.notes?.error}
              errorMsj="Character limit has been reached."
              onChange={handleInvoiceTextAreaChange}
              characterCount={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FirmGeneralInformation;
