import "../../../../assets/css/AddCatalogs.css";
import React, { useState } from "react";
import MainBoxDefault from "./MainBoxDefault";
import MainBoxUpdateMembership from "./MainBoxUpdateMembership";
import { useSelector } from "react-redux";
import { Appstore } from "../../../../redux";

interface IMainBoxProps {
}

const MainBox: React.FC<IMainBoxProps> = ({ ...props }) => {
  const [updateMembership, setUpdateMembership] = useState(false);
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);

  const [membershipDetails, setMembershipDetails] = useState({
    membershipStatus: manageFirm.selectedFirm?.membershipStatus,
    membershipType: manageFirm.selectedFirm?.membershipType,
    serviceType: manageFirm.selectedFirm?.serviceType,
    logo: manageFirm.selectedFirm?.logo,
    name: manageFirm.selectedFirm?.name,
  });

  const handleSaveMembership = (updatedDetails: any) => {
    setMembershipDetails((prevDetails) => ({
      ...prevDetails,
      ...updatedDetails,
    }));
    setUpdateMembership(false);
  };

  return (
    <>
      <article className="articleSection relative">
        {updateMembership ? (
          <MainBoxUpdateMembership
            membershipDetails={membershipDetails}
            setUpdateMembership={setUpdateMembership}
            onSaveMembership={handleSaveMembership}
          />
        ) : (
          <MainBoxDefault
            membershipDetails={membershipDetails}
            setUpdateMembership={setUpdateMembership}
          />
        )}
      </article>
    </>
  );
};

export default MainBox;
