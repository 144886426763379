//crear componente de tipo switch
import { Switch } from '@headlessui/react';
import React from 'react';
import '../../../assets/css/Text.css';
import { useStaffMode } from '../../../hooks/StaffModeProvider';

type SwitchProps = {
    seter: (checked: boolean) => void;
    checked: boolean;
    small?: boolean;
    title?: string;
    disabled?: boolean;
    centered?: boolean;
    className ?: string;
    titleFirst ?: boolean;
    name?: string;
    } 
    
//crear componente
const SwitchToggle: React.FC<SwitchProps> = (props) => {
  const {isStaffMode} = useStaffMode(); 

    return (
        <div className={`flex gap-2.5 ${props.centered && 'justify-center items-center'} ${props.className}`}>
            
            {props.titleFirst && props.title && (
                <span className={`flex inputLabel ${props.disabled ? 'text-gray-200' : 'text-gray-font'}`}>
                    {props.title}
                </span>
            )}

            <Switch
                checked={props.checked}
                onChange={props.seter}
                name={props.name}
                disabled={props.disabled}
                className={`${props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'}  ${props.checked ? isStaffMode ? ' bg-Default' : ' bg-accentDefault' : 'bg-neutral '}
                relative inline-flex p-1 ${props.small ? 'w-12' : 'w-14'}   gap-2 shrink-0  rounded-full items-center border border-solid border-gray-300 border-spacing-1 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}>
                <span className="sr-only">Use setting</span>
                <span aria-hidden="true" className={` ${props.small ? 'w-4 h-4' : 'w-5 h-5'}  ${props.checked ? props.small ? 'translate-x-5 bg-white' : 'translate-x-6 bg-white' : 'translate-x-0 bg-gray-300  '}
                pointer-events-none inline-block ${props.small ? 'w-4' : 'w-5'}  transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out
                `}
                />
            </Switch>
            
            {!props.titleFirst && props.title && (
                <span className={`inputLabel ${props.disabled ? 'text-gray-200' : 'text-gray-font'}`}>
                    {props.title}
                </span>
            )}
            
        </div>
    )
};

export const OldSwitch: React.FC<SwitchProps> = (props) => {
  const {isStaffMode} = useStaffMode(); 
    return (
        <>
            <Switch
                checked={props.checked}
                onChange={props.seter}
                name={props.name}
                className={`${props.checked ? isStaffMode ? ' bg-Default' : ' bg-accentDefault' : 'bg-gray-200'} relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}>
                <span className="sr-only">Use setting</span>
                <span aria-hidden="true" className={`${props.checked ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`} />
            </Switch>
            <span className=" ml-4 text-lg leading-8 not-italic font-normal">{props.title}</span>
        </>

    )
}

export default SwitchToggle;