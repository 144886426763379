import React, { useEffect } from "react";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Input from "../../../components/Atomos/Inputs/Input";
import SwitchToogle from "../../../components/Atomos/Switch-Toogle/Switch";
import DropDownList from "../../../components/Atomos/DropDown/Dropdown";
import "../../../assets/css/AddCatalogs.css";
import { IStateErrors } from "../../../interfaces";
import { useStateHook } from "../../../hooks/Catalogs";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useDeleteAndLog } from "../../../hooks/LogActivity/LogActivityDeletes";
import { DeleteState } from "../../../redux/catalogs/states";

interface CatalogProps {
  mode: "add" | "edit";
}

const AddState: React.FC<CatalogProps> = ({ mode }) => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { currentState, errors, saveOverrideOpen, handleButtonClick, handleSubmit, handleChange, setSaveOverrideOpen, countryData, selectedCountry } = useStateHook({ mode });
  const dispatch = useDispatch();
  const erase = useDeleteAndLog();

  const handleDelete = async () => {   
    try {
      await erase.state({ state: currentState, pathname });
      dispatch(DeleteState(currentState.id));
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <main
      className={`mainSection ${saveOverrideOpen ? "pt-7 md:pt-9 " : ""}`}
    >
      <form onSubmit={handleSubmit}>
        <SaveOverride
          fields={
            errors &&
            Object.keys(errors).filter(
              (key) => errors[key as keyof IStateErrors].error
            )
          }
          handleButtonClick={handleButtonClick}
          open={saveOverrideOpen}
          close={() => setSaveOverrideOpen(false)}
        />
        <CatalogTitle
          title={`${mode === "add" ? "New State" : `Edit ${currentState?.name}`}`}
          className="text-Default"
        >
          <GoBack />
        </CatalogTitle>
        <article className="articleSection">
          <section className="formsectionNTitle">
            <CatalogSubTitle
              title=" State Information"
              className=" text-accent-Default-dark"
            />
            <div className=" formsection flex flex-col md:flex-row justify-start w-full">
              <div className=" w-full md:w-[45%]">
                <Input
                  title={"Name"}
                  value={currentState.name}
                  name="name"
                  error={errors.name.error}
                  errorMsj={errors.name.errorMsj}
                  required
                  onChange={(event) => handleChange(event)}
                />
              </div>
              <div className="w-full md:w-[31%]">
                <Input
                  title={"Abbreviation"}
                  value={currentState.abbreviation}
                  name="abbreviation"
                  error={errors.abbreviation.error}
                  errorMsj={errors.abbreviation.errorMsj}
                  required
                  onChange={(event) => handleChange(event)}
                />
              </div>
              <div className="flex flex-row md:flex-col gap-2.5  text-left text-lg leading-5 font-normal not-italic justify-start items-center lg:items-start col-span-12  md:col-span-2 lg:col-span-3 text-gray-font">
                <span className="inputLabel">Has SubState?</span>
                <SwitchToogle
                  title=""
                  name="hasSubstate"
                  checked={currentState?.hasSubstate}
                  seter={(value: boolean) => 
                    handleChange({ target: { name: "hasSubstate", value } })
                  }
                />
              </div>
            </div>
          </section>
          <section className="formsectionNTitle ">
            <CatalogSubTitle
              title="Catalog List Relationships"
              className=" text-accent-Default-dark"
            />
            <div className=" formsection flex justify-start w-full ">
              <div className="w-full md:w-1/2">
                <DropDownList
                  getid
                  title={"Country"}
                  name="country"
                  required={true}
                  data={countryData}
                  selectedObjst={selectedCountry}
                  seter={(value) =>
                    handleChange({ target: { name: "country", value } })
                  }
                  error={errors.country.error}
                  errormsj={errors.country.errorMsj}
                />
              </div>
            </div>
          </section>
          <ButtonsCrud
            Catalog="state"
            mode={mode}
            id={currentState?.id ? currentState.id : ""}
            disabled={
              errors.name.error ||
              errors.abbreviation.error ||
              errors.country.error
            }
            actionButton={handleButtonClick}
            onDelete={handleDelete}
            state={{...state, hasSubstate: state?.hasSubstate ?? false}}
            newState={currentState}
          />
        </article>
      </form>
    </main>
  );
};
export default AddState;
