import React from "react";

function ChevronDown(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 32 32"
      style={{transform: props.rotation}}
    >
      <path 
        d="M15.754 18.212l8.879-8.879 2.034 2.034L15.754 22.28 4.841 11.367l2.034-2.034 8.879 8.879z"
      ></path>
    </svg>
  );
}

export default ChevronDown;
