import { useState } from "react";
import CloseIcon from "../../Atomos/Icons/Close";

interface warningProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  message: string;
}

const WarningModal: React.FC<warningProps> = ({
  showModal,
  setShowModal,
  title,
  message,
}) => {
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleClose = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setShowModal(false);
      setIsTransitioning(false);
    }, 500);
  };

  return (
    <>
      {showModal && (
        <div
          className={`font-semibold fixed top-20 left-1/3 -translate-x-1/4 md:left-1/2 md:-translate-x-1/2 bg-pending z-20 text-white my-6 p-6 text-start w-fit shadow-md shadow-gray-700 
          transition-opacity duration-500 ease-in-out ${isTransitioning ? "opacity-0 scale-95" : "opacity-100 scale-100"}`}
        >
          <button
            className="absolute -top-8 right-1 h-full fill-current"
            onClick={handleClose}
          >
            <CloseIcon className="w-6 p-1" />
          </button>
          <div className="flex h-full mb-2">{title}</div>
          <div>{message}</div>
        </div>
      )}
    </>
  );
};
export default WarningModal;
