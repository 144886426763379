import React, { useEffect, useState } from "react";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import useDataLoader2 from "../../hooks/useDataLoader2";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import { useColumnConfig } from "../../hooks/Table/UseColumnSettings";
import { Link } from "react-router-dom";
import ShapeEdit from "../../components/Atomos/Icons/ShapeEdit";
import BaseFilter from "../../components/Catalogs/Filters/BaseFilter/BaseFilter";
import { formatTimeSpanToString } from "../../utils/functions";
import { defaultPageSize } from '../../utils/const';

const JurisdictionGroup: React.FC = ({ ...props }) => {
  const Headers: string[] = ["name", "createdDate"];
  const [tableInstance, setTableInstance] = useState<any>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'legacyId',
    'name',
    'createdDate'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/jurisdictionGroup',
    defaultPageSize,
    fields
  });
  const [nameFilter, setNameFilter] = useState('');
  const { generateColumns } = useColumnConfig(Headers);

  const clearSelections = () => {
    setNameFilter('');
    fetchData(true);
  };

  const columns = generateColumns([
    { id: 'count', header: '#', cell: (props: any) => <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>, enableSorting: false, size: 50 },
    { id: 'name', header: 'Name', cell: (props: any) => props.getValue() },
    {
      id: 'createdDate', header: "Created Date",
      cell: (props: any) => {
        const value = props.getValue();
        return (
          <p>
            {value && formatTimeSpanToString(value) }
          </p>
        );
      },
    },
    {
      id: 'edit', header: 'Edit', cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={props.row.original}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ), enableSorting: false
    },
  ]);

  const handleNameFilterChange = (newNameFilter: string) => {
    setNameFilter(newNameFilter);
  };

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  useEffect(() => {
    setFilter({
      name: {
        value: nameFilter,
        comparisonType: 'CONTAINS'
      }
    })
  },[nameFilter]);

  return (
    <>
      <div className=" mainSection">
        <CatalogTableC
          title="Jurisdiction Group Catalog"
          catalogShortName="Jurisdiction Group"
          catalogName="Jurisdiction Groups"
          headers={Headers}
          route={"JurisdictionGroup"}
          totalCount={totalCount}
          isfiltered={true}
          data={data}
        >
          <BaseFilter
            title='By Name'
            key="degreeFilter"
            search={() => fetchData()}
            headers={Headers}
            onNameFilterChange={handleNameFilterChange}
            nameFilter={nameFilter}
            resetFilters={clearSelections}
            tableInstance={tableInstance}
          />

          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </div>
    </>
  );
};
export default JurisdictionGroup;
