import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import '../../../assets/css/AddCatalogs.css';
import Input from "../../../components/Atomos/Inputs/Input";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import { getCurrentTimestamp } from "../../../utils/functions";
import { useDispatch } from "react-redux";
import { useUpdateAndLog } from "../../../hooks/LogActivity/LogActivityUpdates";
import { useCreateAndLog } from "../../../hooks/LogActivity/LogActivityCreates";
import { useDeleteAndLog } from "../../../hooks/LogActivity/LogActivityDeletes";
import { AddRegion as AddRegionAction, DeleteRegion, UpdateRegion } from "../../../redux/catalogs/states";
import { IRegion } from "../../../interfaces";

interface iCatalogProps {
  mode: "add" | "edit";
}
type Errors = { name: { error: boolean; message: string; }; abbreviation: { error: boolean; message: string; } }

const AddRegion: React.FC<iCatalogProps> = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state, pathname } = useLocation();
  const [errors, setErrors] = useState<Errors>({ name: { error: false, message: '' }, abbreviation: { error: false, message: '' } });
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [region, setRegion] = useState<IRegion>({ id: state?.id ?? '', name: state?.name ?? '', abbreviation: state?.abbreviation, createdDate: state?.createdDate });
  const [buttonClicked, setButtonClicked] = useState('');
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const update = useUpdateAndLog();
  const create = useCreateAndLog();
  const erase = useDeleteAndLog();

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); };
  }, []);

  useEffect(() => {
    if (region.name?.trim() !== '') {
      setErrors({ ...errors, name: { error: false, message: '' } });
    }
    if (region.abbreviation?.trim() !== '') {
      setErrors({ ...errors, abbreviation: { error: false, message: '' } });
    }
  }, [region]);

  const validation = () => {

    const name = region.name?.trim() === '' ? { error: true, message: 'Name is required' } : { error: false, message: '' };

    const abbreviation = region.abbreviation?.trim() === '' ? { error: true, message: 'Abbreviation is required' } : { error: false, message: '' };

    setErrors({ name: name, abbreviation: abbreviation });
    setError(name.error || abbreviation.error);
    return name.error || abbreviation.error;
  }

  const handleDelete = async (id: string) => {
    try {
      await erase.region({ region: state, pathname });
      dispatch(DeleteRegion(state.id ?? ""));
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  }

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (buttonClicked === "saveOverride") {
      if (props.mode === "add") {
        if (region.name !== "" && region.abbreviation !== "") {
          handleSave(e);
        }
      } else {
        if (region.name !== "" && region.abbreviation !== "") {
          handleUpdate(e);
        }
      }
    }

    if (buttonClicked === 'updateButton') {
      handleUpdate(e);
    } else if (buttonClicked === 'saveButton') {
      handleSave(e);
    }
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    if (buttonClicked !== 'saveOverride' && validation()) return
    try {
      const _region = { ...region, createdDate: getCurrentTimestamp() };
      const response = await create.region({ region: _region, pathname});
      dispatch(AddRegionAction(response));
      navigate(-1);
    } catch (error: any) {
      console.error('Error creating element:', error);
      const nameError = error.includes('Name') ? { error: true, message: 'Name already exists' } : { error: false, message: '' };
      const abbreviationError = error.includes('Abbreviation') ? { error: true, message: 'Abbreviation already exists' } : { error: false, message: '' };

      setErrors({ name: nameError, abbreviation: abbreviationError });
    }
  };

  useEffect(() => {
    if (errors.abbreviation.error || errors.name.error)
      setSaveOverrideOpen(true)
    else {
      setSaveOverrideOpen(false)
      if (error)
        setError(false)
    }
  }
    , [errors]);

  const handleUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    if (buttonClicked !== 'saveOverride' && validation()) return
    try {
      await update.region({ region, pathname});
      dispatch(UpdateRegion(region));
      navigate(-1);
    } catch (error: any) {
      console.error('Error creating element:', error);
      const nameError = error.includes('Name') ? { error: true, message: 'Name already exists' } : { error: false, message: '' };
      const abbreviationError = error.includes('Abbreviation') ? { error: true, message: 'Abbreviation already exists' } : { error: false, message: '' };

      setErrors({ name: nameError, abbreviation: abbreviationError });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (error) validation();
    setRegion({ ...region, [e.target.name]: e.target.value });
  }

  const handleCloseSaveOverride = () => {
    setSaveOverrideOpen(!saveOverrideOpen);
  }

  useEffect(() => {
    const errorsToSet = Object.keys(errors).filter((clave) => errors[clave as keyof typeof errors].error);
    setErrorFields(errorsToSet);
  }, [errors]);

  return (
    <>
      <div className='  mainSection'>
        <form onSubmit={handleSubmit}>
          <SaveOverride fields={errorFields} handleButtonClick={handleButtonClick} open={saveOverrideOpen} close={handleCloseSaveOverride} />

          <CatalogTitle title={`${props.mode === 'add' ? 'New Region' : `Edit ${state?.name}`}`} className="text-Default">
            <GoBack />
          </CatalogTitle>
          <article className='articleSection '>
            <section className='formsectionNTitle '>
              <CatalogSubTitle title="Region Information" className=" text-accent-Default-dark" />
              <div className="formsection grid grid-cols-12 lg:grid-cols-11 ">
                <div className=" col-span-12 md:col-span-8 lg:col-span-4">
                  <Input name="name" title="Name" value={region.name} required={true} error={errors.name.error} errorMsj={errors.name.message} onChange={handleInputChange} />
                </div>
                <div className=" col-span-12 md:col-span-4 lg:col-span-2">
                  <Input name="abbreviation" value={region.abbreviation} title="Abbreviation" required={true} error={errors.abbreviation.error} errorMsj={errors.abbreviation.message} onChange={handleInputChange} />
                </div>
              </div>
              <ButtonsCrud
                Catalog="Region"
                mode={props.mode}
                id={id ? id : ""}
                disabled={errors.name.error || errors.abbreviation.error}
                actionButton={handleButtonClick}
                onDelete={handleDelete}
                state={state}
                newState={region}
              />
            </section>
          </article>
        </form>
      </div>
    </>
  );
}

export default AddRegion;