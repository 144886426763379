const Categories = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="2 2 20 20"
      className={props.className}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M6.8075 10.8845L11.6152 3L16.423 10.8845H6.8075ZM17.1152 21.8845C15.9677 21.8845 14.995 21.4855 14.197 20.6875C13.399 19.8895 13 18.9168 13 17.7693C13 16.6218 13.399 15.649 14.197 14.851C14.995 14.053 15.9677 13.654 17.1152 13.654C18.2627 13.654 19.2355 14.053 20.0335 14.851C20.8315 15.649 21.2305 16.6218 21.2305 17.7693C21.2305 18.9168 20.8315 19.8895 20.0335 20.6875C19.2355 21.4855 18.2627 21.8845 17.1152 21.8845ZM3 21.3845V14.154H10.2305V21.3845H3ZM17.115 20.3848C17.8472 20.3848 18.4662 20.1319 18.972 19.6263C19.4778 19.1206 19.7307 18.5017 19.7307 17.7695C19.7307 17.0373 19.4779 16.4183 18.9723 15.9125C18.4666 15.4067 17.8477 15.1538 17.1155 15.1538C16.3833 15.1538 15.7643 15.4066 15.2585 15.9122C14.7527 16.4179 14.4998 17.0368 14.4998 17.769C14.4998 18.5012 14.7526 19.1202 15.2582 19.626C15.7639 20.1318 16.3828 20.3848 17.115 20.3848ZM4.49975 19.8848H8.73075V15.6538H4.49975V19.8848ZM9.45375 9.38475H13.7768L11.6152 5.90775L9.45375 9.38475Z"/>
    </svg>
  );
}

export default Categories;