import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IGlobalCrisis from "../../../interfaces/models/IGlobalCrisis";

const initialState: IGlobalCrisis[] = [];

export const globalCrisisSlice = createSlice({
  name: "globalCrisis",
  initialState,
  reducers: {
    SetGlobalCrisis: (_, action: PayloadAction<IGlobalCrisis[]>) => action.payload,
    AddGlobalCrisis: (state, action: PayloadAction<IGlobalCrisis>) => {
      state.push(action.payload);
    },
    UpdateGlobalCrisis: (state, action: PayloadAction<IGlobalCrisis>) => {
      const index = state.findIndex((globalCrisis) => globalCrisis.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },
    DeleteGlobalCrisis: (state, action: PayloadAction<string>) => {
      return state?.filter((globalCrisis) => globalCrisis.id !== action.payload);
    },
  },
});

export const {
  SetGlobalCrisis,
  UpdateGlobalCrisis,
  AddGlobalCrisis,
  DeleteGlobalCrisis,
} = globalCrisisSlice.actions;
