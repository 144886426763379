import React from 'react';

export interface Tab {
  title: string;
  action: () => void;
  selectedTab?: string | number;
}

interface FirmOfficesTab extends Tab {
  id?: string;
  name?: string;
  countryCodePhone: string;
  countryCodeFax: string;
  faxNumber: string;
  yearFounded: number;
  jurisdiction: string;
  jurisdictionGroup: string;
  countOfPeople: number;
  officeType: any;
  address: string;
  firm: string;
  city: string;
  phoneNumber: string;
  digPhoneNumber: string;
  codePhoneNumber: string;
  overview: string;
}

interface TabManagerProps {
  tabs?: Tab[];
  officesTabs?: FirmOfficesTab[];
}

interface TabsManagerProps extends TabManagerProps {
  tabs?: Tab[];
  selectedTabIndex: number;
  officesTabs?: FirmOfficesTab[];
  selectedTab?: string | number;
}

const TabManager: React.FC<TabsManagerProps> = ({ tabs, selectedTabIndex, officesTabs, selectedTab }) => {
  return (
    <div className={`tabs-container gap-1 flex ${selectedTabIndex}  relative z-10 self-stretch overflow-x-scroll`}>
      {tabs?.map((tab, index) => (
        <button key={index} onClick={tab.action} className={`py-3 pl-3 min-h-[2.6125rem] pr-2 flex min-w-[8.1875rem] justify-center items-center gap-3 lg:py-3 lg:px-3 lg:min-w-[10.53125rem] md:min-w-[9.375rem]
          ${index === selectedTabIndex ? 'bg-[#004578] text-white' : 'bg-[#D3D3D3] text-[#514F4F]'}
        `}
        >
          <span className='font-decimal text-[.75rem] font-medium leading-[1.125rem] md:text-[.875rem] lg:text-base lg:leading-5'>{tab.title}</span>
        </button>
      ))}
      {officesTabs && officesTabs.length > 0 &&
        officesTabs.map((tab, index) => (
          <button key={index} onClick={tab.action} className={`py-3 pl-3 min-h-[2.6125rem] pr-2 flex min-w-[8.1875rem] justify-center items-center gap-3 lg:py-3 lg:px-3 lg:min-w-[10.53125rem] md:min-w-[9.375rem]
          ${(tab.id === selectedTab) ? 'bg-[#004578] text-white' : 'bg-[#D3D3D3] text-[#514F4F]'}
        `}
          >
            <span className='font-decimal text-[.75rem] font-medium leading-[1.125rem] md:text-[.875rem] lg:text-base lg:leading-5'>{tab.name}</span>
          </button>
        ))
      }
    </div>
  );
};

export default TabManager;
