import { Link } from "react-router-dom";
import CatalogTableC from "../../../components/Catalogs/CatalogTableC";
import Eye from "../../../components/Atomos/Icons/ViewMode/Eye";
import { ColumnConfig } from "../../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport";
import { useColumnConfig } from "../../../hooks/Table/UseColumnSettings";
import { useEffect, useMemo, useState } from "react";
import BackgroundComponent from "../../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from '../../../assets/images&icons/profileViewMode.jpeg';
import { Option } from "../../../components/Atomos/DropDown/DDMultiple";
import { UsePracticeIndustryList } from "../../../hooks/Catalogs/usePracticeIndustry";
import AopManagementFilter from "./AopManagementFilter";
import IOffice from "../../../interfaces/models/IOffice";
import { getOfficesByFirmId } from "../../../Services/Firm";
import { useSelector } from 'react-redux';
import { Appstore } from "../../../redux";
import { defaultPageSize } from "../../../utils/const";
import useDataLoader2 from "../../../hooks/useDataLoader2";
import TableWithPagination from '../../../components/Organismos/Table/TableTransitionToTanSack2';
import { fetchPersonByFirm } from "../../../Services/Person";
import TabManager, { Tab } from "../../../components/Layout/TabManager/TabManager";
import CurrentPage from "../../../components/Layout/CurrentPageTab/CurrentPage";
const WSGDots = require("../../../assets/images&icons/WSGDots.svg");

const AopManagement: React.FC = ({}) => {
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);
  const Headers: string[] = ["name", "professionals"];

  const { generateColumns } = useColumnConfig(['name', 'professionals','_ts']);

  const [selectedPracticeIndustry, setSelectedPracticeIndustry] = useState<Option[]>([]);
  const [nameFilter, setNameFilter] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [tableInstance, setTableInstance] = useState<any>();
  const { practiceIndustryData: practiceIndustries } = UsePracticeIndustryList();
  const [firmOffices, setFirmOffices] = useState<IOffice[]>([]);
  const [allPersons, setAllPersons] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const fields = [
    'id', 
    'legacyId',
    'name', 
    'professionals',
    'firms',
    'offices',
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination,
    persistentFilter,
    setPersistentFilter
  } = useDataLoader2<any>({
    endpoint: '/areaOfPractice',
    defaultPageSize,
    fields,
  });

  useEffect(() => {
    if (
      !!data.length ||
      !persistentFilter ||
      isFetching
    )
      return;
    fetchData();
    setIsFetching(true);
  }, [data, persistentFilter]);

  const columnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false, size: 50 },
    { id: 'name', header: 'Area Of Practice'},
    { id: 'professionals', header: '# Of Professionals' },
    {
      id: 'edit',
      header: 'Actions',
      enableSorting: false,
      cell: (props: any) => (
        <Link
          className="edit"
          to={`/Staff/ManageFirm/aopManagement/professionals/${props.row.original.id}`}
          state={{
            name: props.row.original.name,
            firm: manageFirm.selectedFirm,
            filteredPersons: filterProfessionalsByAopAndOffice(props.row.original.name),
            selectedTab: selectedTab == 0 ? selectedTab : selectedTab.toString(),
          }}
        >
          View Professionals
          <div className="editsvg"><Eye /></div>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    getAllPersons();
  }, []);

  const getAllPersons = async () => {
    const persons = await fetchPersonByFirm(manageFirm.selectedFirm?.id);
      setAllPersons(persons);
  };

  const filterProfessionalsByAopAndOffice = (aopName: string) => {
    if (allPersons && allPersons.length > 0) {
      if (selectedTab !== 0) {
        return allPersons.filter(person =>
          person.areasOfPractice?.some((aop: any) => aop.name === aopName) &&
          person.mainOffice === firmOffices[selectedTab - 1].id
        );
      } else {
        return allPersons.filter(person =>
          person.areasOfPractice?.some(
            (aop: any) => aop.name === aopName) &&
            firmOffices.some(office => office.id === person.mainOffice
          )
        );
      }
    }
    return [];
  };

  const getProfessionalCountForAopAndOffice = (aopName: string) => {
    const filteredProfessionals = filterProfessionalsByAopAndOffice(aopName);
    return filteredProfessionals.length;
  };

  const columns = generateColumns(columnConfigs.map(column => ({
    ...column,
    cell: column.cell || ((props: any) => {
      switch (column.id) {
        case 'count':
          return <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
        case 'professionals':
          const aopName = props.row.original.name;
          const professionalCount = getProfessionalCountForAopAndOffice(aopName);
          return <p>{professionalCount}</p>;
        default:
          return <p>{props.getValue()}</p>;
      }
    })
  })));

  useEffect(() => {
    const filterAux: any = {
      name: {
        value: nameFilter,
        comparisonType: 'CONTAINS'
      },
      practiceIndustries: { value: selectedPracticeIndustry?.map(f => f.id) },
      firms: { value: [manageFirm.selectedFirm?.id] }
    };
  
    if (selectedTab !== 0) {
      filterAux.offices = { value: [selectedTab.toString()] };
    } else {
      delete filterAux.offices;
    }
  
    setFilter(filterAux);
  }, [
    nameFilter,
    selectedPracticeIndustry,
  ]);

  const clearSelections = () => {
    setSelectedPracticeIndustry([]);
    setNameFilter('');
    setFilter([]);
    fetchData(true)
  };

  const practiceIndustriesAsFilterProps: Option[] = useMemo(() => {
    return practiceIndustries
      .filter(pi => pi != null && typeof pi.name === 'string')
      .map(pi => ({ id: pi.id, name: pi.name } as Option));
  }, [practiceIndustries]);

  const handleNameFilterChange = (newNameFilter: string) => {
    setNameFilter(newNameFilter);
  };

  const getFirmOffices = async (id: string) => {
    const data: any = await getOfficesByFirmId(id);
    if (data) {
      const updatedData = data.map((office: any) => ({
        ...office,
        action: () => {
          setSelectedTab(office.id);
        }
      }));
      setFirmOffices(updatedData);
    }
  };

  useEffect(() => {
    if (!manageFirm.selectedFirm?.id) return
    getFirmOffices(manageFirm.selectedFirm.id);
  }, [manageFirm.selectedFirm]);

  useEffect(() => {
    setPersistentFilter({firms: {value: [manageFirm.selectedFirm?.id], comparisonType: "EQUAL"}})
  }, []);

  const tabs: Tab[] = [
    {
      title: 'Firm Wide',
      action: () => {
        setSelectedTab(0);
      },
    },
      ...firmOffices.map((office, index) => ({
        title: office.name,
        action: () => setSelectedTab(index + 1),
      }))
    ];
    return (
      <>
        <BackgroundComponent image={ProfileViewModeBg} />
        <div className="bg-gray-100 min-h-screen text-left px-4 py-7 items-start flex flex-col gap-[1.5rem] md:py-8 md:px-6 lg:py-10 lg:px-10 lg:gap-[2.5rem]">
          <div className="flex flex-col items-start gap-5 self-stretch">
            <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 self-stretch !p-0 flex-col ">
              <div className="flex p-0 flex-col justify-center gap-5 self-stretch md:flex-row lg:justify-between lg:gap-6">
                <h2 className="text-left w-full lg:w-max text-white font-decimal text-[2.25rem] font-medium leading-[2.75rem] md:text-[2.75rem] md:leading-[3.25rem] lg:text-[3.25rem] lg:leading-[4.5rem]">
                  Login History
                </h2>
                <div
                  className="self-stretch flex-1 hidden lg:flex"
                  style={{
                    backgroundImage: `url(${WSGDots.default})`,
                  }}
                ></div>
              </div>
              <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
              <div className="flex w-full">
                <CurrentPage
                  prevPage="Proffessionals"
                  currentPage="Login History"
                />
              </div>
  
              <div className="">
                <TabManager tabs={tabs} selectedTabIndex={selectedTab} />
                <CatalogTableC
                  catalogName="AREAS OF PRACTICE"
                  catalogShortName="Area Of Practice"
                  route="AreaOfPractice"
                  isfiltered={true}
                  data={data}
                  headers={Headers}
                  totalCount={totalCount ?? 0}
                  exportData={data}
                  linkToAdd="/staff/manageFirm/aopManagement/update"
                >
                  <AopManagementFilter
                    order={["name", "practiceIndustries"]}
                    search={() => fetchData()}
                    resetFilters={clearSelections}
                    headers={["name", "practiceIndustries"]}
                    practiceIndustriesData={practiceIndustriesAsFilterProps}
                    selectedPracticeIndustries={selectedPracticeIndustry}
                    setSelectedPracticeIndustries={setSelectedPracticeIndustry}
                    nameFilter={nameFilter}
                    onNameFilterChange={handleNameFilterChange}
                    tableInstance={tableInstance}
                  />
                  <TableWithPagination
                    data={data}
                    columnsDef={columns}
                    setTableInstance={setTableInstance}
                    sorting={sort}
                    setSorting={setSort}
                    totalCount={totalCount}
                    pagination={pagination}
                    setPagination={setPagination}
                  />
                </CatalogTableC>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

export default AopManagement;
