import React, { useEffect, useState } from 'react';
import useDataLoader2 from '../../hooks/useDataLoader2';
import EducationFilter from '../../components/Catalogs/Filters/EducationFilter';
import Axios from '../../utils/axios';
import { UseFirmList } from '../../hooks/Catalogs/useFirm';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import { ColumnConfig } from '../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport';
import { transformData } from '../../hooks/Table/TransformColumnsForExport';
import { formatTimeSpanToString } from '../../utils/functions';
import { defaultPageSize } from '../../utils/const';

const Education: React.FC = ({ ...props }) => {
  const axios = new Axios()
  const Headers: string[] = ['firm', 'person', 'school', 'degree', 'createdDate'];
  const [selectedFirm, setSelectedFirm] = useState<any[]>([]);
  const [personData, setPersonData] = useState<any[]>([])
  const [selectedPerson, setSelectedPerson] = useState<any[]>([]);
  const [schoolData, setSchoolData] = useState<any[]>([]) //TODO modify to use Redux and hook
  const [selectedSchool, setSelectedSchool] = useState<any[]>([]);
  const [degreeData, setDegreeData] = useState<any[]>([]) //TODO modify to use Redux and hook
  const [selectedDegree, setSelectedDegree] = useState<any[]>([]);
  const [tableInstance, setTableInstance] = useState<any>();
  const { firmData } = UseFirmList();
  const { generateColumns } = useColumnConfig(Headers);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/Education',
    defaultPageSize,
  });
  const columnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false, size: 50 },
    { id: 'firmName', header: 'Firm' },
    { id: 'personName', header: 'Person' },
    { id: 'schoolName', header: 'School' },
    { id: 'degreeName', header: 'Degree' },
    { id: 'createdDate', header: 'Created Date' },
    {
      id: 'edit',
      header: 'Edit',
      enableSorting: false,
      cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={props.row.original}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ),
    },
  ];

  const columns = generateColumns(columnConfigs.map(column => ({
    ...column,
    cell: column.cell || ((props: any) => {
      switch (column.id) {
        case 'count':
          return <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
        case 'firm':
          return <p>{props.getValue()?.name}</p>
        case 'personName':
          return <p>{props.getValue()}</p>;
        case 'schoolName':
          return <p>{props.getValue()}</p>;
        case 'degreeName':
          return <p>{props.getValue()?.name}</p>;
        case 'createdDate':
          const value = props.getValue();
          return (
            <p>
              {value && formatTimeSpanToString(value)}
            </p>
          );
        default:
          return <p>{props.getValue()}</p>;
      }
    })
  })));

  useEffect(() => {
    fetchPersonData()
    fetchSchoolData()
    fetchDegreeData()
  }, []);

  const clearSelections = async () => {
    setFilter([]);
    setSelectedFirm([]);
    setSelectedPerson([]);
    setSelectedSchool([]);
    setSelectedDegree([]);
    fetchData(true)
  };

  const fetchPersonData = async () => {
    try {
      const response = await axios.Get('./Person')
      const data = response.data
      if (data != null) {
        setPersonData(data)
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  const fetchSchoolData = async () => {
    try {
      const response = await axios.Get('./School')
      const data = response.data
      if (data != null) {
        setSchoolData(data)
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  const fetchDegreeData = async () => {
    try {
      const response = await axios.Get('./Degree')
      const data = response.data
      if (data != null) {
        setDegreeData(data)
      }
    } catch (error: any) {
      console.log(error)
    }
  }
  const transformedData = transformData(data, columnConfigs, pagination.pageIndex, pagination.pageSize, firmData, '', 'Education');

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  useEffect(() => {
    let filterAux = {
      firm: {
        value: selectedFirm.map(f => f.id),
      },
      person: {
        value: selectedPerson?.map(c => c.id),
      },
      school: {
        value: selectedSchool?.map(f => f.id),
      },
      degree: {value: selectedDegree?.map(f => f.id)},
    } //TODO Modify all fields how are saving
    setFilter(filterAux)
  },[
    selectedFirm, 
    selectedPerson, 
    selectedSchool, 
    selectedDegree
  ]);

  return (
    <>
      <div className='mainSection'>
        <CatalogTableC
          title={`Education Catalog`}
          catalogName={'Education'}
          route={'Education'}
          data={data}
          headers={Headers}
          totalCount={totalCount}
          isfiltered={true}
          exportData={transformedData}
        >
          <EducationFilter
            headers={Headers}
            search={() => fetchData()}
            firmData={firmData}
            selectedFirm={selectedFirm}
            setSelectedFirm={setSelectedFirm}
            personData={personData}
            selectedPerson={selectedPerson}
            setSelectedPerson={setSelectedPerson}
            selectedTab={1}
            schoolData={schoolData}
            selectedSchool={selectedSchool}
            setSelectedSchool={setSelectedSchool}
            degreeData={degreeData}
            selectedDegree={selectedDegree}
            setSelectedDegree={setSelectedDegree}
            resetFilters={clearSelections}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </div>
    </>
  );
};

export default Education;