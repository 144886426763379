import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IContentTag from "../../../interfaces/models/IContentTag";

const initialState: IContentTag[] = [];

export const contentTagsSlice = createSlice({
  name: "contentTags",
  initialState,
  reducers: {
    SetContentTags: (_, action: PayloadAction<IContentTag[]>) => action.payload,
    UpdateContentTags: (state, action: PayloadAction<IContentTag>) => {
      const index = state.findIndex((contentTag) => contentTag.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },    
    AddContentTags: (state, action: PayloadAction<IContentTag>) => {
      state.push(action.payload);
    },
    DeleteContentTags: (state, action: PayloadAction<string>) => {
      return state?.filter((contentTag) => contentTag.id !== action.payload);
    },
  },
});

export const {
  SetContentTags,
  UpdateContentTags,
  AddContentTags,
  DeleteContentTags,
} = contentTagsSlice.actions;