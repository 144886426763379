import { useLocation } from "react-router-dom";
import Axios from "../../utils/axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonsCrud from "../../components/Catalogs/ButtonsCrud";
import "../../assets/css/AddCatalogs.css";
import Input from "../../components/Atomos/Inputs/Input";
import SaveOverride from "../../components/Catalogs/SaveOverride";
import CatalogSubTitle from "../../components/Moleculas/Catalog Text/CatalogSubTitle";
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import DDMultiple, {
  Option,
} from "../../components/Atomos/DropDown/DDMultiple";
import RadioButton from "../../components/Atomos/Radio/RadioButton";
import NewButton from "../../components/Atomos/Buttons/newButton";
import SwitchToogle from "../../components/Atomos/Switch-Toogle/Switch";
import TextArea from "../../components/Atomos/Inputs/TextArea";
import WSGCheckbox from "../../components/Atomos/Checkbox/WSGCheckBox";
import CalendarInput from "../../components/Atomos/Inputs/CalendarInput";
import Plus2 from "../../components/Atomos/Icons/Plus";
import Eye from "../../components/Atomos/Icons/ViewMode/Eye";
import Modal from "../../components/Atomos/Modals/Modal";
import Cancel from "../../components/Atomos/Icons/Close";
import SvgIcon from "../../components/ViewMode/IconComponent";
import NewTooltip from "../../components/Atomos/Tooltip/NewTooltip";
import { submissionTypeEnum } from "../../Enums/ContentEnums";
import { IContent } from "../../interfaces/models/IContent";
import { UseFirmList } from "../../hooks/Catalogs/useFirm";
import { UseJurisdictionList } from "../../hooks/Catalogs/useJurisdiction";
import { UsePracticeIndustryList } from "../../hooks/Catalogs/usePracticeIndustry";
import { UseContentTag } from "../../hooks/Catalogs/useContentTag";
import { UseContentGeneralTopicList } from "../../hooks/Catalogs/useContentGeneralTopic";
import {
  UseGlobalCrisislList,
} from "../../hooks/Catalogs/useGlobalCrisis";
import { MemberType } from "../../Enums/PersonEnums";
import { useDispatch, useSelector } from "react-redux";
import { Appstore, contentContext, contentsSlice } from "../../redux";
import {
  AddContentGeneralTopics,
  AddContentTags,
  AddGlobalCrisis,
} from "../../redux/catalogs/states";
import RichTextEditor from "../../components/SlateEditor/RichTextEditor";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import ManageFirmPracticeHeadsBackground from "../../assets/images&icons/bg-manageFirmPracticeHeads.png";
import GoBack from "../../components/Moleculas/GoBack";
import { getCurrentTimestamp } from "../../utils/functions";
import { IContentGeneralTopic } from "../../interfaces";
import { useUpdateAndLog } from "../../hooks/LogActivity/LogActivityUpdates";
import { useCreateAndLog } from "../../hooks/LogActivity/LogActivityCreates";
import { useDeleteAndLog } from "../../hooks/LogActivity/LogActivityDeletes";
import IContentTag from "../../interfaces/models/IContentTag";
import IGlobalCrisis from "../../interfaces/models/IGlobalCrisis";

const WSGDots = require("../../assets/images&icons/WSGDots.svg");

const initialFormData: IContent = {
  title: "",
  contentType: "article",
  writtenDate: undefined,
  reviewedDate: "pending",
  publishedDate: "pending",
  isApproved: "pending",
  shortId: "",
  authors: [],
  submissionType: "manualInput",
  footnotes: "",
  link: "",
  memberFirms: [],
  nonMemberFirms: [],
  jurisdictions: [],
  tags: [],
  articleContent: "",
  generalTopics: [],
  globalCrisisHub: [],
  practiceIndustries: [],
  createdBy: "",
};

interface iCatalogProps {
  mode: "add" | "edit";
}

type Errors = {
  title: { error: boolean; message: string };
  writtenDate: { error: boolean; message: string };
  author: { error: boolean; message: string };
  jurisdiction: { error: boolean; message: string };
  articleContent: { error: boolean; message: string };
  footnotes: { error: boolean; message: string };
  tagModal: { error: boolean; message: string };
  crisisModal: { error: boolean; message: string };
  topicModal: { error: boolean; message: string };
  practiceIndustries: { error: boolean; message: string };
};

const initialErrors = {
  title: { error: false, message: "" },
  writtenDate: { error: false, message: "" },
  author: { error: false, message: "" },
  jurisdiction: { error: false, message: "" },
  articleContent: { error: false, message: "" },
  footnotes: { error: false, message: "" },
  tagModal: { error: false, message: "" },
  crisisModal: { error: false, message: "" },
  topicModal: { error: false, message: "" },
  practiceIndustries: { error: false, message: "" },
};

const CreatePageArticles: React.FC<iCatalogProps> = (props) => {
  const axios = new Axios();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [errors, setErrors] = useState<Errors>(initialErrors);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [canSave, setCanSave] = useState(true);
  const [buttonClicked, setButtonClicked] = useState("");
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const create = useCreateAndLog();
  const dispatch = useDispatch();

  const isInitializedRef = useRef(false);

  useEffect(() => {
    if (props.mode === "add" && !isInitializedRef.current) {
      handleAdd();
    }
  }, [props.mode]);

  const handleAdd = () => {
    if (!isInitializedRef.current && props.mode === "add") {
      dispatch(contentsSlice.actions.NewContent({ content: initialFormData }));
      isInitializedRef.current = true;
    }
  };

  const currentContent: contentContext = useSelector(
    (store: Appstore) => store.content
  );

  const [nonMemberFirms, setNonMemberFirms] = useState<any[]>([]);

  const [firmMembers, setFirmMembers] = useState<Option[]>([]);
  const { jurisdictionData } = UseJurisdictionList();
  const [jurisdictions] = useState<any[]>(jurisdictionData);
  const [isAuthoredByFirm, setIsAuthoredByFirm] = useState<boolean>(false);
  const [isGlobalLocation, setIsGlobalLocation] = useState<boolean>(false);
  const [checkedGeneralTopics, setCheckedGeneralTopics] = useState<string[]>(
    []
  );
  const [checkedGlobalCrisis, setCheckedGlobalCrisis] = useState<string[]>([]);
  const [checkedPracticeIndustries, setCheckedPracticeIndustries] = useState<
    string[]
  >([]);
  const [tagModalOpen, setTagModalOpen] = useState<boolean>(false);
  const [crisisModalOpen, setCrisisModalOpen] = useState<boolean>(false);
  const [topicModalOpen, setTopicModalOpen] = useState<boolean>(false);
  const [modalNameAux, setModalNameAux] = useState<string>("");
  const [checked, setChecked] = useState(false);

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  const { firmData: firmsAux } = UseFirmList();

  const { contentTagsData: tags } = UseContentTag();
  const { contentGeneralTopicsData: generalTopics } =
    UseContentGeneralTopicList();
  const { contentGlobalCrisisData: globalCrisis } =
    UseGlobalCrisislList();
  const { practiceIndustryData: practiceIndustries } =
    UsePracticeIndustryList();

  useEffect(() => {
    if (
      !currentContent?.content ||
      !generalTopics.length ||
      !globalCrisis.length ||
      !practiceIndustries.length
    )
      return;

    setCheckedGeneralTopics(
      getNameById(currentContent?.content?.generalTopics ?? [], generalTopics)
    );
    setCheckedGlobalCrisis(
      getNameById(currentContent?.content?.globalCrisisHub ?? [], globalCrisis)
    );

    setCheckedPracticeIndustries(
      getNameById(
        currentContent?.content?.practiceIndustries ?? [],
        practiceIndustries
      )
    );
  }, [practiceIndustries, generalTopics, globalCrisis, currentContent]);

  const getNameById = (idArray: string[], data: any) => {
    if (!data.length || !idArray) return [];
    return data
      .filter((d: any) => idArray.includes(d.id))
      .map((m: any) => m.name);
  };

  useEffect(() => {
    if (checked) {
      validation();
    }
  }, [currentContent?.content]);

  const validation = () => {
    const newActiveErrors: Errors = {
      ...errors,
      writtenDate: {
        error: !currentContent?.content?.writtenDate,
        message: "Written date is required",
      },
      author: {
        error: !currentContent?.content?.authors.length,
        message: "At least one author is required",
      },
      jurisdiction: {
        error: !currentContent?.content?.jurisdictions.length,
        message: "At least one jurisdiction is required",
      },
      articleContent: {
        error: !currentContent?.content?.articleContent,
        message: "Article is required",
      },
      footnotes: {
        error: (currentContent?.content?.footnotes?.length ?? 0) == 0,
        message: "Footnotes is required",
      },
      title: {
        error: currentContent?.content?.title === "",
        message: "Title is required",
      },
    };

    if (currentContent?.content?.footnotes?.length ?? 0 > 0)
      newActiveErrors.footnotes = {
        error: (currentContent?.content?.footnotes?.length ?? 0) > 6000,
        message: "Footnotes cannot exceed 6000 characters",
      };

    setErrors(newActiveErrors);
    return isSomeError(newActiveErrors);
  };

  useEffect(() => {
    if (!firmsAux.length) return;
    const notMember = firmsAux.filter(
      (x: { isMember: boolean }) => x.isMember === false
    );

    setNonMemberFirms(notMember);
  }, [firmsAux]);

  useEffect(() => {
    const content = currentContent?.content;

    setIsAuthoredByFirm(!!content?.authors?.includes("authoredByFirm"));
    setIsGlobalLocation(!!content?.jurisdictions?.includes("GlobalLocation"));
  }, [currentContent?.content]);

  useEffect(() => {
    const content = currentContent?.content;
    const allFirms = content?.memberFirms?.concat(content?.nonMemberFirms)
    if (allFirms?.length) {
      axios
        .Get(`/person?firmsId=${JSON.stringify(allFirms)}`)
        .then((resp) => setFirmMembers(resp.data));
    }
    const firms = firmsAux.filter((f: any) =>
      content?.nonMemberFirms?.includes(f.id)
    );

    firms.map((f: any) => {
      if (f.membershipType !== "Primary") return;

      const jurisdictionsIds = firms.map((m: any) => m.jurisdictionId);
      const relevantJurisdiction = jurisdictionData
        .filter((j: any) => jurisdictionsIds.includes(j.id))
        .map((m: any) => m.id);

      dispatch(
        contentsSlice.actions.ModifyContent({
          content: {
            ...currentContent?.content,
            jurisdictions: relevantJurisdiction,
          },
        })
      );
    });
  }, [currentContent?.content?.memberFirms, currentContent?.content?.nonMemberFirms]);

  const isSomeError = (errors: Errors) => {
    return Object.values(errors).some((errorObj) => errorObj.error);
  };

  const setSelectedObjects = (data: any, field: Option[]) => {
    if (!data.length || !field) return;
    return data.filter((d: any) => field.includes(d.id));
  };

  type CheckType = "generalTopics" | "globalCrisisHub" | "practiceIndustries";

  const getUpdatedValues = (
    currentValues: string[],
    value: string,
    limit?: number
  ): string[] => {
    if (currentValues.includes(value)) {
      return currentValues.filter((v) => v !== value);
    }
    if (limit && currentValues.length === limit) {
      return [...currentValues.slice(1), value];
    }
    return [...currentValues, value];
  };

  const getIds = (
    items: { name: string; id: string }[],
    values: string[]
  ): string[] => {
    return items
      .filter((item) => values.includes(item.name))
      .map((item) => item.id);
  };

  const handleCheckboxChange = (check: CheckType, value: string) => {
    let updatedValues: string[] = [];
    let ids: string[] = [];
    switch (check) {
      case "generalTopics":
        updatedValues = getUpdatedValues(checkedGeneralTopics, value);
        ids = getIds(generalTopics, updatedValues);
        break;
      case "globalCrisisHub":
        updatedValues = getUpdatedValues(checkedGlobalCrisis, value);
        ids = getIds(globalCrisis, updatedValues);
        break;
      case "practiceIndustries":
        updatedValues = getUpdatedValues(checkedPracticeIndustries, value, 3);
        ids = getIds(practiceIndustries, updatedValues);
        break;
    }

    const updatedContent = {
      ...currentContent?.content,
      [check]: ids,
    };

    dispatch(contentsSlice.actions.ModifyContent({ content: updatedContent }));
  };

  const handleRadioChange = (name: string, value: string) => {
    const updatedContent = {
      ...currentContent?.content,
      [name]: value,
    };
    dispatch(contentsSlice.actions.ModifyContent({ content: updatedContent }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    const updatedContent = {
      ...currentContent?.content,
      [name]: value,
    };

    dispatch(contentsSlice.actions.ModifyContent({ content: updatedContent }));
  };

  const handleDropdownChange = (
    name: string,
    value: any,
    multipleOption: boolean = false
  ) => {
    if (multipleOption) {
      const idArray = value.map((v: any) => v.id);

      const updatedContent = {
        ...currentContent?.content,
        [name]: idArray,
      };

      dispatch(
        contentsSlice.actions.ModifyContent({ content: updatedContent })
      );
    } else {
      const updatedContent = {
        ...currentContent?.content,
        [name]: value,
      };

      dispatch(
        contentsSlice.actions.ModifyContent({ content: updatedContent })
      );
    }
  };

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };
  const handleDelete = async (id: string) => {
    const response = await axios.Delete(`/Content`, id);
    if (response.status === 200) {
      navigate("/Staff/Catalogs/Content");
    } else {
      console.error("Error al eliminar el elemento:", response.data);
    }
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (buttonClicked === "saveOverride") {
      if (props.mode === "add") {
        handleSave(e);
      } else {
        handleUpdate(e);
      }
    }

    if (buttonClicked === "updateButton") {
      handleUpdate(e);
    } else if (buttonClicked === "saveButton") {
      handleSave(e);
    }
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      if (!checked) {
        setChecked(true);
      }
      if (buttonClicked !== "saveOverride") {
        if (validation()) return;
      }
    } catch {}

    try {
      const addData: IContent = currentContent?.content ?? initialFormData;
      const response = await axios.Post("/content", addData);
      if (response.status === 200) {
        if (response.data.error) {
          if (response.data.error.includes("Name")) {
            setErrors({
              ...errors,
              title: { error: true, message: "Title already exists" },
            });
          }
        } else {
          dispatch(
            contentsSlice.actions.ModifyContent({ content: initialFormData })
          );

          setTimeout(() => {
            navigate("/Articles/Manage");
          }, 500);
        }
      } else {
        console.error("Error creating element:", response.data);
      }
    } catch (error: any) {
      console.error("Error creating element:", error);
    }
  };
  const handleUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    if (buttonClicked !== "saveOverride") {
      await validation();
    }

    if (!canSave) {
      const updatedData: IContent = currentContent?.content ?? initialFormData;
      try {
        const response = await axios.Put("Content", updatedData);
        if (response.data.error) {
          if (response.data.error.includes("Name")) {
            setErrors({
              ...errors,
              title: { error: true, message: "Title already exists" },
            });
          }
        } else navigate("/Articles/Manage");
      } catch (error: any) {
        console.error("Error al actualizar el elemento:", error);
      }
    }
  };

  const handleCloseSaveOverride = () => {
    setSaveOverrideOpen(!saveOverrideOpen);
  };

  const cleanData = (obj: any): Partial<IContent> => {
    const result: Partial<IContent> = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key as keyof IContent];
        if (value && value.length !== 0 && value !== "pending") {
          result[key as keyof IContent] = value;
        }
      }
    }
    return result;
  };

  const handleNewNonMemberFirm = () => {
    const auxContent = cleanData(currentContent?.content);
    const newNonMemberFirm = {
      content: auxContent,
      isMember: false,
      contentRedirectUrl: pathname,
    };
    navigate(`${pathname}/Firm`, { state: newNonMemberFirm });
  };

  const handleNewProfessional = () => {
    const auxContent = cleanData(currentContent?.content);
    const newProfessional = {
      content: auxContent,
      memberType: MemberType.member,
      contentRedirectUrl: pathname,
    };

    navigate(`${pathname}/AddPerson`, { state: newProfessional });
  };

  const saveCrisis = async () => {
    try {
      const globalCrisis: IGlobalCrisis = {id: "", name: modalNameAux, createdDate: getCurrentTimestamp()};
      const response = await create.globalCrisis({ globalCrisis, pathname });
      dispatch(AddGlobalCrisis(response));
      setModalNameAux("");
    } catch (error: any) {
      if (error.message.includes("Name")) {
        setErrors({
          ...errors,
          topicModal: { error: true, message: "Name already exists" },
        });
      } else {
        console.error(error);
      }
    }
    setErrors({ ...errors, crisisModal: { error: false, message: "" } });
    setCrisisModalOpen(!crisisModalOpen);
    setModalNameAux("");
  }

  const saveTag = async () => {
    try {
      const contentTag: IContentTag = {id: "", name: modalNameAux, createdDate: getCurrentTimestamp()};
      const response = await create.contentTag({ contentTag, pathname });
      dispatch(AddContentTags(response));
      setModalNameAux("");
    } catch (error: any) {
      if (error.message.includes("Name")) {
        setErrors({
          ...errors,
          topicModal: { error: true, message: "Name already exists" },
        });
      } else {
        console.error(error);
      }
    }
    setErrors({ ...errors, tagModal: { error: false, message: "" } });
    setTagModalOpen(!tagModalOpen);
    setModalNameAux("");
  }

  const saveTopic = async () => {
    try {
      const generalTopic: IContentGeneralTopic = {id: "", name: modalNameAux, createdDate: getCurrentTimestamp()};
      const response = await create.contentGeneralTopic({ generalTopic, pathname });
      dispatch(AddContentGeneralTopics(response));
    } catch (error: any) {
      if (error.message.includes("Name")) {
        setErrors({
          ...errors,
          topicModal: { error: true, message: "Name already exists" },
        });
      } else {
        console.error(error);
      }
    }
    setErrors({ ...errors, topicModal: { error: false, message: "" } });
    setTopicModalOpen(!topicModalOpen);
    setModalNameAux("");
  }

  const saveModal = async (modal: string) => {
    if (modal === "crisis") {
      await saveCrisis();
    } else if (modal === "tag") {
      await saveTag();
    } else if (modal === "topic") {
      await saveTopic();
    }
  };

  useEffect(() => {
    let updatedContent = { ...currentContent?.content };

    if (!currentContent?.modified && state?.content) {
      updatedContent = {...state.content};
    }
    if (state?.contentType) {
      updatedContent.contentType = state.contentType;
    }    
    if (state?.currentFirm?.length) {
      updatedContent.memberFirms = [state.currentFirm];
    }
    if (state?.currentPerson?.length) {
      updatedContent.createdBy = state.currentPerson;
    }

    if (Object.keys(updatedContent).length) {
      dispatch(
        contentsSlice.actions.ModifyContent({
          ...currentContent,
          content: updatedContent,
        })
      );
    }

    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const errorsToSet = Object.keys(errors).filter(
      (clave) => errors[clave as keyof typeof errors].error
    );

    setErrorFields(errorsToSet);
    setSaveOverrideOpen(errorsToSet.length > 0);
    setCanSave(isSomeError(errors));
  }, [errors]);

  useEffect(() => {
    topicModalOpen && setModalNameAux("");
    crisisModalOpen && setModalNameAux("");
    if (!tagModalOpen) setModalNameAux("");
  }, [tagModalOpen, topicModalOpen, crisisModalOpen]);

  return (
    <>
      <BackgroundComponent image={ManageFirmPracticeHeadsBackground} />

      <div className={`mainSection ${saveOverrideOpen ? "pt-7 md:pt-9 " : ""}`}>
        <form onSubmit={handleSubmit}>
          <SaveOverride
            fields={errorFields}
            handleButtonClick={handleButtonClick}
            open={saveOverrideOpen}
            close={handleCloseSaveOverride}
          />

          <div className="flex flex-col pt-7 gap-5 md:gap-6 lg:gap-7 md:pt-8 lg:pt-10 pb-5">
            <div className="flex md:min-h-[50px] gap-4 justify-between">
              <CatalogTitle
                title={`New Article`}
                className="text-white !p-0 lg:text-[52px] md:text-[44px] text-[42px]"
              />
              <div
                className="md:min-w-[20rem] overflow-hidden flex-1 hidden relative md:flex"
                style={{
                  backgroundImage: `url(${WSGDots.default})`,
                }}
              ></div>
              <div className="text-white relative">
                <GoBack className="!text-white !fill-white" />
              </div>
            </div>
            <hr className="flex h-0 justify-end items-center self-stretch relative z-10" />
            <div className="flex w-full">
              <CurrentPage
                prevPage="Home > Publications"
                currentPage={`Member Articles`}
              />
            </div>
          </div>

          <article className="articleSectionRelative">
            <Modal
              isModalOpen={tagModalOpen}
              toggleModal={() => setTagModalOpen(!tagModalOpen)}
              title="New Tag"
            >
              <div className="flex flex-col formModal formsection lg:items-end">
                <Input
                  title="Name"
                  error={errors.tagModal.error}
                  errorMsj={errors.tagModal.message}
                  required
                  value={modalNameAux}
                  onChange={(e) => setModalNameAux(e.target.value)}
                />
                <div className="flex formsection flex-col md:flex-row">
                  <NewButton
                    text="CANCEL"
                    icon={<Cancel />}
                    style={"outlined"}
                    size="large"
                    content="textIcon"
                    onClick={() => setTagModalOpen(!tagModalOpen)}
                    color="neutral"
                  />
                  <NewButton
                    text="ADD"
                    icon={<Plus2 />}
                    style={"filled"}
                    size="large"
                    content="textIcon"
                    onClick={() => saveModal("tag")}
                    color="accent"
                  />
                </div>
              </div>
            </Modal>
            <Modal
              isModalOpen={topicModalOpen}
              toggleModal={() => setTopicModalOpen(!topicModalOpen)}
              title="New Topic"
            >
              <div className="flex flex-col formsection formModal lg:items-end">
                <Input
                  title="Name"
                  error={errors.topicModal.error}
                  errorMsj={errors.topicModal.message}
                  required
                  value={modalNameAux}
                  onChange={(e) => setModalNameAux(e.target.value)}
                />
                <div className="flex flex-col md:flex-row formsection">
                  <NewButton
                    text="CANCEL"
                    icon={<Cancel />}
                    style={"outlined"}
                    size="large"
                    content="textIcon"
                    onClick={() => setTopicModalOpen(!topicModalOpen)}
                    color="neutral"
                  />
                  <NewButton
                    text="ADD"
                    icon={<Plus2 />}
                    style={"filled"}
                    size="large"
                    content="textIcon"
                    onClick={() => saveModal("topic")}
                    color="accent"
                  />
                </div>
              </div>
            </Modal>
            <Modal
              isModalOpen={crisisModalOpen}
              toggleModal={() => setCrisisModalOpen(!crisisModalOpen)}
              title="New Crisis"
            >
              <div className="flex flex-col formsection formModal lg:items-end">
                <Input
                  title="Name"
                  error={errors.crisisModal.error}
                  errorMsj={errors.crisisModal.message}
                  required
                  value={modalNameAux}
                  onChange={(e) => setModalNameAux(e.target.value)}
                />
                <div className="flex flex-col md:flex-row formsection ">
                  <NewButton
                    text="CANCEL"
                    icon={<Cancel />}
                    style={"outlined"}
                    size="large"
                    content="textIcon"
                    onClick={() => setCrisisModalOpen(!crisisModalOpen)}
                    color="neutral"
                  />
                  <NewButton
                    text="ADD"
                    icon={<Plus2 />}
                    style={"filled"}
                    size="large"
                    content="textIcon"
                    onClick={() => saveModal("crisis")}
                    color="accent"
                  />
                </div>
              </div>
            </Modal>
            <section className="formsectionNTitle ">
              <CatalogSubTitle
                title="Article Information"
                className=" text-primary flex flex-col sm:flex-row gap-2 justify-between w-full"
              >
                <NewButton
                  text="Preview"
                  color="primary"
                  content="textIcon"
                  size="large"
                  icon={<Eye />}
                  onClick={() =>
                    navigate("/Articles/Manage/Preview", {
                      state: currentContent?.content,
                    })
                  }
                />
              </CatalogSubTitle>
              <div className="formsection flex flex-col w-full">
                <div className="formsection flex w-full flex-1 items-end">
                  <div className="w-full lg:w-1/2">
                    <DDMultiple
                      title="Non-WSG Member Firms"
                      objects={nonMemberFirms}
                      selectedObjst={setSelectedObjects(
                        nonMemberFirms,
                        currentContent?.content?.nonMemberFirms ?? []
                      )}
                      seter={(firm: any) =>
                        handleDropdownChange("nonMemberFirms", firm, true)
                      }
                      error={false}
                    />
                  </div>
                  <div>
                    <NewButton
                      text={
                        viewportWidth > 1024 ? "NEW NON-MEMBER FIRM" : "NEW"
                      }
                      color="primary"
                      content={viewportWidth > 640 ? "textIcon" : "iconSquare"}
                      size="large"
                      icon={<Plus2 />}
                      onClick={handleNewNonMemberFirm}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="formsectionNTitle ">
              <div className="flex flex-col formsection w-full">
                <div className="flex formsection w-full flex-col md:flex-row">
                  <div className="flex formsection flex-col">
                    <div>
                      <span className="">Submission Type</span>
                    </div>
                    <div className="flex formsection !whitespace-nowrap flex-wrap">
                      <RadioButton
                        index={0}
                        text={submissionTypeEnum.manualInput}
                        selected={
                          currentContent?.content?.submissionType ===
                          "manualInput"
                        }
                        handleRadioButtonChange={() =>
                          handleRadioChange("submissionType", "manualInput")
                        }
                        name="submissionType"
                      />
                      <RadioButton
                        index={1}
                        text={submissionTypeEnum.pdfLink}
                        selected={
                          currentContent?.content?.submissionType === "pdfLink"
                        }
                        handleRadioButtonChange={() =>
                          handleRadioChange("submissionType", "pdfLink")
                        }
                        name="submissionType"
                      />
                      <RadioButton
                        index={2}
                        text={submissionTypeEnum.uploadDocument}
                        selected={
                          currentContent?.content?.submissionType ===
                          "uploadDocument"
                        }
                        handleRadioButtonChange={() =>
                          handleRadioChange("submissionType", "uploadDocument")
                        }
                        name="submissionType"
                      />
                    </div>
                  </div>
                  <div className="lg:w-1/4">
                    <CalendarInput
                      title="Written Date"
                      type="single"
                      errorMsj={errors.writtenDate.message}
                      error={errors.writtenDate.error}
                      value={
                        currentContent?.content?.writtenDate &&
                        currentContent?.content?.writtenDate.toString()
                      }
                      onChange={(e: any) => {
                        dispatch(
                          contentsSlice.actions.ModifyContent({
                            content: {
                              ...currentContent?.content,
                              writtenDate: e && new Date(e),
                            },
                          })
                        );
                      }}
                      required
                      tooltip="Creation Date of the Content that will be displayed."
                    />
                  </div>
                </div>
                <div className="flex flex-1 formsection w-full  flex-col sm:flex-row">
                  <div className="w-full flex flex-row items-end formsection">
                    <DDMultiple
                      title="Author (s)"
                      objects={firmMembers}
                      selectedObjst={firmMembers?.filter((f: any) =>
                        currentContent?.content?.authors?.includes(f.id)
                      )}
                      seter={(person: any) =>
                        handleDropdownChange("authors", person, true)
                      }
                      error={errors.author.error}
                      errorMessage={errors.author.message}
                      required={!isAuthoredByFirm}
                      disabled={isAuthoredByFirm}
                      tooltip="If Author is not listed. Please create their “New Professional” in WSG's System. Then, select them as Authors."
                    />
                    <div>
                      <NewButton
                        text={viewportWidth > 1024 ? "NEW PROFESSIONAL" : "NEW"}
                        color="primary"
                        content={
                          viewportWidth > 640 ? "textIcon" : "iconSquare"
                        }
                        size="large"
                        icon={<Plus2 />}
                        type="button"
                        onClick={handleNewProfessional}
                        disabled={!currentContent?.content?.memberFirms?.length}
                      />
                    </div>
                  </div>
                  <div className="self-center	min-w-[140px]">
                    <SwitchToogle
                      className="flex-col"
                      title="Authored By Firm"
                      checked={isAuthoredByFirm}
                      seter={(e) => (
                        handleDropdownChange(
                          "authors",
                          e ? ["authoredByFirm"] : "",
                          false
                        ),
                        setIsAuthoredByFirm(e)
                      )}
                      titleFirst
                      centered={viewportWidth <= 640}
                    />
                  </div>
                </div>
                <div className="flex formsection flex-col sm:flex-row">
                  <div className="w-full">
                    <DDMultiple
                      title="Relevant Jurisdiction (s)"
                      objects={jurisdictions}
                      selectedObjst={jurisdictions.filter((j: any) =>
                        currentContent?.content?.jurisdictions?.includes(j.id)
                      )}
                      seter={(jurisdiction: any) =>
                        handleDropdownChange(
                          "jurisdictions",
                          jurisdiction,
                          true
                        )
                      }
                      error={errors.jurisdiction.error}
                      errorMessage={errors.jurisdiction.message}
                      required={!isGlobalLocation}
                      disabled={isGlobalLocation}
                    />
                  </div>
                  <div className="self-center	min-w-[140px]">
                    <SwitchToogle
                      className="flex-col"
                      title="Global Location"
                      checked={isGlobalLocation}
                      seter={(e) => (
                        handleDropdownChange(
                          "jurisdictions",
                          e ? ["GlobalLocation"] : "",
                          false
                        ),
                        setIsGlobalLocation(e)
                      )}
                      titleFirst
                      centered={viewportWidth <= 640}
                    />
                  </div>
                </div>
                <div>
                  <TextArea
                    title="Title"
                    error={errors.title.error}
                    errorMsj={errors.title.message}
                    maxChars={200}
                    characterCount
                    required
                    name="title"
                    value={currentContent?.content?.title}
                    height="h-5rem"
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <RichTextEditor
                    title={
                      currentContent?.content?.submissionType === "manualInput"
                        ? "Article Content"
                        : "Abstract"
                    }
                    characterCount
                    required
                    error={errors.articleContent.error}
                    errorMsj={errors.articleContent.message}
                    name="articleContent"
                    value={currentContent?.content?.articleContent}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <RichTextEditor
                    title="Footnotes"
                    maxChars={6000}
                    characterCount
                    error={errors.footnotes.error}
                    errorMsj={errors.footnotes.message}
                    required
                    name="footnotes"
                    value={currentContent?.content?.footnotes}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <Input
                    title={
                      currentContent?.content?.submissionType === "pdfLink"
                        ? "Link to PDF"
                        : "Link to Additional Information"
                    }
                    error={false}
                    value={currentContent?.content?.link}
                    onChange={(e) => {
                      dispatch(
                        contentsSlice.actions.ModifyContent({
                          content: {
                            ...currentContent?.content,
                            link: e.target.value,
                          },
                        })
                      );
                    }}
                  />
                </div>
                <div className="flex w-full formsection items-end">
                  <div className=" w-full">
                    <DDMultiple
                      title="Tags"
                      objects={tags}
                      selectedObjst={setSelectedObjects(
                        tags,
                        currentContent?.content?.tags ?? []
                      )}
                      seter={(tag: any) =>
                        handleDropdownChange("tags", tag, true)
                      }
                      error={false}
                      errorMessage=""
                      tooltip="Add all tags needed for SEO purposes."
                    />
                  </div>
                  <div>
                    <NewButton
                      text={viewportWidth > 1024 ? "NEW TAG" : "NEW"}
                      color="primary"
                      content={viewportWidth > 640 ? "textIcon" : "iconSquare"}
                      type="button"
                      size="large"
                      icon={<Plus2 />}
                      onClick={() => setTagModalOpen(!tagModalOpen)}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="formsectionNTitle ">
              <CatalogSubTitle
                title="Article Subject Matter"
                className=" text-primary"
              />
              <span className="text-accentDefault">
                Please select 1 Subject Matter. At least one “General Topic”,
                “Global Crisis” or “Practice Industry”
              </span>
              <div className="w-full">
                <span>General Topics</span>
                <div className="flex flex-1 justify-between w-full formsection items-end">
                  <div className="flex formsection flex-row flex-wrap">
                    {generalTopics.length > 0 &&
                      generalTopics.map((topic, index) => {
                        return (
                          <WSGCheckbox
                            index={index}
                            text={topic.name}
                            checked={checkedGeneralTopics.includes(topic.name)}
                            handleCheckboxChange={() =>
                              handleCheckboxChange("generalTopics", topic.name)
                            }
                          />
                        );
                      })}
                  </div>
                  <div>
                    <NewButton
                      text={viewportWidth > 1024 ? "NEW TOPIC" : "NEW"}
                      color="primary"
                      content={viewportWidth > 640 ? "textIcon" : "iconSquare"}
                      type="button"
                      size="large"
                      icon={<Plus2 />}
                      onClick={() => setTopicModalOpen(!topicModalOpen)}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <span>Global Crisis Hub</span>
                <div className="flex flex-1 justify-between w-full formsection items-end">
                  <div className="flex formsection flex-row flex-wrap">
                    {globalCrisis.length > 0 &&
                      globalCrisis.map((crisis, index) => {
                        return (
                          <WSGCheckbox
                            index={index}
                            text={crisis.name}
                            checked={checkedGlobalCrisis.includes(crisis.name)}
                            handleCheckboxChange={() =>
                              handleCheckboxChange(
                                "globalCrisisHub",
                                crisis.name
                              )
                            }
                          />
                        );
                      })}
                  </div>
                  <div>
                    <NewButton
                      text={viewportWidth > 1024 ? "NEW CRISIS" : "NEW"}
                      color="primary"
                      content={viewportWidth > 640 ? "textIcon" : "iconSquare"}
                      type="button"
                      size="large"
                      icon={<Plus2 />}
                      onClick={() => setCrisisModalOpen(!crisisModalOpen)}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-row gap-2.5 w-full">
                  <span>Practice Industries Categories:</span>{" "}
                  <NewTooltip
                    text={
                      "Please select up to 3 Practice Industries relevant to the currentContent?.content?."
                    }
                  />
                </div>

                <div className="flex flex-row w-full flex-wrap">
                  {practiceIndustries.map((pIndustries, index) => {
                    return (
                      <WSGCheckbox
                        index={index}
                        text={pIndustries.name}
                        checked={checkedPracticeIndustries.includes(
                          pIndustries.name
                        )}
                        handleCheckboxChange={() =>
                          handleCheckboxChange(
                            "practiceIndustries",
                            pIndustries.name
                          )
                        }
                        icon={
                          <SvgIcon
                            practiceId={pIndustries.id || ""}
                            iconUrl={pIndustries?.icon}
                            practiceName={pIndustries.name}
                            isSelected={checkedPracticeIndustries.includes(
                              pIndustries.name
                            )}
                          />
                        }
                        className="w-full md:w-1/2 lg:w-1/3"
                      />
                    );
                  })}
                </div>
              </div>
            </section>
            <ButtonsCrud
              Catalog="ContentGeneralTopic"
              mode={props.mode}
              id={currentContent?.content?.id ?? ""}
              disabled={canSave}
              actionButton={handleButtonClick}
              onDelete={handleDelete}
              member={true}
              state={state}
              newState={currentContent?.content}
            />
          </article>
        </form>
      </div>
    </>
  );
};

export default CreatePageArticles;
