// context/LoggingContext.tsx
import React, { createContext, useContext, ReactNode, useRef, useCallback } from 'react';
import AuthContext from '../store/AuthContext';
import Axios from '../utils/axios';
import { IActivityLog } from '../interfaces/models/IActivityLogs';
import { getCurrentTimestamp } from '../utils/functions';
import { getFirmById } from '../Services/Firm';
const axios = new Axios();

interface LoggingContextProps {
  logActivity: (action: string, page: string, resourceUpdated?: string, firmId?: string) => void;
  deleteActivityLog: (id: string) => void;
}

const LoggingContext = createContext<LoggingContextProps | undefined>(undefined);

interface LoggingProviderProps {
  children: ReactNode;
}

const excludedRoutes = ['/Staff/activityLog'];

export const LoggingProvider: React.FC<LoggingProviderProps> = ({ children }) => {
  const { tokenDecoded, loggedPerson } = useContext(AuthContext);
  const lastLogTime = useRef<number | null>(null);
  const delay = 5000;

  const formatDetails = (details: any): string => {
    return JSON.stringify(details, null, 2);
  };

  const logActivity = useCallback(async (action: string, page: string, resourceUpdated?: string, firmId?: string): Promise<void> => {
    const normalizedPage = page.toLowerCase();
    const isExcluded = excludedRoutes.some(route => route.toLowerCase() === normalizedPage);

    if (isExcluded) {
      return;
    }
    
    const personName = loggedPerson
      ? [
          loggedPerson.honorary,
          loggedPerson.name,
          loggedPerson.middleInitial,
          loggedPerson.lastName,
          loggedPerson.suffix,
        ]
          .filter((part) => part)
          .join(" ")
      : "";

    const email = loggedPerson?.firmEmail ?? "";
    const memberType = loggedPerson?.memberType;

    let firmName = '';
    if (firmId) {
      firmName = (await getFirmById(firmId ?? ''))?.firmName ?? "";
    }
    const accessType = tokenDecoded?.extension_WSG_Permissions ?? ""

    const log: IActivityLog = {
      userId: loggedPerson?.userId ?? '',
      personName,
      email,
      memberType,
      firmId,
      firmName,
      action,
      accessType,
      page,
      resourceUpdated: resourceUpdated ? formatDetails(resourceUpdated) : undefined,
      _ts: getCurrentTimestamp(),
    };

    const now = Date.now();
    if (lastLogTime.current && now - lastLogTime.current < delay) {
      return;
    }
    lastLogTime.current = now;

    try {
      await axios.Post('/activityLog', log);
    } catch (error) {
      console.error('Error logging activity:', error);
    }
  }, [tokenDecoded]);

  const deleteActivityLog = async (id: string) => {
    try {
      await axios.Delete('/activityLog', id);
    } catch (error) {
      console.error('Error deleting activity log:', error);
    }
  };


  return (
    <LoggingContext.Provider value={{ logActivity, deleteActivityLog }}>
      {children}
    </LoggingContext.Provider>
  );
};

export const useLogging = (): LoggingContextProps => {
  const context = useContext(LoggingContext);
  if (!context) {
    throw new Error('useLogging must be used within a LoggingProvider');
  }
  return context;
};
