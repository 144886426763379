import { axios } from "..";
import { IPracticeIndustry } from "../interfaces/models/IPracticeIndustry";

export const getAllPracticeIndustries = async () => {
  try {
    const response = await axios.Get('/practiceIndustry')
    if(response && response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const addPracticeIndustry = async (person: IPracticeIndustry): Promise<void> => {
  try {
    const response = await axios.Post('practiceIndustry', person);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePracticeIndustry = async (person: IPracticeIndustry): Promise<void> => {
  try {
    const response = await axios.Put('practiceIndustry', person);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePracticeIndustry = async (personId: string) => {
  try {
    await axios.Delete('practiceIndustry', personId);
    return true;
  } catch (error) {
    throw error;
  }
};
