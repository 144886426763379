import React, { useEffect, useState } from 'react';
import useDataLoader2 from '../../hooks/useDataLoader2';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import DataAdminFilter from '../../components/Catalogs/Filters/DataAdminFilter';
import { UseFirmList } from '../../hooks/Catalogs/useFirm';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import { formatDateToString } from '../../utils/functions';
import Axios from '../../utils/axios';
import { defaultPageSize } from '../../utils/const';

const DataAdmin: React.FC = () => {
  const Headers: string[] = ['firm', 'person', 'dataAdminType', 'membershipLocation', 'createdAt'];
  const { firmData } = UseFirmList();
  const { generateColumns } = useColumnConfig(Headers);
  const [tableInstance, setTableInstance] = useState<any>();
  const [dataAdminFilters, setDataAdminFilters] = useState<any>();
  const [dataAdmin, setDataAdmin] = useState<any>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/dataAdmin',
    defaultPageSize,
  });
  const axios = new Axios()


  const columns = generateColumns([
    { id: 'count', header: '#', cell: (props: any) => <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>},
    { id: 'firmName', header: 'Firm', cell: (props: any) => props.getValue() },
    { id: 'personName', header: 'Person', cell: (props: any) => props.getValue() },
    { id: 'dataAdminType', header: 'Data Admin Type', cell: (props: any) => <p>{props.getValue()}</p> },
    { id: 'membershipLocationName', header: 'MembershipLocation', cell: (props: any) => props.getValue() },
    {
      id: 'createdAt', header: 'Created Date', cell: (props: any) => {
        const value = props.getValue();
        return (
          <p>
            {value && formatDateToString(new Date(value)) }
          </p>
        );
      }
    },
    {
      id: 'edit', header: 'Edit', cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={data[props.row.id]}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ), enableSorting: false
    },
  ]);

  const clearSelections = () => {
    setDataAdminFilters({ person: "", firms: [] });
    fetchData(true)
  }

  const formatData = (data: any) => {
    const formatedData = data.map((d: any) => {
      return {
        ...d,
        dataAdminType: d.type === 0 ? 'Primary' : d.type === 1 ? 'Dedicated' : ''
      }
    })
    return formatedData
  }

  useEffect(() => {
    if (!data.length) return
    if (data) setDataAdmin(formatData(data))
  },[data]);

  useEffect(() => {
    if (!firmData) return
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[firmData]);

  useEffect(() => {
    let filterAux = {
      person: {
        value: dataAdminFilters?.person,
      },
      firms: {
        value: dataAdminFilters?.firms
      },
    }
    setFilter(filterAux)
  }, [dataAdminFilters]);


  return (
    <>
      <main className=' mainSection'>
        <CatalogTableC
          title={`DataAdmin Catalog`} catalogName={'Data Admin'} route={'DataAdmin'}
          isfiltered={true}
          data={data} headers={Headers}
          totalCount={totalCount}
          personFromDto={true}
        >
          <DataAdminFilter
            key={0}
            Search={() => fetchData()}
            filters={dataAdminFilters}
            setFilters={setDataAdminFilters}
            firmData={firmData}
            headers={Headers}
            clearSelections={clearSelections}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={dataAdmin}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </main>
    </>

  );
};

export default DataAdmin;
