import { CSSProperties } from "react";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { Header } from "@tanstack/react-table";
import CustomShuffle from "../../Atomos/Icons/CustomShuffle";
import SortAsending from "../../Atomos/Icons/SortAsending";
import SortDecending from "../../Atomos/Icons/SortDecending";
import SortDefault from "../../Atomos/Icons/SortDefault";
import { ISorting } from "../../../interfaces/Components/ITable";

const DraggableTableHeader = ({
  header,
  sorting,
  setSorting
}: {
  header: Header<unknown, unknown>;
  sorting?: ISorting;
  setSorting?: React.Dispatch<React.SetStateAction<ISorting>>;
}) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
    });

  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform),
    transition: "width transform 0.2s ease-in-out",
    whiteSpace: "nowrap",
    width: header.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  };

  const toogleSortIcon = (field: string) => {
    const prevsorting = sorting;
    if (setSorting && sorting)
      if (prevsorting && prevsorting.field === field) {
        if (prevsorting.order === "desc") {
          setSorting({ field, order: "asc" });
        } else if (prevsorting.order === "asc") {
          setSorting({ field, order: "default" });
        } else {
          setSorting({ field, order: "desc" });
        }
      }
      else {
        setSorting({ field, order: "desc" });
      }
  }

  return (
    <th
      className="th"
      colSpan={header.colSpan}
      ref={setNodeRef}
      key={header.id}
      style={style}
    >
      <div className="flex">
        <button {...attributes} {...listeners} className="min-w-[1rem] fill-white flex items-center justify-end cursor-grab mr-2">
          <CustomShuffle />
        </button>
        <span className="flex h-4 fill-white cursor-pointer" onClick={() => toogleSortIcon(String(header.column.id))}>
        <label>{String(header.column.columnDef.header)}</label>
        <div
          onMouseDown={header.getResizeHandler()}
          onTouchStart={header.getResizeHandler()}
          className={`resizer ${header.column.getIsResizing() ? "isResizing" : ""} w-3`}
        />
          {sorting && sorting.field === String(header.column.id) && sorting.order === "asc" && <SortAsending />}
          {sorting && sorting.field === String(header.column.id) && sorting.order === "desc" && <SortDecending />}
          {(sorting != undefined && (!sorting || sorting.field !== String(header.column.id))) && <SortDefault />}
          {sorting && sorting.field === String(header.column.id) && sorting.order === "default" && <SortDefault />}
        </span>
      </div>
    </th>
  );
};

export default DraggableTableHeader;
