import React, { useEffect, useState } from 'react';
import useDataLoader2 from '../../hooks/useDataLoader2';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import AOPRankingFilter from '../../components/Catalogs/Filters/AOPRankingFilter';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import { ColumnConfig } from '../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport';
import { UseFirmList } from '../../hooks/Catalogs/useFirm';
import { IFilters } from '../../interfaces/Components/ITable';
import { defaultPageSize } from '../../utils/const';

const AOPRanking: React.FC = ({ ...props }) => {
  const Headers: string[] = ['person', 'firm', 'rank_1', 'rank_2', 'rank_3', 'rank_4'];
  const [tableInstance, setTableInstance] = useState<any>();
  const [nameFilter, setNameFilter] = useState('');
  const [selectedFirm, setSelectedFirm] = useState<any[]>([]);
  const { firmData } = UseFirmList();
  const { generateColumns } = useColumnConfig(Headers);

  const [aopData, setAopData] = useState<any>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'firm', 
    'person', 
    'ranks', 
    'firmName', 
    'personName', 
    'rank1Name',
    'rank2Name',
    'rank3Name',
    'rank4Name',
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/AOPRanking',
    defaultPageSize,
    fields
  });

  const clearSelections = () => {
    setSelectedFirm([])
    setNameFilter('')
    fetchData(true)
  };

  const handleNameFilterChange = (newNameFilter: string) => {
    setNameFilter(newNameFilter);
  };

  const columnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false, size: 50 },
    { id: 'person', header: 'Person' },
    { id: 'firm', header: 'Firm' },
    { id: 'rank_1', header: 'Rank 1' },
    { id: 'rank_2', header: 'Rank 2' },
    { id: 'rank_3', header: 'Rank 3' },
    { id: 'rank_4', header: 'Rank 4' },
    {
      id: 'edit',
      header: 'Edit',
      enableSorting: false,
      cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={data[props.row.id]}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ),
    },
  ];

  const columns = generateColumns(columnConfigs.map(column => ({
    ...column,
    cell: column.cell || ((props: any) => {
      if (column.id === 'count') {
        return <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
      } else if (column.id.startsWith('rank_')) {
        const rankIndex = parseInt(column.id.split('_')[1], 10) - 1;
        const ranks = props.row.original.ranks;
        return ranks && ranks[rankIndex] ? ranks[rankIndex].name : '';
      } else if (column.id === 'firm') {
        return props.row.original.firmName;
      } else if (column.id === 'person') {
        return props.row.original.personName;
      }
      return '';
    })
  })));

  useEffect(() => {
    if (data) setAopData(data)
  },[data]);

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  useEffect(() => {
    let filterAux:{[key: string]: IFilters} = {
      personName: {
        value: nameFilter, //TODO Modify how person data is saving
        comparisonType: 'CONTAINS'
      },
      firm: {
        value: selectedFirm?.map(c => c.id),
        comparisonType: 'EQUAL'
      }
    }
    setFilter(filterAux)
  },[selectedFirm, nameFilter]);

  return (
    <>
      <main className='mainSection'>
        <CatalogTableC
          title={`AOP Ranking Catalog`}
          catalogName={'RANKINGS'}
          route={'AOPRanking'}
          isfiltered={false}
          data={data}
          headers={Headers}
          totalCount={totalCount}
          noNewCatalog
          exportData={[]}
        >
          <AOPRankingFilter
            onNameFilterChange={handleNameFilterChange}
            nameFilter={nameFilter}
            headers={Headers}
            resetFilters={clearSelections}
            search={() => fetchData()}
            firmData={firmData}
            selectedFirm={selectedFirm}
            setSelectedFirm={setSelectedFirm}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={aopData}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </main>
    </>
  );
};

export default AOPRanking;
