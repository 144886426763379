import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../utils/axios";
import '../../../assets/css/AddCatalogs.css';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../../components/Atomos/Inputs/Input";
import RadioButton from "../../../components/Atomos/Radio/RadioButton";
import DropDown from "../../../components/Atomos/DropDown/Dropdown";
import ButtonsCrudWithDropdown from "../../../components/Catalogs/ButtonsCrudWithDropdown";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import { UseFirmList } from "../../../hooks/Catalogs/useFirm";
import { fetchPersonByFirm } from "../../../Services/Person";
import { IPerson } from "../../../interfaces";
import { getUsers } from "../../../Services/User";
import { generateRandomPassword } from "../../../utils/functions";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import { sendPassword } from "../../../Services/Password";

interface DataItem {
  id: string;
  email: string;
  user: string;
  firm?: string;
  person?: string;
}

interface iCatalogProps {
  mode: "add" | "edit";
}

const initialActiveErrors = {
  inputFirm: false,
  inputPerson: false,
  inputEmail: false,
  inputAccess: false,
  inputUserName: false,
};

const ActiveErrorsMessage = {
  inputFirm: "Firm is required.",
  inputPerson: "Person is required.",
  inputEmail: "Email is required.",
  inputAccess: "Access is required.",
  inputUserName: "UserName is required.",
};

const AddUser: React.FC<iCatalogProps> = (props) => {
  const axios = new Axios();
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  const [activeErrors, setActiveErrors] = useState(initialActiveErrors);
  const [activeErrorsMessage, setActiveErrorsMessage] = useState(ActiveErrorsMessage);

  const [error, setError] = useState(false);
  const [user, setUser] = useState<DataItem>({
    id: state?.user?.id ?? "",
    email: state?.user?.email ?? "",
    user: state?.person?.userName ?? "",
  });

  const [selectedFirm, setSelectedFirm] = useState<{ id?: string, name?: string }>({
    id: state && state?.firm ? state?.firm?.id : "",
    name: state && state?.firm?.name ? state?.firm?.name : ""
  });

  const [selectedPerson, setSelectedPerson] = useState<{ id?: string, name?: string }>({
    id: state?.person?.id ?? "",
    name: state?.person?.name ?? "",
  });
  const [checked, setChecked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState("");
  const [selectedOption, setSelectedOption] = useState(0);
  const [personType, setPersonType] = useState(state?.userType ?? undefined);
  const [selectedAccess, setSelectedAccess] = useState<string | undefined>(
    state?.user?.access ?? ""
  );
  const [userData, setUserData] = useState([]);

  const [personData, setPersonData] = useState<IPerson[]>([]);
  const [personCreate, setPersonCreate] = useState<any[]>([]);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { firmData } = UseFirmList()

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const checkEditType = (index: number) => {
    const wsgFirm = firmData.find((firm: any) => firm.name === "World Services Group");
    setSelectedOption(index);
    if (index === 0) {
      setSelectedFirm({ id: "", name: "" })
      setSelectedPerson({})
      setUser({ id: "", email: "", user: "" })
    } else {
      setSelectedFirm({ id: wsgFirm?.id, name: wsgFirm?.name });
    }

  };

  function transformPersonData(newPersons: any) {
    return newPersons.map((person: any) => {
      return {
        id: person.id,
        name: person ? (
          [
            person.name,
            person.lastName,
          ]
            .filter(part => part) // Remove empty parts
            .join(" ")
        ) : '',
        generatedId: person ? person.generatedId : '',
        firm: person ? person.firm : '',
        email: person ? person.firmEmail : '',
        userId: person ? person.userId : "",
        lastName: person ? person.lastName : "",
      };
    });
  }

  useEffect(() => {
    getUsers().then((response) => {
      setUserData(response)
    })
  }, []);


  useEffect(() => {
    const loadData = async () => {
      if (personType === 2 || personType === 3) {
        setSelectedOption(1);
        const wsgFirm = firmData.find((firm: any) => firm.name === "World Services Group");
        setSelectedFirm({ id: wsgFirm?.id, name: wsgFirm?.name });
        setSelectedAccess(personType === 3 ? "WSG Staff Super" : "WSG Staff");
      }
      if (selectedPerson.id !== "") {
        try {
          const person = personData.find((person) => person.id === selectedPerson.id);
          const createdUsername = await getUserByPerson(person);

          setUser((prevUser) => ({
            ...prevUser,
            user: createdUsername ? createdUsername : "",
          }));

        } catch (error) {
          console.error("Error creating user:", error);
        }
      }
    };

    if (props.mode !== "edit") {
      loadData();
    }
  }, [personType, selectedPerson, firmData, props.mode]);


  useEffect(() => {
    if (!selectedFirm.id) return
      fetchPersonData();
  }, [selectedFirm]);

  const fetchPersonData = async () => {
    try {
      const data = await fetchPersonByFirm(selectedFirm.id);
        const filteredPersonData = data.filter((person: any) => 
          !person.userId && person.firmEmail
      );  
        const transformed = transformPersonData(filteredPersonData)
        setPersonData(transformed)
        if (state.user.person) {
          const person = data.find((person: IPerson) => person.id === state.user.person);
          setSelectedPerson(person)
        }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (state?.firm && firmData.length > 0 && !selectedFirm.id) {
      const stateFirm = firmData.find((obj: any) => obj.id === state.firm);
      setSelectedFirm({ id: stateFirm?.id, name: stateFirm?.name });
    }

    if (state?.person && personData.length > 0 && !selectedPerson.id) {
      const statePerson = personData.find((obj: any) => obj.id === state.person);
      setSelectedPerson({ id: statePerson?.id, name: statePerson?.name });
    }

    if (state?.access && selectedAccess === undefined) {
      setSelectedAccess(state.access);
    }
  }, [state, personData, firmData]);


  useEffect(() => {
    if (checked) {
      validateForm();
    }

  }, [
    user,
    selectedAccess,
    selectedFirm,
    selectedPerson,
  ]);

  const validateForm = () => {

    const firmInput = selectedFirm.name === "" || selectedFirm.name === undefined;
    const personInput = selectedPerson.name === "" || selectedPerson.name === undefined;
    const accessInput = selectedAccess === "" || selectedAccess === undefined;
    const emailInput = user.email === "";
    const userInput = user.user === "";

    setActiveErrors((prevState) => ({
      ...prevState,
      inputFirm: firmInput,
      inputPerson: personInput,
      inputAccess: accessInput,
      inputEmail: emailInput,
      inputUserName: userInput,
    }));
    setActiveErrorsMessage((prevState) => ({
      ...prevState,
      inputUserName: "Username is required."
    }));
  };


  const handleDelete = async (id: string) => {
    const response = await axios.Delete(`/User`, id);
    if (response.status === 200) {
      navigate("/Staff/Catalogs/User");
    } else {
      console.error("Error al eliminar el elemento:", response.data);
    }
  };



  const handleDropDownChangeFirm = (obj: any) => {
    setSelectedFirm({ id: obj.id, name: obj.name });
    setSelectedPerson({ id: "", name: "" })
    setUser((prevUser) => ({
      ...prevUser,
      email: "",
      user: ""
    }))
    

    setActiveErrors((prevState) => ({
      ...prevState,
      inputFirm: false,
    }));
  };

  const getUserByPerson = (person: any) => {
    const probableUsername = person ? person.name.slice(0, 1) + person.lastName : ""
    const filterUser = userData.find((newUser: any) => newUser.mailNickname === probableUsername)
    if (filterUser) {
      return probableUsername + person.generatedId
    } else {
      return probableUsername
    }
  };


  const handleDropDownChangePerson = async (obj: any) => {
    setSelectedPerson({ id: obj.id, name: obj.name });
    const person = personData.find((person) => person.id === obj.id);
    const createdUsername = getUserByPerson(person);

    setUser((prevUser) => ({
      ...prevUser,
      user: createdUsername ?? "",
      email: obj.email ?? ""
    }))
    setActiveErrors((prevState) => ({
      ...prevState,
      inputPerson: false,
    }));

  };

  const handleDropDownChangeAccess = (name: string) => {
    setSelectedAccess(name);
    setActiveErrors((prevState) => ({
      ...prevState,
      inputAccess: false,
    }));
  };

  const handleButtonClick = (buttonName: string) => {
    console.log(buttonName)
    setButtonClicked(buttonName);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (buttonClicked === 'updateButton') {
      handleUpdate(e);
    } else if (buttonClicked === 'saveButton') {
      handleSave(e);
    }
  };


  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {


      setChecked(true);

      const firmInput = selectedFirm.name === "" || selectedFirm.name === undefined;
      const personInput = selectedPerson.name === "" || selectedPerson.name === undefined;
      const accessInput = selectedAccess === "" || selectedAccess === undefined;
      const emailInput = user.email === "";
      const userInput = user.user === "";


      const newActiveErrors = {
        inputFirm: firmInput,
        inputPerson: personInput,
        inputAccess: accessInput,
        inputEmail: emailInput,
        inputUserName: userInput,
      };

      setActiveErrors(newActiveErrors);

      if (
        newActiveErrors.inputFirm ||
        newActiveErrors.inputPerson ||
        newActiveErrors.inputEmail ||
        newActiveErrors.inputAccess ||
        newActiveErrors.inputUserName
      )
        return;


      const person = personData.find((person: any) => person.id === selectedPerson.id);
      const responseUserData = await axios.Get("/User")
      const usersDataCreate = responseUserData.data;
      const filterUser = usersDataCreate.find((newUser: any) => newUser.mailNickname === user.user)

      if (filterUser) {
        setActiveErrorsMessage((prevState) => ({
          ...prevState,
          inputUserName: "This value is already registered in the DB. Please input a different/new one.",
        }));
        setActiveErrors((prevState) => ({
          ...prevState,
          inputUserName: true,
        }));
        return
      }
    
      const password = generateRandomPassword()
      const response = await axios.Post("/User", {
        access: selectedAccess,
        nickName: user.user,
        password,
        fullName: person?.name,
        email: user.email
      });

      if (response.data.success) {
        if (response.data.error) {
        } else {
          const newUsers = await axios.Get("/User")
          const newUsersData = newUsers.data
          const newUser = newUsersData.find((newUser: any) => newUser.mailNickname === user.user)
          const updatedPerson = { ...person, userId: newUser ? newUser.id : "" }
          const personResponse = await axios.Put(`/person?id=${selectedPerson.id}`, updatedPerson)
          await sendPassword(password, user.user, user.email)
          if (state?.isContentRedirect) {
            const newState = {
              ...state.content,
              authors: [personResponse.data.id]
            }
            navigate("/Staff/Catalogs/Content/Create", { state: newState });
          } else {
            navigate("/Staff/Catalogs/User");
          }
        }
      } else {
        setActiveErrorsMessage((prevState) => ({
          ...prevState,
          inputEmail: response.data.error === "Another object with the same value for property userPrincipalName already exists." ? "Another User Already is using this email." : "",
        }));
        setActiveErrors((prevState) => ({
          ...prevState,
          inputEmail: true,
        }));
      }
    } catch (error: any) {
      console.error("Error al crear el elemento:", error);
    }
  };

  const buildUserObject = () => {
    return {
      id: state?.person?.userId,
      mailNickname: user.user.trim(),
      displayName: state?.user.subject.displayName,
      email: user.email.trim(),
      userPrincipalName: state?.user.subject.userPrincipalName,
      access: selectedAccess ?? state?.user.access,
      identities: state?.user.subject.identities ? state.user.subject.identities.map((identity: any) => {
        switch (identity.signInType) {
          case "userName":
            return { ...identity, issuerAssignedId: user.user };
          case "emailAddress":
            return { ...identity, issuerAssignedId: user.email };
          case "userPrincipalName":
            return { ...identity, issuerAssignedId: user.user };
          default:
            return identity;
        }
      }) : [],
    }
  }
  
  const prevUserObject = () => {
    if (!state?.user) return undefined;
    return {
      id: state?.person?.userId,
      mailNickname: state?.user.userName,
      displayName: state?.user.subject.displayName,
      email: state?.user.email,
      userPrincipalName: state?.user.subject.userPrincipalName,
      access: state?.user.access,
      identities: state?.user.subject.identities ? state.user.subject.identities.map((identity: any) => {
        switch (identity.signInType) {
          case "userName":
            return { ...identity, issuerAssignedId: user.user };
          case "emailAddress":
            return { ...identity, issuerAssignedId: user.email };
          case "userPrincipalName":
            return { ...identity, issuerAssignedId: user.user };
          default:
            return identity;
        }
      }) : [],
    }
  }

  const handleUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setChecked(true);

      // Validaciones para campos requeridos
      if (!selectedFirm.name) {
        setActiveErrors((prevState) => ({ ...prevState, inputFirm: true }));
        return;
      }

      if (!selectedPerson.name) {
        setActiveErrors((prevState) => ({ ...prevState, inputPerson: true }));
        return;
      }

      if (!selectedAccess) {
        setActiveErrors((prevState) => ({ ...prevState, inputAccess: true }));
        return;
      }

      if (!user.email) {
        setActiveErrors((prevState) => ({ ...prevState, inputEmail: true }));
        return;
      }

      if (!user.user) {
        setActiveErrors((prevState) => ({ ...prevState, inputUserName: true }));
        return;
      }

      if (!state?.user) {
        console.error("Invalid user data in state");
        return;
      }


      const cleanedUserForUpdate = buildUserObject();

      const response = await axios.Put(`/User`, cleanedUserForUpdate);

      if (response.data.success === true && response.status === 200) {
        setTimeout(() => {
          navigate("/Staff/Catalogs/User");
        }, 500);
      } else {
        console.error("Error al actualizar el elemento:", response.data);
        const errorMessage = response.data.message || "An unexpected error occurred.";
        setErrorMessage(errorMessage);
        return
      }
    } catch (error: any) {
      console.error("Error al actualizar el elemento:", error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "An error occurred while updating the user.";

      setErrorMessage(errorMessage);
      return
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (props.mode === "edit" && state) {
      const wsgFirm = firmData.find((firm: any) => firm.name === "World Services Group");
      if (state.user.access === 'WSG Staff' || state.user.access === 'WSG Staff Super') {
        setSelectedOption(1);
        setSelectedFirm({ id: wsgFirm?.id, name: wsgFirm?.name });
        setSelectedPerson({ id: state?.person?.id, name: state?.person?.name ?? '' });
      } else {
        setSelectedOption(0);
      }
      setUser({
        id: state?.user?.id ?? "",
        email: state?.user?.email ?? "",
        user: state?.user?.userName ?? "",
      });
    }
  }, [props.mode, personType, state, firmData]);


  return (
    <>
      <main className=" mainSection">
        <form onSubmit={handleSubmit}>
          <CatalogTitle title={`${props.mode === "add" ? "New User" : "Edit User"}`} className='text-Default'>
            {state?.userType === 0 || state?.userType === 2 ? (
              <div>
              </div>
            ) : (
              <GoBack />
            )}
          </CatalogTitle>

          <article className="articleSection">
            <section className="formsectionNTitle">
              <CatalogSubTitle title="User Type" className=" text-accent-Default-dark" />
              <div className="formsection flex items-start w-full flex-col md:flex-row !gap-2.5 ">
                <h4 className="text-gray-font ">
                  Select User Type:
                </h4>
                <div className="flex flex-1 gap-5">
                  <RadioButton
                    index={0}
                    text={"Firm User"}
                    selected={selectedOption === 0}
                    handleRadioButtonChange={checkEditType}
                    disabled={state?.userType === 0 || state?.userType === 2 ? true : false}
                  />
                  <RadioButton
                    index={1}
                    text={"WSG Staff User"}
                    selected={selectedOption === 1}
                    handleRadioButtonChange={checkEditType}
                    disabled={state?.userType === 0 || state?.userType === 2 ? true : false}
                  />
                </div>
              </div>
            </section>
            <section className="formsectionNTitle">
              <CatalogSubTitle title="User Information" className=" text-accent-Default-dark" />
              <div className=" formsection grid grid-cols-12 w-full">
                <div className=" col-span-12 md:col-span-6">
                  <DropDown
                    title={"Firm"}
                    required={true}
                    disabled={selectedOption === 1 || props.mode === "edit"}
                    selectedObjst={selectedFirm.name}
                    seter={handleDropDownChangeFirm}
                    data={firmData}
                    error={activeErrors.inputFirm}
                    errormsj={activeErrorsMessage.inputFirm ?? ""}
                    getid={true}
                  />
                </div>
                <div className=" col-span-12 md:col-span-6">
                  <DropDown
                    title={"Person"}
                    required
                    disabled={selectedFirm.name === "" || selectedFirm.name === undefined || props.mode === "edit"}
                    selectedObjst={selectedPerson?.name}
                    seter={handleDropDownChangePerson}
                    data={personData}
                    error={activeErrors.inputPerson}
                    errormsj={activeErrorsMessage.inputPerson ?? ""}
                    getid={true}
                    tooltip={selectedOption === 0 ? "Please select Firm" : undefined}
                  />
                </div>
                <div className="grid col-span-12 md:col-span-6 lg:col-span-4 ">
                  <Input
                    name="email"
                    value={user.email}
                    required={true}
                    title="Email"
                    error={activeErrors.inputEmail}
                    errorMsj={activeErrorsMessage.inputEmail ?? ""}
                    onChange={handleInputChange}
                    tooltip="Email is set in Person Catalog."
                    disabled
                  />
                  {errorMessage && (
                    <div className="text-xs text-feedback-error text-justify font-medium">
                      <p>{errorMessage}</p>
                    </div>
                  )}
                </div>
                <div className="grid col-span-12 md:col-span-6 lg:col-span-4">
                  <DropDown
                    title={"Access Type"}
                    required={true}
                    selectedObjst={selectedAccess}
                    seter={handleDropDownChangeAccess}
                    data={selectedOption === 0
                      ? [{ id: "member", name: "Member" }, { id: "dataAdmin", name: "Data Admin + Member" }]
                      : [{ id: "wsgStaff", name: "WSG Staff" }, { id: 'wsgSuper', name: "WSG Staff Super" }]}
                    error={activeErrors.inputAccess}
                    errormsj={activeErrorsMessage.inputAccess ?? ""}
                  // disabled={props.mode === "edit" && state?.user?.access === 'WSG Staff'}
                  />


                </div>
                <div className="grid col-span-12 md:col-span-6 lg:col-span-4">
                  <Input
                    name="user"
                    value={user.user}
                    required={true}
                    title="Username"
                    error={activeErrors.inputUserName}
                    errorMsj={activeErrorsMessage.inputUserName ?? ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </section>
            <ButtonsCrud
              Catalog="User"
              mode={props.mode}
              id={id ? id : ""}
              disabled={error}
              actionButton={handleButtonClick}
              onDelete={() => handleDelete(state.user.userId)}
              redirectUrl={state?.userType === 0 || state?.userType === 2 ? "/Staff/Catalogs/Person/" : undefined}
              state={prevUserObject()}
              newState={buildUserObject()}
              customFieldNames={{
                "mailNickname": "Username",
                "access": "Access Type",
              }}
            />
          </article>
        </form>
      </main>
    </>
  );
};

export default AddUser;
