import { useLogging } from "../../Context/LoggingContext";
import { ICity, IContent, IContentGeneralTopic, ICountry, IJurisdiction, IJurisdictionGroup, IPerson, IRegion, IState } from "../../interfaces";
import { IAddress } from "../../interfaces/models/IAddress";
import { IAreaOfPractice } from "../../interfaces/models/IAreaOfPractice";
import IContentTag from "../../interfaces/models/IContentTag";
import IDataAdmin from "../../interfaces/models/IDataAdmin";
import IFirm from "../../interfaces/models/IFirm";
import IFirmContact from "../../interfaces/models/IFirmContact";
import IFirmNote from "../../interfaces/models/IFirmNote";
import IGlobalCrisis from "../../interfaces/models/IGlobalCrisis";
import IOffice from "../../interfaces/models/IOffice";
import IOfficeContact from "../../interfaces/models/IOfficeContact";
import { IRepresentativeClient } from "../../interfaces/models/IRepresentativeClient";
import { IPracticeIndustry } from "../../interfaces/models/IPracticeIndustry";
import { updateAddress } from "../../Services/Address";
import { updateAop } from "../../Services/AreaOfPractice";
import { updateCity } from "../../Services/City";
import { updateContent } from "../../Services/Content";
import { updateContentGeneralTopics } from "../../Services/ContentGeneralTopic";
import { updateContentTag } from "../../Services/ContentTag";
import { updateCountry } from "../../Services/Country";
import { updateDataAdmin } from "../../Services/DataAdmin";
import { updateFirm } from "../../Services/Firm";
import { updateFirmContact } from "../../Services/FirmContact";
import { updateFirmNote } from "../../Services/FirmNote";
import { updateGlobalCrisis } from "../../Services/GlobalCrisis";
import { updateJurisdiction } from "../../Services/Jurisdiction";
import { updateJurisdictionGroup } from "../../Services/JurisdictionGroup";
import { updateOfficeContact } from "../../Services/MemberMainContact";
import { updateOffice } from "../../Services/Office";
import { updateRepresentativeClient } from "../../Services/RepresentativeClient";
import { updatePracticeIndustry } from "../../Services/PracticeIndustry";
import { updateRegion } from "../../Services/Region";
import { updateState } from "../../Services/State";
import { updatePerson } from "../../Services/Person";
import { IEducation } from "../../interfaces/models/IEducation";
import { updateEducation } from "../../Services/Education";

interface IUseGeneral {
  pathname: string;
}

interface Ifirm extends IUseGeneral { firm: IFirm; prevState?: IFirm; }
interface IdataAdmin extends IUseGeneral { dataAdmin: IDataAdmin; prevState?: IDataAdmin }
interface IfirmContact extends IUseGeneral { firmContact: IFirmContact; prevState?: IFirmContact; }
interface IfirmNote extends IUseGeneral { firmNote: IFirmNote; prevState?: IFirmNote; }
interface IofficeContact extends IUseGeneral { officeContact: IOfficeContact; prevState?: IOfficeContact; }
interface Ioffice extends IUseGeneral { office: IOffice; prevState?: IOffice; }
interface Iaddress extends IUseGeneral { address: IAddress; prevState?: IAddress; firmId: string; }
interface IAOP extends IUseGeneral { aop: IAreaOfPractice; prevState?: IAreaOfPractice; firmId: string; }
interface Icontent extends IUseGeneral { content: IContent; prevState?: IContent; firmId: string; }
interface IrepresentativeClient extends IUseGeneral { representativeClient: IRepresentativeClient; prevState?: IRegion; firmId: string;}
interface Icity extends IUseGeneral { city: ICity; prevState?: ICity; }
interface IgeneralTopic extends IUseGeneral { generalTopic: IContentGeneralTopic; prevState?: IContentGeneralTopic; }

interface IcontentTag extends IUseGeneral { contentTag: IContentTag; prevState?: IContentTag; }
interface Icountry extends IUseGeneral { country: ICountry; prevState?: ICountry; }
interface IglobalCrisis extends IUseGeneral { globalCrisis: IGlobalCrisis; prevState?: IGlobalCrisis; }
interface Ijurisdiction extends IUseGeneral { jurisdiction: IJurisdiction; prevState?: IJurisdiction; }
interface IjurisdictionGroup extends IUseGeneral { jurisdictionGroup: IJurisdictionGroup; prevState?: IJurisdictionGroup; }
interface IpracticeIndustry extends IUseGeneral { practiceIndustry: IPracticeIndustry; prevState?: IPracticeIndustry; }
interface Iregion extends IUseGeneral { region: IRegion; prevState?: IRegion; }
interface Istate extends IUseGeneral { state: IState; prevState?: IState; }
interface Iperson extends IUseGeneral { person: IPerson; prevState?: IPerson; }
interface Ieducation extends IUseGeneral { education: IEducation; prevState?: IEducation; }

interface IUpdateAndLog {
  updateFunction: Function;
  entity: any;
  entityName: string;
  pathname: string;
  prevState: any, 
  firmId?: string
}

const action = "UPDATE_";

export const useUpdateAndLog = () => {
  const { logActivity } = useLogging();

  const updateAndLog: (params: IUpdateAndLog) => Promise<any> = async ({
    updateFunction, 
    entity, 
    entityName, 
    pathname, 
    prevState,
    firmId
  }) => {
    try {
      const response = await updateFunction(entity);
      logActivity(
        `${action}${entityName.toUpperCase()}`,
        pathname,
        JSON.stringify({ prevState: prevState ?? {}, newState: response }),
        firmId
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const firm = async ({ firm, pathname, prevState }: Ifirm) => {
    return await updateAndLog({
      updateFunction: updateFirm,
      entity: firm,
      entityName: "FIRM", 
      pathname, 
      prevState,
      firmId: firm.id
    });
  };

  const dataAdmin = async ({ dataAdmin, pathname, prevState }: IdataAdmin) => {
    return await updateAndLog({
      updateFunction: updateDataAdmin,
      entity: dataAdmin,
      entityName: "DATA_ADMIN", 
      pathname, 
      prevState,
      firmId: dataAdmin.firm
    });
  };

  const firmContact = async ({ firmContact, pathname, prevState }: IfirmContact) => {
    return await updateAndLog({
      updateFunction: updateFirmContact,
      entity: firmContact,
      entityName: "FIRM_CONTACT", 
      pathname, 
      prevState,
      firmId: firmContact.firm
    });
  };

  const firmNote = async ({ firmNote, pathname, prevState }: IfirmNote) => {
    return await updateAndLog({
      updateFunction: updateFirmNote,
      entity: firmNote,
      entityName: "FIRM_NOTE", 
      pathname, 
      prevState,
      firmId: firmNote.firm
    });
  };

  const officeContact = async ({ officeContact, pathname, prevState }: IofficeContact) => {
    return await updateAndLog({
      updateFunction: updateOfficeContact,
      entity: officeContact,
      entityName: "OFFICE_CONTACT", 
      pathname, 
      prevState,
      firmId: officeContact.firm
    });
  };

  const office = async ({ office, pathname, prevState }: Ioffice) => {
    return await updateAndLog({
      updateFunction: updateOffice,
      entity: office,
      entityName: "OFFICE", 
      pathname, 
      prevState,
      firmId: office.firm
    });
  };

  const address = async ({ address, pathname, prevState, firmId }: Iaddress) => {
    return await updateAndLog({
      updateFunction: updateAddress,
      entity: address,
      entityName: "ADDRESS", 
      pathname, 
      prevState,
      firmId
    });
  };

  const aop = async ({ aop, pathname, prevState, firmId }: IAOP) => {
    return await updateAndLog({
      updateFunction: updateAop,
      entity: aop,
      entityName: "AREA_OF_PRACTICE", 
      pathname, 
      prevState,
      firmId
    });
  };

  const content = async ({ content, pathname, prevState, firmId }: Icontent) => {
    return await updateAndLog({
      updateFunction: updateContent,
      entity: content,
      entityName: "CONTENT", 
      pathname, 
      prevState,
      firmId
    });
  };

  const representativeClient = async ({ representativeClient, pathname, prevState, firmId }: IrepresentativeClient) => {
    return await updateAndLog({
      updateFunction: updateRepresentativeClient,
      entity: representativeClient,
      entityName: "REPRESENTATIVE_CLIENT", 
      pathname, 
      prevState,
      firmId
    });
  };

  const city = async ({ city, pathname, prevState }: Icity) => {
    return await updateAndLog({
      updateFunction: updateCity,
      entity: city,
      entityName: "CITY", 
      pathname, 
      prevState,
    });
  };

  const contentGeneralTopic = async ({ generalTopic, pathname, prevState }: IgeneralTopic) => {
    return await updateAndLog({
      updateFunction: updateContentGeneralTopics,
      entity: generalTopic,
      entityName: "CONTENT_GENERAL_TOPIC", 
      pathname, 
      prevState,
    });
  };
  
  const contentTag = async ({
    contentTag,
    pathname,
    prevState,
  }: IcontentTag) => {
    return await updateAndLog({
      updateFunction: updateContentTag,
      entity: contentTag,
      entityName: "CONTENT_TAG",
      pathname,
      prevState,
    });
  };

  const country = async ({ country, pathname, prevState }: Icountry) => {
    return await updateAndLog({
      updateFunction: updateCountry,
      entity: country,
      entityName: "COUNTRY",
      pathname,
      prevState,
    });
  };

  const globalCrisis = async ({
    globalCrisis,
    pathname,
    prevState,
  }: IglobalCrisis) => {
    return await updateAndLog({
      updateFunction: updateGlobalCrisis,
      entity: globalCrisis,
      entityName: "GLOBAL_CRISIS",
      pathname,
      prevState,
    });
  };
  
  const jurisdiction = async ({
    jurisdiction,
    pathname,
    prevState,
  }: Ijurisdiction) => {
    return await updateAndLog({
      updateFunction: updateJurisdiction,
      entity: jurisdiction,
      entityName: "JURISDICTION",
      pathname,
      prevState,
    });
  };

  const jurisdictionGroup = async ({
    jurisdictionGroup,
    pathname,
    prevState,
  }: IjurisdictionGroup) => {
    return await updateAndLog({
      updateFunction: updateJurisdictionGroup,
      entity: jurisdictionGroup,
      entityName: "JURISDICTION_GROUP",
      pathname,
      prevState,
    });
  };
  
  const practiceIndustry = async ({
    practiceIndustry,
    pathname,
    prevState,
  }: IpracticeIndustry) => {
    return await updateAndLog({
      updateFunction: updatePracticeIndustry,
      entity: practiceIndustry,
      entityName: "PRACTICE_INDUSTRY",
      pathname,
      prevState,
    });
  };

  const region = async ({ region, pathname, prevState }: Iregion) => {
    return await updateAndLog({
      updateFunction: updateRegion,
      entity: region,
      entityName: "REGION",
      pathname,
      prevState,
    });
  };
  
  const state = async ({ state, pathname, prevState }: Istate) => {
    return await updateAndLog({
      updateFunction: updateState,
      entity: state,
      entityName: "STATE",
      pathname,
      prevState,
    });
  };
  
  const person = async ({ person, pathname, prevState }: Iperson) => {
    return await updateAndLog({
      updateFunction: updatePerson,
      entity: person,
      entityName: "PERSON",
      pathname,
      prevState,
    });
  };

  const education = async ({ education, pathname, prevState }: Ieducation) => {
    return await updateAndLog({
      updateFunction: updateEducation,
      entity: education,
      entityName: "EDUCATION",
      pathname,
      prevState,
    });
  };

  return {
    firm,
    dataAdmin,
    firmContact,
    firmNote,
    officeContact,
    office,
    address,
    aop,
    content,
    representativeClient,
    city,
    contentGeneralTopic,
    contentTag,
    country,
    globalCrisis,
    jurisdiction,
    jurisdictionGroup,
    practiceIndustry,
    region,
    state,
    person,
    education
  };
};