import NewButton from "../../../components/Atomos/Buttons/newButton";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import React, { useState } from "react";
import Input from "../../../components/Atomos/Inputs/Input";
import DeleteIcon from "../../../components/Atomos/Icons/DeleteIcon";
import { set } from "date-fns";
import InputImageMinimal from "../../../components/Atomos/InputImage/InputImageMinimal";
import Plus from "../../../components/Atomos/Icons/Plus";

type OfficeRepresentativeClientProps = {
  officeName: string;
  representativeClients?: any[];
  setRepresentativeClients?: React.Dispatch<
    React.SetStateAction<any[]>
  >;
  firmId?: string;
  firmName?: string;
  officeId?: string;
  representativeClientsDelete?: string[];
  setRepresentativeClientsDelete?: React.Dispatch<
    React.SetStateAction<string[] | undefined>
  >;
};

const OfficeRepresentativeClientIB: React.FC<
  OfficeRepresentativeClientProps
> = ({
  officeName,
  representativeClients = [],
  setRepresentativeClients,
  firmId,
  firmName,
  officeId,
  representativeClientsDelete = [],
  setRepresentativeClientsDelete,
}) => {

  const handleDelete = (index: number) => {
    const updatedClients = representativeClients.filter((_, i) => i !== index);
    if (!representativeClients[index].new) {
      setRepresentativeClientsDelete?.([
        ...representativeClientsDelete,
        representativeClients[index].id,
      ]);
    }
    setRepresentativeClients?.(updatedClients);
  };

  const handleAddNewRepresentativeClient = () => {
    const newClient = {
      name: "representativeClient",
      firm: firmId,
      firmName: firmName,
      office: officeId,
      officeNames: [officeName],
      new: true,
      logo: null,
    };
    setRepresentativeClients?.([...representativeClients, newClient]);
  };

  const handleImageChange = (index: number, image: string | File | null) => {
    setRepresentativeClients?.((prevClients) => {
      const updatedClients = prevClients ? [...prevClients] : [];
      updatedClients[index] = { ...updatedClients[index], logo: image};
      return updatedClients;
    });
  };

  return (
    <>
      <div className="flex w-full flex-col gap-5 md:flex-row md:justify-between">
        <CatalogSubTitle
          className="text-Default"
          title={`${officeName} Representative Client`}
        />
        <NewButton
          text={"ADD"}
          content="textIcon"
          color={"accent"}
          className=""
          icon={<Plus />}
          onClick={() => {
            handleAddNewRepresentativeClient();
          }}
        />
      </div>

      <span className="text-gray-font text-base">
        {`${officeName} has Investment Banking Service Type. Click 'Add' to create a new “Representative Client” and upload/crop an image, or click the thumbnail to edit.`}
      </span>

      <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-5">
        {representativeClients?.map(
          (representativeClient: any, index: number) => (
            <div className="flex flex-col formsection w-full" key={index}>
              <InputImageMinimal
                setImage={(image) => handleImageChange(index, image)}
                photo={representativeClient.logo as string}
                title="Representative Client Image"
              />
              <NewButton
                text={"DELETE"}
                icon={<DeleteIcon />}
                content="textIcon"
                color={"delete"}
                style="outlined"
                onClick={() => {
                  handleDelete(index);
                }}
              />
            </div>
          )
        )}
      </div>
    </>
  );
};

export default OfficeRepresentativeClientIB;
