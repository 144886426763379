import React, { useEffect, useMemo, useState } from 'react';
import Axios from '../../utils/axios';
import AnimationLogo from '../../components/Layout/LoadingAnimation/AnimationLogo';
import TabManager from '../../components/Layout/TabManager/TabManager';
import MappingFilter, { FilterComponentKey } from '../../components/Catalogs/Filters/MappingFilter';
import { AlphabetOptions } from '../../utils/AlphabetOptions';
import useDataLoader from '../../hooks/useDataLoader';
import TotalCounter from '../../components/Atomos/Counters/TotalCounter';
import { IPracticeIndustry } from '../../interfaces/models/IPracticeIndustry';
import { Option } from '../../components/Atomos/DropDown/DDMultiple';
import CatalogTitle from '../../components/Moleculas/Catalog Text/CatalogTitle';
import BackgroundComponent from '../../components/Layout/BackgroundProfile/BackgroundProfile';
import ProfileViewModeBg from '../../assets/images&icons/profileViewMode.jpeg'
import CurrentPage from '../../components/Layout/CurrentPageTab/CurrentPage';
import PaginationComponent from '../../components/Atomos/Pagination/PaginationComponent';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import CustomReactTable from '../../components/Organismos/Table/TanStackTable';
import useGenerateColumns from '../../hooks/useGenerateColumns';
import { defaultPageSize } from '../../utils/const';

const AOPMapping: React.FC = ({ ...props }) => {
  const Headers: string[] = ['name', 'professionals', 'firms', 'practiceIndustries', 'isIgnored'];
  const axios = new Axios();

  const [sort, setSort] = useState<{ field: string; order: string }[]>([]);
  const [pageHeaders, setPageHeaders] = useState<string[]>([]);
  const [tableKey, setTableKey] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(defaultPageSize);
  const [filter, setFilter] = useState<{ field: string; value: string }[]>([]);
  const [firmData, setFirmData] = useState<any[]>([])
  const [selectedPracticeIndustry, setSelectedPracticeIndustry] = useState<Option[]>([]);
  const [selectedFirm, setSelectedFirm] = useState<Option[]>([]);
  const [selectedInitials, setSelectedInitials] = useState<Option[]>([]);
  const [nameFilter, setNameFilter] = useState('');

  const savedTab = localStorage.getItem('selectedTab');
  const initialTab = savedTab ? parseInt(savedTab, 10) : 0;
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [mappedTotals, setMappedTotals] = useState(0)
  const [unmappedTotals, setUnmappedTotals] = useState(0)
  const [showMappedCounter, setShowMappedCounter] = useState(true);
  const [showUnmappedCounter, setShowUnmappedCounter] = useState(true);
  const [practiceIndustries, setPracticeIndustries] = useState<IPracticeIndustry[]>([]);
  const [filterOrder, setFilterOrder] = useState<FilterComponentKey[]>(['name', 'firm', 'practiceIndustries', 'initial']);
  const [tableInstance, setTableInstance] = useState<any>();


  const practiceIndustriesAsFilterProps: Option[] = useMemo(() => {
    return practiceIndustries.map(pi => ({ id: pi.id, name: pi.name as string } as Option));
  }, [practiceIndustries]);


  const getMappedCounters = async () => {
    try {
      const response = await axios.Get('/AreaOfPractice?mappingCounters=true&resourceId=mappingPage')
      const data = response.data
      setMappedTotals(data.countTrue)
      setUnmappedTotals(data.countFalse)
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const getPracticeIndustries = async () => {
    try {
      const response = await axios.Get('/practiceIndustry?sort=name')
      if (response && response.data) {
        setPracticeIndustries(response.data)
      }
    } catch (error: any) {
      throw error
    }
  }


  useEffect(() => {
    localStorage.setItem('selectedTab', selectedTab.toString());
    getPracticeIndustries()
  }, [selectedTab]);

  const queryParams = useMemo(() => {
    let params = [
      `pageNumber=${currentPage}`,
      `pageSize=${recordsPerPage}`,
      `resourceId=mappingPage`,
      ...sort.map(s => `sort=${s.field}:${s.order}`),
      ...filter
        .filter(f => f.value !== "")
        .map(f => `filter=${f.field}:${f.value}`)
    ];
    if (selectedTab === 1) {
      params.push('isMapped=true');
    }
    return params;
  }, [currentPage, recordsPerPage, sort, filter, selectedTab]);

  const { data, totalCount, isLoading } = useDataLoader<any>({
    endpoint: '/areaOfPractice',
    queryParams,
    countOnly: true,
  });

  const fetchFirmData = async () => {
    try {
      const response = await axios.Get('./firm')
      const data = response.data
      if (data != null) {
        setFirmData(data)
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (pageHeaders.length === 0) {
      setPageHeaders(Headers);
    }
    getMappedCounters()
    fetchFirmData()
  }, []);

  const handleNameFilterChange = (newNameFilter: string) => {
    setNameFilter(newNameFilter);
  };
  const HandleFilter = (field: string, value: string) => {
    setFilter([...filter, { field, value }]);
  }
  const handlePageChange = (page: number, recordsPerPage: number) => {
    setCurrentPage(page);
    setRecordsPerPage(recordsPerPage);
  };



  const tabs = [
    { title: 'Unmapped AOP', action: () => setSelectedTab(0) },
    { title: 'Mapped AOP', action: () => setSelectedTab(1) },
  ];

  useEffect(() => {
    let headersToUpdate = Headers.filter(header => header !== 'isTargeted');
    if (selectedTab === 1) {
    } else {

      headersToUpdate = Headers.filter(header => header !== 'practiceIndustries' && header !== 'isIgnored');
    }
    setPageHeaders(headersToUpdate);
  }, [selectedTab]);


  const SubmitFilters = () => {
    setFilter([
      { field: 'firms', value: selectedFirm.map(f => f.id).join(':') },
      { field: 'initials', value: selectedInitials.map(i => i?.name).join(':') },
      { field: 'name', value: nameFilter },
      { field: 'practiceIndustries', value: selectedPracticeIndustry.map(pi => pi.id).join(':') },
    ]);
  };

  const clearSelections = () => {
    setSelectedPracticeIndustry([]);
    setSelectedFirm([]);
    setSelectedInitials([]);
    setNameFilter('');

    setFilter([]);
    setCurrentPage(1);
    setSort([{ field: '_ts', order: 'desc' }]);
  };


  const toggleShowUnmapped = () => {
    setShowUnmappedCounter(!showUnmappedCounter)
  }

  const toggleShowMapped = () => {
    setShowMappedCounter(!showMappedCounter)
  }

  useEffect(() => {
    if (practiceIndustries) {

    }
  }, [practiceIndustries])


  const Pagination = () => (
    <PaginationComponent
      recordsPerPage={recordsPerPage}
      currentPage={currentPage}
      onPageChange={handlePageChange}
      totalCount={selectedTab === 1 ? mappedTotals : unmappedTotals}
    />
  )

  const HandlerSort = (field: string) => {
    if (field === 'actions' || field === 'index') {
      return;
    }
    const sortedIndex = sort.findIndex((sortItem) => sortItem.field === field);
    if (sortedIndex !== -1) {
      const updatedSort = [{ field, order: sort[sortedIndex].order === 'asc' ? 'desc' : 'asc' }];
      setSort(updatedSort);
    } else {
      setSort([{ field, order: "asc" }]);
    }
  };

  const baseColumns = useGenerateColumns(Headers, practiceIndustries);
  const [columnOrder, setColumnOrder] = useState<string[]>(baseColumns.map(c => c.id).filter((id): id is string => id !== undefined))



  return (
    <>
      <BackgroundComponent image={ProfileViewModeBg} />
      {isLoading ? (
        <div className='w-full h-screen flex items-center justify-center -mt-24 z-10 relative'>
          <div className='w-full h-full bg-white'>
            <AnimationLogo />
          </div>
        </div>
      ) :
        <>
          <main className='mainSection'>
            <div className='flex flex-col gap-5 lg:gap-6 lg:pb-[1.125rem] pb-5'>
              <CatalogTitle title={`Areas Of Practice Mapping`} className='text-white !pb-0' />
              <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
              <div className="flex w-full">
                <CurrentPage prevPage="HOME > SEO" currentPage={`AOP Mapping`} />
              </div>
            </div>
            <div className='grid-cols-2 grid lg:grid lg:grid-cols-6 flex-wrap gap-5 md:grid-cols-4 mb-5 relative z-10 lg:mb-5'>
              <div className=''>
                <TotalCounter title="# OF ATTORNEYS WITH 0 AOPs" total={2} />
              </div>
              <div className=''>
                <TotalCounter title="# OF ATTORNEYS WITH 1 AOP" total={2} />
              </div>
              <div className=''>
                <TotalCounter title="UNMAPPED & TARGETED AOP" total={2} />
              </div>
              <div className=''>
                <TotalCounter title="# OF NON-ATTORNEYS WITH NO AOPs" total={2} />
              </div>
              {showUnmappedCounter && (
                <div className=''>
                  <TotalCounter title="UNMAPPED AOP" total={unmappedTotals} />
                </div>
              )}
              {showMappedCounter && (
                <div className=''>
                  <TotalCounter title="MAPPED AOP" total={mappedTotals} />
                </div>
              )}
            </div>
            <TabManager
              tabs={tabs}
              selectedTabIndex={selectedTab}
            />
            <CatalogTableC
              noNewCatalog
              // noRedBox
              catalogName={'AreaOfPractice'}
              route={'AreaOfPractice'}
              isfiltered={true}
              data={data}
              headers={Headers}
              totalCount={totalCount}
              practiceIndustries={practiceIndustries}
              selectedTab={selectedTab}
            >
              <MappingFilter
                order={filterOrder}
                search={SubmitFilters}
                resetFilters={clearSelections}
                headers={Headers}
                practiceIndustriesData={practiceIndustriesAsFilterProps}
                selectedPracticeIndustries={selectedPracticeIndustry}
                setSelectedPracticeIndustries={setSelectedPracticeIndustry}
                selectedTab={selectedTab}
                initialsData={AlphabetOptions}
                firmData={firmData}
                selectedInitials={selectedInitials}
                setSelectedInitials={setSelectedInitials}
                selectedFirm={selectedFirm}
                setSelectedFirm={setSelectedFirm}
                nameFilter={nameFilter}
                onNameFilterChange={handleNameFilterChange}
                tableInstance={tableInstance}
              />
              <Pagination />
              <div className='w-full overflow-x-scroll'>
                <CustomReactTable
                  key={0}
                  data={data}
                  columnOrder={columnOrder}
                  columns={baseColumns}
                  onSort={HandlerSort}
                  setSorting={setSort}
                  sorting={sort}
                  onTableInstanceReady={setTableInstance}
                  selectedTab={selectedTab}
                />
              </div>
              <Pagination />
            </CatalogTableC>

          </main>
        </>
      }
    </>
  );
};

export default AOPMapping;