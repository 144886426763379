import { useState } from "react";
import { Combobox } from "@headlessui/react";
import ChevronDown from "../../Atomos/Icons/ChevronDown";
import { countryCodes } from "../../../pages/ProfileEditMode/CountryCodes";
import { useAppContext } from "../../../Context";
import "../../../assets/css/Text.css";

type IPhoneNumberProps = {
  className?: string;
  title?: string;
} & InputProps;

type InputProps = {
  title: string;
  Obj: { code: string; phone: string; basecode: string };
  seter: ({
    code,
    phone,
    basecode,
  }: {
    code: string;
    phone: string;
    basecode: string;
  }) => void;
  required?: boolean;
  error: boolean;
  errormsj?: string;
  tooltip?: string;
  disabled?: boolean;
  getid?: boolean;
  type?: string;
};
const countryCodesToShow = countryCodes.map((object, index) => {
  return {
    id: index,
    name: object.code + " " + object.dial_code,
    countryName: object.name,
    dial: object.dial_code,
    code: object.code,
  };
});

export const PhoneNumber = ({
  className,
  title,
  Obj,
  seter,
  error,
  errormsj,
  tooltip,
  disabled,
  getid,
  required,
}: IPhoneNumberProps): JSX.Element => {
  const formatPhoneNumber = (value: string) => {
    const cleaned = value.replace(/\D/g, "");

    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (!match) return value;
    return [match[1], match[2], match[3]].filter(Boolean).join(" ");
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    seter({ ...Obj, phone: formattedPhone });
  };

  const handleCodeChange = (value: string) => {
    const [basecode, code] = value.split(" ");

    seter({...Obj, code, basecode,
    });
  };
  const [dataQuery, setDataQuery] = useState("");
  const filteredData =
    dataQuery === ""
      ? countryCodesToShow
      : countryCodesToShow?.filter((object) => {
          return (
            object.name.toLowerCase().includes(dataQuery.toLowerCase()) ||
            object.countryName.toLowerCase().includes(dataQuery.toLowerCase())
          );
        });

  const { mode } = useAppContext();

  return (
    <div
      className={
        "flex flex-col gap-2.5 items-start justify-start flex-1 relative " +
        className
      }
    >
      {mode === "member" ? (
        <div
          className={`${disabled ? " text-gray-200" : "text-gray-font"}  inputLabel`}
        >
          <label>
            {title ? title : "Phone Number"}{" "}
            <span
              className={`${required ? (disabled ? " text-gray-200" : "text-primary") : "hidden"}`}
            >
              *
            </span>
          </label>
        </div>
      ) : (
        <div
          className={`${disabled ? " text-gray-200" : required ? " text-primary" : "text-gray-font"}  inputLabel`}
        >
          {title ? title : "Phone Number"}
        </div>
      )}
      <div
        className={`flex flex-row gap-0 w-full h-full items-start justify-start self-stretch shrink-0 relative ${disabled ? "border border-spacing-1 border-solid border-gray-300" : ""}`}
      >
        <div
          className={`flex flex-col gap-0 items-start justify-start shrink-0 w-32 h-12 text-left  min-h-12 max-h-12 relative `}
        >
          <Combobox
            value={`${Obj.basecode || ""} ${Obj.code || ""}`}
            onChange={(e) => handleCodeChange(e)}
            disabled={disabled}
          >
            {({ open }) => (
              <div className="relative ">
                <div
                  className={`   !bg-green-200 ${error && "outline outline-1 outline-feedback-error"}`}
                >
                  <Combobox.Input
                    className={`${disabled ? " placeholder-gray-300" : "placeholder-gray-500"}  text-gray overflow-hidden pr-4 bg-transparent border-none w-full text-sm font-medium   focus:outline-none`}
                    onChange={(event) =>
                      setDataQuery(event.currentTarget.value)
                    }
                    placeholder={"(+ )"}
                  />

                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <div
                      id="chevron-up"
                      className={`w-6 h-6 ${disabled && "fill-gray-400"} ${open && "rotate-180 duration-75"} `}
                    >
                      <ChevronDown />
                    </div>
                  </Combobox.Button>
                </div>
                <Combobox.Options
                  className={`absolute bg-red-100 top-14 w-full h-48 z-30 no-scrollbar overflow-scroll  ${!open && "hidden"} `}
                >
                  {filteredData?.map((object: any) => (
                    <Combobox.Option
                      key={object.id}
                      value={getid ? object : object.name}
                      className={
                        " p-3 text-base leading-[19px] text-neutral-dark bg-white hover:bg-gray-50"
                      }
                    >
                      {object.name}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </div>
            )}
          </Combobox>
        </div>
        <div
          className={`text-gray-font text-left text-lg leading-5 font-normal not-italic relative self-stretch w-full ${error && "outline outline-1 outline-feedback-error"}`}
        >
          <input
            name={title}
            disabled={disabled}
            type="text"
            placeholder="000 000 0000"
            value={Obj.phone}
            onChange={handleNumberChange}
            className=""
          ></input>
        </div>
      </div>
      {error && (
        <span className=" false text-sm font-medium text-feedback-error ">
          {errormsj
            ? errormsj
            : required
              ? "This Phone Number is Required"
              : ""}
        </span>
      )}
    </div>
  );
};
