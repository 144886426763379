import React, { useEffect, useState } from 'react';
import Axios from '../../utils/axios';
import CatalogTable from '../../components/Catalogs/CatalogTable';
import Table from '../../components/Organismos/Table/Table';
import { useTimeoutFn } from 'react-use';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/loaderActions';
import { defaultPageSize } from '../../utils/const';

const WSG: React.FC = ({ ...props }) => {
  const Headers: string[] = ['name', "_ts"];
  const axios = new Axios();
  const dispatch = useDispatch()
  const [data, setData] = useState<any[]>([]);
  const [sort, setSort] = useState<{ field: string; order: string }[]>([{ field: '_ts', order: 'desc' }]);
  const [pageHeaders, setPageHeaders] = useState<string[]>([]);
  const [tableKey, setTableKey] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(defaultPageSize);
  const [totalData, setTotalData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  let [isShowing, setIsShowing] = useState(true)
  let [, , resetIsShowing] = useTimeoutFn(() => setIsShowing(true), 500)

  useEffect(() => {
    const fetchData = async () => {
      try {
        var queryParams: string[] = [];
        if (sort.length > 0) {
          sort.forEach((element) => {
            if (element.order !== 'any')
              queryParams.push(`sort=${element.field}:${element.order}`);
          });
        }

        if (currentPage && recordsPerPage)
          queryParams.push(`pageNumber=${currentPage}`);
        queryParams.push(`pageSize=${recordsPerPage}`);

        var queryString = '/WSG';
        if (queryParams.length > 0) {
          queryString += `?${queryParams.join('&')}`;
        }
        console.log(queryString);
        const response = await axios.Get(queryString);
        const data = response.data;
        if (data != null) {
          setData(data);
        }
      } catch (error) {
        console.error('Error al obtener la información de la columna:', error);
      }
    };
    setIsShowing(false)
    resetIsShowing()
    fetchData();
    setTableKey(tableKey + 1);
  }, [sort, currentPage, recordsPerPage]);

  useEffect(() => {
    if (pageHeaders.length === 0) {
      setPageHeaders(Headers);
    }

    const fetchTotalCount = async () => {
      try {
        var queryString = '/WSG';
        const response = await axios.Get(queryString);
        const data = response.data;
        if (data != null) {
          setTotalData(data)
          setTotalCount(data.length);
        }
      } catch (error) {
        console.error('Error al obtener la información de la columna:', error);
      }
    };
    fetchTotalCount();
  }, []);

  const HandlerSort = (field: string) => {
    const sortedIndex = sort.findIndex((sortItem) => sortItem.field === field);
    if (sortedIndex !== -1) {
      var updatedSort = [...sort];
      var sorted = updatedSort[sortedIndex];
      sorted.order = Sort(sorted.order);
      updatedSort[sortedIndex] = sorted;
      setSort(updatedSort);
    } else {
      setSort([...sort, { field, order: "asc" }]);
    }
  };

  const Sort = (order: string) => {
    if (order === "asc") {
      return order = "desc";
    } else if (order === "desc") {
      return order = "any";
    } else {
      return order = "asc";
    }
  }

  const handlePageChange = (page: number, recordsPerPage: number) => {
    setCurrentPage(page);
    setRecordsPerPage(recordsPerPage);
  };

  return (
    <>
      <div className=' mainSection'>
        <CatalogTable title={'WSG Catalog'} headers={Headers} isfiltered={false}
          catalogName={'WSG'} pageSize={50} route={'WSG'} isPaginated={false}
          table={<Table key={tableKey} HandlerSort={HandlerSort} sort={sort} id={'CatalogTable'} pageData={data} pageSize={recordsPerPage} currentPage={currentPage} pageHeaders={Headers} />}
          totalCount={totalCount} data={data}
          handlePageChange={handlePageChange}
          noExcelExport
          noNewCatalog
          noRedBox
        />
      </div>
    </>
  );
};

export default WSG;
