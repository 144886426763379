import { Link, useLocation } from "react-router-dom";
import "../../../assets/css/AddCatalogs.css";
import "./GeneralTabMenuCSS.css";
import React, { useEffect, useMemo } from "react";
import Dashboard from "../../../components/Atomos/Icons/Dashboard";
import Apartment from "../../../components/Atomos/Icons/Apartment";
import ClinicalNotes from "../../../components/Atomos/Icons/ClinicalNotes";
import Content from "../../../components/Atomos/Icons/Content";
import Communication from "../../../components/Atomos/Icons/Communication";
import GroupsAlt from "../../../components/Atomos/Icons/GroupsAlt";
import SupervisorAccount from "../../../components/Atomos/Icons/SupervisorAccount";
import Gavel from "../../../components/Atomos/Icons/Gavel";
import LocalActivity from "../../../components/Atomos/Icons/LocalActivity";
import { useDispatch, useSelector } from "react-redux";
import { Appstore, manageFirmSlice } from "../../../redux";
import HeaderExtra from "../../../components/Layout/HeaderExtra/HeaderExtra";

interface IMenuProps {
  state?: any;
  tabSelected?: string;
}

interface IMenu {
  id: number;
  name: string;
  icon?: (className: string) => React.ReactNode;
  url?: string;
}

const baseUrl = "/Staff/ManageFirm";

const menuItems: IMenu[] = [
  { id: 0, name: "All Firms", url: "/" },
  { id: 1, name: "General", icon: (className) => <Dashboard className={className} />, url: "/GeneralTab/" },
  { id: 2, name: "Dashboard", icon: (className) => <Dashboard className={className} />, url: "/Dashboard/" },
  { id: 3, name: "Profile", icon: (className) => <Apartment className={className} />, url: "/Profile/" },
  { id: 4, name: "Professionals", icon: (className) => <ClinicalNotes className={className} />, url: "/Professionals/" },
  { id: 5, name: "Referrals", icon: (className) => <Communication className={className} />, url: "/Referrals/" },
  { id: 6, name: "Group Participants", icon: (className) => <GroupsAlt className={className} />, url: "/GroupParticipants/" },
  { id: 7, name: "Practice Heads", icon: (className) => <SupervisorAccount className={className} />, url: "/PracticeHeads/" },
  { id: 8, name: "AOP Management", icon: (className) => <Gavel className={className} />, url: "/aopManagement/" },
  { id: 9, name: "Events", icon: (className) => <LocalActivity className={className} />, url: "/Events/" },
  { id: 10, name: "Content", icon: (className) => <Content className={className} />, url: "/Content/" },
];

const MenuItem: React.FC<{ item: IMenu; selectedTab: number }> = ({
  item,
  selectedTab,
}) => {
  const isSelected = item.id === selectedTab;
  const isDisabled = !item.url;

  return (
    <li
      className={`cursor-pointer menu-item ${isDisabled ? "disabled" : "hover:bg-[#004578]"} ${isSelected ? "selected-item" : ""}`}
    >
      <span>
        {item.icon &&
          item.icon(
            `${isDisabled ? "disabled-icon" : isSelected ? "selected-icon" : "menu-icon"}`
          )}
      </span>
      <span
        className={`${isDisabled ? "disabled-text" : isSelected ? "selected-text" : "menu-text"}`}
      >
        {item.name}
      </span>
    </li>
  );
};

const MenuGT: React.FC<IMenuProps> = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);
  const extraMenuOpen = useSelector((state: Appstore) => state.menus.extraMenuIsOpen);

  useEffect(() => {
    const showMenu =
      pathname.split("/")[2]?.toLowerCase() === "managefirm" &&
      manageFirm.selectedFirm !== undefined;
    dispatch(manageFirmSlice.actions.SetShowMenu(showMenu));

    const currentTabName = pathname.split("/")[3];
    const currentTab = menuItems.find(
      (m) => m.url === `/${currentTabName}/`
    )?.id;
    dispatch(manageFirmSlice.actions.SetTab(currentTab));
  }, [pathname, manageFirm.selectedFirm, dispatch]);

  const renderedMenuItems = useMemo(() => {
    return menuItems.map((item) =>
      item.url ? (
        <Link
          to={`${baseUrl}${item.url}${item.name !== "All Firms" ? manageFirm.selectedFirm?.id : ""}`}
          key={item.id}
        >
          <MenuItem item={item} selectedTab={manageFirm.selectedTab} />
        </Link>
      ) : (
        <MenuItem
          item={item}
          selectedTab={manageFirm.selectedTab}
          key={item.id}
        />
      )
    );
  }, [manageFirm.selectedFirm, manageFirm.selectedTab]);

  return (
    <>
      <HeaderExtra/>
      {manageFirm.showMenu && (
        <div className={`sticky ${extraMenuOpen ? 'top-[60px]' : 'top-0'} overflow-x-auto bg-[#E5F3FF] border-b-[#E5F3FF] scrollbar-hide z-20`}>
          <ul className="menu-bar">{renderedMenuItems}</ul>
        </div>
      )}
    </>
  );
};

export default MenuGT;
