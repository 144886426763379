import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Axios from "../../../../utils/axios";
import JurisdictionContact from "../../../../components/Catalogs/FirmContact/JurisdictionContacts";
import NewButton from "../../../../components/Atomos/Buttons/newButton";
import Plus2 from "../../../../components/Atomos/Icons/Plus";
import Close from "../../../../components/Atomos/Icons/Close";
import FirmContactButton from "../../../../components/Atomos/Buttons/firmContactButton";
import SaveChanges from "../../../../components/Atomos/Icons/EditMode/SaveChanges";
import { useUpdateAndLog } from "../../../../hooks/LogActivity/LogActivityUpdates";
import IFirmContact from "../../../../interfaces/models/IFirmContact";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/loaderActions";
import { UseJurisdictionList } from "../../../../hooks/Catalogs/useJurisdiction";

type props = {
  jurisdictionId: string;
  jurisdictionName?: string;
  setErrorFields: any;
  setErrorJurisdiction: any;
  buttonClicked: string;
  jurisdictionsIds?: any[];
};

interface ContactItem {
  type: string;
  contact: string;
}

const initialActiveErrors = {
  primaryContact: false,
  secondaryContact: false,
  marketingContact: false,
  accountingContact: false,
};

const ActiveErrorsMessage = {
  primaryContact: "Primary Contact is required.",
  secondaryContact: "Secondary Contact is required.",
  marketingContact: "Marketing Contact is required.",
  accountingContact: "Accounting Contact is required.",
};

const EditMembership: React.FC<props> = ({
  jurisdictionId,
  jurisdictionName,
  setErrorFields,
  setErrorJurisdiction,
  buttonClicked,
  jurisdictionsIds,
}) => {
  const axios = new Axios();
  const { state, pathname } = useLocation();
  const update = useUpdateAndLog();
  
  const navigate = useNavigate();

  const saveButtonRef = useRef<HTMLDivElement>(null);

  const [checked, setChecked] = useState(false);
  const [contacts, setContacts] = useState<any[]>([]);
  const [jurisdictions, setJurisdictions] = useState<any[]>([]);
  const [jurisdictionsObjects, setJurisdictionsObjects] = useState<any[]>([]);
  const [selectedContacts, setSelectedContacts] = useState({
    primaryContact: { id: "", name: "" },
    secondaryContact: { id: "", name: "" },
    marketingContact: { id: "", name: "" },
    accountingContact: { id: "", name: "" },
    itContact: { id: "", name: "" },
    socialMediaContact: { id: "", name: "" },
    hrContact: { id: "", name: "" },
  });
  const [selectedAdditionalContacts, setSelectedAdditionalContacts] = useState<
    { id: ""; name: "" }[]
  >([]);
  const [activeErrors, setActiveErrors] = useState(initialActiveErrors);
  const [activeErrorsMessage, setActiveErrorsMessage] =
    useState(ActiveErrorsMessage);
    const {jurisdictionData} = UseJurisdictionList();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const promises = [];
      if (!contacts.length) {
        promises.push(fetchContacts());
      }
      await Promise.all(promises);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchContacts = async () => {
    try {
      const response = await axios.Get("./person");
      const data = response.data.filter(
        (contact: any) => contact.firm === state?.id
      );

      const formatedData = data?.map((d: any) => {
        return {
          ...d,
          name: (d.name + " " + d.lastName).trim(),
        };
      });
      if (data != null) {
        setContacts(formatedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleDropDownChange = (key: string, contact: any) => {
    setSelectedContacts((prevState) => ({
      ...prevState,
      [key]: { id: contact.id, name: contact?.name ?? "" },
    }));

    setJurisdictions((prevJurisdictions) => {
      const updatedJurisdictions = prevJurisdictions?.map((jurisdiction) => {
        if (jurisdiction.jurisdiction === jurisdictionId) {
          return {
            ...jurisdiction,
            contacts: jurisdiction.contacts?.map((contactItem: any) => {
              if (contactItem.type === key) {
                return { ...contactItem, contact: contact.id };
              }
              return contactItem;
            }),
          };
        }
        return jurisdiction;
      });
      return updatedJurisdictions;
    });
  };

  const handleDropDownChangeAdditional = (index: number, contact: any) => {
    setSelectedAdditionalContacts((prevContacts) => {
      const updatedContacts = [...prevContacts];
      updatedContacts[index] = { id: contact.id, name: contact.name };
      return updatedContacts;
    });

    setJurisdictions((prevJurisdictions) => {
      return prevJurisdictions?.map((jurisdiction) => {
        if (jurisdiction.jurisdiction === jurisdictionId) {
          const additionalContactsIndex = jurisdiction.contacts.findIndex(
            (contactItem: any) => Array.isArray(contactItem)
          );

          const updatedContacts = selectedAdditionalContacts;
          updatedContacts[index] = { id: contact.id, name: contact.name };

          if (additionalContactsIndex !== -1) {
            jurisdiction.contacts[additionalContactsIndex] = updatedContacts;
          }
        }
        return jurisdiction;
      });
    });
  };

  const handleAddNewContact = () => {
    setSelectedAdditionalContacts((prevContacts) => [
      ...prevContacts,
      { id: "", name: "" },
    ]);
  };

  const handleApplyContact = (
    jurisdictionIds: any,
    contactObject: any,
    type: any
  ) => {
    jurisdictionIds.forEach((jurisdictionId: any) => {
      const jurisdictionIndex = jurisdictions.findIndex(
        (jurisdiction) => jurisdiction.jurisdiction === jurisdictionId.id
      );

      if (jurisdictionIndex !== -1) {
        const updatedJurisdictions = [...jurisdictions];
        updatedJurisdictions[jurisdictionIndex].contacts = updatedJurisdictions[
          jurisdictionIndex
        ].contacts?.map((contactItem: any) => {
          if (contactItem.type === type) {
            return { ...contactItem, contact: contactObject.id };
          }
          return contactItem;
        });

        setJurisdictions(updatedJurisdictions);
      }
    });
  };

  const handleApplyAllContact = (contactObject: any, type: any) => {
    const updatedJurisdictions = jurisdictions?.map((jurisdiction) => ({
      ...jurisdiction,
      contacts: jurisdiction.contacts?.map((contactItem: any) => {
        if (contactItem.type === type) {
          return { ...contactItem, contact: contactObject.id };
        }
        return contactItem;
      }),
    }));

    setJurisdictions(updatedJurisdictions);
  };

  const handleApplyContactAdditional = (
    jurisdictionIds: any,
    contactObject: any,
    type: any
  ) => {
    jurisdictionIds.forEach((jurisdictionId: any) => {
      const jurisdictionIndex = jurisdictions.findIndex(
        (jurisdiction) => jurisdiction.jurisdiction === jurisdictionId.id
      );

      if (jurisdictionIndex !== -1) {
        const updatedJurisdictions = [...jurisdictions];
        updatedJurisdictions[jurisdictionIndex].contacts = updatedJurisdictions[
          jurisdictionIndex
        ].contacts?.map((contactItem: any) => {
          if (Array.isArray(contactItem)) {
            contactItem.push({
              id: contactObject.id,
              name: contactObject.name,
            });
          }
          return contactItem;
        });

        setJurisdictions(updatedJurisdictions);
      }
    });
  };

  const handleApplyAllContactAdditional = (contactObject: any) => {
    const updatedJurisdictions = jurisdictions?.map((jurisdiction) => {
      if (jurisdiction.jurisdiction !== jurisdictionId) {
        const updatedContacts = jurisdiction.contacts?.map(
          (contactItem: any) => {
            if (Array.isArray(contactItem)) {
              contactItem.push({
                id: contactObject.id,
                name: contactObject.name,
              });
            }
            return contactItem;
          }
        );

        return {
          ...jurisdiction,
          contacts: updatedContacts,
        };
      }
      return jurisdiction;
    });

    setJurisdictions(updatedJurisdictions);
  };

  useEffect(() => {
    const updatedJurisdictions = state?.firmContact?.jurisdictions?.map(
      (jurisdiction: any) => {
        const primaryContact = jurisdiction.contacts?.find(
          (contact: any) => contact.type === "primaryContact"
        ) || { contact: "" };
        const secondaryContact = jurisdiction.contacts?.find(
          (contact: any) => contact.type === "secondaryContact"
        ) || { contact: "" };
        const marketingContact = jurisdiction.contacts?.find(
          (contact: any) => contact.type === "marketingContact"
        ) || { contact: "" };
        const accountingContact = jurisdiction.contacts?.find(
          (contact: any) => contact.type === "accountingContact"
        ) || { contact: "" };
        const itContact = jurisdiction.contacts.find(
          (contact: any) => contact.type === "itContact"
        ) || { contact: "" };
        const socialMediaContact = jurisdiction.contacts?.find(
          (contact: any) => contact.type === "socialMediaContact"
        ) || { contact: "" };
        const hrContact = jurisdiction.contacts?.find(
          (contact: any) => contact.type === "hrContact"
        ) || { contact: "" };

        const additionalContacts = jurisdiction.contacts
          .filter((contact: any) => contact.type === "additionalContact")
          .map((contact: any) => ({
            id: contact.contact,
            name:
              contacts?.find((c: any) => c.id === contact.contact)?.name ?? "",
          }));

        const newContacts = {
          primaryContact: {
            type: "primaryContact",
            contact: primaryContact.contact,
          },
          secondaryContact: {
            type: "secondaryContact",
            contact: secondaryContact.contact,
          },
          marketingContact: {
            type: "marketingContact",
            contact: marketingContact.contact,
          },
          accountingContact: {
            type: "accountingContact",
            contact: accountingContact.contact,
          },
          itContact: {
            type: "itContact",
            contact: itContact.contact,
          },
          socialMediaContact: {
            type: "socialMediaContact",
            contact: socialMediaContact.contact,
          },
          hrContact: {
            type: "hrContact",
            contact: hrContact.contact,
          },
          additionalContacts: additionalContacts?.map((contact: any) => ({
            id: contact.id,
            name: contact.name,
          })),
        };

        return {
          jurisdiction: jurisdiction.jurisdiction,
          contacts: Object.values(newContacts),
        };
      }
    );

    setJurisdictions(updatedJurisdictions);
  }, [state?.firmContact.jurisdictions, contacts]);

  useEffect(() => {
    let newErrorFields: string[] = [];
    if (activeErrors.primaryContact) {
      newErrorFields.push("Primary Contact");
    }
    if (activeErrors.secondaryContact) {
      newErrorFields.push("Secondary Contact");
    }
    if (activeErrors.marketingContact) {
      newErrorFields.push("Marketing Contact");
    }
    if (activeErrors.accountingContact) {
      newErrorFields.push("Accounting Contact");
    }

    setErrorFields(newErrorFields);
  }, [activeErrors]);

  useEffect(() => {
    const active: any = jurisdictions?.find(
      (jur) => jur.jurisdiction === jurisdictionId
    );

    const primaryContact = active?.contacts?.find(
      (contact: any) => contact.type === "primaryContact"
    ) || { contact: "" };
    const secondaryContact = active?.contacts?.find(
      (contact: any) => contact.type === "secondaryContact"
    ) || { contact: "" };
    const marketingContact = active?.contacts?.find(
      (contact: any) => contact.type === "marketingContact"
    ) || { contact: "" };
    const accountingContact = active?.contacts?.find(
      (contact: any) => contact.type === "accountingContact"
    ) || { contact: "" };
    const itContact = active?.contacts?.find(
      (contact: any) => contact.type === "itContact"
    ) || { contact: "" };
    const socialMediaContact = active?.contacts?.find(
      (contact: any) => contact.type === "socialMediaContact"
    ) || { contact: "" };
    const hrContact = active?.contacts?.find(
      (contact: any) => contact.type === "hrContact"
    ) || { contact: "" };

    setSelectedContacts({
      ...selectedContacts,
      primaryContact:
        contacts?.find((contact) => contact.id === primaryContact?.contact) ??
        "",
      secondaryContact:
        contacts?.find((contact) => contact.id === secondaryContact?.contact) ??
        "",
      marketingContact:
        contacts?.find((contact) => contact.id === marketingContact?.contact) ??
        "",
      accountingContact:
        contacts?.find(
          (contact) => contact.id === accountingContact?.contact
        ) ?? "",
      itContact:
        contacts?.find((contact) => contact.id === itContact?.contact) ?? "",
      socialMediaContact:
        contacts?.find(
          (contact) => contact.id === socialMediaContact?.contact
        ) ?? "",
      hrContact:
        contacts?.find((contact) => contact.id === hrContact?.contact) ?? "",
    });

    const addCon = active?.contacts[7];
    const additionalContacts =
      addCon?.map((contact: any) => ({
        id: contact.id,
        name: contacts?.find((c: any) => c.id === contact.id)?.name ?? "",
      })) ?? [];

    setSelectedAdditionalContacts(additionalContacts);
  }, [jurisdictions, jurisdictionId]);

  const dropdowns = [
    {
      key: "primaryContact",
      title: "Primary Contact",
      selectedObjst: selectedContacts.primaryContact,
      required: true,
      error: activeErrors.primaryContact,
      errorMsj: activeErrorsMessage.primaryContact,
    },
    {
      key: "secondaryContact",
      title: "Secondary Contact",
      selectedObjst: selectedContacts.secondaryContact,
      required: true,
      error: activeErrors.secondaryContact,
      errorMsj: activeErrorsMessage.secondaryContact,
    },
    {
      key: "marketingContact",
      title: "Marketing Contact",
      selectedObjst: selectedContacts.marketingContact,
      required: true,
      error: activeErrors.marketingContact,
      errorMsj: activeErrorsMessage.marketingContact,
    },
    {
      key: "accountingContact",
      title: "Accounting Contact",
      selectedObjst: selectedContacts.accountingContact,
      required: true,
      error: activeErrors.accountingContact,
      errorMsj: activeErrorsMessage.accountingContact,
    },
    {
      key: "itContact",
      title: "IT Contact",
      selectedObjst: selectedContacts.itContact,
    },
    {
      key: "socialMediaContact",
      title: "Social Media Contact",
      selectedObjst: selectedContacts.socialMediaContact,
    },
    {
      key: "hrContact",
      title: "HR Contact",
      selectedObjst: selectedContacts.hrContact,
    },
  ];

  useEffect(() => {
    if (!jurisdictionsIds) return;
    setJurisdictionsObjects(jurisdictionsIds);
  }, [jurisdictionsIds]);

  const checkOtherJurisdictionsContacts = () => {
    const jurisdictionsWithErrors = jurisdictions?.map((jurisdiction) => {
      const primaryContact = jurisdiction?.contacts.find(
        (contact: any) => contact.type === "primaryContact"
      ) || { contact: "" };
      const secondaryContact = jurisdiction?.contacts.find(
        (contact: any) => contact.type === "secondaryContact"
      ) || { contact: "" };
      const marketingContact = jurisdiction?.contacts.find(
        (contact: any) => contact.type === "marketingContact"
      ) || { contact: "" };
      const accountingContact = jurisdiction?.contacts.find(
        (contact: any) => contact.type === "accountingContact"
      ) || { contact: "" };

      if (
        primaryContact.contact === "" ||
        secondaryContact.contact === "" ||
        marketingContact.contact === "" ||
        accountingContact.contact === ""
      ) {
        return jurisdiction;
      }

      return null;
    });

    const filteredJurisdictions = jurisdictionsWithErrors.filter(
      (jurisdiction) =>
        jurisdiction !== null && jurisdiction.jurisdiction !== jurisdictionId
    );
    if (filteredJurisdictions.length > 0) {
      const jurIds = filteredJurisdictions?.map((jur) => jur.jurisdiction);
      const jurNames = jurIds?.map(
        (jur) => jurisdictionData.find((j) => j.id === jur).name
      );
      const errorMessage = `Check Other Jurisdiction Contacts:`;
      const errorMessages = [errorMessage, ...jurNames];
      setErrorJurisdiction(errorMessages);
    }

    return filteredJurisdictions;
  };

  useEffect(() => {
    setErrorJurisdiction([]);
    if (checked) {
      checkOtherJurisdictionsContacts();
    }
  }, [jurisdictionId]);

  const handleUpdate = async () => {
    try {
      setChecked(true);
      const jurisdictionsWithErrors = checkOtherJurisdictionsContacts();

      if (buttonClicked !== "saveOverride") {
        const primaryInput =
          selectedContacts.primaryContact.name === undefined ||
          selectedContacts.primaryContact.name === "";
        const secondaryInput =
          selectedContacts.secondaryContact.name === undefined ||
          selectedContacts.secondaryContact.name === "";
        const accountingInput =
          selectedContacts.accountingContact.name === undefined ||
          selectedContacts.accountingContact.name === "";
        const marketingInput =
          selectedContacts.marketingContact.name === undefined ||
          selectedContacts.marketingContact.name === "";

        const newActiveErrors = {
          primaryContact: primaryInput,
          secondaryContact: secondaryInput,
          marketingContact: marketingInput,
          accountingContact: accountingInput,
        };

        setActiveErrors(newActiveErrors);

        if (
          newActiveErrors.primaryContact ||
          newActiveErrors.secondaryContact ||
          newActiveErrors.marketingContact ||
          newActiveErrors.accountingContact
        )
          return;

        if (jurisdictionsWithErrors.length > 0) {
          return;
        }
      }

      const finalJurisdictions: any = jurisdictions?.map((jurisdiction) => {
        const additionalContacts =
          jurisdiction.contacts[7].map((contact: any) => ({
            type: "additionalContact",
            contact: contact.id,
          })) ?? [];

        const nonEmptyAdditionalContacts = additionalContacts.filter(
          (contact: any) => contact.contact !== ""
        );

        // Return the updated jurisdiction object with modified additionalContacts
        return {
          ...jurisdiction,
          contacts: [
            ...jurisdiction.contacts.slice(0, 7),
            ...nonEmptyAdditionalContacts,
          ],
        };
      });

      const updatedData: IFirmContact = {
        id: state?.firmContact?.id,
        firm: state?.firmContact?.firm,
        firmName: state?.firmContact?.firmName,
        contacts: state?.firmContacts?.contacts,
        level: false,
        jurisdictions: finalJurisdictions,
      };

      const response = await update.firmContact({firmContact: updatedData, pathname, prevState: state.firmContact});
      if (response.status === 200) {
        setTimeout(() => {
          navigate(`/Staff/ManageFirm/GeneralTab/${state.id}`, {
            state: { ...state, selectedTab: "GeneralTab" },
          });
        }, 500);
      } else {
        console.error("Error al crear el elemento:", response.data);
      }
    } catch (error: any) {
      console.error("Error al crear el elemento:", error);
    }
  };

  useEffect(() => {
    if (checked) {
      validateForm();
    }
  }, [selectedContacts]);

  const validateForm = () => {
    const primaryInput = selectedContacts.primaryContact.name === undefined;
    const secondaryInput = selectedContacts.secondaryContact.name === undefined;
    const accountingInput =
      selectedContacts.accountingContact.name === undefined;
    const marketingInput = selectedContacts.marketingContact.name === undefined;

    setActiveErrors((prevState) => ({
      ...prevState,
      primaryContact: primaryInput,
      secondaryContact: secondaryInput,
      marketingContact: marketingInput,
      accountingContact: accountingInput,
    }));
  };

  return (
    <div className=" flex flex-col gap-10 lg:pt-0 pt-6">
      <div className=" flex flex-col gap-12 lg:gap-5">
        {dropdowns?.map((dropdown, index) => (
          <div key={index} className="grid col-span-3 gap-1 w-full">
            <JurisdictionContact
              title={dropdown.title}
              selectedObjst={dropdown.selectedObjst?.name ?? ""}
              object={dropdown.selectedObjst}
              type={dropdown.key}
              getid={true}
              seter={(id: string) => handleDropDownChange(dropdown.key, id)}
              error={dropdown.error ?? false}
              errorMsj={dropdown.errorMsj ?? ""}
              required={dropdown.required ?? false}
              contacts={contacts}
              onApplyContact={handleApplyContact}
              onApplyAllContact={handleApplyAllContact}
              jurisdictions={jurisdictionsObjects}
              jurisdictionId={jurisdictionId}
            />
          </div>
        ))}
        {selectedAdditionalContacts?.map((dropdown, index) => (
          <div key={index} className="grid col-span-3 gap-1 w-full ">
            <JurisdictionContact
              title={"Additional Contact " + (index + 1)}
              getid={true}
              object={dropdown}
              selectedObjst={selectedAdditionalContacts[index].name}
              seter={(id: string) => handleDropDownChangeAdditional(index, id)}
              error={false}
              contacts={contacts}
              onApplyContact={handleApplyContactAdditional}
              onApplyAllContact={handleApplyAllContactAdditional}
              jurisdictions={jurisdictionsObjects}
              jurisdictionId={jurisdictionId}
            />
          </div>
        ))}
      </div>

      <div className="w-full md:w-min ">
        <NewButton
          text={`NEW CONTACT`}
          color="accent"
          type="button"
          style="filled"
          size="large"
          content="textIcon"
          onClick={handleAddNewContact}
          icon={
            <Plus2 className="w-5 h-5 shrink-0 relative overflow-visible" />
          }
        />
      </div>

      <div className="flex justify-end self-stretch w-full">
        <div className="flex w-full md:w-min gap-4">
          <NewButton
            text={`CANCEL`}
            color="neutral"
            content="textIcon"
            type="button"
            style="outlined"
            size="medium"
            className=""
            icon={<Close />}
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className="w-full min-w-[8.75rem]">
            <FirmContactButton
              text={`SAVE`}
              color="accent"
              style="filled"
              size="medium"
              content="textIcon"
              className=" "
              icon={<SaveChanges />}
              onClick={handleUpdate}
              ref={saveButtonRef} // This line assigns the ref
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMembership;
