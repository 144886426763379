import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../../assets/css/AddCatalogs.css";
import GoBack from "../../../../components/Moleculas/GoBack";
import CatalogSubTitle from "../../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import CatalogTitle from "../../../../components/Moleculas/Catalog Text/CatalogTitle";
import BackgroundComponent from "../../../../components/Layout/BackgroundProfile/BackgroundProfile";
import ManageFirmBackground from "../../../../assets/images&icons/bg-manageFirm.png";
import CurrentPage from "../../../../components/Layout/CurrentPageTab/CurrentPage";
import DataAdminContact from "./DataAdminContact";
import NewButton from "../../../../components/Atomos/Buttons/newButton";
import Plus2 from "../../../../components/Atomos/Icons/Plus";
import ButtonsCrud from "../../../../components/Catalogs/ButtonsCrud";
import { getDataAdminByFirm } from "../../../../Services/DataAdmin";
import WarningModal from "../../../../components/Atomos/Modals/WarningModal";
import { fetchPersonByFirm } from "../../../../Services/Person";
import { useDispatch, useSelector } from "react-redux";
import { Appstore } from "../../../../redux";
import { useUpdateAndLog } from "../../../../hooks/LogActivity/LogActivityUpdates";
import { useCreateAndLog } from "../../../../hooks/LogActivity/LogActivityCreates";
import { useDeleteAndLog } from "../../../../hooks/LogActivity/LogActivityDeletes";
import { hideLoader, showLoader } from "../../../../redux/loaderActions";
import { UseJurisdictionGroupList } from "../../../../hooks/Catalogs/useJurisdictionGroup";

const WSGDots = require("../../../../assets/images&icons/WSGDots.svg");

const ManageDataAdmin: React.FC = () => {
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [dataAdminList, setDataAdminList] = useState<any[]>(
    state.dataAdminList
  );
  const [personList, setPersonList] = useState<any[]>(state.personList);
  const [deleteList, setDeleteList] = useState<any[]>([]);
  const [save, setSave] = useState(false);
  const [errors, setErrors] = useState<any[]>([]);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [showModal, setShowModal] = useState<boolean>(state.showModal);
  const [defaultMembershipLocation, setDefaultMembershipLocation] = useState({id:'',name:''});  
  const { pathname } = useLocation();
  const update = useUpdateAndLog();
  const create = useCreateAndLog();
  const erase = useDeleteAndLog();
  const dispatch = useDispatch();
  const { jurisdictionGroupData } = UseJurisdictionGroupList();

  const checkEditType = (type: number, index: number) => {
    const updatedList = [...dataAdminList];
    type === 0 &&
      (updatedList[index] = {
        ...updatedList[index],
        type: type,
        membershipLocationName: defaultMembershipLocation.name,
        membershipLocation: defaultMembershipLocation.id,
        updated: true,
      });
    type === 1 &&
      (updatedList[index] = {
        ...updatedList[index],
        type: type,
        updated: true,
        membershipLocation: '',
        membershipLocationName: '',
      });
    setDataAdminList(updatedList);
  };

  const handleCheckBoxDataSubscribeEmails = (
    checked: boolean,
    index: number
  ) => {
    const updatedList = [...dataAdminList];
    updatedList[index] = {
      ...updatedList[index],
      subscription: checked,
      updated: true,
    };
    setDataAdminList(updatedList);
  };

  const handleSetSelectedMembershipLocation = (e: any, index: number) => {
    const updatedList = [...dataAdminList];    
    updatedList[index] = {
      ...updatedList[index],
      membershipLocationName: e.name,
      membershipLocation: e.id,
      updated: true,
    };
    setDataAdminList(updatedList);
  };

  const handleSetSelectedPerson = (e: any, index: number) => {
    const updatedList = [...dataAdminList];
    updatedList[index] = {
      ...updatedList[index],
      person: e.id,
      personName: e.name,
      updated: true,
    };
    setDataAdminList(updatedList);
  };

  const handleErrors = () => {
    const updatedList = [...dataAdminList];
    const newErrors = [...errors];

    updatedList.forEach((dataAdmin: any, index: number) => {
      if (dataAdmin.person === "") {
        newErrors[index].personError = true;
      } else {
        newErrors[index].personError = false;
      }
      if (dataAdmin.membershipLocation === "" && dataAdmin.type !== 0) {
        newErrors[index].membershipLocationError = true;
      } else {
        newErrors[index].membershipLocationError = false;
      }
    });

    updatedList.forEach((dataAdmin: any, index: number) => {
      const isDuplicateEntry = isDuplicate(dataAdmin.person, dataAdmin.membershipLocation, index);
      if (isDuplicateEntry) {
        newErrors[index].personDuplicityError = true;
      } else {
        newErrors[index].personDuplicityError = false;
      }
    });
    setErrors(newErrors);
  };

  const handleSave = async () => {
    setSave(true);
    handleErrors();
    if (checkIfErrorsExist()) return;
  
    try {
      const updateAndCreatePromises = dataAdminList.map( 
        async (dataAdmin: any) => {
          if (!dataAdmin.new) {
            const prevState = state.dataAdminList.find((d: any) =>d.id === dataAdmin.id);
            delete dataAdmin.updated;
            if (!manageFirm.selectedFirm) return
            await update.dataAdmin({dataAdmin, pathname, prevState});
          } else if (dataAdmin.new) {
            delete dataAdmin.new;
            delete dataAdmin.updated;
            if (!manageFirm.selectedFirm) return
            await create.dataAdmin({dataAdmin, pathname});
          }
        }
      );
      const deletePromises = deleteList.map(async (id) => {
        if (!manageFirm.selectedFirm) return;
        const dataAdminToDelete = state.dataAdminList.find(
          (d: any) => d.id === id
        );
        await erase.dataAdmin({ dataAdmin: dataAdminToDelete, pathname });
      });
      await Promise.all([...updateAndCreatePromises, ...deletePromises]);
      if (showModal) {
        delete state.showModal;
        navigate(
          `/Staff/ManageFirm/Professionals/${manageFirm.selectedFirm?.id}`
        );
      } else
        navigate(`/Staff/ManageFirm/GeneralTab/${manageFirm.selectedFirm?.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const isDuplicate = (person: string, membershipLocation: string, index: number) => {
    return dataAdminList.some(
      (dataAdmin, i) =>
        i !== index &&
        dataAdmin.person === person &&
        dataAdmin.membershipLocation === membershipLocation
    );
  };

  const addToDataAdminList = () => {
    const updatedList = [...dataAdminList];
    updatedList.push({
      person: "",
      personName: "",
      membershipLocation: defaultMembershipLocation.id,
      membershipLocationName: defaultMembershipLocation.name,
      firm: manageFirm.selectedFirm?.id,
      type: 0,
      subscription: false,
      new: true,
    });
    const updatedErrors = [
      ...errors,
      { personError: false, membershipLocationError: false },
    ];

    setErrors(updatedErrors);
    setDataAdminList(updatedList);
  };

  const handleDelete = (index: number) => {
    const updatedList = [...dataAdminList];
    const updatedErrors = [...errors];
    updatedErrors.splice(index, 1);
    const id = updatedList[index].id;
    updatedList.splice(index, 1);
    setErrors(updatedErrors);
    setDataAdminList(updatedList);
    if (id) setDeleteList([...deleteList, id]);
  };

  useEffect(() => {
    const membershipLocation =
    manageFirm.selectedFirm?.membershipType === "Primary"
      ? {
          id: manageFirm.selectedFirm?.jurisdiction,
          name: manageFirm.selectedFirm?.jurisdictionName,
        }
      : jurisdictionGroupData.find(
          (jurisdictionGroup: any) =>
            jurisdictionGroup.id ===
            manageFirm.selectedFirm?.jurisdictionGroup
        );
        setDefaultMembershipLocation(membershipLocation);
  }, [manageFirm.selectedFirm]);

  useEffect(() => {
    if (save) handleErrors();
  }, [dataAdminList]);

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async () => {
    try {
      await Promise.all([fetchDataAdmin(), fetchPerson()]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (dataAdminList?.length) {
      const errors = dataAdminList?.map(() => {
        return {
          personError: false,
          personDuplicityError: false,
          membershipLocationError: false,
        };
      });
      setErrors(errors);
    }
  }, [dataAdminList]);

  const fetchDataAdmin = async () => {
    if (manageFirm.selectedFirm) {
      const data = await getDataAdminByFirm(manageFirm.selectedFirm.id);
      if (data.length) setDataAdminList(data);
    }
  };

  const fetchPerson = async () => {
    if (manageFirm.selectedFirm) {
      const data = await fetchPersonByFirm(manageFirm.selectedFirm.id);
      setPersonList(data);
    }
  };


  const checkIfErrorsExist = () => {
    return errors?.some(
      (error) => error.personError || error.membershipLocationError || error.personDuplicityError
    );
  };

  useEffect(() => {
    const hasErrors = checkIfErrorsExist();
    setDisableSaveButton(hasErrors);
  }, [errors]);

  return (
    <>
      <WarningModal 
        showModal={showModal}
        setShowModal={setShowModal}
        title="Update Data Admin"
        message="Save your changes to return to the Professionals Tab. Leaving / clicking ‘Cancel’ without saving will discard all changes."
      />
      <BackgroundComponent image={ManageFirmBackground} />

      <div className="mainSection">
        <div className="flex flex-col pt-7 gap-5 md:gap-6 lg:gap-7 md:pt-8 lg:pt-10 pb-5">
          <div className="flex gap-4 justify-between">
            <CatalogTitle
              title={`Manage Data Admin`}
              className="text-white !p-0 lg:text-[52px] md:text-[44px] text-[42px]"
            />
            <div
              className="md:min-w-[20rem] min-h[4.5rem] overflow-hidden flex-1 hidden relative md:flex"
              style={{
                backgroundImage: `url(${WSGDots.default})`,
              }}
            ></div>
            <div className="text-white relative">
              <GoBack className="!text-white !fill-white" />
            </div>
          </div>
          <hr className="flex h-0 justify-end items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage
              prevPage="Manage Firm > General"
              currentPage={`Data Admin`}
            />
          </div>
        </div>

        <div className="relative flex flex-col gap-10 justify-end">
          <article className="articleSection">
            <section className="formsectionNTitle">
              <div className="flex flex-col md:flex-row w-full justify-between gap-2">
                <CatalogSubTitle
                  title="Data Admin Information"
                  className="text-Default text-[26px] md:text-[34px] lg:text-[42px] not-italic font-medium lg:leading-[44px] md:leading-[48px] leading-normal"
                />
                <NewButton
                  className="w-full md:max-w-fit"
                  text="New Data Admin"
                  color="accent"
                  style="filled"
                  size="large"
                  content="textIcon"
                  icon={<Plus2 />}
                  onClick={addToDataAdminList}
                />
              </div>
              <span className="text-xl font-bold leading-normal not-italic text-gray-font">
                {manageFirm.selectedFirm?.firmName}
              </span>

              {dataAdminList?.map((dataAdmin: any, index: number) => (
                <DataAdminContact
                  key={index}
                  personName={
                    dataAdmin.personName ??
                    personList?.find(
                      (person: any) => person.id === dataAdmin.person
                    )?.name
                  }
                  membershipLocationName={dataAdmin.membershipLocationName}
                  type={dataAdmin.type}
                  emails={dataAdmin.subscription}
                  personList={personList}
                  jurisdictionList={state?.jurisdictionList}
                  handleSetSelectedPerson={(e) =>
                    handleSetSelectedPerson(e, index)
                  }
                  handleSetSelectedMembershipLocation={(e) =>
                    handleSetSelectedMembershipLocation(e, index)
                  }
                  checkEditType={(type) => checkEditType(type, index)}
                  handleCheckBoxDataSubscribeEmails={(checked) =>
                    handleCheckBoxDataSubscribeEmails(checked, index)
                  }
                  handleDelete={() => handleDelete(index)}
                  primary={manageFirm.selectedFirm?.membershipType === "Primary"}
                  personError={errors[index]?.personError}
                  personDuplicityError={errors[index]?.personDuplicityError}
                  membershipLocationError={
                    errors[index]?.membershipLocationError
                  }
                />
              ))}
            </section>

            <ButtonsCrud
              mode="edit"
              hiddenDelete={true}
              actionButton={() => {
                handleSave();
              }}
              disabled={disableSaveButton}
              id={""}
              onDelete={() => {}}
              Catalog={""}
              redirectUrl={
                showModal
                  ? `/Staff/ManageFirm/Professionals/${manageFirm.selectedFirm?.id}`
                  : `/Staff/ManageFirm/GeneralTab/${manageFirm.selectedFirm?.id}`
              }
              state={state.dataAdminList}
              newState={dataAdminList}
              hideFields
            />
          </article>
        </div>
      </div>
    </>
  );
};

export default ManageDataAdmin;
