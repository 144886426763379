import React, { useEffect, useState } from "react";
import useDataLoader from "../../hooks/useDataLoader";
import Axios from "../../utils/axios";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import AOPRankingFilter from "../../components/Catalogs/Filters/AOPRankingFilter";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import aopRankingBg from "../../../src/assets/images&icons/aopRankingBg.jpeg";
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import { useTableSettings } from "../../hooks/Table/UseTableSettings";
import TableWithPagination from "../../components/Catalogs/Tables/TableWithPagination/TableWithPagination";
import {
  ColumnConfig,
  useColumnConfig,
} from "../../hooks/Table/UseColumnSettings";
import { Link } from "react-router-dom";
import ShapeEdit from "../../components/Atomos/Icons/ShapeEdit";
import { UseFirmList } from "../../hooks/Catalogs/useFirm";
import { transformData } from "../../hooks/Table/TransformColumnsForExport";
import { defaultPageSize } from "../../utils/const";

const AopRankingModule: React.FC = ({ ...props }) => {
  const axios = new Axios();
  const Headers: string[] = [
    "person",
    "firm",
    "rank_1",
    "rank_2",
    "rank_3",
    "rank_4",
  ];

  const {
    sort,
    setSort,
    currentPage,
    setCurrentPage,
    recordsPerPage,
    setRecordsPerPage,
    filter,
    setFilter,
    queryParams,
  } = useTableSettings({ defaultPageSize });
  const [pageHeaders, setPageHeaders] = useState<string[]>([]);
  const [personData, setPersonData] = useState<any[]>([]);
  const [tableInstance, setTableInstance] = useState<any>();
  const [selectedFirm, setSelectedFirm] = useState<any[]>([]);
  const [nameFilter, setNameFilter] = useState("");
  const { generateColumns } = useColumnConfig(Headers);
  const { firmData } = UseFirmList();

  const { data, totalCount, isLoading } = useDataLoader<any>({
    endpoint: "/AOPRanking",
    queryParams,
    countOnly: true,
  });

  useEffect(() => {
    if (pageHeaders.length === 0) {
      setPageHeaders(Headers);
    }
    fetchPersonData();
  }, []);

  const clearSelections = async () => {
    setFilter([]);
    setSelectedFirm([]);
    setNameFilter("");
    setCurrentPage(1);
    setSort({ field: "_ts", order: "desc" });
  };

  const SubmitFilters = () => {
    setFilter([
      { field: "name", value: nameFilter },
      { field: "firm", value: selectedFirm.map((f) => f.id).join(":") },
    ]);
  };

  const fetchPersonData = async () => {
    try {
      const response = await axios.Get("./Person");
      const data = response.data;
      if (data != null) {
        setPersonData(data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const columnConfigs: ColumnConfig[] = [
    { id: "count", header: "#", enableSorting: false, size: 50 },
    { id: "person", header: "Name" },
    { id: "firm", header: "Firm" },
    { id: "rank_1", header: "Rank 1" },
    { id: "rank_2", header: "Rank 2" },
    { id: "rank_3", header: "Rank 3" },
    { id: "rank_4", header: "Rank 4" },
    {
      id: "edit",
      header: "Edit",
      enableSorting: false,
      cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={props.row.original}
        >
          Edit
          <div className="editsvg">
            <ShapeEdit />
          </div>
        </Link>
      ),
    },
  ];

  const columns = generateColumns(
    columnConfigs.map((column) => ({
      ...column,
      cell:
        column.cell ||
        ((props: any) => {
          if (column.id === "count") {
            return (
              <p>{props.row.index + 1 + (currentPage - 1) * recordsPerPage}</p>
            );
          } else if (column.id.startsWith("rank_")) {
            const rankIndex = parseInt(column.id.split("_")[1], 10) - 1;
            const ranks = props.row.original.ranks;
            return ranks && ranks[rankIndex] ? ranks[rankIndex].name : "";
          } else if (column.id === "firm") {
            const firm = firmData.find((f: any) => f.id === props?.getValue());
            return firm ? firm.name : "";
          } else if (column.id === "person") {
            return props.row.original.person.name;
          }
          return "";
        }),
    }))
  );

  const handleNameFilterChange = (newNameFilter: string) => {
    setNameFilter(newNameFilter);
  };

  const transformedData = transformData(
    data,
    columnConfigs,
    currentPage,
    recordsPerPage,
    firmData
  );

  return (
    <>
      <BackgroundComponent image={aopRankingBg} />

      <main className="mainSection">
        <div className="flex flex-col gap-5 lg:gap-6 lg:pb-[1.125rem] pb-5">
          <CatalogTitle title={`AOP Rankings`} className="text-white !pb-0" />
          <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage prevPage="HOME > SEO" currentPage={`AOP RANKING`} />
          </div>
        </div>
        <CatalogTableC
          noRedBox
          route={"AOPRANKING"}
          catalogName="Rankings"
          isfiltered={false}
          data={data}
          headers={Headers}
          totalCount={totalCount}
          noNewCatalog
          exportData={transformedData}
        >
          <AOPRankingFilter
            onNameFilterChange={handleNameFilterChange}
            nameFilter={nameFilter}
            headers={Headers}
            resetFilters={clearSelections}
            search={SubmitFilters}
            firmData={firmData}
            selectedFirm={selectedFirm}
            setSelectedFirm={setSelectedFirm}
          />
          <TableWithPagination
            data={data}
            columns={columns}
            totalCount={totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            recordsPerPage={recordsPerPage}
            setRecordsPerPage={setRecordsPerPage}
            setTableInstance={setTableInstance}
            sort={sort}
            setSort={setSort}
          />
        </CatalogTableC>
      </main>
    </>
  );
};

export default AopRankingModule;
