import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import '../../../assets/css/AddCatalogs.css';
import Input from "../../../components/Atomos/Inputs/Input";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import IContentTag from "../../../interfaces/models/IContentTag";
import { getCurrentTimestamp } from "../../../utils/functions";
import { useDispatch } from "react-redux";
import { useUpdateAndLog } from "../../../hooks/LogActivity/LogActivityUpdates";
import { useCreateAndLog } from "../../../hooks/LogActivity/LogActivityCreates";
import { useDeleteAndLog } from "../../../hooks/LogActivity/LogActivityDeletes";
import { AddContentTags, DeleteContentTags, UpdateContentTags } from "../../../redux/catalogs/states";

interface iCatalogProps {
  mode: "add" | "edit";
}
type Errors = { name: { error: boolean; message: string; }; }

const AddContentTag: React.FC<iCatalogProps> = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state, pathname } = useLocation();
  const [errors, setErrors] = useState<Errors>({ name: { error: false, message: '' } });
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [contentTag, setContentTag] = useState<IContentTag>(state);
  const [buttonClicked, setButtonClicked] = useState('');
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const dispatch = useDispatch();
  const update = useUpdateAndLog();
  const create = useCreateAndLog();
  const erase = useDeleteAndLog();

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); };
  }, []);

  useEffect(() => {
    if (contentTag?.name?.trim() !== '') {
      setErrors({ ...errors, name: { error: false, message: '' } });
    }
  }, [contentTag]);

  const validation = () => {

    const name = contentTag?.name?.trim() === '' ? { error: true, message: 'Name is required' } : { error: false, message: '' };

    setErrors({ name: name });
    setError(name.error);
    return name.error;
  }

  const handleDelete = async () => {
    try {
      await erase.contentTag({ contentTag: state, pathname });
      dispatch(DeleteContentTags(state.id ?? ""));
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  }

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (buttonClicked === 'saveOverride') {
      if (props.mode === 'add') {
        handleSave(e);
      }
      else {
        handleUpdate(e);
      }
    }

    if (buttonClicked === 'updateButton') {
      handleUpdate(e);
    } else if (buttonClicked === 'saveButton') {
      handleSave(e);
    }
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    if (buttonClicked !== 'saveOverride' && validation()) return
    try {
      const _contentTag = { ...contentTag, createdDate: getCurrentTimestamp() };
      const response = await create.contentTag({ contentTag: _contentTag, pathname});
      dispatch(AddContentTags(response));
      navigate(-1);
    } catch (error: any) {
      if (error.message.includes('Name')) {
        setErrors({ ...errors, name: { error: true, message: 'Name already exists' } });
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (errors.name.error)
      setSaveOverrideOpen(true)
    else {
      setSaveOverrideOpen(false)
      if (error)
        setError(false)
    }
  }
    , [errors]);

  const handleUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    if (buttonClicked !== 'saveOverride' && validation()) return
    try {
      await update.contentTag({ contentTag, pathname, prevState: state });
      dispatch(UpdateContentTags(contentTag));
      navigate(-1);
    } catch (error: any) {
      if (error.message.includes('Name')) {
        setErrors({ ...errors, name: { error: true, message: 'Name already exists' } });
      } else {
        console.error(error);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (error) validation();
    setContentTag({ ...contentTag, [e.target.name]: e.target.value });
  }

  const handleCloseSaveOverride = () => {
    setSaveOverrideOpen(!saveOverrideOpen);
  }

  useEffect(() => {
    const errorsToSet = Object.keys(errors).filter((clave) => errors[clave as keyof typeof errors].error);
    console.log(errorsToSet);
    setErrorFields(errorsToSet);
  }, [errors]);

  return (
    <>
      <div className={`mainSection ${saveOverrideOpen ? 'pt-7 md:pt-9 ' : ''}`}>
        <form onSubmit={handleSubmit}>
          <SaveOverride fields={errorFields} handleButtonClick={handleButtonClick} open={saveOverrideOpen} close={handleCloseSaveOverride} />

          <CatalogTitle title={`${props.mode === 'add' ? 'New Tag' : `Edit ${state?.name}`}`} className="text-Default">
            <GoBack />

          </CatalogTitle>
          <article className='articleSection '>
            <section className='formsectionNTitle '>
              <CatalogSubTitle title="Tag Information" className=" text-accent-Default-dark" />
              <div className="formsection flex w-full">
                <div className=" w-full">
                  <Input name="name" title="Name" value={contentTag?.name} required={true} error={errors.name.error} errorMsj={errors.name.message} onChange={handleInputChange} />
                </div>
              </div>
              <ButtonsCrud
                Catalog="ContentTag"
                mode={props.mode}
                id={id ? id : ""}
                disabled={errors.name.error}
                actionButton={handleButtonClick}
                onDelete={handleDelete}
                state={state}
                newState={contentTag}
              />
            </section>
          </article>
        </form>
      </div>
    </>
  );
}

export default AddContentTag;