import "../../../../assets/css/AddCatalogs.css";
import React, { useEffect, useState } from "react";
import BackgroundComponent from "../../../../components/Layout/BackgroundProfile/BackgroundProfile";
import ManageFirmBackground from "../../../../assets/images&icons/bg-manageFirm.png";
import CurrentPage from "../../../../components/Layout/CurrentPageTab/CurrentPage";
import CatalogTitle from "../../../../components/Moleculas/Catalog Text/CatalogTitle";
import GoBack from "../../../../components/Moleculas/GoBack";
import { useLocation } from "react-router-dom";
import CatalogSubTitle from "../../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import RadioButton from "../../../../components/Atomos/Radio/RadioButton";
import EditFirmWide from "./EditFirmWide";
import { Tab } from "@headlessui/react";
import EditMembershipLocation from "./EditMembershipLocation";
import SaveOverride from "../../../../components/Catalogs/SaveOverride";
const WSGDots = require("../../../../assets/images&icons/WSGDots.svg");

const ManageFirmContact: React.FC = () => {
  const { state } = useLocation();
  const [selectedOption, setSelectedOption] = useState(
    state?.firmContact.level ? 0 : 1
  );
  const [buttonClicked, setButtonClicked] = useState("");
  const [update, setUpdate] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    state?.firmContact?.jurisdictions
      ? state?.firmContact.jurisdictions[0]?.jurisdiction
      : ""
  );
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [errorJurisdiction, setErrorJurisdictions] = useState<string[]>([]);
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [jurisdictionsIds, setJurisdictionsIds] = useState<any[]>([]);

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };

  const checkEditType = (index: number) => {
    setSelectedOption(index);
  };

  useEffect(() => {
    if (errorFields.length > 0) {
      setSaveOverrideOpen(true);
    }
    if (errorFields.length === 0 && errorJurisdiction.length === 0) {
      setSaveOverrideOpen(false);
    }
  }, [errorFields]);

  useEffect(() => {
    if (errorJurisdiction.length > 0) {
      setSaveOverrideOpen(true);
    }
    if (errorJurisdiction.length === 0 && errorFields.length === 0) {
      setSaveOverrideOpen(false);
    }
  }, [errorJurisdiction]);

  const handleTabValue = (jurisdiction: any) => {
    setSelectedTab(jurisdiction);
  };

  const handleCloseSaveOverride = () => {
    setSaveOverrideOpen(!saveOverrideOpen);
  };

  useEffect(() => {
    const firmJurisdiction = state?.jurisdictionList?.filter(
      (jur: any) =>
        jur.jurisdictionGroup === state?.jurisdictionGroup ||
        jur.jurisdictionGroup === state?.jurisdictionGroupId
    );
    setJurisdictionsIds(firmJurisdiction);
  }, [state.jurisdictionList]);

  return (
    <>
      <BackgroundComponent image={ManageFirmBackground} />

      <div className=" mainSection">
        <div className="flex flex-col pt-7 gap-5 md:gap-6 lg:gap-7 md:pt-8 lg:pt-10 pb-5">
          <SaveOverride
            fields={[...errorFields, ...errorJurisdiction]}
            handleButtonClick={handleButtonClick}
            open={saveOverrideOpen}
            close={handleCloseSaveOverride}
            noSaveOverride={true}
          />
          <div className="flex gap-4 justify-between">
            <CatalogTitle
              title={`Edit Internal Contact`}
              className="text-white !p-0 lg:text-[52px] md:text-[44px] text-[42px]"
            />
            <div
              className="md:min-w-[20rem] min-h[4.5rem] overflow-hidden flex-1 hidden relative md:flex"
              style={{
                backgroundImage: `url(${WSGDots.default})`,
              }}
            ></div>
            <div className="text-white relative">
              <GoBack className="!text-white !fill-white" />
            </div>
          </div>
          <hr className="flex h-0 justify-end items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage
              prevPage="Manage Firm > General"
              currentPage={`Internal Firm Contact`}
            />
          </div>
        </div>
        <div className="relative flex flex-col gap-10 justify-end">
          <article className="articleSection">
            <section className="formsectionNTitle">
              <div className="flex flex-col md:flex-row w-full justify-between gap-2">
                <CatalogSubTitle
                  title={`Internal ${state.name} Contact for WSG`}
                  className="text-Default text-[26px] md:text-[34px] lg:text-[42px] not-italic font-medium lg:leading-[44px] md:leading-[48px] leading-normal"
                />
              </div>
            </section>
            <div className="flex flex-col lg:flex-row lg:items-center w-full gap-5">
              <span className="text-gray-font not-italic leading-6 font-bold text-[22px]">
                Firm Contact Structure:
              </span>
              <RadioButton
                index={0}
                text={"Contacts defined Firm Wide"}
                selected={selectedOption === 0}
                handleRadioButtonChange={checkEditType}
                disabled={true}
              />
              <RadioButton
                index={1}
                text={"Contacts defined by Membership Locations"}
                selected={selectedOption === 1}
                handleRadioButtonChange={checkEditType}
                disabled={true}
              />
            </div>
            <div className="w-full">
              {selectedOption === 0 && (
                <EditFirmWide
                  buttonClicked={buttonClicked}
                  update={update}
                  setUpdate={setUpdate}
                  firmContact={state.firmContact}
                  setErrorFields={setErrorFields}
                  state={state}
                />
              )}
              {selectedOption === 1 && (
                <>
                  <Tab.Group>
                    <Tab.List className={"grid md:grid-cols-3 lg:gap-0 lg:grid-cols-6 pb-4"}>
                      {state?.firmContact?.jurisdictions?.map(
                        (jurisdiction: any, index: number) => {
                          return (
                            <Tab
                              key={index}
                              onClick={() =>
                                handleTabValue(jurisdiction.jurisdiction)
                              }
                              className={` justify-center items-center col-span-1 bg-[#E5E3E4] max-h-[190px] text-gray-font ui-selected:text-neutral p-3 pr-2 ui-selected:bg-accent-Default-dark border-none rounded-none focus:border-none`}
                            >
                              <span
                                className={` not-italic text-lg leading-8 font-normal`}
                              >
                                {state?.jurisdictionList?.find(
                                  (jur: any) =>
                                    jur.id === jurisdiction.jurisdiction
                                )?.name ?? "undefined"}
                              </span>
                            </Tab>
                          );
                        }
                      )}
                    </Tab.List>
                  </Tab.Group>
                  <EditMembershipLocation
                    jurisdictionId={selectedTab}
                    jurisdictionName={
                      state?.jurisdictionList?.find(
                        (jur: any) => jur.id === selectedTab
                      )?.name
                    }
                    jurisdictionsIds={jurisdictionsIds}
                    setErrorFields={setErrorFields}
                    setErrorJurisdiction={setErrorJurisdictions}
                    buttonClicked={buttonClicked}
                  />
                </>
              )}
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default ManageFirmContact;
