const Accounting = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="2 2 20 20"
      className={props.className}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M8.65375 18.3462H9.84625V16.3463H11.8463V15.1538H9.84625V13.1538H8.65375V15.1538H6.65375V16.3463H8.65375V18.3462ZM13.6538 17.5962H18.3462V16.4038H13.6538V17.5962ZM13.6538 15.0963H18.3462V13.9038H13.6538V15.0963ZM6.90375 9.54625H11.5963V8.35375H6.90375V9.54625ZM4 21V4H21V21H4ZM5.5 19.5H19.5V5.5H5.5V19.5ZM14.6 11.2193L16 9.81925L17.4 11.2193L18.2385 10.3807L16.8385 8.95L18.2385 7.55L17.4 6.7115L16 8.1115L14.6 6.7115L13.7615 7.55L15.1615 8.95L13.7615 10.3807L14.6 11.2193Z"/>
    </svg>
  );
}

export default Accounting;
