import React, { useMemo, useState } from 'react';
import Axios from '../../utils/axios';
import AnimationLogo from '../../components/Layout/LoadingAnimation/AnimationLogo';
import useDataLoader from '../../hooks/useDataLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import Input from '../../components/Atomos/Inputs/Input';
import Modal from '../../components/Atomos/Modals/Modal';
import { IAreaOfPractice } from '../../interfaces/models/IAreaOfPractice';
import PracticeIndustriesMapping from '../../components/MappingAOP/AOPMapping';
import usePracticeIndustryMapping from '../../hooks/AOPMapping/useAopMapping';
import NewButton from '../../components/Atomos/Buttons/newButton';
import ButtonsCrud from '../../components/Catalogs/ButtonsCrud';
import WSGCheckbox from '../../components/Atomos/Checkbox/WSGCheckBox';
import GoBack from '../../components/Moleculas/GoBack';
import CatalogTitle from '../../components/Moleculas/Catalog Text/CatalogTitle';
import Rewind from '../../components/Atomos/Icons/Rewind';
import SwitchToogle from '../../components/Atomos/Switch-Toogle/Switch';
import CancelClear from '../../components/Atomos/Icons/CancelClear';
import CatalogSubTitle from '../../components/Moleculas/Catalog Text/CatalogSubTitle';
import { defaultPageSize } from '../../utils/const';
const axios = new Axios();

const MappingProcess: React.FC = ({ ...props }) => {
  const [sort, setSort] = useState<{ field: string; order: string }[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(defaultPageSize);
  const [filter, setFilter] = useState<{ field: string; value: string }[]>([]);
  const { state } = useLocation();
  const [checkedIgnored, setCheckedIgnored] = useState<boolean>(state.isIgnored ? state.isIgnored : false);
  const [inputValue, setInputValue] = useState('');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [nameRepeated, setNameRepeated] = useState<string>('');
  const [hasMerged, setHasMerged] = useState<boolean>(false);
  const navigate = useNavigate()
  const [confirmMerge, setConfirmMerge] = useState<boolean>(false)

  const {
    currentPracticeIndustries,
    setCurrentPracticeIndustries,
    currentAOPS,
    errors,
    handleCheckboxChange,
    prepareUpdatedIndustries,
    updateIndustries,
    updateErrors
  } = usePracticeIndustryMapping([], [], state);

  const queryParams = useMemo(() => {
    let params = [
      `pageNumber=${currentPage}`,
      `pageSize=${recordsPerPage}`,
      ...sort.map(s => `sort=${s.field}:${s.order}`),
      ...filter
        .filter(f => f.value !== "")
        .map(f => `filter=${f.field}:${f.value}`)
    ];
    return params;
  }, [currentPage, recordsPerPage, sort, filter]);

  const { isLoading } = useDataLoader<any>({
    endpoint: '/areaOfPractice',
    queryParams,
    countOnly: true,
  });

  // const normalizeName = (name: string) => name.replace(/\s+/g, ' ');

  const buildAOP = () => {
    return{
      ...state,
      name: inputValue.trim() !== '' ? inputValue.trim() : state.name,
      isIgnored: checkedIgnored,
      isMapped: true
    }
  }

  const handleSave = async () => {
    const selectedIndustries = currentPracticeIndustries.filter(industry => industry.isChecked);
    const isNameDuplicated = inputValue.trim() && currentAOPS.some(aop => aop.name === inputValue.trim());

    if (selectedIndustries.length === 0 && !checkedIgnored) {
      updateErrors("Please map or Ignore this AOP");
      return;
    }

    if (inputValue && selectedIndustries.length === 0 && !checkedIgnored) {
      updateErrors("Please Map or Ignore the AOP.");
      return;
    }

    if (!hasMerged && isNameDuplicated) {
      setNameRepeated(inputValue.trim());
      setIsModalOpen(true);
      return;
    }

    const newState = buildAOP();

    const { toAdd, toRemove } = prepareUpdatedIndustries(currentPracticeIndustries, selectedIndustries, newState);

    await updateAop(newState);
    await updateIndustries(toAdd, toRemove);

    if (hasMerged) {
      const aopToMergeWith = currentAOPS.find(aop => aop.name === inputValue);
      if (aopToMergeWith) {
        handleMergeNames()
        await axios.Delete('/areaOfPractice', aopToMergeWith.id)
        setHasMerged(false);
      }
      setConfirmMerge(true)

      setTimeout(() => {
        navigate('/Staff/AOP')
      }, 200)
      return
    }

    setTimeout(() => {
      navigate('/Staff/AOP')
    }, 200)
    setHasMerged(false)
  }

  const handleIgnoredChange = () => {
    setCheckedIgnored(!checkedIgnored);
    updateErrors(null);
  };

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
    updateErrors(null);
  }

  const updateAop = async (aop: IAreaOfPractice) => {
    try {
      await axios.Put('/areaOfPractice', aop)
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdateName = async () => {
    const updatedAOP = {
      ...state,
      name: inputValue,
      isIgnored: checkedIgnored,
      isMapped: true,
    };
    const selectedIndustries = currentPracticeIndustries.filter(industry => industry.isChecked);
    const { toAdd, toRemove } = prepareUpdatedIndustries(currentPracticeIndustries, selectedIndustries, updatedAOP);
    await updateAop(updatedAOP);
    await updateIndustries(toAdd, toRemove);
    setIsModalOpen(false);
    setTimeout(() => {
      navigate('/Staff/AOP')
    })
  };

  const handleMergeNames = async () => {
    const normalizedInputValue = (inputValue.trim());
    const aopToMergeWith = currentAOPS.find(aop => (aop.name) === normalizedInputValue);

    if (aopToMergeWith) {
      const combinedFirms = [...state.firms, ...aopToMergeWith.firms]
        .reduce((acc, current) => {
          if (!acc.some((firm: any) => firm.id === current.id)) {
            acc.push(current);
          }
          return acc;
        }, []);

      const combinedOffices = [...state.offices, ...aopToMergeWith.offices]
        .reduce((acc, current) => {
          if (!acc.some((office: any) => office.id === current.id)) {
            acc.push(current);
          }
          return acc;
        }, []);

      const updatedPracticeIndustries = currentPracticeIndustries.map(industry => {
        const hasDuplicatedAOP = industry.areasOfPractice?.some(ap => ap.id === aopToMergeWith.id);
        return {
          ...industry,
          isChecked: hasDuplicatedAOP || industry.isChecked,
        };
      });
      setCurrentPracticeIndustries(updatedPracticeIndustries)
      setCheckedIgnored(aopToMergeWith.isIgnored)

      if (hasMerged && confirmMerge) {
        const updatedAOP = {
          ...state,
          name: normalizedInputValue,
          firms: combinedFirms,
          offices: combinedOffices,
          isMapped: true,
        }
        updateAop(updatedAOP)
      };

    }
    setHasMerged(true);
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const CancelIcon = () => (
    <div className=''>
      <CancelClear />
    </div>
  )

  const MergeIcon = () => (
    <div className=''>
      <Rewind />
    </div>
  )



  return (
    <>
      {(isModalOpen) &&
      <div className='w-[18.75rem] lg:min-w-[34.5rem] flex relative h-auto'>
                <Modal isModalOpen={isModalOpen} toggleModal={handleCloseModal} title='Merge AOP' className='min-w-[18.75rem] md:min-w-[34.5rem] py-5 px-4 md:px-7 md:py-7 lg:px-10 lg:py-10'
          children={
            <div>
               <span className='text-left text-[#474F56] w-full flex mb-4  text-base lg:text-[1.125rem] font-normal leading-6'>The name {nameRepeated} already exists as an Area of Practice.</span>
                      <span className='text-left text-[#474F56] w-full flex mb-8  text-base leading-6 lg:text-[1.125rem] font-normal lg:leading-6'>Do you want to merge this mapping with the existent data?</span>
              <div className='flex justify-around flex-col gap-4 md:flex-row'>
                <NewButton onClick={() => setIsModalOpen(false)} text='CANCEL' color='neutral' style='outlined' content='textIcon' icon={<CancelIcon />} />
                <NewButton onClick={handleMergeNames} text='Merge AOP' color='primary' content='textIcon' icon={<MergeIcon />} />
              </div>
            </div>
          } />
        </div>
      }
      {isLoading ? (
        <div className='w-full h-screen flex items-center justify-center -mt-24'>
          <div className='w-full h-full bg-white'>
            <AnimationLogo />
          </div>
        </div>
      ) :
        <>
          <main className='mainSection'>
            <CatalogTitle title={`AOP`} className='text-Default'>
              <GoBack goBackTo="/Staff/AOP" />
            </CatalogTitle>
            <article className='articleSection'>
              <div className='flex flex-col items-start gap-[1.25rem] lg:gap-[1.5rem] self-stretch'>
                <div className=''>
                  <section className="formsectionNTitle">
                    <CatalogSubTitle className="text-accent-Default-dark" title={`${state.name}`} />
                  </section>
                </div>
                <div className='self-stretch flex-col flex gap-[1.25rem] items-start md:flex-row md:gap-5'>
                  <div className='flex gap-[.625rem] items-center self-stretch md:flex-col md:items-center'>
                    <label className='text-[#474F56] whitespace-nowrap text-base leading-[1.125rem] lg:text-[1.125rem] lg:leading-[1.125rem] roboto_font'>Ignore AOP</label>
                    <SwitchToogle checked={checkedIgnored} seter={handleIgnoredChange} small={true} centered />
                  </div>
                  <Input name='replaceText' value={inputValue} title='Rename AOP' error={false} onChange={handleInputChange} isWfull={true} />
                </div>
                <h4 className='text-[1.125rem] leading-[2rem] font-bold text-[#474F56] md:text-[1.25rem] md:leading-[1.5rem] lg:text-[1.375rem] lg:leading-[1.25rem]'>Practice Industries:</h4>
                <PracticeIndustriesMapping practiceIndustries={currentPracticeIndustries} onIndustryChange={handleCheckboxChange} />
              </div>
              {Object.keys(errors).map(key => (
                <div key={key} className='text-feedback-error' style={{ marginBottom: '10px' }}>
                  {errors[key]}
                </div>
              ))}
              <div className='w-full self-stretch justify-end flex'>
              <ButtonsCrud
                actionButton={handleSave}
                disabled={false}
                id={state.id ? state.id : ""}
                mode={"edit"}
                onDelete={() => ""}
                hiddenDelete={true}
                Catalog="AOP"
                state={state}
                newState={buildAOP()}
                />
              </div>
            </article>
          </main>
        </>
      }
    </>
  );
};

export default MappingProcess;