import { useState, useEffect } from 'react';
import Axios from '../../utils/axios'; 
import { IPracticeIndustry } from '../../interfaces/models/IPracticeIndustry';

const usePracticeIndustries = (initialStateId: string, currentAOP: { id: string; name: string }): [IPracticeIndustry[], (industries: IPracticeIndustry[]) => void] => {
  const [currentPracticeIndustries, setCurrentPracticeIndustries] = useState<IPracticeIndustry[]>([]);

  useEffect(() => {
    getPracticeIndustries();
  }, []);

  const getPracticeIndustries = async () => {
    try {
      const response = await new Axios().Get('/practiceIndustry');
      const data: IPracticeIndustry[] = response.data.map((industry: IPracticeIndustry) => ({
        ...industry,
        isChecked: industry.areasOfPractice?.some((aop:any) => aop.id === currentAOP.id) || false
      }));
      setCurrentPracticeIndustries(data);
    } catch (error:any) {
      console.log(error?.message);
    }
  };

  return [currentPracticeIndustries, setCurrentPracticeIndustries];
};

export default usePracticeIndustries;
