import React, { createContext, useEffect, useState } from 'react';
import Axios from '../utils/axios';
import { IPerson } from '../interfaces/models/IPerson';
import { getPersonByUserId } from '../Services/Person';

interface DecodedToken {
  given_name: string;
  family_name: string;
  name: string;
  oid: string;
  sub: string;
  access: string;
  emails: Array<string>;
  extension_WSG_Permissions?: string;
}

const axios = new Axios();

type AuthContextType = {
  isAuthenticated: boolean;
  tokenDecoded: DecodedToken | null;
  loggedPerson: IPerson | undefined; 
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
};

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  tokenDecoded: null,
  loggedPerson: undefined,
  setIsAuthenticated: () => {},
});

const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
};

const getCookie = (name: string) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loggedPerson, setLoggedPerson] = useState<IPerson | undefined>();
  const tokenDecodedString = sessionStorage.getItem("token_decoded") || getCookie("token_decoded");

  const tokenDecoded: DecodedToken | null = tokenDecodedString
    ? JSON.parse(tokenDecodedString)
    : null;

  const getUserInfo = async (userId: string) => {
    const response = await getPersonByUserId(userId);
    setLoggedPerson(response);
  };

  useEffect(() => {
    if (!tokenDecoded || loggedPerson) return
    getUserInfo(tokenDecoded.oid);
  }, [tokenDecoded, loggedPerson]);

  useEffect(() => {
    const isLoggedIn = () => {
      const token = sessionStorage.getItem('token') || getCookie('token');
      const authorizationHeader = 'Bearer ' + token;

      if (authorizationHeader.startsWith('Bearer ') && tokenDecoded) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    isLoggedIn();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, tokenDecoded, loggedPerson, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
