import { Link, useNavigate } from "react-router-dom";
import { formatTimeSpanToString } from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { Appstore, personSlice } from "../../../redux";
import NewButton from "../../../components/Atomos/Buttons/newButton";
import ManageIcon from "../../../components/Atomos/Icons/Manage";
import { useStaffMode } from "../../../hooks/StaffModeProvider";
import { capitalizeHeader } from "../../../utils/capitalize";
import { DataAdminType } from "../../../interfaces";
import Vector from "../../../components/Atomos/Icons/Vector";
import { UseJurisdictionList } from "../../../hooks/Catalogs/useJurisdiction";
import { UseJurisdictionGroupList } from "../../../hooks/Catalogs/useJurisdictionGroup";
import { useEffect, useState } from "react";
interface contentProps {
  dataAdminData: any[];
}

const DataAdminProfile: React.FC<contentProps> = ({ dataAdminData }) => {
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);
  const { isStaffMode } = useStaffMode();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jurisdictionData } = UseJurisdictionList();
  const { jurisdictionGroupData } = UseJurisdictionGroupList();
  const [jurisdictionList, setJurisdictionList] = useState<any[]>([]);

  useEffect(() => {
    let membershipLocation = [];
    if (manageFirm.selectedFirm?.membershipType === 'Primary') {
      membershipLocation = jurisdictionData.filter((jurisdiction: any) => jurisdiction.id === manageFirm.selectedFirm?.jurisdiction)
    } else {
      membershipLocation = jurisdictionData.filter((jurisdiction: any) => jurisdiction.jurisdictionGroup === manageFirm.selectedFirm?.jurisdictionGroup)
    }
    setJurisdictionList(membershipLocation)
  },[jurisdictionData, jurisdictionGroupData])

  return (
    <>
      <div className="w-full flex flex-col gap-4 filtersbox">
        <div className="text-[22px] font-bold">Data Admin Profile</div>
        {dataAdminData.slice(0,5).map((dataAdmin: any, index: number) => (
          <div key={dataAdmin.id}>
            <div key={index} className="grid grid-cols-4 flex gap-2">
              <div className="col-span-4 md:col-span-2 lg:col-span-1 ">
                <div className="font-semibold">Name</div>
                <Link
                  onClick={() =>
                    dispatch(personSlice.actions.ModifyPerson(dataAdmin))
                  }
                  className="link !text-lg"
                  to={`/Staff/ManageFirm/Professionals/${manageFirm.selectedFirm?.id}/Attorney/${dataAdmin.generatedId}`}
                >
                  {`${dataAdmin.name} ${dataAdmin.lastName}`}
                </Link>
              </div>
              <div className="col-span-4 md:col-span-2 lg:col-span-1 ">
                <div className="font-semibold">Type</div>
                <div>
                  {capitalizeHeader(DataAdminType[dataAdmin.type] ?? "")}
                </div>
              </div>
              <div className="col-span-4 md:col-span-2 lg:col-span-1 ">
                <div className="font-semibold">Email</div>
                <div>{dataAdmin.firmEmail}</div>
              </div>
              <div className="col-span-4 md:col-span-2 lg:col-span-1 ">
                <div className="font-semibold">Last Login</div>
                <div>{dataAdmin.lastLogin ? formatTimeSpanToString(dataAdmin.lastLogin) : ''}</div>
              </div>
            </div>
            <div>
              <Vector className="w-full h-0 stroke-gray-50 stroke-1" />
            </div>
          </div>
        ))}
        <div className="flex items-end justify-end w-full">
          <div className="w-full md:w-auto">
            <NewButton
              onClick={() =>
                navigate(`/Staff/ManageFirm/Professionals/${manageFirm.selectedFirm?.id}/DataAdmin`, {
                  state: {
                    ...manageFirm?.selectedFirm,
                    dataAdminList: dataAdminData,
                    jurisdictionList,
                    showModal:true
                  },
                })
              }
              text="Manage"
              className="text-lg"
              content="textIcon"
              icon={<ManageIcon />}
              color={isStaffMode ? "accent" : "primary"}
              size="medium"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default DataAdminProfile;
