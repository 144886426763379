import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IState, IStateErrors } from "../../interfaces";
import { Appstore, ModifyState } from "../../redux";
import { FormEvent, useEffect, useState } from "react";
import { ICatalogProps } from "../../interfaces/Catalogs/ICatalog";
import { UseCountryList } from '../../hooks/Catalogs/useCountry';
import { useLogging } from "../../Context/LoggingContext";
import { getCurrentTimestamp, getNameById } from "../../utils/functions";
import { UseRegionList } from "./useRegionListOptions";
import { useCreateAndLog } from "../LogActivity/LogActivityCreates";
import { useUpdateAndLog } from "../LogActivity/LogActivityUpdates";
import { AddState, UpdateState } from "../../redux/catalogs/states";

export const useStateHook = ({ mode }: ICatalogProps): any => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  //redux
  const dispatch = useDispatch();
  const currentState: IState = useSelector((store: Appstore) => store.state);
  const update = useUpdateAndLog();
  const create = useCreateAndLog();
  
  //error fields
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [errors, setErrors] = useState<IStateErrors>({
    name: { error: false, errorMsj: "" },
    abbreviation: { error: false, errorMsj: "" },
    country: { error: false, errorMsj: "" },
  });

  //list of countries
  const [selectedCountry, setSelectedCountry] = useState<string>("");

  //manage button clicked
  const [buttonClicked, setButtonClicked] = useState("");

  const { countryData } = UseCountryList();
  const { regionData } = UseRegionList();

  useEffect(() => {
    if (
      Object.keys(errors).filter(
        (key) => errors[key as keyof IStateErrors].error
      ).length > 0
    )
    validation();

    let newState = state
    if (state?.country && countryData.length) {
      const currentCountry = countryData.find((item: any) => item.id === state.country)
      if (currentCountry?.length) {
        newState = {
        ...state,
        country: currentCountry?.id
      }
    }
      setSelectedCountry(currentCountry?.name  || "");
    }

    dispatch(
      ModifyState(newState)
    )
  }, [countryData, state]);

  const validation = () => {
    let errors: IStateErrors = {
      name: { error: false, errorMsj: "" },
      abbreviation: { error: false, errorMsj: "" },
      country: { error: false, errorMsj: "" },
    };
    if (currentState.name?.trim() === "") {
      errors = {
        ...errors,
        name: { error: true, errorMsj: "Name is required" },
      };
    }
    if (currentState.abbreviation?.trim() === "") {
      errors = {
        ...errors,
        abbreviation: { error: true, errorMsj: "Abbreviation is required" },
      };
    }

    if (
      currentState.country === undefined ||
      selectedCountry === "" ||
      currentState.country.trim() === ""
    ) {
      errors = {
        ...errors,
        country: { error: true, errorMsj: "Country is required" },
      };
    }

    setErrors(errors);
    setSaveOverrideOpen(
      errors &&
      Object.keys(errors).filter(
        (key) => errors[key as keyof IStateErrors].error
      ).length !== 0
    );
    return (
      errors &&
      Object.keys(errors).filter(
        (key) => errors[key as keyof IStateErrors].error
      ).length !== 0
    );
  };

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const regions = countryData.find(c => c.name === selectedCountry)?.regions;
    const regionNames = regions?.map((r: any) => getNameById(r.id, regionData))?.join(', ');

    const newState = {
      ...currentState,
      countryName: selectedCountry,
      regionNames
    }

    if (mode === "add") {
      if (buttonClicked !== 'saveOverride' && validation()) return
      try {
        const _state = {...newState, createdDate: getCurrentTimestamp()}
        const response = await create.state({ state:_state, pathname });
        dispatch(AddState(response));
        navigate('/Staff/Catalogs/State')
      } catch (error: any) {
        console.error(error);
      }
    } else {
      if (buttonClicked !== 'saveOverride' && validation()) return
      try {
        await update.state({ state: newState, pathname, prevState: state });
        dispatch(UpdateState(newState));
        navigate('/Staff/Catalogs/State')
      } catch (error: any) {
        console.error(error);
      }
    }
  };

  const handleChange = (eventOrValue: {
    target: { name: string; value: any };
  }) => {
    {
      const { name, value } = eventOrValue.target;
      dispatch(
        ModifyState({
          ...currentState,
          [name]: value.id ? value.id : value,
        } as IState)
      );
      setErrors({ ...errors, [name]: { error: false, errorMsj: "" } });
      if (name === "country") {
        setSelectedCountry(value.name);
      }
    }
  };

  return {
    currentState,
    errors,
    saveOverrideOpen,
    handleButtonClick,
    handleSubmit,
    handleChange,
    setSaveOverrideOpen,
    countryData,
    selectedCountry,
  };
};
