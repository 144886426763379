import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { LegacyRedirection, LegacyDataAdminRedirection } from "../../Enums/LegacyRedirection";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../redux/loaderActions";

interface LegacyIFrameProps {
  isDataAdmin?: boolean;
}

const LegacyIFrame: React.FC<LegacyIFrameProps> = ({ isDataAdmin }) => {
	const iframeRef = useRef(null);
	const location = useLocation();
	const dispatch = useDispatch();
	const [token, setToken] = React.useState<string | null>(null);

	useEffect(() => {
		const tokenCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('token='))?.substring(6);
		if(tokenCookie) {
			setToken(tokenCookie);
		}
	}, []);

	useEffect(() => {
		if(!iframeRef.current) return;
		dispatch(showLoader());
		const iframe = iframeRef.current as HTMLIFrameElement;
		let path = location.pathname.toLowerCase();
		const legacyRedirection = isDataAdmin ? LegacyDataAdminRedirection : (LegacyRedirection as any)[path];
		try {
			let legacyPath = new URL(legacyRedirection);
			legacyPath.searchParams.append('hide-navigation', 'true');
			if(token) { 
				const encodedToken = encodeURIComponent(token);
				legacyPath.searchParams.append('token', encodedToken);
			}
			iframe.src = legacyPath.toString();
		} catch (error) {
			console.error(error);
		}

		iframe.onload = () => { dispatch(hideLoader()) };
		iframe.onerror = () => { dispatch(hideLoader()) };

		return () => {
			iframe.onload = null;
			iframe.onerror = null;
			dispatch(hideLoader());
		};
	}, [location.pathname, token]);

  return (
    <>
      <div className="min-h-screen w-full flex items-center align-middle justify-center">
        <iframe ref={iframeRef} className="min-h-screen w-full" title="Legacy"></iframe>
      </div>
    </>
  );
};

export default LegacyIFrame;
