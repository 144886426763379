import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IJurisdiction } from "../../../interfaces";

const initialState: IJurisdiction[] = [];

export const jurisdictionsSlice = createSlice({
  name: "jurisdiction",
  initialState,
  reducers: {
    SetJurisdictions: (_, action: PayloadAction<IJurisdiction[]>) => action.payload,
    AddJurisdiction: (state, action: PayloadAction<IJurisdiction>) => {
      state.push(action.payload);
    },
    UpdateJurisdiction: (state, action: PayloadAction<IJurisdiction>) => {
      const index = state.findIndex((jurisdiction) => jurisdiction.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },  
    DeleteJurisdiction: (state, action: PayloadAction<string>) => {
      return state?.filter((jurisdiction) => jurisdiction.id !== action.payload);
    },
  },
});

export const {
  SetJurisdictions,
  AddJurisdiction,
  UpdateJurisdiction,
  DeleteJurisdiction,
} = jurisdictionsSlice.actions;
