import React, { useEffect, useState } from 'react';
import useDataLoader2 from '../../hooks/useDataLoader2';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import BaseFilter from '../../components/Catalogs/Filters/BaseFilter/BaseFilter';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import { formatTimeSpanToString } from '../../utils/functions';
import { defaultPageSize } from '../../utils/const';

const Degree: React.FC = ({ ...props }) => {
  const Headers: string[] = ['name', 'createdDate'];
  const [nameFilter, setNameFilter] = useState('');
  const [tableInstance, setTableInstance] = useState<any>();
  const { generateColumns } = useColumnConfig(Headers);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'name', 
    'createdDate'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/degree',
    defaultPageSize,
    fields
  });

 
  const handleNameFilterChange = (newNameFilter: string) => {
    setNameFilter(newNameFilter);
  };

  const clearSelections = () => {
    setNameFilter('');
    fetchData(true)
  };

  const columns = generateColumns([
    { id: 'count', header: '#', cell: (props: any) => <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>},
    { id: 'name', header: 'Name', cell: (props: any) => props.getValue() },
    {
      id: 'createdDate', header: 'Created Date', cell: (props: any) => {
        const value = props.getValue();
        return (
          <p>
            {value && formatTimeSpanToString(value) }
          </p>
        );
      }
    },
    {
      id: 'edit', header: 'Edit', cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={props.row.original}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ), enableSorting: false
    },
  ]);

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  useEffect(() => {
    setFilter({name: {
      value: nameFilter,
      comparisonType: 'CONTAINS'
    }})
  },[nameFilter]);

  return (
    <>
      <main className='mainSection'>
        <CatalogTableC
          title={`Degree Catalog`}
          catalogName={'DEGREES'}
          catalogShortName='Degree'
          route={'Degree'}
          data={data}
          headers={Headers}
          totalCount={totalCount}
          isfiltered={true}
        >
          <BaseFilter
            title='By Name'
            key="degreeFilter"
            search={() => fetchData()}
            headers={Headers}
            onNameFilterChange={handleNameFilterChange}
            nameFilter={nameFilter}
            resetFilters={clearSelections}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
        />
        </CatalogTableC>
      </main>
    </>
  );
};

export default Degree;