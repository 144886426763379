import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/AddCatalogs.css";
import DropDownList from "../../../components/Atomos/DropDown/Dropdown";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import RadioButton from "../../../components/Atomos/Radio/RadioButton";
import SwitchToogle from "../../../components/Atomos/Switch-Toogle/Switch";
import { UseJurisdictionList } from "../../../hooks/Catalogs/useJurisdiction";
import { UseJurisdictionGroupList } from "../../../hooks/Catalogs/useJurisdictionGroup";
import { UseFirmList } from "../../../hooks/Catalogs/useFirm";
import IDataAdmin, { DataAdminType } from "../../../interfaces/models/IDataAdmin"
import { useUpdateAndLog } from "../../../hooks/LogActivity/LogActivityUpdates";
import { useCreateAndLog } from "../../../hooks/LogActivity/LogActivityCreates";
import { useDeleteAndLog } from "../../../hooks/LogActivity/LogActivityDeletes";
import { getCurrentTimestamp } from "../../../utils/functions";
import IFirm from "../../../interfaces/models/IFirm";

interface iCatalogProps {
  mode: "add" | "edit";
}

type error = { error: boolean; msj: string };
type errors = {
  person: error;
  membershipLocation: error;
  firm: error;
};

const AddDataAdmin: React.FC<iCatalogProps> = (props) => {
  const axios = new Axios();
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [dataAdmin, setDataAdmin] = useState<IDataAdmin>(
    state
      ? { ...state }
      : {
          firm: "",
          type: 0,
          person: "",
          membershipLocation: "",
          subscription: false,
        }
  );
  const [errors, setErrors] = useState<errors>({
    person: { error: false, msj: "" },
    membershipLocation: { error: false, msj: "" },
    firm: { error: false, msj: "" },
  });

  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState("");
  const [personData, setPersonData] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [membershipLocation, setMembershipLocation] = useState<any[]>([]);
  const [filteredPersons, setFilteredPersons] = useState<any[]>([]);
  const [primaryType, setPrimaryType] = useState(false);
  const [selectedFirm, setSelectedFirm] = useState({
    id: state?.firm ?? "",
    name: state?.firmName ?? "",
  });
  const [selectedPerson, setSelectedPerson] = useState({ id: "", name: "" });
  const [selectedMembershipLocation, setSelectedMembershipLocation] = useState<any>([]);
  const { pathname } = useLocation();
  const update = useUpdateAndLog();
  const create = useCreateAndLog();
  const erase = useDeleteAndLog();

  const { jurisdictionData: jurisdictionData } = UseJurisdictionList();
  const { jurisdictionGroupData: jurisdictionGroupData } =
    UseJurisdictionGroupList();
  const { firmData } = UseFirmList();

  useEffect(() => {
    if (
      errors.firm.error ||
      errors.person.error ||
      errors.membershipLocation.error
    )
      validateForm();
  }, [dataAdmin, selectedFirm, selectedMembershipLocation, selectedPerson]);

  useEffect(() => {
    if (
      !errors.firm.error &&
      !errors.person.error &&
      !errors.membershipLocation.error
    )
      setSaveOverrideOpen(false);
  }, [errorFields]);

  const checkEditType = (index: number) => {
    setDataAdmin({ ...dataAdmin, type: index });
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await axios.Get("/user");
      setUsers(res.data);
    };

    setErrors({
      person: { error: false, msj: "" },
      membershipLocation: { error: false, msj: "" },
      firm: { error: false, msj: "" },
    });

    fetchUsers();
  }, []);

  useEffect(() => {
    if (!personData.length || !users.length) return;
    const dataAdminUserIds = users
      .filter((user) => user.access === "Data Admin + Member")
      .map((user) => user.id);

    const filteredPersons = personData.filter((person) =>
      dataAdminUserIds.includes(person.userId)
    );

    setFilteredPersons(filteredPersons);
  }, [personData,  users]);

  const fetchPersons = async () => {
    try {
      const params = new URLSearchParams();
      params.append('fields', JSON.stringify(['id', 'name', 'lastName', 'userId']))
      params.append('filter', JSON.stringify({firm: {value: selectedFirm.id, comparisonType: 'EQUAL'}}))

      const response = await axios.Get(`./person?${params.toString()}`);
      const data = response.data;
        setPersonData(data?.map((p: any) => ({
          id:p.id, 
          name:`${p.name} ${p.lastName}`,
          userId: p.userId
        })));
        setSelectedPerson({
          id: state?.personId ?? "",
          name: state?.person ?? "",
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPersons();
  }, [selectedFirm.id]);

  const validateForm = () => {
    let valid = true;
    let newerrors: errors = errors;

    newerrors.firm =
      selectedFirm.name === ""
        ? { error: true, msj: "Firm is required" }
        : { error: false, msj: "" };
    newerrors.membershipLocation =
      selectedFirm.name !== "" &&
      dataAdmin.type === DataAdminType.dedicated &&
      selectedMembershipLocation.name === ""
        ? { error: true, msj: "Membership Location is required" }
        : { error: false, msj: "" };
    newerrors.person =
      selectedFirm.name !== "" && selectedPerson.name === ""
        ? { error: true, msj: "Person is required" }
        : { error: false, msj: "" };

    valid = Object.values(errors).every((error) => !error.error);
    setErrors(errors);
    setErrorFields(
      Object.keys(errors).filter(
        (key) => errors[key as keyof typeof errors].error
      )
    );

    if (!valid) setSaveOverrideOpen(true);

    return valid;
  };

  const handleButtonClick = (action: string) => {
    setButtonClicked(action);
  };

  const handleDelete = async () => {
    try {
      await erase.dataAdmin({dataAdmin: state, pathname})
      navigate(-1)
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (buttonClicked === "saveOverride") {
      if (props.mode === "add") {
        handleSave(e);
      } else {
        handleUpdate(e);
      }
    } else if (validateForm()) {
      if (buttonClicked === "saveButton") {
        handleSave(e);
      } else if (buttonClicked === "updateButton") {
        handleUpdate(e);
      }
    }
  };

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const _dataAdmin  = {
        ...dataAdmin,
        createdAt: getCurrentTimestamp()
      }
      await create.dataAdmin({dataAdmin: _dataAdmin, pathname})
      navigate(-1)
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
     
      await update.dataAdmin({dataAdmin, pathname, prevState: state})
      navigate(-1)
    } catch (error) {
      console.error(error);
    }
  };

  const handleSetSelectedFirm = (e: any) => {
    setDataAdmin((prevState) => ({
      ...prevState,
      firm: e.id,
      firmName: e.name,
    }));
  };

  const setFirmAndMembership = (firmId: string) => {
    const firm:IFirm = firmData.find((firm: any) => firm.id === firmId);
    
    setSelectedPerson({ id: "", name: "" });
    setSelectedFirm(firm);
    setDataAdmin({ ...dataAdmin, firm: firmId });

    setPrimaryType(firm?.membershipType === "Primary");
    if (firm?.membershipType === "Primary") {
      setDataAdmin({ ...dataAdmin, type: 0 });
      const jurisdiction = jurisdictionData.filter((j) => j.id === firm.jurisdiction);
      
      setMembershipLocation(jurisdiction);
      setSelectedMembershipLocation(jurisdictionData.find((j) => j.id === firm.jurisdiction));
    } else if (firm?.membershipType === "Multiple Primary" || firm?.membershipType === "Regional") {
      const jurisdictionGroup = jurisdictionGroupData.filter((j) => j.id === firm.jurisdictionGroup);
      setMembershipLocation(jurisdictionGroup);
      setSelectedMembershipLocation(jurisdictionGroupData.find((j) => j.id === firm.jurisdictionGroup));
    }
  }

  const handleSetSelectedJurisdiction = (e: any) => {
    setDataAdmin((prevState) => ({
      ...prevState,
      membershipLocation: e.id,
      membershipLocationName: e.name,
    }));
  };

  const handleSetSelectedPerson = (e: any) => {
    setDataAdmin((prevState) => ({
      ...prevState,
      person: e.id,
      personName: e.name,
    }));
  };

  const handleCheckBoxChange = (name: string, checked: boolean) => {
    setDataAdmin((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleCheckBoxDataSubscribeEmails = (checked: boolean) => {
    handleCheckBoxChange("subscription", checked);
  };

  useEffect(() => {
    if (
      !firmData.length ||
      !personData.length ||
      (!jurisdictionData.length && !jurisdictionGroupData.length) ||
      !state
    )
      return;
      setFirmAndMembership(state?.firm);
      const currentPerson = personData.find((p: any) => p.id === state?.person)
      setSelectedPerson({id: currentPerson?.id, name: currentPerson?.name})
  }, [
    firmData, 
    jurisdictionData, 
    jurisdictionGroupData, 
    state
  ])

  return (
    <>
      <main
        className={`mainSection ${saveOverrideOpen ? "pt-7 md:pt-9 " : ""}`}
      >
        <form onSubmit={handleSubmit}>
          <SaveOverride
            fields={errorFields}
            handleButtonClick={handleButtonClick}
            open={saveOverrideOpen}
            close={() => {
              setSaveOverrideOpen(!saveOverrideOpen);
            }}
          />
          <CatalogTitle
            title={`${props.mode === "add" ? "New DataAdmin" : `Edit ${state?.personName}`}`}
            className="text-Default"
          >
            <GoBack />
          </CatalogTitle>
          <article className="articleSection">
            <section className="formsectionNTitle">
              <CatalogSubTitle
                title="DataAdmin Information"
                className="text-accent-Default-dark"
              />
              <div className="formsection grid grid-cols-12 gap-5 w-full">
                <div className="col-span-12 md:col-span-6 lg:col-span-6">
                  <DropDownList
                    title="Firm"
                    required={true}
                    data={firmData}
                    selectedObjst={dataAdmin.firmName}
                    seter={handleSetSelectedFirm}
                    error={errors.firm.error}
                    errormsj={errors.firm.msj}
                    getid
                  />
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-6">
                  <DropDownList
                    title="Person"
                    required={true}
                    data={filteredPersons}
                    selectedObjst={dataAdmin.personName}
                    seter={handleSetSelectedPerson}
                    error={errors.person.error}
                    errormsj={errors.person.msj}
                    disabled={!selectedFirm.id}
                    getid
                    tooltip="Please select Firm."
                  />
                </div>
                <div className="grid gap-2.5 flex-wrap col-span-12 md:col-span-6 lg:col-span-6">
                  <div className="grid">
                    <span className="text-gray-font text-base lg:text-lg not-italic font-normal leading-8">
                      Data Admin Type:
                    </span>
                  </div>
                  <div className="flex flex-1 gap-5">
                    <RadioButton
                      index={DataAdminType.primary}
                      text={"Primary"}
                      selected={dataAdmin.type === DataAdminType.primary}
                      handleRadioButtonChange={checkEditType}
                      disabled={primaryType}
                    />
                    <RadioButton
                      index={DataAdminType.dedicated}
                      text={"Dedicated"}
                      selected={dataAdmin.type === DataAdminType.dedicated}
                      handleRadioButtonChange={checkEditType}
                      disabled={primaryType}
                    />
                  </div>
                </div>

                <div className="flex-wrap lg:col-start-7 col-span-12 md:col-span-6 lg:col-span-6">
                  <DropDownList
                    title="Membership Location"
                    required={dataAdmin.type === DataAdminType.dedicated}
                    data={membershipLocation}
                    selectedObjst={dataAdmin.membershipLocationName}
                    seter={handleSetSelectedJurisdiction}
                    error={errors.membershipLocation.error}
                    errormsj={errors.membershipLocation.msj}
                    disabled={
                      !selectedFirm.id ||
                      dataAdmin.type === DataAdminType.primary
                    }
                    getid
                    tooltip={
                      'To assign a specific Membership Location, please select DataAdmin Type "Dedicated".'
                    }
                    dynamic={true}
                  />
                </div>
                <div className="flex-2 gap-2.5 flex-wrap col-span-12 md:col-span-6 lg:col-span-6">
                  <SwitchToogle
                    titleFirst
                    className="flex-col items-center md:!items-start"
                    centered
                    small
                    seter={handleCheckBoxDataSubscribeEmails}
                    checked={dataAdmin.subscription}
                    title="Subscribe to Notifications Emails"
                  />
                </div>
              </div>
            </section>
            <ButtonsCrud
              Catalog="DataAdmin"
              mode={props.mode}
              id={id ? id : ""}
              disabled={
                errors.firm.error ||
                errors.person.error ||
                errors.membershipLocation.error
              }
              actionButton={handleButtonClick}
              onDelete={handleDelete}
              state={state}
              newState={dataAdmin}
              customFieldNames={{type:'Data Admin Type'}}
            />
          </article>
        </form>
      </main>
    </>
  );
};

export default AddDataAdmin;
