import { axios } from "..";
import { IJurisdiction, IJurisdictionFilters } from "../interfaces"; 
export const getJurisdiction = async () => {

  const jurisdictions = await axios.Get('jurisdiction');

  return jurisdictions.data.map((jurisdiction: IJurisdiction) => ({ id: jurisdiction.id, name: jurisdiction.name, abbreviation: jurisdiction.abbreviation, country: jurisdiction.country, hasSubstate: jurisdiction.hasSubstate, _ts: jurisdiction._ts, jurisdictionGroup:jurisdiction.jurisdictionGroup }));
}

export const getJurisdictionList = async () => {
    try {
        const response = await axios.Get('./jurisdiction');
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    } 
}

export const getJurisdictionById = async (id:string) => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({id: {value: id, comparisonType: 'EQUAL'}}))
  
    const jurisdiction = await axios.Get(`jurisdiction?${params.toString()}`);
    return jurisdiction.data;
  } catch (error) {
    throw error;
  }
} 

export const getJurisdictionByJurisdictionGroup = async (id:string) => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({jurisdictionGroup: {value: id, comparisonType: 'EQUAL'}}))
  
    const jurisdiction = await axios.Get(`jurisdiction?${params.toString()}`);
    return jurisdiction.data;
  } catch (error) {
    throw error;
  }
} 

export const createJurisdiction = async (jurisdiction: IJurisdiction): Promise<void> => {
  try {
    const response = await axios.Post('jurisdiction', jurisdiction);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateJurisdiction = async (jurisdiction: IJurisdiction): Promise<void> => { 
  try {
    const response = await axios.Put('jurisdiction', jurisdiction);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deleteJurisdiction = async (id:string) => {
  try{
    const response = await axios.Delete('jurisdiction/',id);
    if(response.status === 200){
      console.log('Jurisdiction deleted successfully');
      return true;
    }
    return false; 
  } catch (error) {
    console.error('Error deleting Jurisdiction:', error);
    return false; 
  }
}

export const getJurisdictionTableRequest = async ( sort:any, pagination:any,filters:IJurisdictionFilters,) => {

  try {   
 
    var queryParams: string[] = []; 

    if (sort.length > 0) {
      sort.forEach((element:any) => {
        if (element.order !== 'any')
          queryParams.push(`sort=`+JSON.stringify(element));
      });
    }   

    queryParams.push(`pagination=`+JSON.stringify({ pageNumber: pagination?.pageNumber, pageSize: pagination?.pageSize })); 


    if (filters) {
      const filterData = filterToQueryString(filters); 
      if (filterData.length > 0) {
        queryParams.push(`filter=`+JSON.stringify(filterData));
      }
    }

    var queryString = '/Jurisdiction';
    if (queryParams.length > 0) {
      queryString += `?${queryParams.join('&')}`;
    }
    const response = await axios.Get(queryString);
    const data = response.data;
    if (data != null) {
     return data;
    }
  } catch (error) {
    console.error('Error al obtener la información de la columna:', error);
  }
}

export const fetchTotalCount = async () => {
   try{
    var queryString = '/Jurisdiction?count=true';
    const response = await axios.Get(queryString); 
    return response.data;
   }
   catch (error) {
    console.error('Error fetching data:', error);
   }
} 

export const filterToQueryString = (filters: any) => {
  const filterData = [];

  for (const key in filters) {
      if (Array.isArray(filters[key as keyof any]) && filters[key as keyof any].length > 0) {
          filterData.push({ propertyName: key, searchValue: filters[key as keyof any] });
      } else if (typeof filters[key as keyof any] === 'string' && filters[key as keyof any] !== '') {
          filterData.push({ propertyName: key, searchValue: filters[key as keyof any] });
      }
  }

  return filterData;
};