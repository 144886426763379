import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/AddCatalogs.css";
import DropDownList from "../../../components/Atomos/DropDown/Dropdown";
import Input from "../../../components/Atomos/Inputs/Input";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import CalendarInput from "../../../components/Atomos/Inputs/CalendarInput";
import TextArea from "../../../components/Atomos/Inputs/TextArea";
import { createFirmNote, deleteFirmNote, updateFirmNote } from "../../../Services/FirmNote";
import { UseFirmList, firmType } from "../../../hooks/Catalogs/useFirm";
import { useLogging } from "../../../Context/LoggingContext";
import IFirmNote from "../../../interfaces/models/IFirmNote";

interface iCatalogProps {
  mode: "add" | "edit";
}

type error = { error: boolean; msj: string };
type errors = {
  name: error;
  firm: error;
  note: error;
  noteDate: error;
};

const AddFirmNote: React.FC<iCatalogProps> = (props) => {
  const axios = new Axios();
  const navigate = useNavigate();
  const location = useLocation()
  const { id } = useParams();
  const { state } = useLocation();
  const [firmNote, setFirmNote] = useState<IFirmNote>(
    state
      ? state
      : { name: "", firm: "", note: "", noteDate: "" }
  );

  const [errors, setErrors] = useState<errors>({
    name: { error: false, msj: "" },
    firm: { error: false, msj: "" },
    note: { error: false, msj: "" },
    noteDate: { error: false, msj: "" },
  });

  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState("");
  const { mode } = props;
  const { firmData } = UseFirmList();
  const { logActivity } = useLogging();

  const [selectedFirm, setSelectedFirm] = useState({ id: "", name: "" });

  useEffect(() => {
    if (
      errors.firm.error ||
      errors.name.error ||
      errors.note.error ||
      errors.noteDate.error
    )
      validateForm();
  }, [firmNote, selectedFirm]);

  useEffect(() => {
    if (
      !errors.firm.error &&
      !errors.name.error &&
      !errors.note.error &&
      !errors.noteDate.error
    )
      setSaveOverrideOpen(false);
  }, [errorFields]);

  useEffect(() => {
    const firm = firmData.find((firm: any) => firm.id === state?.firm);
    setSelectedFirm({ id: firm?.id, name: firm?.name });

    setErrors({
      name: { error: false, msj: "" },
      firm: { error: false, msj: "" },
      note: { error: false, msj: "" },
      noteDate: { error: false, msj: "" },
    });

  }, []);

  const validateForm = () => {
    let valid = true;
    let newerrors: errors = errors;
    newerrors.firm =
      selectedFirm.name === ""
        ? { error: true, msj: "Firm is required" }
        : { error: false, msj: "" };
    newerrors.name =
      firmNote.name === ""
        ? { error: true, msj: "Name is required" }
        : { error: false, msj: "" };
    newerrors.noteDate = !firmNote.noteDate
        ? { error: true, msj: "Note Date is required" }
        : { error: false, msj: "" };

    valid = Object.values(errors).every((error) => !error.error);
    setErrors(errors);
    setErrorFields(
      Object.keys(errors).filter(
        (key) => errors[key as keyof typeof errors].error
      )
    );

    if (!valid) setSaveOverrideOpen(true);

    return valid;
  };

  const handleButtonClick = (action: string) => {
    setButtonClicked(action);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (buttonClicked === "delete") {
      axios.Delete("firmNote", firmNote.id ?? "").then(() => {
        navigate("/Staff/Catalogs/FirmNote");
      });
    } else if (buttonClicked === "saveOverride") {
      if (props.mode === "add") {
        handleSave(e);
      } else {
        handleUpdate(e);
      }
    } else if (validateForm()) {
      if (buttonClicked === "saveButton") {
        handleSave(e);
      } else if (buttonClicked === "updateButton") {
        handleUpdate(e);
      }
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFirmNote({ ...firmNote, [e.target.name]: e.target.value });
  };

  const buildFirmNoteObject = () => {
    return {
      id: state?.id,
      name: firmNote.name,
      firm: selectedFirm.id,
      firmName: selectedFirm.name,
      note: firmNote.note,
      noteDate: firmNote.noteDate,
      dateCreated: state?.dateCreated,
    };
  }

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFirmNote: IFirmNote = buildFirmNoteObject();
    newFirmNote.dateCreated = new Date();

    const response = await createFirmNote(newFirmNote);
    logActivity('CREATE_FIRM_NOTE', location.pathname, JSON.stringify(response));
    response?.Done && navigate("/Staff/Catalogs/FirmNote");
  };

  const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const response = await updateFirmNote(buildFirmNoteObject());
    logActivity('UPDATE_FIRM_NOTE', location.pathname, JSON.stringify({ prevState: JSON.stringify(state), newState: JSON.stringify(response) }));
    response?.Done && navigate("/Staff/Catalogs/FirmNote");
  };

  const handleSetSelectedFirm = (e: any) => {
    setSelectedFirm(e);
    setFirmNote((prevState) => ({
      ...prevState,
      firm: e.id,
    }));
  };

  const handleSetSelectedNoteDate = (e: any) => {
    setFirmNote((prevState) => ({
      ...prevState,
      noteDate: e,
    }));
  };

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    if (value.length > 3500) {
      setErrors((prevState) => ({
        ...prevState,
        note: { error: true, msj: "" },
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        note: { error: false, msj: "" },
      }));
    }
    setFirmNote((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <main
        className={`mainSection ${saveOverrideOpen ? "pt-7 md:pt-9 " : ""}`}
      >
        <form onSubmit={handleSubmit}>
          <SaveOverride
            fields={errorFields}
            handleButtonClick={handleButtonClick}
            open={saveOverrideOpen}
            close={() => {
              setSaveOverrideOpen(!saveOverrideOpen);
            }}
          />
          <CatalogTitle
            title={`${props.mode === "add" ? "New Firm Note" : `Edit ${state?.name} `}`}
            className="text-Default"
          >
            <GoBack />
          </CatalogTitle>
          <article className="articleSection">
            <section className="formsectionNTitle">
              <CatalogSubTitle
                title=" Catalog List Relationships"
                className="text-accent-Default-dark"
              />
              <div className="formsection grid grid-cols-12 gap-5 w-full">
                <div className="gap-4 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
                  <DropDownList
                    title="Firm"
                    required={true}
                    data={firmData}
                    selectedObjst={selectedFirm.name}
                    seter={handleSetSelectedFirm}
                    error={errors.firm.error}
                    errormsj={errors.firm.msj}
                    getid
                  />
                </div>
              </div>
            </section>
            <section className="formsectionNTitle">
              <CatalogSubTitle
                title="Firm Note Information"
                className="text-accent-Default-dark"
              />
              <div className="formsection grid grid-cols-7 gap-5 w-full">
                <div className="grid col-span-7 md:col-span-3 lg:col-span-2">
                  <Input
                    title="Name"
                    name="name"
                    error={errors.name.error}
                    errorMsj={errors.name.msj}
                    value={firmNote.name}
                    onChange={handleInputChange}
                    required={true}
                  />
                </div>
                <div className="grid col-span-7 md:col-span-4 lg:col-span-3">
                  <CalendarInput
                    title="Note Date"
                    type="single"
                    error={errors.noteDate.error}
                    errorMsj={errors.noteDate.msj}
                    value={firmNote.noteDate?.toString() ?? ""}
                    onChange={handleSetSelectedNoteDate}
                    required
                  />
                </div>
                <div className="grid col-span-7">
                  <TextArea
                    title="Note"
                    name="note"
                    maxChars={3500}
                    value={firmNote.note}
                    error={errors.note.error}
                    errorMsj="Character limit has been reached."
                    onChange={handleTextAreaChange}
                    characterCount={true}
                  />
                </div>
              </div>
            </section>

            <ButtonsCrud
              Catalog="firmNote"
              mode={props.mode}
              id={id ? id : ""}
              disabled={
                errors.firm.error ||
                errors.name.error ||
                errors.note.error ||
                errors.noteDate.error
              }
              actionButton={handleButtonClick}
              onDelete={async () => {
                if (await deleteFirmNote(state.id as string)) {
                  navigate("/Staff/Catalogs/FirmNote");
                  logActivity('DELETE_FIRM_NOTE', location.pathname, JSON.stringify(state ? state : {}));
                }
              }}
              state={state}
              newState={buildFirmNoteObject()}
            />
          </article>
        </form>
      </main>
    </>
  );
};

export default AddFirmNote;
