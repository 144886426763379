import "../../../assets/css/AddCatalogs.css";
import React, { useEffect, useState } from "react";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Vector from "../../../components/Atomos/Icons/Vector";
import ContactFrame from "./ContactFrame";
import SortArrow from "../../../components/Atomos/Icons/SortArrow";
import Manage from "../../../components/Atomos/Icons/Manage";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Appstore } from "../../../redux";

interface IMainBoxProps {
  dataAdminList: any;
  personList: any;
  jurisdictionList: any;
  users: any;
}

const DataAdminGT: React.FC<IMainBoxProps> = ({
  dataAdminList,
  personList,
  jurisdictionList,
  users,
}) => {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortOrderType, setSortOrderType] = useState<"asc" | "desc">("asc");
  const [sortedAdminList, setSortedAdminList] = useState<any[]>([]);
  const [dataAdminListFiltered, setDataAdminListFiltered] = useState<any[]>([]);
  const navigate = useNavigate();
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);

  const manageDataAdmin = () => {
    const firmPerson = personList.filter(
      (person: any) => person.firm === manageFirm.selectedFirm?.id
    );
    const dataAdminUserIds = users
      .filter((user: any) => user.access === "Data Admin + Member")
      .map((user: any) => user.id);

    const filteredPersons = firmPerson.filter((person: any) =>
      dataAdminUserIds.includes(person.userId)
    );
    const firmJurisdiction = jurisdictionList.filter(
      (jur: any) =>
        jur.jurisdictionGroup === manageFirm.selectedFirm?.jurisdictionGroup
    );
    navigate(`/Staff/ManageFirm/GeneralTab/${manageFirm.selectedFirm?.id}/DataAdmin`, {
      state: {
        ...manageFirm.selectedFirm,
        dataAdminList: dataAdminListFiltered,
        personList: filteredPersons,
        jurisdictionList: firmJurisdiction,
        selectedTab: "GeneralTab",
      },
    });
  };

  useEffect(() => {
    const sortedList = [...dataAdminListFiltered].sort((a: any, b: any) => {
      let locationA = a.membershipLocationName ?? "";
      let locationB = b.membershipLocationName ?? "";

      if (locationA === "Primary") locationA = "";
      if (locationB === "Primary") locationB = "";

      if (sortOrder === "asc") {
        return locationA.localeCompare(locationB);
      } else {
        return locationB.localeCompare(locationA);
      }
    });

    setSortedAdminList(sortedList);
  }, [dataAdminListFiltered, sortOrder]);

  useEffect(() => {
    const formatData = (data: any[]) => {
      const formatedData = data.map((d: any) => {
        return {
          ...d,
          membershipLocationName: jurisdictionList.find(
            (j: any) => j.id === d.membershipLocation
          )?.name,
        };
      });
      return formatedData;
    };

    setDataAdminListFiltered(formatData(dataAdminList));
  }, [dataAdminList]);

  useEffect(() => {
    const sortedList = [...dataAdminListFiltered].sort((a: any, b: any) => {
      let typeA =
        a.type === 0 ? "Primary" : (a.membershipLocationName || "") + " Admin";
      let typeB =
        b.type === 0 ? "Primary" : (b.membershipLocationName || "") + " Admin";

      if (typeA === "Primary" && typeB === "Primary") return 0;
      if (typeA === "Primary") return sortOrderType === "asc" ? -1 : 1;
      if (typeB === "Primary") return sortOrderType === "asc" ? 1 : -1;

      if (sortOrderType === "asc") {
        return typeA.localeCompare(typeB);
      } else {
        return typeB.localeCompare(typeA);
      }
    });

    setSortedAdminList(sortedList);
  }, [sortOrderType]);

  return (
    <>
      <div className="flex flex-col gap-10 justify-end">
        <article className="articleSection relative">
          <section className="flex w-full flex-col gap-7">
            <div className="flex flex-col gap-5 lg:flex-row lg:justify-between">
              <CatalogSubTitle
                title={"Data Admin"}
                className="text-Default lg:text-[32px] md:text-[28px] text-[26px] not-italic font-medium lg:leading-9 md:leading-[44px] leading-normal"
              />

              <div className="flex flex-wrap md:flex-nowrap flex-row gap-3 items-center justify-start">
                <div>
                  <span className="text-Default not-italic font-semibold lg:leading-5 lg:text-lg text-base leading-[18px] ">
                    Sort By
                  </span>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2"
                    height="20"
                    viewBox="0 0 2 20"
                    fill="none"
                  >
                    <path d="M1 0V20" stroke="#EBEBEB" />
                  </svg>
                </div>
                <div
                  className="flex items-center gap-3 cursor-pointer"
                  onClick={() =>
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc")
                  }
                >
                  <span className="text-gray-font not-italic lg:leading-5 font-normal lg:text-lg text-base leading-[18px]  ">
                    Membership Location
                  </span>
                  <SortArrow
                    className={`flex w-6 h-6 items-center justify-center fill-gray-font ${sortOrder === "desc" && "rotate-180"}`}
                  />
                </div>
                <div className="flex items-center text-center gap-3">
                  <span className="text-gray-font text-center justify-center not-italic lg:leading-5 font-normal lg:text-lg text-base leading-[18px] ">
                    •
                  </span>
                </div>
                <div
                  className="flex items-center gap-3 cursor-pointer"
                  onClick={() =>
                    setSortOrderType(sortOrderType === "asc" ? "desc" : "asc")
                  }
                >
                  <span className="text-gray-font not-italic lg:leading-5 font-normal lg:text-lg text-base leading-[18px]  ">
                    Data Admin Type
                  </span>
                  <SortArrow
                    className={`flex w-6 h-6 items-center justify-center fill-gray-font ${sortOrderType === "desc" && "rotate-180"}`}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-1 ">
              <Vector className="w-full h-0 stroke-gray-50 stroke-1" />
            </div>

            <div className="grid grid-cols-6 gap-5">
              {sortedAdminList.map((contact: any) => {
                const foundPerson = personList.find(
                  (person: any) => person.id === contact.person
                );
                return (
                  <ContactFrame
                    key={contact.id}
                    type={
                      contact.type === 0
                        ? "Primary"
                        : contact.membershipLocationName + " Admin"
                    }
                    name={foundPerson?.name + " " + foundPerson?.lastName}
                    phone={
                      foundPerson
                        ? foundPerson.countryCodePhoneNumber +
                          " " +
                          foundPerson.phoneNumber
                        : ""
                    }
                    email={foundPerson?.firmEmail}
                  />
                );
              })}
            </div>
          </section>
          <div className="flex w-full  justify-end">
            <div
              className="flex gap-2.5 items-center cursor-pointer"
              onClick={() => {
                manageDataAdmin();
              }}
            >
              <span className=" text-base not-italic font-bold leading-7 text-primary">
                MANAGE
              </span>
              <Manage className="w-4 h-4 fill-primary" />
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default DataAdminGT;
