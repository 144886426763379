import Axios from "../utils/axios";
const axios = new Axios();
export interface ActivityLog {
  userId: string;
  action: string;
  page: string;
  timestamp: string;
}

export const logActivity = async (log: ActivityLog): Promise<void> => {
  try {
    const response = await fetch('/api/logActivity', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(log)
    });

    if (!response.ok) {
      throw new Error('Failed to log activity');
    }
  } catch (error) {
    console.error('Error logging activity:', error);
  }
};

export const getLastLogin = async (userId: string): Promise<string> => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({userId: {value: userId, comparisonType: 'EQUAL'}}))
    params.append('sort', JSON.stringify({field: '_ts', order: 'DESC'}));
    const response = await axios.Get(`/activityLog?${params.toString()}`);
    return response.data[0]?._ts ?? '';
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
