const Newsletter = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="2 2 20 20"
      className={props.className}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M2 20.3943V3.25L3.425 4.675L5.075 3L6.75 4.675L8.425 3L10.075 4.675L11.75 3L13.425 4.675L15.075 3L16.75 4.675L18.425 3L20.075 4.675L21.5 3.25V20.3943H2ZM3.5 18.8943H11V12.3943H3.5V18.8943ZM12.5 18.8943H20V16.3943H12.5V18.8943ZM12.5 14.8943H20V12.3943H12.5V14.8943ZM3.5 10.8943H20V7.298H3.5V10.8943Z"/>
    </svg>
  );
}

export default Newsletter;
