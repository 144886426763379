import React, { useEffect, useReducer, useCallback, useContext } from 'react';
import AxiosPublic from '../../utils/axiosPublic';
import AxiosPrivate from '../../utils/axios';
import jwtDecode from 'jwt-decode';
import css from './LoginForm.module.css';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/loaderActions';
import classNames from 'classnames';
import AuthContext from '../../store/AuthContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useLogging } from '../../Context/LoggingContext';

type State = {
  checked: boolean;
  userName: string;
  password: string;
  errorMessage: string;
  token: string;
  bgLoading: boolean;
};

type Action =
  | { type: 'SET_CHECKED'; payload: boolean }
  | { type: 'SET_USER_NAME'; payload: string }
  | { type: 'SET_PASSWORD'; payload: string }
  | { type: 'SET_ERROR_MESSAGE'; payload: string }
  | { type: 'SET_TOKEN'; payload: string }
  | { type: 'SET_BG_LOADING'; payload: boolean };

const initialState: State = {
  checked: false,
  userName: '',
  password: '',
  errorMessage: '',
  token: '',
  bgLoading: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_CHECKED':
      return { ...state, checked: action.payload };
    case 'SET_USER_NAME':
      return { ...state, userName: action.payload };
    case 'SET_PASSWORD':
      return { ...state, password: action.payload };
    case 'SET_ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload };
    case 'SET_TOKEN':
      return { ...state, token: action.payload };
    case 'SET_BG_LOADING':
      return { ...state, bgLoading: action.payload };
    default:
      return state;
  }
};

const LoginForm = () => {
  const dispatch = useDispatch();
  const { setIsAuthenticated, tokenDecoded, loggedPerson } = useContext(AuthContext);
  const [state, dispatchLocal] = useReducer(reducer, initialState);
  const [redirectUrl, setRedirectUrl] = React.useState<string>(''); 
  const axiosInstance = new AxiosPublic();
  const { logActivity } = useLogging();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleCheckboxChange = useCallback(() => {
    dispatchLocal({ type: 'SET_CHECKED', payload: !state.checked });
  }, [state.checked]);

  const setCookie = (name: string, value: string, days: number) => {
		if(days === 0) {
			document.cookie = `${name}=${value};path=/`;
			return;
		}

    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  };

  const handleLogin = useCallback(async (event: React.FormEvent) => {

    event.preventDefault();
    try {
      if (state.userName.trim() === '') {
        throw new Error("Please enter a username.");
      }

      if (state.password.trim() === '') {
        throw new Error("Please enter a password.");
      }

      dispatchLocal({ type: 'SET_BG_LOADING', payload: true });

      const response = await axiosInstance.Post("/login", {
        username: state.userName,
        password: state.password
      });
      if (response && response.data) {
        try {
					if((response.data.id_token as string).toLowerCase().includes("end")) {
						handleLogin(event);
						return;
					}
          if (state.checked) {
            setCookie("token", response.data.id_token, 300); // Guardar token por 300 días
            setCookie("token_decoded", JSON.stringify(jwtDecode(response.data.id_token)), 300);
          } else {
            setCookie("token", response.data.id_token, 0); // Guardar token por 300 días
            setCookie("token_decoded", JSON.stringify(jwtDecode(response.data.id_token)), 0);
          }

          sessionStorage.setItem("refresh_token", response.data.refresh_token);
          sessionStorage.setItem("access_token", response.data.access_token);
          sessionStorage.setItem("access_timestamp", Date.now().toString());

          const claims = jwtDecode(response.data.id_token) as any;
          sessionStorage.setItem("token_decoded", JSON.stringify(claims));
					dispatchLocal({ type: 'SET_TOKEN', payload: response.data.id_token });
					setIsAuthenticated(true);
        } catch (error) {
          console.error(error)
        }
      }
    } catch (error: any) {
        let errorMessage = error.response && error.response.data ? error.response.data : error.message;
        if (error.response.status === 403) {
          setRedirectUrl('/modify-password')
          errorMessage = "The user's password has expired. To reset password click "
        } 
      dispatchLocal({ type: 'SET_ERROR_MESSAGE', payload: errorMessage });
    } 
  }, [state.userName, state.password, state.checked]);

  useEffect(() => {
    if (!loggedPerson) return

    logActivity("LOGIN", pathname, `${loggedPerson?.name} ${loggedPerson?.lastName}`, loggedPerson?.firm)
    navigate('/mywsg')
  }, [loggedPerson]);

  useEffect(() => {
    dispatchLocal({ type: 'SET_BG_LOADING', payload: false });

		// if error url param
		const urlParams = new URLSearchParams(window.location.search);
		const error = urlParams.get('error');
		if(error) {
			dispatchLocal({ type: 'SET_ERROR_MESSAGE', payload: error });
		}
  }, []);

  return (
    <div className={classNames({ 'w-full h-screen absolute bg-white z-30': state.bgLoading })}>
      <div className={classNames(css.loginFormbg, 'bg-cover bg-center')}>
        <div className="bg-white w-full h-[100vh] opacity-40"></div>
      </div>
      <div className={css.loginFormContainer}>
        <div className={`flex mx-auto w-full font-semibold ${css.redTextWsg}`}>
          <div className="bg-white p-0"></div>
        </div>
        <form className={`block md:w-10/12 w-11/12 m-auto mt-20 px-5 ${css.formLogin}`} onSubmit={handleLogin}>
          <div className={`flex w-full align-middle justify-center gap-28 px-5 py-4 bg-[#9C3325]`}>
            <h2 className={`text-center text-xl font-sans text-white roboto-font`}>MEMBER LOGIN</h2>
          </div>
          <div className='bg-white w-full py-4'>
            <div className='text-black py-8'>
              {state.errorMessage && (
                <div className="w-11/12 mb-4 mx-auto bg-red-100 border border-red-400 text-red-700 p-0 rounded-md">
                  {state.errorMessage}
                  {redirectUrl && 
                    <Link 
                      to={redirectUrl} 
                      state={{ userName: state.userName }}
                      className="text-red-700 underline">here
                    </Link>
                  }.
                </div>
              )}
              <div className='w-full px-5 '>
                <input
                  className={classNames('border w-full mb-4 p-4 h-10 mx-auto flex roboto_font', css.grayWsgInput, {
                    'border-red-500': state.errorMessage.toLowerCase().includes("a username"),
                    'border-gray-300': !state.errorMessage.toLowerCase().includes("a username")
                  })}
                  type="text"
                  placeholder='Username'
                  value={state.userName}
                  onChange={e => dispatchLocal({ type: 'SET_USER_NAME', payload: e.target.value })}
                />
              </div>
              <div className='w-full px-5'>
                <input
                  className={classNames('border w-full mb-4 p-4 h-10 mx-auto flex roboto_font', css.grayWsgInput, {
                    'border-red-500': state.errorMessage.toLowerCase().includes("a password"),
                    'border-gray-300': !state.errorMessage.toLowerCase().includes("a password")
                  })}
                  type="password"
                  placeholder='Password'
                  value={state.password}
                  onChange={e => dispatchLocal({ type: 'SET_PASSWORD', payload: e.target.value })}
                />
              </div>
              <button
                type="submit"
                className={`w-11/12 text-white mx-auto flex text-center align-middle justify-center py-4 inter_font ${css.buttonWsg}`}
              >
                LOGIN
              </button>
            </div>
            <hr className='m-auto w-11/12 py-2' />
            <div className={`md:flex mx-auto w-11/12 mb-4 align-middle justify-center flex flex-col-reverse ${css.redTextWsg} roboto_font`}>
              <a href='/forgot-password'>Forgot Password?</a>
              <div className='md:flex md:justify-center md:ml-auto md:mb-0 mb-6'>
                <input className='md:ml-auto mr-3' type='checkbox' id={`${css.remember_user}`} checked={state.checked} onChange={handleCheckboxChange} />
                <label className={`roboto_font ${css.customLabel}`} htmlFor="remember_user">Remember Me</label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
