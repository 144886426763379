import { Option } from "../components/Atomos/DropDown/DDMultiple";
import Axios from "../utils/axios";

const axios = new Axios();

export const getContentTags = async () => {
  try {
    const response = await axios.Get('/contentTag');
    return response.data;
  } catch (error) {
    console.error('Error fetching firms:', error);
    return [];
  }
}

export const addContentTag = async (addData: Option) => {
  try {
    const response = await axios.Post('/contentTag',addData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateContentTag = async (addData: Option) => {
  try {
    const response = await axios.Put('/contentTag',addData);
    return response.data;
  } catch (error) {
    throw error;
  }
}


export const deleteContentTag = async (id: string) => {
  try {
    await axios.Delete('/contentTag', id);
    return true
  } catch (error) {
    throw error;
  }
}