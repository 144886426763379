import { axios } from "..";
import IFirmNote from "../interfaces/models/IFirmNote";

export const firmNoteFilterFetch = async (filters: any, pagination: any) => {
  try {
    const qName = filters.name !== "" ? "&name=" + filters.name : "";
    const qFirms =
      filters.firms.length > 0
        ? "&firms=" + JSON.stringify(filters.firms.map((firm: any) => firm.id))
        : "";
    const qPageNumber = `&pageNumber=${pagination.currentPage}`;
    const qPageSize = `&pageSize=${pagination.recordsPerPage}`;

    var queryString = "/FirmNote?" + qName + qFirms + qPageNumber + qPageSize;
    const response = await axios.Get(queryString);
    const dataToAdd = response.data;
    if (dataToAdd != null) {
      return dataToAdd;
    }
  } catch (error) {
    console.error("Error al obtener la información de la columna:", error);
  }
};

export const getAllFirmNote = async () => {
  try {
    const response = await axios.Get("/firmNote");
    const data = response.data;
    if (data != null) {
      return data;
    }
  } catch (error) {
    console.error("Error al obtener la información de la columna:", error);
  }
};

export const countFirmNote = async () => {
  try {
    const response = await axios.Get("/firmNote?&count=true");
    const data = response.data;
    if (data != null) {
      return data;
    }
  } catch (error) {
    console.error("Error al obtener la información de la columna:", error);
  }
};

export const createFirmNote = async (firmNote: IFirmNote) => {
  try {
    const response = await axios.Post("FirmNote", firmNote);
    return response.data
  } catch (error) {
    console.error(error);
    throw error
  }

};

export const updateFirmNote = async (firmNote: IFirmNote) => {
  try {
    const response = await axios.Put("FirmNote", firmNote);
    return response.data
  } catch (error) {
    console.error(error);
    throw error
  }
};

export const deleteFirmNote = async (id: string) => {
  const res = await axios.Delete("FirmNote", id);
  if (res.status === 200) {
    return true;
  } else {
    console.error("Error al eliminar el elemento:", res.data);
    return false;
  }
};

//Delete

