import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import React from "react";
import Input from "../../../components/Atomos/Inputs/Input";

type ListingLayoutProps = { 
  listingCategories: string[]; 
  setListingCategories: React.Dispatch<React.SetStateAction<string[]>> 
};

const ListingLayout: React.FC<ListingLayoutProps> = ({ listingCategories, setListingCategories }) => {
  const handleInputChange = (index: number, value: string) => {
    const updatedCategories = [...listingCategories];
    updatedCategories[index] = value;
    setListingCategories(updatedCategories);
  };

  return (
    <>
      <div className="flex w-full flex-col gap-5 lg:flex-row lg:justify-between">
        <CatalogSubTitle className="text-Default" title="Listing Layout" />
      </div>

      <span className="text-gray-font text-base">
        Professionals representing your firm will be displayed with 4 distinct
        Title Categories in your firm's profile. Default labels are "Partners",
        "Associates", "Of Counsel" and "Other". To better fit the structure of
        your firm, fill in the desired titles in the boxes below which will be
        available when "Other" is selected.
      </span>

      <div className="grid w-full md:grid-cols-2 gap-5">
        {listingCategories.map((category, index) => (
          <Input 
            key={index}
            title=""
            value={category}
            onChange={(e) => handleInputChange(index, e.target.value)}
            error={false}
          />
        ))}
      </div>
    </>
  );
};

export default ListingLayout;