import { useAppContext } from "../../../Context";
import CloseModal from "../../Atomos/Icons/EditMode/CloseModal";

type ModalImageCropperProps = {
    isModalOpen: boolean; 
    toggleModal: () => void; 
    title: string;
    children: React.ReactNode;
    titleColor?: string;
    className?: string;
};

const Modal: React.FC<ModalImageCropperProps> = (props: ModalImageCropperProps) => {
    const {mode} = useAppContext();
    return (
        <>
            {props.isModalOpen && (
                <div className="modal w-full h-screen fixed left-0 top-0 flex justify-center items-center z-50">
                    <div className="w-full h-full absolute bg-black opacity-80" onClick={props.toggleModal}></div>
                    <div className="flex flex-col justify-center items-center text-white z-30 relative">
                        <div className={`${props.className ? props.className : 'py-4 px-4 md:py-7 md:px-7 lg:py-10 lg:px-10'} modal-content bg-white flex flex-col overflow-auto  md:overflow-y-auto`}>
                            <div className="flex items-center justify-between mb-5">
                                <h1 className={`${mode === "member" ?   'text-primary':'text-Default'} text-left text-[1.75rem] lg:text-[2rem] font-medium leading-7 font-decimal ${props.className}`}>{props.title}</h1>
                                <div className="w-5 h-5 cursor-pointer fill-gray-font " onClick={props.toggleModal}>
                                    <CloseModal />
                                </div>
                            </div>
                            <div className="Row">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Modal;
