import AxiosPublic from "../utils/axiosPublic";

const axios = new AxiosPublic();

export const sendPassword = async (password: string, userName: string, userEmail: string) => {
  try {
    const response = await axios.Post(`/password?sendPassword=true`, { password, userName, userEmail });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updatePassword = async (password: string, confirmPassword: string, userName: string) => {
  try {
    const response = await axios.Post("./password?firstTime", {
      userName,
      password,
      confirmPassword
    });
    return response;
  } catch (error) {
    throw error;
  }
};