import React, { useEffect } from "react";
import PersonDataEdit from "../../../components/Moleculas/PersonCatalog/PersonDataEdit";
import PersonSocialEdit from "../../../components/Moleculas/PersonCatalog/PersonSocialEdit";
import PersonOfficeEdit from "../../../components/Moleculas/PersonCatalog/PersonOfficeEdit";
import { useLocation } from "react-router-dom";
import PersonAOPEdit from "../../../components/Moleculas/PersonCatalog/PersonAOPEdit";
import PersonProfessionalCareer from "../../../components/Moleculas/PersonCatalog/PersonProfessionalCareerEdit";
import PersonRelations from "../../../components/Moleculas/PersonCatalog/PersonRelations";
import PersonBioEdit from "../../../components/Moleculas/PersonCatalog/PersonBio";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import usePersonAdd from "../../../hooks/Catalogs/usePersonAdd";
import { PersonCatalogsProps } from "../../../interfaces/models/IPerson";
import '../../../assets/css/AddCatalogs.css'
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import { MemberType } from "../../../Enums/PersonEnums";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
const PersonAdd: React.FC<PersonCatalogsProps> = (props: PersonCatalogsProps) => {
  const { state } = useLocation();
  const [saveOverrideOpen, setSaveOverrideOpen] = React.useState(true);
  const [errorFields, seterrorFields] = React.useState<string[]>([]);
  const { personState, personErrors, offices, officesAdditional, handleAddAdmision, handleMultipleSelectChange, handleInputChange, handleCheckBoxChange, handleRadioButtonChange, handlePhoneNumberChange, handleAddSocialNetworks, handleAddAdditionalOffices, handleTextAreaElement, handleAddlanguages, handlerAreasOfPractice, handleAddIndustryOrganizationAffiliation, handleSubmit, handleDelete, handleButtonClick, setPersonErrors, setRegularPhoto } = usePersonAdd(state ? state : {});

  // check if error open saveOverrideOpen
  useEffect(
    () => {
      if (Object.values(personErrors).some((error) => {
        return error.error === true;
      }
      )) {
        setSaveOverrideOpen(true);
      }

      const fieldsWithError: string[] = Object.entries(personErrors)
        .filter(([key, value]) => value.error)
        .map(([key]) => {
          // Convertir la primera letra a mayúscula
          let formattedKey = key.charAt(0).toUpperCase() + key.slice(1);
          // Separar el nombre del campo en cada letra mayúscula adicional
          formattedKey = formattedKey.replace(/([A-Z])/g, ' $1');
          return formattedKey;
        });

      seterrorFields(fieldsWithError);

      if (fieldsWithError.length === 0) {
        setSaveOverrideOpen(false);
      }

    }, [personErrors]
  )

  return (
    <main className={`mainSection ${saveOverrideOpen ? 'pt-7 md:pt-9 ' : ''}`}>
      <form onSubmit={e => handleSubmit(e, '/Staff/Catalogs/User/Create')}>

        <SaveOverride fields={errorFields} handleButtonClick={handleButtonClick} open={saveOverrideOpen} close={() => setSaveOverrideOpen(false)} />

        <CatalogTitle title={`${props.mode === "add" ? 'New Person' : `Edit ${state?.name}`}`} className='text-Default'>
          <GoBack />
        </CatalogTitle>
        <section className='articleSection'>
          <PersonRelations
            mode={props.mode}
            isContentRedirect={false}
            {...personState}
            Errors={personErrors}
            handleInputChange={handleInputChange}
            handleMultipleSelectChange={handleMultipleSelectChange}
            handleRadioButtonChange={handleRadioButtonChange}
          />          <PersonDataEdit {...personState} setPhoto={setRegularPhoto} offices={offices} Errors={personErrors} handlePhoneNumberChange={handlePhoneNumberChange} handleInputChange={handleInputChange} handleCheckBoxChange={handleCheckBoxChange} />
          {MemberType.member === personState.memberType &&
            <PersonOfficeEdit {...personState} offices={officesAdditional} handleAddAdditionalOffices={handleAddAdditionalOffices} />
          }
          <PersonSocialEdit {...personState} handleAddSosialNetwork={handleAddSocialNetworks} />
          <PersonBioEdit  {...personState} handleTextAreaElement={handleTextAreaElement} handleAddlanguages={handleAddlanguages} handleAddAdmision={handleAddAdmision} />
          <PersonAOPEdit mode={props.mode} {...personState} Errors={personErrors} handlerAreasOfPractice={handlerAreasOfPractice} />
          <PersonProfessionalCareer {...personState} setPersonErrors={setPersonErrors} handleAddIndustryOrganizationAffiliation={handleAddIndustryOrganizationAffiliation} handleTextAreaElement={handleTextAreaElement} />
          <ButtonsCrud
            title={
              personState.memberType === MemberType.nonMember
                ? "Create"
                : "Create and Go to User"
            }
            mode={props.mode}
            id={""}
            onDelete={handleDelete}
            actionButton={handleButtonClick}
            disabled={Object.values(personErrors).some((error) => {
              return error.error === true;
            })}
            Catalog={"Person/"}
            state={state}
            newState={personState}
            customFieldNames={{
              hasAssistant: 'CC assistant on WSG Communications',
            }}
          />
        </section>
      </form>
    </main>
  );
};

export default PersonAdd;