import { axios } from "..";
import IFirmContact from "../interfaces/models/IFirmContact";

export const getFirmContact = async () => {
  try {
    const firmContacts = await axios.Get("./firmContact");
    const data = firmContacts.data;
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const getFirmContactByFirmId = async (id: string) => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({firm: {value: id, comparisonType: 'EQUAL'}}))
    const response = await axios.Get(`./firmContact?${params.toString()}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createFirmContact = async (firmContact: IFirmContact) => {
  try {
    const response = await axios.Post(`/firmContact`, firmContact);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateFirmContact = async (firmContact: any) => {
  try {
    const response = await axios.Put(`/firmContact`, firmContact);
    return response.data;
  } catch (error) {
    console.error("Error updating firm:", error);
    throw error;
  }
};

export const deleteFirmContact = async (id: string): Promise<boolean> => {
  try {
    const response = await axios.Delete(`/firmContact`, `${id}`);
    return response.status === 200;
  } catch (error) {
    console.error(error);
    throw error;
  }
};