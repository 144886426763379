import React, { useEffect, useState } from "react";
import JurisdictionFilter from "../../components/Catalogs/Filters/JurisdictionFilter";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import { UseCountryList } from "../../hooks/Catalogs";
import { UseJurisdictionGroupList } from "../../hooks/Catalogs/useJurisdictionGroup";
import { UseRegionList } from "../../hooks/Catalogs/useRegionListOptions";

import useDataLoader2 from "../../hooks/useDataLoader2";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import { useColumnConfig } from "../../hooks/Table/UseColumnSettings";
import { Link } from "react-router-dom";
import ShapeEdit from "../../components/Atomos/Icons/ShapeEdit";
import { ISubstate } from "../../interfaces";
import Axios from "../../utils/axios";
import { ColumnConfig } from "../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport";
import { formatTimeSpanToString, getNameById } from "../../utils/functions";
import { Option } from "../../components/Atomos/DropDown/DDMultiple";
import { useStatesList } from "../../hooks/Catalogs/useStates";
import { defaultPageSize } from "../../utils/const";

const Jurisdiction: React.FC = ({ ...props }) => {
  const Headers: string[] = [
    "name",
    "legacyId",
    "nickname",
    "isSecondary",
    "partOfMulti",
    "multiType",
    "countryName",
    "stateName",
    "substateName",
    "jurisdictionGroupName",
    "createdDate",
  ];

  const [substates, setAllSubstates] = useState<ISubstate[]>([])

  const [tableInstance, setTableInstance] = useState<any>();
  const fields = [
    'id',
    'name',
    'nickname',
    'country',
    'state',
    'substate',
    'isSecondary',
    'multiType',
    'jurisdictionGroup',
    "countryName",
    "stateName",
    "substateName",
    "jurisdictionGroupName",
    'createdDate'
  ]
  const {
    data,
    totalCount,
    fetchData,
    setFilter,
    sort,
    setSort,
    pagination,
    setPagination
  } = useDataLoader2<any>({
    endpoint: '/jurisdiction',
    defaultPageSize,
    fields
  });

  const isSecondaryData = [
    { id: "No", name: "No" },
    { id: "Yes", name: "Yes" },
  ];
  const multiTypeData = [
    { id: "Regional", name: "Regional" },
    { id: "Multiple", name: "Multiple" },
  ];

  const { statesData } = useStatesList();
  const { countryData } = UseCountryList();
  const { jurisdictionGroupData } = UseJurisdictionGroupList();
  const { regionData, isFetching: isFetchingRegion } = UseRegionList();
  const { generateColumns } = useColumnConfig(Headers);
  const axios = new Axios()
  const [selectedCountries, setSelectedCountries] = useState<Option[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<Option[]>([]);
  const [selectedStates, setSelectedStates] = useState<Option[]>([]);
  const [selectedSubStates, setSelectedSubStates] = useState<Option[]>([]);
  const [selectedJurisdictionGroups, setSelectedJurisdictionGroups] = useState<Option[]>([]);
  const [selectedMultiTypes, setSelectedMultiTypes] = useState<Option[]>([]);
  const [selectedIsSecondary, setSelectedIsSecondary] = useState<Option[]>([]);
  const [nameFilter, setNameFilter] = useState('');
  const [statesByCountry, setStatesByCountry] = useState<any>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getSubStates = async () => {
    try {
      const res = await axios.Get('/substate')
      if (res && res.data) {
        setAllSubstates(res.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getSubStates()
  }, [])

  useEffect(() => {
    setStatesByCountry(statesData.filter(s => selectedCountries.map(se => se.id).includes(s.country)))
  }, [selectedCountries])

  const columnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false, size: 50 },
    { id: 'name', header: 'Name' },
    { id: 'countryName', header: 'Country' },
    { id: 'stateName', header: 'State' },
    { id: 'substateName', header: 'SubState' },
    { id: 'isSecondary', header: 'Is Secondary' },
    { id: 'partOfMulti', header: 'Part of Multi' },
    { id: 'multiType', header: 'Multiple' },
    { id: 'jurisdictionGroup', header: 'Membership Group' },
    { id: 'createdDate', header: 'Created Date' },
    {
      id: 'edit',
      header: 'Edit',
      enableSorting: false,
      cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={props.row.original}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ),
    }
  ];

  const columns = generateColumns(columnConfigs.map(column => ({
    ...column,
    cell: column.cell || ((props: any) => {
      switch (column.id) {
        case 'count':
          return <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
        case 'multiType':
          return <p>{props.row.original?.multiType ? 'Yes' : 'No'}</p>
        case 'isSecondary':
          return <p>{props.row.original?.isSecondary ? 'Yes' : 'No'}</p>
        case 'partOfMulti':
          return <p>{props.row.original?.partOfMulti ? 'Yes' : 'No'}</p>
        case 'jurisdictionGroup':
          return <p>{getNameById(props.getValue(), jurisdictionGroupData)}</p>
        case 'createdDate':
          const value = props.getValue();
          return (
            <p>
              {value && formatTimeSpanToString(value)}
            </p>
          );
        default:
          return <p>{props.getValue()}</p>;
      }
    })
  })));

  const transformedData = data.map(d => {
    return {
      ...d,
      jurisdictionGroupName: jurisdictionGroupData.find(j => j.id === d.jurisdictionGroup)?.name,
      isSecondary: d.isSecondary ? 'Yes' : 'No',
    }
  })

  const clearSelections = () => {
    setSelectedCountries([])
    setSelectedRegions([])
    setSelectedStates([])
    setSelectedSubStates([])
    setSelectedMultiTypes([])
    setSelectedIsSecondary([])
    setSelectedJurisdictionGroups([])
    setNameFilter('')
    setFilter([]);
    fetchData(true);
  };

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  useEffect(() => {
    let filterAux = {
      name: {
        value: nameFilter,
        comparisonType: 'CONTAINS'
      },
      country: {
        value: selectedCountries?.map(c => c.id),
        comparisonType: 'EQUAL'
      },
      regions: {
        value: selectedRegions?.map(f => f.id),
        comparisonType: 'EQUAL'
      },
      state: {
        value: selectedStates?.map(f => f.id),
        comparisonType: 'EQUAL'
      },
      subState: {
        value: selectedSubStates?.map(f => f.id),
        comparisonType: 'EQUAL'
      },
      jurisdictionGroup: {
        value: selectedJurisdictionGroups?.map(f => f.id),
        comparisonType: 'EQUAL'
      },
      multiType: {
        value: selectedMultiTypes.map(f => f.name),
        comparisonType: 'EQUAL'
      },
      isSecondary: {
        value: selectedIsSecondary.map(s => s.name === 'Yes' ? true : false),
        comparisonType: 'EQUAL'
      },
    }
    setFilter(filterAux)
  }, [
    selectedCountries,
    selectedRegions,
    selectedStates,
    selectedSubStates,
    nameFilter,
    selectedJurisdictionGroups,
    selectedMultiTypes,
    selectedIsSecondary
  ]);

  return (
    <>
      <div className=" mainSection">
        <CatalogTableC
          title={`Jurisdiction Catalog`}
          catalogName={"JURISDICTIONS"}
          catalogShortName={"Jurisdiction"}
          route={"Jurisdiction"}
          headers={Headers}
          totalCount={totalCount}
          isfiltered={true}
          data={data}
          exportData={transformedData}
        >
          <JurisdictionFilter
            countryData={countryData}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            regionData={regionData}
            selectedRegions={selectedRegions}
            setSelectedRegions={setSelectedRegions}
            stateData={statesByCountry}
            selectedStates={selectedStates}
            setSelectedStates={setSelectedStates}
            subStateData={substates}
            selectedSubStates={selectedSubStates}
            setSelectedSubStates={setSelectedSubStates}
            jurisdictionGroupData={jurisdictionGroupData}
            selectedJurisdictionGroups={selectedJurisdictionGroups}
            setSelectedJurisdictionGroups={setSelectedJurisdictionGroups}
            multiTypeData={multiTypeData}
            selectedMultiTypes={selectedMultiTypes}
            setSelectedMultiTypes={setSelectedMultiTypes}
            isSecondaryData={isSecondaryData}
            selectedIsSecondary={selectedIsSecondary}
            setSelectedIsSecondary={setSelectedIsSecondary}
            nameFilter={nameFilter}
            onNameFilterChange={setNameFilter}
            order={['country', 'region', 'state', 'substate', 'name', 'jurisdictionGroup', 'multiType', 'isSecondary']}
            search={() => fetchData()}
            resetFilters={clearSelections}
            headers={Headers}
            tableInstance={tableInstance}
          />

          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </div>
    </>
  );
};

export default Jurisdiction;
