import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IContentGeneralTopic } from "../../../interfaces";

const initialState: IContentGeneralTopic[] = [];

export const contentGeneralTopicsSlice = createSlice({
  name: "contentGeneralTopics",
  initialState,
  reducers: {
    SetContentGeneralTopics: (_, action: PayloadAction<IContentGeneralTopic[]>) => action.payload,
    UpdateContentGeneralTopics:(state, action: PayloadAction<IContentGeneralTopic>) => {
      const index = state.findIndex((generalTopic) => generalTopic.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },
    AddContentGeneralTopics: (
      state,
      action: PayloadAction<IContentGeneralTopic>
    ) => {
      state.push(action.payload);
    },
    DeleteContentGeneralTopics: (state, action: PayloadAction<string>) => {
      return state?.filter((city) => city.id !== action.payload);
    },
  },
});

export const {
  SetContentGeneralTopics,
  UpdateContentGeneralTopics,
  AddContentGeneralTopics,
  DeleteContentGeneralTopics,
} = contentGeneralTopicsSlice.actions;
