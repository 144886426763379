import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PreviewComponent from "../../components/SlateEditor/Preview";
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import GoBack from "../../components/Moleculas/GoBack";
import CatalogSubTitle from "../../components/Moleculas/Catalog Text/CatalogSubTitle";
import { UseFirmList } from "../../hooks/Catalogs/useFirm";
import Card from "../../components/Layout/Card/SecondaryCard";
import ProfileViewModeBg from "../../assets/images&icons/profileViewMode.jpeg";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import NewButton from "../../components/Atomos/Buttons/newButton";
import SaveChanges from "../../components/Atomos/Icons/EditMode/SaveChanges";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import { getPersons } from "../../Services/Person";
import ShortPersonCard from "../../components/Moleculas/FirmProfile/ShortPersonCard";
import CommentCard from "../../components/Moleculas/Content/CommentCard";
import MessageIcon from "../../components/Atomos/Icons/MessageIcon";
import PrintIcon from "../../components/Atomos/Icons/PrintIcon";
import ShareIcon from "../../components/Atomos/Icons/ShareIcon";
import FacebookIcon from "../../components/Atomos/Icons/FacebookIcon";
import XIcon from "../../components/Atomos/Icons/XIcon";
import IndeedIcon from "../../components/Atomos/Icons/IndeedIcon";
import RichTextEditor from "../../components/SlateEditor/RichTextEditor";
import ChevronRight from "../../components/Atomos/Icons/ChevronRight";
import CloseIcon from "../../components/Atomos/Icons/Close";
import IFirm from "../../interfaces/models/IFirm";
import Tooltip from "../../components/Atomos/Tooltip/Tooltip";
import InfoIcon from "../../components/Atomos/Icons/InfoIcon";
import PencilEdit from "../../components/Atomos/Icons/PencilEdit";
import { formatDateToString } from "../../utils/functions";
import WarningModal from "../../components/Atomos/Modals/WarningModal";

const placeholderArticles = [
  {
    text: "Compromise for the sake of unity, Deseret News.",
    date: "01/02/2024",
  },
  {
    text: "New York State Education Department Proposes Rules Relating to Affiliations Between New York and Out-of-State Institutions of Higher Education",
    date: "01/02/2024",
  },
  {
    text: "Countersanctions, Foreign Investments, Antitrust and Competition: 2023 Year in Review",
    date: "01/02/2024",
  },
  {
    text: "Official marks in Canada: The prospect of upcoming changes",
    date: "01/02/2024",
  },
  {
    text: "Compromise for the sake of unity, Deseret News.",
    date: "01/02/2024",
  },
  {
    text: "New York State Education Department Proposes Rules Relating to Affiliations Between New York and Out-of-State Institutions of Higher Education",
    date: "01/02/2024",
  },
];
const placeholderPress = [
  {
    text: "Heuking Kühn Lüer Wojtek continues to help Sovereing Centros execute its Festival Place asset management strategy",
    date: "01/02/2024",
  },
  {
    text: "Heuking Kühn Lüer Wojtek advises Moorfield on forward-funding agreement for £120m BTR scheme",
    date: "01/02/2024",
  },
  {
    text: "Heuking Kühn Lüer Wojtek named top M&A legal advisor in the UK",
    date: "01/02/2024",
  },
];
const placeholderCategories = [
  "Government & Public Sector",
  "Transportation",
  "Retail & Distribution items",
];
const placeholderComments = [
  {
    personName: "Simon Curtis",
    personTitle: "Executive",
    firm: "Haynes and Boone",
    comment: `Lance Young, an attorney renowned for his specialization in financial services, brings to the table a wealth of knowledge amassed over a career spanning more than two decades. A graduate of Harvard University's Graduate School of Arts and Sciences, Lance's academic foundations in Economics are rooted in the complexities of global financial systems, monetary policy, and economic regulations. His thesis, "The Interplay between Modern Financial Markets and Central Banking Strategies," has been referenced in several legal and financial courses across prestigious institutions.`,
    date: "February 29th, 2024 | 11:30 AM",
  },
  {
    personName: "Simon Curtis",
    personTitle: "Executive",
    firm: "Haynes and Boone",
    comment: `Lance Young, an attorney renowned for his specialization in financial services, brings to the table a wealth of knowledge amassed over a career spanning more than two decades. A graduate of Harvard University's Graduate School of Arts and Sciences, Lance's academic foundations in Economics are rooted in the complexities of global financial systems, monetary policy, and economic regulations. His thesis, "The Interplay between Modern Financial Markets and Central Banking Strategies," has been referenced in several legal and financial courses across prestigious institutions.`,
    date: "February 29th, 2024 | 11:30 AM",
  },
];

interface PreviewProps {
  canComment?: boolean;
  showComments?: boolean;
  canEdit?: boolean;
  breadcrumb: { prevPage: string; currentPage: string };
  hideModal?: boolean;
  content?: any;
  editmode?: boolean;
  disabledFirm?: boolean;
}

const Preview: React.FC<PreviewProps> = (props) => {
  const { state } = useLocation();
  const [firms, setFirms] = useState<IFirm[]>();
  const [firmNames, setFirmNames] = useState<string>();
  const [personList, setPersonList] = useState([]);
  const [authorList, setAuthorList] = useState([]);
  const [showModal, setShowModal] = useState<boolean>(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [currentContent, setCurrentContent] = useState(state);
  const { firmData } = UseFirmList();

  const navigate = useNavigate();
  useEffect(() => {
    getPersons().then((resp) => setPersonList(resp));

    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (props.content) setCurrentContent(props.content);
  }, [props.content]);

  useEffect(() => {
    if (!personList?.length || !currentContent?.authors?.length) return;

    const authorsData = personList.filter((p: any) =>
      currentContent?.authors?.includes(p.id)
    );
    setAuthorList(authorsData);
  }, [personList]);

  useEffect(() => {
    if (!currentContent?.memberFirms?.length || !firmData.length) return;

    const firmNames = currentContent?.memberFirms?.map((memberFirm: any) => {
      return firmData.find((f: any) => f.id === memberFirm);
    });
    setFirms(firmNames);
    setFirmNames(firmNames.map((f: any) => f?.name).join(", "));
  }, [currentContent, firmData]);

  const socialMedia = () => {
    return (
      <div className="col-span-12 flex flex-row gap-3">
        <div className="flex-none md:flex-grow"></div>
        <div className="flex flex-col -mx-2">
          <div className="flex flex-row px-3 gap-2 justify-center">
            <button className="">
              <IndeedIcon className="text-gray-font fill-current" />
            </button>
            <button className="">
              <XIcon className="text-gray-font fill-current" />
            </button>
            <button className="">
              <FacebookIcon className="text-gray-font fill-current" />
            </button>
            <button className="">
              <ShareIcon className="text-gray-font fill-current" />
            </button>
          </div>
        </div>
        <div className="flex-grow md:flex-none"></div>
        <button className="h-auto flex">
          <PrintIcon className="text-gray-font fill-current mx-1" />
          <span className="text-gray-font">Print Article</span>
        </button>
      </div>
    );
  };
  return (
    <div className="relative w-full">
      <WarningModal
        showModal={showModal}
        setShowModal={setShowModal}
        title="Preview"
        message="This Information may contain unsaved updates. Remember to Save."
      />
      <BackgroundComponent image={ProfileViewModeBg} />

      <div className=" bg-gray-100 min-h-screen text-left px-4 py-7 items-start flex flex-col gap-[1.5rem] md:py-8 md:px-6 lg:py-10 lg:px-10 lg:gap-[2.5rem]">
        <div className="flex flex-col items-start gap-5 self-stretch">
          <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 self-stretch flex-col ">
            <div className="flex flex-row justify-between gap-5 self-stretch lg:justify-between lg:gap-6">
              <h2 className="text-left flex-1 w-full lg:w-max text-white font-decimal text-[2.25rem] font-medium leading-[2.75rem] md:text-[2.75rem] md:leading-[3.25rem] lg:text-[3.25rem] lg:leading-[4.5rem]">
                Preview
              </h2>
              
              <div className="w-auto md:w-[383px] h-[72px] flex flex-row py-2 justify-end">
                {props.editmode ? (
                  <NewButton
                    className="md:!w-1/2"
                    text={"Edit"}
                    icon={<PencilEdit />}
                    color="accent"
                    content="textIcon"
                    size="medium"
                    style="filled"
                    onClick={() =>
                      navigate(`/Staff/${props.disabledFirm 
                        ? 'ManageFirm/Content/' 
                        : 'Content/'}Edit/${state?.id}`, {
                        state: state,
                      })}
                  />
                ) : (
                    <GoBack className="!text-white !w-1/2" />
                )}
              </div>
            </div>

            <hr className="flex h-0 justify-center items-center self-stretch relative" />
            <CurrentPage
              prevPage={props.breadcrumb.prevPage}
              currentPage={props.breadcrumb.currentPage}
            />
            <div className={`flex flex-col bg-white px-10 pb-10 gap-5`}>
              <div className="grid grid-cols-12 lg:gap-10">
                <div className="col-span-12 lg:col-span-9">
                  {viewportWidth < 1024 && (
                    <div className="mt-7">{socialMedia()}</div>
                  )}
                  <CatalogTitle
                    title={currentContent?.title}
                    className="text-Default text-[32px] lg:text-[42px]"
                  />
                  <CatalogSubTitle
                    title={`${currentContent?.writtenDate ? formatDateToString(new Date(currentContent?.writtenDate)): ''}  - 
                    ${firmNames ?? ""}`}
                    className="text-disclosure-primary-dark flex flex-col sm:flex-row gap-2 justify-between w-full "
                  />

                  {props.canComment && (
                    <div className="flex flex-row mt-4">
                      <button className="flex">
                        <MessageIcon className="text-gray-font fill-current my-1 mr-1" />
                        <span className="text-gray-font">Post a comment</span>
                      </button>
                    </div>
                  )}
                </div>
                <div className="col-span-12 lg:col-span-3 flex flex-col justify-between lg:mt-10 lg:mb-4 ">
                  {viewportWidth > 1024 && socialMedia()}

                  <div className="flex-grow flex m-5 items-center justify-center md:justify-start lg:justify-center text-accent-Default-dark font-bold max-h-20 lg:max-h-44">
                    <img
                      src={(firms && firms[0]?.logo) ?? ""}
                      className="border-none h-full"
                    />
                  </div>

                  <button className="self-center md:self-start lg:self-end mt-auto text-primary">
                    {`More ${firms ? firms[0]?.name : ""} Articles`}
                  </button>
                </div>
              </div>
              <hr className="flex h-0 justify-center items-center" />
              <div className="flex flex-col gap-5">
                <div className="grid grid-cols-12 gap-y-5 md:gap-10">
                  <div className="col-span-12 lg:col-span-8 ">
                    <PreviewComponent
                      showMessage={false}
                      value={currentContent?.articleContent ?? ""}
                      className="text-lg"
                    />
                    <PreviewComponent
                      showMessage={false}
                      value={currentContent?.footnotes ?? ""}
                      className="text-lg pt-5"
                    />
                  </div>
                  <div className="col-span-12 lg:col-span-4 grid grid-cols-12 gap-y-5 md:gap-10 lg:gap-x-0">
                    <div className="col-span-12 md:col-span-6 lg:col-span-12">
                      <Card
                        title="Related Articles"
                        items={placeholderArticles}
                        categories={placeholderCategories}
                        itemText="Articles"
                      />
                    </div>
                    <div className="col-span-12 md:col-span-6 lg:col-span-12">
                      <Card
                        title="Latest Press"
                        subtitle={firms ? firms[0]?.name : ""}
                        items={placeholderPress}
                        itemText="Articles"
                      />
                    </div>
                  </div>
                </div>                
                <div className="grid grid-cols-12 flex gap-5">
                  <div className="col-span-12 lg:col-span-9">
                    <span className="font-bold text-lg">Categories: </span>
                    <span className=" text-lg text-Default">
                      {placeholderCategories.join(" | ")}
                    </span>
                  </div>
                  <div className="col-span-12 md:col-span-3 ">
                    <NewButton
                      text={"Link to article"}
                      icon={<ChevronRight />}
                      color="accent"
                      content="textIcon"
                      size="medium"
                      style="filled"
                    />
                  </div>
                </div>
                <div className="text-primary text-[32px]">Authors</div>
                <div className="flex flex-col md:flex-row md:flex-wrap lg:flex-nowrap lg:overflow-x-scroll gap-10">
                  {authorList.map((author: any) => (
                    <ShortPersonCard
                      key={author.id}
                      person={author}
                      className="md:w-1/2 lg:w-auto"
                    />
                  ))}
                </div>

                {props.showComments && (
                  <div className="flex flex-col gap-5">
                    <hr className="flex h-0 justify-center items-center" />
                    <div className="text-primary text-[32px]">
                      Member Comments
                    </div>
                    {placeholderComments.map((comment: any) => (
                      <CommentCard
                        personName={comment.personName}
                        personTitle={comment.personTitle}
                        firm={comment.firm}
                        comment={comment.comment}
                        date={comment.date}
                        canEdit={props.canEdit}
                      />
                    ))}
                  </div>
                )}
                {props.canComment && (
                  <RichTextEditor title="Comment" characterCount />
                )}
              </div>
              <div className="flex flex-col md:flex-row gap-5 justify-end">
                <div className="flex items-center border-2 border-gray-font font-medium py-2 px-4 text-lg text-gray-font justify-center cursor-help">
                  <Tooltip
                    text="Disclaimer info"
                    className="flex justify-center"
                  >
                    DISCLAIMER
                    <InfoIcon className="fill-current text-gray-font ml-2" />
                  </Tooltip>
                </div>
                {props.canComment && (
                  <div>
                    <NewButton
                      text="Comment"
                      content="textIcon"
                      size="medium"
                      icon={<MessageIcon className="fill-current my-1" />}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
