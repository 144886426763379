import { ICountry } from "../interfaces";
import Axios from "../utils/axios";  
const axios = new Axios(); 

export const getCountryList = async () => {
    try {
        const response = await axios.Get('./country');
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    } 
}

export const getCountries = async (): Promise<ICountry[]> => {
    try {
        const countries = await axios.Get('./country');
        const data = countries.data;
        if (data && data != null) {   
            return countries.data.map((country: ICountry) => ({ id: country.id, name: country.name, abbreviation: country.abbreviation, hasState: country.hasState, UpdatedDate: country._ts }));
        }
        return countries.data as ICountry[];
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    } 
}

export const getCountryById = async (id:string) => {
    const country = await axios.Get('country/?id='+id);
    return country.data as ICountry;
}

export const createCountry = async (country:ICountry) => {
  try {
    const response = await axios.Post('/country',country);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateCountry = async (country:ICountry) => {
  try {
    const response = await axios.Put('/country',country);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deleteCountry = async (id:string) => {
    try {
    const response = await axios.Delete('country/',id);
    if(response.status === 200) return true;
    return false;
    } catch (error) {
        console.error('Error deleting country:', error);
        return false;
    }
    
}