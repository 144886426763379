import { useLogging } from "../../Context/LoggingContext";
import { ICity, IContent, IContentGeneralTopic, ICountry, IJurisdiction, IJurisdictionGroup, IRegion, IState } from "../../interfaces";
import IContentTag from "../../interfaces/models/IContentTag";
import IDataAdmin from "../../interfaces/models/IDataAdmin";
import IFirm from "../../interfaces/models/IFirm";
import IFirmContact from "../../interfaces/models/IFirmContact";
import { IRepresentativeClient } from "../../interfaces/models/IRepresentativeClient";
import IGlobalCrisis from "../../interfaces/models/IGlobalCrisis";
import IOfficeContact from "../../interfaces/models/IOfficeContact";
import { IPracticeIndustry } from "../../interfaces/models/IPracticeIndustry";
import { deleteCity } from "../../Services/City";
import { deleteContent } from "../../Services/Content";
import { deleteContentGeneralTopics } from "../../Services/ContentGeneralTopic";
import { deleteContentTag } from "../../Services/ContentTag";
import { deleteCountry } from "../../Services/Country";
import { deleteDataAdmin } from "../../Services/DataAdmin";
import { deleteFirm } from "../../Services/Firm";
import { deleteFirmContact } from "../../Services/FirmContact";
import { deleteRepresentativeClient } from "../../Services/RepresentativeClient";
import { deleteGlobalCrisis } from "../../Services/GlobalCrisis";
import { deleteJurisdiction } from "../../Services/Jurisdiction";
import { deleteJurisdictionGroup } from "../../Services/JurisdictionGroup";
import { deleteOfficeContact } from "../../Services/MemberMainContact";
import { deletePracticeIndustry } from "../../Services/PracticeIndustry";
import { deleteRegion } from "../../Services/Region";
import { deleteState } from "../../Services/State";
import { IEducation } from "../../interfaces/models/IEducation";
import { deleteEducation } from "../../Services/Education";

interface IUseGeneral {
  pathname: string;
}

interface Ifirm extends IUseGeneral { firm: IFirm; }
interface IdataAdmin extends IUseGeneral { dataAdmin: IDataAdmin}
interface IfirmContact extends IUseGeneral { firmContact: IFirmContact; }
interface Icontent extends IUseGeneral { content: IContent; firmId: string;}
interface IrepresentativeClient extends IUseGeneral { representativeClient: IRepresentativeClient; firmId: string;}
interface Icity extends IUseGeneral { city: ICity; }
interface IgeneralTopic extends IUseGeneral { generalTopic: IContentGeneralTopic; }

interface IcontentTag extends IUseGeneral { contentTag: IContentTag; }
interface Icountry extends IUseGeneral { country: ICountry; }
interface IglobalCrisis extends IUseGeneral { globalCrisis: IGlobalCrisis; }
interface Ijurisdiction extends IUseGeneral { jurisdiction: IJurisdiction; }
interface IjurisdictionGroup extends IUseGeneral { jurisdictionGroup: IJurisdictionGroup; }
interface IpracticeIndustry extends IUseGeneral { practiceIndustry: IPracticeIndustry; }
interface Iregion extends IUseGeneral { region: IRegion; }
interface Istate extends IUseGeneral { state: IState; }
interface IofficeContact extends IUseGeneral { officeContact: IOfficeContact; }
interface Ieducation extends IUseGeneral { education: IEducation;}

interface IDeleteAndLog {
  deleteFunction: Function;
  entity: any;
  entityName: string;
  pathname: string;
  firmId?: string
}

const action = "DELETE_";

export const useDeleteAndLog = () => {
  const { logActivity } = useLogging();

  const deleteAndLog: (params: IDeleteAndLog) => Promise<any> = async ({
    deleteFunction, 
    entity, 
    entityName, 
    pathname, 
    firmId
  }) => {
    try {
      const response = await deleteFunction(entity.id);
      logActivity(
        `${action}${entityName}`,
        pathname,
        JSON.stringify(entity),
        firmId
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const firm = async ({firm, pathname}: Ifirm) => {
    return deleteAndLog({
      deleteFunction: deleteFirm, 
      entity: firm,
      entityName: "FIRM",
      pathname,
      firmId: firm.id
    });
  };

  const dataAdmin = async ({dataAdmin, pathname}: IdataAdmin) => {
    return deleteAndLog({
      deleteFunction: deleteDataAdmin, 
      entity: dataAdmin,
      entityName: "DATA_ADMIN",
      pathname,
      firmId: dataAdmin.firm
    });
  };

  const firmContact = async ({firmContact, pathname}: IfirmContact) => {
    return deleteAndLog({
      deleteFunction: deleteFirmContact, 
      entity: firmContact,
      entityName: "FIRM_CONTACT",
      pathname,
      firmId: firmContact.firm
    });
  };

  const content = async ({content, pathname, firmId}: Icontent) => {
    return deleteAndLog({
      deleteFunction: deleteContent, 
      entity: content,
      entityName: "CONTENT",
      pathname,
      firmId
    });
  };

  const representativeClient = async ({representativeClient, pathname, firmId}: IrepresentativeClient) => {
    return deleteAndLog({
      deleteFunction: deleteRepresentativeClient, 
      entity: representativeClient,
      entityName: "REPRESENTATIVE_CLIENT",
      pathname,
      firmId
    });
  };

  const city = async ({city, pathname}: Icity) => {
    return deleteAndLog({
      deleteFunction: deleteCity, 
      entity: city,
      entityName: "CITY",
      pathname,
    });
  };

  const contentGeneralTopic = async ({generalTopic, pathname}: IgeneralTopic) => {
    return deleteAndLog({
      deleteFunction: deleteContentGeneralTopics, 
      entity: generalTopic,
      entityName: "CONTENT_GENERAL_TOPIC",
      pathname,
    });
  };

  const contentTag = async ({ contentTag, pathname }: IcontentTag) => {
    return deleteAndLog({
      deleteFunction: deleteContentTag,
      entity: contentTag,
      entityName: "CONTENT_TAG",
      pathname,
    });
  };

  const country = async ({ country, pathname }: Icountry) => {
    return deleteAndLog({
      deleteFunction: deleteCountry,
      entity: country,
      entityName: "COUNTRY",
      pathname,
    });
  };

  const globalCrisis = async ({ globalCrisis, pathname }: IglobalCrisis) => {
    return deleteAndLog({
      deleteFunction: deleteGlobalCrisis,
      entity: globalCrisis,
      entityName: "GLOBAL_CRISIS",
      pathname,
    });
  };

  const jurisdiction = async ({ jurisdiction, pathname }: Ijurisdiction) => {
    return deleteAndLog({
      deleteFunction: deleteJurisdiction,
      entity: jurisdiction,
      entityName: "JURISDICTION",
      pathname,
    });
  };

  const jurisdictionGroup = async ({
    jurisdictionGroup,
    pathname,
  }: IjurisdictionGroup) => {
    return deleteAndLog({
      deleteFunction: deleteJurisdictionGroup,
      entity: jurisdictionGroup,
      entityName: "JURISDICTION_GROUP",
      pathname,
    });
  };

  const practiceIndustry = async ({
    practiceIndustry,
    pathname,
  }: IpracticeIndustry) => {
    return deleteAndLog({
      deleteFunction: deletePracticeIndustry,
      entity: practiceIndustry,
      entityName: "PRACTICE_INDUSTRY",
      pathname,
    });
  };

  const region = async ({ region, pathname }: Iregion) => {
    return deleteAndLog({
      deleteFunction: deleteRegion,
      entity: region,
      entityName: "REGION",
      pathname,
    });
  };
  
  const state = async ({ state, pathname }: Istate) => {
    return deleteAndLog({
      deleteFunction: deleteState,
      entity: state,
      entityName: "STATE",
      pathname,
    });
  };

  const officeContact = async ({ officeContact, pathname }: IofficeContact) => {
    return deleteAndLog({
      deleteFunction: deleteOfficeContact,
      entity: officeContact,
      entityName: "OFFICE_CONTACT",
      pathname,
    });
  };

  const education = async ({ education, pathname }: Ieducation) => {
    return deleteAndLog({
      deleteFunction: deleteEducation,
      entity: education,
      entityName: "EDUCATION",
      pathname,
    });
  };

  return {
    firm,
    dataAdmin,
    firmContact,
    content, representativeClient,
    city,
    contentGeneralTopic,
    contentTag,
    country,
    globalCrisis,
    jurisdiction,
    jurisdictionGroup,
    practiceIndustry,
    region,
    state,
    officeContact,
    education
  };
};
