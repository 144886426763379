import { axios } from "..";
import { IDegree } from "../interfaces";

export const getDegrees = async (): Promise<IDegree[]> => {
  try {
    const response = await axios.Get('./degree');
    return response.data;
  } catch (error) {
    console.error(error);
    throw error
  }
};

export const addDegree = async (degree: IDegree): Promise<any> => {
  try {
    const response = await axios.Post('./degree', degree);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error
  }
};
