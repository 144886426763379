import React from "react";

interface AreaOfPracticeFrameProps {
  name?: string;
}

const AreaOfPracticeFrame: React.FC<AreaOfPracticeFrameProps> = ({ name }) => {
  return (
    <div className=" px-4 py-2 font-normal lg:text-lg lg:leading-5 text-base leading-6 text-gray-300 border border-gray-300 bg-gray-25">
      {name}
    </div>
  );
};

export default AreaOfPracticeFrame;
