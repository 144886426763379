function MonitorIcon() {
	return (
		<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
			width="22.000000pt" height="22.000000pt" viewBox="0 0 512.000000 512.000000"
			preserveAspectRatio="xMidYMid meet" fill="white"
		>

			<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
			<path d="M1130 4625 c-337 -68 -587 -329 -640 -665 -8 -55 -10 -317 -8 -965 4
			-964 0 -904 58 -1057 66 -177 261 -372 438 -438 142 -54 143 -54 555 -58 l387
			-3 0 -319 0 -318 -109 -4 c-92 -2 -114 -6 -137 -24 -53 -39 -69 -71 -69 -134
			0 -63 16 -95 69 -134 27 -21 34 -21 886 -21 852 0 859 0 886 21 53 39 69 71
			69 134 0 63 -16 95 -69 134 -23 18 -45 22 -137 24 l-109 4 0 318 0 319 388 3
			c411 4 412 4 554 58 177 66 372 261 438 438 58 153 55 98 55 1102 0 1004 3
			949 -55 1102 -29 77 -104 191 -175 261 -68 70 -184 148 -263 177 -157 59 -41
			55 -1557 57 -1119 1 -1403 -1 -1455 -12z m2825 -320 c172 -45 305 -179 350
			-352 22 -86 22 -1740 0 -1826 -45 -173 -178 -307 -350 -352 -52 -13 -228 -15
			-1395 -15 -1167 0 -1343 2 -1395 15 -172 45 -305 179 -350 352 -22 86 -22
			1740 0 1826 45 171 177 306 346 351 84 23 2708 23 2794 1z m-1075 -3185 l0
			-320 -320 0 -320 0 0 320 0 320 320 0 320 0 0 -320z"/>
			<path d="M1215 2386 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 24
			-7 160 -10 394 -8 342 3 358 4 384 24 53 39 69 71 69 134 0 63 -16 95 -69 134
			-26 20 -41 21 -394 23 -290 2 -373 0 -397 -11z"/>
			</g>
		</svg>
	);
}

export default MonitorIcon;
