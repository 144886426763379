import BackgroundComponent from "../../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from "../../../assets/images&icons/profileViewMode.jpeg";
import CurrentPage from "../../../components/Layout/CurrentPageTab/CurrentPage";
import { useEffect, useState } from "react";
import CatalogTableC from "../../../components/Catalogs/CatalogTableC";
import { professionalInitialState } from "../../../interfaces";
import TableWithPagination from "../../../components/Organismos/Table/TableTransitionToTanSack2";
import {
  ColumnConfig,
  useColumnConfig,
} from "../../../hooks/Table/UseColumnSettings";
import { Link, useNavigate } from "react-router-dom";
import ShapeEdit from "../../../components/Atomos/Icons/ShapeEdit";
import useDataLoader2 from "../../../hooks/useDataLoader2";
import ProfessionalProfileFilter from "../../../components/Catalogs/Filters/ProfessionalProfileFilter";
import { getPersonById, searchableProfiles } from "../../../Services/Person";
import { getOfficeByFirm } from "../../../Services/Office";
import IOffice from "../../../interfaces/models/IOffice";
import { getAOPRankings } from "../../../Services/AOPRanking";
import { IAOPRanking } from "../../../interfaces/models/IAOPRanking";
import { useDispatch, useSelector } from "react-redux";
import { Appstore, personSlice } from "../../../redux";
import { axios } from "../../..";
import { getUsers } from "../../../Services/User";
import DataAdminProfile from "./DataAdminProfile";
import NewButton from "../../../components/Atomos/Buttons/newButton";
import PencilEdit from "../../../components/Atomos/Icons/Bars";
import { defaultPageSize } from "../../../utils/const";
import { getLastLogin } from "../../../Services/ActivityLogger";
import { hideLoader, showLoader } from "../../../redux/loaderActions";
const WSGDots = require("../../../assets/images&icons/WSGDots.svg");

interface contentProps {
  breadcrumb: { prevPage: string; currentPage: string };
}

interface IKPI {
  totalProfile?: number;
  totalSearchable?: number;
  totalNonSearchable?: number;
}

const Professionals: React.FC<contentProps> = (props) => {
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);
  const [tableInstance, setTableInstance] = useState<any>();
  const [KPI, setKPI] = useState<IKPI>({
    totalProfile: undefined,
    totalSearchable: undefined,
    totalNonSearchable: undefined,
  });
  const dispatch = useDispatch();

  const Headers: string[] = [
    "name",
    'legacyId',
    "title",
    "firmEmail",
    "mainOffice",
    "AOPRanking",
    "isSearchable",
    "multipleOffices",
  ];
  const { generateColumns } = useColumnConfig(Headers);
  const [professionalFilters, setProfessionalFilters] = useState<any>({
    ...professionalInitialState,
    firm: { value: manageFirm.selectedFirm?.id, comparisonType: "EQUAL" },
  });
  const [professionalData, setProfessionalData] = useState<any[]>([]);
  const [officeData, setOfficeData] = useState<IOffice[]>([]);
  const [AOPRankingData, setAOPRankingData] = useState<IAOPRanking[]>([]);
  const [dataAdminData, setDataAdminData] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    data,
    totalCount,
    fetchData,
    setFilter,
    sort,
    setSort,
    pagination,
    setPagination,
    setPersistentFilter,
    persistentFilter
  } = useDataLoader2<any>({
    endpoint: "/person",
    defaultPageSize,
  });

  useEffect(() => {
    if (
      !manageFirm.selectedFirm || 
      !data.length || 
      AOPRankingData.length ||
      officeData.length ||
      dataAdminData.length 
    ) return
    fetchCatalogs();
    dispatch(personSlice.actions.ResetPerson());
    dispatch(personSlice.actions.ModifyPerson({ firm: manageFirm.selectedFirm?.id }));
  }, [manageFirm.selectedFirm, data]);

  const fetchCatalogs = async () => {
    try {
      if (!manageFirm.selectedFirm) return
      const [offices, aop, dataAdmin] = await Promise.all([
        getOfficeByFirm(manageFirm.selectedFirm.id),
        getAOPRankings(),
        getDataAdminByFirm(manageFirm.selectedFirm.id),
      ]);
      setOfficeData(offices);
      setAOPRankingData(aop);
  
      let peopleAdmin = await Promise.all(
        dataAdmin.map(async (da) => {
          const person = (await getPersonById(da.person));
          const lastLogin = person?.userId ? await getLastLogin(person.userId) : "";
          return { ...person, ...da, lastLogin };
        })
      );
  
      setDataAdminData(peopleAdmin);
    } catch (error) {
      console.error(error);
    }
  };

  const getDataAdminByFirm = async (firmId: string): Promise<any[]> => {
    try {
      const params = new URLSearchParams();
      params.append(
        "filter",
        JSON.stringify({ firm: { value: firmId, comparisonType: "EQUAL" } })
      );
      const dataAdmin = await axios.Get(`./dataAdmin?${params.toString()}`);
      return dataAdmin.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  useEffect(() => {
    if (!manageFirm.selectedFirm || KPI.totalSearchable || KPI.totalNonSearchable) return;
    updateKPI({ firm: { value: manageFirm.selectedFirm?.id, comparisonType: "EQUAL" } });
  }, [manageFirm.selectedFirm]);

  const updateKPI = async (filtersAux: any) => {
    try {
      const resp = await searchableProfiles(filtersAux);
      setKPI({
        totalProfile: resp?.searchable + resp?.nonSearchable,
        totalSearchable: resp?.searchable,
        totalNonSearchable: resp?.nonSearchable,
      });
    } catch (error) {
      console.error("Error updating KPI:", error);
    }
  };

  const columnConfigs: ColumnConfig[] = [
    { id: "count", header: "#", enableSorting: false },
    { 
      id: "name", 
      header: "Name", 
      cell: (props: any) => (
        <Link
        onClick={() =>
          dispatch(personSlice.actions.ModifyPerson(data[props.row.id]))
        }
        className="link !text-lg"
        to={`/Staff/ManageFirm/Professionals/${manageFirm.selectedFirm?.id}/Attorney/${data[props.row.id]?.generatedId}`}
      >
        
       {props.getValue()}
      </Link>
      )
    },
    { id: "title", header: "Title / Unlisted Title" },
    { id: "firmEmail", header: "Email" },
    { id: "mainOffice", header: "Main Office" },
    { id: "AOPRanking", header: "AOP Ranking" },
    { id: "isSearchable", header: "Searchable" },
    { id: "multipleOffices", header: "Multiple Offices" },
    {
      id: "manage",
      header: "Actions",
      cell: (props: any) => (
        <div className="flex">
          <Link
            onClick={() =>
              dispatch(personSlice.actions.ModifyPerson(data[props.row.id]))
            }
            className="edit"
            to={`/Staff/ManageFirm/Professionals/${manageFirm.selectedFirm?.id}/Edit/`}
          >
            Edit
            <div className="editsvg">
              <ShapeEdit />
            </div>
          </Link>
          <Link
            className="edit"
            to={`/Staff/ManageFirm/Professionals/${manageFirm.selectedFirm?.id}/Logs/`}
            state={data[props.row.id]}
          >
            Activity Log
            <div className="editsvg">
              <ShapeEdit />
            </div>
          </Link>
        </div>
      ),
      enableSorting: false,
    },
  ];

  const columns = generateColumns(
    columnConfigs.map((column) => ({
      ...column,
      cell:
        column.cell ||
        ((props: any) => {
          if (column.id === "count") {
            return (
              <p>
                {props.row.index +
                  1 +
                  pagination.pageIndex * pagination.pageSize}
              </p>
            );
          } else {
            return props.getValue();
          }
        }),
    }))
  );

  const clearSelections = () => {
    setProfessionalFilters(professionalInitialState);
    fetchData(true);
  };

  const formatData = (data: any[]) => {
    const formatedData = data.map((d: any) => {
      return {
        ...d,
        isSearchable:
          d.isSearchable === true
            ? "Yes"
            : d.isSearchable === false
              ? "No"
              : "",
        multipleOffices:
          d.multipleOffices === true
            ? "Yes"
            : d.multipleOffices === false
              ? "No"
              : "",
        name: [d.suffix, d.name, d.lastName].filter(Boolean).join(" "),
        mainOffice: d.mainOfficeName,
        AOPRanking: d.AOPRankingNames,
      };
    });
    return formatedData;
  };

  useEffect(() => {
    setPersistentFilter({firm: { value: manageFirm.selectedFirm?.id, comparisonType: "EQUAL" }});
  }, []);

  useEffect(() => {
    if (data) setProfessionalData(formatData(data));
  }, [data]);

  useEffect(() => {
    if (
      !!officeData.length ||
      !!AOPRankingData.length ||
      !persistentFilter ||
      isFetching
    )
      return;
    fetchData();
    setIsFetching(true);
  }, [officeData, AOPRankingData, persistentFilter]);

  useEffect(() => {
    let filterAux = {
      names: {
        value: professionalFilters.name,
        comparisonType: "CONTAINS",
      },
      offices: {
        value: professionalFilters.offices?.map((o: any) => o.id),
        comparisonType: "EQUAL",
      },
      title: {
        value: professionalFilters.title,
        comparisonType: "CONTAINS",
      },
      isSearchable: {
        value: professionalFilters.isSearchable?.map((i: any) =>
          i.id === "no" ? false : true
        ),
        comparisonType: "EQUAL",
      },
      multipleOffices: {
        value: professionalFilters.multipleOffice?.map((m: any) =>
          m.id === "no" ? false : true
        ),
        comparisonType: "EQUAL",
      },
      firm: {
        value: manageFirm.selectedFirm?.id,
        comparisonType: "EQUAL",
      },
    };
    setFilter(filterAux);
  }, [professionalFilters]);

  return (
    <>
      <BackgroundComponent image={ProfileViewModeBg} />
      <div className="bg-gray-100 min-h-screen text-left px-4 py-7 items-start flex flex-col gap-[1.5rem] md:py-8 md:px-6 lg:py-10 lg:px-10 lg:gap-[2.5rem]">
        <div className="flex flex-col items-start gap-5 self-stretch">
          <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 self-stretch !p-0 flex-col ">
            <div className="flex p-0 flex-row justify-center gap-5 self-stretch md:flex-row lg:justify-between lg:gap-6">
              <h2 className="text-left w-full lg:w-max text-white font-decimal text-[2.25rem] font-medium leading-[2.75rem] md:text-[2.75rem] md:leading-[3.25rem] lg:text-[3.25rem] lg:leading-[4.5rem]">
                Professional Profiles
              </h2>
              <div
                className="self-stretch flex-1 hidden lg:flex"
                style={{
                  backgroundImage: `url(${WSGDots.default})`,
                }}
              ></div>
              <div className="flex h-[4rem] w-fit items-center">
                <NewButton
                  onClick={()=> navigate("Logs")}
                  text={"LOG"}
                  icon={<PencilEdit />}
                  color="accent"
                  content="textIcon"
                  size="large"
                  style="filled"
                />
              </div>
            </div>
            <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
            <div className="flex w-full">
              <CurrentPage
                prevPage={props.breadcrumb.prevPage}
                currentPage={props.breadcrumb.currentPage}
              />
            </div>
            <div className="flex w-full text-center">
              <div className="w-full flex gap-5">
                <div className="flex flex-col w-[260px]">
                  <div className="bg-Default flex justify-center items-center h-[56px] text-xs lg:text-base text-white py-[3px] px-4">
                    TOTAL PROFILES
                  </div>
                  <div className="bg-white text-[26px] md:text-[28px] lg:text-[32px] font-medium p-4">
                    {KPI.totalProfile || "-"}
                  </div>
                </div>
                <div className="flex flex-col w-[260px]">
                  <div className="bg-Default flex justify-center items-center h-[56px] text-xs lg:text-base text-white py-[3px] px-4">
                    TOTAL SEARCHABLE
                  </div>
                  <div className="bg-white text-[26px] md:text-[28px] lg:text-[32px] font-medium p-4">
                    {KPI.totalSearchable || "0"}
                  </div>
                </div>
                <div className="flex flex-col w-[260px]">
                  <div className="bg-Default flex justify-center items-center h-[56px] text-xs lg:text-base text-white py-[3px] px-4">
                    TOTAL NON-SEARCHABLE
                  </div>
                  <div className="bg-white text-[26px] md:text-[28px] lg:text-[32px] font-medium p-4">
                    {KPI.totalNonSearchable || "0"}
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <CatalogTableC
                catalogName="PROFESSIONALS PROFILES"
                catalogShortName="PROFESSIONAL"
                route={"Professional"}
                isfiltered={true}
                data={professionalData}
                headers={Headers}
                totalCount={totalCount}
                exportData={professionalData}
                linkToAdd={`/Staff/ManageFirm/Professionals/${manageFirm.selectedFirm?.id}/Create`}
                state={{ firm: manageFirm.selectedFirm?.id  }}
                dataAdminChildren={
                  <DataAdminProfile 
                    dataAdminData={dataAdminData}
                  />
                }
              >
                <ProfessionalProfileFilter
                  filters={professionalFilters}
                  setFilters={setProfessionalFilters}
                  headers={Headers}
                  clearSelections={clearSelections}
                  Search={() => fetchData()}
                  tableInstance={tableInstance}
                  officeData={officeData}
                />
                <TableWithPagination
                  data={professionalData}
                  columnsDef={columns}
                  setTableInstance={setTableInstance}
                  sorting={sort}
                  setSorting={setSort}
                  totalCount={totalCount}
                  pagination={pagination}
                  setPagination={setPagination}
                />
              </CatalogTableC>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Professionals;
