import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useStaffMode } from "../../../hooks/StaffModeProvider";
import { useSelector } from "react-redux";
import { Appstore } from "../../../redux";
import AuthContext from "../../../store/AuthContext";
import worldBg from "../../../assets/images&icons/world-bg.png";
import ChevronDown from "../../Atomos/Icons/ChevronDown";
import EmailIcon from "../../Atomos/Icons/EmailIcon";
import Tools from "../../Atomos/Icons/Tools";
import Sync from "../../Atomos/Icons/Sync";
import WorldServicesGroupIcon from "../../Atomos/Icons/WorldServicesGroupIcon";
import VCard from "../../Atomos/Icons/ViewMode/VCard";
import Groups from "../../Atomos/Icons/ViewMode/Groups";
import LocalActivity from "../../Atomos/Icons/LocalActivity";
import Bar_chart_4_bars from "../../Atomos/Icons/Bar_chart_4_bars";
import Accounting from "../../Atomos/Icons/Accounting";
import Blogs from "../../Atomos/Icons/Blogs";
import Insights from "../../Atomos/Icons/Insights";
import Newsletter from "../../Atomos/Icons/Newsletter";
import Resources from "../../Atomos/Icons/Resources";
import WSGLearningInstitute from "../../Atomos/Icons/WSGLearningInstitute";
import Global from "../../Atomos/Icons/Global";
import IndividualSearch from "../../Atomos/Icons/IndividualSearch";
import Update from "../../Atomos/Icons/Update";
import Catalogs from "../../Atomos/Icons/Catalogs";
import Lists from "../../Atomos/Icons/Lists";
import Categories from "../../Atomos/Icons/Categories";
import New from "../../Atomos/Icons/New";
import ChevronRight from "../../Atomos/Icons/ChevronRight";
import "./Navigation.module.css";

interface MenuItem {
  name: string;
  to?: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
}

const memberMenu: MenuItem[] = [
  { to: "/about-us", name: "About Us", icon: <WorldServicesGroupIcon /> },
  {
    to: "/members",
    name: "Member List",
    icon: <VCard className="text-white " />,
  },
  { to: "/groups", name: "Groups", icon: <Groups /> },
  {
    name: "Blogs",
    icon: <Blogs />,
    children: [
      { to: "/blog-posts", name: "Blog Posts" },
    ],
  },
  { to: "/events", name: "Events", icon: <LocalActivity /> },
  { to: "/newsletters", name: "Newsletter", icon: <Newsletter /> },
  {
    name: "Insights",
    icon: <Insights />,
    children: [
      { to: "/gc-insights", name: "GC Insights" },
      { to: "/featured", name: "Featured" },
      { to: "/wsg-blog", name: "WSG Blog" },
    ],
  },
  {
    name: "Resources",
    icon: <Resources />,
    children: [
      {
        name: "News & Articles",
        children: [
          { to: "/articles", name: "Articles" },
          { to: "/release", name: "Press" },
          { to: "/collaborations", name: "WSG Collaborations" },
          { to: "/newsletters", name: "WSG Newsletter" },
          { to: "/latest-news", name: "Latest World News" },
          { to: "/submit-article", name: "Submit Articles" },
          { to: "/submit-release", name: "Submit Press" },
          { to: "/submit-event", name: "Submit Events" },
        ],
      },
      { to: "/wsg-knowledge-center", name: "WSG Knowledge Center" },
      { to: "/secondments", name: "Secondments" },
      { to: "/dealmakers", name: "Dealmakers" },
      { to: "/help-center", name: "Help Center" },
    ],
  },
  {
    name: "WSG Learning Institute",
    icon: <WSGLearningInstitute />,
    children: [
      { to: "/leadership-program", name: "WSG Leadership Training Program" },
      { to: "/marketing-workshop", name: "WSG Marketing Workshop" },
      { to: "/emeritus-program", name: "WSG and Emeritus Program" },
    ],
  },
  {
    name: "Global Crisis Hub",
    icon: <Global />,
    children: [
      { to: "/covid-19", name: "Covid-19 Crisis" },
      { to: "/ukraine-crisis", name: "Ukraine Crisis" },
    ],
  },
];

const staffMenu: MenuItem[] = [
  { to: "/about-us", name: "About Us", icon: <WorldServicesGroupIcon /> },
  {
    to: "/members",
    name: "Member List",
    icon: <VCard className="text-white " />,
  },
  {
    name: "Blogs",
    icon: <Blogs />,
    children: [
      { to: "/blogroll", name: "Blogroll" },
      { to: "/blog-posts", name: "Blog Posts" },
    ],
  },
  { to: "/newsletters", name: "Newsletter", icon: <Newsletter /> },
  {
    name: "Insights",
    icon: <Insights />,
    children: [
      { to: "/gc-insights", name: "GC Insights" },
      { to: "/member-blogs", name: "Member Blogs" },
      { to: "/featured", name: "Featured" },
      { to: "/wsg-blog", name: "WSG Blog" },
    ],
  },
  {
    name: "WSG Learning Institute",
    icon: <WSGLearningInstitute />,
    children: [
      { to: "/leadership-program", name: "WSG Leadership Training Program" },
      { to: "/marketing-workshop", name: "WSG Marketing Workshop" },
      { to: "/emeritus-program", name: "WSG and Emeritus Program" },
    ],
  },
  {
    name: "Global Crisis Hub",
    icon: <Global />,
    children: [
      { to: "/covid-19", name: "Covid-19 Crisis" },
      { to: "/ukraine-crisis", name: "Ukraine Crisis" },
    ],
  },
  {
    name: "",
  },
  {
    name: "Individual Profile Search",
    to: "/manage-profile",
    icon: <IndividualSearch />,
  },
  {
    name: "Members",
    icon: <VCard className="text-white" />,
    children: [
      { to: "/manageFirm", name: "Manage Firm" },
      { to: "/firm-score", name: "Firm Score" },
      { to: "/practice-head", name: "Practice Heads" },
      { to: "/wsg-contacts", name: "Firm Contacts" },
    ],
  },
  { to: "/accounting", name: "Accounting", icon: <Accounting /> },
  { to: "/groups", name: "Manage Groups", icon: <Groups /> },
  { to: "/events", name: "Manage Events", icon: <LocalActivity /> },
  {
    name: "Resources",
    icon: <Resources />,
    children: [
      { to: '/content', name: "Manage Resources",},
      { to: "/wsg-knowledge-center", name: "WSG Knowledge Center" },
      { to: "/secondments", name: "Secondments" },
      { to: "/dealmakers", name: "Dealmakers" },
      { to: "/help-center", name: "Help Center" },
    ],
  },
  {
    name: "Email Design & Distribution",
    icon: <EmailIcon />,
    children: [
      { to: "/send-emails", name: "Send Emails" },
      { to: "/design-email", name: "Design Email" },
      { to: "/unsubscriber-list", name: "Unsubscriber List" },
      { to: "/trigger-email-templates", name: "Trigger Email Templates" },
    ],
  },
  {
    name: "Site Updates",
    icon: <Update />,
    children: [
      {
        name: "Help Guide",
        children: [
          { to: "/how-to", name: "How To" },
          { to: "/faq", name: "FAQ" },
        ],
      },
      { to: "/bod-council", name: "BoD/Regional Council" },
      { to: "/wsg-objectives", name: "WSG Objectives" },
      { to: "/home-page-update", name: "Home Page" },
      {
        name: "SEO",
        children: [
          {
            to: "",
            name: "Meta Tags",
            children: [
              { to: "/meta-tags/firm", name: "Firm Profile" },
              { to: "/meta-tags/professional", name: "Professional Profile" },
            ],
          },
          { to: "/AOP", name: "AOP Mapping" },
          { to: "/AOPRanking", name: "AOP Ranking" },
          { to: "/ActivityLog", name: "Activity Log" },
        ],
      },
    ],
  },
  {
    name: "Tools",
    icon: <Tools />,
    children: [
      { to: "/firm-referrals", name: "Referrals/Collaborations" },
      { to: "/secondments", name: "Secondments" },
      { to: "/dealmaker", name: "Dealmaker" },
      { to: "/business-guide-upload", name: "Firm Business Guide" },
      { to: "/assessment-fee", name: "Assessment Fee" },
      { to: "/guides", name: "Guides" },
      { to: "/listing", name: "Guests/Speaker Lists" },
      { to: "/member-to-member", name: "Member - to - Member" },
    ],
  },
  {
    name: "Syncs",
    icon: <Sync />,
    children: [
      { to: "/sync-module", name: "Member Syncs" },
      { to: "/press-sync", name: "Press/Article Sync" },
      { to: "/rss-feed", name: "Press/Article RSS Feed" },
    ],
  },
  {
    name: "Analytics",
    icon: <Bar_chart_4_bars />,
    children: [
      {
        name: "WSG General Stats",
        children: [
          { to: "/overview/firm", name: "Members" },
          { to: "/overview/events", name: "Events" },
          { to: "/overview/referrals", name: "Referrals" },
          { to: "/overview/dues", name: "Annual Dues" },
        ],
      },
      { to: "/stats", name: "Legacy Statistics" },
      {
        name: "Google Analytics",
        children: [
          { to: "/google/wsg-site", name: "WSG Site" },
          { to: "/google/firm-profile", name: "Firm Profile" },
          { to: "/google/professional-profile", name: "Professional Profile" },
          { to: "/google/article", name: "Article/Press" },
          { to: "/google/campaigns", name: "Campaigns" },
        ],
      },
      { to: "/send-grid", name: "SendGrid Stats" },
    ],
  },
  {
    name: "Catalogs",
    icon: <Catalogs />,
    children: [
      { to: "/Catalogs/AreaOfPractice", name: "Areas Of Practice" },
      { to: "/Catalogs/AOPRanking", name: "AOP Ranking" },
      { to: "/Catalogs/BarAdmission", name: "Bar Admission" },
      { to: "/Catalogs/City", name: "City" },
      { to: "/Catalogs/Content", name: "Content" },
      { to: "/Catalogs/ContentGeneralTopic", name: "Content General Topic" },
      { to: "/Catalogs/PublicationTag", name: "Content Tag" },
      { to: "/Catalogs/Country", name: "Country" },
      { to: "/Catalogs/DataAdmin", name: "Data Admin" },
      { to: "/Catalogs/Degree", name: "Degree" },
      { to: "/Catalogs/Education", name: "Education" },
      { to: "/Catalogs/Firm", name: "Firm" },
      { to: "/Catalogs/FirmContact", name: "Firm Contact" },
      { to: "/Catalogs/GlobalCrisis", name: "Global Crisis" },
      { to: "/Catalogs/Jurisdiction", name: "Jurisdiction" },
      { to: "/Catalogs/JurisdictionGroup", name: "Jurisdiction Group" },
      { to: "/Catalogs/Language", name: "Language" },
      { to: "/Catalogs/Office", name: "Office" },
      { to: "/Catalogs/person", name: "Person" },
      { to: "/Catalogs/PracticeIndustry", name: "Practice Industry" },
      { to: "/Catalogs/Region", name: "Region" },
      { to: "/Catalogs/RepresentativeClient", name: "Representative Client" },
      { to: "/Catalogs/School", name: "School" },
      { to: "/Catalogs/State", name: "State" },
      { to: "/Catalogs/Substate", name: "SubState" },
      { to: "/Catalogs/timezone", name: "Timezone" },
      { to: "/Catalogs/User", name: "User" },
      { to: "/Catalogs/WSG", name: "WSG" },
    ],
  },
  {
    name: "Lists",
    icon: <Lists />,
    to: "/lists",
  },
  {
    name: "Legacy Misc",
    icon: <Categories />,
    children: [
      { to: "/trigger", name: "Trigger" },
      { to: "/history-logs", name: "History Logs" },
      { to: "/db", name: "Database" },
    ],
  },
  {
    name: "Beta",
    icon: <New />,
    children: [
      { to: "/notification-email", name: "Notification Email" },
      { to: "/networkwire-tool", name: "Networkwire Tool" },
    ],
  },
];

const SideMenu = () => {
  const [openMenus, setOpenMenus] = useState<string[]>([]);
  const [activeSubmenu, setActiveSubmenu] = useState<{
    [key: number]: { id: string; top: number; left: number };
  }>({});
  const { isStaffMode } = useStaffMode();
  const closeAll = () => {};
  const isOpen = useSelector((state: Appstore) => state.menus.sideMenuIsOpen);
  const { isAuthenticated } = useContext(AuthContext);
  const [showTopShadow, setShowTopShadow] = useState(false);
  const [showBottomShadow, setShowBottomShadow] = useState(true);
  const navRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  const handleScroll = () => {
    if (!navRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = navRef.current;

    setShowTopShadow(scrollTop > 5);
    setShowBottomShadow(scrollTop + clientHeight < scrollHeight - 5);
  };

  useEffect(() => {
    const nav = navRef.current;
    if (nav) {
      nav.addEventListener("scroll", handleScroll);
      handleScroll();
    }
    return () => nav?.removeEventListener("scroll", handleScroll);
  }, [navRef.current]);

  if (!isAuthenticated) {
    return null;
  }

  const navigationClassName = isAuthenticated
    ? !isStaffMode
      ? "bg-primary-dark"
      : "bg-accent-Default-dark"
    : "bg-white z-40";

  const handleMenuItemClick = (path: string, hasUrl: boolean) => {
    setOpenMenus((prev) => {
      if (prev.includes(path)) {
        return prev.filter((p) => !p.startsWith(path));
      }
      return [...prev.filter((p) => path.startsWith(p)), path];
    });
    if (hasUrl) {
      setOpenMenus([]);
      closeAll();
    }
  };

  const handleMouseEnter = (
    event: React.MouseEvent,
    id: string,
    level: number,
    childrenLength: number
  ) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const itemHeight = 36;
    const maxHeight = window.innerHeight * 0.9;
    const submenuHeight = Math.min((childrenLength + 1) * itemHeight, maxHeight);
    const spaceBelow = window.innerHeight - rect.bottom;
    const spaceAbove = rect.top;
  
    let topPosition = rect.top;
  
    if (spaceBelow < 0) {
      topPosition = Math.max(0, window.innerHeight - submenuHeight);
    } else if (spaceBelow < submenuHeight && spaceAbove >= submenuHeight) {
      topPosition = rect.bottom - submenuHeight;
    } else if (spaceBelow < submenuHeight) {
      topPosition = Math.max(0, Math.min(rect.top, window.innerHeight - submenuHeight));
    }
  
    setActiveSubmenu((prev) => ({
      ...prev,
      [level]: {
        id,
        top: topPosition,
        left: rect.right,
      },
    }));
  };

  const handleMouseLeave = (level: number) => {
    setActiveSubmenu((prev) => {
      const newEntries = Object.entries(prev).filter(
        ([key]) => Number(key) < level
      );
      return Object.fromEntries(newEntries);
    });
  };

  const activeBackground = (item: MenuItem, pathname: string): boolean => {
    const lowerPath = pathname.toLowerCase();

    const isActiveRecursive = (menuItem: MenuItem): boolean => {
      if (menuItem.to && lowerPath.includes(menuItem.to.toLowerCase())) {
        return true;
      }
      return menuItem.children?.some(isActiveRecursive) ?? false;
    };

    return isActiveRecursive(item)
  };

  const expandedMenu = (item: MenuItem, currentPath: string) => {
    return (
      <>
        {item.to ? (
          <Link
            to={`${isStaffMode ? "staff" : ""}${item.to ?? ""}`}
            className="flex whitespace-nowrap overflow-hidden px-4 py-3"
          >
            {item.icon && <p className="h-6 w-6 mr-5">{item.icon}</p>}
            {item.name}
          </Link>
        ) : (
          <div
            className={`flex whitespace-nowrap overflow-hidden px-4 py-3`}
            onClick={() => handleMenuItemClick(currentPath, !!item.to)}
          >
            {item.name ? (
              <>
                {item.icon && <p className="h-6 w-6 mr-5">{item.icon}</p>}
                <p className="flex-1 text-start">{item.name}</p>

                <div
                  className={`${isOpen ? "block" : "hidden"} flex whitespace-nowrap overflow-hidden`}
                >
                  {item.children && (
                    <div className="ml-1 w-6 h-6 fill-white">
                      <ChevronDown
                        rotation={
                          openMenus.includes(currentPath)
                            ? "rotate(180deg)"
                            : "rotate(0deg)"
                        }
                      />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="flex w-full h-1 border-t-2" />
            )}
          </div>
        )}
      </>
    );
  };

  const collapsedMenu = (item: MenuItem, level: number) => {
    const isActive = activeSubmenu[level]?.id === item.name;
    return (
      <div className="relative cursor-default">
      <div
        className="px-4 py-3"
        onMouseEnter={(event) =>
          handleMouseEnter(
            event,
            item.name,
            level,
            item.children?.length ?? 0
          )
        }
      >
        {item.icon ? (
          <p className="h-6 w-6 mr-2">{item.icon}</p>
        ) : (
          <div className="flex w-full h-1 border-t-2" />
        )}
      </div>

      {isActive && submenu(level, item)}
    </div>
    )
  };

  const renderMenuItems = (items: MenuItem[], parentPath = "", level = 0) => {
    return items.map((item, index) => {
      const currentPath = `${parentPath}/${item.name}`;
      

      return (
        <div key={index} className="flex flex-col w-full">
          <li className="relative flex items-center w-full cursor-pointer text-white fill-white group/item">
            {item.name && (
              <div
                className={`${activeBackground(item, pathname) && 'w-full'}
                  absolute inset-0 ${isStaffMode ? "bg-secondary" : "bg-primary"} w-0 h-full transition-all duration-300 group-hover/item:w-full opacity-70`}
              />
            )}
            <div className={`relative z-10 w-80`}>
              {isOpen ? expandedMenu(item, currentPath) : collapsedMenu(item, level)}
            </div>
          </li>
          {item.children && isOpen && (
            <div
              className={`ml-5 flex flex-col transition-all duration-300 ease-in-out transform ${
                openMenus.includes(currentPath)
                  ? "max-h-screen opacity-100"
                  : "max-h-0 opacity-0"
              } overflow-hidden`}
            >
              {renderMenuItems(item.children, currentPath, level + 1)}
            </div>
          )}
        </div>
      );
    });
  };

  const submenu = (level: number, item: MenuItem) => {
    return (
      <ul
        className={`${navigationClassName} ${item.children && "rounded-t-md"}
          fixed w-fit  text-white text-smm overflow-y-auto max-h-[90vh] shadow-md shadow-black`}
        style={{
          top: activeSubmenu[level]?.top,
          left: activeSubmenu[level]?.left + (level ? 0 : -8),
        }}
        onMouseLeave={() => handleMouseLeave(level)}
      >
        {item.children && (
          <div className="bg-white text-secondary text-sm rounded-t-md overflow-hidden cursor-default">
            {item.name}
          </div>
        )}
        {item.to && level === 0
          ? submenuItem(item.name, item.to)
          : item.children?.map((child) => (
              <li
                key={child.name}
                onMouseEnter={(event) =>
                  handleMouseEnter(
                    event,
                    child.name,
                    level + 1,
                    child.children?.length || 0
                  )
                }
              >
                {submenuItem(child.name, child.to)}
                {activeSubmenu[level + 1]?.id === child.name &&
                  submenu(level + 1, child)}
              </li>
            ))}
      </ul>
    );
  };

  const submenuItem = (name: string, to?: string) => {
    return (
      <div className="relative group/sub px-4 py-2">
        <div
          className={`${isStaffMode ? "bg-secondary" : "bg-primary"} absolute inset-0 
          w-0 h-full transition-all duration-300 opacity-70 group-hover/sub:w-full -z-10`}
        />
        {to ? (
          <Link
            to={`${isStaffMode ? "staff" : ""}${to}`}
            className="w-full flex items-center"
          >
            {name}
          </Link>
        ) : (
          <div className="flex w-full items-center">
            <div className="w-full flex items-center">{name}</div>
            <div className="h-4">
              <ChevronRight />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`relative h-full ${isOpen ? `w-72 bg-no-repeat bg-right` : "w-14 !bg-none"} ${navigationClassName} 
      relative flex flex-col transition-all duration-300 ease-in-out z-30 text-white h-full py-1`}
      style={{
        backgroundImage: `url(${worldBg})`,
        backgroundPosition: "120px 20px",
      }}
    >
      {showTopShadow && (
        <div className="absolute top-0 left-0 w-full h-8 bg-gradient-to-b from-black/70 to-transparent pointer-events-none z-30"></div>
      )}
      {showBottomShadow && (
        <div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-black/70 to-transparent pointer-events-none z-30"></div>
      )}

      <nav
        ref={navRef}
        className="overflow-y-auto overflow-x-hidden "
        onMouseLeave={() => handleMouseLeave(0)}
      >
        {renderMenuItems(isStaffMode ? staffMenu : memberMenu)}
      </nav>
    </div>
  );
};

export default SideMenu;
