import NewButton from "../../../components/Atomos/Buttons/newButton";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import React from "react";
import Plus from "../../../components/Atomos/Icons/EditMode/Plus";
import AreaOfPracticeFrame from "./AreaOfPracticeFrame";
import { useNavigate } from "react-router-dom";

type AreasOfPracticeOfficeProps = {
  officeName: string;
  aops: any;
  firmId?: string;
};

const AreasOfPracticeOffice: React.FC<AreasOfPracticeOfficeProps> = ({
  officeName,
  aops,
  firmId,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex w-full flex-col gap-5 lg:flex-row lg:justify-between">
        <CatalogSubTitle
          className="text-Default"
          title={`${officeName} Areas Of Practice`}
        />
        <NewButton
          text={"EDIT"}
          icon={<Plus />}
          content="textIcon"
          color={"accent"}
          onClick={() => {navigate(`/Staff/ManageFirm/AopManagement/${firmId}`)}}
        />
      </div>

      <span className="text-gray-font text-base">
        To edit this Office's Areas of Practice, you will be redirected to the
        Update AOP page from the AOP Management tab. After making your changes
        and saving, you will be automatically returned to this page. Clicking
        'Cancel' will discard any changes and bring you back to this page.
      </span>

      <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
        {aops?.map((aop: any, index: number) => (
          <AreaOfPracticeFrame key={index} name={aop.name} />
        ))}
      </div>
    </>
  );
};

export default AreasOfPracticeOffice;
