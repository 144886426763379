import React, { ChangeEvent, useEffect, useState } from 'react';
import DDMultiple, { Option } from '../../../Atomos/DropDown/DDMultiple';
import Input from '../../../Atomos/Inputs/Input';
import CatalogFilterButtons from '../../CatalogFilterButtons';
import DropDownList from '../../../Atomos/DropDown/Dropdown';
import CalendarInput from '../../../Atomos/Inputs/CalendarInput';
import { IActivityLogsFilters } from '../../../../interfaces/models/IActivityLogs';
import { UseFirmList } from '../../../../hooks/Catalogs/useFirm';


interface IActivityLogFilterProps {
  search: () => void;
  resetFilters: () => void;
  headers: any;
  tableInstance?: any;
  filters: IActivityLogsFilters
  setFilters: React.Dispatch<React.SetStateAction<IActivityLogsFilters>>;
}

const accessOptions: Option[] = [
  { id: 'DataAdmin', name: 'Data Admin' },
  { id: 'WSGStaff', name: 'WSG Staff' },
  { id: 'WSGStaffSuper', name: 'WSG Staff Super' },
  { id: 'Member', name: 'Member' }
];

const ActivityLogFilter: React.FC<IActivityLogFilterProps> = ({ 
  search,
  resetFilters,
  headers,
  tableInstance,
  filters,
  setFilters,
}) => {
  const {firmData} = UseFirmList();
  const handleFilters = (field: string, value:any) => {
    setFilters({...filters, [field]: value})
  }

  return (
    <>
      <div className='flex flex-col items-center self-stretch formsection justify-end p-4
     md:grid md:grid-cols-2 md:items-end  md:justify-center
      lg:flex-1 lg:flex lg:flex-row
      border border-gray-100'>
        <div className='w-full'>
          <Input
            type="text"
            value={filters.name}
            onChange={(e) => handleFilters('name', e.target.value)}
            error={false}
            title='By Name'
            isWfull
          />
        </div>
        <div className="items-center w-full">
          <DDMultiple
            title="By Firm"
            objects={firmData}
            selectedObjst={filters.firm}
            seter={(e) => handleFilters('firm', e)}
            error={false}
            required={false}
          />
        </div>
        <div className="items-center w-full">
        <DDMultiple
          title="Access"
          objects={accessOptions}
          selectedObjst={filters.access}
          seter={(e) => handleFilters('access', e)}
          error={false}
          required={false}
        />
        </div>
        <div className='w-full'>
          <CalendarInput title={"By Published Date:"} error={false} type={'range'} value={filters.publishedDate} onChange={(e: any) => handleFilters('publishedDate', e)} />
        </div>
        <div className='flex flex-row gap-2 w-full md:justify-end lg:w-max md:col-span-2 md:max-w-[17rem] md:ml-auto'>
          <CatalogFilterButtons onClickSearch={search} handleFormReset={resetFilters} headers={headers} tableInstance={tableInstance} />
        </div>
      </div>
    </>
  );
};

export default ActivityLogFilter;
