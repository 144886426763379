import React, { useEffect } from "react";
import UserIcon from "../../Atomos/Icons/EditMode/UserIcon";
import Modal from "../Modals/Modal";
import { useAppContext } from "../../../Context";
import ImageCropper from "../../Organismos/ImageCropper/ImageCropper";
import Image from "../../Atomos/Icons/Image";

type inputImageProps = {

  photo?: string;
  setImage: (photo: string | File | null) => void;
  title?: string;
  icon?:React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  tooltip?: string;
  error?: boolean;
  errorMsg?: string;
};

const InputImageMinimal: React.FC<inputImageProps> = ({icon=<Image/>,...props}:inputImageProps) => {

  const [hasPhoto, setHasPhoto] = React.useState(false)
  const [spinner, setSpinner] = React.useState(false)

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [draggedPhoto, setDraggedPhoto] = React.useState<string | File | null>()
  const { mode } = useAppContext();
  const [newphoto, setPhoto] = React.useState<File | null>(null);

  useEffect(() => {
    if (newphoto) {
      props.setImage(newphoto);
    }
  }, [newphoto]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    document.body.style.overflow = !isModalOpen ? 'hidden' : '';
  };

  const photoHasLoaded = async () => {
    setHasPhoto(true)
  }
  const handleSpinner = async () => {
    setSpinner(true)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const droppedFile = e.dataTransfer.files[0];

    if (droppedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && typeof event.target.result === 'string') {
          setDraggedPhoto(event.target.result);
        }
      };
      reader.readAsDataURL(droppedFile);
    }
  };

  return (
    <>
      <Modal title={props.title ? props.title : "Upload Image"} isModalOpen={isModalOpen} toggleModal={toggleModal} >
        <ImageCropper
          setPhoto={setPhoto}
          onHandleUpload={photoHasLoaded}
          draggedPhoto={draggedPhoto}
          onImageUpload={() => {
            toggleModal();
          }}
          shape="rect"
        />
      </Modal>
      <div className={`flex flex-col gap-[.625rem] self-stretch items-start w-full`}>
      <div className="flex flex-col gap-2.5 items-center w-full h-full">        
        <div className={`flex flex-col items-center gap-2.5 [&>*:last-child]:gap-0 w-full`}>
          <div className={`w-full h-full flex flex-col gap-2.5 items-center justify-center`}>
            <div
              className="bg-[#F4F4F4] border-[1px] border-dashed border-[#BEBDBD] relative flex items-center justify-center w-full h-40 overflow-hidden "
              onClick={toggleModal}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                handleDrop(e);
                toggleModal();
              }}
            >
              <div className="relative flex-1 flex  justify-center items-center">
                {
                  newphoto ?
                    <img src={URL.createObjectURL(newphoto)} alt="Wsg_Profile_Picture" className="object-cover w-fit h-fit" />
                    :
                    props.photo ? (
                      <img src={`${props.photo}`} alt="Wsg_Profile_Picture" className="object-cover w-full h-full" />
                    ) :
                    (
                      <div className="w-[3.75rem] h-[3.75rem] lg:w-[6rem] lg:h-[6rem] fill-gray-font">{icon}</div>
                    )}
              </div>
              
            </div>              
          </div>
        </div>
      </div>
      </div>
    </>
  )
}
export default InputImageMinimal;