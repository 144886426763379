import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../../components/Atomos/Inputs/Input";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import "../../../assets/css/AddCatalogs.css";
import DropDownList from "../../../components/Atomos/DropDown/Dropdown";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import { UseCountryList } from "../../../hooks/Catalogs";
import { useStatesList } from "../../../hooks/Catalogs/useStates";
import { ICity } from "../../../interfaces";
import { updateData } from "../../../utils/functions";
import { useUpdateAndLog } from "../../../hooks/LogActivity/LogActivityUpdates";
import { useCreateAndLog } from "../../../hooks/LogActivity/LogActivityCreates";
import { useDeleteAndLog } from "../../../hooks/LogActivity/LogActivityDeletes";
import { useDispatch } from "react-redux";
import { AddCity, DeleteCity, UpdateCity } from "../../../redux/catalogs/states";

type listobj = { id: string; name: string };

interface iCatalogProps {
  mode: "add" | "edit";
}

type error = { error: boolean; msj: string };
type errors = {
  name: error;
  country: error;
  state: error;
};

const AddCountry: React.FC<iCatalogProps> = (props) => {
  const crudNavegate = () => {
    navigate(-1);
  };

  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [city, setCity] = useState<ICity>(
    state
      ? { ...state}
      : { name: "", state: "" }
  );
  const [buttonClicked, setButtonClicked] = useState("");
  const [stateData, setStateData] = useState<any[]>([]);
  const [errors, setErrors] = useState<errors>({
    name: { error: false, msj: "" },
    country: { error: false, msj: "" },
    state: { error: false, msj: "" },
  });
  const [selectedCountry, setSelectedCountry] = useState<listobj>({
    id: state?.countryId ?? "",
    name: state?.country ?? "",
  });
  const [selectedState, setSelectedState] = useState<listobj>({
    id: state?.stateId ?? "",
    name: state?.state ?? "",
  });
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [query, setQuery] = useState("");

  const { countryData } = UseCountryList();
  const { statesData } = useStatesList();
  const dispatch = useDispatch();
  const update = useUpdateAndLog();
  const create = useCreateAndLog();
  const erase = useDeleteAndLog();

  const filteredCountries =
    query === ""
      ? countryData
      : countryData?.filter((country) => {
        return country.name.toLowerCase().includes(query.toLowerCase());
      });

  useEffect(() => {
    if (!statesData.length) return

    setStateData(statesData.filter(s => s.country === selectedCountry.id))
    setSelectedState(statesData.find(s => s.name === state?.state))
  }, [statesData, selectedCountry]);

  useEffect(() => {
    if (state) {
      const country = countryData.find((country) => country.name === state?.country);
      if(country) {
        setSelectedCountry(country);
      }
    }
  }, [countryData]);

  useEffect(() => {
    if (errors.name.error || errors.country.error || errors.state.error)
      validateForm();
  }, [city, selectedCountry, selectedState]);

  useEffect(() => {
    if (!errors.name.error && !errors.country.error && !errors.state.error)
      setSaveOverrideOpen(false);
  }, [errorFields]);

  const validateForm = () => {
    let valid = true;
    let newerrors: errors = errors;
    newerrors.name =
      city?.name === ""
        ? { error: true, msj: "Name is required" }
        : { error: false, msj: "" };
    newerrors.country =
      selectedCountry.name === ""
        ? { error: true, msj: "Country is required" }
        : { error: false, msj: "" };
    newerrors.state =
      selectedState?.name === ""
        ? { error: true, msj: "State is required" }
        : { error: false, msj: "" };

    valid = Object.values(errors).every((error) => !error.error);

    setErrors(errors);
    setErrorFields(
      Object.keys(errors).filter(
        (key) => errors[key as keyof typeof errors].error
      )
    );

    if (!valid) setSaveOverrideOpen(true);

    return valid;
  };

  const handleButtonClick = (action: string) => {
    setButtonClicked(action);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    switch (buttonClicked) {
      case "delete":
        await handleDelete()
        break;
      case "saveOverride":
        if (props.mode === "add") {
          await handleCreate()
        } else {
          await handleUpdate()
        }
        break;
      case "saveButton":
        await handleCreate()
        break;
      case "updateButton":
        if (validateForm()) {
          await handleUpdate()
        }
        break;
      default:
        // Handle unexpected buttonClicked values if needed
        break;
    }
  };

  const handleSetSelectedCountry = (e: any) => {
    setSelectedCountry(e);
    setSelectedState({ id: "", name: "" });
  };

  const handleSetSelectedState = (e: any) => {
    setSelectedState(e);
    setCity({ ...city, state: e.id });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setCity({ ...city, [e.target.name]: e.target.value });
  };
  
  const generateCityData = () => ({
    ...city,
    state: selectedState?.id,
    stateName: selectedState?.name,
    country: selectedCountry.id,
    countryName: selectedCountry.name,
    regionNames: city.regions,
  });

  const handleCreate = async () => {
    try {
      const cityData = generateCityData();
      const response = await create.city({ city: cityData, pathname });
      if (state?.name && state.name !== cityData?.name) {
        await Promise.all([
          updateData('address', 'cityName', state.name, cityData.name),
          updateData('office', 'cityName', state.name, cityData.name),
        ]);
      }
      dispatch(AddCity(response));
      crudNavegate();
    } catch (error) {
      console.error('Error creating city:', error);
    }
  };

  const handleUpdate = async () => {
    try {
      const cityData = generateCityData();
      await update.city({ city: cityData, pathname, prevState: city });
      if (state?.name && state.name !== cityData?.name) {
        await Promise.all([
          updateData('address', 'cityName', state.name, cityData.name),
          updateData('office', 'cityName', state.name, cityData.name),
        ]);
      }
      dispatch(UpdateCity(cityData));
      crudNavegate();
    } catch (error) {
      console.error('Error updating city:', error);
    }
  };

  const handleDelete = async () => {
    try {
      const cityData = generateCityData();
      await erase.city({ city: cityData, pathname });
      dispatch(DeleteCity(cityData.id ?? ""));
      crudNavegate();
    } catch (error) {
      console.error('Error deleting city:', error);
    }
  };

  return (
    <main className={`mainSection ${saveOverrideOpen ? "pt-7 md:pt-9 " : ""}`}>
      <form onSubmit={handleSubmit}>
        <SaveOverride
          fields={errorFields}
          handleButtonClick={handleButtonClick}
          open={saveOverrideOpen}
          close={() => {
            setSaveOverrideOpen(!saveOverrideOpen);
          }}
        />
        <CatalogTitle
          title={`${props.mode === 'add' ? 'New City' : `Edit ${state?.name || ''}`}`}
          className="text-Default"
        >
        <GoBack />
      </CatalogTitle>
        <article className="articleSection ">
          <section className="formsectionNTitle">
            <CatalogSubTitle
              title=" City Information"
              className="text-accent-Default-dark"
            />
            <div className="formsection flex w-full">
              <div className=" w-full lg:w-1/2">
                <Input
                  name="name"
                  value={city.name}
                  required={true}
                  title="Name"
                  error={errors.name.error}
                  errorMsj={errors.name.msj}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </section>
          <section className="formsectionNTitle">
            <CatalogSubTitle
              title=" Catalog List Relationships"
              className="text-accent-Default-dark"
            />
            <div className="formsection flex w-full flex-wrap md:flex-nowrap">
              <div className="w-full md:w-1/2">
                <DropDownList
                  title="Country"
                  getid
                  required={true}
                  selectedObjst={selectedCountry.name}
                  seter={handleSetSelectedCountry}
                  data={filteredCountries}
                  error={errors.country.error}
                  errormsj={errors.country.msj}
                />
              </div>
              <div className="w-full md:w-1/2">
                <DropDownList
                  title="State"
                  getid
                  tooltip={"Please select a Country"}
                  required={true}
                  disabled={selectedCountry.name === ""}
                  selectedObjst={selectedState?.name}
                  seter={handleSetSelectedState}
                  data={stateData}
                  error={errors.state.error}
                  errormsj={errors.state.msj}
                />
              </div>
            </div>
          </section>
          <ButtonsCrud
            Catalog="City"
            mode={props.mode}
            id={id ? id : ""}
            disabled={
              errors.name.error || errors.state.error || errors.country.error
            }
            actionButton={handleButtonClick}
            onDelete={handleDelete}
            state={state}
            newState={city}
          />
        </article>
      </form>
    </main>
  );
};
export default AddCountry;
