import NewButton from "../../../components/Atomos/Buttons/newButton";
import BackgroundComponent from "../../../components/Layout/BackgroundProfile/BackgroundProfile";
import CurrentPage from "../../../components/Layout/CurrentPageTab/CurrentPage";
import ProfileViewModeBg from "../../../assets/images&icons/profileViewMode.jpeg";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { IAddress, initialValue as initialAddress } from "../../../interfaces/models/IAddress";
import { UseCountryList } from "../../../hooks/Catalogs";
import { useStatesList } from "../../../hooks/Catalogs/useStates";
import { useCityList } from "../../../hooks/Catalogs/useCityList";
import { getAllCities } from "../../../Services/City";
import { getStates } from "../../../Services/State";
import Axios from "../../../utils/axios";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import { uploadImage } from "../../../utils/UploadImage";
import { Option } from "../../../components/Atomos/DropDown/DDMultiple";
import useViewport from "../../../hooks/useViewPort";
import FirmGeneralInformation from "./FirmGeneralInformation";
import FirmInformation from "./FirmInformation";
import { useDispatch, useSelector } from "react-redux";
import { Appstore, manageFirmSlice } from "../../../redux";
import OfficeMainContacts from "./OfficeMainContacts";
import ListingLayout from "./ListingLayout";
import AreasOfPracticeOffice from "./AreasOfPracticeOffice";
import { useUpdateAndLog } from "../../../hooks/LogActivity/LogActivityUpdates";
import { useCreateAndLog } from "../../../hooks/LogActivity/LogActivityCreates";
import IFirm from "../../../interfaces/models/IFirm";
import IOffice, { IPhoneNumber } from "../../../interfaces/models/IOffice";
import Notables from "./Notables";
import OfficeRepresentativeClient from "./OfficeRepresentativeClient";
import MoreInformation from "./MoreInformation";
import LatestUpdate from "./LatestUpdate";
import { useDeleteAndLog } from "../../../hooks/LogActivity/LogActivityDeletes";
import OfficeRepresentativeClientIB from "./OfficeRepresentativeClientIB";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import { getAddresById } from "../../../Services/Address";
const WSGDots = require("../../../assets/images&icons/WSGDots.svg");
const axios = new Axios();
type listobj = { id: string; name: string };

type NotableItem = {
  link: string;
  photo: string | File | null;
};

type error = { error: boolean; msj: string };
type AddressErrors = {
  address1: error;
  country: error;
  addressType: error;
};

type FirmErrors = {
  firmName: error;
  logo: error;
  websiteUrl: error;
  overview: error;
}

type officePhoneErrors = {
  phone: error;
};

const ModifyMainOffices = () => {
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [allProfessionals, setAllProfessionals] = useState([]);
  const [allOffices, setAllOffices] = useState([]);

  const fetchAllProfessionals = async () => {
    try {
      const res = await axios.Get("/person");
      if (res && res.data) {
        setAllProfessionals(res.data);
      }
    } catch (error) {}
  };

  const fetchAllOffices = async () => {
    try {
      const res = await axios.Get("/office");
      if (res && res.data) {
        setAllOffices(res.data);
      }
    } catch (error) {}
  };

  const getFilteredProfessionalsByFirm = (firmId: string) => {
    return allProfessionals?.filter(
      (professional: any) => professional.firm === firmId
    );
  };

  const getFilteredOfficesByFirm = (firmId: string) => {
    return allOffices?.filter((office: any) => office.firm === firmId);
  };

  const { state, pathname } = useLocation();
  const update = useUpdateAndLog();
  const create = useCreateAndLog();
  const erase = useDeleteAndLog();

  const initialState = {
    websiteUrl: manageFirm.selectedFirm?.websiteUrl || "",
    professionalsWorldWide: "",
    yearFounded: manageFirm.selectedFirm?.yearFounded || "",
    litigationPercentage: manageFirm.selectedFirm?.litigationPercentage || "",
    officeYearFounded: state?.office?.yearFounded || "",
    officesWorldwide: "",
    overview: manageFirm.selectedFirm?.firmOverview || "",
    isMember: false,
  };
  const [formData, setFormData] = useState(initialState);

  const [stateData, setStateData] = useState<any[]>([]);
  const [cityData, setCityData] = useState<any[]>([]);
  const [addressErrors, setAddressErrors] = useState<AddressErrors>({
    address1: { error: false, msj: "" },
    country: { error: false, msj: "" },
    addressType: { error: false, msj: "" },
  });

  const [invoiceErrors, setInvoiceErrors] = useState<AddressErrors>({
    address1: { error: false, msj: "" },
    country: { error: false, msj: "" },
    addressType: { error: false, msj: "" },

  });

  const [firmErrors, setFirmErrors] = useState<FirmErrors>({
    firmName: { error: false, msj: "" },
    logo: { error: false, msj: "" },
    websiteUrl: { error: false, msj: "" },
    overview: { error: false, msj: "" },
  });

  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [activeErrors, setActiveErrors] = useState<Record<string, { error: boolean; msj: string }>>({});
  const [canSave, setCanSave] = useState(true);
  const [checked, setChecked] = useState(false);
  const [notableErrors, setNotableErrors] = useState<{errorMsj: string }[]>([{errorMsj: ""}, {errorMsj: ""}, {errorMsj: ""}, {errorMsj: ""}]);
  const [prevInvoiceAddress, setPrevInvoiceAddress] = useState<IAddress>(initialAddress);
  const [prevFirmAddress, setPrevFirmAddress] = useState<IAddress>(initialAddress);
  const [officePhoneErrors, setOfficePhoneErrors] = useState<officePhoneErrors>(
    {
      phone: { error: false, msj: "" },
    }
  );

  const [selectedCountry, setSelectedCountry] = useState<listobj>(() => {
    return manageFirm.selectedFirm?.country
      ? {
          id: manageFirm.selectedFirm.country,
          name: manageFirm.selectedFirm.countryName || "",
        }
      : { id: "", name: "" };
  });

  const { viewportWidth } = useViewport();
  const [selectedState, setSelectedState] = useState<listobj>({
    id: manageFirm.selectedFirm?.state || "",
    name: manageFirm.selectedFirm?.stateName || "",
  });
  const [selectedCity, setSelectedCity] = useState<listobj>({
    id: manageFirm.selectedFirm?.cityId || "",
    name: manageFirm.selectedFirm?.cityName || "",
  });
  const [selectedAddressType, setSelectedAddressType] = useState();
  const [selectedInvoiceAddressType, setSelectedInvoiceAddressType] =
    useState("Legal");
  const { countryData } = UseCountryList();
  const [representativeClients, setRepresentativeClients] = useState<any[]>();
  const [notableItems, setNotableItems] = useState<NotableItem[]>(
    manageFirm?.selectedFirm?.notables || []
  );
  const [representativeClientsFirm, setRepresentativeClientsFirm] = useState<any[]>([]);
  const [representativeClientsDelete, setRepresentativeClientsDelete] = useState<string[]>();
  const [moreInformation, setMoreInformation] = useState<string>(manageFirm?.selectedFirm?.moreInformation || "");
  const { statesData } = useStatesList();
  const { cityData: citiesList } = useCityList();
  const [logo, setLogo] = useState<string | File | null>(
    manageFirm.selectedFirm?.logo || null
  );
  const [smallLogo, setSmallLogo] = useState<string | File | null>(
    manageFirm.selectedFirm?.smallLogo || null
  );
  const [allAddresses, setAllAddresses] = useState<IAddress[]>(
    [] as IAddress[]
  );
  const [selectedAddress, setSelectedAddress] = useState<IAddress>(initialAddress);
  const [invoiceAddress, setInvoiceAddress] = useState<IAddress>({...initialAddress, addressType: "Legal"});  
  const [phoneNumber, setPhoneNumber] = useState<IPhoneNumber>({
    code: "",
    phone: "",
    basecode: "",
  });
  const [faxNumber, setFaxNumber] = useState<IPhoneNumber>({
    code: "",
    phone: "",
    basecode: "",
  });
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

  const toggleLanguageModal = () => {
    setIsLanguageModalOpen((prevState) => !prevState);
  };

  useEffect(() => {
    console.log(state)
    if (state.office) {
      setPhoneNumber({
        code: state.office?.phoneNumber?.code,
        phone: state.office?.phoneNumber?.phone,
        basecode: state.office?.phoneNumber?.basecode,
      });
      setFaxNumber({
        code: state.office?.faxNumber?.code,
        phone: state.office?.faxNumber?.phone,
        basecode: state.office?.faxNumber?.basecode,
      });
    }
  }, [state.office]);

  const [invoiceCountry, setInvoiceCountry] = useState<listobj>({
    id: "",
    name: "",
  });
  const [invoiceState, setInvoiceState] = useState<listobj>({
    id: "",
    name: "",
  });
  const [invoiceCity, setInvoiceCity] = useState<listobj>({ id: "", name: "" });

  const [useSameAddress, setUseSameAddress] = useState(false);

  const [firmName, setFirmName] = useState(
    manageFirm.selectedFirm?.firmName || ""
  );
  const [languages, setLanguages] = useState<Option[]>([]);
  const [listingCategory, setListingCategory] = useState<string[]>(manageFirm?.selectedFirm?.listingCategory??["Partners","Associates","Of Counsel","Other"]);
  const [selectedLanguages, setSelectedLanguages] = useState<Option[]>(() => {
    return (
      manageFirm.selectedFirm?.languages?.map((language: string) => ({
        id: language,
        name: language,
      })) || []
    );
  });

  const getLanguages = async () => {
    const languages = await axios.Get("language");
    setLanguages(languages.data);
  };

  useEffect(() => {
    const selLanguages = manageFirm.selectedFirm?.languages?.map(
      (language: any) => languages?.find((lang: any) => lang.id === language)
    );
    setSelectedLanguages(
      selLanguages?.filter((lang): lang is Option => !!lang) || []
    );
  }, [manageFirm.selectedFirm?.languages, languages]);

  const handleSetInvoiceCountry = (e: any) => {
    setInvoiceCountry(e);
    setInvoiceState({ id: "", name: "" });
    setInvoiceCity({ id: "", name: "" });
    setInvoiceErrors((prevState) => ({
      ...prevState,
      country: { error: false, msj: "" },
    }));
  };

  const handleSetInvoiceState = (e: any) => {
    setInvoiceState(e);
    setInvoiceCity({ id: "", name: "" });
    setInvoiceErrors((prevState) => ({
      ...prevState,
      state: { error: false, msj: "" },
    }));
  };

  const handleSetInvoiceCity = (e: any) => {
    setInvoiceCity(e);
    setInvoiceAddress({ ...invoiceAddress, city: e.id });
    setInvoiceErrors((prevState) => ({
      ...prevState,
      city: { error: false, msj: "" },
    }));
  };

  useEffect(() => {
    if (!invoiceCountry.id) return;
    getStates().then((data) => {
      if (data != null) {
        const newStates = data.filter(
          (item: any) => item.country === invoiceCountry.id
        );
        setStateData(newStates);
      }
    });
    if (invoiceCountry.id === "") setInvoiceState({ id: "", name: "" });
  }, [invoiceCountry]);

  useEffect(() => {
    if (!invoiceState.id) return;
    getAllCities().then((data) => {
      if (data != null) {
        const newCities = data.filter(
          (item: any) => item.state === invoiceState.id
        );
        setCityData(newCities);
      }
    });
    if (invoiceState.id === "") setInvoiceCity({ id: "", name: "" });
  }, [invoiceState]);

  useEffect(() => {
    setInvoiceAddress((prev) => ({
      ...prev,
      country: invoiceCountry.id,
      state: invoiceState.id,
      city: invoiceCity.id,
    }));
  }, [invoiceCountry, invoiceState, invoiceCity]);

  const fetchAddresses = async () => {
    try {
      const res = await axios.Get("/Address");
      if (res && res.data.length > 0) {
        setAllAddresses(res.data);
      }
    } catch (error) {
      console.error("Address Fetching failed, try again another time.");
    }
  };

  const fetchRepresentativeClients = async () => {
    try {
      const res = await axios.Get("/representativeClient");
      if (res && res.data.length > 0) {
        setRepresentativeClients(res.data);
      }
    } catch (error) {
      console.error("Representative Clients fetching failed, try again another time.");
    }
  };

  useEffect(() => {
    fetchAddresses();
    getLanguages();
    fetchAllProfessionals();
    fetchAllOffices();
    fetchRepresentativeClients();
  }, []);

  useEffect(() => {
    setAddressErrors({
      address1: { error: false, msj: "" },
      country: { error: false, msj: "" },
      addressType: { error: false, msj: "" },
    });
  }, []);

  useEffect(() => {
    if (representativeClients) {
      setRepresentativeClientsFirm(
        representativeClients.filter(
          (client: any) => manageFirm.selectedFirm?.id === client.firm
        )
      );
    }
  }, [representativeClients]);

  useEffect(() => {
    if (
      countryData.length &&
      statesData.length &&
      citiesList.length &&
      manageFirm.selectedFirm &&
      allAddresses.length > 0
    ) {
      const currentCity = citiesList.find((c) => c.id === manageFirm.selectedFirm?.cityId);
      const currentState = statesData.find((s) => s.id === currentCity?.state);
      const currentCountry = countryData.find(
        (c) => c.id === currentState?.country
      );

      if (currentCity && currentState && currentCountry) {
        setSelectedState({ id: currentState.id, name: currentState.name });
        setSelectedCountry({
          id: currentCountry.id,
          name: currentCountry.name,
        });
      }
    }
  }, [
    citiesList,
    statesData,
    countryData,
    manageFirm.selectedFirm,
    allAddresses,
  ]);

  useEffect(() => {
    if (!selectedCountry.id) return;
    getStates().then((data) => {
      if (data != null) {
        const newStates = data.filter(
          (item: any) => item.country === selectedCountry.id
        );
        const filteredAddress = allAddresses.find(
          (add: any) => add.id === state?.office.address
        );
        if (filteredAddress) setSelectedAddress(filteredAddress);
        setStateData(newStates);
      }
    });
    if (selectedCountry.id === "")
      setSelectedState({
        id: manageFirm.selectedFirm?.state ?? "",
        name: manageFirm.selectedFirm?.state ?? "",
      });
  }, [selectedCountry, allAddresses]);

  useEffect(() => {
    if (!citiesList.length || !statesData.length || !countryData.length) return;
    getAddresses()
  },[citiesList, statesData, countryData])

  useEffect(() => {
    const errorsToSet = Object.keys(activeErrors).filter(
      (clave) => activeErrors[clave as keyof typeof activeErrors]?.error
    );

    setErrorFields(errorsToSet);
    setSaveOverrideOpen(errorsToSet.length > 0);
    setCanSave(!isSomeError(activeErrors));
  }, [activeErrors]);

  useEffect(() => {
    if (checked) validateFields();
  }, [
    logo,
    smallLogo,
    selectedCountry,
    selectedAddressType,
    phoneNumber,
    invoiceCountry,
    invoiceAddress,
    formData,
  ]);

  useEffect(() => {
    if (!selectedState.id) return;
    getAllCities().then((data) => {
      if (data != null) {
        const newCities = data.filter(
          (item: any) => item.state === selectedState.id
        );
        setCityData(newCities);
      }
    });
    if (selectedState.id === "")
      setSelectedCity({ id: manageFirm.selectedFirm?.cityId ?? "", name: manageFirm.selectedFirm?.cityName ?? "" });
  }, [selectedState]);
  
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const maxLength = 10;

    if (name === "altitude" || name === "latitude") {
      if (value.length > maxLength) {
        return;
      }
      const trimmedValue = value.trim();

      if (trimmedValue !== "") {
        if (
          trimmedValue.length > maxLength ||
          !/^[-+]?[0-9]*\.?[0-9]+$/.test(trimmedValue)
        ) {
          setAddressErrors((prevState) => ({
            ...prevState,
            [name]: {
              error: true,
              msj: "Invalid format or length exceeds 10 characters",
            },
          }));
        } else {
          setAddressErrors((prevState) => ({
            ...prevState,
            [name]: { error: false, msj: "" },
          }));
        }
      } else {
        setAddressErrors((prevState) => ({
          ...prevState,
          [name]: { error: false, msj: "" },
        }));
      }
      setSelectedAddress((prevAddress: any) => ({
        ...prevAddress,
        [name]: trimmedValue,
      }));
    } else if (name === "firmName") {
      setFirmName(value);
    } else {
      setSelectedAddress((prevAddress: any) => ({
        ...prevAddress,
        [name]: value,
      }));
    }
  };

  const handleFirmDataInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name === "litigationPercentage") {
      const decimalPattern = /^\d{0,2}(\.\d{0,2})?$/;

      if (value === "") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        return;
      }

      const parsedValue = parseFloat(value);

      if (parsedValue === 100) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: "100",
        }));
        return;
      }

      if (
        decimalPattern.test(value) &&
        parsedValue >= 0 &&
        parsedValue <= 100
      ) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "yearFounded" || name === "officeYearFounded") {
      const yearPattern = /^\d{0,4}$/;

      if (value === "" || (yearPattern.test(value) && value.length <= 4)) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleInvoiceInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const maxLength = 10;

    if (name === "altitude" || name === "latitude") {
      if (value.length > maxLength) {
        return;
      }
      const trimmedValue = value.trim();
      if (
        trimmedValue.length > maxLength ||
        !/^[-+]?[0-9]*\.?[0-9]+$/.test(trimmedValue)
      ) {
        setInvoiceErrors((prevState) => ({
          ...prevState,
          [name]: {
            error: true,
            msj: "Invalid format or length exceeds 10 characters",
          },
        }));
      } else {
        setInvoiceErrors((prevState) => ({
          ...prevState,
          [name]: { error: false, msj: "" },
        }));
      }
      setInvoiceAddress((prevInvoice) => ({
        ...prevInvoice,
        [name]: trimmedValue,
      }));
      setInvoiceErrors((prevState) => ({
        ...prevState,
        address1: { error: false, msj: "" },
      }));
    } else {
      setInvoiceAddress((prevInvoice) => ({
        ...prevInvoice,
        [name]: value,
      }));
      setInvoiceErrors((prevState) => ({
        ...prevState,
        address1: { error: false, msj: "" },
      }));
    }
  };

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    setAddressErrors((prevState) => ({
      ...prevState,
      notes: { error: false, msj: "" },
    }));

    if (value.length > 200) {
      setAddressErrors((prevState) => ({
        ...prevState,
        notes: { error: true, msj: "Character limit has been reached." },
      }));
    }
    setSelectedAddress((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInvoiceTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    setInvoiceErrors((prevState) => ({
      ...prevState,
      notes: { error: false, msj: "" },
    }));

    if (value.length > 200) {
      setInvoiceErrors((prevState) => ({
        ...prevState,
        notes: { error: true, msj: "Character limit has been reached." },
      }));
    }
    setInvoiceAddress((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInvoiceCheckBoxChange = (name: string, checked: boolean) => {
    setInvoiceAddress((prevState: any) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSetSelectedCountry = (e: any) => {
    setSelectedCountry(e);
    setSelectedState({ id: "", name: "" });
    setSelectedCity({ id: "", name: "" });

    setAddressErrors((prevState) => ({
      ...prevState,
      country: { error: false, msj: "" },
    }));
  };

  const handleSetSelectedState = (e: any) => {
    setSelectedState(e);
    setSelectedCity({ id: "", name: "" });
    setAddressErrors((prevState) => ({
      ...prevState,
      city: { error: false, msj: "" },
    }));
  };

  const handleSetSelectedCity = (e: any) => {
    setSelectedCity(e);
    setSelectedAddress({ ...selectedAddress, city: e.id });
    setAddressErrors((prevState) => ({
      ...prevState,
      state: { error: false, msj: "" },
    }));
  };

  const handleSetSelectedAddressType = (e: any) => {
    setSelectedAddressType(e);
    setSelectedAddress({ ...selectedAddress, addressType: e });
    setAddressErrors((prevState) => ({
      ...prevState,
      addressType: { error: false, msj: "" },
    }));
  };

  const handleSetSelectedInvoiceAddressType = (e: any) => {
    setSelectedInvoiceAddressType(e);
    setInvoiceAddress({ ...invoiceAddress, addressType: "Legal" });
  };

  const handleSwitchToggle = (checked: boolean) => {
    setUseSameAddress(checked);
  };

  const buildOfficeData = () => {
    return {
      ...state.office,
      address: selectedAddress?.id,
      addressName: selectedAddress?.address1,
      phoneNumber: {
        phone: phoneNumber.phone,
        code: phoneNumber.code,
        basecode: phoneNumber.basecode,
      },
      faxNumber: {
        phone: faxNumber.phone,
        code: faxNumber.code,
        basecode: faxNumber.basecode,
      },
      yearFounded: formData.officeYearFounded,
      city: selectedCity.id,
      state: selectedState.id,
      country: selectedCountry.id,
      cityName: selectedCity.name,
      stateName: selectedState.name,
      countryName: selectedCountry.name,
      regionName: countryData.find((c) => c.id === selectedCountry.id)?.regionNames,
      moreInformation: moreInformation,
      websiteUrl: formData.websiteUrl,
      languages: selectedLanguages.map((lang: any) => lang.id),
      overview: formData.overview,
    }
  }  

  const getAddresses = async () => {
    try {
      const [[_officeAddress], [_invoiceAddress]] = await Promise.all([
        getAddresById(manageFirm.selectedFirm?.address ?? ""),
        getAddresById(manageFirm.selectedFirm?.invoiceAddress ?? ""),
      ])
      setUseSameAddress(!_invoiceAddress)
      if (_invoiceAddress) {
        setInvoiceAddress(_invoiceAddress);
        setPrevInvoiceAddress(_invoiceAddress)
        setInvoiceCountry(countryData.find(c => c.id === _invoiceAddress.country));
        setInvoiceState(statesData.find(c => c.id === _invoiceAddress.state));
        const _invoiceCity = citiesList.find(c => c.id === _invoiceAddress.city);
        if (_invoiceCity) setInvoiceCity({id: _invoiceCity.id ?? "", name: _invoiceCity?.name ?? ""});
      }

      setSelectedAddress(_officeAddress);
      setPrevFirmAddress(_officeAddress)
      setSelectedAddressType(_officeAddress?.addressType);
    } catch (error) {
      console.error(error);
    }
  }

  const handleUpdateInfo = async () => {
    if (!checked) setChecked(true);
    if (validateFields()) return;

    try {
      let logoUrl = typeof logo === "string" ? logo : "";
      let smallLogoUrl = typeof smallLogo === "string" ? smallLogo : "";

      if (logo && logo instanceof File && manageFirm.selectedFirm) {
        const uploadedLogoUrl = await uploadLogo(logo);
        logoUrl = uploadedLogoUrl ?? "";
      }

      if (smallLogo && smallLogo instanceof File && manageFirm.selectedFirm) {
        const uploadedLogoUrl = await uploadLogo(smallLogo);
        smallLogoUrl = uploadedLogoUrl ?? "";
      }

      const updatedRepresentativeClientsFirm = await updateRepresentativeClientsFirms(representativeClientsFirm);
      await updateRepresentativeClients(updatedRepresentativeClientsFirm);
      await updateOffice()
      const firmAddress = await updateAddress()
      const invoiceAddress = await updateInvoiceAddress()

      const updatedNotableItems = await updateNotables(notableItems)
      await updateFirm(logoUrl, smallLogoUrl, updatedNotableItems ?? [], firmAddress?.id, invoiceAddress?.id);

      navigate(-1);
    } catch (error) {
      console.error(error);
      //TODO rollback
    }
  };

  const uploadLogo = async (logo: File) => {
    try {
      return manageFirm.selectedFirm ? await uploadImage(logo, manageFirm.selectedFirm.id, "firm/logos/") : "";
    } catch (error) {
      console.error(error)
      return null;
    }
  }
  
  const updateNotables = async (notableItems: NotableItem[]) => {
    try {
      if (!notableItems.length) return [];
      return await Promise.all(
        notableItems.map(async (item) => {
          if (item.photo && item.photo instanceof File) {
            const uploadedPhotoUrl = await uploadImage(
              item.photo,
              manageFirm.selectedFirm?.id ?? "",
              "firm/notables/"
            );
            
            if (uploadedPhotoUrl) {
              return { ...item, photo: uploadedPhotoUrl };
            } else {
              console.error("Error uploading notable item photo.");
              return { ...item, photo: "" };
            }
          }
          return item;
        })
      );
    } catch (error) {
      console.error(error)
    }
  }

  const updateRepresentativeClientsFirms = async (representativeClientsFirm: any[]) => {
    try {
      if (!representativeClientsFirm.length && manageFirm.selectedFirm?.serviceType !== "Investment Banking") return representativeClientsFirm
      return await Promise.all(
        representativeClientsFirm.map(async (item) => {
          if (item.logo && item.logo instanceof File && (item.new || item.updated)) {
            const uploadedPhotoUrl = await uploadImage(item.logo,manageFirm.selectedFirm?.id ?? "","firm/representativeClient/");
            return { ...item, logo: uploadedPhotoUrl };
          }
          return item;
        })
      );
    } catch (error) {
      console.error(error)
    }
  }

  const updateFirm = async (
    logoUrl: string, 
    smallLogoUrl: string, 
    updatedNotableItems: NotableItem[], 
    address?: string,
    invoiceAddress?: string
  )  => {
    try {
      if (manageFirm.selectedFirm) {
        const firmData: IFirm = {
          ...manageFirm.selectedFirm,
          firmName,
          logo: logoUrl,
          smallLogo: smallLogoUrl,
          firmOverview: formData.overview,
          cityId: selectedCity.id,
          cityName: selectedCity.name,
          state: selectedState.id,
          stateName: selectedState.name,
          country: selectedCountry.id,
          countryName: selectedCountry.name,
          yearFounded: formData.yearFounded,
          websiteUrl: formData.websiteUrl,
          listingCategory: listingCategory,
          notables: updatedNotableItems ?? [],
          litigationPercentage: formData.litigationPercentage,
          address: address ?? selectedAddress.id,
          invoiceAddress: useSameAddress ? undefined : invoiceAddress,
        };
        try {
          const response = await update.firm({
            firm: firmData, 
            pathname, 
            prevState: manageFirm.selectedFirm
          });
          dispatch(manageFirmSlice.actions.SetFirm(response))
        } catch (error) {
          console.error(error)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const updateRepresentativeClients = async (updatedRepresentativeClientsFirm: any) => {
    try {
      if (!manageFirm.selectedFirm) return;

      const updateAndCreateRepresentativeClients = updatedRepresentativeClientsFirm?.map(updateOrCreateClient);
      const deleteRepresentativeClients = representativeClientsDelete?.map(async (id) => {
        const clientToDelete = representativeClients?.find((rC: any) => rC.id === id);
        if (clientToDelete && manageFirm.selectedFirm) {
          await erase.representativeClient({ representativeClient: clientToDelete.id, pathname, firmId: manageFirm.selectedFirm.id, });
        }
      });
      
      await Promise.all([
        ...(updateAndCreateRepresentativeClients || []),
        ...(deleteRepresentativeClients || []),
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const updateOrCreateClient = async (client: any) => {
    if (!manageFirm.selectedFirm) return;
    
    const { new: isNew, id } = client;
    if (isNew) {
      delete client.new;
      await create.representativeClient({ representativeClient: client, pathname, firmId: manageFirm.selectedFirm.id });
    } else {
      const prevState = representativeClients?.find((d: any) => d.id === id);
      await update.representativeClient({ representativeClient: client, pathname, prevState, firmId: manageFirm.selectedFirm.id, });
    }
  };

  const updateAddress = async () => { 
    if (!selectedAddress) return 
    try {
      const address: IAddress = createAddressPayload(
        selectedAddress,
        selectedCity,
        selectedState,
        selectedCountry,
        {
          id: selectedAddress.id,
        }
      );

      return await update.address({
        address: address, 
        pathname, 
        prevState: prevFirmAddress, 
        firmId: manageFirm.selectedFirm?.id ?? ""
      });
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const updateInvoiceAddress = async () => { 
    if (useSameAddress) return 

    try {
      const address = createAddressPayload(
        invoiceAddress,
        invoiceCity,
        invoiceState,
        invoiceCountry,
        {
          addressType: "Legal",
          isInvoicing: true,
        }
      );
      if (prevInvoiceAddress.id) {
        return await update.address({
          address: address,
          pathname,
          prevState: prevInvoiceAddress,
          firmId: manageFirm.selectedFirm?.id ?? "",
        })
      } else {
        return await create.address({
          address: address,
          pathname,
          firmId: manageFirm.selectedFirm?.id ?? "",
        })
      }
    } catch (error) {
      throw error
    }
  }

  const updateOffice = async () => {
    try {
      const officeData: IOffice = buildOfficeData();
  
      await update.office({
        office: officeData, 
        pathname, 
        prevState: state.office
      });
    } catch (error) {
      console.error(error)
      throw error
    }
  }


  const validateFields = () => {
    let hasErrors = false;

    const newOfficeErrors: AddressErrors = {
      address1: { error: false, msj: "" },
      country: { error: false, msj: "" },
      addressType: { error: false, msj: "" },
    };

    const newFirmErrors: FirmErrors = {
      firmName: { error: false, msj: "" },
      logo: { error: false, msj: "" },
      websiteUrl: { error: false, msj: "" },
      overview: { error: false, msj: "" },
    };

    const newPhoneErrors: officePhoneErrors = {
      phone: { error: false, msj: "" },
    };

    const newInvoiceErrors: AddressErrors = {
      address1: { error: false, msj: "" },
      country: { error: false, msj: "" },
      addressType: { error: false, msj: "" },
    };

    const newNotableErrors: { errorMsj: string }[] = [];

    const addError = (errorsObj: any, fieldName: string, message: string) => {
      errorsObj[fieldName] = { error: true, msj: message };
      hasErrors = true;
    };

    notableItems.forEach((notable, index) => {
      const errorMsj =
        !notable.link && !notable.photo
          ? "URL and photo are required"
          : !notable.link
          ? "URL is required"
          : !notable.photo
          ? "Photo is required"
          : "";

      if (errorMsj !== "") {
        newNotableErrors[index] = { errorMsj };
        hasErrors = true;
      }
      if (errorMsj === "") {
        newNotableErrors[index] = { errorMsj };
      }
    });

    if (!firmName) {
      addError(newFirmErrors, "firmName", "Law Firm Name is Required");
    }
    if (!logo) {
      addError(newFirmErrors, "logo", "Logo Required");
    }

    if (!formData.websiteUrl || formData.websiteUrl === "") {
      addError(newFirmErrors, "websiteUrl", "Firm Website required");
    }

    if (!formData.overview || formData.overview === "") {
      addError(newFirmErrors, "overview", "Firm Overview required");
    }

    if (!selectedAddress?.address1) {
      addError(newOfficeErrors, "address1", "Address 1 is required");
    }
    if (!selectedCountry.id) {
      addError(newOfficeErrors, "country1", "Office Country is required");
    }
    if (!selectedAddressType) {
      addError(newOfficeErrors, "addressType", "Address Type is required");
    }
    if (!useSameAddress) {
      if (!invoiceAddress?.address1) {
        addError(
          newInvoiceErrors,
          "address1",
          "Address 1 is required for invoice"
        );
      }
      if (!invoiceCountry.id) {
        addError(
          newInvoiceErrors,
          "country",
          "Country is required for invoice"
        );
      }
    }

    setOfficePhoneErrors(newPhoneErrors);
    setFirmErrors(newFirmErrors);
    setAddressErrors(newOfficeErrors);
    setInvoiceErrors(newInvoiceErrors);
    setNotableErrors(newNotableErrors);
    const newActiveErrors = {
      ...newFirmErrors,
      ...newOfficeErrors, 
      ...newPhoneErrors,
      ...newInvoiceErrors, 
      invoiceAddress: newInvoiceErrors.address1,
      invoiceCountry: newInvoiceErrors.country,
      invoiceAddressType: newInvoiceErrors.addressType,
    };

    setActiveErrors(newActiveErrors);
    return hasErrors;
  };

  const isSomeError = (activeErrors: any): boolean => {
    for (const key in activeErrors) {
      if (activeErrors[key] === true) {
        return true;
      }
    }
    return false;
  };

  const createAddressPayload = (
    address: any,
    cityObj: any,
    stateObj: any,
    countryObj: any,
    additionalFields = {}
  ): IAddress => {
    return {
      ...address,
      ...additionalFields,
      address1: address.address1,
      address2: address.address2,
      zipCode: address.zipCode,
      venueLink: address.venueLink,
      addressType: address.addressType,
      noZipCode: address.noZipCode,
      notes: address.notes,
      city: cityObj.id,
      cityName: cityObj.name,
      country: countryObj.id,
      countryName: countryObj.name,
      state: stateObj.id,
      stateName: stateObj.name,
      altitude: address.altitude,
      latitude: address.latitude,
    };
  };

  const handleCheckBoxChange = (name: string, checked: boolean) => {
    setSelectedAddress((prevState: any) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleChangePhone = (obj: any) => {
    setPhoneNumber({ code: obj.code, phone: obj.phone, basecode: obj.basecode });

    setOfficePhoneErrors((prevState) => ({
      ...prevState,
      phone: { error: false, msj: "" },
    }));
  };

  const handleChangeFax = (obj: any) => {
    setFaxNumber({ code: obj.code, phone: obj.phone, basecode: obj.basecode });
  };

  const handleSetLogo = (image: any) => {
    setLogo(image);
    setFirmErrors((prevState) => ({
      ...prevState,
      logo: { error: false, msj: "" },
    }));
  };

  const handleSetSmallLogo = (image: any) => {
    setSmallLogo(image);
  };

  const filteredProfessionals = getFilteredProfessionalsByFirm(
    manageFirm.selectedFirm?.id ?? ""
  ).length;
  const filteredOffices = getFilteredOfficesByFirm(state.office.firm).length;

  const handleFirmTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    setFirmErrors((prevState) => ({
      ...prevState,
      overview: { error: false, msj: "" },
    }));

    if (value.length >= 3500) {
      setFirmErrors((prevState) => ({
        ...prevState,
        overview: { error: true, msj: "Character limit has been reached." },
      }));
    }
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addNewLanguage = (newLanguage: Option) => {
    setLanguages((prevLanguages) => [...prevLanguages, newLanguage]);
  };

  const handleCloseSaveOverride = () => {
    setSaveOverrideOpen(!saveOverrideOpen);
  };

  return (
    <>
      <BackgroundComponent image={ProfileViewModeBg} />
      <main className="mainSection">
        <SaveOverride
          fields={errorFields}
          handleButtonClick={()=>{}}
          open={saveOverrideOpen}
          close={handleCloseSaveOverride}
          noSaveOverride
        />
        <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 !items-end self-stretch !p-0 flex-col my-10">
          <div className="flex p-0 flex-col justify-center items-end gap-5 self-stretch md:flex-row lg:justify-between lg:gap-6">
            <h2 className="text-left w-full md:w-max text-white font-decimal text-[2.25rem] font-medium leading-[2.75rem] md:text-[2.75rem] md:leading-[3.25rem] lg:text-[3.25rem] lg:leading-[4.5rem]">
              Modify Main Office
            </h2>
            <div
              className="self-stretch flex-1 hidden md:flex"
              style={{
                backgroundImage: `url(${WSGDots.default})`,
              }}
            ></div>
            <div className="flex items-center my-auto">
              <NewButton text="Preview" content="text" color="accent" />
            </div>
          </div>
          <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage
              prevPage="Manage Firm > Profile > Offices"
              currentPage={`Modify Main Office`}
            />
          </div>
        </div>
        <article className="articleSection relative">
          <section className="formsectionNTitle">
            <FirmGeneralInformation
              handleSetLogo={handleSetLogo}
              handleSetSmallLogo={handleSetSmallLogo}
              handleChangePhone={handleChangePhone}
              handleChangeFax={handleChangeFax}
              handleInputChange={handleInputChange}
              handleInvoiceInputChange={handleInvoiceInputChange}
              handleTextAreaChange={handleTextAreaChange}
              handleInvoiceTextAreaChange={handleInvoiceTextAreaChange}
              handleInvoiceCheckBoxChange={handleInvoiceCheckBoxChange}
              handleSetInvoiceCity={handleSetInvoiceCity}
              handleSetInvoiceCountry={handleSetInvoiceCountry}
              handleSetInvoiceState={handleSetInvoiceState}
              handleSetSelectedCountry={handleSetSelectedCountry}
              handleSetSelectedState={handleSetSelectedState}
              handleSetSelectedCity={handleSetSelectedCity}
              handleSetSelectedAddressType={handleSetSelectedAddressType}
              handleSetSelectedInvoiceAddressType={
                handleSetSelectedInvoiceAddressType
              }
              handleSwitchToggle={handleSwitchToggle}
              handleCheckBoxChange={handleCheckBoxChange}
              countryData={countryData}
              stateData={stateData}
              cityData={cityData}
              selectedCountry={selectedCountry}
              selectedState={selectedState}
              selectedCity={selectedCity}
              selectedAddress={selectedAddress}
              selectedAddressType={selectedAddressType}
              invoiceAddress={invoiceAddress}
              directPhoneCode={phoneNumber}
              faxNumber={faxNumber}
              logo={logo}
              smallLogo={smallLogo}
              firmName={firmName}
              addressErrors={addressErrors}
              officePhoneErrors={officePhoneErrors}
              invoiceCity={invoiceCity}
              invoiceState={invoiceState}
              invoiceCountry={invoiceCountry}
              invoiceErrors={invoiceErrors}
              firmErrors={firmErrors}
              useSameAddress={useSameAddress}
              selectedInvoiceAddressType={selectedInvoiceAddressType}
            />

            <FirmInformation
              formData={formData}
              firmName={firmName}
              handleFirmDataInputChange={handleFirmDataInputChange}
              filteredProfessionals={filteredProfessionals}
              filteredOffices={filteredOffices}
              selectedLanguages={selectedLanguages}
              languages={languages}
              addNewLanguage={addNewLanguage}
              setSelectedLanguages={setSelectedLanguages}
              handleFirmTextAreaChange={handleFirmTextAreaChange}
              isLanguageModalOpen={isLanguageModalOpen}
              toggleLanguageModal={toggleLanguageModal}
              viewportWidth={viewportWidth}
              firmErrors={firmErrors}
            />

            <OfficeMainContacts
              officeName={state?.office.name}
              mainContacts={state?.mainContacts}
              firmId={manageFirm?.selectedFirm?.id}
            />

            <ListingLayout
              listingCategories={listingCategory}
              setListingCategories={setListingCategory}
            />

            <AreasOfPracticeOffice
              officeName={state.office.name}
              aops={state.aops}
              firmId={manageFirm?.selectedFirm?.id}
            />

            <Notables
              officeName={state.office.name}
              notables={notableItems}
              setNotables={setNotableItems}
              notablesError={notableErrors}
            />

            {manageFirm?.selectedFirm?.serviceType !== "Investment Banking" && (
              <OfficeRepresentativeClient
                officeName={state.office.name}
                representativeClients={representativeClientsFirm}
                setRepresentativeClients={setRepresentativeClientsFirm}
                firmId={manageFirm.selectedFirm?.id}
                firmName={manageFirm.selectedFirm?.firmName}
                officeId={state.office.id}
                representativeClientsDelete={representativeClientsDelete}
                setRepresentativeClientsDelete={setRepresentativeClientsDelete}
              />
            )}
            {manageFirm?.selectedFirm?.serviceType === "Investment Banking" && (
              <OfficeRepresentativeClientIB
                officeName={state.office.name}
                representativeClients={representativeClientsFirm}
                setRepresentativeClients={setRepresentativeClientsFirm}
                firmId={manageFirm.selectedFirm?.id}
                firmName={manageFirm.selectedFirm?.firmName}
                officeId={state.office.id}
                representativeClientsDelete={representativeClientsDelete}
                setRepresentativeClientsDelete={setRepresentativeClientsDelete}
              />
            )}

            <MoreInformation
              moreInformation={moreInformation}
              setMoreInformation={setMoreInformation}
            />

            <LatestUpdate lastModified={manageFirm.selectedFirm?.dateCreated}/>

            <ButtonsCrud
              Catalog="Address"
              mode={"edit"}
              id={manageFirm.selectedFirm?.id ?? ""}
              disabled={!canSave}
              actionButton={handleUpdateInfo}
              onDelete={() => ""}
              hiddenDelete
              state={state.office}
              newState={buildOfficeData()}
            />
          </section>
        </article>
      </main>
    </>
  );
};

export default ModifyMainOffices;
