import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRegion } from "../../../interfaces";

const initialState: IRegion[] = [];

export const regionsSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    SetRegions: (_, action: PayloadAction<IRegion[]>) => action.payload,
    AddRegion: (state, action: PayloadAction<IRegion>) => {
      state.push(action.payload);
    },
    UpdateRegion: (state, action: PayloadAction<IRegion>) => {
      const index = state.findIndex((region) => region.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },
    DeleteRegion: (state, action: PayloadAction<string>) => {
      return state?.filter((region) => region.id !== action.payload);
    },
  },
});

export const { SetRegions, AddRegion, UpdateRegion, DeleteRegion } =
  regionsSlice.actions;
