import React, { useEffect, useRef, useState } from "react";
import PersonDataEdit from "../../../components/Moleculas/PersonCatalog/PersonDataEdit";
import PersonOfficeEdit from "../../../components/Moleculas/PersonCatalog/PersonOfficeEdit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PersonRelations from "../../../components/Moleculas/PersonCatalog/PersonRelations";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import usePersonAdd from "../../../hooks/Catalogs/usePersonAdd";
import {
  IPerson,
  PersonCatalogsProps,
} from "../../../interfaces/models/IPerson";
import "../../../assets/css/AddCatalogs.css";
import GoBack from "../../../components/Moleculas/GoBack";
import { MemberType } from "../../../Enums/PersonEnums";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import PersonSocialEdit from "../../../components/Moleculas/PersonCatalog/PersonSocialEdit";
import PersonBioEdit from "../../../components/Moleculas/PersonCatalog/PersonBio";
import NewUserToggle from "../../../components/Moleculas/PersonCatalog/NewUserToggle";
import { axios } from "../../..";
import { Appstore, personSlice } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import ProfileEducationEdit from "../../../components/Moleculas/Profile/ProfileEducationEdit";
import BackgroundComponent from "../../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from "../../../assets/images&icons/profileViewMode.jpeg";
import CurrentPage from "../../../components/Layout/CurrentPageTab/CurrentPage";
import { getEducationByPersonId } from "../../../Services/Education";
import { IEducation } from "../../../interfaces/models/IEducation";
import { useUpdateAndLog } from "../../../hooks/LogActivity/LogActivityUpdates";
import { useCreateAndLog } from "../../../hooks/LogActivity/LogActivityCreates";
import { useDeleteAndLog } from "../../../hooks/LogActivity/LogActivityDeletes";
const WSGDots = require("../../../assets/images&icons/WSGDots.svg");

const AddProfessional: React.FC<PersonCatalogsProps> = (
  props: PersonCatalogsProps
) => {
  const currentProffessional: IPerson = useSelector(
    (store: Appstore) => store.person
  );
  const [saveOverrideOpen, setSaveOverrideOpen] = React.useState(true);
  const [errorFields, seterrorFields] = React.useState<string[]>([]);
  const {
    personState,
    personErrors,
    offices,
    officesAdditional,
    handleAddAdmision,
    handleMultipleSelectChange,
    handleInputChange,
    handleCheckBoxChange,
    handleRadioButtonChange,
    handlePhoneNumberChange,
    handleAddSocialNetworks,
    handleAddAdditionalOffices,
    handleTextAreaElement,
    handleAddlanguages,
    handleSubmit,
    handleDelete,
    handleButtonClick,
    setRegularPhoto,
  } = usePersonAdd(currentProffessional ? currentProffessional : {});
  const [userError, setUserError] = React.useState({
    error: false,
    errorMsj: "",
  });

  const navigate = useNavigate();
  const [user, setUser] = React.useState({
    hasUser: false,
    userName: "",
    accessType: "",
  });
  const { pathname } = useLocation();
  const update = useUpdateAndLog();
  const create = useCreateAndLog();
  const erase = useDeleteAndLog();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [educations, setEducations] = useState<IEducation[]>([]);
  const [newEducations, setNewEducations] = useState<IEducation[]>([]);

  useEffect(() => {
    if (!personState.id) return
    getEducations(personState.id)
  }, [personState]);

  useEffect(() => {
    dispatch(personSlice.actions.ModifyPerson({...currentProffessional, firm: id}))
  }, [id]);

  useEffect(() => {
    if (
      Object.values(personErrors).some((error) => {
        return error.error === true;
      })
    ) {
      setSaveOverrideOpen(true);
    }

    const fieldsWithError: string[] = Object.entries(personErrors)
      .filter(([key, value]) => value.error)
      .map(([key]) => {
        let formattedKey = key.charAt(0).toUpperCase() + key.slice(1);
        formattedKey = formattedKey.replace(/([A-Z])/g, " $1");
        return formattedKey;
      });

    seterrorFields(fieldsWithError);

    if (fieldsWithError.length === 0) {
      setSaveOverrideOpen(false);
    }
  }, [personErrors]);

  const formSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (user.hasUser && user.accessType === "") {
      setUserError({ error: true, errorMsj: "Please select an Access Type" });
      seterrorFields((currentErrorFields) => [
        ...currentErrorFields,
        "Access Type",
      ]);
      setSaveOverrideOpen(true);
      return;
    }

    try {
      const newUser = {
        fullName: `${personState.name} ${personState.middleInitial} ${personState.lastName}`,
        nickName: user.userName,
        password: "gsW@4321",
        access: user.accessType,
        email: personState.firmEmail,
      }
      await handleSubmit(e, "", true, newUser);
      await handleEducations()
      if (user.hasUser) {
        if (user.accessType === "Data Admin + Member") {
          navigate(`/Staff/ManageFirm/GeneralTab/${currentProffessional.firm}/DataAdmin`, {
            state: {
              ...personState,
              id,
              firm: personState.firmName,
              showModal: true,
            },
          });
        }
      } else navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEducations = async () => {
    const educationIds = educations.map((education) => education.id);
    const newEducationIds = newEducations.map((education) => education.id);

    const educationsToCreate = educations.filter(
      (education) => !newEducationIds.includes(education.id)
    );

    const educationsToUpdate = educations.filter((newEducation) => {
      const existingEducation = newEducations.find(
        (education) => education.id === newEducation.id
      );
      return existingEducation && JSON.stringify(existingEducation) !== JSON.stringify(newEducation);
    });

    const educationsToDelete = newEducations.filter(
      (newEducation) => !educationIds.includes(newEducation.id)
    );

    try {
      await Promise.all([
        ...educationsToDelete.map((education) => erase.education({education, pathname})),
        ...educationsToUpdate.map((education) => update.education({education, pathname, prevState: educations.find((prevEducation) => prevEducation.id === education.id)})),  
        ...educationsToCreate.map((education) => create.education({education, pathname})),
      ]);
    } catch (error) {
      console.error('Error handling educations:', error);
    }
  };

  const getEducations = async (id: string) => {
    try {
      const response = await getEducationByPersonId(id)
      setEducations(response);
      setNewEducations(response);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <BackgroundComponent image={ProfileViewModeBg} />
      <div className="bg-gray-100 min-h-screen text-left px-4 py-7 items-start flex flex-col gap-[1.5rem] md:py-8 md:px-6 lg:py-10 lg:px-10 lg:gap-[2.5rem]">
        <div className="flex flex-col items-start gap-5 self-stretch">
          <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 self-stretch !p-0 flex-col ">
            <div className="flex p-0 flex-row justify-center gap-5 self-stretch md:flex-row lg:justify-between lg:gap-6">
              <h2 className="text-left w-full lg:w-max text-white font-decimal text-[2.25rem] font-medium leading-[2.75rem] md:text-[2.75rem] md:leading-[3.25rem] lg:text-[3.25rem] lg:leading-[4.5rem]">
                {`${props.mode === "add" ? "New Professional" : "Edit " + (currentProffessional.name ? `${currentProffessional.name} ${currentProffessional.lastName}` : "none")}`}
              </h2>
              <div
                className="self-stretch flex-1 hidden lg:flex"
                style={{
                  backgroundImage: `url(${WSGDots.default})`,
                }}
              ></div>
              <div className="flex h-[4rem] w-fit items-center">
                <GoBack className="!text-white" />
              </div>
            </div>
            <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
            <div className="flex w-full">
              <CurrentPage
                prevPage="Professionals"
                currentPage="Professional Profile"
              />
            </div>
            <form onSubmit={formSubmit}>
              <SaveOverride
                fields={errorFields}
                handleButtonClick={handleButtonClick}
                open={saveOverrideOpen}
                close={() => setSaveOverrideOpen(false)}
              />
              <section className="articleSection">
                <PersonRelations
                  mode={props.mode}
                  isContentRedirect={false}
                  {...personState}
                  Errors={personErrors}
                  handleInputChange={handleInputChange}
                  handleMultipleSelectChange={handleMultipleSelectChange}
                  handleRadioButtonChange={handleRadioButtonChange}
                  disaableCatalogList
                  content={{ memberFirms: [currentProffessional.firm] }}
                  disabledFirm
                />
                <PersonDataEdit
                  {...personState}
                  setPhoto={setRegularPhoto}
                  offices={offices}
                  Errors={personErrors}
                  handlePhoneNumberChange={handlePhoneNumberChange}
                  handleInputChange={handleInputChange}
                  handleCheckBoxChange={handleCheckBoxChange}
                  showSendCredentials
                />
                {MemberType.member === personState.memberType && (
                  <PersonOfficeEdit
                    {...personState}
                    offices={officesAdditional}
                    handleAddAdditionalOffices={handleAddAdditionalOffices}
                  />
                )}
                <PersonSocialEdit
                  {...personState}
                  handleAddSosialNetwork={handleAddSocialNetworks}
                />
                <PersonBioEdit
                  {...personState}
                  handleTextAreaElement={handleTextAreaElement}
                  handleAddlanguages={handleAddlanguages}
                  handleAddAdmision={handleAddAdmision}
                />
                <ProfileEducationEdit
                  person={personState}
                  educations={educations}
                  setEducations ={setEducations}
                />
                {!personState.userId && 
                  <NewUserToggle
                    personState={personState}
                    user={user}
                    setUser={setUser}
                    errorFields={errorFields}
                    seterrorFields={seterrorFields}
                    userError={userError}
                    setUserError={setUserError}
                  />
                }
                <ButtonsCrud
                  title={"Create"}
                  mode={props.mode}
                  id={""}
                  onDelete={handleDelete}
                  actionButton={handleButtonClick}
                  disabled={Object.values(personErrors).some((error) => {
                    return error.error === true;
                  })}
                  Catalog={"Person/"}
                  state={currentProffessional}
                  newState={personState}
                />
              </section>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProfessional;
