import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import css from "./HeaderExtra.module.css";
import ToggleSwitch from "./Toggle";
import { useStaffMode } from "../../../hooks/StaffModeProvider";
import AuthContext from "../../../store/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { Appstore, setExtraMenuIsOpen, setSideMenuIsOpen } from "../../../redux";
import Cookies from "js-cookie";

const HeaderExtra = () => {
  const [isStaff, setIsStaff] = useState(false);
  const [userId, setUserId] = useState<any>();
  const { isStaffMode } = useStaffMode();
  const { setIsAuthenticated, tokenDecoded } =
    useContext(AuthContext);
  const isOpen = useSelector((state: Appstore) => state.menus.extraMenuIsOpen);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setExtraMenuIsOpen(false));
  }, [pathname])

  const userPermissions = tokenDecoded?.extension_WSG_Permissions
    ? tokenDecoded.extension_WSG_Permissions.split(",")
    : [];
  const isDataAdmin = userPermissions.includes("Data Admin + Member");

  const eraseCookie = (name: string) => {
		document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
		Cookies.remove(name);
  };

  const getCookie = (name: string) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("access_timestamp");
    sessionStorage.removeItem("token_decoded");

    eraseCookie("token");
    eraseCookie("refresh_token");
    eraseCookie("access_token");
    eraseCookie("access_timestamp");
    eraseCookie("token_decoded");

    setIsAuthenticated(false);
    localStorage.setItem("logout", Date.now().toString());
    window.location.href = "/";
  };

  useEffect(() => {
    const syncLogout = (event: StorageEvent) => {
      if (event.key === "logout") {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("refresh_token");
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("access_timestamp");
        sessionStorage.removeItem("token_decoded");

        eraseCookie("token");
        eraseCookie("refresh_token");
        eraseCookie("access_token");
        eraseCookie("access_timestamp");
        eraseCookie("token_decoded");

        setIsAuthenticated(false);
        window.location.href = "/";
      }
    };

    window.addEventListener("storage", syncLogout);

    return () => {
      window.removeEventListener("storage", syncLogout);
    };
  }, [setIsAuthenticated]);

  useEffect(() => {
    const tokenDecodedString =
      sessionStorage.getItem("token_decoded") || getCookie("token_decoded");
    if (tokenDecodedString) {
      const parsed = JSON.parse(tokenDecodedString);
      setUserId(parsed.oid);
    }

    const permissions = tokenDecodedString
      ? JSON.parse(tokenDecodedString).extension_WSG_Permissions
      : null;
    setIsStaff(
      permissions === "WSG Staff" || permissions === "WSG Staff Super"
    );
  }, [sessionStorage.getItem("token_decoded")]);

  useEffect(() => {
    const iconElement = document.getElementById("favicon");
    const icon = isStaffMode ? "/favicon_staff.png" : "/favicon.png";
    iconElement?.setAttribute("href", icon);
  }, [isStaffMode]);

  const isStaffLink = isStaffMode ? `/staff` : "";

  const navUrl = "/staff";

  return (
    <React.Fragment>
      <div
        id="headerExtra"
        className={`w-full h-auto ${isStaffMode ? 'bg-secondary-light' : 'bg-primary-light'} ${
          isOpen ? "max-h-screen translate-y-0 z-20" : "max-h-0 -z-10"}
        md:px-4 left-0 md:items-center md:align-middle md:justify-end md:flex-row 
        flex flex-col-reverse transition-all duration-300 ease-in-out transform sticky top-0`}
      >
        <div className="md:order-1">
          <ul
            className={`md:flex justify-center ml-auto text-white text-lg font-medium roboto_font align-middle`}
          >
            {!isDataAdmin && (
              <>
                {!isStaffMode && (
                  <li
                    className={`md:mx-3 py-4 cursor-pointer  ${css.link} ${css.leftToRight}`}
                  >
                    <Link to={`${isStaffLink}/mywsg`}>
                      My WSG
                    </Link>
                  </li>
                )}
                <li
                  className={`md:mx-3 py-4 cursor-pointer  ${css.link} ${css.leftToRight}`}
                >
                  <Link
                    to={
                      userId
                        ? `${isStaffMode ? `${navUrl}/profile/attorney/${userId}` : `/profile/attorney/${userId}`}`
                        : ""
                    }
                  >
                    My Profile
                  </Link>
                </li>
                {!isStaffMode && (
                  <>
                    <li
                      className={`md:mx-3 py-4 cursor-pointer  ${css.link} ${css.leftToRight}`}
                    >
                      <Link to={`/referrals`}>
                        Referrals
                      </Link>
                    </li>
                    <li
                      className={`md:mx-3 py-4 cursor-pointer  ${css.link} ${css.leftToRight}`}
                    >
                      <Link to={`/inbox`}>
                        Inbox
                      </Link>
                    </li>
                  </>
                )}
              </>
            )}
            <li
              className={`md:mx-3 py-4 cursor-pointer  ${css.link} ${css.leftToRight}`}
              onClick={handleLogout}
            >
              Log Out
            </li>
          </ul>
        </div>
        {isStaff && (
          <>
            <div className="w-1 h-12 bg-white border-l border-white mx-4 hidden md:block md:order-2"></div>
            <ToggleSwitch IsStaffNavigate={isStaffMode} onToggle={() => {}} />
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default HeaderExtra;
