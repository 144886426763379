import React, { useEffect, useState } from "react";
import FirmContactFilter from "../../components/Catalogs/Filters/FirmContactFilter";
import { UseFirmList } from "../../hooks/Catalogs/useFirm";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import useDataLoader2 from "../../hooks/useDataLoader2";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import { Link } from "react-router-dom";
import Eye from "../../components/Atomos/Icons/ViewMode/Eye";
import {
  ColumnConfig,
  useColumnConfig,
} from "../../hooks/Table/UseColumnSettings";
import { transformData } from "../../hooks/Table/TransformColumnsForExport";
import ShapeEdit from "../../components/Atomos/Icons/ShapeEdit";
import { getPersons } from "../../Services/Person";
import { getFirms } from "../../Services/Firm";
import { getJurisdiction } from "../../Services/Jurisdiction";
import { getOffice } from "../../Services/Office";
import { defaultPageSize } from "../../utils/const";

const FirmContact: React.FC = () => {
  const Headers: string[] = [
    "firm",
    "level",
    "jurisdiction",
    "type",
    "name",
    "phone",
    "email",
  ];

  const [selectedFirm, setSelectedFirm] = useState<any[]>([]);
  const { generateColumns } = useColumnConfig(Headers);
  const [tableInstance, setTableInstance] = useState<any>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [allPersons, setAllPersons] = useState<any[]>([] as any[]);
  const [allFirms, setAllFirms] = useState<any[]>([] as any[]);
  const [allJurisdictions, setAllJurisdictions] = useState<any[]>([] as any[]);
  const [allOffices, setAllOffices] = useState<any[]>([] as any[]);
  const [exportData, setExportData] = useState<any[]>([]);
  const fields = [
    "id",
    'legacyId',
    "contacts",
    "firm",
    "level",
    "jurisdictions",
    "firmName",
    "_ts",
  ];

  const getAllPersons = async () => {
    const contacts = await getPersons();
    setAllPersons(contacts);
  };
  const getAllFirms = async () => {
    const firms = await getFirms();
    setAllFirms(firms);
  };
  const getAllJurisdictions = async () => {
    const jurisdictions = await getJurisdiction();
    setAllJurisdictions(jurisdictions);
  };
  const getAllOffices = async () => {
    const offices = await getOffice();
    setAllOffices(offices);
  };
  useEffect(() => {
    getAllPersons();
    getAllJurisdictions();
    getAllFirms();
    getAllOffices();
  }, []);

  const {
    data,
    totalCount,
    fetchData,
    setFilter,
    sort,
    setSort,
    pagination,
    setPagination,
  } = useDataLoader2<any>({
    endpoint: "/firmContact",
    defaultPageSize,
    fields,
  });

  const columns = generateColumns([
    {
      id: "count",
      header: "#",
      cell: (props: any) => (
        <p>
          {props.row.index + 1 + pagination.pageIndex * pagination.pageSize}
        </p>
      ),
      enableSorting: false,
      size: 50,
    },
    {
      id: "firm",
      header: "Firm",
      cell: (props: any) =>
        firmData.find((firm: any) => firm.id === props.getValue())?.name ??
        firmData.find((firm: any) => firm.shortId === props.getValue())?.name ??
        "No Value ---",
    },
    {
      id: "level",
      header: "Level",
      cell: (props: any) => (props.getValue() ? "Firm" : "Membership Location"),
    },
    {
      id: "edit",
      header: "Edit",
      cell: (props: any) => (
        <Link
          className="edit"
          to={`View/${props.row.original.id}`}
          state={props.row.original}
        >
          View
          <div className="editsvg">
            <Eye />
          </div>
        </Link>
      ),
      enableSorting: false,
    },
  ]);

  const clearSelections = () => {
    setSelectedFirm([]);
    fetchData(true);
  };
  const { firmData } = UseFirmList();

  useEffect(() => {
    if (!firmData.length) return;
    if (!isFetching) fetchData();
    setIsFetching(true);
  }, [firmData]);

  useEffect(() => {
    setFilter({
      firm: {
        value: selectedFirm?.map((c) => c.id),
        comparisonType: "EQUAL",
      },
    });
  }, [selectedFirm]);

  const transformFirmContactData = (data: any[]): any[] => {
    const transformedData: any[] = [];

    data.forEach((item) => {
      if (!item.contacts && !item.jurisdictions) return;

      const { firm, level, jurisdictions, contacts } = item;
      const actualFirm = firmData.find(
        (f: any) => f.id === firm || f.shortId === firm
      );
      if (level) {
        contacts.forEach((contact: any) => {
          if (!contact.contact) return;
          const actualPerson = allPersons.find(
            (p: any) => p.id === contact.contact
          );
          transformedData.push({
            firm: actualFirm?.name,
            level: "Firm",
            jurisdiction: allJurisdictions.find(
              (j: any) => j.id === actualFirm?.jurisdiction
            )?.name,
            type: contact.type,
            name: actualPerson?.name + " " + actualPerson?.lastName,
            phone: actualPerson?.phoneNumber,
            email: actualPerson?.firmEmail,
          });
        });
      } else {
        jurisdictions.forEach((jurisdictionObj: any) => {
          const { jurisdiction, contacts: jurisdictionContacts } =
            jurisdictionObj;
          jurisdictionContacts.forEach((contact: any) => {
            if (!contact.contact) return;
            const actualPerson = allPersons.find(
              (p: any) => p.id === contact.contact
            );
            transformedData.push({
              firm: actualFirm?.name,
              level: "Membership Location",
              jurisdiction: allJurisdictions.find(
                (j: any) => j.id === jurisdiction
              )?.name,
              type: contact.type,
              name: actualPerson?.name + " " + actualPerson?.lastName,
              phone: actualPerson?.phoneNumber,
              email: actualPerson?.firmEmail,
            });
          });
        });
      }
    });

    return transformedData;
  };

  useEffect(() => {
    const transformedData = transformFirmContactData(data);
    setExportData(transformedData);
  }, [data]);

  return (
    <>
      <div className=" mainSection">
        <CatalogTableC
          title={`Firm Contact Catalog`}
          catalogName={"FIRM CONTACT"}
          route={"FirmContact"}
          isfiltered={true}
          data={exportData}
          headers={Headers}
          totalCount={totalCount}
          exportData={exportData}
          noNewCatalog
        >
          <FirmContactFilter
            firmData={firmData}
            selectedFirm={selectedFirm}
            setSelectedFirm={setSelectedFirm}
            search={() => fetchData()}
            headers={Headers}
            resetFilters={clearSelections}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </div>
    </>
  );
};

export default FirmContact;
