import { axios } from "..";
import { IContent } from "../interfaces";

export const getFilteredContents = async (
  fields: any,
  filter: any,
  pagination: any,
  sort: any
) => {
  try {
    const queryString = `/content?fields=${JSON.stringify(fields)}
      &filter=${JSON.stringify(filter)}
      &pagination=${JSON.stringify({ ...pagination, pageNumber: pagination.pageNumber - 1 })}
      &sorting=${JSON.stringify(sort)}`;
    const response = await axios.Get(queryString);
    const dataToAdd = response.data;
    if (dataToAdd != null) {
      return dataToAdd;
    }
  } catch (error) {
    console.error("Error al obtener la información de la columna:", error);
  }
};

export const countContents = async (filter: any, count: boolean = true) => {
  try {
    const queryString = `/content?count=${count.toString()}
    &filter=${JSON.stringify(filter)}`;
    const response = await axios.Get(queryString);
    const dataToAdd = response.data;
    if (dataToAdd != null) {
      return dataToAdd;
    }
  } catch (error) {
    console.error("Error al obtener la información de la columna:", error);
  }
};

export const countUniqueMembersAuthors = async (filter = {}) => {
  try {
    const queryString = `/content?uniqueMemberAuthors=true
      &filter=${JSON.stringify(filter)}`;
    const response = await axios.Get(queryString);
    const dataToAdd = response.data;
    if (dataToAdd != null) {
      return dataToAdd;
    }
  } catch (error) {
    console.error("Error al obtener la información de la columna:", error);
  }
};

export const countFirmsWithArticles = async (filter = {}) => {
  try {
    const queryString = `/content?totalFirmWithArticles=true
    &filter=${JSON.stringify(filter)}`;
    const response = await axios.Get(queryString);
    const dataToAdd = response.data;
    if (dataToAdd != null) {
      return dataToAdd;
    }
  } catch (error) {
    console.error("Error al obtener la información de la columna:", error);
  }
};

export const deleteContent = async (id: string) => {
  try {
    const response = await axios.Delete(`/content`, `${id}`);
    return response.status === 200;
  } catch (error) {
    console.error(error);
    throw error
  }
};

export const updateContent = async (content: IContent) => {
  try {
    const response = await axios.Put(`/content`, `${content}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error
  }
};

export const createContent = async (content: IContent) => {
  try {
    const response = await axios.Post(`/content`, content);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error
  }
};