import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IJurisdictionGroup } from "../../../interfaces";

const initialState: IJurisdictionGroup[] = [];

export const jurisdictionGroupsSlice = createSlice({
  name: "jurisdictionGroups",
  initialState,
  reducers: {
    SetJurisdictionGroups: (_, action: PayloadAction<IJurisdictionGroup[]>) => action.payload,
    AddJurisdictionGroup: (
      state,
      action: PayloadAction<IJurisdictionGroup>
    ) => {
      state.push(action.payload);
    },
    UpdateJurisdictionGroup: (state, action: PayloadAction<IJurisdictionGroup>) => {
      const index = state.findIndex((jurisdictionGroup) => jurisdictionGroup.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },    
    DeleteJurisdictionGroup: (state, action: PayloadAction<string>) => {
      return state?.filter(
        (jurisdictionGroup) => jurisdictionGroup.id !== action.payload
      );
    },
  },
});

export const {
  SetJurisdictionGroups,
  AddJurisdictionGroup,
  UpdateJurisdictionGroup,
  DeleteJurisdictionGroup,
} = jurisdictionGroupsSlice.actions;
