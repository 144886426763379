import { useLocation, useNavigate } from "react-router-dom";
import { FormEvent, useEffect, useState } from "react";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import '../../../assets/css/AddCatalogs.css';
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Input from "../../../components/Atomos/Inputs/Input";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import { useLogging } from "../../../Context/LoggingContext";
import { getCurrentTimestamp } from "../../../utils/functions";
import { useDispatch } from "react-redux";
import { useUpdateAndLog } from "../../../hooks/LogActivity/LogActivityUpdates";
import { useCreateAndLog } from "../../../hooks/LogActivity/LogActivityCreates";
import { useDeleteAndLog } from "../../../hooks/LogActivity/LogActivityDeletes";
import { IJurisdictionGroup } from "../../../interfaces";
import { AddJurisdictionGroup as AddJurisdictionGroupAction, DeleteJurisdictionGroup, UpdateJurisdictionGroup } from "../../../redux/catalogs/states";

type JurisdictionGroupError = {
  name: { error: boolean, msj: string }
}

interface iCatalogProps {
  mode: "add" | "edit";
}

const AddJurisdictionGroup: React.FC<iCatalogProps> = (props) => {
  const { state, pathname } = useLocation();
  const { logActivity } = useLogging();
  const navigate = useNavigate();
  const [jurisdictionGroup, setJurisdictionGroup] = useState<IJurisdictionGroup>(state ? state : { id: '', name: '' });
  const [jurisdictionGroupError, setJurisdictionGroupError] = useState<JurisdictionGroupError>({ name: { error: false, msj: '' } });
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [hasError, setError] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');
  const dispatch = useDispatch();
  const update = useUpdateAndLog();
  const create = useCreateAndLog();
  const erase = useDeleteAndLog();

  const Validation = () => {
    let error = false;
    if (!jurisdictionGroup.name) {
      setJurisdictionGroupError({ ...jurisdictionGroupError, name: { error: true, msj: 'This field is required' } })
      error = true;
    }
    else
      setJurisdictionGroupError({ ...jurisdictionGroupError, name: { error: false, msj: '' } })
    setSaveOverrideOpen(error);
    setError(error);
    return !error;
  }
  const handleSetJurisdictionGroup = (inputName: string) => {
    setJurisdictionGroup({ ...jurisdictionGroup, name: inputName })
  }
  const handleOverride = () => {
    setSaveOverrideOpen(!saveOverrideOpen);
  }
  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };

  useEffect(() => {
    if (hasError)
      Validation();
  }
    , [jurisdictionGroup]);

  function handleSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();

    if (buttonClicked === 'saveOverride') {

      if (props.mode === 'add') {
        handleSave(e);
      }
      else {
        handleUpdate(e);
      }
    }

    if (buttonClicked === 'updateButton') {
      if (Validation())
        handleUpdate(e);
    } else if (buttonClicked === 'saveButton') {
      if (Validation())
        handleSave(e);
    }

    if (buttonClicked === 'deleteButton') {
      handleDelete(e);
    }
  }

  const handleSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const _jurisdictionGroup = { ...jurisdictionGroup, createdDate: getCurrentTimestamp() };
      const response = await create.jurisdictionGroup({ jurisdictionGroup: _jurisdictionGroup, pathname});
      dispatch(AddJurisdictionGroupAction(response));
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  }

  const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const _jurisdictionGroup = { ...jurisdictionGroup, createdDate: getCurrentTimestamp() };
      await update.jurisdictionGroup({ jurisdictionGroup: _jurisdictionGroup, pathname, prevState: state});
      dispatch(UpdateJurisdictionGroup(_jurisdictionGroup));
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  }

  const handleDelete = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await erase.jurisdictionGroup({ jurisdictionGroup: state, pathname });
      dispatch(DeleteJurisdictionGroup(state.id));
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <>
      <main className={`mainSection ${saveOverrideOpen ? 'pt-7 md:pt-9 ' : ''}`}>

        <form onSubmit={handleSubmit}>
          <SaveOverride fields={['Jurisdiction Group']} handleButtonClick={setButtonClicked} open={saveOverrideOpen} close={handleOverride} />
          <CatalogTitle title={`${props.mode === 'add' ? 'New Jurisdiction Group' : 'Edit ' + (state?.name ?? 'none')}`} className='text-Default'>
            <GoBack />
          </CatalogTitle>
          <article className='articleSection '>
            <section className="formsectionNTitle ">
              <CatalogSubTitle title={" Jurisdiction Group Information "} className=" text-accent-Default-dark" />
              <div className="formsection flex flex-1 w-full lg:w-1/2 ">
                <Input title={"Name"} value={jurisdictionGroup.name} onChange={(e) => handleSetJurisdictionGroup(e.target.value)} error={jurisdictionGroupError.name.error} errorMsj={jurisdictionGroupError.name.msj} required={true} />
              </div>
            </section>
            <ButtonsCrud
              Catalog="JurisdictionGroup"
              mode={props.mode}
              id={jurisdictionGroup.id ?? ""}
              disabled={jurisdictionGroupError.name.error}
              actionButton={setButtonClicked}
              onDelete={() => handleButtonClick("deleteButton")}
              state={state}
              newState={jurisdictionGroup}
            />
          </article>
        </form>
      </main>
    </>
  );
}
export default AddJurisdictionGroup;