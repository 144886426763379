const Insights = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="3 3 20 20"
      className={props.className}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M8.875 18.3077V14.9038C7.96983 14.2987 7.26442 13.5199 6.75875 12.5673C6.25292 11.6147 6 10.5955 6 9.5095C6 7.70067 6.63108 6.1635 7.89325 4.898C9.15542 3.63267 10.691 3 12.5 3C14.309 3 15.8446 3.63108 17.1068 4.89325C18.3689 6.15542 19 7.691 19 9.5C19 10.5923 18.7471 11.6122 18.2413 12.5595C17.7356 13.507 17.0302 14.2884 16.125 14.9038V18.3077H8.875ZM10.375 16.8077H14.625V14.1135L15.35 13.6C16.027 13.127 16.5546 12.5296 16.9327 11.8077C17.3109 11.0859 17.5 10.3167 17.5 9.5C17.5 8.11667 17.0125 6.9375 16.0375 5.9625C15.0625 4.9875 13.8833 4.5 12.5 4.5C11.1167 4.5 9.9375 4.9875 8.9625 5.9625C7.9875 6.9375 7.5 8.11667 7.5 9.5C7.5 10.3167 7.68908 11.0859 8.06725 11.8077C8.44542 12.5296 8.973 13.127 9.65 13.6L10.375 14.1135V16.8077ZM9.8365 22V20.5H15.1635V22H9.8365Z"/>
    </svg>
  );
}

export default Insights;
