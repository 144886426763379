import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileViewModeBg from "../../assets/images&icons/bg-login.jpg";
import { updatePassword } from "../../Services/Password";

const ModifyFirstTimePassword = () => {
  const {state} = useLocation();
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      await updatePassword(newPassword, confirmPassword, state.userName)
      navigate('/login')
    } catch (error) {
      console.error(error);
    }
  }
  
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newPassword = event.target.value;
      setNewPassword(newPassword);
  
      const isPasswordValid =
        newPassword.trim() === newPassword &&
        newPassword.length >= 8 &&
        /[A-Z]/.test(newPassword) &&
        /\d/.test(newPassword) &&
        /[!@#$%^&*(),.?":{}|<>]/.test(newPassword) &&
        /^[^\s!@#$%^&*(),.?":{}|<>]/.test(newPassword);
  
      setIsPasswordValid(isPasswordValid);
    };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmPasswordTouched(true);
      const password = event.target.value;
      setConfirmPassword(password);
    };

  return (
    <React.Fragment>
      <div
        className="absolute w-full min-h-screen"
        style={{
          backgroundImage: `url(${ProfileViewModeBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat'
        }}
      >
      </div>
      <div className="w-full min-h-screen justify-center items-center flex self-stretch relative">
        <div className="lg:w-[520px] w-8/12">
          <div className={`flex w-full align-middle justify-center gap-28 px-5 py-4 bg-[#9C3325]`}>
            <h2 className={`text-center text-xl font-sans text-white roboto-font`}>CHANGE PASSWORD</h2>
          </div>
          <div className='bg-white w-full py-4'>
            <form className='text-black py-8 flex flex-col gap-4' onSubmit={handleSubmit}>
              <div className='w-full px-5'>
                <input
                  type="password"
                  placeholder='Enter New Password'
                  value={newPassword}
                  onChange={handlePasswordChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <ul className="text-left list-item text-[.75rem] mt-2">
                  <li className={newPassword.length >= 8 ? 'text-green-500' : 'text-feedback-error'}>- Minimum 8 characters</li>
                  <li className={/[A-Z]/.test(newPassword) ? 'text-green-500' : 'text-feedback-error'}>- At least 1 Capital Letter</li>
                  <li className={/\d/.test(newPassword) ? 'text-green-500' : 'text-feedback-error'}>- At least 1 number</li>
                  <li className={/[!@#$%^&*(),.?":{}|<>]/.test(newPassword) ? 'text-green-500' : 'text-feedback-error'}>- At least 1 special character</li>
                  <li className={/^[^\s!@#$%^&*(),.?":{}|<>]/.test(newPassword) ? 'text-green-500' : 'text-feedback-error'}>- Must not start with a space or special character</li>
                </ul>
              </div>
              <div className='w-full px-5'>
                <input
                  type="password"
                  placeholder='Confirm New Password'
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                {confirmPasswordTouched && (
                  newPassword !== '' && newPassword === confirmPassword ? (
                    <span className="text-[.75rem] text-feedback-success">
                      Passwords match!
                    </span>
                  ) : (
                    newPassword !== '' && (
                      <span className="text-[.75rem] text-feedback-error">
                        Passwords don't match
                      </span>
                    )
                  )
                )}
              </div>
              <button
                type="submit"
                disabled={!isPasswordValid || newPassword !== confirmPassword}
                className={`cursor-pointer w-11/12 text-white mx-auto flex text-center align-middle justify-center py-4 inter_font bg-[#BC3323] font-semibold text-[1rem] ${(!isPasswordValid || newPassword !== confirmPassword) ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                Change Password
              </button>
            </form>

          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ModifyFirstTimePassword;
