import "../../../../assets/css/AddCatalogs.css";
import React, { useEffect, useState } from "react";
import CatalogSubTitle from "../../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Vector from "../../../../components/Atomos/Icons/Vector";
import SortArrow from "../../../../components/Atomos/Icons/SortArrow";
import NewButton from "../../../../components/Atomos/Buttons/newButton";
import NoteListFrame from "./NoteListFrame";
import { format, isValid } from "date-fns";
import { useNavigate } from "react-router-dom";
import { getAllFirmNote } from "../../../../Services/FirmNote";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/loaderActions";

interface INotesProps {
  noteList: any;
  stateData: any;
}

const NotesList: React.FC<INotesProps> = ({ stateData }) => {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortedNoteList, setSortedNoteList] = useState<any[]>([]);
  const navigate = useNavigate();
  const [firmNotes, setFirmNotes] = useState<any[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const sortedList = [...firmNotes].sort((a: any, b: any) => {
      const dateA = new Date(a.noteDate);
      const dateB = new Date(b.noteDate);

      if (sortOrder === "asc") {
        return dateA.getTime() - dateB.getTime();
      } else {
        return dateB.getTime() - dateA.getTime();
      }
    });

    setSortedNoteList(sortedList);
  }, [firmNotes, sortOrder]);

  const handleNoteAdd = () => {
    navigate(`/Staff/ManageFirm/GeneralTab/${stateData?.id}/Notes/Create`, {
      state: { ...stateData, selectedTab: "GeneralTab" },
    });
  };

  const handleNoteEdit = (
    fullNote: any,
  ) => {
    navigate(`/Staff/ManageFirm/GeneralTab/${stateData?.id}/Notes/Edit/${fullNote.id}`, {
      state: {
        ...stateData,
        selectedTab: "GeneralTab",
        note: fullNote,
      },
    });
  };

  useEffect(() => {
    fetchFirmNote();
  }, [stateData]);

  const fetchFirmNote = async () => {
    try {
      const data = await getAllFirmNote();
      
      const firmNoteList = data.filter(
        (firmNote: any) => firmNote.firm === stateData?.id
      );
      setFirmNotes(firmNoteList);
    } catch (error) {
      console.error(error)
    }
  };

  return (
    <>
      <div className="flex flex-col gap-10 justify-end">
        <article className="articleSection relative">
          <section className="flex w-full flex-col gap-7">
            <div className="flex flex-row flex-wrap gap-5 md:justify-between">
              <div className="flex flex-col gap-5 lg:justify-between w-full md:w-fit">
                <CatalogSubTitle
                  title={"Manage Notes"}
                  className="text-Default lg:text-[32px] md:text-[28px] text-[26px] not-italic font-medium lg:leading-9 md:leading-[44px] leading-normal"
                />
                <div className="block md:hidden w-full">
                  <NewButton
                    text={`ADD NOTE`}
                    color="accent"
                    type="button"
                    style="filled"
                    size="large"
                    content="text"
                    onClick={handleNoteAdd}
                  />
                </div>
                <div className="flex flex-wrap md:flex-nowrap flex-row gap-3 items-center justify-start">
                  <div>
                    <span className="text-Default not-italic font-semibold lg:leading-5 lg:text-lg text-base leading-[18px]">
                      Sort By
                    </span>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2"
                      height="20"
                      viewBox="0 0 2 20"
                      fill="none"
                    >
                      <path d="M1 0V20" stroke="#EBEBEB" />
                    </svg>
                  </div>
                  <div
                    className="flex items-center gap-3 cursor-pointer"
                    onClick={() =>
                      setSortOrder(sortOrder === "asc" ? "desc" : "asc")
                    }
                  >
                    <span className="text-gray-font not-italic lg:leading-5 font-normal lg:text-lg text-base leading-[18px]">
                      Date
                    </span>
                    <SortArrow
                      className={`flex w-6 h-6 items-center justify-center fill-gray-font ${
                        sortOrder === "desc" && "rotate-180"
                      }`}
                    />
                  </div>
                </div>
              </div>
              <div className="hidden md:block w-full md:w-min">
                <NewButton
                  text={`ADD NOTE`}
                  color="accent"
                  type="button"
                  style="filled"
                  size="large"
                  content="text"
                  onClick={handleNoteAdd}
                />
              </div>
            </div>
            <div className="grid grid-cols-6 gap-7 w-full">
              {sortedNoteList.map((note: any) => (
                <>
                  <div className="grid flex-1 col-span-6">
                    <Vector className="w-full h-0 stroke-gray-50 stroke-1" />
                  </div>
                  <NoteListFrame
                    name={note?.name}
                    date={
                      isValid(new Date(note?.noteDate))
                        ? format(new Date(note?.noteDate), "MM/dd/yyyy")
                        : "Invalid date"
                    }
                    note={note?.note}
                    handleEdit={handleNoteEdit}
                    fullNote={note}
                  />
                </>
              ))}
            </div>
          </section>
        </article>
      </div>
    </>
  );
};

export default NotesList;
