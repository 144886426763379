import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import '../../../assets/css/AddCatalogs.css';
import GoBack from "../../../components/Moleculas/GoBack";
import Input from "../../../components/Atomos/Inputs/Input";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import { useLogging } from "../../../Context/LoggingContext";
import { getCurrentTimestamp } from "../../../utils/functions";

interface DataItem {
  id?: string;
  name?: string;
  createdDate?: number;
}
interface iCatalogProps {
  mode: "add" | "edit";
}
interface IErrorState {
  nameRequired: boolean,
  nameDuplicated: boolean,
  nameErrorMessage?: string,
  nameDuplicatedMessage?: string
}

const initialErrorState: IErrorState = {
  nameRequired: false,
  nameDuplicated: false,
  nameErrorMessage: "",
  nameDuplicatedMessage: '',
};

const AddSchool: React.FC<iCatalogProps> = (props) => {
  const { state } = useLocation();

  const axios = new Axios();
  const navigate = useNavigate();
  const { id } = useParams();
  const { mode } = props;
  const [buttonClicked, setButtonClicked] = useState('saveButton');
  const [errorState, setErrorState] = useState<IErrorState>(initialErrorState);
  const [currentName, setCurrentName] = useState(state?.name ?? '')
  const { logActivity } = useLogging();
  const location = useLocation()

  useEffect(() => {
    if (mode === 'edit' && state) {
      setCurrentName(state?.name)
      setButtonClicked('updateButton')
    }
  }, [mode, state]);

  const handleDelete = async (id: string) => {
    const response = await axios.Delete(`/school`, id);
    if (response.status === 200) {
      logActivity('DELETE_SCHOOL', location.pathname, JSON.stringify(state ? state : {}));
      navigate('/Staff/Catalogs/School')
    } else {
      console.error('Error al eliminar el elemento:', response.data);
    }
  }

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
    handleSubmit()
  };

  const handleSubmit = () => {

    const errors = {
      ...initialErrorState,
      nameRequired: currentName.trim() === '',
      nameErrorMessage: currentName.trim() === '' ? 'Name is required' : ''
    }
    setErrorState(errors)

    const isValid = Object.values(errors).every((error) => !error);
    if (!isValid) {
      return
    } else {
      if (buttonClicked === 'updateButton') {
        handleUpdate();
      } else if (buttonClicked === 'saveButton') {
        handleSave();
      }
    }
  };

  const handleSave = async () => {
    try {
      const addData: DataItem = {
        name: currentName,
        createdDate: getCurrentTimestamp()
      };
      const response = await axios.Post("/school", addData);
      if (response.data.error) {
        const errors = {
          ...initialErrorState,
          nameDuplicated: true
        }
        setErrorState(errors)
        const isValid = Object.values(errors).every((error) => !error)
        if (!isValid) {
          const newError = {
            ...initialErrorState,
            nameDuplicated: true,
            nameDuplicatedMessage: 'This value is already registered in the DB. Please input a different/new one.'
          }
          setErrorState(newError)
        }
        return
      }
      if (response.status === 200) {
        logActivity('CREATE_SCHOOL', location.pathname, JSON.stringify(response.data))
        setTimeout(() => {
          navigate('/Staff/Catalogs/school')
        }, 500);
      }
    } catch (error: any) {
      console.error('Error creating element:', error);
    }
  };

  const handleUpdate = async () => {
    if (state.id) {
      const updatedData: DataItem = {
        id: state.id,
        name: currentName,
      };
      try {
        const response = await axios.Put("/School", updatedData);
        if (response && response.data.error) {
          const errors = {
            ...errorState,
            nameDuplicated: true,
            nameDuplicatedMessage: 'This value is already registered in the DB. Please input a different/new one.'
          }
          setErrorState(errors)
          const isValid = Object.values(errors).every((error) => !error)
          if (!isValid) {
            return
          }
        }
        logActivity('UPDATE_SCHOOL', location.pathname, JSON.stringify({ prevState: JSON.stringify(state), newState: JSON.stringify(response.data) }))
        navigate("/Staff/Catalogs/School");
      } catch (error: any) {
        console.error("Error al actualizar el elemento:", error);
      }
    }
  };

  const inputOnchange = (e: any) => {
    setCurrentName(e.target.value)
    setErrorState(initialErrorState)
  }

  return (
    <>
      <main className='mainSection'>
        <CatalogTitle
          title={`${props.mode === 'add' ? 'New School' : 'Edit ' + (state?.name || 'none' )}`} className="text-Default">
          <GoBack />
        </CatalogTitle>
        <article className='articleSection'>
          <section className="formsectionNTitle">
            <CatalogSubTitle className="text-accent-Default-dark" title="School Information" />
            <Input value={currentName} onChange={inputOnchange} title="Name" name="Name" required error={errorState.nameRequired} errorMsj={errorState.nameErrorMessage} />
            {errorState.nameDuplicated &&
              <span className={`flex flex-wrap items-center text-sm font-medium text-feedback-error`}>
                {errorState.nameDuplicatedMessage}
              </span>
            }
          </section>
          <ButtonsCrud
            Catalog="School"
            mode={props.mode}
            id={id ? id : ""}
            disabled={errorState.nameRequired || errorState.nameDuplicated}
            actionButton={handleButtonClick}
            onDelete={handleDelete}
            state={state}
            newState={{name: currentName, createdDate: state?.createdDate}}
          />
        </article>
      </main>
    </>
  );
}
export default AddSchool;