import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IState } from "../../../interfaces";

const initialState: IState[] = [];

export const statesSlice = createSlice({
  name: "states",
  initialState,
  reducers: {
    SetStates: (_, action: PayloadAction<IState[]>) => action.payload,
    AddState: (state, action: PayloadAction<IState>) => {
      state.push(action.payload);
    },
    UpdateState: (state, action: PayloadAction<IState>) => {
      const index = state.findIndex((state) => state.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },
    DeleteState: (state, action: PayloadAction<string>) => {
      return state?.filter((State) => State.id !== action.payload);
    },
  },
});

export const { SetStates, AddState, UpdateState, DeleteState } =
  statesSlice.actions;
