import React, { useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../redux/loaderActions";
import { LEGACY_STAFF_URL } from "../../Enums/LegacyRedirection";
import { getFirmById } from "../../Services/Firm";
import IFirm from "../../interfaces/models/IFirm";
import { setCurrentFirm } from "../../redux";

const legacyTabs: { [char: string]: string } = {
	dashboard: 'wsg_admin2.asp',
	professionals: 'wsg_firmprofessionals.asp',
	referrals: 'admin_manage_referrals.asp',
	groupparticipants: 'wsg_admin15.asp?action=detailfirm&f=1',
	events: 'manage_events.asp?fromadmin=true',
};

const GroupsPage = () => {
	const { id } = useParams();
	const iframeRef = useRef(null);
	const location = useLocation();
	const dispatch = useDispatch();
	const [token, setToken] = React.useState<string | null>(null);
	
	useEffect(() => {
		const tokenCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('token='))?.substring(6);
		if(tokenCookie) {
			setToken(tokenCookie);
		}
	}, []);

	const getFirmInfo = async (id: string) => {
    const firm:IFirm = await getFirmById(id);
    dispatch(setCurrentFirm(firm));
		return firm;
  };

	useEffect(() => {
		if(!id || !token) return;
		getFirmInfo(id)
			.then(firm => {
				if(!iframeRef.current) return;
				dispatch(showLoader());
				const iframe = iframeRef.current as HTMLIFrameElement;
				const path = location.pathname.toLowerCase();
				const section = path.split('/')[3];
				const legacyPath = new URL(`${LEGACY_STAFF_URL}/${legacyTabs[section.toLocaleLowerCase()]}`);
				legacyPath.searchParams.append('hide-navigation', 'true');
				legacyPath.searchParams.append('pcomp', firm.legacyId as string);

				if(token){
					const encodedToken = encodeURIComponent(token);
					legacyPath.searchParams.append('token', encodedToken);
				}

				iframe.src = legacyPath.toString();
		
				iframe.onload = () => { dispatch(hideLoader()) };
				iframe.onerror = () => { dispatch(hideLoader()) };
			})
	}, [location.pathname, id, token]);

	useEffect(() => {
	}, [id]);

  return (
    <>
      <div className="min-h-screen w-full flex items-center align-middle justify-center">
        <iframe ref={iframeRef} className="min-h-screen w-full" title="Legacy"></iframe>
      </div>
    </>
  );
};

export default GroupsPage;
