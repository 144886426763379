import { useLocation } from "react-router-dom";
import Axios from "../../../utils/axios";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import '../../../assets/css/AddCatalogs.css';
import DropDownList from "../../../components/Atomos/DropDown/Dropdown";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import Input from "../../../components/Atomos/Inputs/Input";
import SwitchToogle from "../../../components/Atomos/Switch-Toogle/Switch";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import { UseJurisdictionGroupList } from "../../../hooks/Catalogs/useJurisdictionGroup";
import { useLogging } from "../../../Context/LoggingContext";
import { getCurrentTimestamp, getNameById } from "../../../utils/functions";
import { useStatesList } from "../../../hooks/Catalogs/useStates";
import { useUpdateAndLog } from "../../../hooks/LogActivity/LogActivityUpdates";
import { useCreateAndLog } from "../../../hooks/LogActivity/LogActivityCreates";
import { useDeleteAndLog } from "../../../hooks/LogActivity/LogActivityDeletes";
import { useDispatch } from "react-redux";
import { AddJurisdiction, DeleteJurisdiction, UpdateJurisdiction } from "../../../redux/catalogs/states";



interface iCatalogProps {
  mode: "add" | "edit";
}

const AddJurisdictionGroup: React.FC<iCatalogProps> = (props) => {
  const axios = new Axios();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const [jurisdiction, setJurisdiction] = useState<any>({
    name: '',
    nickname: '',
    isSecondary: false,
    jurisdictionGroup: '',
    multiType: '',
    country: '',
    state: '',
    substate: '',
    partOfMulti: false,
  });
  const [activeErrors, setActiveErrors] = useState<any>({
    name: { error: false, msj: '' },
    country: { error: false, msj: '' },
    state: { error: false, msj: '' },
    substate: { error: false, msj: '' },
    jurisdictionGroup: { error: false, msj: '' },
    multiType: { error: false, msj: '' },
  });

  const [countryData, setCountryData] = useState<any[]>([]);
  const [stateData, setStateData] = useState<any[]>([]);
  const [substateData, setSubStateData] = useState<any[]>([]);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');
  const multiTypeData = [{ id: "Regional", name: "Regional" }, { id: "Multiple", name: "Multiple" }]
  const { jurisdictionGroupData } = UseJurisdictionGroupList();
  const { statesData } = useStatesList()
  const dispatch = useDispatch();
  const update = useUpdateAndLog();
  const create = useCreateAndLog();
  const erase = useDeleteAndLog();

  useEffect(() => {
    if (countryData.length === 0) {
      const fetchCountry = async () => {
        try {
          var queryString = '/Country?showOnly=true';
          const response = await axios.Get(queryString);
          const data = response.data;

          if (data != null) {
            const selectedCountry = data.map((country: any) => ({
              ...country,
              checked: country ? true : false
            }));
            setCountryData(selectedCountry);

            jurisdiction.country &&
              setJurisdiction((prevState: any) => ({ ...prevState, country: selectedCountry.find((obj: any) => obj.name === jurisdiction.country)?.id }))


          }

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchCountry();
    }

  }, []);

  useEffect(() => {
    const country = countryData.find(country => country.id === jurisdiction.country);
    const stateS = stateData.find(stateD => stateD.id === jurisdiction.state);
    const substate = substateData.find(substate => substate.id === jurisdiction.substate);
    const nameSections = [country?.name, stateS?.name, substate?.name]
      .filter((section) => section !== undefined && section !== '');
    const name = nameSections.join(', ');
    setJurisdiction((prevState: any) => ({ ...prevState, name }));
  }, [jurisdiction.country, jurisdiction.state, jurisdiction.substate, countryData, stateData, substateData]);

  const validateForm = () => {
    let errors = false;
    let errorFields: string[] = [];
    let newActiveErrors = { ...activeErrors };

    if (!jurisdiction.name || jurisdiction.name.trim() === '') {
      newActiveErrors.name.error = true;
      newActiveErrors.name.msj = 'This field is required';
      errorFields.push('name');
      errors = true;
    } else {
      newActiveErrors.name.error = false;
      newActiveErrors.name.msj = '';
    }
    if (!jurisdiction.country || jurisdiction.country.trim() === '') {
      newActiveErrors.country.error = true;
      newActiveErrors.country.msj = 'This field is required';
      errorFields.push('country');
      errors = true;
    } else {
      newActiveErrors.country.error = false;
      newActiveErrors.country.msj = '';
    }
    if ((!jurisdiction.state || jurisdiction.state.trim() === '') && countryData.find(country => country.id === jurisdiction.country)?.hasState) {
      newActiveErrors.state.error = true;
      newActiveErrors.state.msj = 'This field is required';
      errorFields.push('state');
      errors = true;
    } else {
      newActiveErrors.state.error = false;
      newActiveErrors.state.msj = '';
    }
    if (stateData.find(state => state.id === jurisdiction.state)?.hasSubstate && jurisdiction.state && (!jurisdiction.substate || jurisdiction.substate.trim() === '')) {

      newActiveErrors.substate.error = true;
      newActiveErrors.substate.msj = 'This field is required';
      errorFields.push('substate');
      errors = true;
    } else {
      newActiveErrors.substate.error = false;
      newActiveErrors.substate.msj = '';
    }
    if (jurisdiction.partOfMulti && (!jurisdiction.jurisdictionGroup || jurisdiction.jurisdictionGroup.trim() === '')) {
      newActiveErrors.jurisdictionGroup.error = true;
      newActiveErrors.jurisdictionGroup.msj = 'This field is required';
      errorFields.push('jurisdictionGroup');
      errors = true;
    } else {
      newActiveErrors.jurisdictionGroup.error = false;
      newActiveErrors.jurisdictionGroup.msj = '';
    }
    if (jurisdiction.partOfMulti && (!jurisdiction.multiType || jurisdiction.multiType.trim() === '')) {
      newActiveErrors.multiType.error = true;
      newActiveErrors.multiType.msj = 'This field is required';
      errorFields.push('multiType');
      errors = true;
    } else {
      newActiveErrors.multiType.error = false;
      newActiveErrors.multiType.msj = '';
    }

    setErrorFields(errorFields);

    setActiveErrors(newActiveErrors);
    return errors;
  }

  const buildJurisdiction = () => {
    return {
      ...jurisdiction, 
      countryName: getNameById(jurisdiction.country, countryData).join(),
      stateName: getNameById(jurisdiction.state, statesData).join(),
      substateName: getNameById(jurisdiction.substate, substateData).join(),
      jurisdictionGroupName: getNameById(jurisdiction.jurisdictionGroup, jurisdictionGroupData).join(),
    };
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newJurisdiction = buildJurisdiction();

    if (buttonClicked !== 'saveOverride' && validateForm()) return

    if (props.mode === 'add') {
      try {
        const response = await create.jurisdiction({ jurisdiction: {...newJurisdiction, createdDate: getCurrentTimestamp()}, pathname });
        dispatch(AddJurisdiction(response));
        navigate(-1);
      } catch (error: any) {
        if (error.message.includes('Name')) {
          setActiveErrors({ ...activeErrors, name: { error: true, message: 'Name already exists' } });
        } else {
          console.error(error);
        }
      }
    } else {
      try {
        await update.jurisdiction({ jurisdiction: newJurisdiction, pathname, prevState: state });
        dispatch(UpdateJurisdiction(newJurisdiction));
        navigate(-1);
      } catch (error: any) {
        console.error(error);
      }
    }

    if (buttonClicked === 'deleteButton') {
      try {
        await erase.jurisdiction({ jurisdiction: state, pathname });
        dispatch(DeleteJurisdiction(state.id));
        navigate(-1);
      } catch (error) {
        console.error(error);
      }
    }
  }

  const handleCloseSaveOverride = () => {
    setSaveOverrideOpen(!saveOverrideOpen);
  }

  const handlerSetJurisdiction = (field: string, item: any, data: any) => {
    setJurisdiction({ ...jurisdiction, [field]: data.find((obj: any) => obj.id === item.id)?.id });
    if (field === 'country' && jurisdiction.country !== item.id) {
      setJurisdiction((prevState: any) => ({ ...prevState, state: '', substate: '' }));
    } else if (field === 'state' && jurisdiction.state !== item.id) {
      setJurisdiction((prevState: any) => ({ ...prevState, substate: '' }));
    }
  }

  useEffect(() => {


    const fetchStateData = async () => {
      try {
        if (jurisdiction.country) {

          const response = await axios.Get(`/state?&countries=` + JSON.stringify([jurisdiction.country]));
          const data = response.data;
          if (data != null) {

          const newStates = data.filter((item: any) => item.country === jurisdiction.country);
          setStateData(newStates);
            //   jurisdiction.state &&
            //     setJurisdiction((prevState: any) => ({ ...prevState, state: data.find((obj: any) => obj.name === jurisdiction.state)?.id }))
          }
        }
        else {
          setStateData([])
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchStateData();

  }, [jurisdiction.country]);

  useEffect(() => {
    const fetchSubStateData = async () => {
      try {
        if (jurisdiction.state) {
          const params = new URLSearchParams();
          params.append('fields', JSON.stringify(['id', 'name']))
          params.append('filter', JSON.stringify({state: {value: jurisdiction.state, comparisonType: 'EQUAL'}}))

          const response = await axios.Get(`/substate?${params.toString()}`);
          const data = response.data;
          setSubStateData(data);
        }
        else {
          setSubStateData([])
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchSubStateData();
  }, [jurisdiction.state]);

  useEffect(() => {
    if (errorFields.length !== 0) {
      setSaveOverrideOpen(true);
      validateForm();
    }
    if (errorFields.length === 0) {
      setSaveOverrideOpen(false);
    }
  }
    , [jurisdiction.country, jurisdiction.state, jurisdiction.substate, jurisdiction.name, jurisdiction.jurisdictionGroup, jurisdiction.multiType, jurisdiction.partOfMulti]);

  useEffect(() => {
    if (state) {
      setJurisdiction({ ...state });
    }
  }, [state]);

  useEffect(() => {
    let nickname = '';
    if (jurisdiction.country)
      nickname += countryData.find(country => country.id === jurisdiction.country)?.name ?? '';
    if (jurisdiction.state)
      nickname += "-" + stateData.find(state => state.id === jurisdiction.state)?.name || '';
    if (jurisdiction.substate)
      nickname += "-" + substateData.find(substate => substate.id === jurisdiction.substate)?.name || '';
    if (countryData.length > 0 && stateData.length > 0 && substateData.length > 0) { setJurisdiction((prevState: any) => ({ ...prevState, nickname: nickname })); }
  }, [jurisdiction.country, jurisdiction.state, jurisdiction.substate])

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };
  return (
    <>
      <main className={`mainSection ${saveOverrideOpen ? 'pt-7 md:pt-9 ' : ''}`}>
        <form onSubmit={handleSubmit}>
          <SaveOverride fields={errorFields} handleButtonClick={handleButtonClick} open={saveOverrideOpen} close={handleCloseSaveOverride} />
          <CatalogTitle title={`${props.mode === 'add' ? 'New Jurisdiction' : `Edit ${jurisdiction?.name}`}`} className='text-Default'>
            <GoBack />
          </CatalogTitle>
          <article className="articleSection">
            <section className="formsectionNTitle">
              <CatalogSubTitle title=" Catalog List Relationships" className=" text-accent-Default-dark" />
              <div className="formsection grid w-full grid-cols-2 lg:grid-cols-3">

                <div className="col-span-2 md:col-span-1">
                  <DropDownList title="Country" required={true} data={countryData} name={'country'} getid={true}
                    selectedObjst={countryData.find(country => country.id === jurisdiction.country)?.name} seter={(e) => handlerSetJurisdiction('country', e, countryData)} error={activeErrors.country.error} errormsj={activeErrors.country.msj} />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <DropDownList title="State" tooltip={`Please select a Country dived 
                  by States for Jurisdiction purposes.`}
                    disabled={(!jurisdiction.country || !countryData.find(country => country?.id === jurisdiction.country)?.hasState)} getid={true}
                    required={(jurisdiction.country && countryData.find(country => country?.id === jurisdiction.country)?.hasState)}
                    data={stateData} selectedObjst={stateData.find(state => state.id === jurisdiction.state)?.name} seter={(e) => handlerSetJurisdiction('state', e, stateData)} error={activeErrors.state.error} errormsj={activeErrors.state.msj} />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <DropDownList title="Substate" tooltip={`Please select a State dived 
                  by SubStates for Jurisdiction purposes.`}
                    disabled={(!jurisdiction.state || !stateData.find(state => state?.id === jurisdiction.state)?.hasSubstate)} getid={true}
                    required={(jurisdiction.state && stateData.find(state => state?.id === jurisdiction.state)?.hasSubstate)}
                    data={substateData} selectedObjst={substateData.find(substate => substate.id === jurisdiction.substate)?.name} seter={(e) => handlerSetJurisdiction('substate', e, substateData)} error={activeErrors.substate.error} errormsj={activeErrors.substate.msj} />
                </div>
              </div>
            </section>
            <section className="formsectionNTitle">
              <CatalogSubTitle title="Jurisdiction Information" className=" text-accent-Default-dark" />
              <div className="formsection grid w-full grid-cols-2 ">
                <div className="col-span-2 md:col-span-1">
                  <Input
                    title="Name"
                    name="name"
                    tooltip="This field will be populated with the Geography selections above."
                    errorMsj={activeErrors.name.msj}
                    error={activeErrors.name.error}
                    value={jurisdiction.name}
                    onChange={(e) => setJurisdiction((prevState: any) => ({ ...prevState, name: e.target.value }))}
                    disabled={true}
                    required={true}
                  />
                </div>
                <div className="formsection col-span-2 flex justify-around md:justify-start ">
                  <SwitchToogle
                    className=" flex-col-reverse md:flex-row items-center md:items-start"
                    small
                    title="Is Part Of Multi?"
                    seter={(e) => setJurisdiction((prev: any) => ({ ...prev, partOfMulti: e }))}
                    checked={jurisdiction.partOfMulti}
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <DropDownList title="MultiType" required={
                    jurisdiction.partOfMulti}
                    disabled={!jurisdiction.partOfMulti}
                    selectedObjst={jurisdiction.multiType} seter={(e) => handlerSetJurisdiction('multiType', { id: e, name: e }, multiTypeData)}
                    data={multiTypeData} error={activeErrors.multiType.error} errormsj={activeErrors.multiType.msj} />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <DropDownList title="Jurisdiction Group" required={jurisdiction.partOfMulti} data={jurisdictionGroupData} getid={true}
                    selectedObjst={jurisdictionGroupData.find((JurisdictionGroup: any) => JurisdictionGroup.id === jurisdiction.jurisdictionGroup)?.name} seter={(e) => handlerSetJurisdiction('jurisdictionGroup', e, jurisdictionGroupData)}
                    error={activeErrors.jurisdictionGroup.error} errormsj={activeErrors.jurisdictionGroup.msj} />
                </div>
              </div>
            </section>
            <ButtonsCrud
              Catalog="Jurisdiction"
              mode={props.mode}
              id={jurisdiction.id ?? ""}
              disabled={errorFields.length > 0}
              actionButton={handleButtonClick}
              onDelete={() => handleButtonClick("deleteButton")}
              state={state}
              newState={buildJurisdiction()}
            />
          </article>
        </form>
      </main>
    </>
  );
}

export default AddJurisdictionGroup;
