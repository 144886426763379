export enum ContactType {
  primaryContact = 'primaryContact',
  secondaryContact = 'secondaryContact',
  marketingContact = 'marketingContact',
  itContact = 'itContact',
  accountingContact = 'accountingContact',
  socialMediaContact = 'socialMediaContact',
  hrContact = 'hrContact',
  additionalContact = 'additionalContact', 
}

export enum ContactTypeString {
  primaryContact = 'Primary Contact',
  secondaryContact = 'Secondary Contact',
  marketingContact = 'Marketing Contact',
  itContact = 'IT Contact',
  accountingContact = 'Accounting Contact',
  socialMediaContact = 'Social Media Contact',
  hrContact = 'HR Contact',
  additionalContact = 'Additional Contact',
}