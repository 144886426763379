const Tools = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="2 2 20 20"
      className={props.className}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M19.0153 20.0152L13.502 14.5017L15.0825 12.921L20.596 18.4345L19.0153 20.0152ZM5.16725 20.0152L3.5865 18.4345L10.5155 11.5057L8.18075 9.1805L7.55775 9.80375L6.44625 8.6825V10.6075L5.823 11.2305L3 8.4075L3.623 7.7845H5.548L4.44225 6.68825L7.3 3.83075C7.582 3.54858 7.88742 3.34217 8.21625 3.2115C8.54508 3.08067 8.88708 3.01525 9.24225 3.01525C9.59742 3.01525 9.93942 3.08067 10.2682 3.2115C10.5971 3.34217 10.9025 3.54858 11.1845 3.83075L9.2115 5.80375L10.3845 6.97675L9.7615 7.59975L12.096 9.925L14.5 7.521C14.3885 7.3185 14.3071 7.10925 14.2558 6.89325C14.2044 6.67708 14.1788 6.44983 14.1788 6.2115C14.1788 5.3115 14.489 4.55125 15.1095 3.93075C15.73 3.31025 16.4903 3 17.3903 3C17.6019 3 17.8042 3.01858 17.997 3.05575C18.19 3.09292 18.3839 3.15508 18.5788 3.24225L16.3058 5.51525L18.0865 7.296L20.3595 5.023C20.457 5.21783 20.5218 5.41175 20.5538 5.60475C20.5858 5.79758 20.6018 5.99983 20.6018 6.2115C20.6018 7.1115 20.2915 7.87175 19.671 8.49225C19.0505 9.11275 18.2903 9.423 17.3903 9.423C17.1519 9.423 16.9247 9.39992 16.7085 9.35375C16.4925 9.30758 16.2833 9.22358 16.0808 9.10175L5.16725 20.0152Z"/>
    </svg>
  );
}

export default Tools;
