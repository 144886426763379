import React, { useEffect, useState } from 'react';
import useDataLoader2 from '../../hooks/useDataLoader2';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import BaseFilter from '../../components/Catalogs/Filters/BaseFilter/BaseFilter';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import { formatTimeSpanToString } from '../../utils/functions';
import { defaultPageSize } from '../../utils/const';

const Language: React.FC = ({ ...props }) => {
  const Headers: string[] = ['name', 'createdDate'];
  const [nameFilter, setNameFilter] = useState('');
  const [tableInstance, setTableInstance] = useState<any>();
  const { generateColumns } = useColumnConfig(Headers);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'name', 
    'createdDate'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/language',
    defaultPageSize,
    fields
  });

  const columns = generateColumns([
    { id: 'count', header: '#', cell: (props: any) => <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>, enableSorting: false, size: 50 },
    { id: 'name', header: 'Name', cell: (props: any) => props.getValue() },
    {
      id: 'createdDate', header: "Created Date",
      cell: (props: any) => {
        const value = props.getValue();
        return (
          <p>
            {value && formatTimeSpanToString(value) }
          </p>
        );
      },
    },
    {
      id: 'edit', header: 'Edit', cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={props.row.original}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ), enableSorting: false
    },
  ]);

  const SubmitFilters = () => {
    setFilter([
      { field: 'name', value: nameFilter },
    ]);
  };
  const handleNameFilterChange = (newNameFilter: string) => {
    setNameFilter(newNameFilter);
  };

  const clearSelections = () => {
    setNameFilter('');
    fetchData(true);
  };

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  useEffect(() => {
    setFilter({
      name: {
        value: nameFilter,
        comparisonType: 'CONTAINS'
      }
    })
  },[nameFilter]);

  return (
    <>
      <main className='mainSection'>
        <CatalogTableC
          title={`Language Catalog`}
          catalogName={'Language'}
          route={'Language'}
          isfiltered={true}
          data={data}
          headers={Headers}
          totalCount={totalCount}
        >
          <BaseFilter
            key={0}
            title='By Name'
            search={SubmitFilters}
            headers={Headers}
            onNameFilterChange={handleNameFilterChange}
            nameFilter={nameFilter}
            resetFilters={clearSelections}
            tableInstance={tableInstance}
          />

          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </main>
    </>
  );
};

export default Language;
