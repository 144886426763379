const New = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="2 2 20 20"
      className={props.className}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M9.13825 21.7305L7.35375 18.723L3.96525 17.9805L4.296 14.4922L2 11.8652L4.296 9.23825L3.96525 5.75L7.35375 5.0075L9.13825 2L12.3268 3.35375L15.5153 2L17.2998 5.0075L20.6883 5.75L20.3575 9.23825L22.6535 11.8652L20.3575 14.4922L20.6883 17.9805L17.2998 18.723L15.5153 21.7305L12.3268 20.3768L9.13825 21.7305ZM9.77675 19.8153L12.3268 18.7345L14.9075 19.8153L16.3268 17.4152L19.0768 16.7845L18.8268 13.9653L20.6768 11.8652L18.8268 9.7345L19.0768 6.91525L16.3268 6.31525L14.8768 3.91525L12.3268 4.996L9.746 3.91525L8.32675 6.31525L5.57675 6.91525L5.82675 9.7345L3.97675 11.8652L5.82675 13.9653L5.57675 16.8152L8.32675 17.4152L9.77675 19.8153ZM11.2768 15.069L16.5805 9.76525L15.5268 8.68075L11.2768 12.9307L9.12675 10.8115L8.073 11.8652L11.2768 15.069Z"/>
    </svg>
  );
}

export default New;
