import { IManageFirm } from "../../interfaces/models/IManageFirm";

const getInitialFirmState = () => {
  try {
    const storedFirm = localStorage.getItem("selectedFirm");
    return storedFirm ? JSON.parse(storedFirm) : {};
  } catch (error) {
    console.error("Error parsing selectedFirm from localStorage", error);
    return {}; 
  }
};


export const ManageFirmEmpty: IManageFirm = {

    selectedTab: Number(localStorage.getItem("selectedTab")) || 0,
    showMenu: false,
    selectedFirm: getInitialFirmState()
};