import "../../../../assets/css/AddCatalogs.css";
import React, { useEffect, useState } from "react";
import BackgroundComponent from "../../../../components/Layout/BackgroundProfile/BackgroundProfile";
import ManageFirmBackground from "../../../../assets/images&icons/bg-manageFirm.png";
import CurrentPage from "../../../../components/Layout/CurrentPageTab/CurrentPage";
import CatalogTitle from "../../../../components/Moleculas/Catalog Text/CatalogTitle";
import GoBack from "../../../../components/Moleculas/GoBack";
import { useLocation, useNavigate } from "react-router-dom";
import CatalogSubTitle from "../../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import { Tab } from "@headlessui/react";
import SaveOverride from "../../../../components/Catalogs/SaveOverride";
import NewButton from "../../../../components/Atomos/Buttons/newButton";
import Plus2 from "../../../../components/Atomos/Icons/Plus";
import EditOfficeManage from "./EditOfficeManage";
import { getMemberMainContact } from "../../../../Services/MemberMainContact";
import { useDispatch, useSelector } from "react-redux";
import { Appstore } from "../../../../redux";
import { getOffice } from "../../../../Services/Office";
import { hideLoader, showLoader } from "../../../../redux/loaderActions";
const WSGDots = require("../../../../assets/images&icons/WSGDots.svg");

const ManageMainContact: React.FC = () => {
  const navigate = useNavigate();
  const [buttonClicked, setButtonClicked] = useState("");
  const [memberMainContact, setMemberMainContact] = useState<any>([]);

  const [selectedTab, setSelectedTab] = useState("");

  const manageFirm = useSelector((state: Appstore) => state.manageFirm);

  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [errorJurisdiction, setErrorJurisdictions] = useState<string[]>([]);
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [officesIds, setOfficeIds] = useState<any[]>([]);
  const [offices, setOffices] = useState<any[]>([]);
  const dispatch = useDispatch();

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };

  const fetchMemberMainContact = async () => {
    const data = await getMemberMainContact();
    const memberMainContactList = data.filter(
      (memberMainContact: any) => memberMainContact.firm === manageFirm.selectedFirm?.id
    );
    setMemberMainContact(memberMainContactList[0]);
  };    

  const fetchOffices = async () => {
    const data = await getOffice();
    setOffices(data);
  };

  useEffect(() => {
    fetchData();
  }, [manageFirm.selectedFirm]);

  const fetchData = async () => {
    try {
      await Promise.all([
        fetchMemberMainContact(),
        fetchOffices(),
      ]);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setSelectedTab(memberMainContact?.offices?.[0]?.office);
  }, [memberMainContact]);

  useEffect(() => {
    if (errorFields.length > 0) {
      setSaveOverrideOpen(true);
    }
    if (errorFields.length === 0 && errorJurisdiction.length === 0) {
      setSaveOverrideOpen(false);
    }
  }, [errorFields]);

  useEffect(() => {
    if (errorJurisdiction.length > 0) {
      setSaveOverrideOpen(true);
    }
    if (errorJurisdiction.length === 0 && errorFields.length === 0) {
      setSaveOverrideOpen(false);
    }
  }, [errorJurisdiction]);

  const handleTabValue = (jurisdiction: any) => {
    setSelectedTab(jurisdiction);
  };

  const handleCloseSaveOverride = () => {
    setSaveOverrideOpen(!saveOverrideOpen);
  };

  useEffect(() => {
    const firmOffice = offices?.filter(
      (office: any) => office.firm === manageFirm?.selectedFirm?.id
    );
    setOfficeIds(firmOffice);
  }, [offices, manageFirm.selectedFirm]);

  return (
    <>
      <BackgroundComponent image={ManageFirmBackground} />

      <div className=" mainSection">
        <div className="flex flex-col pt-7 gap-5 md:gap-6 lg:gap-7 md:pt-8 lg:pt-10 pb-5">
          <SaveOverride
            fields={[...errorFields, ...errorJurisdiction]}
            handleButtonClick={handleButtonClick}
            open={saveOverrideOpen}
            close={handleCloseSaveOverride}
            noSaveOverride={true}
          />
          <div className="flex gap-4 justify-between">
            <CatalogTitle
              title={`Edit Member Main Contacts`}
              className="text-white !p-0 lg:text-[52px] md:text-[44px] text-[42px]"
            />
            <div
              className="md:min-w-[20rem] min-h[4.5rem] overflow-hidden flex-1 hidden relative md:flex"
              style={{
                backgroundImage: `url(${WSGDots.default})`,
              }}
            ></div>
            <div className="text-white relative">
              <GoBack className="!text-white !fill-white" />
            </div>
          </div>
          <hr className="flex h-0 justify-end items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage
              prevPage="Manage Firm > General"
              currentPage={`Member Main Contacts`}
            />
          </div>
        </div>
        <div className="relative flex flex-col gap-10 justify-end">
          <article className="articleSection">
            <section className="formsectionNTitle">
              <div className="flex flex-col md:flex-row w-full justify-between gap-2">
                <CatalogSubTitle
                  title={"Member Main Contacts"}
                  className="text-Default text-[26px] md:text-[34px] lg:text-[42px] not-italic font-medium lg:leading-[44px] md:leading-[48px] leading-normal"
                />
              </div>
            </section>
            <div className="w-full">
              <div className="flex gap-6 flex-wrap">
                {(memberMainContact?.offices?.length === 0 || !memberMainContact) && (
                  <div className="flex gap-2 flex-wrap md:justify-end lg:flex-nowrap self-stretch lg:justify-between w-full">
                    <span className=" text-gray-font text-lg leading-8 font-normal not-italic">
                      {"There are currently no Offices created for " +
                        manageFirm?.selectedFirm?.firmName +
                        ". Please Create an Office to continue the Member Main Contacts setup."}
                    </span>
                    <div className="flex w-full md:w-min lg:justify-end">
                      <NewButton
                        text={`NEW OFFICE`}
                        color="accent"
                        type="button"
                        style="filled"
                        size="large"
                        content="textIcon"
                        onClick={() =>
                          navigate(`/Staff/Catalogs/Office/Create`)
                        }
                        icon={
                          <Plus2 className="w-5 h-5 shrink-0 relative overflow-visible" />
                        }
                      />
                    </div>
                  </div>
                )}
                <div className="flex gap-1 overflow-x-auto scrollbar-hidden max-w-screen-xl">
                  <Tab.Group>
                    <Tab.List className="flex gap-1">
                      {memberMainContact?.offices?.map((office: any, index: number) => {
                        return (
                          <Tab
                            key={index}
                            onClick={() => handleTabValue(office.office)}
                            className={`justify-center w-auto items-center min-w-[131px] md:min-w-[150px] lg:min-w-[168.5px] bg-[#E5E3E4] max-h-[190px] text-gray-font ui-selected:text-neutral p-3 pr-2 ui-selected:bg-accent-Default-dark border-none rounded-none focus:border-none whitespace-nowrap`}
                          >
                            <span
                              className={`not-italic text-xs md:text-sm lg:text-base leading-8 font-normal`}
                            >
                              {offices?.find(
                                (off: any) => off.id === office.office
                              )?.name ?? ""}
                            </span>
                          </Tab>
                        );
                      })}
                    </Tab.List>
                  </Tab.Group>
                </div>
              </div>

              <EditOfficeManage
                officeId={selectedTab}
                officeName={
                  offices?.find((jur: any) => jur.id === selectedTab)?.name
                }
                officeObjs={officesIds ? officesIds : []}
                setErrorFields={setErrorFields}
                setErrorJurisdiction={setErrorJurisdictions}
                buttonClicked={buttonClicked}
                memberMainContact={memberMainContact}
              />
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default ManageMainContact;
