import { useEffect, useState } from "react";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from "../../assets/images&icons/profileViewMode.jpeg";
import Eye from "../../components/Atomos/Icons/ViewMode/Eye";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import Axios from "../../utils/axios";
import ProfileAndSocialLinks from "./ProfileAndSocials";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProfileCardNew from "../../components/ViewMode/WsgProfileCard";
import SignificantAccomplishmentsCard from "../../components/ViewMode/SignificantAccomplishmentsCard";
import FirmCard from "../../components/ViewMode/FirmCard";
import ProfessionalCard from "../../components/ViewMode/ProfessionalCard";
import ExpertiseCard from "../../components/ViewMode/ExpretiseCard";
import ProfileBanner from "../../components/ViewMode/ProfileBanner";
import AreasOfPracticeCard from "../../components/ViewMode/AreasOfPracticeCard";
import { IPerson } from "../../interfaces/models/IPerson";
import Disclosure from "../../components/Moleculas/Disclosure/Disclosure";
import { useStaffMode } from "../../hooks/StaffModeProvider";
import { UseFirmList } from "../../hooks/Catalogs/useFirm";
import OverrideVCF from "./OverrideVCF";
import { hideLoader, showLoader } from "../../redux/loaderActions";
import { useDispatch } from "react-redux";
import { getAllPracticeIndustries } from "../../Services/PracticeIndustry"
import { IContent } from "../../interfaces";
import PersonArticles from "../../components/ViewMode/PersonArticles";
import NewButton from "../../components/Atomos/Buttons/newButton";
import GoBack from "../../components/Moleculas/GoBack";
import PencilEdit from "../../components/Atomos/Icons/PencilEdit";

const WSGDots = require("../../assets/images&icons/WSGDots.svg");
const axios = new Axios();
interface ViewModeProps {
  editmode?: boolean;
}

const ViewMode: React.FC<ViewModeProps> = ({editmode}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { userId, customPath, generatedId } = useParams();
  const [currentPerson, setCurrentPerson] = useState<IPerson>()
  const [personLanguages, setCurrentPersonLanguages] = useState<any[]>()
  const [personAOPS, setPersonAOPS] = useState<any[]>()
  const [personFirm, setPersonFirm] = useState<any>()
  const [formattedUrl, setFormattedUrl] = useState('')
  const [personMainOffice, setPersonMainOffice] = useState<any>()
  const [officeCountry, setOfficeCountry] = useState<any>()
  const [isEditable, setIsEditable] = useState<boolean>(false)
  const [isPublicPreview, setIsPublicPreview] = useState<boolean>(false)
  const currentLoggedUser = sessionStorage.getItem('token_decoded')
  const [personBAS, setPersonBAS] = useState<any[]>([]);
  const [isProfileCardOpen, setIsProfileCardOpen] = useState(false);
  const [isArticlesCardOpen, setIsArticlesCardOpen] = useState(true);
  const [isBlogCardOpen, setIsBlogCardOpen] = useState(true);
  const [isAOPCardOpen, setIsAOPCardOpen] = useState(false);
  const [isProfessionalCareerCardOpen, setIsProfessionalCareerCardOpen] =
    useState(false);
  const [personMatchedJurisdictionGroups, setPersonMatchedJurisdictionGroups] =
    useState<any[]>([]);
  const [practiceIndustriesMatched, setPracticeIndustriesMatched] = useState<
    any[]
  >([]);
  const [viewMoreActive, setViewMoreActive] = useState(false);
  const [educations, setEducations] = useState<any[]>([]);
  const [profileEducation, setProfileEducation] = useState<any[]>([]);
  const { isStaffMode } = useStaffMode();
  const [primaryLocations, setPrimaryLocations] = useState<boolean>(false);
  const [urlType, setUrlType] = useState("");
  const { firmData } = UseFirmList();
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const [isSaveOverrideOpen, setIsSaveOverrideOpen] = useState<boolean>(false);
  const [personArticles, setPersonArticles] = useState<IContent[]>([] as IContent[])


  useEffect(() => {
    if (location.pathname.includes("/Staff/")) {
      setUrlType("staff");
    } else {
      setUrlType("member");
    }
  }, [location]);

  const formatPhoneNumber = (phoneNumber: string, codePhoneNumber: string) => {
    if (!phoneNumber || !codePhoneNumber) return "";
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
    const cleanedCodePhoneNumber = codePhoneNumber.replace(/\D/g, "");
    return `+${cleanedCodePhoneNumber}${cleanedPhoneNumber}`;
  };

  const generateVCardOverride = () => {
    if (currentPerson) {
      const formattedPhoneNumber = formatPhoneNumber(
        personMainOffice?.digPhoneNumber || "",
        personMainOffice?.codePhoneNumber || ""
      );

      const address = personMainOffice
        ? `${personMainOffice.address || ""};${personMainOffice.city || ""};${personMainOffice.state || ""};;${personMainOffice.country || ""}`
        : "";

      const vCardData = `
  BEGIN:VCARD
  VERSION:3.0
  N:${currentPerson.lastName};${currentPerson.name};${currentPerson.middleInitial || ""};${currentPerson.honorary || ""};${currentPerson.suffix || ""}
  ORG:${personFirm?.firmName || "No firm available"}
  TITLE:${currentPerson.title || "No title available"}
  FN:${currentPerson.displayName || `${currentPerson.name} ${currentPerson.middleInitial || ""} ${currentPerson.lastName}`}
  TEL;TYPE=WORK,VOICE:${formattedPhoneNumber ? formattedPhoneNumber : ""}
  EMAIL:${currentPerson.firmEmail || "No email available"}
  ADR;TYPE=WORK:;;${personMainOffice?.address || ""};${personMainOffice?.city || ""};${personMainOffice?.state || ""};;${personMainOffice?.country || ""}
  END:VCARD
      `.trim();

      const blob = new Blob([vCardData], { type: "text/vcard" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${currentPerson.displayName || currentPerson.name}.vcf`;
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  useEffect(() => {
    if (isEditable && !isPublicPreview && personArticles.length === 0) {
      setIsArticlesCardOpen(false);
    } else if (personArticles && personArticles.length > 0) {
      setIsArticlesCardOpen(true);
    }
  }, [personArticles, currentPerson, isEditable, isPublicPreview]);

  const handleSaveOverrideButtonClick = (buttonClicked: string) => {
    if (buttonClicked === "saveOverride") {
      generateVCardOverride();
      closeSaveOverride();
    }
  };

  const getEducations = async () => {
    try {
      const res = await axios.Get("/education");
      if (res && res.data) {
        setEducations(res.data);
      }
    } catch (error) {}
  };

  const getAopPracticeIndustry = async () => {
    try {
      const response = await getAllPracticeIndustries();

      if (response && personAOPS && personAOPS?.length > 0) {
        const practiceIndustries = response;
        const matchedPracticeIndustries: any[] = [];
        personAOPS.forEach((areaOfPractice: any) => {
          const matchedPractices = practiceIndustries?.filter((practice: any) =>
            practice.areasOfPractice?.some(
              (aop: any) => aop.id === areaOfPractice.id
            )
          );
          matchedPractices.forEach((practice: any) => {
            if (
              !matchedPracticeIndustries?.some(
                (matched: any) => matched.id === practice.id
              )
            ) {
              matchedPracticeIndustries.push(practice);
              setPracticeIndustriesMatched(matchedPracticeIndustries);
            }
          });
        });
      }
    } catch (error) {
      console.error("Error while fetching practice industries:", error);
    }
  };

  useEffect(() => {
    const hasValidAOPs =
      personAOPS &&
      personAOPS?.length > 0 &&
      personAOPS?.some((aop) => aop != null);
    if (hasValidAOPs) {
      getAopPracticeIndustry();
    }
    setIsAOPCardOpen(!!hasValidAOPs);
  }, [personAOPS]);

  useEffect(() => {
    if (isEditable && !isPublicPreview && personArticles.length === 0) {
      setIsArticlesCardOpen(false);
    } else if (personArticles && personArticles.length > 0) {
      setIsArticlesCardOpen(true);
    }
  }, [personArticles, currentPerson, isEditable, isPublicPreview]);



  useEffect(() => {
    if (currentLoggedUser && currentPerson) {
      const jsonUser = JSON.parse(currentLoggedUser);
      const currentUserId = jsonUser.oid;
      const personUserId = currentPerson.userId;
      setIsEditable(currentUserId === personUserId);
    }
  }, [userId, currentPerson, currentLoggedUser]); 

  async function getUserId(_persons: IPerson[]) {
    if (userId) {
      const currentPerson = _persons?.find((person: IPerson) => person.userId === userId);
      if (currentPerson) {
        setCurrentPerson(currentPerson);
        const baseUrl = isStaffMode ? "/staff/" : "/"
        const path = `${baseUrl}profile/attorney/${currentPerson.name?.trim()}-${currentPerson.lastName?.trim()}/${currentPerson.generatedId}`;
        setFormattedUrl(path.toLocaleLowerCase());
      }
      return currentPerson
    } else if (customPath && generatedId) {
      const currentPerson = _persons?.find((person: IPerson) => {
        const customPathFromUser = `${person.name?.trim()}-${person.lastName?.trim()}/${person.generatedId}`;
        const customPathFromLocation = `${customPath}/${generatedId}`;
        if (
          customPathFromUser.toLocaleLowerCase() ===
          customPathFromLocation.toLocaleLowerCase()
        ) {
          return person;
        }
      });
      setCurrentPerson(currentPerson);
      return currentPerson
    } else if (generatedId) {
      const currentPerson = _persons?.find((person: IPerson) => person.generatedId === Number(generatedId));
      if (currentPerson) {
        setCurrentPerson(currentPerson);
        return currentPerson
      }
    }
  }

  const getPersons = async () => {
    try {
			if(userId) {
				const response = await axios.Get(`/person?filter={"userId":{"value":"${userId}", "comparisonType":"EQUAL"}}`);
				return response?.data
			}
      const response = await axios.Get(`/person?filter={"generatedId":{"value":${generatedId}, "comparisonType":"EQUAL"}}`);
      return response?.data
    } catch (error) {
      console.error(error)
    }
  }

  async function getUserLanguages() {
    try {
      const response = await axios.Get(`/language`);
      if (response) {
        const userLanguagesDetails = response.data?.filter((language: any) =>
          currentPerson?.languages?.includes(language.id)
        );
        setCurrentPersonLanguages(userLanguagesDetails);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getUserAreasOfPractice(userAOPs: any[]) {
    try {
      const response = await axios.Get(`/areaOfPractice?filter={"id":{"value":[${userAOPs.map(x => `"${x.id}"`)}], "comparisonType":"EQUAL"}}`);
      if (response && response.data) {
        const filteredAOPs = response.data.filter((aop: any) =>
          userAOPs?.some((userAop) => userAop.id === aop.id)
        );
        setPersonAOPS(filteredAOPs);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [isWSGFirm, setIsWSGFirm] = useState<boolean>(false);
  const [wsgOffice, setWsgOffice] = useState<any>();

  const getWsgOfficeData = async (wsgFirmId: string) => {
    try {
      if (wsgFirmId) {
        const response = await axios.Get(`/office`);
        if (response && response.data) {
          const filteredWsgOffice = response.data.filter(
            (office: any) => office.firmId === wsgFirmId
          );
          setWsgOffice(filteredWsgOffice[0]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function getUserFirm(_currentPerson: any) {
    try {
      if (!_currentPerson) return
      const currentPersonFirm = firmData.filter(
          (firm: any) => firm.id === _currentPerson.firm
        );
        if (currentPersonFirm[0]?.id === "ee153da4-bbf0-4c29-80cb-29a05905ae2c") {
          await getWsgOfficeData(currentPersonFirm[0]?.id);
          setIsWSGFirm(true);
        }
        setPersonFirm(currentPersonFirm[0]);
    } catch (error) {
      console.error(error);
    }
  }

  async function getUserMainOffice() {
    if (currentPerson && currentPerson.mainOffice) {
      try {
        const response = await axios.Get(`/office`, currentPerson.mainOffice);
        if (response && response.data) {
          setPersonMainOffice(response.data[0]);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function getPersonsOfficesCountry() {
    if (personMainOffice) {
      try {
        const response = await axios.Get(
          `/country?id=${personMainOffice.country}`
        );
        setOfficeCountry(response.data[0]);
      } catch (error) {}
    }
  }

  async function getJurisdictionGroupByFirm() {
    if (personFirm) {
      try {
        const jurisdictionsGroup = personFirm.jurisdictionGroup
          ? personFirm.jurisdictionGroup
          : null;
        if (!jurisdictionsGroup && personFirm.membershipType === "Primary") {
          setPrimaryLocations(true);
        }
        const jresponse = await axios.Get("/jurisdictionGroup");
        let personJurisdictionGroup = jresponse.data.find(
          (jg: any) => jg.name === jurisdictionsGroup
        );
        let personJName = personJurisdictionGroup.name;
        if (personJurisdictionGroup) {
          try {
            const response = await axios.Get("/jurisdiction");
            const allJurisdictions = response.data;
            const matchedJurisdictions = allJurisdictions.filter(
              (jurisdiction: any) => {
                return jurisdiction.jurisdictionGroup === personJName;
              }
            );
            setPersonMatchedJurisdictionGroups(matchedJurisdictions);
          } catch (error) {
            console.error(error);
          }
        } else {
          console.log(
            "Jurisdiction group not found for personFirm:",
            jurisdictionsGroup
          );
        }
      } catch (error) {}
    }
  }

  const handleBooleanFromChild = (value: any) => {
    if (value) {
      setViewMoreActive(true);
    }
  };

  const fetchPersonEducation = async () => {
    try {
      const response = await Promise.all([getPersons(), getEducations()]);
      if (response[0].length) {
        const _currentPerson = await getUserId(response[0])
        if (_currentPerson) await fetchData(_currentPerson)
      }
    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    fetchPersonEducation();
    setIsSaveOverrideOpen(false);
  }, []);

  useEffect(() => {
    if (personMainOffice) {
      getPersonsOfficesCountry();
    }
  }, [personMainOffice]);

  useEffect(() => {
    getJurisdictionGroupByFirm();
  }, [personFirm]);

  const fetchData = async (_currentPerson: any) => {
    if (!_currentPerson) return
		setCurrentPerson(_currentPerson)
		await Promise.all([
			getUserLanguages(),
			getUserFirm(_currentPerson),
			getUserAreasOfPractice(_currentPerson.areasOfPractice),
			getUserMainOffice(),
			getContentData(_currentPerson)
		])
  }

  useEffect(() => {
    if (formattedUrl !== "") {
      navigate(formattedUrl);
    }
    setFormattedUrl("");
  }, [formattedUrl]);

  useEffect(() => {
    if (educations && educations?.length > 0 && currentPerson) {
      const educationPerson = educations?.filter(
        (education: any) => education?.person?.id === currentPerson.id
      );
      if (educationPerson) {
        setProfileEducation(educationPerson);
      }
    }
  }, [educations, currentPerson]);

  const shouldShowDisclosure = (
    person: any,
    fields: any,
    isEditable: boolean,
    isPublicPreview: boolean
  ) => {
    if (!person || !fields) return false;
    if (isEditable && !isPublicPreview) return true;
    return fields.some((field: any) => {
      if (person.hasOwnProperty(field)) {
        const value = person[field];
        if (Array.isArray(value)) {
          return value.some(
            (item) => typeof item === "string" && item.trim() !== ""
          );
        } else {
          return typeof value === "string" && value.trim() !== "";
        }
      }
      return false;
    });
  };

  useEffect(() => {
    const fetchBarAdmissions = async () => {
      const promises = currentPerson?.barAdmissions?.map((id: string) =>
        axios
          .Get(`/barAdmission`, `${id}`)
          .then((response: any) => response.data[0])
      );
      if (promises) {
        const results = await Promise.all(promises);
        setPersonBAS(results);
      }
    };

    fetchBarAdmissions();
  }, [currentPerson?.barAdmissions]);

  const checkDataAndSetVisibility = (person: IPerson) => {
    if (person) {
      if (
        person?.bio === "" &&
        person?.languages?.length === 0 &&
        profileEducation &&
        profileEducation?.length === 0 &&
        personBAS &&
        personBAS?.length === 0
      ) {
        setIsProfileCardOpen(false);
      } else {
        setIsProfileCardOpen(true);
      }
    }
  };

  useEffect(() => {
    if (currentPerson) {
      checkDataAndSetVisibility(currentPerson);
      hasRelevantContent();
    }
  }, [currentPerson]);

  const hasRelevantContent = () => {
    if (currentPerson) {
      const isProfessionalCareerDataEmpty: boolean =
        currentPerson?.significantAccomplishments?.trim() === "" &&
        currentPerson.additionalActivities?.trim() === "" &&
        currentPerson.seminarPresentation?.trim() === "" &&
        (currentPerson.industryOrganizationAffiliation?.every(
          (affiliation) => affiliation === ""
        ) ||
          currentPerson.industryOrganizationAffiliation?.length === 0);

      if (isProfessionalCareerDataEmpty === true) {
        setIsProfessionalCareerCardOpen(false);
      } else {
        setIsProfessionalCareerCardOpen(true);
      }
    }
  };

  const handleChangePublic = () => {
    setIsPublicPreview(!isPublicPreview);
  };

  const handleMissingFields = (fields: string[]) => {
    setMissingFields(fields);
    if (fields.length > 0) {
      setIsSaveOverrideOpen(true);
    } else {
      setIsSaveOverrideOpen(false);
    }
  };

  const closeSaveOverride = () => {
    setIsSaveOverrideOpen(false);
  };

  const getContentData = async (_currentPerson:any) => {
    try {
      const response = await axios.Get(`/Content?filter={"authors":{"value":"${_currentPerson?.id}", "comparisonType":"ARRAY_CONTAINS"}}`);
      if (response && response.data && currentPerson) {
        const filteredByCurrentPerson = response.data.filter((content: any) =>
          content.authors.find((author: any) => author === currentPerson.id)
        );

        const validArticles = filteredByCurrentPerson.filter((article: any) => {
          const publishedDate = new Date(article.publishedDate);
          return (
            !isNaN(publishedDate.getTime()) &&
            typeof article.articleContent === "string"
          );
        });

        if (validArticles.length > 0) {
          console.log("personArticles:", validArticles);
          setPersonArticles(validArticles);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <BackgroundComponent image={ProfileViewModeBg} />
      {missingFields.length > 0 && (
        <OverrideVCF
          fields={missingFields}
          handleButtonClick={handleSaveOverrideButtonClick}
          open={isSaveOverrideOpen}
          close={closeSaveOverride}
          title={"Generate anyway"}
        />
      )}
      <div className="bg-gray-100 min-h-screen text-left px-4 py-7 items-start flex flex-col gap-[1.5rem] md:py-8 md:px-6 lg:py-10 lg:px-10 lg:gap-[2.5rem]">
        <div className="flex flex-col items-start gap-5 self-stretch">
          <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 !items-end self-stretch !p-0 flex-col ">
            <div className="flex p-0 flex-row justify-center items-end gap-5 self-stretch md:flex-row lg:justify-between lg:gap-6">
              <h2 className="text-left w-full lg:w-max text-white font-decimal > font-medium leading-[2.75rem] md:text-[2.75rem] md:leading-[3.25rem] lg:text-[3.25rem] lg:leading-[4.5rem]">{`${isEditable ? "My Profile" : "Profile"}`}</h2>
              <div
                className="self-stretch flex-1 hidden lg:flex"
                style={{
                  backgroundImage: `url(${WSGDots.default})`,
                }}
              ></div>
              {isEditable && (
                <div className="self-stretch flex whitespace-nowrap">
                  <button
                    onClick={handleChangePublic}
                    content="textIcon"
                    type="button"
                    className={`relative self-stretch h-[3.75rem] min-h-[2.25rem] md:min-h-[3.75rem] md:h-[3.75rem] lg:min-h-[4.5rem]
                          flex w-full md:w-auto border border-solid ${urlType === "staff" ? "border-Default bg-Default" : "border-[#BC3323] bg-[#BC3323]"} pl-3 pr-2 min-w-auto gap-3 items-center justify-center py-2  md:py-2 md:pr-2 md:pl-3 md:gap-3 lg:py-[1.4375rem] lg:pl-8 lg:pr-6 lg:gap-4`}
                  >
                    <span className="text-white text-[1rem] font-medium leading-[1.125rem] font-decimal text-center md:text-[1.125rem] lg:text-[1.25rem] lg:leading-[1.5rem]">
                      {isPublicPreview
                        ? "EXIT PUBLIC PREVIEW"
                        : "PREVIEW PUBLIC PROFILE"}
                    </span>
                    <div className="w-[1.125rem] h-[1.125rem] md:w-5 md:h-5 flex items-center justify-center fill-white">
                      <Eye />
                    </div>
                  </button>
                </div>
              )}
              {editmode && (
                <div className="flex h-[4rem] w-fit items-center">
                  <GoBack className="!text-white" />
                  <NewButton
                    onClick={() => navigate(`/Staff/ManageFirm/Professionals/${currentPerson?.id}/Edit`)}
                    className=""
                    text={"Edit Profile"}
                    icon={<PencilEdit />}
                    color="accent"
                    content="textIcon"
                    size="large"
                    style="filled"
                  />
                </div>
                )}
            </div>
            <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
            <div className="flex w-full">
              <CurrentPage
                prevPage="Home"
                currentPage={`${isEditable && !isPublicPreview ? "My Profile" : "Profile"}`}
              />
            </div>
          </div>

          <div className="bg-white  flex py-[1.25rem] px-4 flex-col items-start gap-[1.25rem] self-stretch z-10 relative md:py-7 md:px-7 md:gap-6 lg:py-10 lg:px-10 lg:gap-7 lg:flex-row">
            {currentPerson && (
              <div className="flex p-0 flex-col items-start gap-5 self-stretch h-max  md:flex-row md:gap-[3.25rem] lg:flex-col lg:justify-center lg:items-start lg:gap-[3.25rem] lg:max-w-[30rem] lg:pt-16 ">
                <ProfessionalCard
                  wsgOffice={wsgOffice}
                  isWSGFirm={isWSGFirm}
                  isEditable={isEditable && !isPublicPreview}
                  viewMoreActive={viewMoreActive}
                  entity={currentPerson}
                  isOrganization={false}
                  officeCountry={officeCountry}
                  personMainOffice={personMainOffice}
                  isPublicPreview={isPublicPreview}
                  isDataMissing={handleMissingFields}
                />
                <div className="flex flex-col p-0 justify-center items-center gap-[.625rem] self-stretch h-max personal lg:w-[30rem] flex-shrink flex-grow basis-0 lg:flex-shrink-0 lg:flex-grow-0 lg:basis-auto lg:self-baseline lg:p-0 lg:items-center lg:gap-[.625rem] lg:flex-row">
                  {personFirm && (
                    <>
                      <ProfileAndSocialLinks
                        isEditable={isEditable && !isPublicPreview}
                        currentFirm={personFirm}
                        person={currentPerson}
                        organization={true}
                      />
                      <FirmCard
                        wsgOffice={wsgOffice}
                        isWSGFirm={isWSGFirm}
                        viewMoreActive={viewMoreActive}
                        personFirm={personFirm}
                        personJurisdictionGroups={
                          personMatchedJurisdictionGroups
                        }
                        primaryLocations={primaryLocations}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
            <hr className="flex h-0 justify-center items-center self-stretch relative z-10 lg:flex-col lg:border-[0.063rem] lg:h-auto" />
            <ExpertiseCard
              onBooleanChange={handleBooleanFromChild}
              person={currentPerson}
              practiceIndustries={practiceIndustriesMatched}
              isEditable={isEditable && !isPublicPreview}
              personAops={personAOPS}
            />
          </div>
        </div>

        {shouldShowDisclosure(
          currentPerson,
          ["bio", "languages", "barAdmissions"],
          isEditable,
          isPublicPreview
        ) && (
          <Disclosure
            personInfo={currentPerson}
            title="PROFILE"
            isEditable={isEditable && !isPublicPreview}
            color={`${urlType === "staff" ? "secondary" : "primary"}`}
            isCardOpen={isProfileCardOpen}
            onCardOpen={() => setIsProfileCardOpen(!isProfileCardOpen)}
          >
            <div
              className={`${isProfileCardOpen === false ? "hidden" : ""} duration-300 px-4 py-5 flex flex-col self-stretch md:px-7 md:py-7 lg:px-10 lg:py-10`}
            >
              <ProfileCardNew
                personBAS={personBAS}
                profileEducation={profileEducation ?? []}
                isEditable={isEditable && !isPublicPreview}
                person={currentPerson}
                personLanguages={personLanguages}
              />
            </div>
          </Disclosure>
        )}

        {personAOPS && personAOPS.length > 0 &&
          <Disclosure
            isOnlyAops={true}
            isOnlyProfessionalCareer={false}
            personInfo={currentPerson}
            title="Areas Of Practice"
            isEditable={isEditable && !isPublicPreview}
            color={`${urlType === "staff" ? "secondary" : "primary"}`}
            isCardOpen={isAOPCardOpen}
            onCardOpen={() => setIsAOPCardOpen(!isAOPCardOpen)}
          >
            <div
              className={`${isAOPCardOpen === false ? "hidden" : ""} duration-300 flex flex-col self-stretch`}
            >
              <AreasOfPracticeCard
                practiceIndustriesMatched={
                  practiceIndustriesMatched ? practiceIndustriesMatched : []
                }
                person={currentPerson}
                personAOPS={personAOPS ? personAOPS : {}}
                isEditable={isEditable && !isPublicPreview}
              />
            </div>
          </Disclosure>}

        {shouldShowDisclosure(
          currentPerson,
          [
            "significantAccomplishments",
            "industryOrganizationAffiliation",
            "additionalActivities",
            "seminarPresentation",
          ],
          isEditable,
          isPublicPreview
        ) && (
          <Disclosure
            isOnlyAops={false}
            isOnlyProfessionalCareer={true}
            personInfo={currentPerson}
            title="Professional Career"
            isCardOpen={isProfessionalCareerCardOpen}
            onCardOpen={() =>
              setIsProfessionalCareerCardOpen(!isProfessionalCareerCardOpen)
            }
            isEditable={isEditable && !isPublicPreview}
            color={`${urlType === "staff" ? "secondary" : "primary"}`}
          >
            <div
              className={`${isProfessionalCareerCardOpen === false ? "hidden" : ""} duration-300 flex flex-col self-stretch`}
            >
              <SignificantAccomplishmentsCard
                isEditable={isEditable && !isPublicPreview}
                person={currentPerson}
              />
            </div>
          </Disclosure>
        )}

        {currentPerson && (isEditable && !isPublicPreview || personArticles.length > 0) && (
          <Disclosure
            isOnlyAops={false}
            isOnlyProfessionalCareer={false}
            isLinkDisabled
            color={`${urlType === 'staff' ? 'secondary' : 'primary'}`}
            title="Articles"
            isEditable={isEditable && !isPublicPreview}
            isCardOpen={isArticlesCardOpen}
            personInfo={currentPerson}
            onCardOpen={() => setIsArticlesCardOpen(!isArticlesCardOpen)}
          >
            <div className={`${!isArticlesCardOpen ? 'hidden' : ''} duration-300 flex flex-col self-stretch`}>
              <PersonArticles articles={personArticles} person={currentPerson} />
            </div>
          </Disclosure>
        )}
        <Disclosure isLinkDisabled color={`${urlType === 'staff' ? 'secondary' : 'primary'}`} title="Blog" isEditable={isEditable && !isPublicPreview} isCardOpen={false} personInfo={currentPerson} onCardOpen={() => setIsBlogCardOpen(!isBlogCardOpen)}>
        </Disclosure>
        <ProfileBanner />
      </div>
    </>
  );
};

export default ViewMode;
