import React, { useEffect, useState } from "react";
import CityFilter from "../../components/Catalogs/Filters/CityFilter";
import { Option } from "../../components/Atomos/DropDown/DDMultiple";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import { createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import ShapeEdit from "../../components/Atomos/Icons/ShapeEdit";
import { formatTimeSpanToString } from "../../utils/functions";
import useDataLoader2 from "../../hooks/useDataLoader2";
import { useStatesList } from "../../hooks/Catalogs/useStates";
import { UseCountryList } from "../../hooks/Catalogs";
import { UseRegionList } from "../../hooks/Catalogs/useRegionListOptions";
import { ICity } from "../../interfaces";
import { defaultPageSize } from "../../utils/const";

type tablebase = {
  count: number;
  edit: boolean;
};

const columnHelper = createColumnHelper<tablebase & ICity>();

const City: React.FC = () => {
  const Headers: string[] = ["name", "country", "state", "regions", "createdDate"];

  const [cityFilters, setCityFilters] = useState<{
    name: string;
    countries: Option[];
    regions: Option[];
    state: Option[];
  }>({ name: "", countries: [], regions: [], state: [] });

  const [tableInstance, setTableInstance] = useState<any>()
  const [cityData, setCityData] = useState<any>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { statesData } = useStatesList()
  const { countryData } = UseCountryList()
  const {regionData}  = UseRegionList()
  const fields = [
    'id', 
    'legacyId',
    'name', 
    'state',
    'stateName',
    'countryName',
    'regionNames',
    'createdDate'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/city',
    defaultPageSize,
    fields
  });

  const columns = [
    columnHelper.accessor("count", {
      header: "#",
      cell: (props) => (
        <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
      ),
      size: 50,
      enableSorting: false,
    }),
    columnHelper.accessor("name", {
      header: "Name",
      cell: (props) => <p>{props.getValue()}</p>,
    }),
    columnHelper.accessor("country", {
      header: "Country",
      cell: (props) => <p>{props.getValue()}</p>,
    }),
    columnHelper.accessor("state", {
      header: "State",
      cell: (props) => <p>{props.getValue()}</p>,
    }),
    columnHelper.accessor("regions", {
      header: "Regions",
      cell: (props) => <p>{props.getValue()}</p>,

    }),
    columnHelper.accessor("createdDate", {
      header: "Created Date",
      cell: (props) => {
        const value = props.getValue();
        return (
          <p>
            {value && formatTimeSpanToString(''+value)}
          </p>
        );
      },
    }),

    columnHelper.accessor("edit", {
      header: "Edit",
      enableSorting: false,
      cell: (props) => (
        <Link
          className="edit"
          to={`Edit/${props.row["original"].id}`}
          state={props.row["original"]}
        >
          Edit{" "}
          <div className="editsvg">
            <ShapeEdit />
          </div>{" "}
        </Link>
      ),
    }),
  ];

  const formatData = (data: ICity[]) => {
    const formattedData = data.map((d: ICity) => {
      return {
        ...d,
        state: d.stateName,
        country: d.countryName,
        regions: d.regionNames
      };
    });
    return formattedData;
  };

  useEffect(() => {
    if (data) setCityData(formatData(data))
  },[data]);

  useEffect(() => {
    if (!statesData.length || !countryData.length) return
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[statesData, countryData]);

  useEffect(() => {
    let filterAux = {
      name: {
        value: cityFilters.name,
        comparisonType: 'CONTAINS'
      },
      state: {
        value: cityFilters.state.map(c => c.id),
        comparisonType: 'EQUAL'
      },
      country: {
        value: cityFilters.countries.map(f => f.id),
      },
      regions: {
        value: cityFilters.regions.map(f => f.id)
      },
    }
    setFilter(filterAux)
  }, [cityFilters])

  const clearSelections = async () => {
    setCityFilters({ name: "", countries: [], regions: [], state: [] });
    fetchData(true)
  };

  return (
    <>
      <main className=" mainSection">
        <CatalogTableC
          title={`City Catalog`}
          catalogName={"CITIES"}
          catalogShortName={"CITY"}
          route={"City"}
          isfiltered={true}
          data={data}
          headers={Headers}
          totalCount={totalCount}
          exportData={cityData}
        >
          <CityFilter
            headers={Headers}
            key={0}
            Search={() => fetchData()}
            filters={cityFilters}
            setFilters={setCityFilters}
            tableInstance={tableInstance}
            clearSelections={clearSelections}
          />
          <TableWithPagination
            data={cityData}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </main>
    </>
  );
};

export default City;
