import React, { useEffect, useState } from 'react';
import Axios from '../../utils/axios';
import UserFilter from '../../components/Catalogs/Filters/UserFilter';
import { Option } from '../../components/Atomos/DropDown/DDMultiple';
import { useTableSettings } from '../../hooks/Table/UseTableSettings';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import { UseFirmList } from '../../hooks/Catalogs/useFirm';
import { ISorting } from '../../interfaces/Components/ITable';
import { defaultPageSize } from '../../utils/const';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/loaderActions';
import useDataLoader2 from '../../hooks/useDataLoader2';

const User: React.FC = ({ ...props }) => {

  const axios = new Axios();
  const [accessData, setAccessData] = useState<any[]>([]);
  
  const [tableInstance, setTableInstance] = useState<any>();
  const [userData, setUserData] = useState<any>([]);
  const [usersData, setUsersData] = useState<any[]>([]);
  const [nameFilter, setNameFilter] = useState<string>('');
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const {firmData} = UseFirmList();
  const dispatch = useDispatch();

	const { data, totalCount, fetchData, setFilter, sort, setSort, pagination, setPagination } = useDataLoader2<any>({
    endpoint: '/user',
    defaultPageSize
  });

  useEffect(() => {
    fetchUsers()
    setAccessData([{ id: "member", name: "Member" }, { id: "dataAdmin", name: "Data Admin + Member" }, { id: "wsgStaff", name: "WSG Staff" }, { id: "wsgSuper", name: "WSG Staff Super" }]);
  }, []);
  
  const fetchUsers = async () => {
    try {
      const users = (await axios.Get('/user')).data;
      const userIds = users.map((u: any) => u.id);

      const groupedIds = groupIdsInChunks(userIds, 50);

      const people = (await Promise.all(
        groupedIds.map(async (chunk) => {
          const people = await getPeople(chunk);
          return people;
        })
      )).flat();

      const data = users.map((u: any) => ({
        ...u,
        ...people.find((p: any) => p.userId === u.id)
      }))

      setUsersData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getPeople = async (ids: string[]) => {
    try {
      const params = new URLSearchParams();
      params.append('filter', JSON.stringify({userId: {value: ids, comparisonType: 'EQUAL'}}))
      const response = await axios.Get(`./person?${params.toString()}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  const groupIdsInChunks = (userIds: string[], chunkSize: number): string[][] => {
    const chunks: string[][] = [];
    for (let i = 0; i < userIds.length; i += chunkSize) {
      chunks.push(userIds.slice(i, i + chunkSize));
    }
    return chunks;
  }

  const Headers: string[] = ['fullName', 'generatedId', 'firm', 'email', 'access', '_ts'];
  const [pageHeaders, setPageHeaders] = useState<string[]>([]);
  const [selectedFirm, setSelectedFirm] = useState([] as Option[])
  const [selectedAccess, setSelectedAccess] = useState([] as Option[])
  const { generateColumns } = useColumnConfig(Headers);

  const columns = generateColumns([
    { id: 'count', header: '#', cell: (props: any) => <p>{props.row.index + 1 + (pagination.pageIndex) * pagination.pageSize}</p>, enableSorting: false, size: 50 },
    { id: 'fullName', header: 'Name', cell: (props: any) => props.getValue() },
    { id: 'generatedId', header: 'Generated ID', cell: (props: any) => props.getValue() },
    { id: 'firm', header: 'Firm', cell: (props: any) => props.getValue() },
    { id: 'email', header: 'Email', cell: (props: any) => props.getValue() },
    { id: 'access', header: 'Access', cell: (props: any) => props.getValue() },

    {
      id: 'edit', header: 'Edit', cell: (props: any) =>
      (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={{ 
            user: props.row.original, 
          }}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ), enableSorting: false
    },
  ]);

  const formatData = (data:any) => {
    const formattedData = data.map((subject: any) => {
      const firm = firmData?.find((fi: any) => fi.id === subject?.firm);
      return {
        id: subject?.id,
        fullName: subject ? (
          [
            subject.honorary,
            subject.name,
            subject.middleInitial,
            subject.lastName,
            subject.suffix
          ]
            .filter(part => part)
            .join(" ")
        ) : '',
        generatedId: subject ? subject.generatedId : '',
        firm: firm ? firm.name : '',
        email: subject ? subject.firmEmail : '',
        userName: subject?.mailNickname,
        person: subject ? subject.id : "",
        access: subject?.access,
        userId: subject ? subject.userId : "",
        subject: subject,
        _ts: subject?.createdDateTime
      };
    });
    return formattedData;
  };
  

  useEffect(() => {
    if (pageHeaders.length === 0) {
      setPageHeaders(Headers);
    }
  }, []);

	useEffect(() => {
    const userPersonData = formatData(data);
    setUserData(userPersonData);
	}, [data]);

  const clearSelections = () => {
    setSelectedAccess([]);
    setSelectedFirm([]);
    setFilter([]);
    fetchData(true);
  }

  const sortData = (data: any[], sort: ISorting): any[] => {
    if (sort.order === 'default') {
      return data; 
    }
  
    return data.sort((a, b) => {
      const fieldA = a[sort.field];
      const fieldB = b[sort.field];
  
      if (fieldA === fieldB) return 0;
  
      if (sort.order === 'asc') {
        return fieldA > fieldB ? 1 : -1;
      } else {
        return fieldA < fieldB ? 1 : -1;
      }
    });
  };
  
  useEffect(() => {
    fetchData()
  }, [sort]);

  useEffect(() => {
    if (!usersData.length ||!firmData.length) return
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[usersData, firmData]);

  useEffect(() => {
    setFilter({
      name: nameFilter, 
      firm: selectedFirm?.map(c => c.id),
      access: selectedAccess?.map(c => c.id)
    })
  },[
    nameFilter, 
    selectedFirm, 
    selectedAccess, 
  ]);

  return (
    <>
      <div className=' mainSection'>
        <CatalogTableC
          title={`User Catalog`}
          catalogName={'USER'}
          route={'User'}
          data={userData}
          headers={Headers}
          totalCount={totalCount}
          isfiltered={true}
          exportData={userData}
        >
          <UserFilter
            nameFilter={nameFilter}
            onNameFilterChange={setNameFilter}
            firmData={firmData}
            selectedFirm={selectedFirm}
            setSelectedFirm={setSelectedFirm}
            accessData={accessData}
            selectedAccess={selectedAccess}
            clearSelections={clearSelections}
            Search={() => fetchData()}
            headers={Headers}
            setSelectedAccess={setSelectedAccess}
          />
          <TableWithPagination
            data={userData}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            totalCount={totalCount}
						pagination={pagination}
						setPagination={setPagination}
            sorting={sort}
            setSorting={setSort}
          />
        </CatalogTableC>
      </div>
    </>
  );

};

export default User;