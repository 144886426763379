import React from 'react';

interface ArrowBackProps {
  className?: string;
}

const ArrowBack: React.FC<ArrowBackProps> = (props: ArrowBackProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none">
			<path d="M7.37302 12.75L13.0692 18.4461L12 19.5L4.5 12L12 4.5L13.0692 5.55383L7.37302 11.25H19.5V12.75H7.37302Z" fill="white"/>
		</svg>
  );
}

export default ArrowBack;
