import { useNavigate } from "react-router-dom";
import { capitalizeHeader } from "../../utils/capitalize";
import { useState } from "react";

interface IProps {
  id?: string;
  onDelete: (id: string) => void;
  redirectUrl?: string;
  state: any;
  newState: any;
  customFieldNames?: any;
}

export const useButtonCrud = ({
  id,
  onDelete,
  redirectUrl,
  state,
  newState,
  customFieldNames,
}: IProps) => {
  const navigate = useNavigate();
  const [modifiedFields, setModifiedFields] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalConfig, setModalConfig] = useState({ title: '', message: '', onConfirm: (confirm: boolean) => {} });

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setModifiedFields([])
    setModalConfig({
      title: "Delete Confirmation",
      message: "Are you sure you want to delete this item?",
      onConfirm: (confirm) => {
        if (confirm) {
          if (id !== undefined) {
            onDelete(id);
          } else {
            console.error("id is undefined");
          }
        } else {
          setOpenModal(false);
        }
      },
    });
    setOpenModal(true);
  };

  const handleCancel = () => {
    const fields = deepEqual(state, newState);
    if (fields.length) {
      setModifiedFields(fields);
      setModalConfig({
        title: "Cancel Confirmation",
        message: "There are unsaved changes. Do you want to leave the page?",
        onConfirm: (confirm: boolean) => {
          if (confirm) redirectUrl ? navigate(redirectUrl) : navigate(-1);
          else setOpenModal(false);
        },
      });
      setOpenModal(true);
    } else {
      redirectUrl ? navigate(redirectUrl) : navigate(-1);
    }
  };

  const deepEqual = (obj1: any, obj2: any, path = ""): string[] => {
    const fields: string[] = [];

    if (typeof obj1 !== typeof obj2) {
      fields.push(path);
      return fields;
    }

    if (obj1 instanceof Date && obj2 instanceof Date) {
      if (obj1.getTime() !== obj2.getTime()) {
        fields.push(path);
      }
      return fields;
    }
    
    if (typeof obj1 !== "object" || obj1 === null || obj2 === null) {
      if (obj1 !== obj2) {
        fields.push(path);
      }
      return fields;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    const allKeys = new Set([...keys1, ...keys2]);

    allKeys.forEach((key) => {
      const newPath = path ? `${path}.${key}` : key;
      if (!shouldIgnoreField(newPath)) {
        fields.push(...deepEqual(obj1[key], obj2[key], newPath));
      }
    });
    const formatedFields = formatFieldName(fields);
    return formatedFields;
  };

  const formatFieldName = (field: string[]) => {
    return field.map((f) => {
      if (customFieldNames && Object.keys(customFieldNames).includes(f))
        return customFieldNames[f];
      else {
        const newField = f.split(".").join(" ");
        return capitalizeHeader(newField);
      }
    });
  };

  const fieldsToIgnore = [
    "id",
    "_rid",
    "_self",
    "_etag",
    "_attachments",
    "_ts",
  ];
  
  const shouldIgnoreField = (fieldName: string): boolean => {
    return fieldsToIgnore.includes(fieldName);
  };

  return {
    handleCancel,
    handleDelete,
    modifiedFields,
    setOpenModal,
    openModal,
    modalConfig
  };
};
