import React from "react";

function GroupsAlt(props: any) {
  return (
    <div className={props.className}>
      <svg
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 48 48"
      >
        
          <path 
            d="M-.34 36.34v-3.002c0-1.39.725-2.518 2.175-3.382 1.45-.863 3.344-1.295 5.684-1.295.3 0 .58.003.837.01.257.008.522.03.794.066a9.55 9.55 0 00-.69 1.942 8.974 8.974 0 00-.233 2.066v3.594H-.34zm12 0v-3.59c0-1.123.297-2.15.89-3.08.593-.93 1.441-1.739 2.546-2.424 1.104-.686 2.415-1.205 3.932-1.557 1.517-.352 3.172-.528 4.964-.528 1.827 0 3.494.176 5.002.528 1.508.352 2.819.871 3.932 1.557 1.104.685 1.95 1.493 2.535 2.424.585.93.878 1.957.878 3.08v3.59H11.661zm28.103 0v-3.602a9.67 9.67 0 00-.217-2.089 9.285 9.285 0 00-.646-1.908c.273-.039.54-.062.8-.07a29 29 0 01.8-.01c2.367 0 4.265.427 5.695 1.28 1.43.855 2.144 1.991 2.144 3.409v2.99h-8.576zm-24.141-3.566h16.756v-.3c-.15-1.14-.992-2.053-2.525-2.742-1.532-.69-3.483-1.034-5.853-1.034-2.37 0-4.32.35-5.853 1.048-1.533.698-2.374 1.624-2.525 2.778v.25zm-8.138-5.528c-1.012 0-1.88-.364-2.6-1.092a3.598 3.598 0 01-1.082-2.627c0-1.028.362-1.903 1.087-2.624.724-.72 1.594-1.081 2.612-1.081 1.022 0 1.899.36 2.63 1.082.73.722 1.095 1.602 1.095 2.64 0 1.007-.363 1.876-1.088 2.606-.726.73-1.61 1.096-2.654 1.096zm33 0c-1.012 0-1.88-.364-2.6-1.092a3.598 3.598 0 01-1.082-2.627c0-1.028.362-1.903 1.087-2.624.724-.72 1.594-1.081 2.611-1.081 1.023 0 1.9.36 2.63 1.082.73.722 1.096 1.602 1.096 2.64 0 1.007-.363 1.876-1.088 2.606-.726.73-1.61 1.096-2.654 1.096zm-16.47-3.557c-1.765 0-3.265-.617-4.5-1.853-1.235-1.235-1.853-2.735-1.853-4.5 0-1.785.617-3.285 1.853-4.501 1.235-1.216 2.735-1.824 4.5-1.824 1.785 0 3.285.608 4.501 1.824 1.216 1.216 1.824 2.716 1.824 4.501 0 1.765-.608 3.265-1.824 4.5-1.216 1.236-2.716 1.853-4.501 1.853zm.018-3.565c.77 0 1.424-.271 1.963-.814.538-.543.807-1.208.807-1.995 0-.787-.273-1.445-.818-1.974a2.724 2.724 0 00-1.966-.793c-.789 0-1.451.266-1.988.799-.536.533-.804 1.193-.804 1.98 0 .773.266 1.433.797 1.978.531.546 1.2.819 2.009.819z"
          ></path> 
      </svg>
    </div>
  );
}

export default GroupsAlt;
