import React, { useEffect, useState } from 'react';
import TextArea from '../../Atomos/Inputs/TextArea';
import Axios from '../../../utils/axios';
import HeaderTitle from './HeaderTitle';
import { PersonBioProps } from '../../../interfaces/models/IPerson';
import DDMultiple, { Option } from '../../Atomos/DropDown/DDMultiple';
import CatalogSubTitle from '../Catalog Text/CatalogSubTitle';
import NewButton from '../../Atomos/Buttons/newButton';
import Plus from '../../Atomos/Icons/EditMode/Plus';
import AddLanguageModal from './AddLanguageModal';
import AddAdmissionModal from './AddAdmissionModal';
import { get } from 'http';
import useViewport from '../../../hooks/useViewPort';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../../../Context';
import { useStaffMode } from '../../../hooks/StaffModeProvider';

const axios = new Axios();


type error = {
  error: boolean,
  errorMsj: string
}

type PersonBioPropsEdit = {
  handleAddlanguages: (newAdditionalOffices: string[]) => void;
  handleAddAdmision: (newAdmision: string[]) => void;
  handleTextAreaElement: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) => void;
  languages: string[];
  barAdmissions: string[];
} & PersonBioProps;

const PersonBioEdit: React.FC<PersonBioPropsEdit> = (props: PersonBioPropsEdit) => {

  const [error, setError] = useState<error>({ error: false, errorMsj: "" });
  const [languages, setLanguages] = useState<Option[]>([]);
  const [barAdmissions, setBarAdmissions] = useState<Option[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<Option[]>([]);
  const [selectedBarAdmissions, setSelectedBarAdmissions] = useState<Option[]>([]);
  const [openbarAdmision, setOpenBarAdmision] = useState(false);
  const [open, setOpen] = useState(false);
  const {viewportWidth} = useViewport();
  const {isStaffMode} = useStaffMode(); 

  const getLanguages = async () => {
    const languages = await axios.Get('language');
    const languagesOptions = languages.data.map((language: any) => ({
      id: language.id,
      name: language.name,
    }));  
    setLanguages(languagesOptions);

    const currentLanguages: Option[] = languagesOptions.filter((language: Option) =>
      props.languages?.some((savedItem: string) => savedItem === language.id)
    ); 

    setSelectedLanguages(currentLanguages) 

  }
  
  const getBarAdmissions = async () => {
    const barAdmissionsResponse = await axios.Get('barAdmission');
    const barAdmissionsOptions = barAdmissionsResponse.data.map((barAdmission: any) => ({
      id: barAdmission.id,
      name: barAdmission.name,
    }));  
    setBarAdmissions(barAdmissionsOptions);

    const currentBarAdmissions: Option[] = barAdmissionsOptions.filter((admission: Option) =>
      props.barAdmissions?.some((savedItem: string) => savedItem === admission.id)
    ); 

    setSelectedBarAdmissions(currentBarAdmissions) 
  }

  useEffect(() => {

    getBarAdmissions();
    getLanguages();
  }, []);

  const handleLanguagesChange = (clanguages: any) => {
    props.handleAddlanguages(clanguages?.map((language: any) => {
      return language.id;
    }) as string[]); 

    setSelectedLanguages(clanguages || [] as Option[])
  };
  const handleAdmissionChange = (admissions: any) => {
    props.handleAddAdmision(admissions?.map((admission: any) => {
      return admission.id;
    }) as string[]); 
    setSelectedBarAdmissions(admissions || [] as Option[])
  }

  const handleBioChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) => {
    if (event.target.value.length > 4000) {
      setError({ error: true, errorMsj: "Bio must be less than 4000 characters" });
    }
    else {
      setError({ error: false, errorMsj: "" });
    }

    props.handleTextAreaElement(event);
  }

  const ToogleLangugeModal = () => { 
    setOpen(!open);
    getLanguages();
  }
  const ToogleAdmissionModal = () => {
    setOpenBarAdmision(!openbarAdmision);
    getBarAdmissions();
  }
  return (
    <>
    <AddLanguageModal open={open} toggleModal={ToogleLangugeModal} />
    <AddAdmissionModal open={openbarAdmision} toggleModal={ToogleAdmissionModal} />
    <section className=' formsectionNTitle w-full'>
      <CatalogSubTitle title={'Professional Bio'} className={`${isStaffMode ? 'text-Default' : 'text-accentDefault'}`} />
      <div className='flex flex-col w-full gap-5'>
        <div className="col-span-12 md:col-span-12 ">
          <TextArea title="Summary" maxChars={4000} characterCount={true} name="bio" errorMsj={error.errorMsj} error={error.error} value={props.bio} onChange={handleBioChange} required={false} />
        </div>
        <div>
          <div className='flex flex-col gap-4'>
            <span className="text-gray-font font-bold text-left text-[1.375rem] leading-6 ont-bold relative self-stretch">
              Languages
            </span> 
            <div className='flex gap-5 items-end self-auto'>
              <div className='flex-1'>
              <DDMultiple title="" objects={languages} selectedObjst={selectedLanguages} seter={ (e)=>handleLanguagesChange(e)} /> 
              </div>
              <div className='self-auto'>
              <NewButton text={`${viewportWidth>1023?"New Language":viewportWidth>768?"New":""}`} icon={<Plus />} content='textIcon' color={isStaffMode ? 'accent' : 'primary'} onClick={()=>setOpen(true)} />
              </div>
            </div>
            <span className="text-gray-font font-bold text-left text-[1.375rem] leading-6 ont-bold relative self-stretch">
              Bar Admissions
            </span> 
            <div className='flex gap-5 items-end self-auto '>
              <div className='flex-1'>
                <DDMultiple title="" objects={barAdmissions} selectedObjst={selectedBarAdmissions} seter={(e)=>handleAdmissionChange(e)} />
              </div>
              <div className='self-auto'>
              <NewButton text={`${viewportWidth>1023?"New Admission":viewportWidth>768?"New":""}`} icon={<Plus />} content='textIcon' color={isStaffMode ? 'accent' : 'primary'} onClick={()=>setOpenBarAdmision(true)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>  
    </>
  )
}
export default PersonBioEdit;
