
import { useStaffMode } from '../../../hooks/StaffModeProvider';

type titleprops = {
    title: string;
} 

const HeaderTitle: React.FC<titleprops> = ({ title }) => {
  const {isStaffMode} = useStaffMode(); 

  return (
    <h3 className={`${isStaffMode ? 'text-Default' : 'text-primary' } font-decimal text-[2rem] font-medium flex-2`}>{title}</h3>
  );
};

export default HeaderTitle;