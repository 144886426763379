import React, { useState, useContext, useEffect } from "react";
import css from "./Header.module.css";
import AuthContext from "../../../store/AuthContext";
import LightLogo from "../Logo/LightLogo";
import WhiteBars from "../../Atomos/Icons/WhiteBars";
import Search from "../../Atomos/Icons/Search";
import ChevronDown from "../../Atomos/Icons/ChevronDown";
import RedWorldDark from "../Logo/RedWorldDark";
import DarkSearch from "../../Atomos/Icons/DarkSearch";
import RedBars from "../../Atomos/Icons/RedBars";
import Account from "../../Atomos/Icons/Account";
import { useNavigate, Link } from "react-router-dom";
import AnimationLogo from "../LoadingAnimation/AnimationLogo";
import { useStaffMode } from "../../../hooks/StaffModeProvider";
import { getPersonByUserId } from "../../../Services/Person";
import user_icon_placeholder from '../../../assets/images&icons/user_icon_placeholder.png';
import MonitorIcon from '../../Atomos/Icons/MonitorIcon';
import { navigateToLegacy } from "../../../Enums/LegacyRedirection";
import { useDispatch } from "react-redux";
import { toggleExtraMenuIsOpen, toggleSideMenuIsOpen } from "../../../redux";

interface User {
  name: string;
  middleName?: string;
  lastName?: string;
  // ... otros campos relevantes
}

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { tokenDecoded, isAuthenticated } = useContext(AuthContext);
  const loginUrl = process.env.REACT_APP_SIGNIN_AZB2C_URL as string;
  const [isHeaderExtraOpen, setIsHeaderExtraOpen] = useState(false);
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const [isCatalogsOpen, setIsCatalogsOpen] = useState(false);
  const [isSubCatalogsOpen, setIsSubCatalogsOpen] = useState(false);
  const [showingLoader, setShowingLoader] = useState(false);
  const userPermissions = tokenDecoded?.extension_WSG_Permissions 
  ? tokenDecoded.extension_WSG_Permissions.split(',') 
  : [];
	const isDataAdmin = userPermissions.includes('Data Admin + Member');

  const [user, setUser] = useState<User | null>(null);
  const [userPhoto, setUserPhoto] = useState(user_icon_placeholder);
  const dispatch = useDispatch();

  const { isStaffMode, toggleStaffMode } = useStaffMode();

  const fetchPerson = async (userId: string) => {
    const person:any = await getPersonByUserId(userId);
    setUser(person)
		if(person?.photo)
    	setUserPhoto(person?.photo)
  }

  useEffect(() => {
    if (!tokenDecoded) return
    fetchPerson(tokenDecoded.oid);
  }, [tokenDecoded]);


  const toggleNav = () => {
    dispatch(toggleSideMenuIsOpen());
  };

  const headerColor = isAuthenticated ? (isStaffMode ? "bg-Default" : " bg-accentDefault") : "bg-white";

  const toggleHeaderExtra = () => {
    dispatch(toggleExtraMenuIsOpen())
  };



  return (
    <> {
      showingLoader ?
        <AnimationLogo />
        :
        <div className={`relative`}>
          <div className={`relative header-container z-30 w-full py-5 px-4 top-0 ${headerColor} `} >
            <div className="flex items-center header-components align-middle h-10">
              {!isDataAdmin && (
                <div
                  className={`flex align-middle justify-center items-center w-6 h-6 cursor-pointer ${isAuthenticated ? "fill-neutral" : "fill-accentDefault"}`}
                  onClick={toggleNav}
                >
                  {isAuthenticated ? <WhiteBars /> : <RedBars />}
                </div>
              )}
              <div className="flex items-center ml-8">
                <div className="w-[5.8rem] h-[2rem] md:w-[7.25rem] md:h-[2.5rem]">
                  <Link to="/">
                    {isAuthenticated ?
                      <LightLogo /> :
                      <RedWorldDark />
                    }
                  </Link>
                </div>
                <div className="items-center pl-6">
                </div>
              </div>

              <div className="flex align-middle h-10 ml-auto justify-center">
                {!isAuthenticated ? (
                  <Link
                    to={loginUrl}
                    className={`flex items-center justify-center gap-1 h-fit my-auto lg:mr-8 roboto_font ${css.grayText}`}
                  >
                    <div className="w-8 h-8"><Account/></div>
                    <span className="hidden md:flex mr-0 md:mr-4 lg:mr-0 text-lg">
                      Member Login
                    </span>
                  </Link>
                ) : (
                  <div className="w-auto h-auto flex mr-4 md:mr-6 md:gap-2 items-center justify-center">
										<div className="text-white flex items-center gap-1 cursor-pointer text-lg mr-5 font-bold" onClick={() => navigateToLegacy(isStaffMode)}>
											<MonitorIcon/>
											Go to Legacy
										</div>

                    <div className="md:w-10 md:h-10 h-8 w-8 relative rounded-full bg-gray-200 flex items-center align-middle justify-center">
                      {
                        isAuthenticated && user && (
                          <img src={userPhoto} alt="profile phto" className='relative rounded-full object-center object-cover h-full' />
                        )
                      }
                    </div>
                    <button className="flex items-center justify-center" onClick={toggleHeaderExtra}>
                      <span className="text-white font-bold hidden md:block text-lg">{user?.name} {user?.middleName} {user?.lastName}</span>
                      <div 
                        className="flex w-5 h-5 ml-1 fill-white " 
                        onClick={(e) => { e.currentTarget.classList.toggle('rotate-180') }}
                      >
                        <ChevronDown rotation={isNavigationOpen ? "rotate(0deg)" : "rotate(180deg)"}/>
                      </div>
                    </button>

                  </div>
                )}
                <div className="flex items-center justify-center">
                  <div className=" w-6 h-6 fill-neutral">
                    {isAuthenticated ? <Search /> : <DarkSearch />}
                  </div></div>
                <p
                  className={`text-lg md:flex hidden h-fit my-auto roboto_font hover:opacity-80 font-bold ${isAuthenticated ? 'text-white' : ''}`}
                >
                  Member Search
                </p>
              </div>
            </div>
          </div>
        </div>
    }
    </>
  );
};

export default Header;
