import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import BackgroundComponent from "../../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from '../../../assets/images&icons/profileViewMode.jpeg';
import CurrentPage from "../../../components/Layout/CurrentPageTab/CurrentPage";
import ShortPersonCard from "../../../components/Moleculas/FirmProfile/ShortPersonCard";
import Axios from "../../../utils/axios";
import Vector from "../../../components/Atomos/Icons/Vector";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/loaderActions";
import GoBack from "../../../components/Moleculas/GoBack";
const WSGDots = require("../../../assets/images&icons/WSGDots.svg");
const axios = new Axios();

const AopProfessionalsPage = () => {
  const { state } = useLocation();
  const [aopProfessionals, setAopProfessionals] = useState<any[]>([]);
  const [groupedByOffice, setGroupedByOffice] = useState<any>({});
  const [officeName, setOfficeName] = useState<string>("");
  const dispatch = useDispatch()

  useEffect(() => {
    if (state && state.filteredPersons) {
      if (state.selectedTab === 0) {
        groupByOffice(state.filteredPersons);
      } else {
        setAopProfessionals(state.filteredPersons);
        fetchOfficeName(state.selectedTab !== 0 ? state.selectedTab : '');
      }
    }
  }, [state]);

  const groupByOffice = async (persons: any[]) => {
    const officesResponse = await axios.Get('/office');
    if (officesResponse && officesResponse.data) {
      const offices = officesResponse.data.reduce((acc: any, office: any) => {
        acc[office.id] = office;
        return acc;
      }, {});
      const groupedPersonByOffice = persons.reduce((acc: any, person: any) => {
        const officeId = person.mainOffice;
        const officeName = offices[officeId]?.name || "Unknown Office";
        if (!acc[officeName]) {
          acc[officeName] = [];
        }
        acc[officeName].push(person);
        return acc;
      }, {});

      setGroupedByOffice(groupedPersonByOffice);
    }
  };

  const fetchOfficeName = async (officeId: string) => {
    const officeResponse = await axios.Get(`/office`, officeId);
    if (officeResponse && officeResponse.data) {
      setOfficeName(officeResponse.data[0].name || "Unknown Office");
    }
  };

  return (
    <>
      <BackgroundComponent image={ProfileViewModeBg} />
      <main className="mainSection">
        <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 !items-end self-stretch !p-0 flex-col mb-5">
          <div className="flex p-0 flex-col justify-center items-end gap-5 self-stretch md:flex-row lg:justify-between lg:gap-6 pt-[3rem]">
            <h2 className="text-left w-full lg:w-max text-white font-decimal text-[2.25rem] font-medium leading-[2.75rem] md:text-[2.75rem] md:leading-[3.25rem] lg:text-[3.25rem] lg:leading-[4.5rem]">
              {state?.firm?.name}
            </h2>
            <div className='self-stretch flex-1 hidden lg:flex' style={{
              backgroundImage: `url(${WSGDots.default})`,
            }}>
            </div>
            <div className="flex h-[5rem] w-fit items-center">
                <GoBack className="!text-white" />
              </div>
          </div>
          <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
          <div className="flex w-full lg:mb-[1.75rem]">
            <CurrentPage prevPage="Home > AOP Management" currentPage={`${state?.name} professionals`} />
          </div>
        </div>
        <div className="flex py-[1.25rem] px-4 bg-white lg:py-10 lg:px-10 flex-col items-start gap-6 self-stretch relative">
          <span className="text-Default text-[1.625rem] leading-normal font-decimal lg:text-[2.625rem] lg:leading-[2.75rem] self-stretch">
            {state?.name}
          </span>
          {state.selectedTab === 0 ? (
            Object.keys(groupedByOffice).map(officeName => (
              <div key={officeName} className="flex flex-col gap-[1.25rem] self-stretch">
                <div className="flex flex-row items-center self-stretch gap-4">
                  <h2 className="not-italic font-medium text-lg leading-5 text-Default font-decimal">
                    {officeName}
                  </h2>
                  <div className="flex flex-1 "><Vector className="w-full h-0 stroke-gray-50 stroke-1" /></div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-start gap-[1.25rem] self-stretch">
                  {groupedByOffice[officeName].map((person: any) => (
                    <ShortPersonCard
                      key={person.id}
                      person={person}
                      additionalInfo={!person.isAttorney ? '(Non-Attorney)' : ''}
                    />
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-start gap-[1.25rem] self-stretch">
              {aopProfessionals.map((person: any) => (
                <ShortPersonCard key={person.id} person={person} additionalInfo={!person.isAttorney ? '(Non-Attorney)' : ''} />
              ))}
            </div>
          )}

        </div>
      </main>
    </>
  );
};

export default AopProfessionalsPage;
