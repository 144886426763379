import React, { useEffect, useState } from "react";
import SwitchToogle from "../../Atomos/Switch-Toogle/Switch";
import NewButton from "../../Atomos/Buttons/newButton";
import DDMultiple from "../../Atomos/DropDown/DDMultiple";
import DropDown from "../../Atomos/DropDown/Dropdown";
import RemoveSign from "../../Atomos/Icons/RemoveSign";

interface ContactProps {
  title: string;
  selectedObjst: any;
  index?: number;
  getid?: boolean;
  seter?: any;
  error: boolean;
  errorMsj?: string;
  object?: any;
  type?: any;
  required?: boolean;
  contacts?: any;
  onApplyContact?: (officesIds: any, contactObject: any, type: any) => void;
  onApplyAllContact?: (contactObject: any, type: any) => void;
  offices?: any;
  officeId?: any;
  remove?: (index: any) => void;
}

const OfficeContactBody: React.FC<ContactProps> = (props) => {
  const [multipleMemberships, setMultipleMemberships] = useState(false);
  const [selectedJurisdictions, setSelectedJurisdictions] = useState<any[]>([]);

  useEffect(() => {
    setMultipleMemberships(false);
  }, [props.officeId]);

  useEffect(() => {
    if (!multipleMemberships) setSelectedJurisdictions([]);
  }, [multipleMemberships]);

  const handleApplyContact = () => {
    if (props.onApplyContact) {
      props.onApplyContact(selectedJurisdictions, props.object, props.type);
    }
  };

  const handleApplyAllContact = () => {
    if (props.onApplyAllContact) {
      props.onApplyAllContact(props.object, props.offices);
    }
  };

  const handleRemove = () => {
    if (props.remove) {
      props.remove(props.index);
    }
  };

  // Helper function to filter out undefined values and ensure uniqueness
  const cleanOffices = (offices: any[]) => {
    const uniqueOffices =
      offices?.filter(
        (office, index, self) =>
          office !== undefined &&
          self.findIndex((o) => o?.id === office.id) === index
      ) || [];
    return uniqueOffices;
  };

  return (
    <div
      className={`grid grid-cols-12 md:grid-cols-11 lg:flex lg:flex-nowrap gap-5 w-full ${props.error ? "lg:items-center" : ""}`}
    >
      <div className="grid col-span-9 lg:w-[35%]">
        <DropDown
          title={props.title}
          required={props.required}
          selectedObjst={props.selectedObjst}
          seter={props.seter}
          data={props.contacts}
          error={props.error}
          errormsj={props.errorMsj ?? ""}
          getid={props.getid}
        />
      </div>
      <div className="grid col-span-3 md:col-span-2 lg:[5%]">
        <span className="text-gray-font text-center text-lg leading-5 not-italic font-normal">
          Multiple Offices:
        </span>
        <SwitchToogle
          centered={true}
          seter={setMultipleMemberships}
          checked={multipleMemberships}
        />
      </div>
      <div className="grid col-span-12 md:col-span-11 lg:w-[35%]">
        <DDMultiple
          title="Assign Contact to"
          disabled={!multipleMemberships}
          selectedObjst={selectedJurisdictions}
          seter={setSelectedJurisdictions}
          objects={cleanOffices(props.offices)}
          error={false}
        />
      </div>
      <div className="grid col-span-12 md:col-span-5 lg:[5%]">
        <NewButton
          disabled={!multipleMemberships}
          className="!max-h-[3rem]"
          text="APPLY"
          type="button"
          color="accent"
          style="outlined"
          size="large"
          content="text"
          onClick={handleApplyContact}
        />
      </div>
      <div className="grid col-span-12 md:col-span-5 lg:[10%]">
        <NewButton
          disabled={!multipleMemberships}
          text={`APPLY TO ALL`}
          className="!max-h-[3rem]"
          color="accent"
          style="filled"
          size="large"
          content="text"
          onClick={handleApplyAllContact}
        />
      </div>
      <div className="hidden md:grid col-span-1 lg:[5%]">
        <NewButton
          className="!max-h-[3rem] !self-end"
          color="delete"
          style="outlined"
          size="large"
          content="iconSquare"
          icon={<RemoveSign />}
          onClick={handleRemove}
        />
      </div>
      <div className="md:hidden grid col-span-12 ">
        <NewButton
          className="!max-h-[3rem]"
          color="delete"
          text="REMOVE"
          style="outlined"
          size="large"
          content="textIcon"
          icon={<RemoveSign />}
          onClick={handleRemove}
        />
      </div>
    </div>
  );
};

export default OfficeContactBody;
