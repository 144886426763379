import { useContext, useEffect, useState } from 'react';
import Input from "../../Atomos/Inputs/Input";
import DropDownList from "../../Atomos/DropDown/Dropdown";
import Axios from '../../../utils/axios';
import { PersonRelationsProps } from '../../../interfaces/models/IPerson';
import RadioButton from '../../Atomos/Radio/RadioButton';
import { MemberType } from '../../../Enums/PersonEnums';
import DDMultiple, { Option } from '../../Atomos/DropDown/DDMultiple';
import CatalogSubTitle from '../Catalog Text/CatalogSubTitle';
import useViewport from '../../../hooks/useViewPort';
import NewButton from '../../Atomos/Buttons/newButton';
import { useAppContext } from '../../../Context';
import { UseFirmList } from '../../../hooks/Catalogs/useFirm';
import { useLocation } from 'react-router-dom';
const axios = new Axios();


type PersonRelationsEditProps = {
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
  handleRadioButtonChange: (name: string, index: number) => void
  handleMultipleSelectChange: (name: string, value: Option[]) => void
  Errors: any
  isContentRedirect: boolean
  content?: { memberFirms: string[]; }
  disabledFirm?: boolean
  mode: string;
  disaableCatalogList?: boolean;
} & PersonRelationsProps;

const PersonRelations: React.FC<PersonRelationsEditProps> = (props: PersonRelationsEditProps) => {
  const [selectedSubmittedBy, setSelectedSubmittedBy] = useState('');
  const externalTypeOptions = [{ id: "Author", name: "Author" }, { id: "Guest", name: "Guest" }];

  // Initializing memberType as Member if it's undefined
  const [memberType, setMemberType] = useState(props.memberType ?? MemberType.member);
  const { firmData } = UseFirmList();
  const { state } = useLocation()
  const [selectedFirm, setSelectedFirm] = useState(state?.firm?.name);
  const fetchWSGCompany = async (): Promise<string[] | any> => {
    try {
      const response = await axios.Get('./wsg');
      const responseData = response.data;
      return responseData.map((wsg: any) => wsg);
    } catch (error) {
      console.error('Error fetching firms:', error);
    }
    return undefined;
  }
  
  useEffect(() => {
    const updateFirm = async () => {
      if (memberType === MemberType.staff) {
        const wsg = await fetchWSGCompany();
        if (wsg && wsg[0].name) {
          setSelectedFirm(wsg[0].name);
          props.handleInputChange({ target: { name: 'firm', value: wsg[0].id } } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
        }
      } else {
        setSelectedFirm(firmData?.find((firm: any) => firm.id === state?.firm)?.name ?? '');
        props.handleInputChange({ target: { name: 'firm', value: state?.firm } } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
      }
    };

    updateFirm();
  }, [memberType, state?.firm]);


  const handleDropDownChangefirms = (obj: any) => {
    setSelectedFirm(obj.name);
    props.handleInputChange({ target: { name: 'firm', value: obj.id } } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
  };

  const handleDropDownChangeSubmittedBy = (obj: any) => {
    setSelectedSubmittedBy(obj.name);
    props.handleInputChange({ target: { name: 'submittedBy', value: obj.id } } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
  };

  const [firmsSubmittedBy, setFirmsSubmittedBy] = useState<[{ id: string; name: string; }]>([{ id: '0', name: '' } as { id: string; name: string; }]);
  const [firms, setFirms] = useState<[{ id: string; name: string; }]>([{ id: '0', name: '' } as { id: string; name: string; }]);

  useEffect(() => {
    if (!firmData.length) return

    const mappedFirms = firmData.map((firm: any) => ({
      id: firm.id,
      name: firm.name, isMember: firm.isMember,
    }));

    const currentfirm = mappedFirms.find((firm: any) => firm.id === (props.firm || props.content?.memberFirms[0]));
    const currentSubmittedBy = mappedFirms.find((firm: any) => firm.id === props.submittedBy);

    const firmsAux = mappedFirms.filter((firm: any) => memberType === 1
      ? firm.isMember === false
      : firm.isMember === true
    )

    setFirms(firmsAux)
    setFirmsSubmittedBy(mappedFirms);
    setSelectedFirm(currentfirm?.name || '')
    props.handleInputChange({ target: { name: 'firm', value: currentfirm?.id } } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
    setSelectedSubmittedBy(currentSubmittedBy?.name || '')
  }, [firmData, memberType]);
  
  const handleRadioButtonIsMember = (index: number) => {
    // Verifica si el cambio es de 'staff' a 'member' o 'nonMember'
    if (memberType === MemberType.staff && (index === MemberType.member || index === MemberType.nonMember)) {
      setSelectedFirm(''); // Limpia la firma
      props.handleInputChange({ target: { name: 'firm', value: '' } } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
    }
  
    // Actualiza el memberType al nuevo valor seleccionado
    setMemberType(index);
    props.handleRadioButtonChange('memberType', index);
  };

  const { mode } = useAppContext();
  const { viewportWidth } = useViewport();

  return (
    <section className='formsectionNTitle w-full'>
      {!props.disaableCatalogList &&
        <CatalogSubTitle title={'Catalog List Relationships'} className="flex flex-col gap-1.5 md:flex-row justify-between w-full text-accent-Default-dark">
          {(viewportWidth > 1023 || (mode === 'staff' && memberType !== MemberType.nonMember)) && 
            <div className='col-span-4'>
              <NewButton text='SEND CREDENTIALS' className='md:text-base md:leading-[1.125rem] md:!h-7 md:!px-3 md:!py-1' size='small' color='accent' onClick={() => { }} />
            </div>}
        </CatalogSubTitle>
      }

      <div className='formsection flex flex-col w-full'>
        {!props.disaableCatalogList && 
          <div className=" flex flex-col md:flex-row items-start md:items-center w-full justify-start gap-5">
            <h5 className=' text-[1.375rem] font-bold leading-6 not-italic text-gray-font'>Person Type:</h5>
            <RadioButton index={MemberType.member} text={'Member'} selected={memberType === MemberType.member} handleRadioButtonChange={() => handleRadioButtonIsMember(MemberType.member)} />
            <RadioButton index={MemberType.nonMember} text={'Non-Member'} selected={memberType === MemberType.nonMember} handleRadioButtonChange={() => handleRadioButtonIsMember(MemberType.nonMember)} disabled={props.isContentRedirect} />
            <RadioButton index={MemberType.staff} text={'WSG Staff'} selected={memberType === MemberType.staff} handleRadioButtonChange={() => handleRadioButtonIsMember(MemberType.staff)} disabled={props.isContentRedirect} />
          </div>
        }

        <div className="w-full md:w-full  flex flex-col md:flex-row  gap-4">
          {!props.disaableCatalogList && 
            <div className={`w-full ${memberType === MemberType.nonMember ? 'md:w-3/12' : 'md:w-6/12'}`}>
              <DropDownList getid={true} title={"Firm"} required={memberType !== MemberType.nonMember}
                disabled={memberType === MemberType.staff || props.disabledFirm}
                data={firms} selectedObjst={selectedFirm} seter={handleDropDownChangefirms}
                error={props.Errors.firm.error} errormsj={props.Errors.firm.message} />
            </div>
          }
          {memberType === MemberType.nonMember && <>
            <div className="w-full md:w-3/12">
              <DDMultiple title={"External Type"} required={true}
                objects={externalTypeOptions} error={props.Errors.externalType.error} errorMessage={props.Errors.externalType.message}
                selectedObjst={externalTypeOptions.filter((obj) => (props.externalType?.includes(obj.id)))}
                seter={(e: any) => props.handleMultipleSelectChange('externalType', e)}
              />
            </div>
            <div className="w-full md:w-3/12">
              <DropDownList getid={true} title={"Submitted By"} required={false}
                data={firmsSubmittedBy} selectedObjst={selectedSubmittedBy} seter={handleDropDownChangeSubmittedBy}
                error={props.Errors.firm.error} errormsj={props.Errors.firm.message} />
            </div>
          </>}
          {!props.disaableCatalogList && 
            <div className={`w-full ${memberType === MemberType.nonMember ? 'md:w-3/12' : 'md:w-6/12'}`}>
              <Input disabled title="GeneratedID" name="GeneratedID" errorMsj={""} error={false}
                tooltip='Generated Automatically.' value={props.generatedId?.toString()} onChange={props.handleInputChange} required={false} />
            </div>
          }
        </div>
      </div>
    </section>
  );
}

export default PersonRelations;
