import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentFirm,
  setOffice,
  setCityInfo,
  setAddressInfo,
  setPracticeIndustries,
  setPersonCount,
  setProfessionals,
  setOffices,
  setArticles,
  setMainProfessionalsByOffice,
  setAreasOfPractice,
} from "../../redux/states/firmProfile.state";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from "../../assets/images&icons/profileViewMode.jpeg";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import ChevronRight from "../../components/Atomos/Icons/ChevronRight";
import SocialLinks from "../../components/ViewMode/SocialLinks";
import { getFirmById, getFirms, getOfficesByFirmId } from "../../Services/Firm";
import { ICity, IPerson } from "../../interfaces";
import { getAllCities } from "../../Services/City";
import { getAllAddresses } from "../../Services/Address";
import Disclosure from "../../components/Moleculas/Disclosure/Disclosure";
import { IAreaOfPractice } from "../../interfaces/models/IAreaOfPractice";
import { getAllAops } from "../../Services/AreaOfPractice";
import { getAllPracticeIndustries } from "../../Services/PracticeIndustry";
import { IPracticeIndustry } from "../../interfaces/models/IPracticeIndustry";
import Axios from "../../utils/axios";
import IOffice from "../../interfaces/models/IOffice";
import GuidesCard from "../../components/Moleculas/FirmProfile/Guides";
import { Appstore } from "../../redux";
import { IRepresentativeClient } from "../../interfaces/models/IRepresentativeClient";
import { getRepresentativeClients } from "../../Services/RepresentativeClient";
import Professionals from "./Profile/Professionals";
import OverviewCard from "./Profile/OverviewCard";
import NewButton from "../../components/Atomos/Buttons/newButton";
import PressCard from "./Profile/PressCard";
import BlogCard from "./Profile/BlogCard";
import Vector from "../../components/Atomos/Icons/Vector";
import ArticleCard from "./Profile/ArticleCard";
import ContactFrame from "./Profile/ContactFrame";
import AopFirm from "./Profile/AopFirm";
import { getNameById } from "../../utils/functions";
import { UseCountryList } from "../../hooks/Catalogs";
import useViewport from "../../hooks/useViewPort";
import IFirm from "../../interfaces/models/IFirm";
import { hideLoader, showLoader } from "../../redux/loaderActions";

const WSGDots = require("../../assets/images&icons/WSGDots.svg");
const axios = new Axios();

const ManageFirmProfile = () => {
  const manageFirm = useSelector((state: Appstore) => state.manageFirm);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { viewportWidth } = useViewport();
  const {
    currentFirm,
    office,
    cityInfo,
    addressInfo,
    practiceIndustries,
    professionals,
    offices,
    articles,
    mainProfessionalsByOffice,
    areasOfPractice,
  } = useSelector((state: Appstore) => state.firmProfile);
  const [representativeClients, setRepresentativeClients] = useState<
    IRepresentativeClient[]
  >([]);
  const [personList, setPersonList] = useState([])
  const {countryData} = UseCountryList();

  const isValidDate = (dateString: string): boolean => {
    const date = new Date(dateString);
    const currentDate = new Date();

    return !isNaN(date.getTime()) && date <= currentDate;
  };

  const getArticles = async () => {
    try {
      const response = await axios.Get("/Content");
      if (response && response.data) {
        dispatch(
          setArticles(
            response.data.filter(
              (article: any) => article?.contentType === "article"
              // && article?.memberFirms.includes(state?.id) 
              // && isValidDate(article.publishedDate)
              // && article.isApproved === "yes"
            )
          )
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const GetRepClients = async () => {
    if (currentFirm) {
      const representativeClients = await getRepresentativeClients();
      const representativeCByFirm = representativeClients.filter(
        (rc: IRepresentativeClient) => rc?.firm === manageFirm.selectedFirm?.id
      );
      setRepresentativeClients(representativeCByFirm);
    }
  };

  const getAopsByOffice = async () => {
    try {
      const response = await getAllAops();
      if (response) {
        const filteredByOffice = response.filter((aop: IAreaOfPractice) =>
          aop.offices?.some((o: IOffice) => o.id === office?.id)
        );
        const uniqueAops = new Set(
          [...areasOfPractice, ...filteredByOffice].map((aop) => aop.id)
        );

        const updatedAops = Array.from(uniqueAops).map((id) => {
          return [...areasOfPractice, ...filteredByOffice].find(
            (aop) => aop.id === id
          )!;
        });
        await getPracticeIndustriesByAops(
          updatedAops.length > 0 ? updatedAops : []
        );
        dispatch(setAreasOfPractice(updatedAops.length > 0 ? updatedAops : []));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOfficesInfo = async (id: string) => {
    if (currentFirm) {
      const offices: any = await getOfficesByFirmId(id);
      const mainOffice = offices.filter(
        (office: any) => office.officeType === "Main"
      )[0];
      dispatch(setOffices(offices));
      dispatch(setOffice(mainOffice));
    }
  };

  const getPracticeIndustriesByAops = async (aops: IAreaOfPractice[]) => {
    //TODO: Modified to fetch from AOP instead of practice industries
    // if (currentFirm) {
    //   const practiceIndustries: IPracticeIndustry[] =
    //     await getAllPracticeIndustries();
    //   if (practiceIndustries) {
    //     const matchedIndustries: IPracticeIndustry[] =
    //       practiceIndustries.filter((industry) =>
    //         industry.areasOfPractice.some((area) =>
    //           aops.some((aop) => aop?.id === area?.id)
    //         )
    //       );
    //     dispatch(setPracticeIndustries(matchedIndustries));
    //   }
    // }
  };

  const getCityInfo = async (city: string) => {
    let formattedCity = city?.toLowerCase().trim();
    const cities = await getAllCities();
    const firmCity = cities?.find(
      (c: any) => c?.name?.trim().toLowerCase() === formattedCity
    );
    if (firmCity) {
      try {
        const res = await getAllAddresses();
        const filtered = res.filter(
          (address: any) =>
            address.cityId === firmCity?.id && address.noZipCode === false
        );
        if (Array.isArray(filtered) && filtered.length === 1) {
          dispatch(setAddressInfo(filtered[0]));
        }
      } catch (error) { }
    }
    dispatch(setCityInfo(firmCity));
  };

  const getFirmInfo = async (id: string) => {
    const firm:IFirm = await getFirmById(id);
    dispatch(setCurrentFirm(firm));
    if (firm) {
      const aops: IAreaOfPractice[] = await getAllAops();
      const matchingAops = aops.filter((aop) =>
        aop.firms?.some((f) => f?.id === id)
      );
      dispatch(setAreasOfPractice(matchingAops));
      await getPracticeIndustriesByAops(matchingAops);
    }
  };

  const getAllPersons = async () => {
    const res = await axios.Get("/person");
    setPersonList(res.data);
  };

  const getProfessionalsByOffice = async () => {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({firm: {value: manageFirm.selectedFirm?.id, comparisonType: 'EQUAL'}}))
    const res = await axios.Get(`/officeContact?${params.toString()}`);
    if (res && res.data) {
      const officeMainContactsByFirm = res.data
      if (officeMainContactsByFirm && officeMainContactsByFirm.length > 0) {
        const filteredByOffice = officeMainContactsByFirm[0]?.offices?.find(
          (o: any) => o.office === office?.id
        )?.contacts;

        const matchedPersons = personList.filter((person: any) =>
          filteredByOffice?.find(
            (contact: any) => contact.contact === person.id
          )
        );
        dispatch(setMainProfessionalsByOffice(matchedPersons));
      }
    }
  };

  const getPersonCountByFirm = async (firmId: string) => {
    const filteredPersons = personList.filter(
      (person: any) => person.firm === firmId
    );
    dispatch(setPersonCount(filteredPersons.length));
    dispatch(setProfessionals(filteredPersons));
  };

  const fetchData = async () => {
    try {
      const promises = [];

      if (id) {
        promises.push(getFirmInfo(id));
        promises.push(getOfficesInfo(id));
        promises.push(getArticles());
        promises.push(GetRepClients());
      }

      if (office) {
        promises.push(getCityInfo(office.city));
      }

      promises.push(getAllPersons());

      await Promise.all(promises);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!office || !currentFirm || !personList?.length) return
      getProfessionalsByOffice();
  }, [office, currentFirm, personList]);

  useEffect(() => {
    if (!manageFirm.selectedFirm?.id || !personList?.length) return
      getPersonCountByFirm(manageFirm.selectedFirm?.id);
  }, [manageFirm.selectedFirm, personList]);

  const handleOfficeChange = (office: any) => {
    dispatch(setOffice(office));
    getAopsByOffice();
  };
  const pressCardData = [1, 2, 3];

  return (
    <>
      <BackgroundComponent image={ProfileViewModeBg} />
      <div className="bg-gray-100 min-h-screen text-left px-4 py-7 items-start flex flex-col gap-[1.5rem] md:py-8 md:px-6 lg:py-10 lg:px-10 lg:gap-[2.5rem]">
        <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 !items-end self-stretch !p-0 flex-col ">
          <div className="flex p-0 flex-col justify-center items-end gap-5 self-stretch md:flex-row lg:justify-between lg:gap-6">
            <h2 className="text-left w-full md:w-max text-white font-decimal text-[2.25rem] font-medium leading-[2.75rem] md:text-[2.75rem] md:leading-[3.25rem] lg:text-[3.25rem] lg:leading-[4.5rem]">{`${manageFirm.selectedFirm?.name} Preview`}</h2>
            <div
              className="self-stretch flex-1 hidden md:flex"
              style={{
                backgroundImage: `url(${WSGDots.default})`,
              }}
            ></div>
            <div className="w-full md:w-fit">
              <Link 
                to={`/staff/ManageFirm/Profile/modify/${manageFirm.selectedFirm?.id}`} 
                state={{offices: offices, mainContacts: mainProfessionalsByOffice, aops: areasOfPractice}}
              >
                <NewButton
                  text={`MODIFY`}
                  color="accent"
                  type="button"
                  style="filled"
                  size="large"
                  content="text"
                  className="!h-[60px] lg:!h-[72px]"
                />
              </Link>
            </div>
          </div>
          <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage prevPage="Manage Firm" currentPage="Profile" />
          </div>
        </div>

        {/* White Box */}
        <div
          className="flex py-5 px-4 flex-col items-start gap-5 self-stretch bg-white relative
          md:py-7 md:px-7 md:gap-6
          lg:py-8 lg:px-10 lg:gap-10"
        >
          <div
            className="flex p-0 flex-col items-start gap-7 self-stretch 
            lg:gap-[1.25rem]"
          >
            <div
              className="flex 
            p-0 justify-center items-center gap-3 lg:rounded-none"
            >
              <Link
                to=""
                className=" text-accent-Default-dark text-center font-decimal leading-7 text-base lg:font-bold lg:leading-6"
              >
                Latest News {office?.name} : {office?.countryName ?? getNameById(office?.country, countryData)?.join?.() ?? ''}
              </Link>
              <div className="flex p-1 m-auto justify-center items-center gap-[.625rem] bg-[#0070CD] rounded-full w-[1.25rem] h-[1.25rem]">
                <div className="w-3 h-3 flex fill-white">
                  <ChevronRight />
                </div>
              </div>
            </div>
            <hr className="flex h-0 lg:justify-center lg:items-center self-stretch"></hr>
          </div>
          <div className="flex flex-col items-center gap-7 lg:gap-10 self-stretch">
            <div className="flex flex-col items-center gap-7 lg:gap-[1.125rem] self-stretch">
              <div className="flex flex-col items-center lg:items-start md:flex-col lg:justify-center gap-[1.125rem] self-stretch flex-1">
                <div className="flex flex-col md:flex-row md:justify-center md:items-start lg:items-center items-center gap-[1.5rem] md:gap-[.625rem] md:flex-1 self-stretch">
                  <div className="flex gap-[1.25rem] md:w-[21.5rem] lg:w-[39.625rem] flex-col lg:justify-center items-center lg:gap-[.625rem] lg:flex-1">
                    <div className="flex flex-col justify-center items-start gap-[.625rem] self-stretch md:flex-1 w-full">
                      <span className="self-stretch text-primary font-decimal text-[2.375rem] leading-[2.75rems]">
                        {manageFirm?.selectedFirm?.firmName}
                      </span>
                      <span className="self-stretch text-center md:text-left text-gray-font lg:text-lg lg:leading-5">
                        {manageFirm?.selectedFirm?.serviceType}
                      </span>
                      <div className="flex justify-center md:justify-start items-center gap-2 self-stretch">
                        <SocialLinks
                          organization
                          organizationObj={currentFirm ? currentFirm : []}
                        />
                      </div>
                      <Link
                        to=""
                        className="self-stretch text-center md:text-left text-Default lg:text-lg lg:leading-5 underline"
                      >
                        {currentFirm?.websiteUrl}
                      </Link>
                    </div>
                  </div>
                  <div className="flex flex-1 justify-center align-middle mb-5 self-stretch ">
                    {manageFirm.selectedFirm?.logo ? (
                      <img
                        src={`${manageFirm.selectedFirm?.logo ?? ""}`}
                        alt="Wsg_Profile_Picture"
                        className="object-cover max-h-[174px] max-w-[330px]"
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-start gap-[.625rem] self-stretch">
                  <div className="flex flex-col items-start gap-4 self-stretch">
                    <span className="text-gray-font text-[1.125rem] leading-normal lg:text-[1.375rem] font-bold lg:leading-6">
                      Office Selected: {office?.name}
                    </span>
                    <div className="flex flex-col justify-center items-start gap-3 self-stretch  overflow-x-auto relative overflow-y-hidden noscrollbar">
                      <div className="flex lg:items-center lg:gap-4">
                        <span className="text-[.875rem] leading-5 text-gray-font lg:text-lg">
                          {office?.phoneNumber?.code || ""} {office?.phoneNumber?.phone || ""}
                        </span>
                        <span className="text-[.875rem] leading-5 text-gray-font lg:text-lg">
                          {office?.faxNumber?.code || ""} {office?.faxNumber?.phone || ""}
                        </span>
                      </div>
                      <div className="flex gap-4 items-center">
                        <span className="whitespace-nowrap text-[.875rem] leading-5 text-gray-font lg:text-lg">
                          {office?.addressName}
                        </span>
                        <hr className="w-0 h-[1.25rem]" />
                        <span className="text-[.875rem] leading-5 text-gray-font lg:text-lg whitespace-nowrap">
                          {office?.cityName}
                        </span>
                        <hr className="w-0 h-[1.25rem]" />
                        <span className="text-[.875rem] leading-5 text-gray-font lg:text-lg whitespace-nowrap">
                          {cityInfo?.stateName}
                        </span>
                        <hr className="w-0 h-[1.25rem]" />
                        <span className="text-[.875rem] leading-5 text-gray-font lg:text-lg">
                          {addressInfo?.zipCode}
                        </span>
                        <hr className="w-0 h-[1.25rem]" />
                        <span className="text-[.875rem] leading-5 text-gray-font lg:text-lg">
                          {cityInfo?.countryName}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {mainProfessionalsByOffice.length > 0 && (
                <>
                  <hr className="flex h-0 lg:justify-center lg:items-center self-stretch"></hr>
                  <div className="flex flex-col items-center gap-4 self-stretch">
                    <span className="text-gray-font text-[1.125rem] leading-normal lg:text-[1.375rem] font-bold lg:leading-6 self-stretch">
                      {office?.name} Main Contacts
                    </span>
                    <div className={`flex flex-col md:grid md:grid-cols-2 ${viewportWidth < 1250 ? "lg:grid-cols-3" : "lg:grid-cols-4"} lg:flex-wrap items-center gap-10 self-stretch`}>
                      {mainProfessionalsByOffice &&
                        mainProfessionalsByOffice.length > 0 &&
                        mainProfessionalsByOffice.map(
                          (professional: IPerson) => (
                            <React.Fragment key={professional.id}>
                              <ContactFrame person={professional} />
                            </React.Fragment>
                          )
                        )}
                    </div>
                  </div>
                </>
              )}
              {offices && offices.length > 0 && (
                <React.Fragment>
                  <hr className="flex h-0 lg:justify-center lg:items-center self-stretch"></hr>
                  <div className="flex flex-col items-center gap-4 self-stretch">
                    <span className="text-gray-font text-[1.125rem] leading-normal lg:text-[1.375rem] font-bold lg:leading-6 self-stretch">
                      Offices
                    </span>
                    <div className="grid grid-cols-2 md:grid-cols-5 lg:grid-cols-8 lg:items-start justify-between gap-4 lg:flex-1 lg:flex-wrap self-stretch">
                      {offices.map((currentOffice: any) => (
                        <div
                          key={currentOffice?.id}
                          className={`grid col-span-1 lg:flex-col lg:items-start flex-wrap`}
                        >
                          <span
                            className={`text-Default text-base leading-[1.125rem] lg:text-[1.125rem] lg:leading-5 cursor-pointer ${currentOffice?.id === office?.id ? "cursor-default" : ""}`}
                            onClick={() => handleOfficeChange(currentOffice)}
                          >
                            {currentOffice?.cityName}, {currentOffice?.countryName}
                          </span>
                          <hr className="h-0 w-[.625rem]" />
                        </div>
                      ))}
                      <hr className="w-0 h-[2.4375rem]" />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {currentFirm?.serviceType === "Investment Banking" && (
                <hr className="flex h-0 lg:justify-center lg:items-center self-stretch"></hr>
              )}
            </div>
            {currentFirm?.serviceType === "Investment Banking" && (
              <div className="flex flex-col items-center gap-6 self-stretch">
                <span className="text-primary font-decimal lg:text-[2rem] lg:font-medium lg:leading-9 self-stretch">
                  Representative Clients
                </span>
                <div className="flex items-center gap-5 self-stretch overflow-x-scroll">
                  {representativeClients &&
                    representativeClients.length > 0 &&
                    representativeClients.map(
                      (representativeClient: IRepresentativeClient) => (
                        <div
                          key={representativeClient.id}
                          className="flex w-[12.5rem] md:w-[18.625rem] md:h-[11.5625rem]  h-[7.75rem] gap-0 lg:w-[18.625rem] lg:h-[11.5625rem] p-6 flex-col justify-center items-center md:gap-[.625rem] border border-solid border-gray-50"
                        >
                          <div className="w-[8.375rem] h-full flex-1">
                            <img
                              src={representativeClient?.logo}
                              className="bg-cover w-full h-full relative flex border-none"
                              alt={representativeClient.name}
                            />
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
        {manageFirm.selectedFirm?.firmOverview !== "" && (
          <Disclosure
            title="Overview"
            isCardOpen={!!manageFirm.selectedFirm?.firmOverview}
            onCardOpen={() => ""}
            color="secondary"
          >
            <OverviewCard overview={manageFirm.selectedFirm?.firmOverview} />
          </Disclosure>
        )}
        {areasOfPractice.length > 0 && areasOfPractice && (
          <Disclosure
            title="Areas Of Practice"
            isCardOpen={true}
            onCardOpen={() => ""}
            color="secondary"
          >
            <div className={`duration-300 flex flex-col self-stretch`}>
              <AopFirm
                practiceIndustries={practiceIndustries}
                aops={areasOfPractice}
              />
            </div>
          </Disclosure>
        )}
        {currentFirm?.serviceType !== "Investment Banking" && (
          <Disclosure
            title="Representative Clients"
            isCardOpen={
              representativeClients && representativeClients.length > 0
            }
            onCardOpen={() => ""}
            color="secondary"
          >
            <div className="py-7 px-7 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 self-stretch">
              {representativeClients &&
                representativeClients.length > 0 &&
                representativeClients.map(
                  (representativeClient: IRepresentativeClient) => (
                    <React.Fragment key={representativeClient.id}>
                      <div className="gap-6 self-stretch">
                        <span className="text-gray-font text-base leading-[1.125rem]">
                          {representativeClient.name}
                        </span>
                      </div>
                    </React.Fragment>
                  )
                )}
            </div>
          </Disclosure>
        )}

        <Professionals professionals={professionals} />

        <Disclosure
          title="Articles"
          isCardOpen={true}
          onCardOpen={() => ""}
          color="secondary"
        >
          <div className="flex py-7 px-7 gap-6 lg:py-11 lg:px-12 flex-col items-end lg:gap-[1.75rem] self-stretch">
            <ArticleCard articles={articles ? articles : []} viewAll />
          </div>
        </Disclosure>

        <Disclosure
          title="Press"
          isCardOpen={true}
          onCardOpen={() => ""}
          color="secondary"
        >
          <div className="grid grid-cols-6 gap-7 w-full py-7 px-7 lg:py-11 lg:px-12 flex-col items-end lg:gap-[1.75rem] self-stretch">
            {pressCardData.map((item, index) => (
              <React.Fragment key={index}>
                <PressCard />
                {index < pressCardData.length - 1 && (
                  <div className="grid flex-1 col-span-6">
                    <Vector className="w-full h-0 stroke-gray-50 stroke-1" />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </Disclosure>

        <Disclosure
          title="Blog"
          isCardOpen={true}
          onCardOpen={() => ""}
          color="secondary"
        >
          <div className="grid grid-cols-6 gap-7 w-full py-7 px-7 lg:py-11 lg:px-12 flex-col items-end lg:gap-[1.75rem] self-stretch">
            {pressCardData.map((item, index) => (
              <React.Fragment key={index}>
                <BlogCard />
                {index < pressCardData.length - 1 && (
                  <div className="grid flex-1 col-span-6">
                    <Vector className="w-full h-0 stroke-gray-50 stroke-1" />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </Disclosure>

        <Disclosure
          title="Guides"
          isCardOpen={true}
          onCardOpen={() => ""}
          color="secondary"
        >
          <GuidesCard />
        </Disclosure>
      </div>
    </>
  );
};

export default ManageFirmProfile;
