import { IPerson } from "../interfaces";
import Axios from "../utils/axios";

const axios = new Axios();

export const getPersons = async () => {
  try {
    const response = await axios.Get("/person?withWSG=True");
    return response.data;
  } catch (error) {
    console.error("Error fetching firms:", error);
    return [];
  }
};

export const fetchPersonByFirm = async (firmid: any) => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({firm: {value: firmid, comparisonType: 'EQUAL'}}))
    const response = await axios.Get(`./person?${params.toString()}`);
    const data = response.data;
    if (data != null) {
      return data;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const searchableProfiles = async (filter = {}) => {
  try {
    const queryString = `/person?serchableProfiles=true
      &filter=${JSON.stringify(filter)}`;
    const response = await axios.Get(queryString);
    return response.data;
  } catch (error) {
    console.error("Error al obtener la información de la columna:", error);
  }
};

export const getPersonById = async (id: string): Promise<IPerson> => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({id: {value: id, comparisonType: 'EQUAL'}}))
    const response = await axios.Get(`./person?${params.toString()}`);
    return response.data[0];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getPersonByUserId = async (id: string): Promise<IPerson> => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({userId: {value: id, comparisonType: 'EQUAL'}}))
    const response = await axios.Get(`./person?${params.toString()}`);
    return response.data[0];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export const addPerson = async (person: IPerson): Promise<void> => {
  try {
    const response = await axios.Post('person', person);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePerson = async (person: IPerson): Promise<void> => {
  try {
    const response = await axios.Put('person', person);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePerson = async (personId: string) => {
  try {
    await axios.Delete('person', personId);
    return true
  } catch (error) {
    throw error;
  }
};

export const countPersonsByFirm = async (firmId: string) => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({firm: {value: firmId, comparisonType: 'EQUAL'}}))
    const response = await axios.Get(`./person?${params.toString()}&count=true`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const countAttorneyByFirm = async (firmId: string) => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({
      isAttorney: {value: true, comparisonType: 'EQUAL'},
      firm: {value: firmId, comparisonType: 'EQUAL'},
    }))
    const response = await axios.Get(`./person?${params.toString()}&count=true`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const countAttorneyByMainOffice = async (officeIds: string[], firmId: string) => {
  try {
    const params = new URLSearchParams();
    params.append('filter', JSON.stringify({
      mainOffice: {value: officeIds, comparisonType: 'EQUAL'},
      firm: {value: firmId, comparisonType: 'EQUAL'},
      isAttorney: {value: true, comparisonType: 'EQUAL'}
    }))
    const response = await axios.Get(`./person?${params.toString()}&count=true`);
    return response.data;
  } catch (error) {
    throw error;
  }
}